import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'
import { mq } from '@lib/hooks'
import theme from '@styles/theme'

export const Header = styled(Box)`
  position: fixed;
  top: 0;
  transition: all 0.3s ease;
  background-color: ${theme.colors.red.primary};

  ${mq('lg')} {
    background-color: ${theme.colors.red.primary};
  }

  &.Header--hide {
    top: ${theme.sizes.header.negative};
  }

  &.Header--secondary,
  &.Header--sticky {
    background-color: ${theme.colors.red.primary};
  }

  &.Header--sticky + * {
    padding-top: 40px;
  }

  & > .Header__Inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .Header__Nav {
      ${mq('lg')} {
        z-index: 2;
      }

    }
  }

  .styles__NavItem {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${theme.colors.black.primary3};
    padding: 0px;
    user-select: none;
    display: flex;
    align-items: center;
  }

  .Header__SSMLogo {
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s linear;
    transform: translateY(-20px);
  }

  .Header__SSMLogo--visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }

  .Header__SSMBigLogo {
    display: block;
    position: absolute;
    top: 15px;
    left: 24px;
    transform: translateX(-50%);
    transition: all 0.25s linear;
    width: 46px;

    ${mq('lg')} {
      top: 15px;
      left: 100px;
      width: auto;
      max-width: 100%;
      padding: 0px ${theme.space.padding.primary};
    }

    ${mq('base')} {
      top: 10px;
    }
    ${mq('md')} {
      top: 15px;
    }

    &.Header__SSMBigLogo--hidden {

      ${mq('lg')} {
        top: 24%;
      }
      ${mq('md')} {
        top: 18%;
      }
    }
  }
`

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
`
