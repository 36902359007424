import {
  Box,
  BoxProps,
  Flex,
  Container,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useStore } from '@store'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import { Link } from 'react-scroll'

interface INavItems {
  id: string
  navTitleTx: string
  href: string
}

interface IExhibitionAndEventNav {
  navItems: INavItems[]
  children?: JSX.Element | JSX.Element[]
  containerProps?: BoxProps
}

const ExhibitionAndEventNav = observer(
  ({ navItems, children, containerProps }: IExhibitionAndEventNav) => {
    const { ui } = useStore()
    const { t } = useTranslation()
    const isDesktop = useBreakpointValue({ base: false, lg: true })

    return (
      <Box
        as="nav"
        position="sticky"
        top={ui.scrollDir === 'down' ? '0px' : '-100%'}
        bgColor="white.primary"
        zIndex={3}
        {...containerProps}
      >
        <Container>
          <Flex
            sx={{
              '& > * + *': {
                ml: 6,
              },
              scrollSnapType: 'x proximity',
              '::-webkit-scrollbar': {
                width: '2px',
                height: '5px',
              },
              '::-webkit-scrollbar-track': {
                background: 'greyAlpha.primary',
              },
              '::-webkit-scrollbar-thumb': {
                background: 'red.primary',
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: 'red.primary3',
              },
            }}
            py={{ base: 4, lg: 5 }}
            overflowX="auto"
            overflowY="hidden"
            height={{ base: '50px', lg: '65px' }}
            flexDirection="row"
            alignItems="center"
          >
            {navItems.map((nav, i) => (
              <Box
                as={Link}
                key={nav.id}
                activeClass="navLink--active"
                to={nav.href}
                spy={true}
                smooth={true}
                offset={isDesktop && i === 0 ? -102 : isDesktop ? -62 : -54}
                duration={200}
                fontSize="sm"
                fontWeight="light"
                color="black.primary3"
                _hover={{ cursor: 'pointer' }}
                position="relative"
                whiteSpace="pre"
                sx={{
                  '&.navLink--active': {
                    fontWeight: 'bold',
                    scrollSnapAlign: 'center',
                  },
                  '&.navLink--active::before': {
                    content: `""`,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: '-4px',
                    width: 'full',
                    height: '2px',
                    bgColor: 'red.primary',
                  },
                }}
              >
                {t(nav.navTitleTx)}
              </Box>
            ))}
            {children}
          </Flex>
        </Container>
      </Box>
    )
  }
)

export default ExhibitionAndEventNav
