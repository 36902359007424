import { createIcon } from '@chakra-ui/icons'

const Minus = createIcon({
  displayName: 'Minus',
  defaultProps: {
    width: 18,
    height: 2,
    color: 'black.primary3',
  },
  viewBox: '0 0 18 2',
  path: <path stroke="currentColor" d="M18 1L0 1" strokeWidth="2" />,
})

export default Minus
