import drupalHttp from '../drupalHttpService'
import { SHOP } from '@lib/const/api-endpoints.const'

/**
 * Shop for eMuseum Http Service
 */
export const ShopHttpService = {
  /**
   *
   */
  getProductDetail: async (productId: number, locale: string) =>
    await drupalHttp.get(`${SHOP[locale]}/${productId}`),
}
