import { IArtCollectionItemType, IFonts } from '@interfaces'
import Masonry from 'react-responsive-masonry'
import { SelectionsFromCollectionCard } from '@ui'
import { Box } from '@chakra-ui/react'

interface ISelectionsFromCollection {
  collection: IArtCollectionItemType[]
  isDarkSection?: boolean
  activeFontFamily?: IFonts
}

const SelectionsFromCollection = ({
  collection,
  isDarkSection = false,
  activeFontFamily = 'body',
}: ISelectionsFromCollection) => {
  return (
    <Box mt="padding.primary">
      {collection && collection.length > 0 && (
        <Masonry columnsCount={2} gutter="20px">
          {collection.map((col) => (
            <SelectionsFromCollectionCard
              key={col.id}
              url={col.url}
              title={col.title}
              name={col.name}
              imgUrl={col.imgUrl}
              isDarkSection={isDarkSection}
              activeFontFamily={activeFontFamily}
            />
          ))}
        </Masonry>
      )}
    </Box>
  )
}

export default SelectionsFromCollection
