import { IStickyNavItem, IArtCollection, ISSRPageData } from '@interfaces'
import { Box } from '@chakra-ui/react'
import {
  ArtCollection,
  ArtCollectionNav,
  // ArtHeroSearchCard disabled for now will be enabled once fixes ready needs to be added in hero tag
  Hero,
  Layout,
} from '@common'

interface ICollectionsAndArchiveTemplate {
  pageTitle: string
  pathname: string
  asPath: string
  heroBgImageUrl: string
  heroSubTitle: string
  heroTitle: string
  heroDescription: string
  artCollectionNav: IStickyNavItem[]
  activePage: 'archive' | 'collection'
  artCollectionData: IArtCollection[]
  global: ISSRPageData['global']
}

export const CollectionsAndArchiveTemplate = ({
  global: { navigation },
  pageTitle,
  pathname,
  asPath,
  heroBgImageUrl,
  heroSubTitle,
  heroTitle,
  heroDescription,
  artCollectionNav,
  activePage,
  artCollectionData,
}: ICollectionsAndArchiveTemplate) => {
  return (
    <Layout title={pageTitle} navigation={navigation} path={asPath}>
      <Hero
        bgImageUrl={heroBgImageUrl}
        subTitle={heroSubTitle}
        title={heroTitle}
        description={heroDescription}
      />

      <Box as="main" pt={4}>
        <ArtCollectionNav activePath={pathname} navItems={artCollectionNav} />
        <ArtCollection.Section
          collection={artCollectionData}
          activePage={activePage}
        />
      </Box>
    </Layout>
  )
}
