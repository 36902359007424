import { createIcon } from '@chakra-ui/icons'

const Learn = createIcon({
  displayName: 'Learn',
  defaultProps: {
    width: 104,
    height: 263,
  },
  viewBox: '0 0 104 263',
  path: (
    <>
      <path
        d="M51.14 45.245l-.613-32.273c-.117-6.194 5.734-10.773 11.718-9.17 5.983 1.604 8.761 8.495 5.563 13.8L51.14 45.245zM59.289 52.752l24.085-21.489c4.623-4.124 11.909-2.668 14.59 2.917 2.682 5.584-.737 12.18-6.845 13.21l-31.83 5.362z"
        fill="#FA79F7"
      />
      <circle cx="43.793" cy="59.898" r="18.436" fill="#FA79F7" />
      <circle cx="43.792" cy="110.598" r="27.655" fill="#272A6E" />
      <circle cx="43.792" cy="155.768" r="27.655" fill="#272A6E" />
      <circle cx="43.792" cy="195.406" r="26.733" fill="#272A6E" />
      <path
        d="M.467 262.989h64.528l-4.058-16.579a16.947 16.947 0 00-16.462-12.919h-.276a16.507 16.507 0 00-14.853 9.303l-.483.996a14.741 14.741 0 01-13.262 8.308 14.74 14.74 0 00-12.287 6.596l-2.847 4.295z"
        fill="#345744"
      />
    </>
  ),
})

export default Learn
