export const SITE_URL =
  process.env.NEXT_PUBLIC_SITE_URL || 'https://test.sakipsabancimuzesi.org'
export const SUCCESS_PASSWORD = 'SUCCESS_PASSWORD'
export const eMUSEUM_PAGINATION_LIMIT = 100
export const HERO_ASPECT_RATIO = 2560 / 996
export const HERO_ASPECT_RATIO_MOBILE = 4 / 5
export const TWO_COL_SECTION_IMAGE_ASPECT_RATIO = 640 / 509
export const PUBLICATION_IMAGE_ASPECT_RATIO_ON_MODAL = 4 / 5
export const PUBLICATION_CHILD_IMAGE_ASPECT_RATIO_ON_MODAL = 1
export const ARTIST_IMAGE_ASPECT_RATIO = 4 / 5
export const ARTIST_DETAIL_IMAGE_ASPECT_RATIO = 4 / 5
