import { Box, BoxProps, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { Link, RichText } from '@ui'
import { ITwoColumnBlock, IFonts } from '@interfaces'
import { Link as ExternalLink } from '@icon'
import { v4 as uuidv4 } from 'uuid'

interface ITwoColumnBlockData {
  data: ITwoColumnBlock[]
  containerProps?: BoxProps
  stickyId?: string
  activeFontFamily?: IFonts
  bodyValue?: string
}

const TwoColumnBlock = ({
  data,
  containerProps,
  stickyId,
  activeFontFamily = 'body',
}: ITwoColumnBlockData) => {
  const cleanData = data && data.filter((d) => d !== null)
  return (
    <Box width="full" {...containerProps} {...(stickyId && { id: stickyId })}>
      {data &&
        cleanData?.length > 0 &&
        cleanData.map((row, i) => (
          <Box key={uuidv4()}>
            <Flex
              key={uuidv4()}
              flexDirection={{ base: 'column', lg: 'row' }}
              alignItems={{ base: 'flex-start', lg: 'flex-start' }}
            >
              <Flex
                flexDir="column"
                w="full"
                maxW={{ base: 'full', lg: '60' }}
                sx={{
                  '& > div + div': {
                    mt: '2.5',
                  },
                }}
              >
                {row.leftColTitleRow &&
                  row.leftColTitleRow.map((leftColTitle) => (
                    <Box key={uuidv4()}>
                      <Flex
                        flexDir="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Text
                          as="h6"
                          color="black.primary3"
                          fontSize="lg"
                          fontFamily={activeFontFamily}
                        >
                          {leftColTitle.leftColTitle}
                        </Text>

                        {leftColTitle.leftColRightSubTitle &&
                          leftColTitle.leftColRightSubTitle.length > 0 && (
                            <Flex flexDirection="column">
                              {leftColTitle.leftColRightSubTitle.map(
                                (subTitle) => (
                                  <Text
                                    key={uuidv4()}
                                    as="span"
                                    color="black.primary2"
                                    fontSize="sm"
                                    fontWeight="light"
                                    fontFamily={activeFontFamily}
                                  >
                                    {subTitle}
                                  </Text>
                                )
                              )}
                            </Flex>
                          )}
                      </Flex>
                    </Box>
                  ))}

                {row.leftColButtonUrl && row.leftColButtonLabel && (
                  <Button
                    as={Link}
                    target={row.leftColisExternelUrl ? '_blank' : '_self'}
                    href={row.leftColButtonUrl}
                    mt={4}
                    variant="secondary"
                    size="secondary"
                    fontFamily={activeFontFamily}
                    {...(row.leftColisExternelUrl && {
                      rightIcon: <ExternalLink color="white.primary" />,
                    })}
                  >
                    {row.leftColButtonLabel}
                  </Button>
                )}
              </Flex>

              <Box
                flex={{ base: 'auto', lg: '1' }}
                mt={{ base: 'padding.primary', lg: '0' }}
                pl={{ base: '0', lg: 20 }}
                alignSelf={{ base: 'flex-start', lg: 'center' }}
              >
                {row?.rightColHtmlContent && (
                  <RichText
                    htmlContent={row?.rightColHtmlContent}
                    fontFamily={activeFontFamily}
                  />
                )}
              </Box>
            </Flex>

            {cleanData.length !== i + 1 && (
              <Divider
                my="padding.primary"
                maxW="container.secondary"
                mx="auto"
                borderColor="white.primary3"
              />
            )}
          </Box>
        ))}
    </Box>
  )
}

export default TwoColumnBlock
