import { createIcon } from '@chakra-ui/icons'

const ListeningAudioGuide = createIcon({
  displayName: 'ListeningAudioGuide',
  viewBox: '0 0 75 70',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <g filter="url(#filter0_d)" fill="currentColor">
        <path d="M37.5 0C19.075 0 4.091 14.603 4.091 32.56v22.106h3.775V32.56C7.866 16.627 21.163 3.68 37.5 3.68S67.134 16.638 67.134 32.56v22.106h3.775V32.56C70.909 14.603 55.925 0 37.5 0z" />
        <path d="M21.37 36.194h-8.387c-1.205 0-2.192.593-2.192 2.717v19.411c0 2.125.976 2.717 2.192 2.717h8.386c1.205 0 2.192-.592 2.192-2.717V38.9c-.012-2.124-.987-2.706-2.192-2.706zM62.03 36.194h-8.387c-1.205 0-2.192.593-2.192 2.717v19.411c0 2.125.975 2.717 2.192 2.717h8.386c1.205 0 2.191-.592 2.191-2.717V38.9c0-2.124-.986-2.706-2.19-2.706z" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x=".091"
          y="0"
          width="74.818"
          height="69.04"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </>
  ),
})

export default ListeningAudioGuide
