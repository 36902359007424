import { Box, Button, ButtonProps, Flex, Link } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useStore } from '@store'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { MutableRefObject } from 'react'
import { IExhibitionSortName } from '@interfaces'
import { createNavLink } from '@utils'

interface IExhibitionAndEventSortButton {
  containerProps?: ButtonProps
  containerRef?: MutableRefObject<HTMLDivElement>
}

const ExhibitionAndEventSortButton = observer(
  ({ containerProps, containerRef }: IExhibitionAndEventSortButton) => {
    const router = useRouter()
    const {
      exhibitionAndEvents: {
        activeCollection,
        setSortResult,
        setPage,
        toggleDisplaySortModal,
      },
    } = useStore()
    const { t } = useTranslation()

    const handleOnClickSortButton = (sortVal: IExhibitionSortName) => {
      const activeSort = activeCollection === 'online' ? 'current' : sortVal
      const query = router.query
      query.sort = activeSort
      query.page = '1'

      setPage('1')
      setSortResult(activeSort)
      toggleDisplaySortModal()

      router.push(
        {
          pathname: createNavLink('exhibitionAndEvent', router.locale),
          query: query,
        },
        undefined,
        { scroll: false }
      )

      const yCoordinate =
        containerRef?.current?.getBoundingClientRect().top + window.pageYOffset
      const yOffset = -40
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
    }

    return (
      <Box>
        <Flex gap="2">
          <Button
            onClick={() => handleOnClickSortButton('current')}
            fontSize="sm"
            color="white.primary3"
            display="flex"
            alignItems="center"
            {...containerProps}
          >
            {t('nav:currentExhibitions')}
          </Button>
          <Button
            onClick={() => handleOnClickSortButton('past')}
            fontSize="sm"
            color="white.primary3"
            display="flex"
            alignItems="center"
            {...containerProps}
          >
            {t('nav:pastExhibitions')}
          </Button>
          {router.query.activeCollection != 'events' && (
            <Button
              as={Link}
              href={`${createNavLink('onlineExhibitionFilter', router.locale)}`}
              fontSize="sm"
              color="white.primary3"
              display="flex"
              alignItems="center"
              {...containerProps}
            >
              {t('nav:onlineExhibitions')}
            </Button>
          )}
        </Flex>
      </Box>
    )
  }
)

export default ExhibitionAndEventSortButton
