import { Container, Box, BoxProps, Button, Text } from '@chakra-ui/react'
import { IDrupalLink, IFonts } from '@interfaces'
import { Link, RichText } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { ShowMore } from '@common'

interface IDataSectionWithCustomHtml {
  link: IDrupalLink
  htmlContent: string
}

interface ISectionWithCustomHtml {
  stickyId: string
  sectionTitle: string
  data: IDataSectionWithCustomHtml
  containerProps?: BoxProps
  isWithShowMoreHtmlContent?: boolean
  activeFontFamily?: IFonts
}

const SectionWithCustomHtml = ({
  stickyId = 'section-with-custom-html',
  sectionTitle,
  data,
  containerProps,
  isWithShowMoreHtmlContent = false,
  activeFontFamily = 'body',
}: ISectionWithCustomHtml) => {
  const { t } = useTranslation()

  const { htmlContent, link } = data || {}

  return (
    <Box
      as="section"
      id={stickyId}
      pt={{ base: '20px', lg: 0 }}
      pb={{ base: '20px', lg: '20px' }}
      {...containerProps}
    >
      {sectionTitle && (
        <Container>
          <Text
            color="red.primary"
            fontFamily={activeFontFamily}
            fontSize={{ base: '2xl', lg: '3xl' }}
          >
            {sectionTitle}
          </Text>
        </Container>
      )}
      <Container
        maxW="container.secondary"
        mx="auto"
        mt={{ base: 'padding.primary', lg: 'padding.primary2' }}
      >
        {htmlContent && isWithShowMoreHtmlContent ? (
          <ShowMore isOnlyMobileWork={false} startingHeight={100}>
            <RichText htmlContent={htmlContent} fontFamily={activeFontFamily} />
          </ShowMore>
        ) : (
          <RichText htmlContent={htmlContent} fontFamily={activeFontFamily} />
        )}
        {link && (
          <Button
            as={Link}
            target={link?.isExternalLink ? '_blank' : undefined || '_blank'}
            href={
              link?.linkUrl ||
              'https://www.sakipsabancimuzesi.org/bilet/rezervasyon-formu/'
            }
            mt={4}
            variant={
              link?.linkVariant === 'secondary'
                ? 'white'
                : 'secondary' || 'secondary'
            }
            size="secondary"
            maxWidth="300px"
            fontFamily={activeFontFamily}
          >
            {link?.linkLabel || t('common:goToWebsite')}
          </Button>
        )}
      </Container>
    </Box>
  )
}

export default SectionWithCustomHtml
