/* eslint-disable react/display-name */
import { Select as ChakraSelect, SelectProps } from '@chakra-ui/react'
import { ChevronDown } from '@icon'
import { forwardRef } from 'react'

type ISelect = SelectProps
type Ref = HTMLSelectElement

const Select = forwardRef<Ref, ISelect>((props, ref) => {
  const { children, ...rest } = props
  return (
    <ChakraSelect
      ref={ref}
      icon={<ChevronDown color="grey.primary" fontSize="md" />}
      {...rest}
    >
      {children}
    </ChakraSelect>
  )
})

export default Select
