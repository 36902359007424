import { AspectRatio, Box } from '@chakra-ui/react'

interface IThreeDTourEmbedVideo {
  stickyId: string
  embedUrl: string
}

const ThreeDTourEmbedVideo = ({
  stickyId,
  embedUrl,
}: IThreeDTourEmbedVideo) => {
  return (
    <Box
      id={stickyId}
      as="section"
      height={{ base: '210px', lg: '720px' }}
      bgColor="black.primary4"
    >
      <AspectRatio ratio={16 / 9} width="full" height="full">
        <iframe
          title="3D Tour Video | SSM"
          src={embedUrl}
          allowFullScreen
          frameBorder="0"
        />
      </AspectRatio>
    </Box>
  )
}

export default ThreeDTourEmbedVideo
