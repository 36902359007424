import { createIcon } from '@chakra-ui/icons'

const ChevronLeft = createIcon({
  displayName: 'ChevronLeft',
  viewBox: '0 0 16 16',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <path
      d="M8.69531 0.910156L1.62424 7.98122L8.69531 15.0523"
      stroke="currentColor"
      strokeWidth="2"
    />
  ),
})

export default ChevronLeft
