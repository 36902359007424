export const Input = {
  variants: {
    secondary: {
      field: {
        fontSize: 'sm',
        borderRadius: 'none',
        fontWeight: 'medium',
        color: 'black.primary3',
        _placeholder: {
          fontWeight: 'medium',
          color: 'grey.primary2',
        },
      },
    },
  },
  sizes: {
    secondary: {
      field: {
        h: '50px',
      },
    },
  },
  defaultProps: {
    variant: 'flushed',
  },
}
