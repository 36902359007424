import { Box, Button, Divider, Text, Image } from '@chakra-ui/react'
import { IPressReleaseCard } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import { Link } from '@ui'
import { Pdf } from '@icon'
import { DownloadIcon } from '@chakra-ui/icons'

const PressReleaseCard = ({
  date,
  title,
  displayTitle,
  bannerImage,
  //shortDescription,
  onClick,
  isLastItem = false,
  pdfUrl,
  pdfUrlStd,
  url,
}: IPressReleaseCard) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box>
        <Text
          mb="2.5"
          color="grey.primary"
          fontSize="sm"
          fontWeight="light"
          as="h5"
        >
          {date}
        </Text>
        <Image src={bannerImage} alt={title} />
        <Box height="120px">
          <Link
            href={url}
            _hover={{
              '& > *': {
                textDecoration: 'underline',
              },
            }}
          >
            <Text mt="0.5" mb="3.5" fontSize="lg" as="h4">
              {displayTitle}
            </Text>
          </Link>
        </Box>
        {pdfUrlStd !== '' && (
          <Button
            as={Link}
            href={pdfUrlStd}
            width={{ base: '100%', lg: '47%' }}
            height="50px"
            variant="secondary"
            leftIcon={<Pdf w={6} h={6} />}
            mr={4}
            mb={4}
          >
            {t('common:downloadPr')}
          </Button>
        )}
        {pdfUrl !== '' && (
          <Button
            onClick={onClick}
            width={{ base: '100%', lg: '47%' }}
            height="50px"
            variant="secondary"
            leftIcon={<DownloadIcon w={6} h={6} />}
            mb={4}
          >
            {t('common:downloadHires')}
          </Button>
        )}
      </Box>
      {!isLastItem && (
        <Divider
          borderBottomColor="white.primary3"
          opacity="1"
          my="padding.primary"
        />
      )}
    </Box>
  )
}

export default PressReleaseCard
