/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { EventHttpService } from '@httpServices'
import { IEventCard, ISortNameOfEvent, IMainTypeOfEvent } from '@interfaces'
import {
  createNavLink,
  convertEMuseumMediaLinks,
  generateStickyNavItem,
  getArtistsDataForExhibitionDetailPage,
  getEventTypesFromAPI,
  getCurrentLocalePropFromAPI,
  removeDuplicateImgUrls,
} from '@utils'
import { ExtendedEMuseumService } from '@services'

export const EventService = {
  /**
   * Get all event
   */
  getAllEvent: async (
    mainType: IMainTypeOfEvent,
    locale: string
  ): Promise<IEventCard[]> => {
    const collection = await EventHttpService.getAllEvent(mainType)

    return await Promise.all(
      collection.events.map(async (d) => {
        const sourceId = d.sourceId?.value

        /* extended e-mueseum data - start */
        const extendedEMuseumData =
          await ExtendedEMuseumService.getExtendedEMuseum(
            locale,
            `event-${parseInt(sourceId, 10)}`,
            'event'
          )

        return {
          id: d.sourceId?.value || '',
          type: 'event',
          tagsTx:
            (d?.eventType && getEventTypesFromAPI(d?.eventType?.value)) || [],
          title:
            (d.eventName &&
              getCurrentLocalePropFromAPI(d.eventName?.value, locale)) ||
            '',
          date:
            (d.displayDate &&
              getCurrentLocalePropFromAPI(d.displayDate?.value, locale)) ||
            '',
          imgUrl:
            (extendedEMuseumData && extendedEMuseumData?.cardImage) ||
            d.primaryMedia?.value ||
            '',
          url:
            `${createNavLink(
              mainType === 'Learning'
                ? 'learning'
                : mainType === 'Workshop'
                ? 'workshop'
                : 'event',
              locale
            )}/${d.sourceId?.value}` || '#',
        }
      })
    )
  },
  /**
   * Get event detail
   */
  getEventDetail: async (id: string) => {
    const collection = await EventHttpService.getEventDetail(id)
    return collection
  },
  /**
   * Get event for detail page
   */
  getEventDetailPage: async (
    id: string,
    locale: string,
    // mainType is subTitle types. for example, https://emuseum.sakipsabancimuzesi.org/events/json?filter=subTitle:Event or https://emuseum.sakipsabancimuzesi.org/events/json?filter=subTitle:Learning
    mainType?: IMainTypeOfEvent = 'Event'
  ) => {
    const collection = await EventHttpService.getEventDetail(id)
    const pageProps = {
      heroCard: {
        title: '',
        date: '',
        bgImgUrl: '',
        mobileBgImgUrl: '',
      },
      eventAbout: {
        stickyTitle: '',
        description: '',
      },
      contentOwner: {
        name: '',
        description: '',
      },
      otherEvents: [],
      stickyNavItems: [],
      artists: [],
      googleArtLink: null,
      threeDTourEmbedLink: null,
      pressRelease: null,
      photosAndVideos: null,
      products: null,
    }

    if (collection?.event.length > 0) {
      const eventTitle =
        (collection?.event[0]?.eventName &&
          getCurrentLocalePropFromAPI(
            collection?.event[0]?.eventName?.value,
            locale
          )) ||
        ''

      const eventDesc =
        (collection?.event[0]?.description &&
          getCurrentLocalePropFromAPI(
            collection?.event[0]?.description?.value,
            locale
          )) ||
        ''

      const eventDate =
        (collection?.event[0]?.displayDate &&
          getCurrentLocalePropFromAPI(
            collection?.event[0]?.displayDate.value,
            locale
          )) ||
        ''

      pageProps.heroCard.title = eventTitle

      pageProps.heroCard.date = eventDate

      pageProps.heroCard.bgImgUrl =
        (collection?.event[0]?.primaryMedia &&
          collection?.event[0]?.primaryMedia?.value) ||
        ''

      pageProps.eventAbout.stickyTitle = eventTitle

      pageProps.eventAbout.description = eventDesc

      // Prepare artist of event
      const initArtists =
        (collection?.event[0]?.people &&
          getArtistsDataForExhibitionDetailPage(
            collection?.event[0]?.people?.value
          )) ||
        []
      pageProps.artists = [...initArtists]

      pageProps.otherEvents = (await EventService.getAllEvent(mainType, locale))
        .filter((col) => col.id !== id)
        .slice(0, 10)

      const stickyNavItems = []

      // check empty control for aboutTheEvent sticky nav item
      pageProps.eventAbout.stickyTitle !== '' &&
        stickyNavItems.push(
          generateStickyNavItem(
            mainType === 'Event'
              ? 'aboutTheEvent'
              : mainType === 'Workshop'
              ? 'aboutTheWorkshop'
              : 'aboutTheProgram'
          )
        )

      /* extended e-mueseum data - start */
      const extendedEMuseumData =
        await ExtendedEMuseumService.getExtendedEMuseum(
          locale,
          `event-${parseInt(collection?.event[0]?.sourceId?.value, 10)}`,
          mainType === 'Event' ? 'event' : 'learning'
        )

      // banner image url
      if (extendedEMuseumData.bannerImage) {
        pageProps.heroCard.bgImgUrl = extendedEMuseumData.bannerImage
      }

      // mobile banner image url
      if (extendedEMuseumData.mobileBannerImage) {
        pageProps.heroCard.mobileBgImgUrl =
          extendedEMuseumData.mobileBannerImage
      }

      // products
      if (extendedEMuseumData.products) {
        pageProps.products = extendedEMuseumData.products
        stickyNavItems.push(generateStickyNavItem('daysAndTickets'))
      }

      // prepare photos and videos (concat exhibition and extended e museum data)
      let photoAndVideos = []
      const eMuseumPhotos =
        (collection?.event[0]?.media &&
          convertEMuseumMediaLinks(
            collection?.event[0]?.media.value,
            eventTitle
          )) ||
        []

      photoAndVideos = [...eMuseumPhotos]

      if (extendedEMuseumData.photosAndVideos) {
        photoAndVideos = [
          ...photoAndVideos,
          ...extendedEMuseumData.photosAndVideos,
        ]
      }

      // remove hero image for on extended drupal img url
      photoAndVideos = removeDuplicateImgUrls(
        photoAndVideos,
        pageProps.heroCard.bgImgUrl
      )

      // remove mobile banner image for on extended drupal img url
      photoAndVideos = removeDuplicateImgUrls(
        photoAndVideos,
        pageProps.heroCard.mobileBgImgUrl
      )

      // remove primary value on e-museum for photo and video module
      photoAndVideos = removeDuplicateImgUrls(
        photoAndVideos,
        collection?.event[0]?.primaryMedia?.value
      )

      if (photoAndVideos.length > 0) {
        pageProps.photosAndVideos = photoAndVideos
        stickyNavItems.push(generateStickyNavItem('photosAndVideos'))
      }

      // 3d tour
      if (extendedEMuseumData.threeDTourEmbedLink) {
        pageProps.threeDTourEmbedLink = extendedEMuseumData.threeDTourEmbedLink
        stickyNavItems.push(generateStickyNavItem('threeDTour'))
      }

      // google art links
      if (extendedEMuseumData.googleArtLink) {
        pageProps.googleArtLink = extendedEMuseumData.googleArtLink
      }

      // press release
      if (extendedEMuseumData.pressRelease) {
        pageProps.pressRelease = extendedEMuseumData.pressRelease
      }
      /* extended e-mueseum data - end */

      // check empty control for other events or other learnings sticky nav item
      pageProps.otherEvents.length &&
        stickyNavItems.push(
          generateStickyNavItem(
            mainType === 'Event'
              ? 'otherEvents'
              : mainType === 'Workshop'
              ? 'otherWorkshops'
              : 'otherLearningPrograms'
          )
        )

      pageProps.stickyNavItems = [...stickyNavItems]
    }

    return pageProps
  },
  /**
   * Get events by sort
   */
  getEventsBySortName: async (
    locale: string,
    mainType: IMainTypeOfEvent,
    sortType: ISortNameOfEvent,
    params?: string
  ) => {
    const collection = await EventHttpService.getEventsBySort(
      mainType,
      sortType,
      params
    )

    const collectionData = {
      data: [],
      totalCount: 0,
    }

    const concatCollections = await Promise.all(
      (collectionData.data = collection.events.map(async (d) => {
        const sourceId = d.sourceId?.value

        // check card image url on extended e-museum
        const extendedEMuseumData =
          await ExtendedEMuseumService.getExtendedEMuseum(
            locale,
            `event-${parseInt(sourceId, 10)}`,
            'event'
          )

        return {
          id: sourceId || '',
          type: mainType === 'Learning' ? 'learning' : 'event',
          tagsTx:
            (d?.eventType && getEventTypesFromAPI(d?.eventType?.value)) || [],
          title:
            (d.eventName &&
              getCurrentLocalePropFromAPI(d.eventName?.value, locale)) ||
            '',
          date:
            (d.displayDate &&
              getCurrentLocalePropFromAPI(d.displayDate?.value, locale)) ||
            '',
          imgUrl:
            (extendedEMuseumData && extendedEMuseumData?.cardImage) ||
            d.primaryMedia?.value ||
            '',
          url:
            `${createNavLink(
              mainType === 'Learning' ? 'learning' : 'event',
              locale
            )}/${d.sourceId?.value}` || '#',
        }
      }))
    )

    collectionData.data = concatCollections
    collectionData.totalCount = collection.count

    return collectionData
  },

  /**
   * Get workshops by sort
   */
  getWorkshopsBySortName: async (
    locale: string,
    sortType: ISortNameOfEvent,
    params?: string
  ) => {
    const collection = await EventHttpService.getWorkshopsBySort(
      sortType,
      params
    )

    const collectionData = {
      data: [],
      totalCount: 0,
    }

    const concatCollections = await Promise.all(
      (collectionData.data = collection.events.map(async (d) => {
        const sourceId = d.sourceId?.value

        // check card image url on extended e-museum
        const extendedEMuseumData =
          await ExtendedEMuseumService.getExtendedEMuseum(
            locale,
            `event-${parseInt(sourceId, 10)}`,
            'event'
          )

        return {
          id: sourceId || '',
          type: 'event',
          tagsTx:
            (d?.eventType && getEventTypesFromAPI(d?.eventType?.value)) || [],
          title:
            (d.eventName &&
              getCurrentLocalePropFromAPI(d.eventName?.value, locale)) ||
            '',
          date:
            (d.displayDate &&
              getCurrentLocalePropFromAPI(d.displayDate?.value, locale)) ||
            '',
          imgUrl:
            (extendedEMuseumData && extendedEMuseumData?.cardImage) ||
            d.primaryMedia?.value ||
            '',
          url:
            `${createNavLink('workshop', locale)}/${d.sourceId?.value}` || '#',
        }
      }))
    )

    collectionData.data = concatCollections
    collectionData.totalCount = collection.count

    return collectionData
  },
  /**
   * Get related events by event source ids
   */
  getRelatedEventsByIds: async (
    sourceIds: string[],
    locale: string
  ): Promise<IEventCard[]> => {
    const relatedEvents = await Promise.all(
      sourceIds.map(async (id) => {
        const eventDetail = await EventHttpService.getEventDetail(id)
        const sourceId = eventDetail.event[0].sourceId?.value

        // check card image url on extended e-museum
        const extendedEMuseumData =
          await ExtendedEMuseumService.getExtendedEMuseum(
            locale,
            `event-${parseInt(sourceId, 10)}`,
            'event'
          )

        return {
          id: sourceId || '',
          type: 'event',
          tagsTx:
            (eventDetail.event[0].eventType &&
              getEventTypesFromAPI(eventDetail.event[0].eventType?.value)) ||
            [],
          title:
            (eventDetail.event[0].eventName &&
              getCurrentLocalePropFromAPI(
                eventDetail.event[0].eventName?.value,
                locale
              )) ||
            '',
          date:
            (eventDetail.event[0].displayDate &&
              getCurrentLocalePropFromAPI(
                eventDetail.event[0].displayDate?.value,
                locale
              )) ||
            '',
          imgUrl:
            (extendedEMuseumData && extendedEMuseumData?.cardImage) ||
            eventDetail.event[0].primaryMedia?.value ||
            '',
          url:
            `${createNavLink('event', locale)}/${
              eventDetail.event[0].sourceId?.value
            }` || '#',
        }
      })
    )

    return relatedEvents
  },
  /**
   * Get conservation events
   */
  getConservationEvents: async (locale) => {
    const conservationEvents = await EventHttpService.getConservationEvents()

    return await Promise.all(
      conservationEvents.events.map(async (d) => {
        const sourceId = d.sourceId?.value

        /* extended e-mueseum data - start */
        const extendedEMuseumData =
          await ExtendedEMuseumService.getExtendedEMuseum(
            locale,
            `event-${parseInt(sourceId, 10)}`,
            'event'
          )

        return {
          id: d.sourceId?.value || '',
          type: 'event',
          tagsTx:
            (d?.eventType && getEventTypesFromAPI(d?.eventType?.value)) || [],
          title:
            (d.eventName &&
              getCurrentLocalePropFromAPI(d.eventName?.value, locale)) ||
            '',
          date:
            (d.displayDate &&
              getCurrentLocalePropFromAPI(d.displayDate?.value, locale)) ||
            '',
          imgUrl:
            (extendedEMuseumData && extendedEMuseumData?.cardImage) ||
            d.primaryMedia?.value ||
            '',
          url: `${createNavLink('event', locale)}/${d.sourceId?.value}` || '#',
        }
      })
    )
  },
  getAllEventSlugsByType: async (
    mainType: IMainTypeOfEvent
  ): Promise<string[]> => {
    const collection = await EventHttpService.getAllEvent(mainType)

    return collection.events.map((d) => d.sourceId?.value)
  },
}
