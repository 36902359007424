import { Box, Container, Text } from '@chakra-ui/react'
import { ArtistPhotoSlider } from '@common'
import { Link, RichText } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { IAboutTheArtistExhibitionAndEvent } from '@interfaces'

const AboutTheArtistExhibitionAndEvent = ({
  stickyId,
  artistName,
  artistLifeTimeRange,
  artistLife,
  artistQuote,
  artistLifeAboutPdfLink,
  artistPhotos,
  activeFontFamily = 'body',
}: IAboutTheArtistExhibitionAndEvent) => {
  const { t } = useTranslation()

  return (
    <Box
      id={stickyId}
      pt={{ base: '200px', lg: '300px' }}
      pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      bgColor="white.primary2"
      position="relative"
    >
      <ArtistPhotoSlider
        artistPhotos={artistPhotos}
        artistName={artistName}
        activeFontFamily={activeFontFamily}
      />
      <Container>
        <Text
          color="red.primary"
          fontFamily={activeFontFamily}
          fontSize={{ base: '2xl', lg: '3xl' }}
          mb="5"
        >
          {artistName}
        </Text>

        <Text color="grey.primary" fontFamily={activeFontFamily} fontSize="sm">
          {artistLifeTimeRange}
        </Text>

        <Box mt="padding.primary" mx="auto" maxW="container.secondary">
          <RichText
            htmlContent={artistLife}
            mb="2.5"
            fontSize="md"
            fontFamily={activeFontFamily}
          />
          <Link
            href={artistLifeAboutPdfLink}
            target="_blank"
            fontWeight="medium"
            fontFamily={activeFontFamily}
            color="red.primary"
            display="inline-flex"
            alignItems="center"
            position="relative"
            transition="all 0.3s ease"
            sx={{
              '&:hover': {
                color: 'red.primary3',
                _before: {
                  bgColor: 'red.primary3',
                },
              },
            }}
            _before={{
              content: `""`,
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
              width: '100%',
              height: '2px',
              bgColor: 'red.primary',
              transition: 'all 0.3s ease',
            }}
          >
            {t(
              'exhibition-and-event:eventAndExhibitionDetail.lifeAndArtAboutLabel',
              { artistName: artistName }
            )}
          </Link>

          {artistQuote && artistQuote !== '' && (
            <Box mt="12">
              <Text
                fontStyle="italic"
                fontFamily={activeFontFamily}
                fontSize="lg"
              >
                {artistQuote}
              </Text>
              <Text
                fontStyle="italic"
                fontFamily={activeFontFamily}
                fontSize="lg"
              >
                {artistName}
              </Text>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  )
}

export default AboutTheArtistExhibitionAndEvent
