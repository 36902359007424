import { Text, AspectRatio, Image, Button, Box } from '@chakra-ui/react'
import { IDaysAndTicketsCard } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import { Link } from '@ui'
import { Markup } from 'interweave'

const SsmShopSliderCard = ({
  price,
  productName,
  productDetails,
  productPhoto,
  purchaseLink,
  stock,
}: IDaysAndTicketsCard) => {
  const { t } = useTranslation()
  return (
    <Box>
      <AspectRatio
        ratio={4 / 5}
        mb="2.5"
        width="full"
        sx={{
          '&>img': {
            objectFit: 'contain',
          },
        }}
      >
        <Image
          loading="lazy"
          src={productPhoto}
          fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
          alt={productName}
        />
      </AspectRatio>
      <Text fontWeight="medium" as="h6">
        {productName}
      </Text>
      <Box fontWeight="light" fontSize="sm" maxHeight="44px" overflowY="auto">
        <Markup content={productDetails} />
      </Box>
      <Text
        fontWeight="light"
        fontSize="sm"
        color="grey.primary"
        isTruncated
        noOfLines={3}
      >
        {price === 0 ? t('common:free') : `${price} ₺`}
      </Text>
      {purchaseLink && (
        <Box cursor={!stock ? 'not-allowed' : 'pointer'}>
          <Button
            as={Link}
            href={purchaseLink}
            target="_blank"
            variant="link"
            minWidth="auto"
            disabled={!stock ? true : false}
            pointerEvents={!stock ? 'none' : 'auto'}
            _disabled={{
              background: 'transparent',
            }}
          >
            {`${t('common:buyButtonLabel')}${
              !stock ? ' (' + t('common:soldOut') + ')' : ''
            }`}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default SsmShopSliderCard
