import drupalHttpService from '../drupalHttpService'
import { SELECTIONS_FROM_COLLECTION } from '@lib/const/api-endpoints.const'

/**
 * Selections from collection Http Service
 */
export const SelectionsFromCollectionsHttpService = {
  /**
   * Get Selections from collection detail drupalHttpService service
   */
  getSelectionsFromCollection: async (locale: string) =>
    await drupalHttpService.get(SELECTIONS_FROM_COLLECTION[locale]),
}
