import {
  createNavLink,
  checkStickyNavItem,
  getEventTypesFromAPI,
  getCurrentLocalePropFromAPI,
  parseISODate,
} from '@/src/utils'
import { ResearchAreaHttpService } from '@httpServices'
import {
  IAccordionCollection,
  IPageTab,
  IStickyNav,
  IEventCard,
  IPublicationCard,
  INewsAndBlogCard,
  ITwoColumnBlock,
} from '@interfaces'
import { v4 as uuidv4 } from 'uuid'
import { PageService } from '../'
import COMMON_TR from '@/locales/tr/common.json'
import COMMON_EN from '@/locales/en/common.json'
import { PublicationsService } from '@services'

export const ResearchAreaService = {
  getAllProjectsAndSwDevs: async (locale: string) => {
    const allProjectsAndSwDevs =
      await ResearchAreaHttpService.getAllProjectsAndSwDevs(locale)

    return allProjectsAndSwDevs
  },
  /**
   * Get all projects on research area page
   */
  getProjects: async (locale: string): Promise<IAccordionCollection[]> => {
    const projects = await ResearchAreaHttpService.getProjects(locale)

    return projects.map((project) => ({
      id: project?.nid[0]?.value || uuidv4(),
      accordionTitle: project?.title[0]?.value || '',
      accordionHtmlContent: `<div><img src="${
        project?.field_page_banner_image[0]?.url || ''
      }" alt="${project?.field_page_banner_image[0]?.alt || ''}" /><p>${
        project?.field_rd_project_listing_text[0]?.value || ''
      }</p></div>`,
      internelLink:
        `${createNavLink('archiveAndResearchArea', locale)}/${
          project?.nid[0]?.value
        }` || null,
      externalLink: project?.field_page_banner_external_url[0]?.value || null,
    }))
  },
  /**
   * Get all software deve on research area page
   */
  getSoftwareDev: async (locale: string): Promise<IAccordionCollection[]> => {
    const rows = await ResearchAreaHttpService.getSoftwareDev(locale)

    return rows.map((r) => ({
      id: r?.nid[0]?.value || uuidv4(),
      accordionTitle: r?.title[0]?.value || '',
      accordionHtmlContent: `<div><img src="${
        r?.field_page_banner_image[0]?.url || ''
      }" alt="${r?.field_page_banner_image[0]?.alt || ''}" /><p>${
        r?.field_rd_project_listing_text[0]?.value || ''
      }</p></div>`,
      internelLink:
        `${createNavLink('archiveAndResearchArea', locale)}/${
          r?.nid[0]?.value
        }` || null,
      externalLink: r?.field_page_banner_external_url[0]?.value || null,
    }))
  },
  /**
   * Get paths of all archive and research detail pages
   */
  getPathsOfResearchDetailPages: async (locale: string) => {
    const allDetailPageData = await ResearchAreaService.getAllProjectsAndSwDevs(
      locale
    )

    const paths = []

    allDetailPageData.map((data) => {
      if (data?.nid && data?.nid.length > 0 && data?.nid[0]?.value !== '') {
        paths.push({ params: { slug: data?.nid[0]?.value.toString() } })
      }
    })

    return paths
  },
  /**
   * Get research area detail page
   */
  getResearchDetail: async (locale: string, slug: string) => {
    const detailPageData = await ResearchAreaHttpService.getResearchDetail(
      locale,
      slug
    )

    return detailPageData
  },
  /**
   * Get archive and research area detail page data
   */
  getArchiveAndResearchAreaDetailPageData: async (
    locale: string,
    slug: string
  ) => {
    const pageData = {
      data: {
        pageTitle: '',
        hero: {
          bgImageUrl: '',
          subTitle: '',
          title: '',
          description: '',
        },
        stickyNavItems: null,
        projectDescription: null,
        rdEvents: null,
        rdPublications: null,
        rdCSs: null,
        rdProjectTeam: null,
        rdProjectPartners: null,
        data: null,
      },
      error: null,
    }

    try {
      const researchDetail = await ResearchAreaService.getResearchDetail(
        locale,
        slug
      )

      if (researchDetail && researchDetail.length > 0) {
        const cleanResearchDetailData = researchDetail[0]
        pageData.data.data = cleanResearchDetailData

        // prepare hero and page title
        const heroAndPageTitle = await PageService.preparePageTitleHeroAnd(
          cleanResearchDetailData
        )

        // preparing hero and page title
        if (heroAndPageTitle) {
          pageData.data.pageTitle = heroAndPageTitle?.pageTitle
          pageData.data.hero = heroAndPageTitle?.hero
        }

        // preparing dynamic tabs
        const tabIds = cleanResearchDetailData.field_rd_tab.map(
          (t) => t.target_id
        )

        // get tabs of page initial data
        const initialTabsOfPage = await ResearchAreaService.getTabsOfPage(
          locale,
          tabIds
        )

        // prepare sticky nav items
        const preparedStickyNavItems =
          await ResearchAreaService.prepareStickyNavItems(initialTabsOfPage)
        pageData.data.stickyNavItems = preparedStickyNavItems

        // prepare section data
        if (preparedStickyNavItems) {
          // prepare projectDescription section data
          const hasProjectDescription = checkStickyNavItem(
            preparedStickyNavItems,
            'projectDescription'
          )

          if (hasProjectDescription) {
            const data = {
              link: null,
              htmlContent: null,
            }

            const link =
              cleanResearchDetailData?.field_page_banner_external_url[0]
                ?.value || null
            const htmlContent = cleanResearchDetailData?.body[0]?.value || ''

            if (link) {
              data.link = {
                isExternalLink: true,
                linkLabel:
                  locale === 'tr'
                    ? COMMON_TR.goToWebsite
                    : COMMON_EN.goToWebsite,
                linkUrl: link,
                linkVariant: 'primary',
              }
            }

            if (htmlContent) {
              data.htmlContent = htmlContent
            }

            pageData.data.projectDescription = data
          }

          // prepare events section data ( rdEvents )
          const hasEvents = checkStickyNavItem(
            preparedStickyNavItems,
            'rdEvents'
          )

          if (hasEvents) {
            if (
              cleanResearchDetailData?.field_rd_event &&
              cleanResearchDetailData?.field_rd_event.length
            ) {
              const eventIds = cleanResearchDetailData?.field_rd_event.map(
                (event) => event?.target_id
              )

              const eventsData = await ResearchAreaService.getEvents(
                locale,
                eventIds
              )

              if (eventsData) {
                pageData.data.rdEvents = eventsData.filter(
                  (event) => event !== null
                )
              }
            }
          }

          // prepare publications section data
          const hasPublications = checkStickyNavItem(
            preparedStickyNavItems,
            'rdPublications'
          )

          if (hasPublications) {
            if (
              cleanResearchDetailData?.field_rd_publication &&
              cleanResearchDetailData?.field_rd_publication.length
            ) {
              const pIds = cleanResearchDetailData?.field_rd_publication.map(
                (publication) => publication?.target_id
              )

              const publications = await ResearchAreaService.getPublications(
                locale,
                pIds
              )

              if (publications && publications.length > 0) {
                pageData.data.rdPublications = publications.filter(
                  (a) => a != null
                )
              }
            }
          }

          // prepare case studies section data
          const hasCaseStudies = checkStickyNavItem(
            preparedStickyNavItems,
            'rdCSs'
          )

          if (hasCaseStudies) {
            if (
              cleanResearchDetailData?.field_rd_cs &&
              cleanResearchDetailData?.field_rd_cs.length
            ) {
              const csIds = cleanResearchDetailData?.field_rd_cs.map(
                (cs) => cs?.target_id
              )

              const caseStudies = await ResearchAreaService.getCaseStudies(
                locale,
                slug,
                csIds
              )

              if (caseStudies && caseStudies.length > 0) {
                pageData.data.rdCSs = caseStudies
              }
            }
          }

          // prepare teams section data
          const hasProjectTeam = checkStickyNavItem(
            preparedStickyNavItems,
            'rdProjectTeam'
          )
          if (hasProjectTeam) {
            if (
              cleanResearchDetailData?.field_rd_project_team &&
              cleanResearchDetailData?.field_rd_project_team.length
            ) {
              const pIds = cleanResearchDetailData?.field_rd_project_team.map(
                (project) => project?.target_id
              )

              const projects = await ResearchAreaService.getProjectTeams(
                locale,
                pIds
              )

              if (projects && projects.length > 0) {
                pageData.data.rdProjectTeam = projects
              }
            }
          }

          // prepare partners section data
          const hasPartners = checkStickyNavItem(
            preparedStickyNavItems,
            'rdProjectPartners'
          )

          if (hasPartners) {
            if (
              cleanResearchDetailData?.field_rd_partner &&
              cleanResearchDetailData?.field_rd_partner.length
            ) {
              const partnerIds = cleanResearchDetailData?.field_rd_partner.map(
                (project) => project?.target_id
              )

              const partners = await ResearchAreaService.getPartners(
                locale,
                partnerIds
              )

              if (partners && partners.length > 0) {
                pageData.data.rdProjectPartners = partners
              }
            }
          }
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  getTabsOfPage: async (
    locale: string,
    tabIds: string[]
  ): Promise<IPageTab[]> => {
    const initTabsOfPage = await Promise.all(
      tabIds.map(async (id) => {
        const tabOfPage = await ResearchAreaService.getTabOfPage(locale, id)

        return tabOfPage
      })
    )

    return initTabsOfPage
  },
  /**
   * Get tab of page drupalHttp service
   */
  getTabOfPage: async (locale: string, id: string) => {
    const tabOfPageCategory = await ResearchAreaHttpService.getTabOfPage(
      locale,
      id
    )

    return tabOfPageCategory
  },
  prepareStickyNavItems: async (
    initTabsOfPage: IPageTab[]
  ): Promise<IStickyNav[]> => {
    const tabsOfTour = initTabsOfPage.map((tab) => ({
      id: tab[0]?.nid[0]?.value || '',
      navTitleTx: tab[0]?.title[0]?.value || '',
      href: tab[0]?.field_rd_tab_id[0]?.value || '',
      titleIsWithLocalizationOnClient: false,
      rank: 0,
      contentType: 'custom',
      __component: 'custom',
    }))

    const stickyNavItems = tabsOfTour.sort((a, b) => a.rank - b.rank)

    return stickyNavItems.filter((nav) => nav.href !== '')
  },
  /**
   * Get events of research area detail
   */
  getEvents: async (
    locale: string,
    eventIds: string[]
  ): Promise<IEventCard[]> => {
    const events = await Promise.all(
      eventIds.map(async (id) => {
        const tabOfPage = await ResearchAreaService.getEvent(locale, id)

        return tabOfPage
      })
    )

    return events
  },
  /**
   * Get events of research area detail
   */
  getEvent: async (locale: string, eventId): Promise<IEventCard> => {
    const eventDetail = await ResearchAreaHttpService.getEvent(locale, eventId)

    if (eventDetail && eventDetail.length > 0) {
      return {
        id:
          (eventDetail[0]?.field_event_em_source_id &&
            eventDetail[0]?.field_event_em_source_id[0]?.value) ||
          '',
        type: 'event',
        tagsTx:
          (eventDetail[0]?.field_event_em_event_type &&
            getEventTypesFromAPI(
              eventDetail[0]?.field_event_em_event_type[0]?.value
            )) ||
          [],
        title:
          (eventDetail[0]?.field_event_em_event_name &&
            getCurrentLocalePropFromAPI(
              eventDetail[0]?.field_event_em_event_name[0]?.value,
              locale
            )) ||
          '',
        date:
          (eventDetail[0]?.field_event_em_display_date &&
            getCurrentLocalePropFromAPI(
              eventDetail[0]?.field_event_em_display_date[0]?.value,
              locale
            )) ||
          '',
        imgUrl:
          (eventDetail[0]?.field_event_em_primary_media &&
            eventDetail[0]?.field_event_em_primary_media[0]?.value) ||
          '',
        url:
          (eventDetail[0]?.field_event_em_source_id &&
            `${createNavLink('event', locale)}/${
              eventDetail[0]?.field_event_em_source_id[0]?.value
            }`) ||
          null,
      }
    }

    return null
  },
  /**
   * Get publications of research area detail
   */
  getPublications: async (
    locale: string,
    publicationIds: string[]
  ): Promise<IPublicationCard[]> => {
    const publications = await Promise.all(
      publicationIds.map(async (id) => {
        const tabOfPage = await PublicationsService.getPublication(locale, id)

        return tabOfPage
      })
    )

    return publications
  },
  /**
   * Get events of research area detail
   */
  getCaseStudies: async (
    locale: string,
    slug: string,
    csIds: string[]
  ): Promise<INewsAndBlogCard[]> => {
    const caseStudies = await Promise.all(
      csIds.map(async (csId) => {
        const tabOfPage = await ResearchAreaService.getCaseStudy(
          locale,
          csId,
          slug
        )

        return tabOfPage
      })
    )

    return caseStudies
  },
  /**
   * Get cs of research area detail
   */
  getCaseStudy: async (
    locale: string,
    csId: string,
    slug?: string
  ): Promise<INewsAndBlogCard> => {
    const d = await ResearchAreaHttpService.getCaseStudy(locale, csId)

    if (d && d.length > 0) {
      return {
        id: d[0].nid[0]?.value || '',
        imgUrl: d[0].field_rd_cs_card_image[0]?.url || '',
        url:
          (slug &&
            `${createNavLink('archiveAndResearchArea', locale)}/${slug}/${
              d[0].nid[0]?.value
            }`) ||
          '',
        title: (d[0].title && d[0].title[0]?.value) || '',
        description: d[0].body[0]?.value || '',
        shortDescription: d[0].body[0]?.summary || '',
        date: parseISODate(d[0].field_cs_release_date[0]?.value, locale) || '',
        type: 'case-studies',
        author: '',
        slug: (d[0].nid && d[0].nid[0]?.value) || '',
      }
    }

    return null
  },
  /**
   * Get cs of research area detail
   */
  getCaseStudyPageData: async (locale: string, csId: string) => {
    const pageData = {
      data: {
        pageTitle: '',
        post: null,
      },
      error: null,
    }

    const caseStudy = await ResearchAreaService.getCaseStudy(locale, csId)

    if (caseStudy) {
      pageData.data.post = caseStudy
      pageData.data.pageTitle = caseStudy?.title || ''
    }

    return pageData
  },
  /**
   * Get project teams of research area detail
   */
  getProjectTeams: async (locale: string, pIds: string[]) => {
    const caseStudies = await Promise.all(
      pIds.map(async (pId) => {
        const tabOfPage = await ResearchAreaService.getProjectTeam(locale, pId)

        return tabOfPage
      })
    )

    return caseStudies && caseStudies.length > 0 ? caseStudies[0] : []
  },
  /**
   * Get project team of research area detail
   */
  getProjectTeam: async (
    locale: string,
    pId: string
  ): Promise<ITwoColumnBlock[]> => {
    const projectTeam = await ResearchAreaHttpService.getProjectTeam(
      locale,
      pId
    )

    if (!projectTeam || !projectTeam.length) {
      return null
    }

    let projectTeamData = []

    try {
      const pTeams = projectTeam[0]?.field_rd_project_team_js[0]?.value || null
      const parsedPTeams = JSON.parse(pTeams)

      if (
        parsedPTeams &&
        typeof parsedPTeams === 'object' &&
        parsedPTeams?.length > 0
      ) {
        projectTeamData = [...parsedPTeams]
      }
    } catch (error) {
      console.log({ error })
    }

    return projectTeamData
  },
  /**
   * Get partners of research area detail
   */
  getPartners: async (locale: string, partnerIds: string[]) => {
    const partners = await Promise.all(
      partnerIds.map(async (pId) => {
        const tabOfPage = await ResearchAreaService.getPartner(locale, pId)

        return tabOfPage && tabOfPage.length > 0 ? tabOfPage[0] : {}
      })
    )

    return partners
  },
  /**
   * Get partner of research area detail
   */
  getPartner: async (locale: string, partnerId: string) => {
    const partner = await ResearchAreaHttpService.getPartner(locale, partnerId)

    return partner.map((p) => ({
      imgAlt:
        (p?.field_rd_partner_logo && p?.field_rd_partner_logo[0]?.alt) || '',
      imgUrl:
        (p?.field_rd_partner_logo && p?.field_rd_partner_logo[0]?.url) || '',
    }))
  },
}
