import { AspectRatio, Image, Flex, Text } from '@chakra-ui/react'
import { IFonts } from '@interfaces'
import { motion } from 'framer-motion'
import { MotionBox } from '@ui'

interface IKidCard {
  title: string
  imgUrl: string
  children: JSX.Element | JSX.Element[]
  primaryColor?: string
  activeFontFamily?: IFonts
}

const KidCard = ({
  title,
  imgUrl,
  children,
  primaryColor = 'yellow.primary2',
  activeFontFamily = 'body',
}: IKidCard) => (
  <MotionBox
    layout
    initial="initial"
    whileHover="hover"
    display="flex"
    flexDir="column"
    alignItems="center"
    width="300px"
    position="relative"
    padding="5"
    zIndex="1"
  >
    <MotionBox
      position="absolute"
      top="0"
      left="0"
      right="0"
      width="full"
      bgColor="white.primary2"
      zIndex="-1"
      as={motion.div}
      variants={bgVariants}
      transition={{ duration: 0.25 }}
    />
    <AspectRatio ratio={1 / 1} mb="2.5" width="full">
      <Image
        src={imgUrl}
        fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
      />
    </AspectRatio>
    <MotionBox
      variants={titleVariants}
      transition={{
        duration: 0.25,
        delay: 0.15,
      }}
      mb="5"
    >
      <Text
        color={primaryColor}
        fontSize="xxl"
        fontWeight="bold"
        textAlign="center"
        fontFamily={activeFontFamily}
      >
        {title}
      </Text>
    </MotionBox>
    <Flex
      flexWrap="wrap"
      flexDir="row"
      alignItems="center"
      sx={{
        '& > * + *': {
          ml: '2.5',
        },
      }}
    >
      {children}
    </Flex>
  </MotionBox>
)

export default KidCard

const bgVariants = {
  hover: {
    height: '100%',
  },
  initial: {
    height: 'calc(100% - 40px)',
  },
}

const titleVariants = {
  hover: {
    opacity: 1,
    height: '100%',
  },
  initial: {
    opacity: 0,
    height: '0px',
  },
}
