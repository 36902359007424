import { Box, VStack, Container, Text } from '@chakra-ui/react'
import { IFonts, IWorkshopCard } from '@interfaces'
import { Link, RichText } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { createNavLink } from '@utils'
import { WorkshopSlider } from '@common'
import { useRouter } from 'next/router'

interface IOnlineChildrensWorkshops {
  stickyId: string
  workshopDescriptionHtmlContent: string
  activeFontFamily?: IFonts
  collection: IWorkshopCard[]
}

const OnlineChildrensWorkshops = ({
  stickyId,
  workshopDescriptionHtmlContent,
  collection,
  activeFontFamily = 'mikado',
}: IOnlineChildrensWorkshops) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <Box
      as="section"
      bgColor="white.primary2"
      id={stickyId}
      py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      position="relative"
    >
      <Container>
        <VStack
          spacing="padding.primary"
          maxWidth="container.secondary"
          mx="auto"
        >
          <Text
            fontFamily={activeFontFamily}
            as="h2"
            fontWeight="extrabold"
            fontSize={{ base: '15vw', lg: '4-5xl' }}
            lineHeight="none"
            textAlign="center"
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            maxWidth="720px"
            mx="auto"
          >
            <Text
              fontFamily={activeFontFamily}
              as="span"
              color="yellow.primary4"
            >
              {t('explore-art:kid.childrensWorkshops.online')}
            </Text>
            <Text fontFamily={activeFontFamily} as="span" color="blue.primary2">
              &nbsp;{t('explore-art:kid.childrensWorkshops.childrens')}
            </Text>
            <Text fontFamily={activeFontFamily} as="span" color="blue.primary4">
              &nbsp;{t('explore-art:kid.childrensWorkshops.workshops')}
            </Text>
          </Text>

          {workshopDescriptionHtmlContent && (
            <RichText
              fontFamily={activeFontFamily}
              fontSize="md"
              textAlign="center"
              htmlContent={workshopDescriptionHtmlContent}
            />
          )}
          <Link
            href={`${createNavLink(
              'exhibitionAndEvent',
              locale
            )}?activeCollection=workshop`}
            textAlign="center"
            fontFamily={activeFontFamily}
            fontSize={{ base: 'lg', lg: 'xxl' }}
            fontWeight="bold"
            color="white.primary"
            bgColor="red.primary4"
            _hover={{
              bgColor: 'red.primary2',
            }}
            px="12"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="60px"
            borderRadius="48px"
          >
            {t('explore-art:kid.childrensWorkshops.buttonLabel')}
          </Link>
        </VStack>
      </Container>
      {collection && collection.length > 0 && (
        <WorkshopSlider collection={collection} />
      )}
    </Box>
  )
}

export default OnlineChildrensWorkshops
