import { Container, Box, BoxProps, Text, useDisclosure } from '@chakra-ui/react'
import { SimpleGrid } from '@chakra-ui/react'
import { IPressReleaseCard, ISSRCollectionError } from '@interfaces'
import {
  PressReleaseCard,
  Skeletons,
  CollectionPaginationText,
  NoResultsFound,
} from '@ui'
import {
  PressReleasePdfForm,
  CollectionSearchPagination,
  PressReleaseFilterBox,
  ContentNotFound,
} from '@common'
import { useState } from 'react'
import { usePagination } from '@lib/hooks'
import { createNavLink } from '@utils'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'

interface ICollection {
  data?: IPressReleaseCard[]
  error?: ISSRCollectionError
}

interface IPressReleaseCollectionGrid {
  sectionTitle: string
  stickyId?: string
  collection: ICollection
  containerProps?: BoxProps
}

const PressReleaseCollectionGrid = observer(
  ({
    sectionTitle,
    stickyId,
    collection,
    containerProps,
  }: IPressReleaseCollectionGrid) => {
    const { locale } = useRouter()
    const { isOpen, onClose, onToggle } = useDisclosure()
    const [activePdfLinkUrl, setActivePdfLinkUrl] = useState('')

    const {
      isLoading,
      handlePagination,
      containerRef,
      currentPage,
      totalPage,
      paginatedData,
    } = usePagination(collection?.data, 6, 1000)

    const handleDownloadPdf = (pdfLink) => {
      setActivePdfLinkUrl(pdfLink)
      onToggle()
    }

    if (collection.error) {
      return <ContentNotFound infoType="serverSideError" />
    }

    if (paginatedData.length === 0) {
      return (
        <Container
          ref={containerRef}
          py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
          {...(stickyId && { id: stickyId })}
          {...containerProps}
        >
          <Text
            as="h2"
            color="red.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
          >
            {sectionTitle}
          </Text>
          <Box maxWidth="container.secondary" mx="auto">
            <PressReleaseFilterBox containerRef={containerRef} />
            <NoResultsFound />
          </Box>
        </Container>
      )
    }

    return (
      <>
        {paginatedData.length > 0 && (
          <Container
            padding={0}
            ref={containerRef}
            py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
            {...(stickyId && { id: stickyId })}
            {...containerProps}
          >
            <Text
              as="h2"
              color="red.primary"
              fontSize={{ base: '2xl', lg: '3xl' }}
              mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
              pl={{ base: '24px', lg: '0' }}
            >
              {sectionTitle}
            </Text>

            {isLoading ? (
              <Skeletons.PressReleaseCard />
            ) : (
              <Container
                maxW="container.secondary"
                mx="auto"
                mt={{ base: 'padding.primary', lg: 'padding.primary2' }}
                mb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
              >
                <PressReleaseFilterBox containerRef={containerRef} />
                <SimpleGrid columns={[1, null, 2]} spacing={10}>
                  {paginatedData.map((col, i) => (
                    <PressReleaseCard
                      key={col.id}
                      onClick={() => handleDownloadPdf(col.pdfUrl)}
                      isLastItem={paginatedData.length === i + 1}
                      url={`${createNavLink('press', locale)}${col.slug}`}
                      {...col}
                    />
                  ))}
                </SimpleGrid>
              </Container>
            )}

            <CollectionSearchPagination
              currentPage={currentPage}
              totalPage={totalPage}
              handlePagination={handlePagination}
            />
            <CollectionPaginationText
              activeLengthOnPage={paginatedData.length}
              totalLength={collection.data.length}
            />
            <PressReleasePdfForm
              isOpen={isOpen}
              onClose={onClose}
              pdfUrl={activePdfLinkUrl}
            />
          </Container>
        )}
      </>
    )
  }
)

export default PressReleaseCollectionGrid
