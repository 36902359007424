import {
  Box,
  Text,
  Flex,
  Button,
  useBreakpointValue,
  Spacer,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useStore } from '@store'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import { ExhibitionAndEventTag, Link } from '@ui'
import { Link as IconLink } from '@icon'
import { sideMenuAnim } from '@lib/motion'

interface IExhibitionAndEventDetailHeroCard {
  title: string
  date: string
  displayBuyTicketButtonGroup?: boolean
  displayFooterButtonGroup?: boolean
}

const ExhibitionAndEventDetailHeroCard = observer(
  ({
    title,
    date,
    displayBuyTicketButtonGroup = false,
    displayFooterButtonGroup = false,
  }: IExhibitionAndEventDetailHeroCard) => {
    const {
      ui: { displayBannerSlider },
    } = useStore()
    const { t } = useTranslation('exhibition-and-event')
    const isDesktop = useBreakpointValue({ base: false, lg: true })

    return (
      <Box position="relative" width="full" zIndex="1">
        <AnimatePresence exitBeforeEnter>
          {displayBannerSlider && (
            <Flex
              flexDirection={{ base: 'column', lg: 'row' }}
              minWidth="min-content"
              alignItems="center"
              gap="2"
              as={motion.div}
              variants={sideMenuAnim.sliderMotion}
              initial="hidden"
              animate="visible"
              exit="hidden"
              bgColor="red.primary"
              textAlign={{ base: 'center', lg: 'left' }}
              py={{ base: 9, lg: '40px' }}
              px={{ base: 10, lg: 14 }}
            >
              <Box>
                {displayBannerSlider && (
                  <Flex flexDirection="column">
                    <Box>
                      <Text as="h6" color="white.primary">
                        {date}
                      </Text>
                      <Text
                        as="h3"
                        color="white.primary"
                        fontSize={{ base: '1.1em', lg: '3xl' }}
                        noOfLines={3}
                      >
                        {title}
                      </Text>
                    </Box>
                  </Flex>
                )}
              </Box>
              <Spacer />
              <Box>
                {displayBuyTicketButtonGroup && (
                  <Flex
                    flexDirection={{ base: 'row', lg: 'row' }}
                    alignItems="baseline"
                  >
                    <Button
                      as={Link}
                      target="_blank"
                      fontSize={{ base: '1.3em' }}
                      leftIcon={
                        <IconLink
                          width="10px"
                          height="10px"
                          color="white.primary"
                        />
                      }
                      variant="unstyled"
                      color="white.primary"
                      href="https://www.sakipsabancimuzesi.org/bilet/"
                      ml={{ base: 0, lg: 3 }}
                    >
                      <Text as="span" fontWeight="light">
                        {t('eventAndExhibitionDetail.buyATicket')}
                      </Text>
                    </Button>
                  </Flex>
                )}
              </Box>
            </Flex>
          )}
        </AnimatePresence>

        <Flex
          bgColor="red.primary2"
          height={{ base: '70px', lg: '85px' }}
          flexDirection="row"
          overflowX="auto"
          alignItems="center"
          px={{ base: 10, lg: 16 }}
          opacity={displayFooterButtonGroup ? 1 : 0}
          sx={{
            '& > * + *': {
              ml: 5,
            },
          }}
          transform={
            displayBannerSlider
              ? 'translateY(0px)'
              : isDesktop
              ? 'translateY(-85px)'
              : 'translateY(-70px)'
          }
          transition="all 0.5s ease-in-out"
        >
          {displayFooterButtonGroup && (
            <>
              <ExhibitionAndEventTag type="3DTour" />
              <ExhibitionAndEventTag type="OnlineExhibitionMap" />
              <ExhibitionAndEventTag type="VoiceGuide" />
              <ExhibitionAndEventTag type="VirtualTour" />
              <ExhibitionAndEventTag type="Catalog" />
            </>
          )}
        </Flex>
      </Box>
    )
  }
)

export default ExhibitionAndEventDetailHeroCard
