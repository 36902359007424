import { Box, Button, Divider, useBreakpointValue } from '@chakra-ui/react'
import { CollectionFilterByTypeWithCheckbox } from '@common'
import { Cross } from '@icon'
import EXHIBITION_AND_EVENT_LOCATION_FILTER_TYPES from '@lib/const/exhibition-and-event-location-filter-types.const'
// import EXHIBITION_AND_EVENT_MASS_FILTER_TYPES from '@lib/const/exhibition-and-event-mass-filter-types.const'
// import EXHIBITION_AND_EVENT_STATUS_FILTER_TYPES from '@lib/const/exhibition-and-event-status-filter-types.const'
import EXHIBITION_AND_EVENT_ACCESSIBILITY_FILTER_TYPES from '@lib/const/exhibition-and-event-accessibility-filter-types.const'
import { useStore } from '@store'
import { ClearSelectionsButton, FilterButton } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

const ExhibitionAndEventFilterBox = () => {
  const [checkedLocationFilterTypes, setCheckedLocationFilterTypes] = useState<
    boolean[]
  >(
    [...Array(EXHIBITION_AND_EVENT_LOCATION_FILTER_TYPES.length).keys()].map(
      () => false
    )
  )

  // const [checkedMassFilterTypes, setCheckedMassFilterTypes] = useState<
  //   boolean[]
  // >(
  //   [...Array(EXHIBITION_AND_EVENT_MASS_FILTER_TYPES.length).keys()].map(
  //     () => false
  //   )
  // )

  // const [checkedStatusFilterTypes, setCheckedStatusFilterTypes] = useState<
  //   boolean[]
  // >(
  //   [...Array(EXHIBITION_AND_EVENT_STATUS_FILTER_TYPES.length).keys()].map(
  //     () => false
  //   )
  // )

  const [checkedAccessibilityFilterTypes, setCheckedAccessibilityFilterTypes] =
    useState<boolean[]>(
      [
        ...Array(EXHIBITION_AND_EVENT_ACCESSIBILITY_FILTER_TYPES.length).keys(),
      ].map(() => false)
    )

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const { t } = useTranslation()
  const {
    exhibitionAndEvents: { onCloseModal, displayFilterModal },
  } = useStore()

  const clearAllFilterAction = () => alert('Clear all filter action')

  const clearFilterAction = () => alert('Clear filter action')

  if (!isDesktop && !displayFilterModal) {
    return null
  }

  return (
    <Box
      width="full"
      maxWidth={{ base: 'full', lg: '345px' }}
      minHeight="auto"
      maxHeight="100vh"
      background="white.primary"
      position={{ base: 'static', lg: 'absolute' }}
      top={{ base: 'auto', lg: '0' }}
      right={{ base: 'auto', lg: '0' }}
      display="flex"
      flexDirection="column"
      sx={{
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: 'white.primary3',
        },
        '::-webkit-scrollbar-thumb': {
          background: 'red.primary',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: 'red.primary3',
        },
      }}
    >
      <Box
        minHeight="65px"
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        px="padding.primary"
        py="3"
      >
        <FilterButton onClick={onCloseModal} />
      </Box>
      <Divider
        borderBottomColor="white.primary3"
        opacity="1"
        width="calc(100% - 60px)"
        mx="auto"
      />
      <Box
        pt="3"
        pb="5"
        flex={1}
        overflowY="auto"
        sx={{
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: 'white.primary3',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'red.primary',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: 'red.primary3',
          },
        }}
      >
        <CollectionFilterByTypeWithCheckbox
          title={t('exhibition-and-event:home.locationFilterTypes.title')}
          collectionListingFilterTypes={
            EXHIBITION_AND_EVENT_LOCATION_FILTER_TYPES
          }
          checkedListingFilterTypes={checkedLocationFilterTypes}
          setCheckedListingFilterTypes={setCheckedLocationFilterTypes}
        />
        <Divider
          borderBottomColor="white.primary3"
          opacity="1"
          width="calc(100% - 60px)"
          mx="auto"
        />
        {/* <CollectionFilterByTypeWithCheckbox
          title={t('exhibition-and-event:home.massFilterTypes.title')}
          collectionListingFilterTypes={EXHIBITION_AND_EVENT_MASS_FILTER_TYPES}
          checkedListingFilterTypes={checkedMassFilterTypes}
          setCheckedListingFilterTypes={setCheckedMassFilterTypes}
        />
        <Divider
          borderBottomColor="white.primary3"
          opacity="1"
          width="calc(100% - 60px)"
          mx="auto"
        />
        <CollectionFilterByTypeWithCheckbox
          title={t('exhibition-and-event:home.statusFilterTypes.title')}
          collectionListingFilterTypes={
            EXHIBITION_AND_EVENT_STATUS_FILTER_TYPES
          }
          checkedListingFilterTypes={checkedStatusFilterTypes}
          setCheckedListingFilterTypes={setCheckedStatusFilterTypes}
        />
        <Divider
          borderBottomColor="white.primary3"
          opacity="1"
          width="calc(100% - 60px)"
          mx="auto"
        /> */}
        <CollectionFilterByTypeWithCheckbox
          title={t('exhibition-and-event:home.accessibilityFilterTypes.title')}
          collectionListingFilterTypes={
            EXHIBITION_AND_EVENT_ACCESSIBILITY_FILTER_TYPES
          }
          checkedListingFilterTypes={checkedAccessibilityFilterTypes}
          setCheckedListingFilterTypes={setCheckedAccessibilityFilterTypes}
        />
        <Divider
          borderBottomColor="white.primary3"
          opacity="1"
          width="calc(100% - 60px)"
          mx="auto"
        />
      </Box>
      <Box bgColor="red.primary" px="padding.primary" py="5">
        <Box
          whiteSpace="nowrap"
          overflowX="auto"
          pb="2"
          mb="3"
          sx={{
            '&::-webkit-scrollbar': {
              width: '2px',
              height: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'red.primary3',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'white.primary',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'white.primary3',
            },
            '&>button+button': {
              ml: 'padding.primary',
            },
          }}
        >
          <Button
            display="inline-flex"
            rightIcon={<Cross width="12px" height="12px" />}
            variant="unstyled"
            color="white.primary"
            fontWeight="light"
            fontSize="sm"
            onClick={clearFilterAction}
          >
            Lorem Ipsum
          </Button>

          <Button
            display="inline-flex"
            rightIcon={<Cross width="12px" height="12px" />}
            variant="unstyled"
            color="white.primary"
            fontWeight="light"
            fontSize="sm"
            onClick={clearFilterAction}
          >
            Dolor sit
          </Button>

          <Button
            display="inline-flex"
            rightIcon={<Cross width="12px" height="12px" />}
            variant="unstyled"
            color="white.primary"
            fontWeight="light"
            fontSize="sm"
            onClick={clearFilterAction}
          >
            Amet consectetur
          </Button>
        </Box>
        <ClearSelectionsButton onClick={clearAllFilterAction} />
      </Box>
    </Box>
  )
}

export default ExhibitionAndEventFilterBox
