import { AspectRatio, Image, Text } from '@chakra-ui/react'
import { ARTIST_IMAGE_ASPECT_RATIO } from '../../../lib/const'
import { IArtistCard } from '@interfaces'
import { Link } from '@ui'

const ArtistCard = ({
  url,
  imgUrl,
  imgAlt,
  fullName,
  lifeRange,
}: IArtistCard) => (
  <Link href={url} display="flex" flexDirection="column">
    <AspectRatio
      ratio={ARTIST_IMAGE_ASPECT_RATIO}
      width="full"
      sx={{
        '& img': {
          objectFit: 'contain',
        },
      }}
    >
      <Image
        src={imgUrl}
        alt={imgAlt || ''}
        fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
      />
    </AspectRatio>
    {fullName && (
      <Text as="h6" fontSize="lg" lineHeight="34px" mt="5">
        {fullName}
      </Text>
    )}
    {lifeRange && (
      <Text
        as="p"
        color="black.primary3"
        fontSize="sm"
        fontWeight="light"
        lineHeight="24px"
        mt="2.5"
      >
        {lifeRange}
      </Text>
    )}
  </Link>
)

export default ArtistCard
