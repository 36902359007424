export const COLORS = {
  black: {
    primary: '#000000',
    primary2: '#0C0E17',
    primary3: '#0A0D19',
    primary4: '#0A0D1A',
    primary5: '#3C3E41',
  },
  blackAlpha: {
    primary10: '#0a0d191a',
    primary65: '#0a0d19a6',
    primary80: '#0a0d19cc',
  },
  white: {
    primary: '#fcfcfc',
    primary2: '#FAF7F0',
    primary3: '#F0EFEE',
  },
  whiteAlpha: {
    primary: 'rgba(255, 255, 255, 0.30)',
    primary2: 'rgba(255, 255, 255, 0.35)',
    primary3: 'rgba(255, 255, 255, 0.25)',
    primary4: 'rgba(255, 255, 255, 0.5)',
    primary5: 'rgba(255, 255, 255, 0.6)',
  },
  blue: {
    primary: '#272A6E',
    primary2: '#186496',
    primary3: '#104263',
    primary4: '#2DADA2',
  },
  grey: {
    primary: '#696969',
    primary2: '#B2B0AB',
    primary3: '#222222',
    primary4: '#B2B2B0',
    primary5: '#ECE9E2',
  },
  greyAlpha: {
    primary: '#B2B0AB',
  },
  red: {
    primary: '#C71F3F',
    primary2: '#A50032',
    primary3: '#99002E',
    primary4: '#F25342',
  },
  yellow: {
    primary: '#F8BB5B',
    primary2: '#F6D570',
    primary3: '#E0C060',
    primary4: '#F6D370',
  },
  pink: {
    primary2: '#FA79F7',
  },
}
