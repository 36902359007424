import { useState, useRef, useEffect } from 'react'
import { Button, Collapse, useBreakpointValue, Box } from '@chakra-ui/react'
import { ArrowBottom } from '@icon'
import useTranslation from 'next-translate/useTranslation'

interface IShowMore {
  children: JSX.Element | JSX.Element[]
  isOnlyMobileWork?: boolean
  startingHeight?: number
}

const ShowMore = ({
  startingHeight = 110,
  isOnlyMobileWork = true,
  children,
}: IShowMore) => {
  const collapseRef = useRef(null)
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const [height, setHeight] = useState(0)
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  useEffect(() => {
    if (collapseRef && collapseRef.current) {
      setHeight(collapseRef.current?.getBoundingClientRect()?.height || 0)
    }
  }, [isDesktop])

  if (isOnlyMobileWork && isDesktop) {
    return <>{children}</>
  }

  const toggleReadMore = () => {
    setShowMore((oldState) => !oldState)
  }

  return (
    <Box>
      <Collapse startingHeight={startingHeight} in={showMore} animateOpacity>
        <Box ref={collapseRef}>{children}</Box>
      </Collapse>
      {height > startingHeight && (
        <Button
          mt={4}
          fontWeight="medium"
          color="black.primary3"
          rightIcon={
            <ArrowBottom
              transition="transform 0.3s ease"
              transform={showMore ? 'rotate(180deg)' : 'rotate(0deg)'}
            />
          }
          variant="unstyled"
          onClick={toggleReadMore}
        >
          {showMore ? t('common:readLess') : t('common:readMore')}
        </Button>
      )}
    </Box>
  )
}

export default ShowMore
