import { Box, Container, VStack, Collapse, Text, Input, Button, Image, Link } from "@chakra-ui/react";
import { useState } from 'react';
import useTranslation from "next-translate/useTranslation";


const DiscoverSsmBanner = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation()

  const handleSearch = () => {
    if (searchTerm.trim()) {
      const formattedSearchTerm = `${searchTerm.trim()}*`
      const searchUrl = `https://digitalssm.org/digital/search/searchterm/${formattedSearchTerm}`;
      window.open(searchUrl, "_blank");
    }
  }
  const [isExpanded, setIsExpanded] = useState(false); // Devamını oku durumu
  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }
  return (
    <Container className="DiscoverSSMSwiper" mt={{base:'0',lg:'0'}}>
      <Box
        position="relative"
        width="100%"
        height={{base:'350px', lg:'500px'}}
        backgroundImage="https://digitalssm.org/customizations/global/images/splash/large-ALT_2-web.jpg"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="linear-gradient(to right, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.1))"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          padding="4"
        >
          <VStack
            align="flex-start"
            spacing={4}
            color="white"
            maxWidth="600px"
            paddingLeft={{base:'0', lg:'20px'}}
            textAlign="left"
          >
            <Link href="https://digitalssm.org/" target="_blank">
              <Image width={{base:'200px', lg:'auto'}} src='https://digitalssm.org/customizations/global/images/logo.png' fallbackSrc='https://via.placeholder.com/150' />
            </Link>
            <Box>
              <Text
                display={{ base: "none", md: "block" }}
                fontSize={{ base: "xs", md: "sm" }}
                opacity={0.85}
              >
                {t('home:discoverArt.slider1.description')}
                <Link href="https://digitalssm.org/" target="_blank" color="white.primary2" textDecoration="underline">www.digitalssm.org</Link>
              </Text>

              <Box display={{ base: "block", md: "none" }}>
                <Collapse startingHeight={60} in={isExpanded}>
                  <Text fontSize={{ base: "xs", md: "sm" }} opacity={0.85}>
                    {t('home:discoverArt.slider1.description')}
                    <Link href="https://digitalssm.org/" target="_blank" color="white.primary2" textDecoration="underline">www.digitalssm.org</Link>
                  </Text>
                </Collapse>
                <Button
                  variant="link"
                  color="red.primary"
                  onClick={handleToggle}
                  size="sm"
                  mt="2"
                >
                  {isExpanded ? t('common:readLess') : t('common:readMore')}
                </Button>
              </Box>
            </Box>

            <Box
              display="flex"
              as="form"
              onSubmit={(e: any) => {
                e.preventDefault()
                handleSearch()
              }}
            >
              <Input
                px="5"
                type="text"
                placeholder={t('home:discoverArt.slider1.placeholder')}
                variant="secondary"
                size="md"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button
                bg="red.primary"
                variant="solid"
                size="md"
                onClick={handleSearch}
              >
                {t('home:discoverArt.slider1.button')}
              </Button>
            </Box>
          </VStack>
        </Box>
      </Box>
    </Container>
  )
}

export default DiscoverSsmBanner
