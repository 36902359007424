import { Container, Box, Text } from '@chakra-ui/react'
import { RichText } from '@ui'
import { IFonts } from '@interfaces'
interface IAboutExhibitionOnline {
  title: string
  descriptions: string
  videoUrl: string
  stickyId: string
  activeFontFamily?: IFonts
}

const AboutExhibitionOnline = ({
  stickyId,
  title,
  descriptions,
  videoUrl,
  activeFontFamily = 'body',
}: IAboutExhibitionOnline) => (
  <Box as="section" py={{ base: '24', lg: '32' }} id={stickyId}>
    <Container>
      <Text
        textAlign="center"
        fontSize={{ base: '3xl', lg: '4xl' }}
        fontWeight="bold"
        fontFamily={activeFontFamily}
        color="greyAlpha.primary"
      >
        {title}
      </Text>
    </Container>
    <Box
      maxW="container.secondary"
      mx="auto"
      mt="padding.primary"
      px={{ base: 'padding.primary', lg: '0' }}
      sx={{
        '& > iframe': {
          mt: 'padding.primary2',
        },
        '& > * + *': {
          mt: '6',
        },
      }}
    >
      {descriptions && (
        <RichText
          htmlContent={descriptions}
          fontFamily={activeFontFamily}
          fontSize="md"
        />
      )}

      {videoUrl && (
        <iframe
          width="100%"
          height="528"
          src={videoUrl}
          frameBorder="0"
          allowFullScreen
        />
      )}
    </Box>
  </Box>
)

export default AboutExhibitionOnline
