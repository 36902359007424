import { Box, BoxProps, Container, Flex, Text } from '@chakra-ui/react'
import {
  CollectionWorkDetailLightGallery,
  DetailPageNavigationHeader,
  ShowMore,
} from '@common'
import { Marker } from '@icon'
import { IObject, IPhotoSliderSectionCollection, IWebShare } from '@interfaces'
import { useShare } from '@lib/hooks'
import useTranslation from 'next-translate/useTranslation'
import { Markup } from 'interweave'
import {
  getCurrentLocalePropFromAPI,
  parsePeopleDataForObjectDetail,
  getCurrentLocaleByObjectTitle,
  createNavLink,
} from '@utils'
import { useRouter } from 'next/router'
import { Link } from '@ui'

interface ICollectionWorkDetailHero {
  objectData: IObject
  photoCollection: IPhotoSliderSectionCollection[]
  containerPros?: BoxProps
  shareProps: IWebShare
  headerLeftNavButtonLabel: string
  headerLeftNavButtonHref: string
  activeFontFamily?: string
}

const CollectionWorkDetailHero = ({
  photoCollection,
  containerPros,
  objectData,
  shareProps,
  headerLeftNavButtonLabel,
  headerLeftNavButtonHref,
  activeFontFamily,
}: ICollectionWorkDetailHero) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <Box as="section" width="full" position="relative" {...containerPros}>
      <DetailPageNavigationHeader
        leftButtonLabel={headerLeftNavButtonLabel}
        leftButtonHref={headerLeftNavButtonHref}
        rightButtonLabel={t('common:detailPageNavigation.share')}
        rightButtonAction={useShare}
        isDarkSection={false}
        isRightButtonShareAction={true}
        shareProps={shareProps}
      />
      <Container
        maxW={{ base: 'full', lg: 'container.secondary' }}
        px={{ base: 'padding.primary', lg: '0' }}
      >
        <Flex flexDirection={{ base: 'column', lg: 'row' }}>
          <Box
            flex="1"
            mb={{ base: 'padding.primary', lg: '0' }}
            pr={{ base: '0', lg: '54px' }}
          >
            <CollectionWorkDetailLightGallery
              collection={photoCollection}
              activeFontFamily={activeFontFamily}
            />
          </Box>
          <Box width={{ base: 'full', lg: '284px' }}>
            {objectData.people && (
              <Link
                href={`${createNavLink('artist', locale)}/${
                  parsePeopleDataForObjectDetail(objectData.people.value)
                    ?.sourceID
                }`}
                variant="inlineLink"
                isExternal={true}
              >
                <Text fontSize="sm" fontWeight="light">
                  {
                    parsePeopleDataForObjectDetail(objectData.people.value)
                      ?.name
                  }
                </Text>
              </Link>
            )}
            <Text
              fontSize={{ base: '2xl', lg: '3xl' }}
              color="red.primary"
              as="h1"
            >
              {getCurrentLocaleByObjectTitle(objectData, locale)}
            </Text>

            {objectData.displayDate && (
              <Text fontSize="lg" pb={{ base: 'padding.primary', lg: '33px' }}>
                {objectData.displayDate.value}
              </Text>
            )}

            <Flex
              alignItems="baseline"
              color="grey.primary"
              mb="padding.primary"
            >
              <Marker width="22px" height="22px" transform="translateY(7px)" />
              <Text fontWeight="light" fontSize="sm" ml={3}>
                Sabancı Üniversitesi Sakıp Sabancı Müzesi (Emirgan, İstanbul,
                Türkiye)
              </Text>
            </Flex>
            {objectData.description && (
              <ShowMore isOnlyMobileWork={false}>
                <Box flex={1} maxW="full" sx={{ '&>*+*': { mt: '4' } }}>
                  <Box fontWeight="light" fontSize="sm" color="black.primary3">
                    <Markup
                      content={getCurrentLocalePropFromAPI(
                        objectData.description?.value || '',
                        locale
                      )}
                    />
                  </Box>
                </Box>
              </ShowMore>
            )}

            {objectData?.audioFileUrl && (
              <Box
                as="audio"
                controls
                src={objectData?.audioFileUrl}
                mt="4"
                width="full"
                sx={{
                  '::-webkit-media-controls-enclosure': {
                    borderRadius: '0px',
                    background: 'white.primary2',
                  },
                }}
              >
                Your browser does not support the
                <code>audio</code> element.
              </Box>
            )}
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default CollectionWorkDetailHero
