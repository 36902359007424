import drupalHttp from '../drupalHttpService'
import {
  ONLINE_EXHIBITIONS,
  TAB_OF_ONLINE_EXHIBITION,
  PERIOD_OF_ONLINE_EXHIBITION,
  OBJECT_OF_ONLINE_EXHIBITION,
} from '@lib/const/api-endpoints.const'

/**
 * Online Exhibition Http Service
 */
export const OnlineExhibitionHttpService = {
  /**
   * Get online exhibitions page drupalHttp service
   */
  getOnlineExhibitions: async (locale: string) =>
    await drupalHttp.get(`${ONLINE_EXHIBITIONS[locale]}`),
  /**
   * Get online exhibition drupalHttp service
   */
  getOnlineExhibition: async (locale: string, slug: string) =>
    await drupalHttp.get(`${ONLINE_EXHIBITIONS[locale]}/${slug}`),
  /**
   * Get tab of online exhibition page drupalHttp service
   */
  getTabOfPage: async (locale: string, id: string) =>
    await drupalHttp.get(`${TAB_OF_ONLINE_EXHIBITION[locale]}/${id}`),
  /**
   * Get period of online exhibition page drupalHttp service
   */
  getPeriodOfOnlineExhibition: async (locale: string, periodId: string) =>
    await drupalHttp.get(`${PERIOD_OF_ONLINE_EXHIBITION[locale]}/${periodId}`),
  /**
   * Get object of online exhibition page drupalHttp service
   */
  getObjectOfOnlineExhibition: async (locale: string, objectId: string) =>
    await drupalHttp.get(`${OBJECT_OF_ONLINE_EXHIBITION[locale]}/${objectId}`),
}
