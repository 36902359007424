export const Select = {
  sizes: {
    primary: {
      field: {
        fontSize: 'sm',
        h: '50px',
        borderRadius: 'md',
      },
    },
  },
  variants: {
    primary: {
      field: {
        color: 'red.primary',
        fontWeight: 'medium',
        bg: 'white.primary',
        pl: 5,
        pr: '10px',
        borderRadius: 'none',
        borderColor: 'grey.primary2',
        borderWidth: 1,
      },
    },
    secondary: {
      field: {
        color: 'black.primary3',
        fontWeight: 'light',
        bg: 'white.primary',
        pl: 5,
        pr: '10px',
        borderRadius: 'none',
        borderColor: 'black.primary3',
        borderWidth: 1,
      },
    },
  },
  defaultProps: {
    size: 'primary',
    variant: 'primary',
  },
}
