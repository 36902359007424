import { Container, Flex, Text, SimpleGrid } from '@chakra-ui/react'
import { DaysAndTicketsCard, DaysAndTicketsCardSm } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { IDaysAndTicketsCard } from '@interfaces'

interface IExhibitionAndEventDaysAndTickets {
  tickets: IDaysAndTicketsCard[]
  sectionId: string
}

const ExhibitionAndEventDaysAndTickets = ({
  tickets,
  sectionId,
}: IExhibitionAndEventDaysAndTickets) => {
  const { t } = useTranslation()

  if (!tickets) {
    return null
  }

  return (
    <Container
      id={sectionId}
      as="section"
      pt={{ base: '0', lg: 'padding.primary2' }}
      pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
    >
      <Text
        color="red.primary"
        fontSize={{ base: '2xl', lg: '3xl' }}
        mb={{ base: '9', lg: '14' }}
      >
        {t('exhibition-and-event:nav.daysAndTickets')}
      </Text>
      <Flex
        flexDirection="column"
        sx={{
          '& > * + *': {
            borderTop: '1px solid',
            borderTopColor: 'white.primary3',
            pt: 'padding.primary',
            mt: 'padding.primary',
          },
        }}
      >
        {tickets &&
          tickets.length <= 4 &&
          tickets.map((ticket) => (
            <DaysAndTicketsCard key={ticket.id} {...ticket} />
          ))}
        <SimpleGrid columns={[1, null, 4]} spacing={10}>
          {tickets &&
            tickets.length > 4 &&
            tickets.map((ticket) => (
              <DaysAndTicketsCardSm key={ticket.id} {...ticket} />
            ))}
        </SimpleGrid>
      </Flex>
    </Container>
  )
}

export default ExhibitionAndEventDaysAndTickets
