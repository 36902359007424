import { Box } from '@chakra-ui/react'
import { Hero, Layout, Sections, StickyHeader, ContentNotFound } from '@common'
import { ISSRPageData } from '@interfaces'
import { useRouter } from 'next/router'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Text } from '@chakra-ui/react'

import useTranslation from 'next-translate/useTranslation'

export const PageWithTabsTemplate = ({
  pageData,
  global: { navigation },
}: ISSRPageData) => {
  const { asPath } = useRouter()
  const { t } = useTranslation()

  if (pageData.error) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound infoType="serverSideError" />
      </Layout>
    )
  }

  return (
    <Layout
      title={pageData.data.pageTitle}
      navigation={navigation}
      path={asPath}
    >
      <style jsx global>{`
        .container > figure > img {
          max-width: 100%;
          display: block;
          margin-bottom: var(--chakra-space-padding-primary5);
        }

        #eventsMindfullook figure {
          margin: 0;
          display: grid;
          grid-template-rows: 1fr auto;
          margin-bottom: 40px;
          break-inside: avoid;
        }

        #eventsMindfullook figure > img {
          grid-row: 1 / -1;
          grid-column: 1;
        }

        #eventsMindfullook figure a {
          color: black;
          text-decoration: none;
        }

        #eventsMindfullook figcaption {
          grid-row: 2;
          grid-column: 1;
          background-color: rgba(255, 255, 255, 0.8);
          padding: 0.2em 0.5em;
          justify-self: start;
        }

        .css-1q5sxve figure {
          margin-bottom: 40px;
        }

        .container {
          column-count: 2;
          column-gap: 10px;
        }

        #selectionsMindfullook {
          background-color: black;
          color: white;
        }

        @media only screen and (min-width: 600px) {
          /* For tablets: */
          #eventsMindfullook .container {
            column-count: 2;
            column-gap: 10px;
          }
        }
      `}</style>
      <Hero
        subTitle={pageData.data.hero.subTitle}
        title={pageData.data.hero.title}
        description={pageData.data.hero.description}
        bgImageUrl={pageData.data.hero.bgImageUrl}
      />
      {pageData.data.stickyNavItems &&
        pageData.data.stickyNavItems.length > 0 && (
          <Box as="main" pt={4}>
            <StickyHeader navs={pageData.data.stickyNavItems} />
            <Sections sections={pageData.data.sections} />
          </Box>
        )}
      {asPath == '/sayfa/muzede-anda' && (
        <Box
          className="mySwiper"
          mt="padding.primary"
          mb="40px"
          maxWidth="container.secondary"
          mx="auto"
          sx={{
            '&.mySwiper .swiper-container': {
              pl: { base: 'padding.primary', lg: '0' },
              pb: { base: '40px', lg: '0' },
            },
            '&.mySwiper .swiper-slide': {
              width: 'auto',
            },
            '&.mySwiper .swiper-pagination-bullet.swiper-pagination-bullet-active':
              {
                backgroundColor: 'red.primary',
                transition: 'all 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
              },
            '&.mySwiper .swiper-pagination-bullet': {
              opacity: 1,
              width: '52px',
              height: '2px',
              borderRadius: 'none',
              backgroundColor: 'blackAlpha.primary10',
              transition: 'all 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
            },
            '&.mySwiper .swiper-pagination': {
              display: { base: 'block', lg: 'none' },
            },
            '@media screen and (max-width: 320px)': {
              '&.mySwiper .swiper-pagination-bullet': {
                width: '50px',
              },
            },
          }}
        >
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              1170: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              <img src="https://emuseum.sakipsabancimuzesi.org/internal/media/dispatcher/2983/preview" />
              <a href="https://sakipsabancimuzesi.org/sergiler-ve-etkinlikler/etkinlik/323">
                <Text fontWeight="bold" fontSize="lg" pb={2} pt={4}>
                  Müzede An’da Galeri Turu
                </Text>
              </a>
              <Text fontSize="md" pb={2}>
                12 Temmuz 2023 10:00-11.45
              </Text>
            </SwiperSlide>
          </Swiper>
        </Box>
      )}
    </Layout>
  )
}
