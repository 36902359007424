import { useState } from 'react'

export const useDateRangePicker = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)

  const onChangeDatePicker = (dates: [Date, Date]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  return {
    startDate,
    endDate,
    onChangeDatePicker,
  }
}
