import { createIcon } from '@chakra-ui/icons'

const Plus = createIcon({
  displayName: 'Plus',
  defaultProps: {
    width: '18px',
    height: '18px',
  },
  viewBox: '0 0 341 341',
  path: (
    <path
      fill="currentColor"
      d="M192 149.4V0h-42.6v149.4H0V192h149.4v149.4H192V192h149.4v-42.6z"
    />
  ),
})

export default Plus
