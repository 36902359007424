import { IVideoSliderCollection } from '@interfaces'

export const generateConferenceOfOnlineExhibition = ({
  id,
  title,
  description,
  posterImgUrl,
  youtubeEmbedUrl,
}: IVideoSliderCollection) => {
  return {
    id,
    title,
    description,
    posterImgUrl,
    youtubeEmbedUrl,
  }
}
