import {
  Box,
  ButtonProps,
  Flex,
  Link,
  Button
} from "@chakra-ui/react";
import { observer } from 'mobx-react-lite'
import { useStore } from '@store'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { MutableRefObject } from 'react'
import { IExhibitionSortName } from '@interfaces'
import { createNavLink } from '@utils'

interface IExhibitionAndEventSortButtonHome {
  containerProps?: ButtonProps
  containerRef?: MutableRefObject<HTMLDivElement>
}

const ExhibitionAndEventSortButtonHome = observer(
  ({ containerProps, containerRef }: IExhibitionAndEventSortButtonHome) => {
    const router = useRouter()
    const {
      exhibitionAndEvents: {
        activeCollection,
        setSortResult,
        setPage,
        toggleDisplaySortModal,
      },
    } = useStore()
    const { t } = useTranslation()

    const handleOnClickSortButton = (sortVal: IExhibitionSortName) => {
      const activeSort = activeCollection === 'online' ? 'current' : sortVal;
      const query = router.query;
      query.sort = activeSort;
      query.page = '1';
      setPage('1');
      setSortResult(activeSort);
      toggleDisplaySortModal();

      router.push(
        {
          pathname: createNavLink('exhibitionAndEvent', router.locale),
          query: query,
        },
        undefined,
        { scroll: false }
      );

      const yCoordinate =
        containerRef?.current?.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -40;
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    };

    return (
      <Box>
        <Flex gap="1">
          <Button
            onClick={() => handleOnClickSortButton('current')}
            fontSize="sm"
            color="dark.primary3"
            display="flex"
            alignItems="center"
            variant="unstyled"
            cursor="pointer"
            sx={{
              marginRight: { base: '15px', sm: '15px', lg: '20px', xl: '25px' }
            }}
            {...containerProps}
          >
            {t('nav:currentExhibitions')}
          </Button>
          <Button
            onClick={() => handleOnClickSortButton('past')}
            fontSize="sm"
            color="grey.primary4"
            display="flex"
            cursor="pointer"
            variant="unstyled"
            alignItems="center"
            sx={{
              marginRight: { base: '15px', sm: '15px', lg: '20px', xl: '25px' }
            }}
            {...containerProps}
          >
            {t('nav:pastExhibitions')}
          </Button>
          <Button
            as={Link}
            href={`${createNavLink('onlineExhibitionFilter', router.locale)}`}
            fontSize="sm"
            color="grey.primary4"
            display="flex"
            variant="unstyled"
            px="5"
            mx="2"
            alignItems="center"
            {...containerProps}
          >
            {t('nav:onlineExhibitions')}
          </Button>
        </Flex>
      </Box>
    )
  }
)

export default ExhibitionAndEventSortButtonHome
