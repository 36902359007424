import { Container, Box, Text } from '@chakra-ui/react'
import { VideoSliderSection } from '@common'
import { IVideoSliderCollection, IFonts } from '@interfaces'

interface IConferencesExhibitionAndEvent {
  stickyId: string
  sectionTitle: string
  videoCollection: IVideoSliderCollection[]
  activeFontFamily?: IFonts
}

const ConferencesExhibitionAndEvent = ({
  stickyId,
  sectionTitle,
  videoCollection,
  activeFontFamily = 'body',
}: IConferencesExhibitionAndEvent) => (
  <Box
    as="section"
    id={stickyId}
    py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
  >
    <Container>
      <Box mb="45px">
        <Text
          color="red.primary"
          fontFamily={activeFontFamily}
          fontSize={{ base: '2xl', lg: '3xl' }}
        >
          {sectionTitle}
        </Text>
      </Box>
      <VideoSliderSection
        videoCollection={videoCollection}
        activeFontFamily={activeFontFamily}
      />
    </Container>
  </Box>
)

export default ConferencesExhibitionAndEvent
