import {
  Box,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  chakra,
} from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useStore } from '@store'
import { useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Cross } from '@icon'

interface ICollectionSearchInputBox {
  handleSearchAction: () => void
}

const CollectionSearchInputBox = observer(
  ({ handleSearchAction }: ICollectionSearchInputBox) => {
    const inputEl = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()

    const {
      artSearch: { searchTitle, setSearchTitle },
    } = useStore()

    const handleCloseSearchAction = () => setSearchTitle('')

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleSearchAction()
    }

    return (
      <Box
        width={{ base: 'full', lg: 'auto' }}
        height="65px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        background="white.primary"
        position={{ base: 'static', lg: 'absolute' }}
        top={{ base: 'auto', lg: '0' }}
        right={{ base: 'auto', lg: '345px' }}
        left={{ base: 'auto', lg: '345px' }}
      >
        <chakra.form display="flex" width="full" onSubmit={handleFormSubmit}>
          <InputGroup>
            <Input
              ref={inputEl}
              onChange={(e) => setSearchTitle(e.target.value)}
              value={searchTitle}
              px="5"
              type="text"
              placeholder={t(
                'collections-and-research:search.advancedSearchInputPlaceholder'
              )}
              variant="secondary"
              size="secondary"
              border="1px solid"
              borderColor="black.primary3"
            />
            <InputRightElement pr="5" width="5" bottom="0" height="full">
              <IconButton
                onClick={handleCloseSearchAction}
                variant="unstyled"
                aria-label={t(
                  'collections-and-research:search.advancedSearchInputPlaceholder'
                )}
                icon={
                  <Cross width="12px" height="12px" color="grey.primary2" />
                }
              />
            </InputRightElement>
          </InputGroup>
          <Button
            type="submit"
            fontSize="sm"
            fontWeight="medium"
            minW={{ base: '10', lg: '110px' }}
            height="50px"
            _hover={{
              bgColor: 'red.primary3',
            }}
          >
            {t(
              'collections-and-research:collections.heroSearchCard.searchButtonLabel'
            )}
          </Button>
        </chakra.form>
      </Box>
    )
  }
)

export default CollectionSearchInputBox
