import {
  Box,
  Button,
  Flex,
  SlideFade,
  TabPanel,
  TabPanels as ChakraTabPanels,
  useBreakpointValue,
} from '@chakra-ui/react'
import { IArtCollection, ArtCollectionType } from '@interfaces'
import { Link, RichText } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import SelectionsFromCollection from './SelectionsFromCollection'
import { createNavLink } from '@utils'
import { useRouter } from 'next/router'

interface ITabPanels {
  tabPanelsCollection: Omit<IArtCollection, 'title'>[]
  activeTabIndex: number
  activePage: ArtCollectionType
}

const TabPanels = ({
  tabPanelsCollection,
  activeTabIndex,
  activePage,
}: ITabPanels) => {
  const { locale } = useRouter()
  const { t } = useTranslation()
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <Box
      as={ChakraTabPanels}
      flex={{ base: 'auto', lg: '1' }}
      pl={{ base: '0', lg: 20 }}
    >
      {tabPanelsCollection.map((col, index) => (
        <TabPanel key={col.id} p="0">
          <Box as={SlideFade} in={activeTabIndex === index} offsetY="45px">
            {col.description !== '' && (
              <RichText htmlContent={col.description} />
            )}
            <Flex
              flexDirection={{ base: 'column', lg: 'row' }}
              mt="padding.primary"
              sx={{
                '&>*+*': {
                  ml: isDesktop ? 5 : '0',
                  mt: isDesktop ? '0' : '10px',
                },
              }}
            >
              <Button
                as={Link}
                variant="secondary"
                size="secondary"
                maxW={{ base: 'full', lg: '300px' }}
                href={`${createNavLink(
                  activePage === 'collection' ? 'collections' : 'archives',
                  locale
                )}/${col.id}`}
              >
                {t(
                  col.type === 'archive'
                    ? 'collections-and-research:collections.archivesButtonLabel'
                    : 'collections-and-research:collections.collectionButtonLabel'
                )}
              </Button>
            </Flex>

            <SelectionsFromCollection collection={col.collection} />
            <Flex
              flexDirection={{ base: 'column', lg: 'row' }}
              mt="padding.primary"
              sx={{
                '&>*+*': {
                  ml: isDesktop ? 5 : '0',
                  mt: isDesktop ? '0' : '10px',
                },
              }}
            ></Flex>
          </Box>
        </TabPanel>
      ))}
    </Box>
  )
}

export default TabPanels
