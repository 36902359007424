import drupalHttp from '../drupalHttpService'
import {
  ARTICLE,
  TAGS_OF_ARTICLE,
  FILTER_ARTICLE_BY_TAG,
} from '@lib/const/api-endpoints.const'

/**
 * Article Http Service
 */
export const ArticleHttpService = {
  /**
   * Get all article drupalHttp service
   */
  getAllArticle: async (locale: string, addUrlParams?: string) =>
    await drupalHttp.get(`${ARTICLE[locale]}${addUrlParams || ''}`),
  /**
   * Get articles by filter type drupalHttp service
   * filterType: 'featured' | 'inspiring'
   */
  getArticlesByFilter: async (
    locale: string,
    filterType: 'featured' | 'inspiring'
  ) => await drupalHttp.get(`${ARTICLE[locale]}/${filterType}`),
  /**
   * Get tags of article
   */
  getTagsOfArticles: async (locale: string) =>
    await drupalHttp.get(`${TAGS_OF_ARTICLE[locale]}`),
  /**
   * Get articles by tag id
   */
  getArticleByTag: async (locale: string, tagId: string) =>
    await drupalHttp.get(`${FILTER_ARTICLE_BY_TAG[locale]}/${tagId}`),
}
