import { AspectRatio, Box } from '@chakra-ui/react'
import { IInstagramMedia } from '@interfaces'

interface IChildAtTheMuseumCard extends IInstagramMedia {
  children: JSX.Element
}

const SsmInstagramCard = ({ children }: IChildAtTheMuseumCard) => (
  <Box
    display="inline-block"
    width={{ base: '137px', sm: '225px', lg: '300px' }}
  >
    <AspectRatio ratio={1}>{children}</AspectRatio>
  </Box>
)

export default SsmInstagramCard
