import {
  PageWithTabsTemplate,
  BasicPageTemplate,
  PageWithHeroBannerTemplate,
  PageWithHeroTextTemplate,
} from '@templates'

const PAGE_TYPES = {
  // only title and html content ( like kvkk page )
  basicpage: BasicPageTemplate,
  // with sticky tabs ( like visit page )
  pagewithtabs: PageWithTabsTemplate,
  // with hero and html content ( like membership page )
  pagewithherobanner: PageWithHeroBannerTemplate,
  // with subtitle, title, desc and html content  ( like press page )
  pagewithherotext: PageWithHeroTextTemplate,
}

export type IPAGE_TYPES = keyof typeof PAGE_TYPES
export const PAGE_TYPES_KEYS = Object.keys(PAGE_TYPES)

export default PAGE_TYPES
