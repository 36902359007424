import { createIcon } from '@chakra-ui/icons'

const Catalog = createIcon({
  displayName: 'Catalog',
  defaultProps: {
    width: 22,
  },
  viewBox: '0 0 24 21',
  path: (
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M9.25 1c.688 0 1.854.311 2.553.883l.202.204.189-.204C12.956 1.259 13.719 1 14.75 1h6.875C22.305 1 23 1.73 23 2.461v14.615c0 .731-.688 1.462-1.375 1.462H14.75c-.344 0-.998.42-1.838 1.064a1.261 1.261 0 01-.143.126 1.23 1.23 0 01-.792.272 1.234 1.234 0 01-.781-.303l-.285-.269c-.479-.43-1.306-.89-1.661-.89H2.375c-.68 0-1.375-.745-1.375-1.462V2.461C1 1.737 1.692 1 2.375 1H9.25zm5.5 1.461c-.633 0-1.214.07-1.8.569l-.128.113a.62.62 0 00-.123.152.373.373 0 00-.051.187v14.657l.68-.48c.5-.34.94-.584 1.422-.584h6.6c.073 0 .143-.03.194-.086a.302.302 0 00.081-.206V2.753a.302.302 0 00-.08-.206.267.267 0 00-.195-.086h-6.6zm-5.5 0h-6.6a.267.267 0 00-.194.086.302.302 0 00-.081.207v14.03c0 .16.124.292.275.292h6.6c.587 0 1.49.541 2.104.994V3.51a.464.464 0 00-.032-.167l-.037-.076-.066-.092c-.47-.494-1.281-.714-1.969-.714z"
    />
  ),
})

export default Catalog
