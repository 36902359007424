export const Button = {
  baseStyle: {
    lineHeight: 'normal',
    borderRadius: 'none',
    fontSize: 'sm',
    fontWeight: 'medium',
    transition: 'all 0.3s ease-in-out',
    _focus: {
      boxShadow: 'none',
    },
    _disabled: {
      cursor: 'not-allowed',
      boxShadow: 'none',
      backgroundColor: 'red.primary2',
      opacity: 1,
    },
    _hover: {
      backgroundColor: 'red.primary2',
      _disabled: {
        bg: 'red.primary2',
      },
    },
  },
  variants: {
    primary: {
      background: 'red.primary2',
      color: 'white.primary',
      _hover: {
        backgroundColor: 'red.primary',
      },
    },
    secondary: {
      background: 'red.primary',
      color: 'white.primary',
      _hover: {
        backgroundColor: 'red.primary2',
      },
    },
    blue: {
      background: 'blue.primary',
      color: 'white.primary',
      _hover: {
        backgroundColor: 'blue.primary2',
      },
    },
    white: {
      background: 'white.primary3',
      color: 'grey.primary2',
      _hover: {
        backgroundColor: 'red.primary',
        color: 'white.primary',
      },
    },
    unstyled: {
      bg: 'none',
      color: 'inherit',
      display: 'inline',
      lineHeight: 'inherit',
      m: 0,
      p: 0,
      _hover: {
        backgroundColor: 'transparent',
        opacity: 0.75,
      },
    },
    link: {
      display: 'inline-block',
      bg: 'none',
      m: 0,
      p: 0,
      pb: '0.5',
      color: 'inherit',
      lineHeight: 'inherit',
      textAlign: 'left',
      transition: 'all 0.3s ease-in-out',
      _hover: {
        backgroundColor: 'transparent',
        textDecoration: 'none',
        color: 'red.primary',
        _disabled: {
          bg: 'transparent',
        },
        _before: {
          width: '100%',
        },
      },
      _before: {
        content: `""`,
        position: 'absolute',
        bottom: '0.5',
        width: '20%',
        height: '2px',
        bgColor: 'red.primary',
        transition: 'all 0.3s linear',
      },
    },
    outline: {
      bg: 'none',
      color: 'inherit',
      display: 'inline',
      lineHeight: 'inherit',
      borderColor: 'black.primary3',
    },
    threeD: {
      backgroundColor: 'yellow.primary2',
      color: 'white.primary',
      width: 63,
      height: 65,
      borderRadius: '50%',
      boxShadow: '0 0.4em #E0C060',
      transition: 'all 300ms ease-in-out',
      position: 'relative',
      top: 0,
      _hover: {
        backgroundColor: 'yellow.primary2',
        top: '0.2em',
        boxShadow: '0 0.1em #E0C060',
      },
    },
  },
  sizes: {
    primary: {
      display: 'block',
      h: {
        base: 20,
        lg: '50px',
      },
      minW: {
        base: 'full',
        lg: '240px',
      },
      mx: {
        base: '0',
        lg: 'auto',
      },
    },
    donate: {
      w: 'full',
      h: {
        base: 20,
        lg: '100px',
      },
    },
    newsletter: {
      minW: {
        base: 20,
        lg: '100px',
      },
      h: {
        base: 20,
        lg: '100px',
      },
    },
    secondary: {
      h: '50px',
      w: 'full',
      minW: 10,
      fontSize: 'sm',
      fontWeight: 'medium',
      px: 4,
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
}
