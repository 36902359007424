/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosResponse } from 'axios'

const googleMapInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_GOOGLE_MAP_PLACE_API_URL,
  timeout: 15000,
})

const responseBody = (response: AxiosResponse) => response.data

const googleMapHttp = {
  get: (url: string) => googleMapInstance.get(url).then(responseBody),
  post: (url: string, body: {}) =>
    googleMapInstance.post(url, body).then(responseBody),
  put: (url: string, body: {}) =>
    googleMapInstance.put(url, body).then(responseBody),
  delete: (url: string) => googleMapInstance.delete(url).then(responseBody),
}

export default googleMapHttp
