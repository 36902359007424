import { IPhotoAndVideoSliderSectionCollection } from '@interfaces'

export const removeDuplicateImgUrls = (
  photoAndVideos: IPhotoAndVideoSliderSectionCollection[],
  duplicateImageUrl: string
) => {
  return photoAndVideos.filter((row) => {
    if (!row.isVideo) {
      const parsedRowImgUrl = decodeURIComponent(row.url).replace(/\[;\]/g, '')
      const parsedDuplicateImageUrl = decodeURIComponent(
        duplicateImageUrl
      ).replace(/\[;\]/g, '')

      return !(parsedRowImgUrl === parsedDuplicateImageUrl)
    }

    return true
  })
}
