import { GoogleMapHttpService } from '@httpServices'
import { IGoogleMapPlaceType } from '@interfaces'
import { generateVisitingDays } from '@utils'

export const GoogleMapService = {
  /**
   * Get place detail on google map api
   */
  getPlaceDetail: async (locale: string, placeType: IGoogleMapPlaceType) => {
    const data = {
      websiteUrl: null,
      openingHours: null,
    }

    const placeDetail = await GoogleMapHttpService.getPlaceDetail(placeType)

    if (placeDetail) {
      const websiteUrl = placeDetail?.result?.website
      const openingHours = placeDetail?.result?.openingHours?.periods

      if (websiteUrl) {
        data.websiteUrl = websiteUrl
      }

      if (openingHours && openingHours.length > 0) {
        data.openingHours = generateVisitingDays(locale, openingHours)
      }
    }

    return data
  },
}
