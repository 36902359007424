import {
  Box,
  Button,
  IconButton,
  Divider,
  Flex,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ExhibitionAndEventSortButton } from '@common'
import EXHIBITION_AND_EVENT_SORT_FILTER_OPTIONS from '@lib/const/exhibition-and-event-sort-filter-options.const'
import { useStore } from '@store'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import { Cross } from '@icon'
import { ClearSelectionsButton, DateRangePicker } from '@ui'
import { useDateRangePicker } from '@lib/hooks'
import { useRouter } from 'next/router'

const CollectionSearchSortBox = observer(() => {
  const { locale } = useRouter()
  const { startDate, endDate, onChangeDatePicker } = useDateRangePicker()
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const { t } = useTranslation()
  const {
    exhibitionAndEvents: { setSortResult, onCloseModal, displaySortModal },
  } = useStore()

  const clearAllFilterAction = () => alert('Clear all filter action')

  const clearFilterAction = () => alert('Clear filter action')

  const handleSearchSelectedDays = () => alert('Search selected days action')

  if (!isDesktop && !displaySortModal) {
    return null
  }

  return (
    <Box
      width="full"
      maxWidth={{ base: 'full', lg: '345px' }}
      minHeight="auto"
      maxHeight="100vh"
      background="white.primary"
      position={{ base: 'static', lg: 'absolute' }}
      top={{ base: 'auto', lg: '0' }}
      left={{ base: 'auto', lg: '0' }}
      display="flex"
      flexDirection="column"
      sx={{
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: 'white.primary3',
        },
        '::-webkit-scrollbar-thumb': {
          background: 'red.primary',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: 'red.primary3',
        },
      }}
    >
      <Box
        height="65px"
        display="flex"
        alignItems="center"
        justifyContent={{ base: 'space-between', lg: 'flex-start' }}
        px="padding.primary"
      >
        <ExhibitionAndEventSortButton
          containerProps={{ mr: { base: 'auto', lg: '0px' } }}
        />
        {!isDesktop && (
          <IconButton
            aria-label={t('common:clossButtonLabel')}
            icon={<Cross />}
            onClick={onCloseModal}
            variant="unstyled"
          />
        )}
      </Box>
      <Flex
        flexDirection="column"
        pb="padding.primary"
        maxHeight="calc(100vh - 65px)"
        overflowY="auto"
        sx={{
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: 'white.primary3',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'red.primary',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: 'red.primary3',
          },
        }}
      >
        <Flex flexDirection="column" px="padding.primary">
          {EXHIBITION_AND_EVENT_SORT_FILTER_OPTIONS.map((option, index) => (
            <Button
              key={option.id}
              onClick={() => setSortResult(option.value)}
              fontWeight="light"
              color="black.primary3"
              fontSize="sm"
              variant="unstyled"
              height="auto"
            >
              {index === 0 && (
                <Divider borderBottomColor="white.primary3" opacity="1" />
              )}
              <Text py="4" textAlign="left">
                {t(option.labelTx)}
              </Text>
              <Divider borderBottomColor="white.primary3" opacity="1" />
            </Button>
          ))}
        </Flex>

        <Box px="padding.primary" py="5">
          <DateRangePicker
            locale={locale}
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDatePicker}
          />
          <Button
            onClick={handleSearchSelectedDays}
            variant="secondary"
            mt="1.5"
            width="full"
            fontSize="sm"
            height="50px"
          >
            {t('exhibition-and-event:home.searchSelectedDays')}
          </Button>
        </Box>
      </Flex>

      <Box bgColor="red.primary" px="padding.primary" py="5">
        <Box
          whiteSpace="nowrap"
          overflowX="auto"
          pb="2"
          mb="3"
          sx={{
            '&::-webkit-scrollbar': {
              width: '2px',
              height: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'red.primary3',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'white.primary',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'white.primary3',
            },
            '&>button+button': {
              ml: 'padding.primary',
            },
          }}
        >
          <Button
            display="inline-flex"
            rightIcon={<Cross width="12px" height="12px" />}
            variant="unstyled"
            color="white.primary"
            fontWeight="light"
            fontSize="sm"
            onClick={clearFilterAction}
          >
            Lorem Ipsum
          </Button>

          <Button
            display="inline-flex"
            rightIcon={<Cross width="12px" height="12px" />}
            variant="unstyled"
            color="white.primary"
            fontWeight="light"
            fontSize="sm"
            onClick={clearFilterAction}
          >
            Dolor sit
          </Button>

          <Button
            display="inline-flex"
            rightIcon={<Cross width="12px" height="12px" />}
            variant="unstyled"
            color="white.primary"
            fontWeight="light"
            fontSize="sm"
            onClick={clearFilterAction}
          >
            Amet consectetur
          </Button>
        </Box>
        <ClearSelectionsButton onClick={clearAllFilterAction} />
      </Box>
    </Box>
  )
})

export default CollectionSearchSortBox
