import { createIcon } from '@chakra-ui/icons'

const ExternalLink = createIcon({
  displayName: 'ExternalLink',
  viewBox: '0 0 21 21',
  defaultProps: {
    width: '21px',
    height: '21px',
  },
  path: (
    <path
      d="M1 20L20 1M20 1H6.24138M20 1V14.7586"
      stroke="currentColor"
      strokeWidth="2"
    />
  ),
})

export default ExternalLink
