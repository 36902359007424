import { NavigationHttpService } from '@httpServices'
import { IMenuPaths } from '@interfaces'

export const NavigationService = {
  /**
   * Get nav items
   */
  getNavItems: async (locale: string): Promise<IMenuPaths[]> => {
    const MENU_PATHS = []

    try {
      const navItems = await NavigationHttpService.getNavItems(locale)

      navItems.forEach((nav) => {
        const preparedMainNavItem = {
          id: nav?.key || '',
          path: nav?.external
            ? nav?.uri
            : (nav?.alias && `/${nav?.alias}`) || '',
          labelTx: nav?.title || '',
          isExternal: nav?.external,
          subMenu: [],
          bgImage: nav?.description || '/img/nav/visit.jpg',
        }
        if (
          nav?.below &&
          typeof nav?.below === 'object' &&
          nav?.below.length > 0
        ) {
          const SUB_MENU_PATHS = []

          nav?.below.forEach((subItem) => {
            const subNavItem = {
              id: subItem?.key || '',
              path: subItem?.external
                ? subItem?.uri
                : (subItem?.alias && `/${subItem?.alias}`) || '',
              labelTx: subItem?.title || '',
              isExternal: subItem?.external,
              isPwaLink: subItem?.description === 'isPWA' || false,
            }

            SUB_MENU_PATHS.push(subNavItem)
          })

          preparedMainNavItem.subMenu = SUB_MENU_PATHS
        }

        MENU_PATHS.push(preparedMainNavItem)
      })
    } catch (error) {
      console.log({ navigationError: error })
    }

    return MENU_PATHS
  },
}
