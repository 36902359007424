import { Container, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

interface ICollectionResultsSummary {
  searchTerm: string
  totalCount: string
}

const CollectionResultsSummary = ({
  searchTerm,
  totalCount,
}: ICollectionResultsSummary) => {
  const { t } = useTranslation()

  return (
    <Container pt={{ base: 'padding.primary', lg: 'padding.primary2' }}>
      <Text color="red.primary" fontSize={{ base: '2xl', lg: '3xl' }}>
        {`‘${searchTerm}’ ${t('common:allResults')}`}
      </Text>
      <Text fontSize={{ base: 'md', lg: 'lg' }}>
        {t('common:resultFoundWithCount', {
          count: totalCount,
        })}
      </Text>
    </Container>
  )
}

export default CollectionResultsSummary
