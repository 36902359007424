import { PressReleasesHttpService } from '@httpServices'
import { parseISODate } from '@utils'
import { IPressReleaseCard } from '@interfaces'

export const PressReleasesService = {
  /**
   * Get press releases service
   */
  getAllPressRelease: async (
    locale: string,
    addUrlParams?: string
  ): Promise<IPressReleaseCard[]> => {
    const collection = await PressReleasesHttpService.getAllPressRelease(
      locale,
      addUrlParams
    )

    return collection
      .sort(
        (a, b) =>
          new Date(b.field_pr_date[0].value).valueOf() -
          new Date(a.field_pr_date[0].value).valueOf()
      )
      .map((d) => ({
        id: d.uuid[0]?.value || '',
        date:
          (d?.field_pr_date &&
            parseISODate(d?.field_pr_date[0]?.value, locale)) ||
          '',
        title: d.title[0]?.value || '',
        displayTitle: d.field_pr_display_title[0]?.value || '',
        bannerImage: d.field_pr_banner_image[0]?.url || '',
        description:
          (d.field_pr_body.length > 0 && d.field_pr_body[0]?.value) || '',
        shortDescription:
          (d.field_pr_short_description.length > 0 &&
            d.field_pr_short_description[0]?.value) ||
          '',
        pdfUrl:
          (d.field_pr_download_link && d.field_pr_download_link[0]?.value) ||
          '',
        pdfUrlStd:
          (d.field_pr_download_link_std &&
            d.field_pr_download_link_std[0]?.value) ||
          '',

        slug:
          (d.field_path_alias.length > 0 && d.field_path_alias[0]?.value) || '',
        ISODate: (d?.field_pr_date && d?.field_pr_date[0]?.value) || '',
      }))
  },
  getAllPressReleaseSlugs: async (locale: string): Promise<string[]> => {
    const collection = await PressReleasesHttpService.getAllPressRelease(locale)

    return collection.map(
      (d) =>
        (d.field_path_alias.length > 0 &&
          d.field_path_alias[0]?.value.slice(
            1,
            d.field_path_alias[0]?.value.length
          )) ||
        ''
    )
  },
}
