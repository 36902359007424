import { types } from 'mobx-state-tree'
export const PressRelease = types
  .model({
    displaySortModal: types.boolean,
    filterResult: types.optional(types.string, 'all'),
  })
  .actions((self) => ({
    toggleDisplaySortModal() {
      self.displaySortModal = !self.displaySortModal
    },
    setFilterResult(val: string) {
      self.filterResult = val
    },
  }))
