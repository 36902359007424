import { createIcon } from '@chakra-ui/icons'

const PlayForKids = createIcon({
  displayName: 'PlayForKids',
  viewBox: '0 0 35 34',
  defaultProps: {
    width: 35,
    height: 34,
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M10.4279 5.78431L10.9459 6.63968C10.3701 6.98842 9.95738 7.65481 9.73053 9.21106C9.50257 10.7749 9.5 12.9478 9.5 15.9954L9.5 18.0046C9.5 21.0521 9.50257 23.2251 9.73053 24.7889C9.95738 26.3452 10.3701 27.0116 10.9459 27.3603C10.9817 27.382 11.018 27.403 11.0547 27.4231L10.4279 5.78431ZM10.4279 5.78431L10.9459 6.63967C10.9817 6.61798 11.018 6.59704 11.0547 6.57685C11.6447 6.25253 12.4281 6.22832 13.8893 6.80999C15.3576 7.39448 17.2407 8.47874 19.88 10.0025L21.62 11.0071C24.2593 12.5309 26.1398 13.6196 27.3802 14.5989M10.4279 5.78431L27.3802 14.5989M27.3802 14.5989C28.6145 15.5735 28.9852 16.2641 28.9993 16.9372M27.3802 14.5989L28.9993 16.9372M28.9993 16.9372C29.0002 16.9791 29.0002 17.0209 28.9993 17.0628M28.9993 16.9372L28.9993 17.0628M28.9993 17.0628C28.9852 17.7359 28.6145 18.4265 27.3801 19.4011M28.9993 17.0628L27.3801 19.4011M27.3801 19.4011C26.1398 20.3804 24.2593 21.4691 21.62 22.9929M27.3801 19.4011L21.62 22.9929M21.62 22.9929L19.88 23.9975M21.62 22.9929L19.88 23.9975M19.88 23.9975C17.2407 25.5213 15.3576 26.6055 13.8893 27.19M19.88 23.9975L13.8893 27.19M13.8893 27.19C12.4282 27.7717 11.6447 27.7475 11.0548 27.4232L13.8893 27.19Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M8.83984 7.12891C9.42864 10.5349 10.1623 13.9177 10.3033 17.3734C10.3516 18.5567 10.8093 19.6861 11.0126 20.8481C11.3854 22.9784 11.0163 25.182 11.3538 27.3036C11.4626 27.9873 11.6619 28.5983 11.7579 29.2699C11.9042 30.2942 16.439 29.8558 17.145 29.5662C18.0828 29.1814 18.8278 28.6956 19.8295 28.4259C20.6931 28.1934 21.5384 27.8998 22.1999 27.2946C23.1859 26.3925 24.6897 25.909 25.8451 25.1937C27.3282 24.2756 28.2619 22.3124 29.9393 21.7998C30.5847 21.6026 34.2489 19.5384 33.9617 19.1691C32.4182 17.1845 30.4685 15.4324 28.0539 14.5631C25.8389 13.7657 23.3268 13.2432 21.2481 12.1389C19.4023 11.1583 17.3272 10.4812 15.3942 9.6788C13.6991 8.97519 11.9467 8.43233 10.2944 7.64966C9.86596 7.44673 9.30014 7.63829 9.00146 7.93697"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})

export default PlayForKids
