import { createIcon } from '@chakra-ui/icons'

const Filter = createIcon({
  displayName: 'Filter',
  viewBox: '0 0 20 18',
  defaultProps: {
    width: '20px',
    height: '18px',
    fill: 'none',
  },
  path: (
    <>
      <path d="M0 2H20" stroke="currentColor" strokeWidth="2" />
      <path d="M0 9H20" stroke="currentColor" strokeWidth="2" />
      <path d="M0 16H20" stroke="currentColor" strokeWidth="2" />
      <circle cx="4" cy="2" r="2" fill="currentColor" />
      <circle cx="10" cy="9" r="2" fill="currentColor" />
      <circle cx="4" cy="16" r="2" fill="currentColor" />
    </>
  ),
})

export default Filter
