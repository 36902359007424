import { Flex, Button, BoxProps, ButtonProps } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useStore } from '@store'
import { observer } from 'mobx-react-lite'
import { IExhibitionAndEventActiveCollectionName } from '@interfaces'
import { useRouter } from 'next/router'
import { MutableRefObject } from 'react'
import { createNavLink } from '@utils'

interface IExhibitionAndEventTabButtonGroups {
  containerProps?: BoxProps
  buttonProps?: ButtonProps
  containerRef?: MutableRefObject<HTMLDivElement>
  isLoading?: boolean
}

const ExhibitionAndEventTabButtonGroups = observer(
  ({
    containerProps,
    buttonProps,
    containerRef,
    isLoading = false,
  }: IExhibitionAndEventTabButtonGroups) => {
    const router = useRouter()
    const { t } = useTranslation()
    const {
      exhibitionAndEvents: {
        activeCollection,
        setActiveCollection,
        setDisplaySortModal,
        sortResult,
        setSortResult,
        setPage,
      },
    } = useStore()

    const typeOnClickAction = (
      activeCollection: IExhibitionAndEventActiveCollectionName
    ) => {
      const activeSort = activeCollection === 'online' ? 'current' : sortResult
      setDisplaySortModal(false)

      const query = router.query
      query.activeCollection = activeCollection
      query.sort = activeSort
      query.page = '1'

      setSortResult(activeSort)
      setActiveCollection(activeCollection)
      setPage('1')

      router.push(
        {
          pathname: createNavLink('exhibitionAndEvent', router.locale),
          query: query,
        },
        undefined,
        { scroll: false }
      )

      const yCoordinate =
        containerRef?.current?.getBoundingClientRect().top + window.pageYOffset
      const yOffset = -40
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
    }

    return (
      <Flex
        gridArea="tabButtonGroup"
        overflowX="auto"
        mx="padding.primary"
        sx={{
          '::-webkit-scrollbar': {
            height: '6px',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'red.primary',
          },
          '&>*+*': {
            ml: '10px',
          },
        }}
        {...containerProps}
      >
        <Button
          display="inline-flex"
          height="50px"
          fontSize="sm"
          variant={activeCollection === 'exhibitions' ? 'secondary' : 'outline'}
          onClick={() => typeOnClickAction('exhibitions')}
          disabled={isLoading}
          _disabled={{
            opacity: 1,
            cursor: 'not-allowed',
            boxShadow: 'none',
            _hover: {
              background: 'red.primary3',
            },
          }}
          {...buttonProps}
        >
          {t('exhibition-and-event:home.eventTypes.exhibitions')}
        </Button>
        <Button
          display="inline-flex"
          height="50px"
          fontSize="sm"
          variant={activeCollection === 'online' ? 'secondary' : 'outline'}
          onClick={() => typeOnClickAction('online')}
          disabled={isLoading}
          _disabled={{
            opacity: 1,
            cursor: 'not-allowed',
            boxShadow: 'none',
            _hover: {
              background: 'red.primary3',
            },
          }}
          {...buttonProps}
        >
          {t('exhibition-and-event:home.eventTypes.online')}
        </Button>
        <Button
          display="inline-flex"
          height="50px"
          fontSize="sm"
          variant={activeCollection === 'events' ? 'secondary' : 'outline'}
          onClick={() => typeOnClickAction('events')}
          disabled={isLoading}
          _disabled={{
            opacity: 1,
            cursor: 'not-allowed',
            boxShadow: 'none',
            _hover: {
              background: 'red.primary3',
            },
          }}
          {...buttonProps}
        >
          {t('exhibition-and-event:home.eventTypes.events')}
        </Button>
        <Button
          //display="inline-flex"
          display="none"
          height="50px"
          fontSize="sm"
          variant={activeCollection === 'workshop' ? 'secondary' : 'outline'}
          onClick={() => typeOnClickAction('workshop')}
          disabled={isLoading}
          _disabled={{
            opacity: 1,
            cursor: 'not-allowed',
            boxShadow: 'none',
            _hover: {
              background: 'red.primary3',
            },
          }}
          {...buttonProps}
        >
          {t('exhibition-and-event:home.eventTypes.workshops')}
        </Button>
        <Button
          display="inline-flex"
          //display="none"
          height="50px"
          fontSize="sm"
          variant={activeCollection === 'learning' ? 'secondary' : 'outline'}
          onClick={() => typeOnClickAction('learning')}
          disabled={isLoading}
          _disabled={{
            opacity: 1,
            cursor: 'not-allowed',
            boxShadow: 'none',
            _hover: {
              background: 'red.primary3',
            },
          }}
          {...buttonProps}
        >
          {t('exhibition-and-event:home.eventTypes.learning')}
        </Button>
        <Button
          //display="inline-flex"
          display="none"
          height="50px"
          fontSize="sm"
          variant={activeCollection === 'tours' ? 'secondary' : 'outline'}
          onClick={() => typeOnClickAction('tours')}
          disabled={isLoading}
          _disabled={{
            opacity: 1,
            cursor: 'not-allowed',
            boxShadow: 'none',
            _hover: {
              background: 'red.primary3',
            },
          }}
          {...buttonProps}
        >
          {t('exhibition-and-event:home.eventTypes.tours')}
        </Button>
      </Flex>
    )
  }
)

export default ExhibitionAndEventTabButtonGroups
