import { Box, BoxProps, Container, Text, Button } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EventCard, EventInformationCard, Link } from '@ui'
import {
  IEventCard,
  EventCardPrimaryColor,
  IMainTypeOfEvent,
  IFonts,
} from '@interfaces'
import { useRouter } from 'next/router'
import { createNavLink } from '@utils'
import useTranslation from 'next-translate/useTranslation'

type IActivePage = IMainTypeOfEvent | 'Article'

interface IRelatedEvents {
  title: string
  collection: IEventCard[]
  containerProps?: BoxProps
  stickyId?: string
  primaryColor?: EventCardPrimaryColor
  children?: JSX.Element
  mainType?: IActivePage
  isWithoutEventInformationCard?: boolean
  activeFontFamily?: IFonts
}

const RelatedEvents = ({
  title,
  collection,
  containerProps,
  stickyId = 'RelatedEvents',
  primaryColor = 'red',
  children,
  mainType = 'Event',
  isWithoutEventInformationCard = false,
  activeFontFamily = 'body',
}: IRelatedEvents) => {
  const { locale } = useRouter()
  const { t } = useTranslation()

  return (
    <Box
      id={stickyId}
      as="section"
      sx={{
        '.RelatedEventsSwiper .swiper-slide': {
          width: '315px',
        },
      }}
      mb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      {...containerProps}
    >
      <Container>
        <Text
          color="red.primary"
          fontFamily={activeFontFamily}
          fontSize={{ base: '2xl', lg: '3xl' }}
          mb={{ base: 'padding.primary', lg: '45px' }}
        >
          {title}
        </Text>
        <Swiper
          spaceBetween={20}
          initialSlide={0}
          slidesPerView="auto"
          grabCursor={true}
          className="RelatedEventsSwiper"
          breakpoints={{
            1170: {
              initialSlide: 0,
            },
          }}
        >
          {collection &&
            collection.length > 0 &&
            collection.map((card) => (
              <SwiperSlide key={card.id}>
                <EventCard
                  activeFontFamily={activeFontFamily}
                  primaryColor={primaryColor}
                  {...card}
                />
              </SwiperSlide>
            ))}
          {collection &&
            collection.length > 0 &&
            !isWithoutEventInformationCard && (
              <SwiperSlide>
                <EventInformationCard
                  type={
                    mainType === 'All'
                      ? 'Events'
                      : mainType === 'Article'
                      ? 'Article'
                      : mainType === 'Learning'
                      ? 'Learning'
                      : mainType === 'Online'
                      ? 'Online'
                      : mainType === 'Workshop'
                      ? 'Workshops'
                      : 'Events'
                  }
                  title={t(
                    `common:tabs.${
                      mainType === 'Learning'
                        ? 'learningPrograms'
                        : mainType === 'Article'
                        ? 'newsAndBlog'
                        : mainType === 'Online'
                        ? 'online'
                        : mainType === 'Workshop'
                        ? 'workshops'
                        : 'events'
                    }`
                  )}
                  containerProps={{
                    minHeight: '500px',
                  }}
                  primaryColor={primaryColor}
                  activeFontFamily={activeFontFamily}
                >
                  <Button
                    as={Link}
                    variant={primaryColor === 'red' ? 'primary' : 'blue'}
                    href={
                      mainType === 'Article'
                        ? createNavLink('newsAndBlog', locale)
                        : `${createNavLink(
                            'exhibitionAndEvent',
                            locale
                          )}?activeCollection=${
                            mainType === 'Learning'
                              ? 'learning'
                              : mainType === 'Online'
                              ? 'online'
                              : mainType === 'Workshop'
                              ? 'workshop'
                              : 'events'
                          }`
                    }
                    fontFamily={activeFontFamily}
                  >
                    {t('common:viewAll')}
                  </Button>
                </EventInformationCard>
              </SwiperSlide>
            )}
        </Swiper>
        {children}
      </Container>
    </Box>
  )
}

export default RelatedEvents
