export const Checkbox = {
  sizes: {
    md: {
      label: {
        fontSize: 'sm',
        color: 'black.primary3',
        ml: '10px',
      },
      control: {
        width: '20px',
        height: '20px',
        border: '1px solid',
        borderColor: 'black.primary3',
        borderRadius: '0px',
        boxShadow: 'none',
      },
      icon: {
        fontSize: '19px',
      },
    },
  },
}
