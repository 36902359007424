/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-irregular-whitespace */
import SEO from '@/next-seo.config'
import { Header, SearchModal } from '@common'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { NextSeo } from 'next-seo'
import { Box } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { layoutAnim } from '@lib/motion'
import { useEffect } from 'react'
import { useStore } from '@store'
import { IFonts, IMenuPaths } from '@interfaces'

import dynamic from 'next/dynamic'
import BackToTop from '@/src/components/common/BackToTop'
const Footer = dynamic(() => import('../Footer'))
const Drawer = dynamic(() => import('../Drawer'))

interface ILayout {
  title: string
  path: string
  children: JSX.Element | JSX.Element[]
  isHomePage?: boolean
  activeFontFamily?: IFonts
  navigation: IMenuPaths[]
}

const Layout = observer(
  ({
    title,
    path,
    isHomePage = false,
    children,
    activeFontFamily = 'body',
    navigation,
  }: ILayout) => {
    const pageTitle = `${title} | SSM`
    const url = `${SEO.canonical}${path}`
    const { route, pathname } = useRouter()
    const {
      ui: { setDisplayBannerSlider },
    } = useStore()

    useEffect(() => {
      setDisplayBannerSlider(true)
    }, [pathname])

    return (
      <>
        <NextSeo
          title={pageTitle}
          canonical={url}
          openGraph={{
            url,
            title,
          }}
        />
        <Header isHomePage={isHomePage} activeFontFamily={activeFontFamily} navigation={navigation} />
        <AnimatePresence key={route} exitBeforeEnter>
          <Box
            as={motion.main}
            initial="initial"
            animate="enter"
            exit="exit"
            variants={layoutAnim.variants}
            minHeight="50vh"
            pt={isHomePage === true ? '0px' : '53px'}
            mt="65x"
          >
            {children}
          </Box>
        </AnimatePresence>
        <Footer activeFontFamily={activeFontFamily} />
        <Drawer navigation={navigation} activeFontFamily={activeFontFamily} />
        <SearchModal activeFontFamily={activeFontFamily} />
        <BackToTop />
      </>
    )
  }
)

export default Layout
