import { extendTheme } from '@chakra-ui/react'
import {
  BREAKPOINTS,
  FONTS,
  FONTSIZES,
  COLORS,
  SPACES,
  SIZES,
  GLOBAL,
  TEXT_STYLES,
} from './tokens'
import COMPONENTS from './components'

const theme = extendTheme({
  colors: COLORS,
  fonts: FONTS,
  fontSizes: FONTSIZES,
  breakpoints: BREAKPOINTS,
  components: COMPONENTS,
  space: SPACES,
  sizes: SIZES,
  textStyles: TEXT_STYLES,
  styles: {
    global: GLOBAL,
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
})

export default theme
