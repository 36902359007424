import drupalHttp from '../drupalHttpService'
import {
  PROJECTS_OF_RESEARCH_AREA,
  SW_DEV_OF_RESEARCH_AREA,
  PROJECTS_AND_SW_DEVS_OF_RESEARCH_AREA,
  TABS_OF_RESEARCH_AREA_DETAIL,
  EVENT_OF_RESEARCH_AREA_DETAIL,
  CASE_STUDIES_OF_RESEARCH_AREA_DETAIL,
  TEAM_OF_RESEARCH_AREA_DETAIL,
  PARTNER_OF_RESEARCH_AREA_DETAIL,
} from '@lib/const/api-endpoints.const'

/**
 * ResearchArea Http Service
 */
export const ResearchAreaHttpService = {
  /**
   * Get all projects on research area page
   */
  getAllProjectsAndSwDevs: async (locale: string) =>
    await drupalHttp.get(PROJECTS_AND_SW_DEVS_OF_RESEARCH_AREA[locale]),
  /**
   * Get all projects on research area page
   */
  getProjects: async (locale) =>
    await drupalHttp.get(PROJECTS_OF_RESEARCH_AREA[locale]),
  /**
   * Get all software dev on research area page
   */
  getSoftwareDev: async (locale) =>
    await drupalHttp.get(SW_DEV_OF_RESEARCH_AREA[locale]),
  /**
   * Get research area detail page
   */
  getResearchDetail: async (locale, slug) =>
    await drupalHttp.get(
      `${PROJECTS_AND_SW_DEVS_OF_RESEARCH_AREA[locale]}/${slug}`
    ),
  /**
   * Get tab of page drupalHttp service
   */
  getTabOfPage: async (locale: string, id: string) =>
    await drupalHttp.get(`${TABS_OF_RESEARCH_AREA_DETAIL[locale]}/${id}`),
  /**
   * Get event of page detail drupalHttp service
   */
  getEvent: async (locale: string, id: string) =>
    await drupalHttp.get(`${EVENT_OF_RESEARCH_AREA_DETAIL[locale]}/${id}`),
  /**
   * Get case study of page detail drupalHttp service
   */
  getCaseStudy: async (locale: string, id: string) =>
    await drupalHttp.get(
      `${CASE_STUDIES_OF_RESEARCH_AREA_DETAIL[locale]}/${id}`
    ),
  /**
   * Get project team of page detail drupalHttp service
   */
  getProjectTeam: async (locale: string, id: string) =>
    await drupalHttp.get(`${TEAM_OF_RESEARCH_AREA_DETAIL[locale]}/${id}`),
  /**
   * Get partner of page detail drupalHttp service
   */
  getPartner: async (locale: string, id: string) =>
    await drupalHttp.get(`${PARTNER_OF_RESEARCH_AREA_DETAIL[locale]}/${id}`),
}
