import { Flex, Text, Image } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

const NoResultsFound = () => {
  const { t } = useTranslation()

  return (
    <Flex
      as="main"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      px={4}
      minHeight="50vh"
    >
      <Image
        width="300px"
        fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
        src="/img/placeholders/empty-source.png"
        alt="Empty Source"
      />
      <Text>{t('common:noResultsFound')}</Text>
    </Flex>
  )
}

export default NoResultsFound
