import { Flex, Box, AspectRatio, Text, Button, Image } from '@chakra-ui/react'
import {
  Exhibition,
  Union,
  Workshop,
  Clapperboard,
  Tour,
  Learning,
  Online,
  Blog,
  News,
  Article,
} from '@icon'
import { IEventCard } from '@interfaces'
import { Link } from '@ui'
import useTranslation from 'next-translate/useTranslation'

const EventCard = (props: IEventCard) => {
  const {
    type,
    tagsTx,
    title,
    description,
    date,
    imgUrl,
    url,
    footerButtonLabel,
    footerButtonHrefOnClick,
    primaryColor = 'red',
    isGrayFilterImage = false,
    activeFontFamily = 'body',
  } = props

  const thumbimgUrl = imgUrl.replace('full', 'thumbnail')

  const { t } = useTranslation()

  const CurrentIcon = () => {
    switch (type) {
      case 'exhibition':
        return <Exhibition color="white.primary" />

      case 'event':
        return <Union color="white.primary" width="26.89px" height="24.2px" />

      case 'workshop':
        return <Workshop color="white.primary" />

      case 'moviesAndVideos':
        return <Clapperboard color="white.primary" />

      case 'tours':
        return <Tour color="white.primary" />

      case 'learning':
        return <Learning color="white.primary" />

      case 'online':
        return <Online color="white.primary" />

      case 'new':
        return <News color="white.primary" />

      case 'article':
        return <Article color="white.primary" />

      case 'blog':
        return <Blog color="white.primary" />

      case 'inspiration':
        return <News color="white.primary" />

      default:
        return <Exhibition color="white.primary" />
    }
  }

  return (
    <Box
      _hover={{ transform: 'translateY(-5px)' }}
      transition="all 0.3s ease"
      pb="5"
      display="inline-block"
      width="full"
      {...(isGrayFilterImage && {
        sx: {
          '&:hover img': {
            filter: 'grayscale(0)',
          },
        },
      })}
    >
      <Link
        href={url}
        display="inline-flex"
        alignItems="flex-start"
        justifyContent="center"
        width="full"
      >
        <Flex
          flexDirection="column"
          width="full"
          maxWidth="315px"
          position="relative"
        >
          <AspectRatio
            ratio={4 / 5}
            sx={{
              '&>img, &>video': {
                objectFit: 'contain',
              },
            }}
          >
            <Image
              src={thumbimgUrl}
              fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
              alt={title}
              objectFit="cover"
              {...(isGrayFilterImage && {
                filter: 'grayscale(1)',
                transition: 'filter 0.3s ease',
              })}
            />
          </AspectRatio>
          <Flex background="transparent" position="relative" mt={'-25px'}>
            <Flex
              position="absolute"
              top="12.5px"
              alignItems="center"
              justifyContent="center"
              width="50px"
              height="50px"
              bgColor={`${primaryColor}.primary`}
            >
              <CurrentIcon />
            </Flex>

            <Box
              pl={'45px'}
              pt={1.5}
              backgroundColor="white.primary"
              width="calc(100% - 25px)"
              ml="auto"
            >
              {tagsTx && (
                <Flex
                  flexDirection="row"
                  overflowY="auto"
                  // mb="15px"
                  pb="2"
                  sx={{
                    '::-webkit-scrollbar': {
                      height: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'white.primary3',
                    },
                    '::-webkit-scrollbar-thumb': {
                      background: `${primaryColor}.primary`,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: `${primaryColor}.primary2`,
                    },
                  }}
                ></Flex>
              )}
              <Text
                opacity={0.8}
                color="black.primary"
                mb={2}
                fontSize="sm"
                fontFamily={activeFontFamily}
              >
                {date ? date : t('nav:collectionExhibition')}
              </Text>
              <Text
                fontFamily={activeFontFamily}
                lineHeight="1.1"
                noOfLines={4}
              >
                {title}
              </Text>
              {description && (
                <Text
                  noOfLines={3}
                  fontSize="sm"
                  fontWeight="light"
                  fontFamily={activeFontFamily}
                  mt={2}
                >
                  {description}
                </Text>
              )}
            </Box>
          </Flex>
        </Flex>
      </Link>
      <Box pl={'45px'} width="calc(100% - 25px)" ml="auto">
        {footerButtonLabel &&
          footerButtonLabel !== '' &&
          footerButtonHrefOnClick &&
          footerButtonHrefOnClick !== '' && (
            <Button
              as={Link}
              variant="secondary"
              size="secondary"
              href={footerButtonHrefOnClick}
              mt="22px"
              fontFamily={activeFontFamily}
            >
              {footerButtonLabel}
            </Button>
          )}
      </Box>
    </Box>
  )
}

export default EventCard
