import React, { useContext, useMemo } from 'react'
import { IntersectionContext, Box } from '../'
import { EasingFunction } from 'framer-motion/types/types'

interface IFadeInUpBox {
  children?: JSX.Element | JSX.Element[]
  /**
   * y initial possition
   */
  yOffset?: number
  duration?: number
  delay?: number
  easing?:
    | [number, number, number, number]
    | 'linear'
    | 'easeIn'
    | 'easeOut'
    | 'easeInOut'
    | 'circIn'
    | 'circOut'
    | 'circInOut'
    | 'backIn'
    | 'backOut'
    | 'backInOut'
    | 'anticipate'
    | EasingFunction
}

export const FadeInUpBox = ({
  children,
  yOffset = 24,
  easing = [0.83, 0, 0.17, 1],
  duration = 0.4,
  delay = 0,
  ...rest
}: IFadeInUpBox) => {
  const { inView } = useContext(IntersectionContext)

  const transition = useMemo(
    () => ({
      duration,
      delay,
      ease: easing,
    }),
    [duration, delay, easing]
  )

  const variants = {
    hidden: { y: yOffset, opacity: 0, transition },
    show: {
      y: 0,
      opacity: 1,
      transition,
    },
  }

  return (
    <Box
      initial="hidden"
      animate={inView ? 'show' : 'hidden'}
      exit="hidden"
      variants={variants}
      {...rest}
    >
      {children} {inView}
    </Box>
  )
}
