import { createIcon } from '@chakra-ui/icons'

const Youtube = createIcon({
  displayName: 'Youtube',
  defaultProps: {
    width: '24px',
    height: '17px',
  },
  viewBox: '0 0 24 17',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.72.5a2.958 2.958 0 012.056 2.119c.484 1.867.484 5.765.484 5.765s0 3.899-.486 5.766a2.958 2.958 0 01-2.057 2.118c-1.813.501-9.087.501-9.087.501s-7.274 0-9.087-.5A2.958 2.958 0 01.486 14.15C0 12.283 0 8.384 0 8.384S0 4.486.486 2.62A2.958 2.958 0 012.543.5C4.356 0 11.63 0 11.63 0s7.274 0 9.09.5zm-5.371 7.884L9.304 4.792v7.185l6.045-3.593z"
    />
  ),
})

export default Youtube
