import { sectionComponents } from '@lib/const/section-components'
import { ISection } from '@interfaces'

const Section = ({
  sectionData,
  activeFontFamily = 'body',
  withDivider = false,
  isLastItem = false,
}: ISection) => {
  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.__component]
  if (!SectionComponent) {
    return null
  }
  // plant section on visit garden page
  if (sectionData.__component === 'sections.plantlist') {
    return (
      <SectionComponent
        stickyId={sectionData.stickyId}
        data={sectionData.data}
        sectionTitle={sectionData.sectionTitle}
        isDarkSection={false}
        variant="withPopUp"
        containerStyle={{
          bgColor: 'white.primary',
          pb: { base: 'padding.primary2', lg: 'padding.primary4' },
        }}
      />
    )
  }

  // gallery
  if (sectionData.__component === 'sections.gallery') {
    return (
      <SectionComponent
        stickyId={sectionData.stickyId}
        data={sectionData.data}
        activeFontFamily={activeFontFamily}
        sectionTitle={sectionData.sectionTitle}
        pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      />
    )
  }

  // contact_info
  if (sectionData.__component === 'sections.contact_info') {
    return (
      <SectionComponent
        stickyId={sectionData.stickyId}
        data={sectionData.data}
        activeFontFamily={activeFontFamily}
        sectionTitle={sectionData.sectionTitle}
        withDivider={withDivider}
        isLastItem={isLastItem}
      />
    )
  }

  // Visit
  if (sectionData.__component === 'sections.block') {
    return (
      <SectionComponent
        stickyId={sectionData.stickyId}
        data={sectionData.data}
        activeFontFamily={activeFontFamily}
        sectionTitle={sectionData.sectionTitle}
        withDivider={withDivider}
        isLastItem={isLastItem}
      />
    )
  }

  // Display the section
  return (
    <SectionComponent
      stickyId={sectionData.stickyId}
      data={sectionData.data}
      sectionTitle={sectionData.sectionTitle}
      {...(sectionData?.activeScreen && {
        activeScreen: sectionData.activeScreen,
      })}
      activeFontFamily={activeFontFamily}
      withDivider={withDivider}
      isLastItem={isLastItem}
    />
  )
}

export default Section
