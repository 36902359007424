/* eslint-disable @typescript-eslint/no-unused-vars */
import { Center, Flex, Box, Image } from '@chakra-ui/react'
import { IKidPhotoAndVideoSliderSection } from '@interfaces'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import { Link, HeroAspectRatio } from '@ui'
import cn from 'classnames'
import { ArrowLeft, ArrowRight } from '@icon'

// install Swiper modules
SwiperCore.use([Navigation])

/**
 * Important note: If it is going to be a youtube video, the url shape should be as follows.
 * Example youtube url: https://www.youtube.com/embed/f0zQ_akQC4A?enablejsapi=1&rel=0
 */
const KidPhotoAndVideoSliderSection = ({
  data,
  buttonUrl,
  buttonLabel,
  ...containerProps
}: IKidPhotoAndVideoSliderSection) => {
  /**
   * If there is a video playing, let's stop it.
   */
  const swiperOnSlideChange = () => {
    const slides = document.querySelectorAll('.gallery .swiper-slide')

    slides.forEach((slide) => {
      const isExistIframe = slide.querySelector('iframe')
      if (isExistIframe) {
        const videoProvider = isExistIframe.dataset.videoProvider
        let command

        if (videoProvider === 'vimeo') {
          command = {
            method: 'pause',
            value: 'true',
          }
        } else {
          command = {
            event: 'command',
            func: 'pauseVideo',
          }
        }
        isExistIframe.contentWindow.postMessage(JSON.stringify(command), '*')
      }
    })
  }

  return (
    <Box
      as="section"
      pb="padding.primary"
      bgColor="white.primary2"
      {...containerProps}
    >
      <Box
        sx={{
          '& .gallery-swiper.swiper-container': {
            height: 'full',
            position: 'relative',
            pb: { base: 'padding.primary2', lg: '0px' },
          },
          '& .gallery-swiper .swiper-slide': {
            width: 'auto',
            height: 'auto',
            position: 'relative',
          },
          '& .gallery-swiper .swiper-slide > iframe': {
            width: 'full',
            height: 'full',
          },
        }}
      >
        <Swiper
          navigation={{
            nextEl: '.gallery-swiper-button-next',
            prevEl: '.gallery-swiper-button-prev',
          }}
          grabCursor
          centeredSlides
          initialSlide={1}
          className="gallery-swiper"
          onSlideChange={swiperOnSlideChange}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            1170: {
              slidesPerView: 1.4,
              spaceBetween: 10,
            },
          }}
        >
          {data &&
            data.length > 0 &&
            data.map((col) => (
              <SwiperSlide
                key={col.id}
                className={cn({
                  'swiper-no-swiping': col.isVideo,
                })}
              >
                <HeroAspectRatio as="div" minHeight="auto">
                  <Box width="full" height="full">
                    {col.isVideo ? (
                      <Box
                        as="iframe"
                        src={col.url}
                        frameBorder="0"
                        allowFullScreen
                        width="full"
                        height="full"
                        data-video-provider={col.videoProvider}
                      />
                    ) : (
                      <Image
                        src={col.url}
                        fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
                        alt={col.alt}
                        width="full"
                        height="full"
                        objectFit={{ base: 'cover', lg: 'contain' }}
                      />
                    )}
                  </Box>
                </HeroAspectRatio>
              </SwiperSlide>
            ))}
          <Flex
            position="absolute"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            height="30px"
            top={{ base: 'auto', lg: '50%' }}
            bottom={{ base: '0px', lg: 'auto' }}
            left="0px"
            right="0px"
            px={{ base: 'padding.primary', lg: '100px' }}
            zIndex="1"
            sx={{
              '.gallery-swiper-button-prev': {
                position: 'absolute',
                top: '0px',
                left: { base: '35%', lg: '100px' },
              },
              '.gallery-swiper-button-next': {
                position: 'absolute',
                top: '0px',
                left: 'auto',
                right: { base: '35%', lg: '100px' },
              },
              '.gallery-swiper-button-next, .gallery-swiper-button-prev': {
                width: '24px',
                height: 'auto',
                mt: '0px',
                color: 'red.primary4',
                fontWeight: 'black',
              },
              '.gallery-swiper-button-next:after, .gallery-swiper-button-prev:after':
                {
                  fontSize: 'xl',
                },
              '.gallery-swiper-button-next.swiper-button-disabled, .gallery-swiper-button-prev.swiper-button-disabled':
                {
                  opacity: 0.5,
                },
            }}
          >
            <Box
              role="button"
              aria-label="Gallery slider previous button"
              className="gallery-swiper-button-prev"
              position="static"
            >
              <ArrowLeft />
            </Box>
            <Box
              role="button"
              aria-label="Gallery slider next button"
              position="static"
              className="gallery-swiper-button-next"
            >
              <ArrowRight />
            </Box>
          </Flex>
        </Swiper>
      </Box>
      <Center mt="padding.primary">
        <Link
          href={buttonUrl}
          textAlign="center"
          fontFamily="mikado"
          fontSize={{ base: 'lg', lg: 'xxl' }}
          fontWeight="bold"
          color="white.primary"
          bgColor="red.primary4"
          _hover={{
            bgColor: 'red.primary2',
          }}
          px="12"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="60px"
          borderRadius="48px"
        >
          {buttonLabel}
        </Link>
      </Center>
    </Box>
  )
}

export default KidPhotoAndVideoSliderSection
