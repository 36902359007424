export const FONTS = {
  heading: "'Inter', sans-serif",
  // body: "'Inter', sans-serif",
  body: "'Spectral', serif",
  mono: "'Inter', sans-serif",
  // maaxMono: "'Maax Mono', sans-serif",
  //crimsonText: "'Crimson Text', sans-serif",
  mikado: "'Mikado', sans-serif",
  spectral: "'Spectral', serif",
}
