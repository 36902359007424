import { Box, Container, Flex, Text, SimpleGrid, Image } from '@chakra-ui/react'
import { IPartnersOfResearchSection } from '@interfaces'

const PartnersOfResearchSection = ({
  stickyId,
  containerProps,
  sectionTitle,
  data,
  activeFontFamily = 'body',
}: IPartnersOfResearchSection) => (
  <Box
    id={stickyId}
    py={{ base: 'padding.primary', lg: 24 }}
    as="section"
    {...containerProps}
  >
    <Container>
      <Text
        as="h2"
        color="red.primary"
        fontSize={{ base: '2xl', lg: '3xl' }}
        fontFamily={activeFontFamily}
        mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
      >
        {sectionTitle}
      </Text>
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        maxW="container.secondary"
        mx="auto"
      >
        <SimpleGrid
          columns={{ base: 1, lg: 4 }}
          spacing="padding.primary2"
          placeItems="center"
        >
          {data &&
            data.length > 0 &&
            data.map((partner, i) => (
              <Image
                key={i}
                src={partner?.imgUrl}
                alt={partner?.imgAlt}
                fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
              />
            ))}
        </SimpleGrid>
      </Flex>
    </Container>
  </Box>
)

export default PartnersOfResearchSection
