import styled from '@emotion/styled'
import { mq } from '@lib/hooks'
import theme from '@styles/theme'

export const I18nWidget = styled.div`
  display: flex;

  &:hover {
    .I18nWidget__Link {
      opacity: 0.75;
    }
  }
  .I18nWidget__Link--onDrawer {
    color: ${theme.colors.white.primary};
  }

  ${mq('lg')} {
    .I18nWidget__Link {
      color: ${theme.colors.white.primary};

      &.I18nWidget__Link--isSticky {
        color: ${theme.colors.black.primary3};
      }
    }
  }
`
