import { types } from 'mobx-state-tree'
import {
  IArtSortFilterOptionValueTypes,
  // IArtLimitCountOfPageOptionTypes,
  // IArtListingViewTypes,
} from '@interfaces'

export const ArtSearch = types
  .model({
    searchTerm: types.optional(types.string, ''),
    displayFilterModal: types.boolean,
    displaySortModal: types.boolean,
    sortResult: types.union(
      types.literal('Relevance'),
      types.literal('title-asc'),
      types.literal('title-desc'),
      types.literal('displayDate-asc'),
      types.literal('displayDate-desc'),
      types.literal('primaryMakerAlpha-asc'),
      types.literal('primaryMakerAlpha-desc'),
      types.literal('invno-asc'),
      types.literal('invno-desc')
    ),
    page: types.string,
    searchTitle: types.optional(types.string, ''),
    searchArtist: types.optional(types.string, ''),
    searchInvNo: types.optional(types.string, ''),
    searchDescription: types.optional(types.string, ''),
    displayBeginDate: types.optional(types.boolean, false),
    displayEndDate: types.optional(types.boolean, false),
    beginDate: types.maybeNull(types.Date),
    endDate: types.maybeNull(types.Date),
    selectedInCollectionOptions: types.union(
      types.literal('All'),
      types.literal('Abidin Dino Archive'),
      types.literal('Arkeolojik Eserler Koleksiyonu'),
      types.literal('Dekoratif Eserler Koleksiyonu'),
      types.literal('Emirgan Archive'),
      types.literal('Kitap Sanatları ve Hat Koleksiyonu'),
      types.literal('Resim Koleksiyonu')
    ),
    // limitCountOfPage: types.union(
    //   types.literal(20),
    //   types.literal(40),
    //   types.literal(60)
    // ),
    // listingView: types.union(types.literal('row'), types.literal('column')),
  })
  .actions((self) => ({
    setDisplayFilterModal(val: boolean) {
      self.displayFilterModal = val
    },
    toggleDisplayFilterModal() {
      self.displayFilterModal = !self.displayFilterModal
    },
    toggleDisplaySortModal() {
      self.displaySortModal = !self.displaySortModal
    },
    setSearchTerm(val: string) {
      self.searchTerm = val
    },
    setSortResult(val: IArtSortFilterOptionValueTypes) {
      self.sortResult = val
    },
    // setLimitCountOfPage(val: IArtLimitCountOfPageOptionTypes) {
    //   self.limitCountOfPage = val
    // },
    // setListingView(val: IArtListingViewTypes) {
    //   self.listingView = val
    // },
    onCloseModal() {
      self.displayFilterModal = false
      self.displaySortModal = false
    },
    setPage(val) {
      self.page = val
    },
    setMetaData(metaData) {
      self.page = metaData.page
      self.sortResult = metaData.sort
    },
    setBeginDate(beginDate) {
      self.beginDate = beginDate
    },
    setEndDate(endDate) {
      self.endDate = endDate
    },
    toggleDisplayBeginDate() {
      self.displayBeginDate = !self.displayBeginDate
    },
    toggleDisplayEndDate() {
      self.displayEndDate = !self.displayEndDate
    },
    setSelectedInCollectionOptions(selectedInCollectionOptions) {
      self.selectedInCollectionOptions = selectedInCollectionOptions
    },
    setSearchTitle(searchTitle) {
      self.searchTitle = searchTitle
    },
    setSearchArtist(searchArtist) {
      self.searchArtist = searchArtist
    },
    setSearchInvNo(searchInvNo) {
      self.searchInvNo = searchInvNo
    },
    setSearchDescription(searchDescription) {
      self.searchDescription = searchDescription
    },
    clearAllFilterOnAdvancedFilter() {
      self.searchTerm = ''
      self.searchTitle = ''
      self.searchArtist = ''
      self.searchInvNo = ''
      self.searchDescription = ''
      self.selectedInCollectionOptions = 'All'
      self.beginDate = new Date('July 20, 1700 00:20:18')
      self.endDate = new Date()
      self.displayBeginDate = false
      self.displayEndDate = false
    },
  }))
