/* eslint-disable @typescript-eslint/no-explicit-any */
import { IEventCard } from '@interfaces'
import { v4 as uuidv4 } from 'uuid'

const generateDummyEventCard = (): IEventCard => ({
  id: uuidv4(),
  type: 'event',
  title: 'Lorem ipsum',
  imgUrl: '',
  url: '',
})

const insert = (arr: any[], index: number, newItem: any) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
]

export const expandedExhibitionData = (collection: IEventCard[]) => {
  if (collection.length > 7) {
    return collection
  }

  const expandedCollectionStep1 = insert(
    collection,
    4,
    generateDummyEventCard()
  )
  const expandedCollectionStep2 = insert(
    expandedCollectionStep1,
    6,
    generateDummyEventCard()
  )

  return expandedCollectionStep2
}
