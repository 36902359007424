import { mq } from '@/src/lib/hooks'
import { DrawerBody } from '@chakra-ui/react'
import styled from '@emotion/styled'
import theme from '@styles/theme'

export const Drawer__Body = styled(DrawerBody)`
  position: relative;
  padding: ${theme.space.padding.primary};

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${theme.colors.white.primary3};
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.red.primary2};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.red.primary3};
  }

  ${mq('lg')} {
    padding-left: ${theme.space.padding.primary};
    padding-top: 84px;
  }
`
