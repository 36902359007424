import { useState } from 'react'
import { IVimeoPlayer } from '@interfaces'
import { useStore } from '@store'

export const useVimeoPlayer = (vimeoCollection: IVimeoPlayer[]) => {
  const [vimeoVideos, setVimeoVideos] = useState(vimeoCollection)
  const {
    ui: { setDisplayBannerSlider },
  } = useStore()

  const handlePlayerPause = (videoId) => {
    setVimeoVideos((oldState) => {
      const copiedOldState = [...oldState]
      const currentIndex = copiedOldState.findIndex(
        (oldState) => oldState.id === videoId
      )
      if (currentIndex !== -1) {
        copiedOldState[currentIndex].paused = true
      }
      return copiedOldState
    })
  }

  const handlePlayerPlay = (videoId) => {
    setVimeoVideos((oldState) => {
      const copiedOldState = [...oldState]
      const currentIndex = copiedOldState.findIndex(
        (oldState) => oldState.id === videoId
      )
      if (currentIndex !== -1) {
        copiedOldState[currentIndex].paused = false
      }
      return copiedOldState
    })
    setTimeout(() => {
      setDisplayBannerSlider(false)
    }, 1500)
  }

  const getPausedVideoState = (videoId) => {
    const currentVideoIndex = vimeoVideos.findIndex(
      (video) => video.id === videoId
    )

    if (currentVideoIndex !== -1) {
      return vimeoVideos[currentVideoIndex].paused
    }

    return false
  }

  const stopPlayingVideo = () => {
    const findPlayingIndex = vimeoVideos.findIndex((vimeo) => !vimeo.paused)
    if (findPlayingIndex !== -1) {
      handlePlayerPause(vimeoVideos[findPlayingIndex].id)
    }
  }

  return {
    vimeoVideos,
    setVimeoVideos,
    handlePlayerPause,
    handlePlayerPlay,
    getPausedVideoState,
    stopPlayingVideo,
  }
}
