import {
  ThreeDTour,
  OnlineExhibitionMap,
  AudioGuide,
  VirtualTour,
  Catalog,
} from '@icon'
import { Text, Flex } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

interface IExhibitionAndEventTag {
  type:
    | '3DTour'
    | 'OnlineExhibitionMap'
    | 'VoiceGuide'
    | 'VirtualTour'
    | 'Catalog'
  textColor?: string
}

const ExhibitionAndEventTag = ({
  type,
  textColor = 'white.primary',
}: IExhibitionAndEventTag) => {
  const { t } = useTranslation()

  const Icon = () => {
    switch (type) {
      case '3DTour':
        return <ThreeDTour color="red.primary" />

      case 'Catalog':
        return <Catalog color="red.primary" />

      case 'OnlineExhibitionMap':
        return <OnlineExhibitionMap color="red.primary" />

      case 'VirtualTour':
        return <VirtualTour color="red.primary" />

      case 'VoiceGuide':
        return <AudioGuide color="red.primary" />

      default:
        return <ThreeDTour color="red.primary" />
    }
  }

  const getLabel = () => {
    switch (type) {
      case '3DTour':
        return t('common:collectionWorkTag.3DTour')

      case 'Catalog':
        return t('common:collectionWorkTag.catalog')

      case 'OnlineExhibitionMap':
        return t('common:collectionWorkTag.onlineExhibitionMap')

      case 'VirtualTour':
        return t('common:collectionWorkTag.virtualTour')

      case 'VoiceGuide':
        return t('common:collectionWorkTag.voiceGuide')

      default:
        return t('common:collectionWorkTag.3DTour')
    }
  }

  return (
    <Flex alignItems="center">
      <Icon />
      <Text
        ml="10px"
        fontWeight="light"
        color={textColor}
        fontSize="sm"
        as="span"
        whiteSpace="pre"
      >
        {getLabel()}
      </Text>
    </Flex>
  )
}

export default ExhibitionAndEventTag
