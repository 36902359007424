import { IMainTypeOfEvent, ISortNameOfEvent } from '@interfaces'

/* Drupal Endpoints */
export const PRESS_RELEASE = {
  en: 'en/api/pressrelease',
  tr: 'tr/api/pressrelease',
}

export const PUBLICATIONS = {
  en: 'en/api/publication',
  tr: 'tr/api/publication',
}

export const PLANT = {
  en: 'en/api/plant',
  tr: 'tr/api/plant',
}

export const ARTICLE = {
  en: 'en/api/article',
  tr: 'tr/api/article',
}

export const TAGS_OF_ARTICLE = {
  en: 'en/api/articletaxonomyterms',
  tr: 'tr/api/articletaxonomyterms',
}

export const FILTER_ARTICLE_BY_TAG = {
  en: 'en/api/taxonomy/term',
  tr: 'tr/api/taxonomy/term',
}

export const HEADLINE = {
  en: 'en/api/headline',
  tr: 'tr/api/headline',
}

export const PAGE = {
  en: 'en/api/page',
  tr: 'tr/api/page',
}

export const DYNAMIC_PAGES = {
  en: 'en/api/page/dynamicpages',
  tr: 'tr/api/page/dynamicpages',
}

export const PAGE_TAB = {
  en: 'en/api/pagetab',
  tr: 'tr/api/pagetab',
}

export const REST_BLOCK_FOR_PAGE_SECTION = {
  en: 'en/api/pagerestblock',
  tr: 'tr/api/pagerestblock',
}

export const ACCORDION_FOR_PAGE_SECTION = {
  en: 'en/api/pageaccordion',
  tr: 'tr/api/pageaccordion',
}

/*
 * tr/api/contact/ssm
 * tr/api/contact/seed
 * tr/api/contact/msa
 */
export const CONTACT_FOR_PAGE_SECTION = {
  en: 'en/api/contact',
  tr: 'tr/api/contact',
}

export const KIDS_PAGE = {
  en: 'en/api/kids',
  tr: 'tr/api/kids',
}

export const PROJECTS_AND_SW_DEVS_OF_RESEARCH_AREA = {
  en: 'en/api/rdproject',
  tr: 'tr/api/rdproject',
}

export const PROJECTS_OF_RESEARCH_AREA = {
  en: 'en/api/rdproject/type/project',
  tr: 'tr/api/rdproject/type/project',
}

export const SW_DEV_OF_RESEARCH_AREA = {
  en: 'en/api/rdproject/type/swdevelopment',
  tr: 'tr/api/rdproject/type/swdevelopment',
}

export const TABS_OF_RESEARCH_AREA_DETAIL = {
  en: 'en/api/rdtab',
  tr: 'tr/api/rdtab',
}

export const EVENT_OF_RESEARCH_AREA_DETAIL = {
  en: 'en/api/events',
  tr: 'tr/api/events',
}

export const CASE_STUDIES_OF_RESEARCH_AREA_DETAIL = {
  en: 'en/api/rdcs',
  tr: 'tr/api/rdcs',
}

export const TEAM_OF_RESEARCH_AREA_DETAIL = {
  en: 'en/api/rdprojectteam',
  tr: 'tr/api/rdprojectteam',
}

export const PARTNER_OF_RESEARCH_AREA_DETAIL = {
  en: 'en/api/rdpartner',
  tr: 'tr/api/rdpartner',
}

export const ONLINE_EXHIBITIONS = {
  en: 'en/api/onlineexhibitions',
  tr: 'tr/api/onlineexhibitions',
}

export const TAB_OF_ONLINE_EXHIBITION = {
  en: 'en/api/oetab',
  tr: 'tr/api/oetab',
}

export const PERIOD_OF_ONLINE_EXHIBITION = {
  en: 'en/api/oerelatedperiod',
  tr: 'tr/api/oerelatedperiod',
}

export const OBJECT_OF_ONLINE_EXHIBITION = {
  en: 'en/api/object',
  tr: 'tr/api/object',
}

export const INSTAGRAM_FEED = {
  en: 'en/api/instagramfeed',
  tr: 'tr/api/instagramfeed',
}

export const SELECTIONS_FROM_COLLECTION = {
  en: 'en/api/selectionsfromcollections',
  tr: 'tr/api/selectionsfromcollections',
}

export const EXTENDED_EMUSEUM = {
  en: 'en/api/additionalEMobjectdata',
  tr: 'tr/api/additionalEMobjectdata',
}

export const SHOP = {
  en: 'en/api/shopproduct',
  tr: 'tr/api/shopproduct',
}

export const VIDEO = {
  en: 'en/api/pagevideo',
  tr: 'tr/api/pagevideo',
}

export const TOUR_CATEGORY = {
  en: 'en/api/tourcategory',
  tr: 'tr/api/tourcategory',
}

export const TOUR_CATEGORY_DETAIL = {
  en: 'en/api/tourcategorybyslug',
  tr: 'tr/api/tourcategorybyslug',
}

export const TICKETS_OF_TOUR_CATEGORY = {
  en: 'en/api/toursbytourcategoryID',
  tr: 'tr/api/toursbytourcategoryID',
}

export const FAQ_OF_TOUR_CATEGORY = {
  en: 'en/api/tourfaqbyID',
  tr: 'tr/api/tourfaqbyID',
}

export const TABS_OF_TOUR_CATEGORY = {
  en: 'en/api/tourtab',
  tr: 'tr/api/tourtab',
}

export const VIDEOS_OF_TAB_CONTENT_OF_TOUR_CATEGORY = {
  en: 'en/api/tourvideobyID',
  tr: 'tr/api/tourvideobyID',
}

export const CONSERVATION = {
  en: 'en/api/conservation',
  tr: 'tr/api/conservation',
}

export const NAVIGATION = {
  en: 'en/api/menu_items/en-new-menu',
  tr: 'tr/api/menu_items/tr-new-menu',
}

export const FORM = 'webform_rest/submit?_format=json'

export const SEARCH_RESULTS = {
  en: 'en/api/search',
  tr: 'tr/api/search',
}

/* eMuseum Endpoints */
export const COLLECTIONS = 'collections/json'
export const COLLECTION_DETAIL = (id) => `collections/${id}/json`
export const OBJECTS_OF_COLLECTION = (id) => `collections/${id}/objects/json`

export const ARTIST = 'people/json'
export const ARTIST_DETAIL = (id) => `people/${id}/json`
export const RELATED_OBJECTS_OF_ARTIST = (id) => `people/${id}/objects/json`

export const EXHIBITION = 'exhibitions/json'
export const EXHIBITION_DETAIL = (id) => `exhibitions/${id}/json`
export const OBJECTS_OF_EXHIBITION = (id) => `exhibitions/${id}/objects/json`
export const EXHIBITIONS_BY_TYPE = (sortType) =>
  `exhibitions/json?filter=exhibitionType:${sortType}`

export const EVENT = (mainType: IMainTypeOfEvent) => {
  if (mainType === 'Workshop') {
    return 'events/json?filter=eventType:Learning-Kid-Workshop'
  }

  return `events/json?filter=${
    mainType === 'All' ? '' : 'subTitle:' + mainType
  }`
}

export const EVENT_DETAIL = (id) => `events/${id}/json`
export const EVENT_BY_SORT = (
  mainType: IMainTypeOfEvent,
  sortType: ISortNameOfEvent
) =>
  `events/json?filter=sortName:${sortType}${
    mainType === 'All' ? '' : ';subTitle:' + mainType
  }`

export const WORKSHOPS_BY_SORT = (sortType: ISortNameOfEvent) =>
  `events/json?filter=eventType:Learning-Kid-Workshop;sortName:${sortType}`

export const CONSERVATION_EVENTS =
  'events/json?filter=subTitle:Event;eventType:Event-Conservation'

export const OBJECT = 'objects/json'
export const OBJECT_DETAIL = (id) => `objects/${id}/json`
export const COMMON_OBJECT_SEARCH = (searchTerm) =>
  `search/${searchTerm}/objects/json`
export const ADVANCED_OBJECT_SEARCH = (filterParams) =>
  `advancedsearch/Objects/${filterParams}/json`

// export const GOOGLE_MAP_PLACE = 'maps/api/place/details/json'
export const GOOGLE_MAP_PLACE = '/ajax/get-google-places'
