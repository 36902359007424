import {
  ObjectHttpService,
  CollectionsHttpService,
  OnlineExhibitionHttpService,
} from '@httpServices'
import {
  getPhotoCollectionFromObject,
  generateArtollectionWorkDetailAccordionItems,
  generateSelectionsFromCollectionDataFrom,
} from '@utils'
import { IRelatedObjtectsPageNames } from '@interfaces'
import {
  ExhibitionService,
  OnlineExhibitionsService,
  ArtistService,
} from '@services'

export const ObjectService = {
  /**
   * Get object
   */
  getObject: async (id: string) => {
    const object = await ObjectHttpService.getObject(id)
    return object
  },
  /**
   * Get object detail page
   */
  getObjectDetailPage: async (
    id: string,
    locale: string,
    activePage: IRelatedObjtectsPageNames,
    slug: string,
    periodId?: string,
    dId?: string
  ) => {
    const object = await ObjectHttpService.getObject(id)

    const transformedObject = object.object[0]

    const photoCollection = getPhotoCollectionFromObject(transformedObject)

    const accordionItems = await generateArtollectionWorkDetailAccordionItems(
      transformedObject,
      locale
    )

    /*
     * related objects
     * get active page and slug
     * get objects by slug
     */

    if (activePage === 'collection' || activePage === 'archive') {
      // selections from works
      const objectsOfCollection =
        await CollectionsHttpService.getObjectsOfCollection(slug)
      transformedObject.relatedObjects =
        generateSelectionsFromCollectionDataFrom(
          slug,
          objectsOfCollection.objects,
          activePage,
          locale
        )
          .filter((object) => object.id !== id)
          .slice(0, 10) || []
    }

    if (activePage === 'exhibition') {
      const initObjectsOfExhibition =
        await ExhibitionService.getObjectsOfExhibition(slug, locale)

      transformedObject.relatedObjects = initObjectsOfExhibition
        .filter((object) => object.id !== id)
        .slice(0, 10)
    }

    if (activePage === 'online') {
      const periodDetail =
        await OnlineExhibitionsService.getPeriodOfOnlineExhibition(
          locale,
          periodId,
          slug
        )

      // prepare related objects
      if (
        periodDetail &&
        periodDetail.collection &&
        periodDetail.collection.length > 0
      ) {
        transformedObject.relatedObjects = periodDetail.collection.filter(
          (object) => object.id != id
        )
      }

      // prepare audio file with did from objects service on drupal
      const audioFileUrl = await ObjectService.getAudioObjectFromDrupal(
        locale,
        dId
      )

      transformedObject.audioFileUrl = audioFileUrl || null
    }

    if (activePage === 'artist') {
      const relatedObjects = await ArtistService.getRelatedObjects(locale, slug)

      if (relatedObjects && relatedObjects.length > 0) {
        transformedObject.relatedObjects = relatedObjects.filter(
          (object) => object.id != id
        )
      }
    }

    transformedObject.photoCollection = photoCollection
    transformedObject.accordionItems = accordionItems

    return transformedObject
  },
  /**
   * Get object detail page
   */
  getObjectPureDetailPage: async (id: string, locale: string) => {
    const object = await ObjectHttpService.getObject(id)

    const transformedObject = object.object[0]

    const photoCollection = getPhotoCollectionFromObject(transformedObject)

    const accordionItems = await generateArtollectionWorkDetailAccordionItems(
      transformedObject,
      locale
    )

    transformedObject.photoCollection = photoCollection
    transformedObject.accordionItems = accordionItems

    return transformedObject
  },
  getAudioObjectFromDrupal: async (locale, drupalId) => {
    const objectDetailOfOnlineExhibition =
      await OnlineExhibitionHttpService.getObjectOfOnlineExhibition(
        locale,
        drupalId
      )

    if (
      !objectDetailOfOnlineExhibition ||
      objectDetailOfOnlineExhibition.length != 1
    ) {
      return null
    }

    const audioFileUrl =
      (objectDetailOfOnlineExhibition[0]?.field_object_audio_file &&
        objectDetailOfOnlineExhibition[0]?.field_object_audio_file[0]?.url) ||
      null

    return audioFileUrl
  },
}
