import { observer } from 'mobx-react-lite'
import * as S from './styles'
import cn from 'classnames'
import { Text } from '@chakra-ui/react'
import { Cross } from '../../icons'
import { IFonts } from '@interfaces'

interface IHamburgrMenu {
  onClick: () => void
  label: string
  isSticky?: boolean
  isOpened?: boolean
  onDrawerMenu?: boolean
  activeFontFamily?: IFonts
}

const HamburgerMenu = observer(
  ({
    onClick,
    label,
    isSticky,
    isOpened = false,
    onDrawerMenu = false,
    activeFontFamily = 'body',
  }: IHamburgrMenu) => {
    return (
      <S.HamburgerMenu
        role="button"
        aria-pressed={isOpened}
        aria-label="Toggle side menu button"
        onClick={onClick}
      >
        {isOpened ? (
          <Cross color="white.primary" className="HamburgerMenu__Close" />
        ) : (
          <S.HamburgerMenu__Lines
            className={cn('HamburgerMenu__Lines', {
              'HamburgerMenu__Lines--isSticky': isSticky,
            })}
          >
            <div className="line line--first"></div>
            <div className="line line--sec"></div>
            <div className="line line--thr"></div>
          </S.HamburgerMenu__Lines>
        )}

        <Text
          fontSize="md"
          fontWeight="medium"
          fontFamily={activeFontFamily}
          as="span"
          ml={'6px'}
          className={cn('HamburgerMenu__Label', {
            'HamburgerMenu__Label--onDrawer': onDrawerMenu,
          })}
        >
          {label}
        </Text>
      </S.HamburgerMenu>
    )
  }
)

export default HamburgerMenu
