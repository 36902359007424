/**
 * added json api param to video url for PhotoAndVideoSliderSection component
 * @param videoUrl string
 * @param videoProvider 'youtube' | 'vimeo'
 * @returns string
 */
export const prepareVideoUrl = (
  videoUrl: string,
  videoProvider: 'youtube' | 'vimeo'
) => {
  const url = new URL(videoUrl)
  if (videoProvider === 'youtube') {
    url.searchParams.set('enablejsapi', '1')
    return url.href
  } else {
    url.searchParams.set('api', '1')
    return url.href
  }
}
