import { Box, AspectRatio, AspectRatioProps } from '@chakra-ui/react'
import { HERO_ASPECT_RATIO, HERO_ASPECT_RATIO_MOBILE } from '../../../lib/const'

interface IHeroAspectRatio extends AspectRatioProps {
  children: JSX.Element
}

const HeroAspectRatio = ({ children, ...rest }: IHeroAspectRatio) => (
  <AspectRatio
    as="section"
    ratio={{ base: HERO_ASPECT_RATIO_MOBILE, lg: HERO_ASPECT_RATIO,}}
    minHeight={{ base: 'calc(100vh - 268px)' }}
    sx={{
      '&>*:not(style)': {
        overflow: 'visible',
      },
    }}
    {...rest}
  >
    <Box width="full" height="full">
      {children}
    </Box>
  </AspectRatio>
)

export default HeroAspectRatio
