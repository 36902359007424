import { createIcon } from '@chakra-ui/icons'

const Bubble = createIcon({
  displayName: 'Bubble',
  defaultProps: {
    width: 222,
    height: 222,
    color: 'grey.primary5',
  },
  viewBox: '0 0 222 222',
  path: <circle cx="111" cy="111" r="111" fill="currentColor" />,
})

export default Bubble
