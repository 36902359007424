import { HeadlineHttpService } from '@httpServices'
import { IHeadlineSlider } from '@interfaces'

export const HeadlineService = {
  /**
   * Get all headline
   */
  getAllHeadline: async (
    locale: string | 'en' | 'tr'
  ): Promise<IHeadlineSlider[]> => {
    const collection = await HeadlineHttpService.getAllHeadline(locale)

    if (!collection || collection.length === 0) {
      return []
    }

    const transformedCollections: IHeadlineSlider[] = collection.map((col) => {
      const initCol: IHeadlineSlider = {
        id: '',
        title: '',
        description: '',
        isWithVideo: false,
        rank: 0,
        isExternalLink: false,
      }

      initCol.id = col?.nid[0]?.value || ''
      initCol.title = col?.field_headline_title[0]?.value || ''
      initCol.description = col?.field_headline_subtitle[0]?.value || ''
      initCol.rank = col?.field_headline_rank[0]?.value || ''
      initCol.imgUrl = ''
      initCol.mobileImgUrl = ''

      // prepare img url
      const imgUrl = col?.field_headline_banner_image[0]?.url || false
      if (imgUrl) {
        initCol.imgUrl = imgUrl
      }

      // prepare mobile img url
      const mobileImgUrl = col?.field_headline_mobile_banner[0]?.url || false
      if (mobileImgUrl) {
        initCol.mobileImgUrl = mobileImgUrl
      }

      const externalImgUrl = col?.field_headline_banner_url[0]?.value || false

      if (externalImgUrl) {
        initCol.imgUrl = externalImgUrl
      }

      // prepare video url
      const vimeoId = col?.field_headline_video_vimeo_id[0]?.value || false

      if (vimeoId) {
        initCol.isWithVideo = true
        initCol.videoProvider = 'vimeo'
        initCol.vimeoId = vimeoId
      }

      // prepare mobile video url

      const mobileVimeoId =
        col?.field_headline_mobile_vimeo_id[0]?.value || false

      if (mobileVimeoId) {
        initCol.isWithVideo = true
        initCol.videoProvider = 'vimeo'
        initCol.mobileVimeoId = mobileVimeoId
      }

      // prepare link
      const linkUrl = col?.field_headline_link_url[0]?.value || false
      const linkLabel = col?.field_headline_link_title[0]?.value || false
      const isExternalLink =
        col?.field_headline_link_url_is_exter[0]?.value || false

      if (linkUrl && linkLabel) {
        initCol.linkUrl = linkUrl
        initCol.linkLabel = linkLabel
        initCol.isExternalLink = isExternalLink
      }

      // prepare bgColor
      const bgColor = col?.field_headline_bg_color[0]?.value || false

      if (bgColor) {
        initCol.bgColor = bgColor
      }

      // prepare mobile bgColor
      const mobileBgColor =
        col?.field_headline_mobile_bg_color[0]?.value || false

      if (mobileBgColor) {
        initCol.mobileBgColor = mobileBgColor
      }
      // prepare font Color
      const fontColor = col?.field_headline_font_color[0]?.value || false

      if (fontColor) {
        initCol.fontColor = fontColor
      }

      // prepare font Color
      const mobileFontColor =
        col?.field_headline_mobile_font_color[0]?.value || false

      if (mobileFontColor) {
        initCol.mobileFontColor = mobileFontColor
      }
      // prepare bgColorChevron
      const bgColorChevron =
        col?.field_headline_bg_color_chevron[0]?.value || false

      if (bgColorChevron) {
        initCol.bgColorChevron = bgColorChevron
      }

      return initCol
    })

    return transformedCollections.sort((a, b) =>
      a.rank > b.rank ? 1 : b.rank > a.rank ? -1 : 0
    )
  },
}
