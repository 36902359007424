import { createIcon } from '@chakra-ui/icons'

const BookForKids = createIcon({
  displayName: 'BookForKids',
  viewBox: '0 0 32 25',
  defaultProps: {
    width: 32,
    height: 25,
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M12.75 1C13.5625 1 14.9405 1.37683 15.7676 2.06858L16.0065 2.31627L16.2291 2.06858C17.1294 1.31314 18.0312 1 19.25 1H27.375C28.1794 1 29 1.88459 29 2.76918V20.4609C29 21.3455 28.1875 22.2301 27.375 22.2301H19.25C18.8438 22.2301 18.0703 22.7379 17.0774 23.5181C17.0246 23.5733 16.9681 23.6241 16.9084 23.6702C16.639 23.8905 16.3098 24.0065 15.973 23.9997C15.6362 23.993 15.3111 23.8639 15.0494 23.6331L14.713 23.3075C14.1475 22.7874 13.1692 22.2301 12.75 22.2301H4.625C3.82063 22.2301 3 21.3278 3 20.4609V2.76918C3 1.89166 3.81738 1 4.625 1H12.75ZM19.25 2.76918C18.5025 2.76918 17.8151 2.8541 17.1229 3.45738L16.9717 3.59361C16.9142 3.646 16.8649 3.70813 16.8255 3.77761C16.7865 3.84552 16.7656 3.92395 16.7654 4.00406V21.7471L17.5697 21.1668C18.1596 20.7546 18.6813 20.4592 19.25 20.4592H27.05C27.1362 20.4592 27.2189 20.4219 27.2798 20.3555C27.3408 20.2892 27.375 20.1992 27.375 20.1053V3.12301C27.375 3.02917 27.3408 2.93917 27.2798 2.87281C27.2189 2.80645 27.1362 2.76918 27.05 2.76918H19.25ZM12.75 2.76918H4.95C4.8638 2.76918 4.78114 2.80645 4.72019 2.87281C4.65924 2.93917 4.625 3.02917 4.625 3.12301V20.1071C4.625 20.3017 4.77125 20.4609 4.95 20.4609H12.75C13.4439 20.4609 14.5099 21.1155 15.2362 21.664V4.03767C15.236 3.96843 15.2233 3.89992 15.1989 3.83599L15.155 3.74399L15.077 3.63253C14.5213 3.03455 13.5625 2.76918 12.75 2.76918Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M1.29688 2.15835C3.45041 1.8507 5.56087 2.30795 7.65366 2.8048C8.49027 3.00342 9.47359 3.28964 10.3472 3.28964C11.4785 3.28964 12.6098 3.28964 13.7411 3.28964C14.3739 3.28964 14.9463 3.12803 15.5907 3.12803C17.4263 3.12803 16.1146 6.09731 15.878 7.09653C15.7893 7.47105 15.7989 7.99594 15.878 8.37148C16.1498 9.66255 15.6804 10.9628 15.6804 12.2681C15.6804 13.1143 16.0037 13.8969 16.0037 14.7283C16.0037 15.3978 15.6751 15.9704 15.5188 16.6137C15.3156 17.4505 15.1487 19.0445 15.3931 19.8999C15.4947 20.2555 15.8221 20.5272 15.8421 20.9055C15.858 21.2077 15.7879 21.5466 15.878 21.8392C15.9868 22.193 16.059 22.1134 15.8061 21.9111C15.3473 21.544 14.1945 21.5014 13.6513 21.3903C12.3786 21.13 11.0962 20.9947 9.82646 20.7439C8.66931 20.5153 7.49942 20.652 6.3428 20.4206C5.73195 20.2985 4.70675 19.9477 4.08021 20.1333C3.72823 20.2376 3.45049 20.3998 3.07462 20.4206C1.95042 20.4831 1.48019 18.3623 1.45849 17.3859C1.43859 16.4906 1.91699 15.6847 2.10494 14.818C2.36273 13.6294 2.71802 12.2533 2.46408 11.0471C2.33136 10.4167 2.48513 9.74622 2.39225 9.10771C2.2799 8.33531 2.13369 7.49553 2.10494 6.71943C2.05178 5.28405 1.78171 3.9294 1.78171 2.48157"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3261 3.28945C17.7609 2.33292 19.016 1.47124 20.7077 1.34111C22.5514 1.19929 24.3684 1.09838 26.2025 1.51171C27.0042 1.69237 27.497 1.51171 28.2855 1.51171C28.9582 1.51171 29.7559 0.444661 30.3057 1.11665C31.3246 2.36205 30.5481 5.24109 30.5481 6.71924C30.5481 9.45912 30.9614 12.1272 30.6289 14.8538C30.5032 15.8848 30.4856 16.8231 30.2608 17.8346C30.0957 18.5777 30.3685 19.3721 30.3685 20.0164C30.3685 20.0597 23.7581 18.6351 22.6291 19.3251C21.2557 20.1643 19.4637 21.3901 17.7807 21.3901C17.3446 21.3901 16.8281 21.6311 16.6135 22.0366C16.4759 22.2964 15.8608 23.5301 15.6797 23.1679"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})

export default BookForKids
