import { v4 as uuidv4 } from 'uuid'
import { IEventCard, ITabData } from '@interfaces'

type INPUT_TAB_LABEL =
  | 'relatedEvents'
  | 'otherExhibitions'
  | 'allTab'
  | 'exhibitions'
  | 'onlineExhibitions'
  | 'events'
  | 'learningPrograms'
  | 'workshops'
  | 'tours'

export const generateTabCollection = (
  tabLabelTx: INPUT_TAB_LABEL,
  tabContent: IEventCard[]
): ITabData => {
  switch (tabLabelTx) {
    case 'allTab':
      return {
        id: uuidv4(),
        tabLabelTx: 'common:tabs.allTab',
        tabContent,
      }

    case 'relatedEvents':
      return {
        id: uuidv4(),
        tabLabelTx: 'common:tabs.relatedEvents',
        tabContent,
      }

    case 'otherExhibitions':
      return {
        id: uuidv4(),
        tabLabelTx: 'common:tabs.otherExhibitions',
        tabContent,
      }

    case 'events':
      return {
        id: uuidv4(),
        tabLabelTx: 'common:tabs.events',
        tabContent,
      }

    case 'exhibitions':
      return {
        id: uuidv4(),
        tabLabelTx: 'common:tabs.exhibitions',
        tabContent,
      }

    case 'onlineExhibitions':
      return {
        id: uuidv4(),
        tabLabelTx: 'common:tabs.onlineExhibitions',
        tabContent,
      }

    case 'learningPrograms':
      return {
        id: uuidv4(),
        tabLabelTx: 'common:tabs.learningPrograms',
        tabContent,
      }

    case 'workshops':
      return {
        id: uuidv4(),
        tabLabelTx: 'common:tabs.workshops',
        tabContent,
      }

    case 'tours':
      return {
        id: uuidv4(),
        tabLabelTx: 'common:tabs.tours',
        tabContent,
      }

    default:
      return {
        id: uuidv4(),
        tabLabelTx,
        tabContent,
      }
  }
}
