import { Box, Text, AspectRatio } from '@chakra-ui/react'
import { IDiscoverSsmCard } from '@interfaces'
import { Markup } from 'interweave'

const DiscoverSsmCard = ({
  title,
  description,
  imgUrl,
}: IDiscoverSsmCard) => {

  return (
    <Box
      width={{ base: '315px', lg: '407px' }}
      overflow="hidden"
      className="discover-ssm-card"
    >
      <AspectRatio
        ratio={2 / 3}
        backgroundImage={`url(${
          imgUrl || '/img/placeholders/no-image-available-icon.jpeg'
        })`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        _after={{
          content: `""`,
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          background: 'inherit',
          backgroundSize: 'cover',
          transformOrigin: 'center',
          transition: 'transform 0.6s ease-in-out',
        }}
        sx={{
          '.discover-ssm-card:hover &': {
            '&:after': {
              transform: 'scale(1.05)',
            },
          },
        }}
      >
        <Box>
          <Box
            _before={{
              content: `""`,
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              width: 'full',
              height: '80%',
              mt: 'auto',
              bgImage:
                'linear-gradient(180deg, rgba(13, 16, 29, 0.8) 0%, rgba(0, 0, 0, 0.0046875) 99.99%, rgba(13, 16, 29, 0) 100%)',
              transform: 'rotate(-180deg)',
              zIndex: 1,
            }}
          />
          <Box
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            zIndex="1"
            display="flex"
            alignItems="flex-end"
            px="padding.primary"
            py={{ base: '10', lg: '16' }}
          >
            <Box>
              <Text
                fontSize="lg"
                color="white.primary"
                as="h5"
                mb={1}
                noOfLines={2}
                whiteSpace="pre"
              >
                {title}
              </Text>
              {description && (
                <Box
                  sx={{
                    '& p': {
                      fontSize: 'sm',
                      noOfLines: 3,
                      color: 'white.primary',
                    },
                  }}
                >
                  <Markup content={description} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </AspectRatio>
    </Box>
  )
}

export default DiscoverSsmCard
