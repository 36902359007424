import { Text } from '@chakra-ui/react'
import { Search } from '@icon'
import cn from 'classnames'
import * as S from './styles'
import { IFonts } from '@interfaces'

interface ISearchBox {
  onClick: () => void
  label: string
  isSticky?: boolean
  onDrawerMenu?: boolean
  isActive: boolean
  activeFontFamily?: IFonts
}

const SearchBox = ({
  onClick,
  label,
  isSticky = false,
  onDrawerMenu = false,
  isActive,
}: ISearchBox) => (
  <S.SearchBox
    className={cn({
      'SearchBox--active': isActive,
    })}
    onClick={onClick}
  >
    <Search
      width={'15px'}
      height={'15px'}
      className={cn('SearchBox__Icon', {
        'SearchBox__Icon--isSticky': isSticky,
        'SearchBox__Icon--onDrawer': onDrawerMenu,
      })}
    />
    <Text
      as="span"
      color="white.primary"
      fontWeight="bold"
      fontSize="15px"
      fontFamily="heading"
      className={cn('SearchBox__Label', {
        'SearchBox__Label--isSticky': isSticky,
        'SearchBox__Label--onDrawer': onDrawerMenu,
      })}
    >
      {label}
    </Text>
  </S.SearchBox>
)

export default SearchBox
