import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'

export const StickyBox = styled(Box)`
  position: ${({ isSticky }) => (isSticky ? 'fixed' : 'relative')};
  top: ${({ isSticky }) => (isSticky ? '0' : '-100%')};
  width: 100%;
  transition: all 0.35s ease;
  cursor: pointer;
`
