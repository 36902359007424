import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Checkbox, Link } from '@ui'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { FormService } from '@services'
import { useRouter } from 'next/router'

type PdfDownloadFormInputs = {
  name: string
  surname: string
  email: string
  companyName: string
  pdfUrl: string
  kvkk: boolean
}

interface IPressReleasePdfForm {
  isOpen: boolean
  onClose: () => void
  pdfUrl: string
}

const PressReleasePdfForm = ({
  isOpen,
  onClose,
  pdfUrl,
}: IPressReleasePdfForm) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const toast = useToast()

  const schema = yup.object().shape({
    name: yup.string().required(t('form:error.requiredField')),
    surname: yup.string().required(t('form:error.requiredField')),
    email: yup
      .string()
      .email(t('form:error.invalidEmail'))
      .required(t('form:error.requiredField')),
    companyName: yup.string().required(t('form:error.requiredField')),
    kvkk: yup.bool().oneOf([true], t('form:error.requiredField')),
  })

  const handlePdfDownload = () => {
    window.open(pdfUrl, '_blank')
  }
  //console.log( pdfUrl)
  const { register, handleSubmit, errors, reset } =
    useForm<PdfDownloadFormInputs>({
      resolver: yupResolver(schema),
    })

  const onSubmit = async (values: PdfDownloadFormInputs, e) => {
    e.preventDefault()
    try {
      const response = await FormService.post({
        webform_id: 'pr_register',
        name: values.name,
        surname: values.surname,
        email: values.email,
        companyname: values.companyName,
        pdfurl: pdfUrl,
        locale,
        consent: true,
      })
      if (response) {
        toast({
          title: t('form:success.primary'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        reset()
        onClose()
        setTimeout(() => {
          handlePdfDownload()
        }, 400)
      }
    } catch (error) {
      toast({
        title: t('form:error.commonTitle'),
        description: t('form:error.commonDescription'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bgColor="blackAlpha.primary80"
        transition="all 0.3s ease"
        sx={{
          backdropFilter: 'blur(8px)',
        }}
      />
      <ModalContent bgColor="white.primary">
        <ModalBody py="padding.primary2">
          <Box
            as="form"
            sx={{
              '& > * + *': {
                mt: '6',
              },
            }}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl isInvalid={!!errors?.name?.message}>
              <FormLabel fontWeight="medium" fontSize="sm" mb="2">
                {t('form:field.name')}
              </FormLabel>
              <Input
                type="name"
                name="name"
                px="10px"
                border="1px solid"
                borderColor="black.primary3"
                variant="secondary"
                size="secondary"
                focusBorderColor="black.primary3"
                errorBorderColor="red.primary"
                ref={register}
              />
              <FormErrorMessage color="red.primary">
                {errors?.name?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.surname?.message}>
              <FormLabel fontWeight="medium" fontSize="sm" mb="2">
                {t('form:field.surname')}
              </FormLabel>
              <Input
                type="name"
                name="surname"
                px="10px"
                border="1px solid"
                borderColor="black.primary3"
                variant="secondary"
                size="secondary"
                focusBorderColor="black.primary3"
                errorBorderColor="red.primary"
                ref={register}
              />
              <FormErrorMessage color="red.primary">
                {errors?.surname?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.email?.message}>
              <FormLabel fontWeight="medium" fontSize="sm" mb="2">
                {t('form:field.email')}
              </FormLabel>
              <Input
                type="email"
                name="email"
                px="10px"
                border="1px solid"
                borderColor="black.primary3"
                variant="secondary"
                size="secondary"
                focusBorderColor="black.primary3"
                errorBorderColor="red.primary"
                ref={register}
              />
              <FormErrorMessage color="red.primary">
                {errors?.email?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.companyName?.message}>
              <FormLabel fontWeight="medium" fontSize="sm" mb="2">
                {t('form:field.companyName')}
              </FormLabel>
              <Input
                type="name"
                name="companyName"
                px="10px"
                border="1px solid"
                borderColor="black.primary3"
                variant="secondary"
                size="secondary"
                focusBorderColor="black.primary3"
                errorBorderColor="red.primary"
                ref={register}
              />
              <FormErrorMessage color="red.primary">
                {errors?.companyName?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl d="none" isInvalid={!!errors?.pdfUrl?.message}>
              <FormLabel fontWeight="medium" fontSize="sm" mb="2">
                {t('form:field.pdfUrl')}
              </FormLabel>
              <Input
                type="name"
                value={pdfUrl}
                name="pdfUrl"
                px="10px"
                border="1px solid"
                borderColor="black.primary3"
                variant="secondary"
                size="secondary"
                focusBorderColor="black.primary3"
                errorBorderColor="red.primary"
                ref={register}
              />
              <FormErrorMessage color="red.primary">
                {errors?.pdfUrl?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.kvkk?.message}>
              <Checkbox name="kvkk" ref={register} id="kvkk">
                <Trans
                  i18nKey="legal:press.kvkkLabel"
                  components={[
                    <Text
                      key="kvkkTextComponent"
                      fontWeight="light"
                      fontSize="sm"
                    />,
                    <Link
                      fontWeight="medium"
                      key="kvkkLinkComponent"
                      href="/kvkk"
                      target="_blank"
                    />,
                  ]}
                />
              </Checkbox>
              <FormErrorMessage color="red.primary">
                {errors?.kvkk?.message}
              </FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              disabled={
                !!errors.email ||
                !!errors.kvkk ||
                !!errors.name ||
                !!errors.surname ||
                !!errors.companyName
              }
            >
              {t('common:download')}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PressReleasePdfForm
