import {
  Grid,
  Flex,
  AspectRatio,
  Image,
  Button,
  BoxProps,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { fadeIn } from '@lib/motion'
import { useInViewMotion } from '@lib/hooks'
import { MotionBox, MotionText, Link } from '@ui'

interface ISsmFriendlyCardAdvantages extends BoxProps {
  isReverseOnMobile?: boolean
}

const SsmFriendlyCardAdvantages = ({
  isReverseOnMobile = false,
  ...rest
}: ISsmFriendlyCardAdvantages) => {
  const { t } = useTranslation('common')
  const { controls, ref } = useInViewMotion(true)

  return (
    <MotionBox
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={fadeIn.animationContainer}
    >
      <Grid
        as="section"
        gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}
        templateAreas={{
          base: isReverseOnMobile
            ? `'ssm-card-content' 'ssm-card-img'`
            : `'ssm-card-img' 'ssm-card-content'`,
          lg: `'ssm-card-img ssm-card-content'`,
        }}
        {...rest}
      >
        <AspectRatio ratio={1.85 / 1} gridArea="ssm-card-img">
          <Image
            loading="lazy"
            width="full"
            src="/img/home-page/ssm-friendly-card/SSM_dostkart.webp"
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
            alt={t('ssmFriendlyCardAdvantages.title')}
          />
        </AspectRatio>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          px="padding.primary"
          py={{ base: 'padding.primary', lg: 'padding.primary3' }}
          gridArea="ssm-card-content"
        >
          <MotionText
            variants={fadeIn.up}
            color="red.primary"
            fontSize="3xl"
            fontWeight="600"
            mb={{ base: 3, lg: 5 }}
          >
            {t('ssmFriendlyCardAdvantages.title')}
          </MotionText>
          <MotionText variants={fadeIn.up} fontWeight="light" mb={5}>
            {t('ssmFriendlyCardAdvantages.description')}
          </MotionText>
          <MotionBox variants={fadeIn.up}>
            <Button variant="link" as={Link} href="/membership" target="_blank">
              {t('ssmFriendlyCardAdvantages.buttonLabel')}
            </Button>
          </MotionBox>
        </Flex>
      </Grid>
    </MotionBox>
  )
}

export default SsmFriendlyCardAdvantages
