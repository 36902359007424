/**
 * parse note props to description, 3d tour and google art links for collection detail page
 */
export const getParseNotes = (note: string) => {
  const splittedNote = note.split('[;]')
  const externelLinks = splittedNote[1]

  const emptyLinks = { gac_url: null, '3dvideo_url': null }

  const parseLinks = () => {
    try {
      return JSON.parse(externelLinks)
    } catch (error) {
      return emptyLinks
    }
  }

  return {
    description: splittedNote[0],
    externelLinks: externelLinks === undefined ? emptyLinks : parseLinks(),
  }
}
