import { AspectRatio, Image, Flex, Text } from '@chakra-ui/react'
import { IFonts } from '@interfaces'

interface IOnlineExhibitionForKidsSlider {
  title: string
  imgUrl: string
  children: JSX.Element | JSX.Element[]
  primaryColor?: string
  activeFontFamily?: IFonts
}

const OnlineExhibitionForKidsSlider = ({
  title,
  imgUrl,
  children,
  primaryColor = 'yellow.primary2',
  activeFontFamily = 'body',
}: IOnlineExhibitionForKidsSlider) => (
  <Flex flexDir="column" alignItems="center" width="300px">
    <AspectRatio ratio={1 / 1} mb="2.5" width="full">
      <Image
        src={imgUrl}
        fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
      />
    </AspectRatio>
    <Text
      color={primaryColor}
      mb="5"
      fontSize="lg"
      fontWeight="bold"
      textAlign="center"
      fontFamily={activeFontFamily}
    >
      {title}
    </Text>
    <Flex
      flexWrap="wrap"
      flexDir="row"
      alignItems="center"
      sx={{
        '& > * + *': {
          ml: '2.5',
        },
      }}
    >
      {children}
    </Flex>
  </Flex>
)

export default OnlineExhibitionForKidsSlider
