import { Grid, Skeleton, SkeletonText, Box, Divider } from '@chakra-ui/react'

const ArticleCard = () => (
  <Box>
    <Grid
      gridColumnGap={{ base: '0', lg: '5' }}
      gridTemplateColumns={{ base: '1fr', lg: '300px 1fr' }}
      gridTemplateRows={{ base: 'repeat(5, auto)', lg: 'repeat(4, auto)' }}
      gridTemplateAreas={{
        base: `
    'newsAndBlogPhoto'
    'newsAndBlogText'
    'newsAndBlogText'
    'newsAndBlogText'
    'newsAndBlogText'
  `,
        lg: `
    'newsAndBlogPhoto newsAndBlogText'
    'newsAndBlogPhoto newsAndBlogText'
    'newsAndBlogPhoto newsAndBlogText'
    'newsAndBlogPhoto newsAndBlogText'
  `,
      }}
      placeItems="center"
    >
      <Skeleton gridArea="newsAndBlogPhoto" width="300px" height="150px" />
      <SkeletonText
        gridArea="newsAndBlogText"
        width="full"
        noOfLines={5}
        spacing="3"
      />
    </Grid>
    <Divider
      borderBottomColor="white.primary3"
      opacity="1"
      my="padding.primary"
    />
  </Box>
)

export default ArticleCard
