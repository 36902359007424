import { v4 as uuidv4 } from 'uuid'
import { IExhibitionAndEventSortFilterOption } from '@interfaces'

const EXHIBITION_AND_EVENT_SORT_FILTER_OPTIONS: IExhibitionAndEventSortFilterOption[] =
  [
    {
      id: uuidv4(),
      labelTx: 'exhibition-and-event:home.sort.currentProgram',
      value: 'current',
    },
    /* {
    id: uuidv4(),
    labelTx: 'exhibition-and-event:home.sort.futureProgram',
    value: 'future',
  }, */
    {
      id: uuidv4(),
      labelTx: 'exhibition-and-event:home.sort.pastProgram',
      value: 'past',
    },
  ]

export default EXHIBITION_AND_EVENT_SORT_FILTER_OPTIONS
