import { createIcon } from '@chakra-ui/icons'

const Link = createIcon({
  displayName: 'Link',
  defaultProps: {
    width: 15,
    height: 15,
    color: 'black.primary3',
    fill: 'none',
  },
  viewBox: '0 0 17 17',
  path: (
    <path
      stroke="currentColor"
      d="M5.286 11.357L15.643 1m0 0h-7.5m7.5 0v7.5M4.571 1H1v15h15v-3.571"
    />
  ),
})

export default Link
