import { createIcon } from '@chakra-ui/icons'

const ArrowBottom = createIcon({
  displayName: 'ArrowBottom',
  viewBox: '0 0 13 13',
  defaultProps: {
    width: '13px',
    height: '13px',
  },
  path: (
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M6.303 1.46v9.147m0 0l5.303-5.304m-5.303 5.304L1 5.303"
    />
  ),
})

export default ArrowBottom
