import { createIcon } from '@chakra-ui/icons'

const Checked = createIcon({
  displayName: 'Checked',
  viewBox: '0 0 19 19',
  defaultProps: {
    width: '19px',
    height: '19px',
    fill: 'none',
    transition: 'all 0.3s ease-in',
  },
  path: (
    <>
      <rect width="19" height="19" fill="currentColor" />
    </>
  ),
})

export default Checked
