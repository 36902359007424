const easing = [0.6, -0.05, 0.01, 0.99]

const animationContainer = {
  visible: {
    transition: {
      staggerChildren: 0.2,
      easing,
    },
  },
}

const fadeInUp = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
}

const fadeInRight = {
  hidden: {
    opacity: 0,
    x: '-100%',
  },
  visible: {
    opacity: 1,
    x: '0%',
    transition: {
      duration: 0.5,
    },
  },
}

const fadeInLeft = {
  hidden: {
    opacity: 0,
    x: '100%',
  },
  visible: {
    opacity: 1,
    x: '0%',
    transition: {
      duration: 0.5,
    },
  },
}

export const fadeIn = {
  animationContainer,
  up: fadeInUp,
  right: fadeInRight,
  left: fadeInLeft,
  easing,
}
