import { Box, Stack, Skeleton, Divider } from '@chakra-ui/react'

const PressReleaseSkeletonCard = () => (
  <Stack>
    <Skeleton width="95px" height="22px" />
    <Skeleton height="35px" mb="3.5" />
    <Skeleton height="112px" mb="2.5" />
  </Stack>
)

const PressReleaseCard = () => (
  <Box
    maxWidth="container.secondary"
    mx="auto"
    mt="8"
    px={{ base: 'padding.primary', lg: '0' }}
  >
    <PressReleaseSkeletonCard />
    <Divider
      borderBottomColor="white.primary3"
      opacity="1"
      my="padding.primary"
    />
    <PressReleaseSkeletonCard />
    <Divider
      borderBottomColor="white.primary3"
      opacity="1"
      my="padding.primary"
    />
    <PressReleaseSkeletonCard />
  </Box>
)

export default PressReleaseCard
