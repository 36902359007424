import { Layout, Hero, ContentNotFound } from '@common'
import { ISSRPageData } from '@interfaces'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Flex, Grid, Text, Image, Box, Button, Link } from '@chakra-ui/react'
import { RichText } from '@ui'

export const PageWithHeroBannerTemplate = ({
  pageData,
  global: { navigation },
}: ISSRPageData) => {
  const { asPath } = useRouter()
  const { t } = useTranslation()
  if (pageData.error) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound infoType="serverSideError" />
      </Layout>
    )
  }

  return (
    <Layout
      title={pageData?.data?.pageTitle || t('common:ssmPageTitle')}
      navigation={navigation}
      path={asPath}
    >
      <Hero
        subTitle={pageData.data.hero.subTitle}
        title={pageData.data.hero.title}
        description={pageData.data.hero.description}
        bgImageUrl={pageData.data.hero.bgImageUrl}
        bgImageUrlMobile={pageData.data.hero.bgImageUrlMobile}
      />
      <Flex
        flexDirection="column"
        w="full"
        maxW="container.secondary"
        mx="auto"
        py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
        px={{ base: 'padding.primary', lg: '0' }}
      >
        {pageData.data.htmlContent && (
          <RichText htmlContent={pageData.data.htmlContent} />
        )}
        {pageData.data.alternative_block && (
          <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={6}>
            {pageData.data.alternative_block.map((item, index) => (
              <Box
                height="fit-content"
                boxShadow="lg"
                rounded="md"
                bg="#faf7f0"
                p="0"
                key={index}
                borderRadius="sm"
                overflow="hidden"
                sx={{
                  transition: 'box-shadow 0.2s',
                  ':hover': {
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
                  },
                }}
              >
                <Image
                  boxShadow="inner"
                  src={item.image_url}
                  fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
                  alt={item.title}
                />
                <Box p={2}>
                  <Text
                    textDecoration="underline"
                    mb="2.5"
                    as="h5"
                    overflow="hidden"
                    sx={{
                      display: '-webkit-box',
                      '-webkit-line-clamp': '3å',
                      'line-clamp': '3',
                      '-webkit-box-orient': 'vertical',
                    }}
                  >
                    {item.title}
                  </Text>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="sm"
                    href={item.url}
                    target="_blank"
                  >
                    {t('common:detailedReviewButtonLabel')}
                  </Button>
                </Box>
              </Box>
            ))}
          </Grid>
        )}
      </Flex>
    </Layout>
  )
}
