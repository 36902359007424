import { useRouter } from 'next/router'
import { Layout, PressReleasePdfForm } from '@common'
import {
  Container,
  Box,
  Text,
  Button,
  useDisclosure,
  Image,
} from '@chakra-ui/react'
import { IPressReleaseCard, IGlobal } from '@interfaces'
//import { Markup } from 'interweave'
import useTranslation from 'next-translate/useTranslation'
import { Pdf } from '@icon'
import { RichText, Link } from '@ui'
import { DownloadIcon } from '@chakra-ui/icons'

interface IPressDetailTemplate extends IGlobal {
  data: IPressReleaseCard
}

export const PressDetailTemplate = ({
  data,
  global: { navigation },
}: IPressDetailTemplate) => {
  const { asPath } = useRouter()
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { t } = useTranslation()

  const handleDownloadPdf = () => onToggle()

  return (
    <Layout title={data.title} navigation={navigation} path={asPath}>
      <Box
        as="section"
        mt={{ base: 'padding.primary2', lg: '77px' }}
        minHeight="50vh"
      >
        <Container>
          <Text color="red.primary" fontSize={{ base: '2xl', lg: '3xl' }}>
            {data.title}
          </Text>
          <Text
            mb="2.5"
            mt="2.5"
            color="grey.primary"
            fontSize="sm"
            fontWeight="light"
            as="h5"
          >
            {data.date}
          </Text>
        </Container>
        <style jsx global>{`
          .css-g82lcd span > p {
            margin-bottom: 16px;
            text-align: justify;
          }
          .css-g82lcd div > p {
            margin-bottom: 16px;
            text-align: justify;
          }
        `}</style>
        <Container
          maxW="container.secondary"
          mx="auto"
          mt={{ base: 'padding.primary', lg: 'padding.primary2' }}
          mb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
        >
          <Image src={data.bannerImage} alt={data.title} />

          {data.shortDescription && (
            <RichText
              fontWeight="bold"
              mt="10"
              mb="10"
              htmlContent={data.shortDescription}
            />
          )}
          {data.description && <RichText htmlContent={data.description} />}

          {data.pdfUrlStd !== '' && (
            <Button
              as={Link}
              href={data.pdfUrlStd}
              mr={4}
              mb={4}
              width={{ base: '100%', lg: '24%' }}
              height="50px"
              variant="secondary"
              mt="padding.primary"
              leftIcon={<Pdf />}
            >
              {t('common:downloadPr')}
            </Button>
          )}
          {data.pdfUrl !== '' && (
            <Button
              width={{ base: '100%', lg: '24%' }}
              height="50px"
              mt="padding.primary"
              onClick={handleDownloadPdf}
              variant="secondary"
              leftIcon={<DownloadIcon w={6} h={6} />}
              mr={4}
              mb={4}
            >
              {t('common:downloadHires')}
            </Button>
          )}
        </Container>
      </Box>
      <PressReleasePdfForm
        isOpen={isOpen}
        onClose={onClose}
        pdfUrl={data.pdfUrl}
      />
    </Layout>
  )
}
