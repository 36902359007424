import {
  Box,
  Container,
  Grid,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { HomeEventCard, MotionBox, MotionText } from "@ui";
import { observer } from 'mobx-react-lite'
import { fadeIn } from '@lib/motion'
import { useInViewMotion } from '@lib/hooks'
import { ITabData } from '@interfaces'
import { ContentNotFound } from '@common'
import { useRef, MutableRefObject } from 'react'
import ExhibitionAndEventSortButtonHome from '../ExhibitionAndEventSortButtonHome'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from "next/router";

interface IMuseumProgram {
  tabData: ITabData[]
}
const MuseumProgram = observer(({ tabData }: IMuseumProgram) => {
  const { controls, ref } = useInViewMotion(true, 0)
  const containerRef = useRef(null) as MutableRefObject<HTMLDivElement>
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <Box as="section" ref={containerRef}>
      <Container pt="6">
        <MotionText
          variants={fadeIn.up}
          fontSize={{ base: 'lg', lg: 'xl' }}
          color="red.primary2"
          fontWeight="200"
          fontStyle="normal"
          letterSpacing="1.2px"
          lineHeight="normal"
          as="h2"
        >
          {t('common:museumProgram.tab1')}
        </MotionText>

        <Tabs>
          <MotionBox
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={fadeIn.animationContainer}
          >
            <Grid
              gridAutoFlow="column"
              justifyContent="unset"
              overflowX="auto"
              background="white.primary"
              zIndex="1"
              transition="all 0.3s ease"
              py="4"
              sx={{
                '&::-webkit-scrollbar': {
                  height: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'white.primary3',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'red.primary',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: 'red.primary3',
                },
              }}
            >
              <ExhibitionAndEventSortButtonHome
                containerProps={{ mr: { base: 'auto', lg: '0px' } }}
              />
            </Grid>
            <TabPanels
              margin={{ base: 'auto', lg: '0px auto' }}
              id="MuseumProgram"
            >
              {tabData.map((tab) => {
                const isEnglish = router.locale === 'en';

                const tabContentToDisplay = [
                  ...(tab.tabContent ?? []),
                  {
                    id: 'atlikosk',
                    title: 'Atlı Köşk',
                    imgUrl: 'https://drupal.sakipsabancimuzesi.org/sites/default/files/2024-12/atli-kosk.jpg',
                    url: isEnglish ? '/page/atli-kosk-open' : '/sayfa/atli-kosk-acildi',
                  },
                ];

                return tabContentToDisplay.length < 1 ? (
                  <TabPanel key={tab.id}>
                    <ContentNotFound minHeight="50vh" />
                  </TabPanel>
                ) : (
                  <TabPanel
                    key={tab.id}
                    display="grid"
                    gridTemplateColumns={{
                      base: '1fr',
                      md: 'repeat(2, 1fr)',
                      lg: 'repeat(2, 1fr)',
                    }}
                    gridRowGap={{ base: '0', lg: 3 }}
                    gridColumnGap={{ base: 0, lg: 5 }}
                    pt={{ base: '0px', lg: '0px' }}
                    px={0}
                    justifyItems="center"
                  >
                    {tabContentToDisplay.map((card) => (
                      <Box key={card.id} width="full">
                        <MotionBox variants={fadeIn.left}>
                          <HomeEventCard {...card} />
                        </MotionBox>
                      </Box>
                    ))}
                  </TabPanel>
                );
              })}



            </TabPanels>
          </MotionBox>
        </Tabs>
      </Container>
    </Box>
  )
})

export default MuseumProgram
