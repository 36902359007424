import { createIcon } from '@chakra-ui/icons'

const S = createIcon({
  displayName: 'S',
  defaultProps: {
    width: 197,
    height: 183,
    color: 'yellow.primary',
  },
  viewBox: '0 0 197 183',
  path: (
    <path
      fill="currentColor"
      d="M196.839 10.25c-25-6.5-50.25-10.25-93.75-10.25C23.339 0 2.339 9 2.339 57.75c0 42.75 11.25 52.25 83.5 63.25 12.25 1.75 14.75 2.5 14.75 6 0 2.75-1.5 4-7 4-26 0-63.5-7.5-88.75-16.25L.09 170.5c23.25 8 64.25 12.5 95.75 12.5 79 0 96.75-12 96.75-56.75 0-36.5-7.75-43.75-74.75-55-23.25-3.75-24.75-4.5-24.75-7.5s2.5-3.75 15.5-3.75c22 0 52.25 4.5 76.75 11.25l11.5-61z"
    />
  ),
})

export default S
