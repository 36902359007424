import { FormHttpService } from '@httpServices'
import {
  IReservationForm,
  IMusicProgramForm,
  IWebForm,
  IWomensDayForm,
} from '@interfaces'

export const FormService = {
  post: async (postData: IWebForm) => FormHttpService.post(postData),
}

export const ReservationFormService = {
  post: async (postData: IReservationForm) => FormHttpService.post(postData),
}
export const MusicProgramFormService = {
  post: async (postData: IMusicProgramForm) => FormHttpService.post(postData),
}
export const WomensdayFormService = {
  post: async (postData: IWomensDayForm) => FormHttpService.post(postData),
}
