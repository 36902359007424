import { types } from 'mobx-state-tree'
import { IPublicationsSortTypes } from '@interfaces'

export const Publications = types
  .model({
    displaySortModal: types.boolean,
    sortResult: types.union(
      types.literal('all-publications'),
      types.literal('exhibition-catalogs'),
      types.literal('childrens-publications'),
      types.literal('scientific-publications')
    ),
  })
  .actions((self) => ({
    setSortResult(val: IPublicationsSortTypes) {
      self.sortResult = val
    },
    setDisplaySortModal(val: boolean) {
      self.displaySortModal = val
    },
    toggleDisplaySortModal() {
      self.displaySortModal = !self.displaySortModal
    },
  }))
