export const Tabs = {
  sizes: {
    md: {
      tab: {
        fontSize: 'sm',
        py: 0,
        px: 0,
        fontWeight: 'light',
        marginRight: 'padding.primary',
      },
    },
  },
}
