/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Container, Box, Flex, Text, Skeleton, Divider } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { SearchResultCard, Skeletons, CollectionPaginationText } from '@ui'
import { useStore } from '@store'
import { observer } from 'mobx-react-lite'
import { ISearchResultsScreen } from '@interfaces'
import { ContentNotFound, CollectionSearchPagination } from '@common'
import { useHandleSearchPagination } from '@lib/hooks'
interface ISearchResultsCollection {
  results: ISearchResultsScreen['pageData']['data']
  metaData: ISearchResultsScreen['metaData']
  isLoading: boolean
}

const SearchResultsCollection = observer(
  ({ metaData, results, isLoading }: ISearchResultsCollection) => {
    const { t } = useTranslation()
    const { search } = useStore()
    const { containerRef, handlePagination } = useHandleSearchPagination()

    if (isLoading) {
      return (
        <Box
          pt="padding.primary2"
          pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
        >
          <Container mb={{ base: '10', lg: '14' }}>
            <Box maxWidth="580px" mr="auto">
              <Skeleton height="40px" />
              <Skeleton height="20px" mt="4" />
            </Box>
          </Container>
          <Flex
            maxWidth="container.secondary"
            px={{ base: 'padding.primary', lg: '0' }}
            mx="auto"
            flexDirection="column"
          >
            <Skeletons.ArticleCard />
            <Skeletons.ArticleCard />
            <Skeletons.ArticleCard />
            <Skeletons.ArticleCard />
            <Skeletons.ArticleCard />
            <Skeletons.ArticleCard />
          </Flex>
        </Box>
      )
    }

    if (search?.searchTerm === '') {
      return null
    }

    if (!results || (typeof results === 'object' && results.length < 1)) {
      return <ContentNotFound />
    }

    return (
      <Box
        ref={containerRef}
        pt="padding.primary2"
        pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      >
        <Container mb={{ base: '10', lg: '14' }}>
          <Text color="red.primary" fontSize={{ base: '2xl', lg: '3xl' }}>
            {t(
              `search:searchResultFacets.${
                metaData?.searchFacet || search.searchFacet
              }`,
              {
                searchTerm: `‘${metaData?.searchTerm || search.searchTerm}’`,
              }
            )}
          </Text>
          <Text fontSize={{ base: 'md', lg: 'lg' }}>
            {t('common:resultFoundWithCount', {
              count: metaData.totalCount,
            })}
          </Text>
        </Container>
        <Flex
          maxWidth="container.secondary"
          px={{ base: 'padding.primary', lg: '0' }}
          mx="auto"
          flexDirection="column"
        >
          {results.map((col, i) => {
            return (
              <>
                <SearchResultCard key={i} {...col} />
                {i + 1 < results.length && (
                  <Divider
                    my="padding.primary"
                    borderBottomColor="white.primary3"
                  />
                )}
              </>
            )
          })}
        </Flex>
        <Flex flexDirection="column" mt={{ base: '10', lg: '16' }}>
          {!isLoading && metaData && (
            <>
              <CollectionSearchPagination
                currentPage={parseInt(metaData.page, 10)}
                totalPage={parseInt(metaData.totalPage, 10)}
                handlePagination={handlePagination}
              />
              {parseInt(metaData.totalPage, 10) > 1 && (
                <CollectionPaginationText
                  activeLengthOnPage={results?.length || 0}
                  totalLength={metaData.totalCount}
                />
              )}
            </>
          )}
        </Flex>
      </Box>
    )
  }
)

export default SearchResultsCollection
