import { Container, Flex } from '@chakra-ui/react'
import { ArtCollectionSearchResult, CollectionSearchSortButton } from '@common'
// import ART_SEARCH_RESULT_COLLECTION from '@lib/const/art-search-result-collection.const'
import { useStore } from '@store'
import { FilterButton } from '@ui'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import {
  ISelectionsFromCollectionCard,
  ICollectionSearchScreenMeta,
} from '@interfaces'

interface ICollectionSearchResultSection {
  results: ISelectionsFromCollectionCard[]
  metaData: ICollectionSearchScreenMeta
}

const CollectionSearchResultSection = observer(
  ({ results, metaData }: ICollectionSearchResultSection) => {
    const { t } = useTranslation()
    const {
      artSearch: { toggleDisplayFilterModal, searchTerm },
    } = useStore()

    return (
      <Container
        pt={{ base: 'padding.primary', lg: '40px' }}
        as="section"
        position="relative"
        zIndex={4}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          position="sticky"
          top="0"
          bgColor="white.primary"
          zIndex={1}
          gap={8}
        >
          <FilterButton onClick={toggleDisplayFilterModal} />
          <CollectionSearchSortButton />
        </Flex>
        <ArtCollectionSearchResult
          searchTearm={searchTerm}
          totalCountLabel={t('common:resultFoundWithCount', {
            count: metaData.totalCount,
          })}
          collections={results}
          metaData={metaData}
        />
      </Container>
    )
  }
)

export default CollectionSearchResultSection
