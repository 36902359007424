import { useState } from 'react'
import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import useTranslation from 'next-translate/useTranslation'
import { ExtendedNewsletterForm } from '@forms'
import { IFonts } from '@interfaces'

type NewsletterFormInputs = {
  email: string
}
interface INewsletterForm {
  buttonLabel: string
  activeFontFamily?: IFonts
}

const NewsletterForm = ({
  buttonLabel,
  activeFontFamily = 'body',
}: INewsletterForm) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const { isOpen, onClose, onToggle } = useDisclosure()

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('form:error.invalidEmail'))
      .required(t('form:error.requiredEmail')),
  })

  const { register, handleSubmit, errors, reset } =
    useForm<NewsletterFormInputs>({
      resolver: yupResolver(schema),
    })

  const onSubmit = async (values: NewsletterFormInputs, e) => {
    e.preventDefault()
    setEmail(values.email)
    onToggle()
    reset()
  }

  return (
    <Box
      as="form"
      position="relative"
      mt="padding.primary"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box maxW={{ base: '70%' }}>
        <FormControl isInvalid={!!errors?.email?.message}>
          <Input
            type="email"
            name="email"
            placeholder={t('form:field.email')}
            focusBorderColor="black.primary3"
            errorBorderColor="red.primary"
            _placeholder={{
              color: 'grey.primary2',
            }}
            fontFamily={activeFontFamily}
            ref={register}
          />
          <FormErrorMessage color="red.primary" fontFamily={activeFontFamily}>
            {errors?.email?.message}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Button
        mt="20px"
        position="relative"
        onClick={handleSubmit(onSubmit)}
        size="md"
        disabled={!!errors.email}
        fontFamily={activeFontFamily}
      >
        {buttonLabel}
      </Button>
      <ExtendedNewsletterForm
        isOpen={isOpen}
        onClose={onClose}
        email={email}
        newsLetterReset={reset}
        activeFontFamily={activeFontFamily}
      />
    </Box>
  )
}

export default NewsletterForm
