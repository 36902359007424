import { Box, BoxProps, Button } from '@chakra-ui/react'
import { Cross } from '@icon'
import useTranslation from 'next-translate/useTranslation'

interface IClearSelectionsButton {
  onClick: () => void
  containerProps?: BoxProps
  buttonLabelTx?: string
}

const ClearSelectionsButton = ({
  onClick,
  containerProps,
  buttonLabelTx = 'common:clearSelections',
}: IClearSelectionsButton) => {
  const { t } = useTranslation()

  return (
    <Box {...containerProps}>
      <Button
        display="flex"
        rightIcon={<Cross width="12px" height="12px" />}
        variant="unstyled"
        color="white.primary"
        fontWeight="medium"
        fontSize="sm"
        onClick={onClick}
      >
        {t(buttonLabelTx)}
      </Button>
    </Box>
  )
}

export default ClearSelectionsButton
