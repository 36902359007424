import drupalHttp from '../drupalHttpService'
import { SEARCH_RESULTS } from '@lib/const/api-endpoints.const'

/**
 * Search Http Service
 */
export const SearchHttpService = {
  /**
   * Get search results drupalHttp service
   */
  getSearchResults: async (
    locale: string,
    searchTerm: string,
    page: number,
    type: string
  ) => {
    const reqUrl = `${SEARCH_RESULTS[locale]}/${encodeURIComponent(
      searchTerm
    )}${type ? `/type/${type}` : ''}${(page && `?page=${page}`) || ''}`

    return await drupalHttp.get(reqUrl)
  },
}
