import { Text, useBreakpointValue } from '@chakra-ui/react'
import { TicketIcon } from '@icon'
import cn from 'classnames'
import * as S from './styles'
import { IFonts } from '@interfaces'

interface ITicket {
  label: string
  isSticky?: boolean
  onDrawerMenu?: boolean
  isActive: boolean
  activeFontFamily?: IFonts
}

const Ticket = ({
  label,
  isSticky = true,
  onDrawerMenu = false,
  isActive,
}: ITicket) => {
  const isDesktop = useBreakpointValue({ base: true, lg: true })

  return (
    <>
      {isDesktop ? (
        <S.Ticket
          href={'/bilet'}
          target={'_blank'}
          className={cn({
            'Ticket--active': isActive,
          })}
        >
          <TicketIcon
            className={cn('Ticket__Icon', {
              'Ticket__Icon--isSticky': isSticky,
              'Ticket__Icon--onDrawer': onDrawerMenu,
            })}
          />
          <Text
            as="span"
            color="white.primary"
            mr={2}
            fontWeight="bold"
            fontSize="15px"
            fontFamily="heading"
            ml={'0px'}
            className={cn('Ticket__Label', {
              'Ticket__Label--isSticky': isSticky,
              'Ticket__Label--onDrawer': onDrawerMenu,
            })}
          >
            {label}
          </Text>
        </S.Ticket>
      ) : null}
    </>
  )
}

export default Ticket
