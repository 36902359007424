import { useState, useEffect } from 'react'
import { Box, CircularProgress, Flex } from '@chakra-ui/react'
import { ArrowUpIcon } from '@chakra-ui/icons'

const BackToTop = () => {
  const [showScroll, setShowScroll] = useState(false)
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const checkScrollTop = () => {
      const winScroll = document.documentElement.scrollTop
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      const scrolled = (winScroll / height) * 100

      setScrollPercentage(Math.floor(scrolled))

      if (!showScroll && scrolled > 1) {
        setShowScroll(true)
      } else if (showScroll && scrolled <= 1) {
        setShowScroll(false)
      }
    }

    window.addEventListener('scroll', checkScrollTop)
    return () => window.removeEventListener('scroll', checkScrollTop)
  }, [showScroll])

  const scrollToTop = () => {
    setIsLoading(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  return (
    <Box position="relative">
      <Box
        display={showScroll ? 'flex' : 'none'}
        position="fixed"
        bottom="20px"
        right="20px"
        zIndex="1000"
        transition="visibility 0.3s, opacity 0.3s, transform 0.3s"
        _hover={{ transform: 'scale(1.05)' }}
      >
        <Flex align="center">
          {!isLoading && (
            <CircularProgress
              value={scrollPercentage}
              color="red.primary"
              size="40px"
              thickness="6px"
            />
          )}
          {!isLoading && (
            <ArrowUpIcon
              color="red.primary"
              borderRadius="50%"
              onClick={scrollToTop}
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default BackToTop
