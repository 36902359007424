import { Flex, Box, AspectRatio, Text, Image } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Link } from '@ui'
import { IWorkshopCard } from '@interfaces'

const WorkshopCard = ({
  title,
  shortDescription,
  imgUrl,
  url,
  colorSchema = {
    bgColor: 'yellow.primary2',
    titleColor: 'blue.primary4',
    buttonBgColor: 'blue.primary4',
    buttonTextColor: 'yellow.primary2',
    descriptionTextColor: 'black.primary',
  },
}: IWorkshopCard) => {
  const { t } = useTranslation()

  return (
    <Box
      maxWidth="315px"
      width="full"
      display="inline-block"
      pb="padding.primary"
    >
      <Box
        width="full"
        display="inline-flex"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Flex flexDirection="column" position="relative" width="full">
          <AspectRatio
            ratio={4 / 5}
            sx={{
              '&>img, &>video': {
                objectFit: 'contain',
              },
            }}
          >
            <Image
              src={imgUrl}
              fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
              alt={title}
            />
          </AspectRatio>

          <Box
            bgColor={colorSchema.bgColor}
            pt="5"
            pb="padding.primary2"
            position="relative"
          >
            <Box px="5">
              <Text
                color={colorSchema.titleColor}
                fontFamily="mikado"
                fontWeight="bold"
                fontSize="xxl"
              >
                {title}
              </Text>
              <Text
                color={colorSchema.descriptionTextColor}
                fontFamily="mikado"
                fontWeight="light"
                fontSize="sm"
              >
                {shortDescription}
              </Text>
            </Box>
            <Link
              href={url}
              height="60px"
              width="full"
              borderRadius="30px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgColor={colorSchema.buttonBgColor}
              color={colorSchema.buttonTextColor}
              fontFamily="mikado"
              fontWeight="bold"
              fontSize="xxl"
              position="absolute"
              bottom="-30px"
              _hover={{ transform: 'translateY(-5px)' }}
              transition="all 0.3s ease"
            >
              {t('common:detailedReviewButtonLabel')}
            </Link>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default WorkshopCard
