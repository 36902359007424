import {
  Box,
  Flex,
  Container,
  BoxProps,
  Text,
  Image,
  SimpleGrid,
  VStack,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Divider,
  AspectRatio,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Markup } from 'interweave'
import { RichText } from '@ui'
import { Minus, Plus } from '@icon'
import { ARTIST_DETAIL_IMAGE_ASPECT_RATIO } from '@lib/const'

interface IArtistDetail {
  fullName: string
  lifeRange: string
  biography: string
  imgUrl: string
  caption: string
  school: string
  nationality: string
  personType: 'Individual' | 'Artist' | 'Institution'
  reference: string
  additionalReadings: string
  containerProps?: BoxProps
}

const ArtistDetail = ({
  fullName,
  lifeRange,
  biography,
  imgUrl,
  caption,
  school,
  nationality,
  personType = 'Artist',
  reference,
  additionalReadings,
  containerProps,
}: IArtistDetail) => {
  const { t } = useTranslation()

  return (
    <Box as="section" {...containerProps}>
      <Container
        maxWidth="container.secondary"
        px={{ base: 'padding.primary', lg: '0' }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          pt="4"
          pb={{ base: 'padding.primary', lg: 'padding.primary2' }}
        >
          <Text
            as="h6"
            fontSize="sm"
            fontWeight="medium"
            color="red.primary"
            mb="5"
          >
            {t(`common:artistDetail.personType.${personType}`)}
          </Text>
          <Text
            as="h1"
            mb="padding.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
          >
            {fullName}
          </Text>
          {lifeRange && (
            <Flex mb={{ base: 'padding.primary', lg: 'padding.primary2' }}>
              <Text
                as="h5"
                fontSize="sm"
                fontWeight="light"
                color="grey.primary"
              >
                {lifeRange}&nbsp;
              </Text>
            </Flex>
          )}
          <AspectRatio
            ratio={ARTIST_DETAIL_IMAGE_ASPECT_RATIO}
            width="full"
            maxWidth="400px"
            mx="auto"
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
            sx={{
              '& img': {
                objectFit: 'contain',
              },
            }}
          >
            <Image
              src={imgUrl}
              fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
              alt={fullName}
            />
          </AspectRatio>
          {biography && (
            <Box
              as="p"
              sx={{
                mb: { base: 'padding.primary', lg: 'padding.primary2' },
                fontWeight: 'light',
                fontSize: 'sm',
              }}
            >
              <Markup content={biography} />
            </Box>
          )}
        </Flex>
      </Container>

      <Container>
        <Text color="red.primary" fontSize={{ base: '2xl', lg: '3xl' }}>
          {t('common:artistDetail.title')}
        </Text>
      </Container>

      <Container
        maxW="container.secondary"
        mx="auto"
        mt={{ base: 'padding.primary', lg: 'padding.primary2' }}
        mb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      >
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="padding.primary">
          {(caption || nationality) && (
            <VStack spacing="padding.primary" alignItems="flex-start">
              {caption && (
                <HStack>
                  <Text as="h6" fontWeight="medium">
                    {t('common:artistDetail.cultureAndGroup')}:{' '}
                  </Text>
                  <Text as="span" fontWeight="light">
                    {caption}
                  </Text>
                </HStack>
              )}
              {nationality && (
                <HStack>
                  <Text as="h6" fontWeight="medium">
                    {t('common:artistDetail.nationality')}:{' '}
                  </Text>
                  <Text as="span" fontWeight="light">
                    {nationality}
                  </Text>
                </HStack>
              )}
            </VStack>
          )}

          {(school || personType) && (
            <VStack spacing="padding.primary" alignItems="flex-start">
              {school && (
                <HStack>
                  <Text as="h6" fontWeight="medium">
                    {t('common:artistDetail.school')}:{' '}
                  </Text>
                  <Text as="span" fontWeight="light">
                    {school}
                  </Text>
                </HStack>
              )}
              {personType && (
                <HStack>
                  <Text as="h6" fontWeight="medium">
                    {t('common:artistDetail.personType.label')}:{' '}
                  </Text>
                  <Text as="span" fontWeight="light">
                    {t(`common:artistDetail.personType.${personType}`)}
                  </Text>
                </HStack>
              )}
            </VStack>
          )}
        </SimpleGrid>
        {(reference || additionalReadings) && (
          <>
            <Divider my="padding.primary" borderBottomColor="white.primary3" />
            <Accordion allowToggle defaultIndex={[0]}>
              {reference && (
                <AccordionItem mb={5}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <Text
                          flex="1"
                          textAlign="left"
                          as="span"
                          color="black.primary3"
                          fontSize="lg"
                        >
                          {t('common:artistDetail.references')}
                        </Text>
                        {isExpanded ? <Minus /> : <Plus />}
                      </AccordionButton>
                      <AccordionPanel
                        pb={0}
                        pt={5}
                        sx={{
                          '&>*+*': {
                            mt: 5,
                          },
                        }}
                      >
                        <RichText htmlContent={reference} />
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              )}

              {additionalReadings && (
                <AccordionItem mb={5}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton>
                        <Text
                          flex="1"
                          textAlign="left"
                          as="span"
                          color="black.primary3"
                          fontSize="lg"
                        >
                          {t('common:artistDetail.references')}
                        </Text>
                        {isExpanded ? <Minus /> : <Plus />}
                      </AccordionButton>
                      <AccordionPanel
                        pb={0}
                        pt={5}
                        sx={{
                          '&>*+*': {
                            mt: 5,
                          },
                        }}
                      >
                        <RichText htmlContent={additionalReadings} />
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              )}
            </Accordion>
          </>
        )}
      </Container>
    </Box>
  )
}

export default ArtistDetail
