import { createIcon } from '@chakra-ui/icons'

const Workshop = createIcon({
  displayName: 'Workshop',
  defaultProps: {
    width: '30px',
    height: '29px',
  },
  viewBox: '0 0 30 29',
  path: (
    <path
      d="M28.276 2.175h-13.05V.725a.725.725 0 00-1.45 0v1.45H.725a.725.725 0 000 1.45h27.551a.725.725 0 000-1.45zM26.826 4.35H2.175c-.4 0-.725.325-.725.726v15.95c0 .4.325.725.725.725h11.6v4.509l-5.25 1.312a.725.725 0 00.35 1.407l5.625-1.407 5.625 1.407a.722.722 0 00.879-.528.724.724 0 00-.528-.879l-5.25-1.312V21.75h11.6c.4 0 .725-.324.725-.725V5.076c0-.4-.325-.725-.725-.725zM26.1 20.302H2.9v-14.5h23.2v14.5z"
      fill="currentColor"
    />
  ),
})

export default Workshop
