import { SelectionsFromCollectionsHttpService } from '@httpServices'
import { v4 as uuidv4 } from 'uuid'
import { ISelectionsCollection } from '@interfaces'
import { createRouteUrlByKey } from '@utils'

export const SelectionsFromCollectionService = {
  /**
   * Get all selection from collections data for highlights works on home page
   */
  getSelectionsFromCollection: async (
    locale: string | 'en' | 'tr'
  ): Promise<ISelectionsCollection[]> => {
    const collection =
      await SelectionsFromCollectionsHttpService.getSelectionsFromCollection(
        locale
      )

    return collection.map((col) => ({
      id: col?.uuid[0]?.value || uuidv4(),
      imgUrl:
        (col?.field_object_image && col?.field_object_image[0]?.url) || '',
      title:
        (col?.field_object_title && col?.field_object_title[0]?.value) || '',
      name:
        (col?.field_object_collection_name &&
          col?.field_object_collection_name[0]?.value) ||
        '',
      nameUrl:
        (col?.field_object_collection_url &&
          createRouteUrlByKey(
            col?.field_object_collection_url[0]?.value,
            locale
          )) ||
        '',
      url:
        (col?.field_object_url &&
          createRouteUrlByKey(col?.field_object_url[0]?.value, locale)) ||
        '',
    }))
  },
}
