import drupalHttp from '../drupalHttpService'
import {
  TOUR_CATEGORY,
  TOUR_CATEGORY_DETAIL,
  TICKETS_OF_TOUR_CATEGORY,
  FAQ_OF_TOUR_CATEGORY,
  TABS_OF_TOUR_CATEGORY,
  VIDEOS_OF_TAB_CONTENT_OF_TOUR_CATEGORY,
} from '@lib/const/api-endpoints.const'

/**
 * Headline Http Service
 */
export const ToursHttpService = {
  /**
   * Get tour categories drupalHttp service
   */
  getTourCategories: async (locale: string) =>
    await drupalHttp.get(TOUR_CATEGORY[locale]),
  /**
   * Get tour category drupalHttp service
   */
  getTourCategory: async (locale: string, slug: string) =>
    await drupalHttp.get(`${TOUR_CATEGORY_DETAIL[locale]}/${slug}`),
  /**
   * Get tickets of tour category drupalHttp service
   */
  getTicketsOfTourCategory: async (locale: string, id: string) =>
    await drupalHttp.get(`${TICKETS_OF_TOUR_CATEGORY[locale]}/${id}`),
  /**
   * Get faqs of tour category drupalHttp service
   */
  getFaqOfTourCategory: async (locale: string, id: string) =>
    await drupalHttp.get(`${FAQ_OF_TOUR_CATEGORY[locale]}/${id}`),
  /**
   * Get tabs of tour category drupalHttp service
   */
  getTabsOfTourCategory: async (locale: string, id: string) =>
    await drupalHttp.get(`${TABS_OF_TOUR_CATEGORY[locale]}/${id}`),
  /**
   * Get tabs of tour category drupalHttp service
   */
  getVideoOfTabContentOfTourCategory: async (locale: string, id: string) =>
    await drupalHttp.get(
      `${VIDEOS_OF_TAB_CONTENT_OF_TOUR_CATEGORY[locale]}/${id}`
    ),
}
