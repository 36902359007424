import { createIcon } from '@chakra-ui/icons'

const OnlineExhibitionMap = createIcon({
  displayName: 'OnlineExhibitionMap',
  defaultProps: {
    width: 22,
    height: 22,
  },
  viewBox: '0 0 22 22',
  path: (
    <path
      fill="currentColor"
      d="M22 0H0v22h22V0zm-2.094 14.056l-8.9-4.238 3.565-7.723h5.334v11.96zM2.096 2.096h10.167L4.044 19.904H2.095V2.095zM6.35 19.904l3.777-8.185 9.778 4.656v3.529H6.35z"
    />
  ),
})

export default OnlineExhibitionMap
