import { Box, Container, Grid } from '@chakra-ui/react'
import {
  ExhibitionAndEventSortButton,
  ContentNotFound,
  CollectionSearchPagination,
} from '@common'
import { IEventCard, IExhibitionAndEventScreenMeta } from '@interfaces'
import { useStore } from '@store'
import {
  EventCard,
  EventInformationCard,
  Skeletons,
  CollectionPaginationText,
} from '@ui'
import { expandedExhibitionData, createNavLink } from '@utils'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState, useRef, MutableRefObject } from 'react'
import Router from 'next/router'
import { FadeInUpBox, IntersectionObserver, StaggerWrap } from '@motion'
import { useRouter } from 'next/router'

interface IExhibitionAndEventCollections {
  collectionData: IEventCard[]
  metaData: IExhibitionAndEventScreenMeta
  /**
   * If is true, added AudioGuide and OnlineExhibitionMap cards
   */
  isWithExpandedExhibitionData: boolean
}

const ExhibitionAndEventCollections = observer(
  ({
    collectionData,
    metaData,
    isWithExpandedExhibitionData,
  }: IExhibitionAndEventCollections) => {
    const router = useRouter()
    const containerRef = useRef(null) as MutableRefObject<HTMLDivElement>
    const { t } = useTranslation()
    const {
      exhibitionAndEvents: { setPage },
    } = useStore()

    const [isLoading, setLoading] = useState(false) //State for loading indicator
    const startLoading = () => setLoading(true)
    const stopLoading = () => {
      setTimeout(() => {
        setLoading(false)
      }, 750)
    }

    const [isPastEventPage, setIsPastEventPage] = useState(false)

    //Since requests happens after chaning routes url ?page={n} we need to bind loading events
    // on the router change event.
    useEffect(() => {
      //Setting router event handlers after component is located
      Router.events.on('routeChangeStart', startLoading)
      Router.events.on('routeChangeComplete', stopLoading)

      return () => {
        Router.events.off('routeChangeStart', startLoading)
        Router.events.off('routeChangeComplete', stopLoading)
      }
    }, [])

    useEffect(() => {
      if (metaData.activeCollection === 'events' && metaData.sort === 'past') {
        setIsPastEventPage(true)
      } else {
        setIsPastEventPage(false)
      }
    }, [router.query, metaData])

    // Triggers fetch for new page
    const handlePagination = (page: number) => {
      const query = router.query
      query.page = page.toString()

      const yCoordinate =
        containerRef?.current?.getBoundingClientRect().top + window.pageYOffset
      const yOffset = -40
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
      setPage(page.toString())

      router.push(
        {
          pathname: createNavLink('exhibitionAndEvent', router.locale),
          query: query,
        },
        undefined,
        { scroll: false }
      )
    }

    return (
      <Container
        as="section"
        position="relative"
        zIndex={5}
        pb={{ base: '10', lg: '24' }}
        ref={containerRef}
      >
        <Box
          maxW={{ base: 'full', lg: 'container.secondary' }}
          margin={{
            base: '20px auto auto',
            lg: '20px auto 0px',
          }}
          bgColor="white.primary"
        >
          <FadeInUpBox duration={1}>
            <ExhibitionAndEventSortButton
              containerProps={{
                placeSelf: {
                  base: 'flex-start',
                  lg: 'center',
                },
              }}
              containerRef={containerRef}
            />
          </FadeInUpBox>

          <Box minWidth={{ base: 'auto', lg: '180px' }} />
        </Box>
        {collectionData.length < 1 ? (
          <ContentNotFound minHeight="50vh" />
        ) : (
          <>
            <Box
              maxW={{ base: 'full', lg: 'container.secondary' }}
              margin={{
                base: '20px auto auto',
                lg: '20px auto 0px',
              }}
            >
              <StaggerWrap staggerChildren={1.6}>
                <Grid
                  gridTemplateColumns={{
                    base: '1fr',
                    md: 'repeat(2, 1fr)',
                    lg: 'repeat(3, 1fr)',
                  }}
                  gridRowGap={{ base: 'padding.primary', lg: 9 }}
                  gridColumnGap={{ base: 0, lg: 5 }}
                  justifyItems="center"
                >
                  {isLoading ? (
                    <>
                      <Skeletons.PublicationCard />
                      <Skeletons.PublicationCard />
                      <Skeletons.PublicationCard />
                      <Skeletons.PublicationCard />
                      <Skeletons.PublicationCard />
                      <Skeletons.PublicationCard />
                    </>
                  ) : collectionData.length > 7 &&
                    isWithExpandedExhibitionData ? (
                    expandedExhibitionData(collectionData).map((col, i) => {
                      if (i === 4) {
                        return (
                          <Box key={col.id} width="full" overflow="hidden">
                            <IntersectionObserver>
                              <FadeInUpBox duration={1}>
                                <EventInformationCard
                                  type="AudioGuide"
                                  title={t(
                                    'exhibition-and-event:home.voiceGuideCard.title'
                                  )}
                                  description={t(
                                    'exhibition-and-event:home.voiceGuideCard.description'
                                  )}
                                />
                              </FadeInUpBox>
                            </IntersectionObserver>
                          </Box>
                        )
                      }

                      if (i === 6) {
                        return (
                          <Box key={col.id} width="full" overflow="hidden">
                            <IntersectionObserver>
                              <FadeInUpBox duration={1}>
                                <EventInformationCard
                                  type="OnlineExhibitionMap"
                                  title={t(
                                    'exhibition-and-event:home.virtualExhibitionCard.title'
                                  )}
                                  description={t(
                                    'exhibition-and-event:home.virtualExhibitionCard.description'
                                  )}
                                />
                              </FadeInUpBox>
                            </IntersectionObserver>
                          </Box>
                        )
                      }

                      return (
                        <Box key={col.id} width="full" overflow="hidden">
                          <IntersectionObserver>
                            <FadeInUpBox duration={1}>
                              <EventCard
                                isGrayFilterImage={isPastEventPage}
                                {...col}
                              />
                            </FadeInUpBox>
                          </IntersectionObserver>
                        </Box>
                      )
                    })
                  ) : (
                    collectionData.map((col) => (
                      <Box key={col.id} width="full" overflow="hidden">
                        <IntersectionObserver>
                          <FadeInUpBox duration={1}>
                            <EventCard
                              isGrayFilterImage={isPastEventPage}
                              {...col}
                            />
                          </FadeInUpBox>
                        </IntersectionObserver>
                      </Box>
                    ))
                  )}
                </Grid>
              </StaggerWrap>
            </Box>
            <CollectionSearchPagination
              currentPage={parseInt(metaData.page, 10)}
              totalPage={parseInt(metaData.totalPage, 10)}
              handlePagination={handlePagination}
            />
            {parseInt(metaData.totalPage, 10) > 1 && (
              <CollectionPaginationText
                activeLengthOnPage={collectionData.length}
                totalLength={metaData.totalCount}
              />
            )}
          </>
        )}
      </Container>
    )
  }
)

export default ExhibitionAndEventCollections
