/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from '@store'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Button,
  chakra,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import { useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Cross } from '@icon'
import { createNavLink } from '@utils'
import { IFonts } from '@interfaces'

interface ISearchModal {
  activeFontFamily?: IFonts
}

const SearchModal = observer(({ activeFontFamily = 'body' }: ISearchModal) => {
  const inputEl = useRef<HTMLInputElement>(null)
  const {
    search: { displaySearchModal, closeSideMenu, searchTerm, setSearchTerm },
  } = useStore()
  const { t } = useTranslation()
  const router = useRouter()

  useEffect(() => {
    return () => {
      handleCloseSearchAction()
    }
  }, [])

  const handleSearchAction = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (searchTerm === '') {
      inputEl.current && inputEl.current.focus()
      return
    }
    closeSideMenu()

    const routerQuery = {
      searchTerm: '',
    }

    routerQuery.searchTerm = searchTerm
    router.push({
      pathname: createNavLink('search', router.locale),
      query: routerQuery,
    })
  }

  const handleCloseSearchAction = () => setSearchTerm('')

  return (
    <Modal isOpen={displaySearchModal} onClose={closeSideMenu} size="full">
      <ModalOverlay background="transparent" />
      <ModalContent
        mt="10"
        mb="0"
        minHeight="auto"
        borderRadius="none"
        background="red.primary"
        boxShadow="0px 16px 16px 16px rgba(0, 0, 0, 20%)"
      >
        <ModalBody p="padding.primary">
          <Text
            fontWeight="light"
            fontFamily={activeFontFamily}
            color="white.primary"
            mb="5"
          >
            {t('common:searchModal.description')}
          </Text>
          <chakra.form
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={{ base: 'padding.primary', lg: '5' }}
            onSubmit={handleSearchAction}
          >
            <InputGroup>
              <Input
                ref={inputEl}
                onChange={(e) => setSearchTerm(e.target.value)}
                px="5"
                type="text"
                fontFamily={activeFontFamily}
                placeholder={t('common:searchModal.searchPlaceholder')}
                variant="secondary"
                size="secondary"
                value={searchTerm}
              />
              <InputRightElement pr="5" width="5" bottom="0" height="full">
                <IconButton
                  onClick={handleCloseSearchAction}
                  variant="unstyled"
                  aria-label={t('common:searchModal.searchPlaceholder')}
                  icon={
                    <Cross width="12px" height="12px" color="grey.primary2" />
                  }
                />
              </InputRightElement>
            </InputGroup>
            <Button
              type="submit"
              fontSize="sm"
              fontWeight="medium"
              fontFamily={activeFontFamily}
              minW={{ base: '10', lg: '110px' }}
              height="50px"
              _hover={{
                bgColor: 'red.primary3',
              }}
            >
              {t('common:searchModal.searchLabel')}
            </Button>
          </chakra.form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})

export default SearchModal
