import { createIcon } from '@chakra-ui/icons'

const ArrowLeft = createIcon({
  displayName: 'ArrowLeft',
  viewBox: '0 0 21 29',
  defaultProps: {
    width: '21px',
    height: '29px',
    color: 'red.primary4',
    fill: 'none',
  },
  path: (
    <path
      d="M18 2.99902L6 14.499L18 25.999"
      stroke="currentColor"
      strokeWidth="8"
    />
  ),
})

export default ArrowLeft
