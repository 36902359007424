import { useEffect, useState } from 'react'
import Router from 'next/router'

export const useRouterLoading = (initialState = false) => {
  const [isLoading, setLoading] = useState(initialState) //State for loading indicator
  const startLoading = () => setLoading(true)
  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 750)
  }

  //Since requests happens after chaning routes url ?page={n} we need to bind loading events
  // on the router change event.
  useEffect(() => {
    //Setting router event handlers after component is located
    Router.events.on('routeChangeStart', startLoading)
    Router.events.on('routeChangeComplete', stopLoading)

    return () => {
      Router.events.off('routeChangeStart', startLoading)
      Router.events.off('routeChangeComplete', stopLoading)
    }
  }, [])

  return {
    isLoading,
  }
}
