import { createIcon } from '@chakra-ui/icons'

const Article = createIcon({
  displayName: 'Article',
  viewBox: '0 0 512 512',
  defaultProps: {
    width: 26,
    height: 26,
  },
  path: (
    <>
      <path
        d="m460.053 396.359c4.142 0 7.5-3.358 7.5-7.5v-315.698c0-4.142-3.358-7.5-7.5-7.5h-24.825v-25.836c0-4.142-3.358-7.5-7.5-7.5h-24.825v-24.825c0-4.142-3.358-7.5-7.5-7.5h-343.456c-4.142 0-7.5 3.358-7.5 7.5v431.339c0 4.142 3.358 7.5 7.5 7.5h24.825v24.825c0 4.142 3.358 7.5 7.5 7.5h24.825v25.836c0 4.142 3.358 7.5 7.5 7.5h343.455c4.142 0 7.5-3.358 7.5-7.5v-80.643c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v73.143h-328.454v-18.335h303.63c4.142 0 7.5-3.358 7.5-7.5v-127.653c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v120.152h-328.456v-17.325h303.63c4.142 0 7.5-3.358 7.5-7.5v-158.222c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v150.723h-328.455v-416.34h328.455v230.528c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-198.203h17.325v261.163c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-227.827h17.325v308.199c.001 4.141 3.359 7.499 7.501 7.499z"
        fill="currentColor"
      />
      <path
        d="m198.85 185.289v-139.403c0-4.142-3.358-7.5-7.5-7.5h-101.017c-4.142 0-7.5 3.358-7.5 7.5v139.402c0 4.142 3.358 7.5 7.5 7.5h101.017c4.142.001 7.5-3.357 7.5-7.499zm-15-7.5h-86.017v-124.403h86.016v124.403z"
        fill="currentColor"
      />
      <path
        d="m364.516 45.886c0-4.142-3.358-7.5-7.5-7.5h-129.301c-4.142 0-7.5 3.358-7.5 7.5v34.346c0 4.142 3.358 7.5 7.5 7.5h129.301c4.142 0 7.5-3.358 7.5-7.5zm-15 26.846h-114.301v-19.346h114.301z"
        fill="currentColor"
      />
      <path
        d="m357.016 109.098h-129.301c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h129.301c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
        fill="currentColor"
      />
      <path
        d="m357.016 141.423h-129.301c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h129.301c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
        fill="currentColor"
      />
      <path
        d="m357.016 177.789h-129.301c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h129.301c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
        fill="currentColor"
      />
      <path
        d="m357.016 214.154h-189.911c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h189.911c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
        fill="currentColor"
      />
      <path
        d="m130.74 214.154h-40.407c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h40.406c4.142 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.499-7.5z"
        fill="currentColor"
      />
      <path
        d="m357.016 250.521h-189.911c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h189.911c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
        fill="currentColor"
      />
      <path
        d="m130.74 250.521h-40.407c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h40.406c4.142 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.499-7.5z"
        fill="currentColor"
      />
      <path
        d="m357.016 286.886h-189.911c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h189.911c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
        fill="currentColor"
      />
      <path
        d="m130.74 286.886h-40.407c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h40.406c4.142 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.499-7.5z"
        fill="currentColor"
      />
      <path
        d="m357.016 323.252h-189.911c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h189.911c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
        fill="currentColor"
      />
      <path
        d="m130.74 323.252h-40.407c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h40.406c4.142 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.499-7.5z"
        fill="currentColor"
      />
      <path
        d="m357.016 359.618h-189.911c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h189.911c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"
        fill="currentColor"
      />
      <path
        d="m130.74 359.618h-40.407c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h40.406c4.142 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.499-7.5z"
        fill="currentColor"
      />
    </>
  ),
})

export default Article
