import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import theme from '../../../styles/theme'
import { mq } from '../../../lib/hooks'

const burgerHover = keyframes`
  0% {
    width: 100%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
`

export const HamburgerMenu__Lines = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: space-evenly;
  width: 14px;
  height: 18px;
  cursor: pointer;

  ${mq('lg')} {
    &.HamburgerMenu__Lines > .line {
      background: ${theme.colors.white.primary};
    }

    &.HamburgerMenu__Lines.HamburgerMenu__Lines--isSticky > .line {
      background: ${theme.colors.white.primary3};
    }
  }

  .line {
    align-self: flex-end;
    height: 2px;
    width: 100%;
    background: ${theme.colors.white.primary2};
    transition: all 0.3s ease;
  }

  .line--sec {
    width: 80%;
    transition: all 200ms ease-in-out;
  }

  .line--thr {
    width: 60%;
    transition: all 400ms ease-in-out;
  }
`

export const HamburgerMenu = styled.button`
  display: flex;
  align-items: center;
  padding: 0px;
  user-select: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    .line,
    .HamburgerMenu__Close {
      opacity: 0.75;
    }
  }

  & > span.HamburgerMenu__Label {
    color: ${theme.colors.white.primary3};

    &.HamburgerMenu__Label--onDrawer {
      color: ${theme.colors.white.primary};
    }

    ${mq('lg')} {
      color: ${theme.colors.white.primary};

      &.HamburgerMenu__Label--isSticky {
        color: ${theme.colors.white.primary3};
      }
    }
  }

  ${mq('lg')} {
    &:hover {
      .line {
        width: 100%;
        opacity: 0.75;
      }

      .line--first {
        animation: ${burgerHover} 1s infinite ease-in-out alternate;
      }

      .line--sec {
        animation: ${burgerHover} 1s infinite ease-in-out alternate forwards
          200ms;
      }

      .line--thr {
        animation: ${burgerHover} 1s infinite ease-in-out alternate forwards
          400ms;
      }

      .HamburgerMenu__Label {
        opacity: 0.75;
      }
    }
  }

  .HamburgerMenu__Close {
    width: 13px;
    height: 13px;
  }
`
