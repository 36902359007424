import { Layout, NewsAndBlogContent, ContentNotFound } from '@common'
import { ISSRPageData } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

export const CaseStudyDetailTemplate = ({
  pageData,
  global: { navigation },
}: ISSRPageData) => {
  const { t } = useTranslation()
  const { asPath } = useRouter()

  if (pageData.error || !pageData.data.post) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound />
      </Layout>
    )
  }

  return (
    <Layout
      title={pageData.data.pageTitle}
      navigation={navigation}
      path={asPath}
    >
      <NewsAndBlogContent
        bannerDisplay={pageData.data.post.type}
        type={pageData.data.post.type}
        title={pageData.data.post.title}
        author={pageData.data.post.author}
        date={pageData.data.post.date}
        description={pageData.data.post.description}
        imgUrl={pageData.data.post.imgUrl}
        containerProps={{
          py: 'padding.primary2',
        }}
      />
    </Layout>
  )
}
