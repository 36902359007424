import { Button, Flex } from '@chakra-ui/react'
import {
  DetailPageNavigationHeader,
  Layout,
  MoreNewsAndBlog,
  NewsAndBlogContent,
  //  TextToSpeech,
  SpectralFontFace,
} from '@common'
import { ChevronLeft, Share } from '@icon'
import { INewsAndBlogCard, IArticleTag, IGlobal } from '@interfaces'
import { Link } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { RWebShare } from 'react-web-share'
import { createNavLink } from '@utils'

interface IArticleTemplate extends IGlobal {
  post: INewsAndBlogCard
  morePosts: INewsAndBlogCard[]
  tags: IArticleTag[]
}

export const ArticleDetailTemplate = ({
  post,
  morePosts,
  tags,
  global: { navigation },
}: IArticleTemplate) => {
  const { t } = useTranslation()
  const { asPath, basePath, locale } = useRouter()

  return (
    <Layout
      activeFontFamily="spectral"
      title={post.title}
      navigation={navigation}
      path={asPath}
    >
      <SpectralFontFace />
      <DetailPageNavigationHeader
        variant="extended"
        leftChildren={
          <Flex>
            <Button
              fontFamily="spectral"
              as={Link}
              variant="unstyled"
              display="inline-flex"
              leftIcon={<ChevronLeft />}
              color={'black.primary3'}
              href={createNavLink('newsAndBlog', locale)}
              fontSize="sm"
              fontWeight="light"
            >
              {t('explore-art:newsAndBlog.pageTitle')}
            </Button>
          </Flex>
        }
        rightChildren={
          <Flex overflowX="auto" flexDirection="column" alignItems="flex-end">
            <RWebShare
              data={{
                text: post.title,
                url: basePath,
                title: `${post.title} | SSM`,
              }}
              closeText={t('common:closeLabel')}
            >
              <Button
                fontFamily="spectral"
                order={{ base: 2, lg: 1 }}
                variant="unstyled"
                display="inline-flex"
                rightIcon={<Share />}
                color={'black.primary3'}
                fontSize="sm"
                fontWeight="light"
              >
                {t('common:detailPageNavigation.share')}
              </Button>
            </RWebShare>
          </Flex>
        }
        containerProps={{
          mt: 'padding.primary',
        }}
      />
      <NewsAndBlogContent
        activeFontFamily="spectral"
        type={post.type}
        title={post.title}
        subtitle={post.subtitle}
        author={post.author}
        date={post.date}
        description={post.description}
        imgUrl={post.imgUrl}
        bannerDisplay={post.bannerDisplay}
      />
      <MoreNewsAndBlog
        activeFontFamily="spectral"
        title={t('common:more')}
        collection={morePosts}
        tags={tags}
      />
    </Layout>
  )
}
