import {
  Box,
  Container,
  Text,
  Grid,
  GridItem,
  Button,
  Image,
  Link
} from "@chakra-ui/react";
import { MotionText } from "@ui";
import { fadeIn } from "@lib/motion";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

const LearningCard = () => {
  const { locale } = useRouter()
  const { t } = useTranslation()

  // Manuel veri
  const cards = [
    {
      id: 1,
      image: "https://sakipsabancimuzesi.org/bilet/images/Ogrenme_bilet.jpg",
      title: {
        tr: "Öğrenme Programları",
        en: "Learning Programs"
      },
      description: {
        tr: "Sanatla kurduğunuz bağı güçlendirmek ve öğrenme deneyiminizi zenginleştirmek için tasarlanmış programlar.",
        en: "Programs designed to strengthen your connection to the arts and enrich your learning experience."
      },
      buttonLabel: {
        tr: "Keşfet",
        en: "Discover"
      },
      linkButton: {
        tr: 'https://www.sakipsabancimuzesi.org/bilet/learning-programs',
        en: 'https://www.sakipsabancimuzesi.org/bilet/learning-programs'
      },
    },
    {
      id: 2,
      image: "https://drupal.sakipsabancimuzesi.org/sites/default/files/2023-10/Artboard%205.jpg",
      title: {
        tr: "Etkinlikler",
        en: "Events"
      },
      description:{
        tr: "Sanatın ilham verici gücünü deneyimlemeniz için özenle hazırlanmış kültürel etkinlikler.",
        en: "Carefully curated cultural events for you to experience the inspiring power of art.",
      },
      buttonLabel: {
        tr: "Keşfet",
        en: "Discover"
      },
      linkButton: {
        tr: '/sergiler-ve-etkinlikler/etkinlik',
        en: '/en/exhibitions-and-events/event'
      },
    }
  ];

  return (
    <Box
      as="section"
      pt={{ base: "padding.primary1", lg: "4" }}
      pb={{ base: "0", lg: "4" }}
    >
      <Container>
        <Box
          bgColor={{ base: "white", lg: "white.primary2" }}
          px={{ base: "0", lg: "5" }}
          py="10">
          <MotionText
            variants={fadeIn.up}
            fontSize={{ base: "lg", lg: "3xl" }}
            color="red.primary"
            fontWeight="200"
            fontStyle="normal"
            lineHeight="normal"
            as="h2"
            mb={{ base: 1, lg: "6px" }}
          >
            {t('home:learningProgram.title')}
          </MotionText>
          <MotionText
            variants={fadeIn.up}
            fontSize={{ base: "sm", lg: "md" }}
            fontWeight="light"
            color="black.primary"
            my="2"
          >
            {t('home:learningProgram.description')}

          </MotionText>
          <Grid

            gridTemplateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={6}

          >
            {cards.map((card) => (
              <GridItem
                bgColor={{ base: "white.primary2", lg: "white" }}
                key={card.id}
                display="flex"
                flexDirection="row"
                alignItems="center"
                overflow="hidden"
                boxShadow="sm"
                p={{ base: "2", lg: "4" }}
              >
                <Box flex="1" minW={{ base: "106px", lg: "156px" }} maxW="200px" overflow="hidden">
                  <Image
                    width={{ base: "106px", lg: "156px" }}
                    src={card.image}
                    alt={card.title[locale]}
                  />
                </Box>

                <Box
                  flex="2"
                  pl={{ base: 3, lg: 0 }}
                  ml={{ base: 0, md: 4 }}
                  mt={{ base: 1, md: 0 }}
                  textAlign={{ base: "left", md: "left" }}
                >
                  <Text
                    fontSize={{ base: "md", lg: "2xl" }} mb="10px" color="black.primary"
                  >
                    {card.title[locale]}
                  </Text>
                  <Text minH="60px" fontSize={{ base: "xs", lg: "sm" }} color="black.primary" mb={{ base: "1", lg: "4" }}>
                    {card.description[locale]}
                  </Text>
                  <Button
                    as={Link}
                    href={card.linkButton[locale]}
                    color="black.primary"
                    colorScheme="whiteAlpha"
                    variant="white"
                    fontWeight="bold"
                    height={{ base: "30px", lg: "40px" }}
                    fontSize={{ base: "13px", lg: "16px" }}
                    fontFamily="heading"
                    rightIcon={<span>→</span>}
                  >
                    {card.buttonLabel[locale]}
                  </Button>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Box>


      </Container>
    </Box>
  );
};

export default LearningCard;
