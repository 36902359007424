import { BoxProps, Container, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import {
  parsePeopleDataForObjectDetail,
  createNavLink,
  // prepareCollectionSearchTerm,
} from '@utils'
import { Link } from '@ui'
import { useRouter } from 'next/router'

interface ICollectionWorkCategoryDetailSection {
  title: string
  subject: string
  format: string
  creator: string
  period: string
  geographicalLocation: string
  containerProps?: BoxProps
}

const CollectionWorkCategoryDetailSection = ({
  title,
  subject,
  format,
  creator,
  period,
  geographicalLocation,
  containerProps,
}: ICollectionWorkCategoryDetailSection) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <Container as="section" {...containerProps}>
      <Text
        as="h2"
        color="red.primary"
        fontSize={{ base: '2xl', lg: '3xl' }}
        mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
      >
        {title}
      </Text>
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        maxW="container.secondary"
        mx="auto"
      >
        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          spacingX="5"
          spacingY={{ base: 5, lg: '60px' }}
          width="full"
        >
          <Flex flexDirection="column">
            <Text fontSize="lg" mb={5}>
              {t('common:object.categories.subject')}
            </Text>
            {/* <Link
              href={`${createNavLink(
                'collectionSearch',
                locale
              )}?searchTerm=${prepareCollectionSearchTerm(subject)}`}
              variant="inlineLink"
              isExternal={true}
            > */}
            <Text fontSize="sm" fontWeight="light">
              {subject}
            </Text>
            {/*  </Link> */}
          </Flex>
          {format !== '' && (
            <Flex flexDirection="column">
              <Text fontSize="lg" mb={5}>
                {t('common:object.categories.format')}
              </Text>
              {/*  <Link
                href={`${createNavLink(
                  'collectionSearch',
                  locale
                )}?searchTerm=${prepareCollectionSearchTerm(format)}`}
                variant="inlineLink"
                isExternal={true}
              > */}
              <Text fontSize="sm" fontWeight="light">
                {format}
              </Text>
              {/*   </Link> */}
            </Flex>
          )}

          {creator !== '' && (
            <Flex flexDirection="column">
              <Text fontSize="lg" mb={5}>
                {t('common:object.categories.creator')}
              </Text>
              <Link
                href={`${createNavLink('artist', locale)}/${
                  parsePeopleDataForObjectDetail(creator)?.sourceID
                }`}
                variant="inlineLink"
                isExternal={true}
              >
                <Text fontSize="sm" fontWeight="light">
                  {parsePeopleDataForObjectDetail(creator)?.name}
                </Text>
              </Link>
            </Flex>
          )}

          {period !== '' && (
            <Flex flexDirection="column">
              <Text fontSize="lg" mb={5}>
                {t('common:object.categories.period')}
              </Text>
              {/*         <Link
                href={`${createNavLink(
                  'collectionSearch',
                  locale
                )}?searchTerm=${prepareCollectionSearchTerm(period)}`}
                variant="inlineLink"
                isExternal={true}
              > */}
              <Text fontSize="sm" fontWeight="light">
                {period}
              </Text>
              {/*    </Link> */}
            </Flex>
          )}
          <Flex d="none" flexDirection="column">
            <Text fontSize="lg" mb={5}>
              {t('common:object.categories.geographicalLocation')}
            </Text>
            {/*  <Link
              href={`${createNavLink(
                'collectionSearch',
                locale
              )}?searchTerm=${prepareCollectionSearchTerm(
                geographicalLocation
              )}`}
              variant="inlineLink"
              isExternal={true}
            > */}
            <Text fontSize="sm" fontWeight="light">
              {geographicalLocation}
            </Text>
            {/* </Link> */}
          </Flex>
        </SimpleGrid>
      </Flex>
    </Container>
  )
}

export default CollectionWorkCategoryDetailSection
