import { Text, AspectRatio, Image, Flex, Button } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { IPublicationCard } from '@interfaces'
import {
  PUBLICATION_IMAGE_ASPECT_RATIO_ON_MODAL,
  PUBLICATION_CHILD_IMAGE_ASPECT_RATIO_ON_MODAL,
} from '../../../lib/const'

const PublicationCard = ({
  title,
  imgUrl,
  listeningUrl,
  pdfLink,
  onClick,
  type = 'Sergi Katalogları',
  author,
  activeFontFamily = 'body',
}: IPublicationCard) => {
  const { t } = useTranslation()

  return (
    <Flex
      flexDirection="column"
      _hover={{ transform: 'translateY(-5px)' }}
      transition="all 0.3s ease"
      width="full"
      maxWidth="300px"
      justifyContent="space-between"
    >
      <Button
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        whiteSpace="normal"
        textAlign="left"
        variant="unstyled"
        height="auto"
        fontFamily={activeFontFamily}
        onClick={onClick}
      >
        <AspectRatio
          ratio={
            type === 'Çocuk Yayınları'
              ? PUBLICATION_CHILD_IMAGE_ASPECT_RATIO_ON_MODAL
              : PUBLICATION_IMAGE_ASPECT_RATIO_ON_MODAL
          }
          mb="6"
          width="full"
        >
          <Image
            src={imgUrl}
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
            alt={title}
          />
        </AspectRatio>
        <Text
          mb="2.5"
          as="h5"
          fontFamily={activeFontFamily}
          overflow="hidden"
          sx={{
            display: '-webkit-box',
            '-webkit-line-clamp': '3å',
            'line-clamp': '3',
            '-webkit-box-orient': 'vertical',
          }}
        >
          {title}
        </Text>
        {author && (
          <Text
            d="none"
            as="p"
            textStyle="richText"
            fontFamily={activeFontFamily}
          >
            {author}
          </Text>
        )}
      </Button>
      {(listeningUrl !== '' || pdfLink !== '') && (
        <Button
          variant="secondary"
          width="full"
          height="50px"
          onClick={onClick}
        >
          {t('common:detailedReviewButtonLabel')}
        </Button>
      )}
    </Flex>
  )
}

export default PublicationCard
