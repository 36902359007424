/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'

export const useFilterCollection = (
  safeCollectionData: any[],
  activeCollectionType
) => {
  const [filteredCollectionData, setFilteredCollectionData] =
    useState(safeCollectionData)

  useEffect(() => {
    const filterCollection = () => {
      if (activeCollectionType === 'all') {
        setFilteredCollectionData(safeCollectionData)
      } else {
        const filteredNewCollection = safeCollectionData.filter(
          (row) => row.type === activeCollectionType
        )
        setFilteredCollectionData(filteredNewCollection)
      }
    }

    filterCollection()
  }, [activeCollectionType, safeCollectionData])

  return filteredCollectionData
}
