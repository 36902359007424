import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { ISearchResultCard } from '@interfaces'
import { Link } from '@ui'
import useTranslation from 'next-translate/useTranslation'

const SearchResultCard = ({
  title,
  shortDescription,
  url,
  type,
  imgUrl,
  date,
  author,
  pageCount,
}: ISearchResultCard) => {
  const { t } = useTranslation()

  return (
    <Link
      role="group"
      href={url}
      target="_blank"
      display="flex"
      flexDirection="column"
    >
      <Grid
        gridTemplateColumns={{ base: '1fr', lg: '300px 1fr' }}
        gridColumnGap={{ base: '0px', lg: 5 }}
        gridRowGap={{ base: 5, lg: '0px' }}
      >
        <Box>
          <Image
            src={imgUrl}
            alt={title || ''}
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
          />
        </Box>

        <Flex flexDirection="column">
          <Text fontSize="xs" fontWeight="medium" color="red.primary" as="span">
            {t(`search:searchFacets.${type}`)}
          </Text>

          <Text
            fontSize="lg"
            textDecoration="underline"
            _groupHover={{
              color: 'red.primary',
            }}
            transition="color 0.3s ease"
            as="h5"
          >
            {title}
          </Text>

          {(shortDescription || shortDescription !== '') && (
            <Text fontSize="sm" fontWeight="light" mt="3">
              {shortDescription}
            </Text>
          )}
          {(author || author !== '') && (
            <Text fontSize="sm" fontWeight="light" mt="3">
              {author}
            </Text>
          )}
          {(date || date !== '') && (
            <Text fontSize="sm" fontWeight="light" mt="3">
              {date}
            </Text>
          )}
          {(pageCount || pageCount !== '') && (
            <Text fontSize="sm" fontWeight="light" mt="3">
              {`${pageCount} ${t('common:page')}`}
            </Text>
          )}
        </Flex>
      </Grid>
    </Link>
  )
}

export default SearchResultCard
