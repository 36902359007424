import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Grid,
  Box,
  GridItem,
  AspectRatio,
  Link,
  useDisclosure,
  useBreakpointValue
} from "@chakra-ui/react";
import { Cross, Share } from "@icon";
import { ISelectionsCollection, ISectionData } from "@interfaces";
import { useInViewMotion } from "@lib/hooks";
import { fadeIn } from "@lib/motion";
import { MotionBox, MotionText, SelectionsFromCollectionCard, RichText } from "@ui";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { RWebShare } from "react-web-share";

interface ISelectionsFromWork {
  variant?: "primary" | "withPopUp";
  isDarkSection?: boolean;
  sectionTitle: ISectionData["sectionTitle"];
  sectionSubTitle: string;
  sectionDescription?: string;
  data: ISelectionsCollection[];
  footerButtonLabel?: string;
  footerButtonOnPress?: () => void;
  stickyId?: ISectionData["stickyId"];
  isFooterButtonLoading?: boolean;
  isHomePage?: boolean;
}

const SelectionsFromWork = ({
                              variant = "primary",
                              data,
                              sectionTitle,
                              sectionSubTitle,
                              sectionDescription,
                              stickyId = "SelectionsFromWorks",
                              isDarkSection = true,
                              isHomePage,
                            }: ISelectionsFromWork) => {
  const { t } = useTranslation();
  const [activePopUpIndex, setActivePopUpIndex] = useState<string | null>(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { controls, ref } = useInViewMotion(true);
  const router = useRouter();
  const masonryColumnCount = useBreakpointValue({ base: 2, lg: 3 });

  const handleOnClickForPopUp = (activeIndex: string) => {
    setActivePopUpIndex(activeIndex);
    onToggle();
  };

  const getActiveCollection = () => data.find((collection) => collection.id === activePopUpIndex);


  const renderGrid = () => {
    // Veriyi bölme
    const firstGroup = data.slice(0, 4); // İlk 4 öğe
    const secondGroup = data.slice(4);   // Sonraki 3 öğe

    return (
      <>
        {/* Üstteki 4 öğe */}
        <Grid templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }} gap={4} w="full">
          {firstGroup.map((item) => (
            <GridItem
              key={item.id}
              colSpan={1} // Her öğe 1 sütun kaplıyor
              overflow="hidden"
              position="relative"
              transition="transform 0.3s, box-shadow 0.3s"
              boxShadow="base"
              _hover={{ transform: "scale(1.01)" }}
              role="group"
            >
              <AspectRatio ratio={2 / 3}>
                <Box
                  backgroundImage={`url(${item.imgUrl.replace("full", "thumbnail")})`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  position="relative"
                >
                  <Link
                    key={item.id}
                    href={item.url}
                    >
                    <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    top="0"
                    bgGradient="linear(to-t, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), transparent)"
                    zIndex="1"
                  />
                    <Box
                      position="absolute"
                      bottom="0"
                      left="0"
                      right="0"
                      p="4"
                      color="white"
                      textAlign="left"
                      zIndex="2"
                      transition="opacity 0.3s"
                      opacity="1"
                    >
                      <Text fontSize={{ base: "xs", md: "sm" }} fontWeight="bold">
                        {item.title}
                      </Text>
                    </Box>
                  </Link>
                </Box>
              </AspectRatio>
            </GridItem>
          ))}
        </Grid>

        {/* Alttaki 3 öğe */}
        <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} w="full" mt={4}>
          {secondGroup.map((item) => (
            <GridItem
              key={item.id}
              colSpan={1} // Her öğe 1 sütun kaplıyor
              overflow="hidden"
              position="relative"
              transition="transform 0.3s, box-shadow 0.3s"
              boxShadow="base"
              _hover={{ transform: "scale(1.01)" }}
              role="group"
            >
              <AspectRatio ratio={16 / 9}>
                <Box
                  backgroundImage={`url(${item.imgUrl.replace("full", "thumbnail")})`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  position="relative"
                  cursor="pointer"
                >
                  <Link
                    key={item.id}
                    href={item.url}
                  >

                    <Box
                      position="absolute"
                      bottom="0"
                      left="0"
                      right="0"
                      top="0"
                      bgGradient="linear(to-t, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), transparent)"
                      zIndex="1"
                    />
                    <Box
                      position="absolute"
                      bottom="0"
                      left="0"
                      right="0"
                      p="4"
                      color="white"
                      textAlign="left"
                      zIndex="2"
                      transition="opacity 0.3s"
                      opacity="1"
                    >
                      <Text fontSize={{ base: "xs", md: "sm" }} fontWeight="bold">
                        {item.title}
                      </Text>
                    </Box>
                  </Link>
                </Box>
              </AspectRatio>
            </GridItem>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <MotionBox id={stickyId} as="section">
      <Container>
        <MotionBox ref={ref} initial="hidden" animate={controls} variants={fadeIn.animationContainer}>
          {sectionSubTitle && (
            <MotionText variants={fadeIn.up} color="red.primary" fontSize={{ base: "lg", lg: "3xl" }} as="h3"
                        mb={{ base: 1, lg: "0" }} fontWeight="600">
              {sectionSubTitle}
            </MotionText>
          )}
          {sectionTitle && (
            <MotionText variants={fadeIn.up} color={isDarkSection ? "red.primary" : "red.primary"}
                        fontSize={{ base: "lg", lg: "3xl" }} fontWeight="200" as="h2" opacity="0.8"
                        mb={{ base: 1, lg: "6px" }}>
              {sectionTitle}
            </MotionText>
          )}
          {sectionDescription && (
            <MotionText variants={fadeIn.up} fontSize={{ base: "sm", lg: "md" }} fontWeight="light"
                        color="dark.primary">
              {sectionDescription}
            </MotionText>
          )}
        </MotionBox>
      </Container>

      <Container maxW="container.xl" mt="4">
        <ResponsiveMasonry columnsCountBreakPoints={{ 0: 2, 1170: 3 }}>
          {isHomePage ? renderGrid() : (
            <Masonry columnsCount={masonryColumnCount || 2} gutter="20px">
              {data.map((collection) => (
                <SelectionsFromCollectionCard
                  key={collection.id}
                  {...collection}
                  imgUrl={collection.imgUrl.replace("full", "thumbnail")}
                  onClick={() => variant === "withPopUp" && handleOnClickForPopUp(collection.id)}
                  isDarkSection={isDarkSection}
                  variant={variant}
                />
              ))}
            </Masonry>
          )}
        </ResponsiveMasonry>
      </Container>

      {activePopUpIndex && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay background="blackAlpha.80" sx={{ backdropFilter: "blur(8px)" }} />
          <ModalContent mt={{ base: "4", lg: "12" }} maxW={{ base: "calc(100vw - 60px)", lg: "620px" }}
                        borderRadius="none" bgColor="white">
            <ModalHeader
              minH={{ base: "271px", lg: "267px" }}
              bgImage={`url(${getActiveCollection()?.imgUrl || "/img/placeholders/no-image-available-icon.jpeg"})`}
              backgroundPosition="center"
              backgroundSize="cover"
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
            >
              <RWebShare
                data={{
                  text: getActiveCollection()?.title || "",
                  url: router.basePath,
                  title: `${getActiveCollection()?.title} | SSM`
                }}
              >
                <Button variant="unstyled" rightIcon={<Share />} color="white" fontSize="sm">
                  {t("visit:detailPage.share")}
                </Button>
              </RWebShare>
              <Button variant="unstyled" leftIcon={<Cross />} color="white" fontSize="sm" onClick={onClose} />
            </ModalHeader>
            <ModalBody>
              <Text fontSize="lg" color="red.primary" mb="4" as="h5">
                {getActiveCollection()?.title}
              </Text>
              <Text fontSize="sm" mb="6" fontWeight="medium">
                {getActiveCollection()?.subTitle}
              </Text>
              {getActiveCollection()?.description && <RichText htmlContent={getActiveCollection()?.description} />}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </MotionBox>
  );
};

export default SelectionsFromWork;
