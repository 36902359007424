import { Flex, Box, Text, Button, Image, useMediaQuery } from "@chakra-ui/react";
import { IEventCard } from "@interfaces";
import { Link } from "@ui";
import useTranslation from "next-translate/useTranslation";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const HomeEventCard = (props: IEventCard) => {
  const {
    title,
    date,
    imgUrl,
    url,
    footerButtonLabel,
    footerButtonHrefOnClick,
    isGrayFilterImage = false,
    activeFontFamily = "body"
  } = props;

  const thumbimgUrl = imgUrl.replace("full", "thumbnail");

  const { t } = useTranslation();
  const [isDesktop] = useMediaQuery("(min-width: 769px)");

  const CardContent = () => (
    <Box
      _hover={{
        "& .hover-text": {
          color: "white"
        },
        "& .hover-card": {
          background: "#222"
        },
        "& .hover-image": {
          transform: "scale(1.05)"
        }
      }}
      transition="all 0.3s ease"
      pb="5"
      display="inline-block"
      width="full"
      {...(isGrayFilterImage && {
        sx: {
          "&:hover img": {
            filter: "grayscale(0)"
          }
        }
      })}
    >
      <Link
        href={url}
        display="inline-flex"
        alignItems="flex-start"
        justifyContent="center"
        width="full"
      >
        <Flex
          direction="row"
          bg="white"
          boxShadow="xs"
          mt="1"
          width="100%"
        >
          {/* Sol Taraf - Görsel */}
          <Box width={isDesktop ? "50%" : "100%"} overflow="hidden">
            <Image
              className="hover-image"
              transition="transform 0.3s ease"
              src={thumbimgUrl}
              fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
              alt={title}
              objectFit="cover"
              height= {{ base: 'auto', md: '380px' }}
              width= {{ base: '100%', md: '100%' }}
              {...(isGrayFilterImage && {
                filter: "grayscale(1)",
                transition: "filter 0.3s ease"
              })}
            />
          </Box>

          {/* Sağ Taraf - Metinler */}
          <Flex
            transition="all 0.3s ease"
            className="hover-card"
            direction="column"
            p={({ base: "4", lg: "6" } as any)}
            justifyContent="space-around"
            width={isDesktop ? "50%" : "100%"}
            bg="#f0f0f5"
          >
            <Text fontSize={{base:'12px' , md:'sm'}}  color="gray.500" mb="2">
              {date ? date : t("nav:collectionExhibition")}
            </Text>
            <Text className="hover-text" fontSize={{base:'15px' , md:'30px'}} fontWeight="bold">
              {title}
            </Text>
            <Button
              colorScheme="red"
              color="red.primary"
              variant="link"
              alignSelf="flex-start"
              fontWeight="bold"
              size={({ base: "xs", lg: "md" } as any)}
            >
              {t("nav:more")} →
            </Button>
          </Flex>
        </Flex>
      </Link>
      <Box pl={"45px"} width="calc(100% - 25px)" ml="auto">
        {footerButtonLabel &&
          footerButtonLabel !== "" &&
          footerButtonHrefOnClick &&
          footerButtonHrefOnClick !== "" && (
            <Button
              as={Link}
              variant="secondary"
              size="secondary"
              href={footerButtonHrefOnClick}
              mt="22px"
              fontFamily={activeFontFamily}
            >
              {footerButtonLabel}
            </Button>
          )}
      </Box>
    </Box>
  );

  return (
    <CardContent />
  );
};
export default HomeEventCard;
