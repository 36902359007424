import { PublicationsHttpService } from '@httpServices'
import { IPublicationCard } from '@interfaces'

export const PublicationsService = {
  /**
   * Get all Publications
   */
  getAllPublications: async (
    locale: string,
    addUrlParams?: string
  ): Promise<IPublicationCard[]> => {
    const collection = await PublicationsHttpService.getAllPublications(
      locale,
      addUrlParams
    )

    return collection.map((d) => PublicationsService.preparePublication(d))
  },
  /**
   * Get publication of research area detail
   */
  getPublication: async (
    locale: string,
    publicationId: string
  ): Promise<IPublicationCard> => {
    const publication = await PublicationsHttpService.getPublication(
      locale,
      publicationId
    )

    if (publication && publication.length > 0) {
      return PublicationsService.preparePublication(publication[0])
    }

    return null
  },
  preparePublication: (initPublication) => {
    // check author
    let author = null
    if (
      initPublication.field_publication_author &&
      initPublication.field_publication_author.length > 0
    ) {
      author = initPublication.field_publication_author[0]?.value || ''
    }

    // check place
    let place = null
    if (
      initPublication.field_publication_place_of_publi &&
      initPublication.field_publication_place_of_publi.length > 0
    ) {
      place = initPublication.field_publication_place_of_publi[0]?.value || ''
    }

    // check isbn
    let isbn = null
    if (
      initPublication.field_publication_isbn &&
      initPublication.field_publication_isbn.length > 0
    ) {
      isbn = initPublication.field_publication_isbn[0]?.value || ''
    }

    // check page count
    let pageCount = null
    if (
      initPublication.field_publication_page_count &&
      initPublication.field_publication_page_count.length > 0
    ) {
      pageCount = initPublication.field_publication_page_count[0]?.value || ''
    }

    return {
      id: initPublication.uuid[0]?.value || '',
      title: initPublication.title[0]?.value || '',
      description:
        initPublication.field_publication_description[0]?.value || '',
      author: author,
      place: place,
      isbn: isbn,
      pageCount: pageCount,
      imgUrl: initPublication.field_publication_image[0]?.url || '',
      listeningUrl:
        (initPublication.field_publication_audio &&
          initPublication.field_publication_audio[0]?.url) ||
        '',
      videoUrl:
        (initPublication.field_publication_video &&
          initPublication.field_publication_video[0]?.value) ||
        '',
      pdfLink: initPublication.field_publication_pdf[0]?.url || '',
      epubLink: initPublication.field_publication_epub[0]?.value || '',
      type: initPublication.field_publication_type[0]?.value || '',
      slug: initPublication.field_path_alias[0]?.value || '',
    }
  },
}
