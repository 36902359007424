import drupalHttp from '../drupalHttpService'
import { FORM } from '@lib/const/api-endpoints.const'
import {
  IWebForm,
  IReservationForm,
  IMusicProgramForm,
  IWomensDayForm,
} from '@interfaces'

/**
 * Form for drupal Http Service
 */
export const FormHttpService = {
  post: async (
    postData: IWebForm | IReservationForm | IMusicProgramForm | IWomensDayForm
  ) => await drupalHttp.post(FORM, postData),
}
