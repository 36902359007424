import { Flex, Text, AspectRatio, Image } from '@chakra-ui/react'
import { TWO_COL_SECTION_IMAGE_ASPECT_RATIO } from '@lib/const'
import { RichText } from '@ui'
interface IFullWidthTwoColSection {
  htmlContent: string
  id: string
  imgUrl: string
  isReverse: boolean
  title: string
}

const FullWidthTwoColSection = ({
  htmlContent,
  id = 'FullWidthTwoColSection',
  imgUrl,
  isReverse,
  title,
}: IFullWidthTwoColSection) => (
  <Flex
    as="section"
    id={id}
    flexDirection={{ base: 'column', lg: isReverse ? 'row-reverse' : 'row' }}
    alignItems="center"
    sx={{
      rowGap: { base: '30px', lg: '0' },
    }}
    my={{ base: 'padding.primary', lg: '0' }}
  >
    <Flex flex="0 0 50%" flexDirection="column" px="padding.primary">
      <Text
        color="red.primary"
        fontSize={{ base: '2xl', lg: '3xl' }}
        mb={{ base: '4', lg: '5' }}
      >
        {title}
      </Text>
      {htmlContent && <RichText htmlContent={htmlContent} />}
    </Flex>
    <Flex flex="0 0 50%" width="full">
      <AspectRatio width="100%" ratio={TWO_COL_SECTION_IMAGE_ASPECT_RATIO}>
        <Image
          src={imgUrl}
          fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
        />
      </AspectRatio>
    </Flex>
  </Flex>
)

export default FullWidthTwoColSection
