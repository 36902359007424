import { createIcon } from '@chakra-ui/icons'

const SSMLogo = createIcon({
  displayName: 'SSMLogo',
  defaultProps: {
    width: '68px',
    height: '40px',
  },
  viewBox: '0 0 68 40',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.177 22.393v17.413h-1.599V22.394h1.599zM18.578 0h1.599v17.413h-1.599V0zM44.46 22.393v17.413H42.86V22.394h1.599zM42.86 0h1.599v17.413H42.86V0z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.36 14.07c-1.185-2.898-3.843-4.492-7.05-4.492-3.456 0-6.444 2.016-6.444 5.572 0 6.12 10.283 5.344 10.283 9.259 0 1.928-1.463 2.645-3.373 2.645-2.24 0-3.43-1.24-4.344-3.115l-3.154 1.38c1.137 2.893 4.203 4.71 7.469 4.71 4.422 0 6.998-2.04 6.998-6.12 0-6.453-10.288-5.736-10.288-9.156 0-1.546 1.19-2.205 2.843-2.205 1.798 0 2.984 1.047 3.567 2.592l3.494-1.07zM52.765 29.854V14.195h.059l4.422 15.66h2.906l4.48-15.66h.054v15.66H68V9.95h-5.418l-3.869 13.896h-.053L54.811 9.95h-5.365v19.903h3.32zM14.073 14.07c-1.19-2.898-3.844-4.492-7.05-4.492-3.46 0-6.445 2.016-6.445 5.572 0 6.12 10.288 5.344 10.288 9.259 0 1.928-1.472 2.645-3.377 2.645-2.24 0-3.431-1.24-4.345-3.115L0 25.319c1.137 2.893 4.204 4.71 7.47 4.71 4.421 0 6.992-2.04 6.992-6.12 0-6.453-10.283-5.736-10.283-9.156 0-1.546 1.186-2.205 2.848-2.205 1.798 0 2.984 1.047 3.567 2.592l3.48-1.07z"
        fill="#fff"
      />
    </>
  ),
})

export default SSMLogo
