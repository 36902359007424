import { Image } from '@icon'
import { IconButton, IconButtonProps } from '@chakra-ui/react'

const ImageButton = ({ ...rest }: IconButtonProps) => (
  <IconButton
    icon={<Image />}
    position="absolute"
    bottom="0"
    left="0"
    borderRadius="0"
    zIndex="3"
    pointerEvents="auto"
    {...rest}
  />
)

export default ImageButton
