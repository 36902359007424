import { Box } from '@chakra-ui/react'
import { ContentNotFound, Hero, Layout, Sections, StickyHeader } from '@common'
import { ISSRPageData } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

export const GardenTemplate = ({
  pageData,
  global: { navigation },
}: ISSRPageData) => {
  const { t } = useTranslation()
  const { asPath } = useRouter()

  if (pageData.error) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound infoType="serverSideError" />
      </Layout>
    )
  }

  return (
    <Layout
      title={t('visit:garden.title')}
      navigation={navigation}
      path={asPath}
    >
      <Hero
        subTitle={pageData.data.hero.subTitle}
        title={pageData.data.hero.title}
        description={pageData.data.hero.description}
        bgImageUrl={pageData.data.hero.bgImageUrl}
      />
      {pageData.data.stickyNavItems &&
        pageData.data.stickyNavItems.length > 0 && (
          <Box as="main" pt={4}>
            <StickyHeader navs={pageData.data.stickyNavItems} />
            <Sections sections={pageData.data.sections} />
          </Box>
        )}
    </Layout>
  )
}
