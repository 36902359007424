import { Box, Flex } from '@chakra-ui/react'
import { ChildAtTheMuseumCard } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// import CHILD_AT_THE_MUSEUM_SLIDER_CONTENT from './ChildAtTheMuseumSlider.const'
import { S, M, Play, Learn, Discover } from '@icon'
import KIDS_STICKY_NAV from '@lib/const/kids-sticky-nav.const'
import { createNavLink } from '@utils'
import { useRouter } from 'next/router'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

const ChildAtTheMuseumSlider = () => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <Box
      position="relative"
      className="ChildAtTheMuseumSwiper"
      mt="padding.primary"
      zIndex="2"
      sx={{
        '&.ChildAtTheMuseumSwiper .swiper-container': {
          pl: { base: 'padding.primary', lg: '0' },
          pb: { base: 16, lg: '0' },
        },
        '&.ChildAtTheMuseumSwiper .swiper-slide': {
          width: 'auto',
        },
        '&.ChildAtTheMuseumSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active':
          {
            backgroundColor: 'red.primary',
            transition: 'all 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
          },
        '&.ChildAtTheMuseumSwiper .swiper-pagination-bullet': {
          opacity: 1,
          width: '102px',
          height: '2px',
          borderRadius: 'none',
          backgroundColor: 'blackAlpha.primary10',
          transition: 'all 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
        },
        '&.ChildAtTheMuseumSwiper .swiper-pagination': {
          display: { base: 'block', lg: 'none' },
          bottom: 'padding.primary',
        },
        '@media screen and (max-width: 320px)': {
          '&.ChildAtTheMuseumSwiper .swiper-pagination-bullet': {
            width: '50px',
          },
        },
      }}
    >
      <Swiper
        slidesPerView="auto"
        breakpoints={{
          0: {
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
          },
          1170: {
            centeredSlides: true,
            initialSlide: 1,
            noSwiping: true,
            noSwipingClass: 'swiper-container',
          },
        }}
      >
        <SwiperSlide>
          <ChildAtTheMuseumCard
            footerLabel={t(KIDS_STICKY_NAV[0].navTitleTx)}
            footerLink={`${createNavLink('kid', locale)}#${
              KIDS_STICKY_NAV[0].href
            }`}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flex="1"
              bgColor="blue.primary"
              width="full"
              position="relative"
            >
              <S position="absolute" />
              <Play position="absolute" left={7} bottom={9} />
            </Flex>
          </ChildAtTheMuseumCard>
        </SwiperSlide>
        <SwiperSlide>
          <ChildAtTheMuseumCard
            footerLabel={t(KIDS_STICKY_NAV[1].navTitleTx)}
            footerLink={`${createNavLink('kid', locale)}#${
              KIDS_STICKY_NAV[1].href
            }`}
            footerLabelStyle={{ color: 'red.primary' }}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flex="1"
              bgColor="yellow.primary"
              width="full"
              position="relative"
            >
              <S position="absolute" color="red.primary" />
              <Learn position="absolute" right={24} bottom={9} />
            </Flex>
          </ChildAtTheMuseumCard>
        </SwiperSlide>
        <SwiperSlide>
          <ChildAtTheMuseumCard
            footerLabel={t(KIDS_STICKY_NAV[2].navTitleTx)}
            footerLink={`${createNavLink('kid', locale)}#${
              KIDS_STICKY_NAV[2].href
            }`}
            footerLabelStyle={{ color: 'blue.primary' }}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flex="1"
              bgColor="red.primary"
              width="full"
              position="relative"
            >
              <M position="absolute" />
              <Discover position="absolute" right={4} bottom={9} />
            </Flex>
          </ChildAtTheMuseumCard>
        </SwiperSlide>
        <Box className="swiper-pagination" />
      </Swiper>
    </Box>
  )
}

export default ChildAtTheMuseumSlider
