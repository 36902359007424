import { Container, ContainerProps, Flex, Text, Img } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
interface IContentNotFound extends ContainerProps {
  infoType?: 'notFound' | 'serverSideError'
}

const ContentNotFound = ({
  infoType = 'notFound',
  ...rest
}: IContentNotFound) => {
  const { t } = useTranslation()
  return (
    <Container
      as="section"
      minHeight="75vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <Img
        maxWidth={{ base: '300px', lg: '35vw' }}
        src={
          infoType === 'serverSideError'
            ? '/img/not-found/server-down.png'
            : '/img/not-found/art-collection-not-found.png'
        }
      />
      <Text
        color="black.primary4"
        fontWeight="light"
        fontSize="2xl"
        textAlign="center"
      >
        {t(
          infoType === 'serverSideError'
            ? 'common:swDownTitle'
            : 'common:contentNotFound'
        )}
      </Text>
      {infoType === 'serverSideError' && (
        <Flex flexDirection="column" alignItems="center">
          <Text textAlign="center">{t('common:swDownDescription1')}</Text>
          <Text textAlign="center">{t('common:swDownDescription2')}</Text>
        </Flex>
      )}
    </Container>
  )
}

export default ContentNotFound
