import { v4 as uuidv4 } from 'uuid'
import { IStickyNav, ISSRPage } from '@interfaces'

type generateStickyNavItemKey =
  | 'aboutTheProgram'
  | 'aboutTheArchive'
  | 'aboutTheCollection'
  | 'aboutTheExhibition'
  | 'aboutTheEvent'
  | 'aboutTheArtist'
  | 'daysAndTickets'
  | 'detailsDaysAndTickets'
  | 'photosAndVideos'
  | 'selectionsFromWorks'
  | 'relatedEvents'
  | 'otherEvents'
  | 'otherExhibitions'
  | 'otherOnlineExhibitions'
  | 'otherLearningPrograms'
  | 'pressRelease'
  | 'threeDTour'
  | 'giftShop'
  | 'learnWitSSM'
  | 'stayUpdated'
  | 'getInspired'
  | 'sections.block'
  | 'sections.accordion'
  | 'sections.gallery'
  | 'projectDescription'
  | 'rdEvents'
  | 'rdPublications'
  | 'rdCSs'
  | 'rdProjectTeam'
  | 'rdProjectPartners'
  | 'storyOfTheExhibition'
  | 'aboutTheArtist'
  | 'aboutTheExhibition'
  | 'periodsArtifacts'
  | 'catalog'
  | 'conferences'
  | 'aboutTheWorkshop'
  | 'otherWorkshops'
  | 'forKids'

export const generateStickyNavItem = (key: generateStickyNavItemKey) => {
  switch (key) {
    case 'aboutTheProgram':
      return {
        id: uuidv4(),
        key: 'aboutTheProgram',
        navTitleTx: 'nav:stickyItems.aboutTheProgram',
        href: 'aboutTheProgram',
      }

    case 'aboutTheCollection':
      return {
        id: uuidv4(),
        key: 'aboutTheCollection',
        navTitleTx: 'nav:stickyItems.aboutTheCollection',
        href: 'aboutTheCollection',
      }

    case 'aboutTheArchive':
      return {
        id: uuidv4(),
        key: 'aboutTheArchive',
        navTitleTx: 'nav:stickyItems.aboutTheArchive',
        href: 'aboutTheArchive',
      }

    case 'aboutTheExhibition':
      return {
        id: uuidv4(),
        key: 'aboutTheExhibition',
        navTitleTx: 'nav:stickyItems.aboutTheExhibition',
        href: 'aboutTheExhibition',
      }

    case 'aboutTheEvent':
      return {
        id: uuidv4(),
        key: 'aboutTheEvent',
        navTitleTx: 'nav:stickyItems.aboutTheEvent',
        href: 'aboutTheEvent',
      }

    case 'aboutTheArtist':
      return {
        id: uuidv4(),
        key: 'aboutTheArtist',
        navTitleTx: 'nav:stickyItems.aboutTheArtist',
        href: 'aboutTheArtist',
      }

    case 'aboutTheWorkshop':
      return {
        id: uuidv4(),
        key: 'aboutTheWorkshop',
        navTitleTx: 'nav:stickyItems.aboutTheWorkshop',
        href: 'aboutTheWorkshop',
      }

    case 'daysAndTickets':
      return {
        id: uuidv4(),
        key: 'daysAndTickets',
        navTitleTx: 'nav:stickyItems.daysAndTickets',
        href: 'daysAndTickets',
      }
    case 'detailsDaysAndTickets':
      return {
        id: uuidv4(),
        key: 'detailsDaysAndTickets',
        navTitleTx: 'nav:stickyItems.detailsDaysAndTickets',
        href: 'detailsDaysAndTickets',
      }

    case 'photosAndVideos':
      return {
        id: uuidv4(),
        key: 'photosAndVideos',
        navTitleTx: 'nav:stickyItems.photosAndVideos',
        href: 'photosAndVideos',
      }

    case 'selectionsFromWorks':
      return {
        id: uuidv4(),
        key: 'selectionsFromWorks',
        navTitleTx: 'nav:stickyItems.selectionsFromWorks',
        href: 'selectionsFromWorks',
      }

    case 'relatedEvents':
      return {
        id: uuidv4(),
        key: 'relatedEvents',
        navTitleTx: 'nav:stickyItems.relatedEvents',
        href: 'relatedEvents',
      }

    case 'otherEvents':
      return {
        id: uuidv4(),
        key: 'otherEvents',
        navTitleTx: 'nav:stickyItems.otherEvents',
        href: 'otherEvents',
      }

    case 'otherExhibitions':
      return {
        id: uuidv4(),
        key: 'otherExhibitions',
        navTitleTx: 'nav:stickyItems.otherExhibitions',
        href: 'otherExhibitions',
      }

    case 'otherLearningPrograms':
      return {
        id: uuidv4(),
        key: 'otherLearningPrograms',
        navTitleTx: 'nav:stickyItems.otherLearningPrograms',
        href: 'otherLearningPrograms',
      }

    case 'otherWorkshops':
      return {
        id: uuidv4(),
        key: 'otherWorkshops',
        navTitleTx: 'nav:stickyItems.otherWorkshops',
        href: 'otherWorkshops',
      }

    case 'pressRelease':
      return {
        id: uuidv4(),
        key: 'pressRelease',
        navTitleTx: 'nav:stickyItems.pressRelease',
        href: 'https://www.sakipsabancimuzesi.org/sites/default/files/exhibitions/pdf/seyh-hamdullah-basin-bulteni.pdf',
      }

    case 'threeDTour':
      return {
        id: uuidv4(),
        key: 'threeDTour',
        navTitleTx: 'nav:stickyItems.threeDTour',
        href: 'threeDTour',
      }

    case 'giftShop':
      return {
        id: uuidv4(),
        key: 'giftShop',
        navTitleTx: 'nav:stickyItems.giftShop',
        href: 'giftShop',
      }

    case 'learnWitSSM':
      return {
        id: uuidv4(),
        key: 'learnWitSSM',
        navTitleTx: 'nav:stickyItems.learnWithSSM',
        href: 'learnWitSSM',
      }

    case 'stayUpdated':
      return {
        id: uuidv4(),
        key: 'stayUpdated',
        navTitleTx: 'nav:stickyItems.stayUpdated',
        href: 'stayUpdated',
      }

    case 'getInspired':
      return {
        id: uuidv4(),
        key: 'getInspired',
        navTitleTx: 'nav:stickyItems.getInspired',
        href: 'getInspired',
      }

    case 'projectDescription':
      return {
        id: uuidv4(),
        key: 'projectDescription',
        navTitleTx: 'nav:stickyItems.projectDescription',
        href: 'projectDescription',
      }

    case 'rdEvents':
      return {
        id: uuidv4(),
        key: 'rdEvents',
        navTitleTx: 'nav:stickyItems.rdEvents',
        href: 'rdEvents',
      }

    case 'rdPublications':
      return {
        id: uuidv4(),
        key: 'rdPublications',
        navTitleTx: 'nav:stickyItems.rdPublications',
        href: 'rdPublications',
      }

    case 'rdCSs':
      return {
        id: uuidv4(),
        key: 'rdCSs',
        navTitleTx: 'nav:stickyItems.rdCSs',
        href: 'rdCSs',
      }

    case 'rdProjectTeam':
      return {
        id: uuidv4(),
        key: 'rdProjectTeam',
        navTitleTx: 'nav:stickyItems.rdProjectTeam',
        href: 'rdProjectTeam',
      }

    case 'rdProjectPartners':
      return {
        id: uuidv4(),
        key: 'rdProjectPartners',
        navTitleTx: 'nav:stickyItems.rdProjectPartners',
        href: 'rdProjectPartners',
      }

    default:
      return {
        id: uuidv4(),
        key: 'aboutTheProgram',
        navTitleTx: 'nav:stickyItems.aboutTheProgram',
        href: 'aboutTheProgram',
      }
  }
}

export const checkStickyNavItem = (
  navItems: IStickyNav[],
  key: generateStickyNavItemKey
) => {
  return navItems.some((item) => item.href === key)
}

export const getStickyId = (
  navItems: IStickyNav[],
  key: generateStickyNavItemKey
) => {
  return navItems.find((n) => n.href === key)?.href || '#'
}

export const getSectionTitle = (
  navItems: IStickyNav[],
  key: generateStickyNavItemKey
) => {
  return navItems.find((n) => n.href === key)?.navTitleTx || ''
}

export const getSectionData = (
  pageData: ISSRPage,
  key: generateStickyNavItemKey
) => {
  if (!pageData || !pageData?.data || !pageData?.data[key]) {
    return null
  }

  return pageData?.data[key]
}
