import { Box, Image } from '@chakra-ui/react'
import { IInstagramMedia } from '@interfaces'
import { SsmInstagramCard } from '@ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import LightGallery from 'lightgallery/react'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import React, { useState } from 'react'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'

interface ISsmInstagramSlider {
  media: IInstagramMedia[]
}

const SsmInstagramSlider = ({ media }: ISsmInstagramSlider) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(null)

  const handleImageClick = (index) => {
    setCurrentImageIndex((prevIndex) => (prevIndex === index ? null : index))
  }
  return (
    <>
      <Box
        marginLeft={{
          base: '0px',
          md: '2.1',
          sm: '3%',
          lg: '10%',
          '2xl': '18.1%',
        }}
        position="relative"
        className="SsmInstagramSwiper"
        mt="46px"
        zIndex="2"
        sx={{
          '&.SsmInstagramSwiper .swiper-container': {
            pl: { base: 'padding.primary', lg: '0' },
          },
          '&.SsmInstagramSwiper .swiper-slide': {
            width: 'auto',
          },
        }}
      >
        <Swiper
          slidesPerView="auto"
          spaceBetween={20}
          centeredSlides={false}
          initialSlide={0}
          breakpoints={{
            1170: {
              noSwiping: true,
            },
          }}
        >
          {media.map((content, index) => (
            <SwiperSlide key={content.id}>
              <SsmInstagramCard {...content}>
                <Image
                  onClick={() => handleImageClick(index)}
                  src={
                    content.imgUrl.replace(
                      'files',
                      'files/styles/webp/public'
                    ) + '.webp'
                  }
                  fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
                  alt={content.imgAlt || 'SSM Instagram post'}
                />
              </SsmInstagramCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {currentImageIndex !== null && (
        <LightGallery
          dynamic
          plugins={[lgThumbnail, lgZoom]}
          mode="lg-fade"
          speed={500}
          height="500px"
          thumbnail={true}
          download={false}
          zoom={true}
          pager={false}
          showCloseIcon={true}
          mobileSettings={{
            controls: true,
            download: false,
            rotate: false,
          }}
          closable={true}
          dynamicEl={media.map((col) => ({
            id: col.id,
            src: col.imgUrl,
            thumb: col.imgUrl,
          }))}
          onInit={(detail) => {
            const lg = detail.instance
            lg.openGallery(currentImageIndex)
          }}
        />
      )}
    </>
  )
}

export default SsmInstagramSlider
