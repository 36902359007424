import { fadeIn } from '@lib/motion'
import { Box as MotionBox } from '@motion'
import { AnimatePresence } from 'framer-motion'

interface ICollapseBox {
  children?: JSX.Element | JSX.Element[]
  displayChildren: boolean
}

export const CollapseBox = ({ children, displayChildren }: ICollapseBox) => {
  const variants = {
    open: {
      opacity: 1,
      height: 'auto',
    },
    collapsed: {
      opacity: 0,
      height: 0,
    },
  }

  return (
    <AnimatePresence initial={false}>
      {displayChildren && (
        <MotionBox
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={variants}
          transition={{ easing: fadeIn.easing }}
        >
          {children}
        </MotionBox>
      )}
    </AnimatePresence>
  )
}
