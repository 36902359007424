import { createIcon } from '@chakra-ui/icons'

const Exhibition = createIcon({
  displayName: 'Exhibition',
  defaultProps: {
    width: '29px',
    height: '20px',
  },
  viewBox: '0 0 29 20',
  path: (
    <path
      d="M.703 19.556h27.58c.387 0 .703-.283.703-.631V.632c0-.349-.316-.632-.704-.632H.703C.316 0 0 .284 0 .632v18.292c0 .348.317.633.703.633zM2.4 18.293l1.682-1.513h20.822l1.682 1.513H2.4zm2.093-2.777V4.04h20v11.474h-20zm23.086 1.883l-1.68-1.514V3.671l1.68-1.513v15.24zm-.994-16.135l-1.68 1.513H4.08L2.4 1.263h24.186zm-25.18.895l1.683 1.513v12.213l-1.682 1.513V2.158z"
      fill="currentColor"
    />
  ),
})

export default Exhibition
