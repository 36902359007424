import eMuseumHttp from '../eMuseumHttpService'
import {
  COLLECTIONS,
  COLLECTION_DETAIL,
  OBJECTS_OF_COLLECTION,
} from '@lib/const/api-endpoints.const'

/**
 * Collections Http Service
 */
export const CollectionsHttpService = {
  /**
   * Get all collections eMuseumHttp service
   */
  getAllCollections: async () => await eMuseumHttp.get(COLLECTIONS),
  /**
   * Get collection detail eMuseumHttp service
   */
  getCollectionDetail: async (id) =>
    await eMuseumHttp.get(COLLECTION_DETAIL(id)),
  /**
   * Get objects of collection detail eMuseumHttp service
   * For example;
   * - get objects of collection: https://emuseum.sakipsabancimuzesi.org/collections/340/json
   * -  get objects of collection with add params: https://emuseum.sakipsabancimuzesi.org/collections/340/json?page:1
   */
  getObjectsOfCollection: async (id, addParams?: string) =>
    await eMuseumHttp.get(`${OBJECTS_OF_COLLECTION(id)}?${addParams}`),
}
