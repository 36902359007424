import {
  CollectionWorkCategoryDetailSection,
  CollectionWorkDetailHero,
  CollectionWorkDetailSection,
  ContentNotFound,
  Layout,
  RelatedWorks,
} from '@common'
import { ISSRPageData } from '@interfaces'
import { getCurrentLocaleByObjectTitle } from '@utils'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
// import ART_COLLECTION_WORK_DETAIL_ACCORDION_COLLECTION from '@lib/const/art-collection-work-detail-accordion-collection'
// import RELATED_WORKS_COLLECTION from '@lib/const/related-works-collection.const'

interface IObjectTemplate extends ISSRPageData {
  headerLeftNavButtonLabel: string
  headerLeftNavButtonHref: string
}

export const ObjectTemplate = ({
  global: { navigation, activeFontFamily = 'body' },
  pageData,
  headerLeftNavButtonLabel,
  headerLeftNavButtonHref,
}: IObjectTemplate) => {
  const { t } = useTranslation()
  const { asPath, basePath, locale } = useRouter()

  if (pageData.error) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound infoType="serverSideError" />
      </Layout>
    )
  }

  return (
    <Layout
      title={getCurrentLocaleByObjectTitle(pageData.data, locale)}
      navigation={navigation}
      path={asPath}
    >
      <CollectionWorkDetailHero
        objectData={pageData.data}
        photoCollection={pageData.data.photoCollection}
        shareProps={{
          title: `${pageData.data.title.value} | SSM`,
          text: pageData.data.title.value,
          url: basePath,
        }}
        containerPros={{
          pt: { base: '30px', lg: '33px' },
        }}
        headerLeftNavButtonLabel={headerLeftNavButtonLabel}
        headerLeftNavButtonHref={headerLeftNavButtonHref}
        activeFontFamily={activeFontFamily}
      />
      <CollectionWorkDetailSection
        objectData={pageData.data}
        containerProps={{
          py: { base: 'padding.primary2', lg: 'padding.primary4' },
        }}
        accordionItems={pageData.data.accordionItems}
      />
      {pageData.data.relatedObjects &&
        pageData.data.relatedObjects.length > 0 && (
          <RelatedWorks
            title={t('common:object.relatedWorks.title')}
            collection={pageData.data.relatedObjects}
          />
        )}
      <CollectionWorkCategoryDetailSection
        title={t('common:object.categories.title')}
        subject={pageData.data?.classification?.value || ''}
        format={
          locale === 'tr'
            ? pageData.data?.medium?.value || ''
            : pageData.data?.markings?.value || ''
        }
        creator={pageData.data?.people?.value || ''}
        period={pageData.data?.displayDate?.value || ''}
        geographicalLocation={t('common:turkey')}
        containerProps={{
          pb: { base: 'padding.primary2', lg: 'padding.primary4' },
        }}
      />
    </Layout>
  )
}
