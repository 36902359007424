import {
  Drawer as ChakraDrawer,
  DrawerHeader,
  useBreakpointValue,
  DrawerContent,
  DrawerOverlay,
  useTheme,
  Box,
} from '@chakra-ui/react'
import { I18nWidget, NavList } from '@common'
import { useStore } from '@store'
import { HambugerMenu, SearchBox } from '@ui'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import * as S from './styles'
import { MotionImage } from '@ui'
import { AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'
import { sideMenuAnim } from '@lib/motion'
import { IMenuPaths, IFonts } from '@interfaces'

interface IDrawer {
  activeFontFamily?: IFonts
  navigation: IMenuPaths[]
}

const Drawer = observer(
  ({ navigation, activeFontFamily = 'body' }: IDrawer) => {
    const DRAWER_MENU_BACKGROUND_IMAGES =
      navigation && typeof navigation === 'object' && navigation.length > 0
        ? navigation.map((nav) => nav.bgImage)
        : DRAWER_MENU_BGS

    const {
      ui: { displaySideMenu, toggleSideMenu, activeDrawerMenuIndex },
      search,
    } = useStore()
    const { t } = useTranslation()
    const theme = useTheme()

    const drawerSizeVariant = useBreakpointValue({ base: 'full', lg: 'lg' })

    const handleOnPressSearch = () => {
      toggleSideMenu()
      search.toggleDisplaySearchModal()
    }

    // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
    // then wrap that within 0-2 to find our image ID in the array below. By passing an
    // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
    // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
    const imageIndex = wrap(
      0,
      DRAWER_MENU_BACKGROUND_IMAGES.length,
      activeDrawerMenuIndex
    )

    return (
      <ChakraDrawer
        isOpen={displaySideMenu}
        placement="right"
        onClose={toggleSideMenu}
        size={drawerSizeVariant}
      >
        <DrawerOverlay
          background="blackAlpha.primary80"
          transition="all 0.3s ease"
          sx={{
            backdropFilter: 'blur(8px)',
          }}
        >
          <DrawerContent
            background="red.primary"
            maxW={{ base: 'drawer.xs', lg: 'drawer.lg' }}
            boxShadow="none"
          >
            <DrawerHeader
              px={'padding.primary'}
              py={0}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              minHeight={10}
            >
              <I18nWidget
                onDrawerMenu={true}
                activeFontFamily={activeFontFamily}
              />
              <SearchBox
                onDrawerMenu={true}
                onClick={handleOnPressSearch}
                label={t('nav:search')}
                isActive={search.displaySearchModal}
                activeFontFamily={activeFontFamily}
              />
              <HambugerMenu
                onDrawerMenu={true}
                onClick={toggleSideMenu}
                label={t('nav:close')}
                isOpened={true}
                activeFontFamily={activeFontFamily}
              />
            </DrawerHeader>

            <S.Drawer__Body>
              <NavList
                navigation={navigation}
                activeFontFamily={activeFontFamily}
              />
            </S.Drawer__Body>
          </DrawerContent>
          <Box
            overflow="hidden"
            position="absolute"
            width={{ base: '22.5vw', lg: '170px' }}
            height="100%"
            objectFit="cover"
            right={{ base: 'auto', lg: theme.sizes.drawer.lg }}
          >
            <AnimatePresence exitBeforeEnter>
              <MotionImage
                key={activeDrawerMenuIndex}
                imgUrl={DRAWER_MENU_BACKGROUND_IMAGES[imageIndex]}
                imgAlt="Active Image Menu Item"
                imageProps={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                }}
                fallbackSrc={DRAWER_MENU_BACKGROUND_IMAGES[0]}
                containerProps={{
                  width: 'full',
                  height: 'full',
                }}
                motionProps={{
                  variants: sideMenuAnim.imagesOnDrawerMenu,
                  initial: 'enter',
                  animate: 'center',
                  exit: 'exit',
                }}
              />
            </AnimatePresence>
          </Box>
        </DrawerOverlay>
      </ChakraDrawer>
    )
  }
)

const DRAWER_MENU_BGS = [
  '/img/nav/visit.jpg',
  '/img/nav/collections.jpg',
  '/img/nav/visit.jpg',
  '/img/nav/explore-art.jpg',
  '/img/nav/explore-ssm.jpg',
  '/img/nav/visit.jpg',
]

export default Drawer
