/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  Container,
  Divider,
  Flex,
  Text,
} from '@chakra-ui/react'
import { Minus, Plus } from '@icon'
import useTranslation from 'next-translate/useTranslation'
import { IAccordionCollection, IFonts } from '@interfaces'
import { Link, RichText } from '@ui'
import { ISectionData } from '@interfaces'

import { useRouter } from 'next/router'

interface IAccordionSection extends BoxProps {
  stickyId: string
  sectionTitle: string
  data: IAccordionCollection[]
  activeFontFamily?: IFonts
  withDivider?: boolean
  isLastItem?: boolean
  activeScreen?: ISectionData['activeScreen']
}

const AccordionSection = ({
  stickyId,
  sectionTitle,
  data,
  withDivider = false,
  isLastItem = false,
  activeFontFamily = 'body',
  activeScreen = 'visit',
  ...containerProps
}: IAccordionSection) => {
  const { t } = useTranslation()
  const router = useRouter()

  const aara = router.pathname.includes('archive-and-research-area')
  return (
    <Box
      id={stickyId}
      // as="section" // ssr hata yuzunden kaldırıldı.
      py={{ base: 'padding.primary', lg: 'padding.primary2' }}
      {...(isLastItem && {
        pb: { base: 'padding.primary2', lg: 'padding.primary4' },
      })}
      {...containerProps}
    >
      <Container>
        <Text
          as="h2"
          color="red.primary"
          fontSize={{ base: '2xl', lg: '3xl' }}
          fontFamily={activeFontFamily}
          mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
        >
          {sectionTitle}
        </Text>
        <Flex flexDirection="column" maxW="container.secondary" mx="auto">
          {aara && (
            <Accordion allowToggle>
              {data &&
                data?.length > 0 &&
                data.map((accordion, i) => (
                  <Box key={accordion.id}>
                    <AccordionItem mb={5}>
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton>
                            <Text
                              flex="1"
                              textAlign="left"
                              as="span"
                              color="black.primary3"
                              fontSize="lg"
                              fontFamily={activeFontFamily}
                            >
                              {t(accordion.accordionTitle)}
                            </Text>
                            {isExpanded ? <Minus /> : <Plus />}
                          </AccordionButton>
                          <AccordionPanel
                            pb={0}
                            pt={5}
                            sx={{
                              '&>*+*': {
                                mt: 5,
                              },
                            }}
                          >
                            {accordion?.accordionHtmlContent && (
                              <RichText
                                htmlContent={accordion.accordionHtmlContent}
                                fontFamily={activeFontFamily}
                              />
                            )}

                            <Flex
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              {accordion?.externalLink && (
                                <Button
                                  as={Link}
                                  target="_blank"
                                  href={accordion?.externalLink || '#'}
                                  mt={6}
                                  variant="link"
                                  fontFamily={activeFontFamily}
                                >
                                  {accordion?.externalLink}
                                </Button>
                              )}

                              {accordion.internelLink && (
                                <Button
                                  as={Link}
                                  href={accordion?.internelLink || '#'}
                                  mt={6}
                                  variant="secondary"
                                  size="secondary"
                                  maxWidth="215px"
                                  fontFamily={activeFontFamily}
                                >
                                  {t('common:reviewProject')}
                                </Button>
                              )}
                            </Flex>
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                    {i + 1 < data.length && (
                      <Divider
                        my="padding.primary"
                        borderBottomColor="white.primary3"
                      />
                    )}
                  </Box>
                ))}
            </Accordion>
          )}
          {!aara && (
            <Accordion allowToggle>
              {data &&
                data?.length > 0 &&
                data.map((accordion, i) => (
                  <Box key={accordion.id}>
                    <AccordionItem mb={5}>
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton>
                            <Text
                              flex="1"
                              textAlign="left"
                              as="span"
                              color="black.primary3"
                              fontSize="lg"
                              fontFamily={activeFontFamily}
                            >
                              {t(accordion.accordionTitle)}
                            </Text>
                            {isExpanded ? <Minus /> : <Plus />}
                          </AccordionButton>
                          <AccordionPanel
                            pb={0}
                            pt={5}
                            sx={{
                              '&>*+*': {
                                mt: 5,
                              },
                            }}
                          >
                            {accordion?.accordionHtmlContent && (
                              <RichText
                                htmlContent={accordion.accordionHtmlContent}
                                fontFamily={activeFontFamily}
                              />
                            )}

                            <Flex
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              {accordion?.externalLink && (
                                <Button
                                  as={Link}
                                  target="_blank"
                                  href={accordion?.externalLink || '#'}
                                  mt={6}
                                  variant="link"
                                  fontFamily={activeFontFamily}
                                >
                                  {accordion?.externalLink}
                                </Button>
                              )}

                              {accordion.internelLink && (
                                <Button
                                  as={Link}
                                  href={accordion?.internelLink || '#'}
                                  mt={6}
                                  variant="secondary"
                                  size="secondary"
                                  maxWidth="215px"
                                  fontFamily={activeFontFamily}
                                >
                                  {t('common:reviewProject')}
                                </Button>
                              )}
                            </Flex>
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                    {i + 1 < data.length && (
                      <Divider
                        my="padding.primary"
                        borderBottomColor="white.primary3"
                      />
                    )}
                  </Box>
                ))}
            </Accordion>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default AccordionSection
