export const GLOBAL = {
  '*': {
    WebkitTapHighlightColor: 'transparent',
  },
  '*, *::before, ::after': {
    borderColor: 'grey.primary4',
  },
  '*:focus': {
    outline: 'none',
  },
  html: {
    scrollBehavior: 'smooth',
    fontSize: 'md',
    fontFamily: 'body',
    color: 'black.primary3',
    lineHeight: 'tall',
  },
  '@-moz-document url-prefix()': {
    html: {
      scrollBehavior: 'auto',
    },
  },
  body: {
    overflowX: 'hidden',
    background: 'white.primary',
    fontSize: 'md',
    color: 'black.primary3',
    lineHeight: 'tall',
    transition: 'all 0.3s ease',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'white.primary3',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'red.primary3',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'red.primary2',
    },
  },
  a: {
    transition: 'all 0.3s ease',
  },
}
