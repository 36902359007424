import { createIcon } from '@chakra-ui/icons'

const Share = createIcon({
  displayName: 'Share',
  viewBox: '0 0 18 15',
  defaultProps: {
    width: '18px',
    height: '15px',
    fill: 'none',
    marginTop: '-5px',
  },
  path: (
    <>
      <path d="M16 7L1 7L1 15" stroke="currentColor" strokeWidth="2" />
      <path
        d="M10.8418 12.8994L16.4987 7.24256L10.8418 1.58571"
        stroke="currentColor"
        strokeWidth="2"
      />
    </>
  ),
})

export default Share
