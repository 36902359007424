import { Swiper, SwiperSlide } from 'swiper/react'
import { WorkshopCard } from '@common'
import { IWorkshopCard } from '@interfaces'
import { Box } from '@chakra-ui/react'
import { getActiveColorsForWorkshopCard } from '@utils'

interface IWorkshopSlider {
  collection: IWorkshopCard[]
}

const WorkshopSlider = ({ collection }: IWorkshopSlider) => (
  <Box
    as="section"
    mt="padding.primary2"
    sx={{
      '.workshopsSwiper.swiper-container': {
        px: { base: 'padding.primary', lg: 'padding.primary3' },
      },
      '.workshopsSwiper .swiper-slide': {
        width: '315px',
      },
    }}
  >
    <Swiper
      className="workshopsSwiper"
      spaceBetween={20}
      slidesPerView="auto"
      grabCursor={true}
      breakpoints={{
        1170: {
          initialSlide: 1,
        },
      }}
    >
      {collection &&
        collection.length > 0 &&
        collection.map((card, i) => (
          <SwiperSlide key={card.id}>
            <WorkshopCard
              colorSchema={getActiveColorsForWorkshopCard(i)}
              {...card}
            />
          </SwiperSlide>
        ))}
    </Swiper>
  </Box>
)

export default WorkshopSlider
