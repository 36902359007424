import { Container, Center, Box, Text, useToast } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { createNavLink, prepareQrReaderLink } from '@utils'

const QrReader = dynamic(() => import('react-qr-reader'), { ssr: false })

const ReactQrReader = () => {
  const { t } = useTranslation()
  const [delay] = useState(3000)
  const [isError, setIsError] = useState(false)
  const router = useRouter()
  const toast = useToast()

  const handleScan = (result) => {
    if (result) {
      setIsError(false)
      toast({
        title: t('common:qrReader.successTitle'),
        description: t('common:qrReader.successDescription'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      const internRouteKey = prepareQrReaderLink(result)

      if (!isError && internRouteKey && internRouteKey['mainRouteKey']) {
        const internUrl = `${createNavLink(
          internRouteKey['mainRouteKey'],
          router.locale
        )}${
          internRouteKey['additionalParams']
            ? `/${internRouteKey['additionalParams']}`
            : ''
        }`

        setTimeout(() => {
          router.push(internUrl)
        }, 2000)
      }
    }
  }

  const handleError = (err) => {
    setIsError(true)
    toast({
      title: t('common:qrReader.errorTitle'),
      description: t('common:qrReader.errorDescription'),
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
    console.error({ handleError: err })
  }

  return (
    <Box as="section" py={{ base: 'padding.primary', lg: 24 }}>
      <Container>
        <Text color="red.primary" fontSize={{ base: '2xl', lg: '3xl' }}>
          {t('common:qrReader.sectionTitle')}
        </Text>
      </Container>
      <Container
        maxW="container.secondary"
        mx="auto"
        mt={{ base: 'padding.primary', lg: 'padding.primary2' }}
        mb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      >
        <Center flexDirection="column">
          <Box minHeight="320px">
            <QrReader
              delay={delay}
              style={{
                height: 240,
                width: 320,
              }}
              onError={handleError}
              onScan={handleScan}
            />
          </Box>
        </Center>
      </Container>
    </Box>
  )
}

export default ReactQrReader
