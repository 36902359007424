import { Flex, Box, AspectRatio, Image, Text, Divider } from '@chakra-ui/react'
import { INewsAndBlogCard } from '@interfaces'
import { Link } from '@ui'
import { Markup } from 'interweave'

const NewsAndBlogCard = ({
  type,
  date,
  title,
  subtitle,
  shortDescription,
  imgUrl,
  url,
  isLastItem = false,
  activeFontFamily = 'spectral',
  mediumLink,
}: INewsAndBlogCard) => {
  return (
    <Box>
      <Link
        target="_blank"
        href={mediumLink ? mediumLink : url}
        _hover={{ transform: 'translateY(-5px)' }}
        transition="all 0.3s ease"
        display="grid"
        gridColumnGap={{ base: '0', lg: '5' }}
        gridTemplateColumns={{ base: '1fr', lg: '300px 1fr' }}
        gridTemplateRows={{ base: 'repeat(5, auto)', lg: 'repeat(4, auto)' }}
        gridTemplateAreas={{
          base: `
          'newsAndBlogPhoto'
          'newsAndBlogTag'
          'newsAndBlogDate'
          'newsAndBlogTitle'
          'newsAndBlogDesc'
        `,
          lg: `
          'newsAndBlogPhoto newsAndBlogTag'
          'newsAndBlogPhoto newsAndBlogDate'
          'newsAndBlogPhoto newsAndBlogTitle'
          'newsAndBlogPhoto newsAndBlogDesc'
        `,
        }}
      >
        <AspectRatio
          gridArea="newsAndBlogPhoto"
          ratio={1.85 / 1}
          mb={{ base: '5', lg: '0' }}
        >
          <Image
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
            src={imgUrl}
            alt={title}
          />
        </AspectRatio>
        <Flex
          flexWrap="wrap"
          gridArea="newsAndBlogTag"
          sx={{
            '& > * + *': {
              ml: '4',
            },
          }}
          mb="2.5"
        >
          <Text
            fontWeight="medium"
            color="red.primary"
            fontSize="xs"
            fontFamily={activeFontFamily}
          >
            {type}
          </Text>
        </Flex>
        <Text
          gridArea="newsAndBlogDate"
          color="grey.primary"
          fontWeight="light"
          fontSize="sm"
          fontFamily={activeFontFamily}
          mb="2.5"
        >
          {date}
        </Text>
        <Text
          gridArea="newsAndBlogTitle"
          fontSize="lg"
          mb="3.5"
          fontFamily={activeFontFamily}
        >
          {title + ' ' + subtitle}
        </Text>

        {shortDescription && (
          <Box
            as="p"
            fontFamily={activeFontFamily}
            sx={{
              gridArea: 'newsAndBlogDesc',
              fontWeight: 'light',
              fontSize: 'sm',
              noOfLines: 2,
            }}
          >
            <Markup content={shortDescription} />
          </Box>
        )}
      </Link>
      {!isLastItem && (
        <Divider
          borderBottomColor="white.primary3"
          opacity="1"
          my="padding.primary"
        />
      )}
    </Box>
  )
}

export default NewsAndBlogCard
