/**
 * from "Osman Hamdi Bey (1842-1910)[;]283[;]138706"
 * to {name: "Osman Hamdi Bey (1842-1910)", sourceID: "283"}
 */
export const parsePeopleDataForObjectDetail = (artist: string) => {
  const splittedArtist = artist.split('[;]')
  const name = splittedArtist[0]
  const sourceID = splittedArtist[1]

  return {
    name,
    sourceID,
  }
}
