import {
  Box,
  Button,
  Text,
  chakra,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  useBoolean,
  useOutsideClick,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useRef } from 'react'
import { useRouter } from 'next/router'
import { Cross, ChevronDown } from '@icon'
import { useStore } from '@store'
import { observer } from 'mobx-react-lite'
import { ISEARCH_FILTER_FACETS } from '@interfaces'
import { createNavLink } from '@utils'

interface ISearchResultsHeader {
  filterFacets: ISEARCH_FILTER_FACETS[]
}

const SearchResultsHeader = observer(
  ({ filterFacets }: ISearchResultsHeader) => {
    const morePopoverRef = useRef()
    const inputEl = useRef<HTMLInputElement>(null)

    /**
     * active flag more button state ( for font weight )
     */
    const [flag, setFlag] = useBoolean()

    /**
     * more button toggle handler
     */
    const { isOpen, onToggle, onClose } = useDisclosure()

    const { t } = useTranslation()
    const {
      search: {
        closeSideMenu,
        searchTerm,
        setSearchTerm,
        searchFacet,
        setSearchFacet,
        setPage,
      },
    } = useStore()
    const router = useRouter()

    /**
     * more popover outside handler
     */
    useOutsideClick({
      ref: morePopoverRef,
      handler: () => onClose(),
    })

    const handleSearchAction = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (searchTerm === '') {
        inputEl.current && inputEl.current.focus()
        return
      }
      closeSideMenu()

      const routerQuery = {
        searchTerm: searchTerm,
        page: '1',
        searchFacet: 'all',
      }

      router.push({
        pathname: createNavLink('search', router.locale),
        query: routerQuery,
      })
    }

    const handleCloseSearchAction = () => {
      setSearchTerm('')
      const routerQuery = router.query
      routerQuery.searchTerm = ''
      router.push({
        pathname: createNavLink('search', router.locale),
        query: routerQuery,
      })
    }

    const handleFilterFacets = (
      filterFact: typeof searchFacet | string,
      isOnSetFlag: boolean
    ) => {
      setSearchFacet(filterFact)

      if (isOnSetFlag) {
        setFlag.on()
      } else {
        setFlag.off()
      }

      const routerQuery = router.query
      routerQuery.searchFacet = filterFact
      routerQuery.page = '1'
      setPage('1')

      router.push({
        pathname: createNavLink('search', router.locale),
        query: routerQuery,
      })
    }

    return (
      <Box>
        <Box bgColor="red.primary" p="padding.primary">
          <Text color="white.primary">
            {t('common:searchModal.description')}
          </Text>
          <chakra.form
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={{ base: 'padding.primary', lg: '5' }}
            onSubmit={handleSearchAction}
          >
            <InputGroup>
              <Input
                ref={inputEl}
                onChange={(e) => setSearchTerm(e.target.value)}
                px="5"
                type="text"
                placeholder={t('common:searchModal.searchPlaceholder')}
                variant="secondary"
                size="secondary"
                value={searchTerm}
              />
              <InputRightElement pr="5" width="5" bottom="0" height="full">
                <IconButton
                  onClick={handleCloseSearchAction}
                  variant="unstyled"
                  aria-label={t('common:searchModal.searchPlaceholder')}
                  icon={
                    <Cross width="12px" height="12px" color="grey.primary2" />
                  }
                />
              </InputRightElement>
            </InputGroup>
            <Button
              type="submit"
              fontSize="sm"
              fontWeight="medium"
              minW={{ base: '10', lg: '110px' }}
              height="50px"
              _hover={{
                bgColor: 'red.primary3',
              }}
            >
              {t('common:searchModal.searchLabel')}
            </Button>
          </chakra.form>
        </Box>
        <Flex
          justifyContent={{ base: 'flex-start', lg: 'center' }}
          alignItems="center"
          flexWrap="nowrap"
          bgColor="red.primary2"
          py="4"
          px="padding.primary"
          overflowX="auto"
          sx={{
            '& > * + *': {
              ml: 'padding.primary',
            },
          }}
        >
          {filterFacets &&
            filterFacets.slice(0, 5).map((filter) => (
              <Button
                ml="padding.primary"
                variant="unstyled"
                key={filter.id}
                color="white.primary"
                fontWeight={filter.type === searchFacet ? 'medium' : 'light'}
                fontSize="sm"
                flex="0 0 auto"
                _hover={{
                  opacity: 0.75,
                }}
                onClick={() => handleFilterFacets(filter.type, false)}
              >
                {t(filter.labelTx)}
              </Button>
            ))}
          {filterFacets.length > 5 && (
            <Popover placement="bottom-start" strategy="fixed" isOpen={isOpen}>
              <PopoverTrigger>
                <Button
                  ml="padding.primary"
                  variant="unstyled"
                  color="white.primary"
                  fontWeight={flag ? 'medium' : 'light'}
                  fontSize="sm"
                  rightIcon={
                    <ChevronDown
                      fontSize="16px"
                      transition="transform 0.3s ease"
                      transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                    />
                  }
                  onClick={onToggle}
                  flex="0 0 auto"
                  display="flex"
                  alignItems="center"
                >
                  {t('search:searchFacets.moreButtonLabel')}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                width="xxs"
                background="white.primary"
                border="none"
                borderRadius="none"
                boxShadow="none"
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <PopoverBody p="5" boxShadow="lg" ref={morePopoverRef}>
                  <Flex flexDirection="column">
                    {filterFacets.slice(5).map((filter) => (
                      <Button
                        key={filter.id}
                        variant="unstyled"
                        color={
                          filter.type === searchFacet
                            ? 'red.primary'
                            : 'black.primary3'
                        }
                        fontWeight={
                          filter.type === searchFacet ? 'medium' : 'light'
                        }
                        fontSize="sm"
                        transition="all 0.3s ease"
                        onClick={() => handleFilterFacets(filter.type, true)}
                        _hover={{
                          color: 'red.primary',
                        }}
                        textAlign="left"
                      >
                        {t(filter.labelTx)}
                      </Button>
                    ))}
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
        </Flex>
      </Box>
    )
  }
)

export default SearchResultsHeader
