import theme from '../../styles/theme'

/**
 * Media Queries hooks for emotion components
 *
 * @param {keyof typeof theme.breakpoints} n
 * @returns string = media (min-width: ${size})
 */
export const mq = (n: keyof typeof theme.breakpoints) => {
  const bpArray = Object.keys(theme.breakpoints).map((key) => [
    key,
    theme.breakpoints[key],
  ])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size})`]
    return acc
  }, [])

  return result
}

/**
 * Media Queries hooks for chakra useMediaQuery
 *
 * @param {keyof typeof theme.breakpoints} n
 * @returns string = (min-width: ${size})
 */
export const getMediaQuery = (n: keyof typeof theme.breakpoints) => {
  const bpArray = Object.keys(theme.breakpoints).map((key) => [
    key,
    theme.breakpoints[key],
  ])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `(min-width: ${size})`]
    return acc
  }, [])

  return result
}
