import { createIcon } from '@chakra-ui/icons'

const Tripadvisor = createIcon({
  displayName: 'Tripadvisor',
  defaultProps: {
    width: '25px',
    height: '16px',
  },
  viewBox: '0 0 25 16',
  path: (
    <path
      d="M8.429 8.69c0 1.145-.988 2.073-2.206 2.073s-2.206-.928-2.206-2.072c0-1.145.988-2.073 2.206-2.073s2.206.928 2.206 2.073zm10.228-2.072c-1.218 0-2.205.928-2.205 2.073 0 1.144.987 2.072 2.205 2.072s2.206-.928 2.206-2.072c0-1.145-.988-2.073-2.206-2.073zm6.22 2.073c0 3.226-2.786 5.841-6.22 5.841a6.416 6.416 0 01-4.224-1.556l-1.992 2.037-1.993-2.038a6.416 6.416 0 01-4.225 1.557c-3.433 0-6.216-2.615-6.216-5.841 0-1.71.781-3.25 2.027-4.318L0 2.293h4.521C6.776.847 9.495 0 12.441 0c2.948 0 5.674.845 7.932 2.294h4.51l-2.034 2.08c1.246 1.068 2.028 2.607 2.028 4.317zm-14.446 0c0-2.183-1.884-3.953-4.208-3.953-2.323 0-4.207 1.77-4.207 3.953s1.884 3.953 4.207 3.953c2.324 0 4.208-1.77 4.208-3.953zm6.68-5.902a12.746 12.746 0 00-4.67-.877c-1.656 0-3.232.312-4.669.877 2.656.954 4.67 3.187 4.67 5.788 0-2.601 2.013-4.833 4.668-5.788zm5.753 5.902c0-2.183-1.883-3.953-4.207-3.953-2.323 0-4.207 1.77-4.207 3.953s1.884 3.953 4.207 3.953c2.324 0 4.207-1.77 4.207-3.953z"
      fill="currentColor"
    />
  ),
})

export default Tripadvisor
