import { v4 as uuidv4 } from 'uuid'
import { IArtSortFilterOption } from '@interfaces'

const ART_SORT_FILTER_OPTIONS: IArtSortFilterOption[] = [
  {
    id: uuidv4(),
    labelTx: 'collections-and-research:search.collectionSort1',
    value: 'Relevance',
  },
  {
    id: uuidv4(),
    labelTx: 'collections-and-research:search.collectionSort2',
    value: 'title-asc',
  },
  {
    id: uuidv4(),
    labelTx: 'collections-and-research:search.collectionSort3',
    value: 'title-desc',
  },
  {
    id: uuidv4(),
    labelTx: 'collections-and-research:search.collectionSort4',
    value: 'displayDate-asc',
  },
  {
    id: uuidv4(),
    labelTx: 'collections-and-research:search.collectionSort5',
    value: 'displayDate-desc',
  },
  {
    id: uuidv4(),
    labelTx: 'collections-and-research:search.collectionSort6',
    value: 'primaryMakerAlpha-asc',
  },
  {
    id: uuidv4(),
    labelTx: 'collections-and-research:search.collectionSort7',
    value: 'primaryMakerAlpha-desc',
  },
  {
    id: uuidv4(),
    labelTx: 'collections-and-research:search.collectionSort8',
    value: 'invno-asc',
  },
  {
    id: uuidv4(),
    labelTx: 'collections-and-research:search.collectionSort9',
    value: 'invno-desc',
  },
]

export default ART_SORT_FILTER_OPTIONS
