import { types } from 'mobx-state-tree'
import { INewsAndBlogSortTypes } from '@interfaces'

export const NewsAndBlog = types
  .model({
    displaySortModal: types.boolean,
    sortResult: types.union(
      types.literal('all'),
      types.literal('new'),
      types.literal('article'),
      types.literal('interview'),
      types.literal('performance'),
      types.literal('film-and-video'),
      types.literal('trip'),
      types.literal('case-studies')
    ),
  })
  .actions((self) => ({
    setSortResult(val: INewsAndBlogSortTypes) {
      self.sortResult = val
    },
    setDisplaySortModal(val: boolean) {
      self.displaySortModal = val
    },
    toggleDisplaySortModal() {
      self.displaySortModal = !self.displaySortModal
    },
  }))
