export const LETTER_OF_ARTISTS = [
  { a: 'A' },
  { b: 'B' },
  { c: 'C' },
  { ç: '%C3%87' },
  { d: 'D' },
  { e: 'E' },
  { f: 'F' },
  { g: 'G' },
  { h: 'H' },
  { ı: 'I' },
  { i: '%C4%B0' },
  { j: 'J' },
  { k: 'K' },
  { l: 'L' },
  { m: 'M' },
  { n: 'N' },
  { o: 'O' },
  { ö: '%C3%96' },
  { p: 'P' },
  { r: 'R' },
  { s: 'S' },
  { ş: '%C5%9E' },
  { t: 'T' },
  { u: 'U' },
  { ü: '%C3%9C' },
  { v: 'V' },
  { w: 'W' },
  { y: 'Y' },
  { z: 'Z' },
]
