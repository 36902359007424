import { useState } from 'react'
import { ISelectionsCollection } from '@interfaces'

export const useLoadMoreObjectsOfCollection = (
  initalObjects: ISelectionsCollection[],
  totalCountOfObjects: string,
  collectionSourceId: string,
  activeCollectionPage: 'collection' | 'archive',
  locale: string
) => {
  const [activePage, setActivePage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState({ status: false, message: '' })
  const [objectsOfCollection, setObjectsOfCollection] = useState(initalObjects)

  const handleLoadMore = async () => {
    const totalPage = parseInt(totalCountOfObjects, 10)

    if (totalPage >= activePage) {
      return
    }

    try {
      const nextPage = activePage + 1
      setActivePage(nextPage)

      setIsLoading(true)
      const response = await fetch(
        `/api/collection/get-object-of-collections?collectionId=${collectionSourceId}&activeCollectionPage=${activeCollectionPage}&locale=${locale}&currentPage=${nextPage}`
      )
      const objectsOfCollection = await response.json()

      setObjectsOfCollection((oldState) => [
        ...oldState,
        ...objectsOfCollection.data,
      ])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error instanceof Error) {
        setError({ status: true, message: error.message })
      }
    }
  }

  return {
    activePage,
    objectsOfCollection,
    handleLoadMore,
    isLoading,
    error,
  }
}
