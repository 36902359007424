import { Box, Container, Text, Link } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Instagram } from '@icon'
import SsmInstagramSlider from './SsmInstagramSlider'
import { fadeIn } from '@lib/motion'
import { useInViewMotion } from '@lib/hooks'
import { MotionBox, MotionText } from '@ui'
import { IInstagramMedia } from '@interfaces'

interface ISsmInstagram {
  media: IInstagramMedia[]
}

const SsmInstagram = ({ media }: ISsmInstagram) => {
  const { t } = useTranslation('common')
  const { controls, ref } = useInViewMotion(true)

  return (
    <MotionBox
      as="section"
      pt={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      pb={{ base: '28', lg: 'padding.primary4' }}
      position="relative"
      overflow="hidden"
      _before={{
        content: `""`,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: { base: '30vw', lg: '50vw' },
        height: { base: '90%', lg: '100%' },
        backgroundColor: { base: 'white.primary1', sm: 'black.primary2' },
      }}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={fadeIn.animationContainer}
    >
      <Container position="relative" zIndex="2">
        <Box maxW="822px">
          <MotionBox variants={fadeIn.up}>
            <Link
              href="https://www.instagram.com/sakipsabancimuzesi/"
              target="_blank"
              fontSize={{ base: '2xl', lg: '3xl' }}
              color="red.primary"
              mb={{ base: 1, lg: '0' }}
              _hover={{ transform: 'translateY(-5px)' }}
              display="block"
            >
              <Instagram />
              <Text ml="10px" as="span" fontWeight="600">
                sakipsabancimuzesi
              </Text>
            </Link>
          </MotionBox>
          <MotionText
            variants={fadeIn.up}
            fontSize="md"
            fontWeight="light"
            color="initial"
            display={{ base: 'none', lg: 'block' }}
            maxW="70%"
          >
            {t('ssmInstagram.description')}
          </MotionText>
        </Box>
      </Container>
      <SsmInstagramSlider media={media} />
    </MotionBox>
  )
}

export default SsmInstagram
