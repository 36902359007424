import { Box, Container, Tabs, Text } from '@chakra-ui/react'
import { IArtCollection } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import React, { useRef } from 'react'
import TabList from './TabList'
import TabPanels from './TabPanels'
import { IFonts } from '@interfaces'

interface ISection {
  sectionTitle: string
  stickyId: string
  collection: IArtCollection[]
  activeFontFamily?: IFonts
}

const PeriodsAndArtifacts = ({
  sectionTitle,
  stickyId,
  collection,
  activeFontFamily = 'body',
}: ISection) => {
  const sectionRef = useRef(null)
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = React.useState(0)

  const handleSelectChange = (value: string) => {
    setTabIndex(parseInt(value, 10))
  }

  const handleTabItemButton = () => {
    sectionRef.current.scrollIntoView()
  }

  return (
    <Box
      ref={sectionRef}
      py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      as="section"
      id={stickyId}
      bgColor="black.primary3"
    >
      <Container>
        <Text
          as="h3"
          color="red.primary"
          fontSize={{ base: '2xl', lg: '3xl' }}
          fontFamily={activeFontFamily}
          mb={{ base: '6', lg: '16' }}
        >
          {sectionTitle}
        </Text>
        <Tabs
          display="flex"
          flexDirection={{ base: 'column', lg: 'row' }}
          maxW="container.secondary"
          mx="auto"
          index={tabIndex}
          onChange={(index: number) => {
            setTabIndex(index)
          }}
        >
          <TabList
            handleSelectChange={handleSelectChange}
            tabListCollection={collection.map(({ id, title, type }) => {
              return { id, title: t(title), type }
            })}
            activeFontFamily={activeFontFamily}
            handleTabItemButton={handleTabItemButton}
          />
          <TabPanels
            activeTabIndex={tabIndex}
            tabPanelsCollection={collection.map(
              ({ id, description, collection, type }) => {
                return { id, description: t(description), collection, type }
              }
            )}
            activeFontFamily={activeFontFamily}
          />
        </Tabs>
      </Container>
    </Box>
  )
}

export default PeriodsAndArtifacts
