import { createIcon } from '@chakra-ui/icons'

const Location = createIcon({
  displayName: 'Location',
  viewBox: '0 0 20 23',
  defaultProps: {
    width: '20px',
    height: '23px',
  },
  path: (
    <path
      d="M18.9999 4.59608L3.2896 11.8999L11.0607 13.8646L13.7952 20.9901L18.9999 4.59608Z"
      fill="transparent"
      stroke="currentColor"
    />
  ),
})

export default Location
