import { types } from 'mobx-state-tree'
import {
  IExhibitionSortName,
  IExhibitionAndEventActiveCollectionName,
  IPageMetaDataExhibitionAndEventPage,
} from '@interfaces'

export const ExhibitionAndEvents = types
  .model({
    displayFilterModal: types.boolean,
    displaySortModal: types.boolean,
    sortResult: types.union(
      types.literal('current'),
      types.literal('future'),
      types.literal('past')
    ),
    activeCollection: types.union(
      types.literal('exhibitions'),
      types.literal('events'),
      types.literal('learning'),
      types.literal('online'),
      types.literal('workshop'),
      types.literal('tours')
    ),
    page: types.string,
  })
  .actions((self) => ({
    setDisplayFilterModal(val: boolean) {
      self.displayFilterModal = val
    },
    toggleDisplayFilterModal() {
      self.displayFilterModal = !self.displayFilterModal
    },
    setDisplaySortModal(val: boolean) {
      self.displaySortModal = val
    },
    toggleDisplaySortModal() {
      self.displaySortModal = !self.displaySortModal
    },
    setSortResult(val: IExhibitionSortName) {
      self.sortResult = val
    },
    setActiveCollection(val: IExhibitionAndEventActiveCollectionName) {
      self.activeCollection = val
    },
    setPage(val) {
      self.page = val
    },
    setMetaData(metaData: IPageMetaDataExhibitionAndEventPage) {
      self.page = metaData.page
      self.activeCollection = metaData.activeCollection
      self.sortResult = metaData.sort
    },
    onCloseModal() {
      self.displayFilterModal = false
      self.displaySortModal = false
    },
  }))
