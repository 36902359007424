import {
  Flex,
  TabList as ChakraTabList,
  Tab,
  useBreakpointValue,
  Select,
  Box,
} from '@chakra-ui/react'
import { IArtCollection, IFonts } from '@interfaces'

interface ITabList {
  tabListCollection: Omit<IArtCollection, 'description' | 'collection'>[]
  handleSelectChange: (index: string) => void
  activeFontFamily?: IFonts
  handleTabItemButton: () => void
}

const TabList = ({
  tabListCollection,
  handleSelectChange,
  activeFontFamily = 'body',
  handleTabItemButton,
}: ITabList) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const element = event.target as HTMLSelectElement
    handleSelectChange(element.value)
  }

  return (
    <Flex
      as={ChakraTabList}
      flexDir="column"
      alignItems="flex-start"
      w="full"
      maxW={{ base: 'full', lg: '60' }}
      sx={{
        '&>*+*': {
          mt: '10',
        },
      }}
      borderBottom="none"
      mb={{ base: 'padding.primary', lg: '0' }}
      position="sticky"
      top={{ base: '50px', lg: '100px' }}
      alignSelf="flex-start"
      zIndex={1}
    >
      {isDesktop ? (
        tabListCollection.map((tab) => (
          <Tab
            key={tab.id}
            textAlign="left"
            fontSize="lg"
            fontFamily={activeFontFamily}
            fontWeight={'medium'}
            mb="0"
            mr="0"
            color="white.primary"
            borderBottom="none"
            sx={{
              '&[aria-selected=true]': {
                color: 'red.primary',
              },
              '&:focus': {
                boxShadow: 'none',
              },
            }}
            onClick={handleTabItemButton}
          >
            {tab.title}
          </Tab>
        ))
      ) : (
        <Select onChange={handleChange}>
          {tabListCollection.map((tab, index) => (
            <Box
              as="option"
              fontFamily={activeFontFamily}
              key={tab.id}
              value={index}
            >
              {tab.title}
            </Box>
          ))}
        </Select>
      )}
    </Flex>
  )
}

export default TabList
