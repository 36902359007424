import {
  Box,
  SlideFade,
  TabPanel,
  TabPanels as ChakraTabPanels,
} from '@chakra-ui/react'
import { IArtCollection, IFonts } from '@interfaces'
import SelectionsFromCollection from './SelectionsFromCollection'
import { RichText } from '@ui'
interface ITabPanels {
  tabPanelsCollection: Omit<IArtCollection, 'title'>[]
  activeTabIndex: number
  activeFontFamily?: IFonts
}

const TabPanels = ({
  tabPanelsCollection,
  activeTabIndex,
  activeFontFamily = 'body',
}: ITabPanels) => {
  return (
    <Box
      as={ChakraTabPanels}
      flex={{ base: 'auto', lg: '1' }}
      pl={{ base: '0', lg: 20 }}
    >
      {tabPanelsCollection.map((col, index) => (
        <TabPanel key={col.id} p="0">
          <Box as={SlideFade} in={activeTabIndex === index} offsetY="45px">
            {col.description && (
              <RichText
                htmlContent={col.description}
                color="white.primary"
                fontFamily={activeFontFamily}
                fontSize="md"
                mb="45px"
              />
            )}
            <SelectionsFromCollection
              collection={col.collection}
              isDarkSection={true}
              activeFontFamily={activeFontFamily}
            />
          </Box>
        </TabPanel>
      ))}
    </Box>
  )
}

export default TabPanels
