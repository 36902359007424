import { createIcon } from '@chakra-ui/icons'

const Blog = createIcon({
  displayName: 'Blog',
  viewBox: '0 0 60 60',
  defaultProps: {
    width: 26,
    height: 26,
  },
  path: (
    <>
      <path
        d="m55.5 60h-51c-2.481 0-4.5-2.019-4.5-4.5v-51c0-2.481 2.019-4.5 4.5-4.5h51c2.481 0 4.5 2.019 4.5 4.5v51c0 2.481-2.019 4.5-4.5 4.5zm-51-58c-1.378 0-2.5 1.122-2.5 2.5v51c0 1.378 1.122 2.5 2.5 2.5h51c1.379 0 2.5-1.122 2.5-2.5v-51c0-1.378-1.122-2.5-2.5-2.5z"
        fill="currentColor"
      />
      <path
        d="m9 10h-4c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1h4c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1zm-3-2h2v-2h-2z"
        fill="currentColor"
      />
      <path
        d="m17 10h-4c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1h4c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1zm-3-2h2v-2h-2z"
        fill="currentColor"
      />
      <path
        d="m25 10h-4c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1h4c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1zm-3-2h2v-2h-2z"
        fill="currentColor"
      />
      <path
        d="m59 14h-58c-.552 0-1-.448-1-1v-8.5c0-2.481 2.019-4.5 4.5-4.5h51c2.481 0 4.5 2.019 4.5 4.5v8.5c0 .552-.448 1-1 1zm-57-2h56v-7.5c0-1.378-1.122-2.5-2.5-2.5h-51c-1.378 0-2.5 1.122-2.5 2.5z"
        fill="currentColor"
      />
      <path
        d="m27 38h-22c-.552 0-1-.448-1-1v-20c0-.552.448-1 1-1h22c.552 0 1 .448 1 1v20c0 .552-.448 1-1 1zm-21-2h20v-18h-20z"
        fill="currentColor"
      />
      <path
        d="m16 38c-.256 0-.512-.098-.707-.293l-5.293-5.293-4.293 4.293c-.391.391-1.023.391-1.414 0s-.391-1.023 0-1.414l5-5c.391-.391 1.023-.391 1.414 0l6 6c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293z"
        fill="currentColor"
      />
      <path
        d="m12 28c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"
        fill="currentColor"
      />
      <path
        d="m27 36c-.256 0-.512-.098-.707-.293l-6.262-6.262-5.506 6.002c-.373.408-1.005.435-1.413.061-.407-.373-.435-1.006-.061-1.413l6.211-6.771c.184-.201.443-.318.715-.324.276-.012.536.1.729.293l7 7c.391.391.391 1.023 0 1.414-.194.195-.45.293-.706.293z"
        fill="currentColor"
      />
      <path
        d="m55 56h-22c-.552 0-1-.448-1-1v-20c0-.552.448-1 1-1h22c.552 0 1 .448 1 1v20c0 .552-.448 1-1 1zm-21-2h20v-18h-20z"
        fill="currentColor"
      />
      <path
        d="m44 56c-.256 0-.512-.098-.707-.293l-5.293-5.293-4.293 4.293c-.391.391-1.023.391-1.414 0s-.391-1.023 0-1.414l5-5c.391-.391 1.023-.391 1.414 0l6 6c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293z"
        fill="currentColor"
      />
      <path
        d="m40 46c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"
        fill="currentColor"
      />
      <path
        d="m55 54c-.256 0-.512-.098-.707-.293l-6.262-6.262-5.506 6.002c-.373.408-1.005.435-1.413.061-.407-.373-.435-1.006-.061-1.413l6.211-6.771c.184-.201.443-.318.715-.324.267-.011.536.1.729.293l7 7c.391.391.391 1.023 0 1.414-.194.195-.45.293-.706.293z"
        fill="currentColor"
      />
      <path
        d="m49 22h-16c-.552 0-1-.448-1-1s.448-1 1-1h16c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m55 22h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m49 30h-16c-.552 0-1-.448-1-1s.448-1 1-1h16c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m55 30h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m55 26h-16c-.552 0-1-.448-1-1s.448-1 1-1h16c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m35 26h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m21 44h-16c-.552 0-1-.448-1-1s.448-1 1-1h16c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m27 44h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m21 52h-16c-.552 0-1-.448-1-1s.448-1 1-1h16c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m27 52h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m27 48h-16c-.552 0-1-.448-1-1s.448-1 1-1h16c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
      <path
        d="m7 48h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1z"
        fill="currentColor"
      />
    </>
  ),
})

export default Blog
