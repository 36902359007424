import { createIcon } from '@chakra-ui/icons'

const M = createIcon({
  displayName: 'M',
  defaultProps: {
    width: 272,
    height: 186,
    color: 'blue.primary',
  },
  viewBox: '0 0 272 186',
  path: (
    <path
      fill="currentColor"
      d="M271.394 13.427L157.352.479 142.91 70.448 118.508 2.969.233 6.206l1.245 174.798 91.881-.747-6.722-89.64 25.646 94.62 51.543-5.976 14.442-69.72 6.972 71.712 82.171 2.988 3.983-170.814z"
    />
  ),
})

export default M
