import { Flex, Box, Container, Text } from '@chakra-ui/react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import {
  SelectionsFromCollectionCard,
  CollectionPaginationText,
  Skeletons,
} from '@ui'
import { ContentNotFound, CollectionSearchPagination } from '@common'
import {
  ISelectionsFromCollectionCard,
  ICollectionSearchScreenMeta,
} from '@interfaces'
import { useRouter } from 'next/router'
import {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  useCallback,
} from 'react'
import Router from 'next/router'
import { useStore } from '@store'
import { FadeInUpBox, IntersectionObserver, StaggerWrap } from '@motion'
import { observer } from 'mobx-react-lite'
import { POPULAR_COLLECTION_SEARCH_TEARMS } from '@/src/lib/const/popularCollectionSearchTerms.const'

interface IArtCollectionSearchResult {
  searchTearm: string
  totalCountLabel: string
  collections: ISelectionsFromCollectionCard[]
  metaData: ICollectionSearchScreenMeta
}

const ArtCollectionSearchResult = observer(
  ({
    searchTearm,
    totalCountLabel,
    collections,
    metaData,
  }: IArtCollectionSearchResult) => {
    const router = useRouter()
    const containerRef = useRef(null) as MutableRefObject<HTMLDivElement>

    const {
      artSearch: { setPage, searchTitle, selectedInCollectionOptions },
    } = useStore()

    const [isLoading, setLoading] = useState(false) //State for loading indicator
    const startLoading = () => setLoading(true)
    const stopLoading = () => {
      setTimeout(() => {
        setLoading(false)
        containerRef.current.scrollIntoView()
      }, 750)
    }

    //Since requests happens after chaning routes url ?page={n} we need to bind loading events
    // on the router change event.
    useEffect(() => {
      //Setting router event handlers after component is located
      Router.events.on('routeChangeStart', startLoading)
      Router.events.on('routeChangeComplete', stopLoading)

      return () => {
        Router.events.off('routeChangeStart', startLoading)
        Router.events.off('routeChangeComplete', stopLoading)
      }
    }, [])

    // Triggers fetch for new page
    const handlePagination = (page: number) => {
      const path = router.pathname
      const query = router.query
      query.page = page.toString()

      const yCoordinate =
        containerRef?.current?.getBoundingClientRect().top + window.pageYOffset
      const yOffset = -40
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
      setPage(page.toString())

      router.push(
        {
          pathname: path,
          query: query,
        },
        undefined,
        { scroll: false }
      )
    }

    const getCollectionTitle = useCallback(() => {
      const filteredTitle = POPULAR_COLLECTION_SEARCH_TEARMS[
        router.locale
      ].find((term) => term.inCollection == selectedInCollectionOptions)
      if (filteredTitle) {
        return filteredTitle.displayTerm
      }
      return selectedInCollectionOptions
    }, [router.locale, selectedInCollectionOptions])

    return (
      <Box
        as="section"
        pt={{ base: '40px', lg: '110px' }}
        pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
        ref={containerRef}
      >
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          alignItems="baseline"
        >
          <Text
            color="red.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
            textTransform="capitalize"
          >
            {`${
              searchTearm !== ''
                ? `‘${searchTearm}’`
                : searchTitle !== ''
                ? `‘${searchTitle} ${getCollectionTitle()}’`
                : ''
            }`}
          </Text>
          <Text fontSize="lg">&nbsp;{totalCountLabel}</Text>
        </Flex>
        <Container maxW="container.secondary" mt="padding.primary">
          {collections.length < 1 ? (
            <ContentNotFound minHeight="50vh" />
          ) : (
            <>
              <StaggerWrap staggerChildren={1.6}>
                <ResponsiveMasonry columnsCountBreakPoints={{ 0: 2, 768: 3 }}>
                  <Masonry columnsCount={3} gutter="20px">
                    {isLoading
                      ? [1, 2, 3, 4, 5, 6].map((_c, i) => (
                          <Skeletons.PublicationCard key={i} />
                        ))
                      : collections.map((collection) => (
                          <IntersectionObserver key={collection.id}>
                            <FadeInUpBox duration={1}>
                              <SelectionsFromCollectionCard
                                url={collection.url}
                                title={collection.title}
                                name={collection.name}
                                imgUrl={collection.imgUrl}
                                isDarkSection={false}
                              />
                            </FadeInUpBox>
                          </IntersectionObserver>
                        ))}
                  </Masonry>
                </ResponsiveMasonry>
              </StaggerWrap>
              {!isLoading && (
                <>
                  <CollectionSearchPagination
                    currentPage={parseInt(metaData.page, 10)}
                    totalPage={parseInt(metaData.totalPage, 10)}
                    handlePagination={handlePagination}
                  />
                  {parseInt(metaData.totalPage, 10) > 1 && (
                    <CollectionPaginationText
                      activeLengthOnPage={collections.length}
                      totalLength={metaData.totalCount}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </Box>
    )
  }
)

export default ArtCollectionSearchResult
