import { IExhibitionAndEventAccessibilityFilter } from '@interfaces'
import { v4 as uuidv4 } from 'uuid'

const EXHIBITION_AND_EVENT_ACCESSIBILITY_FILTER_TYPES: IExhibitionAndEventAccessibilityFilter[] =
  [
    {
      id: uuidv4(),
      labelTx: 'exhibition-and-event:home.accessibilityFilterTypes.voiceGuide',
      type: 'voiceGuide',
    },
    {
      id: uuidv4(),
      labelTx: 'exhibition-and-event:home.accessibilityFilterTypes.threeDTour',
      type: 'threeDTour',
    },
  ]

export default EXHIBITION_AND_EVENT_ACCESSIBILITY_FILTER_TYPES
