import { createIcon } from '@chakra-ui/icons'

const AudioGuide = createIcon({
  displayName: 'AudioGuide',
  defaultProps: {
    width: 22,
    height: 22,
  },
  viewBox: '0 0 22 22',
  path: (
    <path
      fill="currentColor"
      d="M18.906 11V3.548c0-.94-.362-1.843-1.007-2.509A3.384 3.384 0 0015.47 0c-.912 0-1.786.374-2.43 1.04a3.607 3.607 0 00-1.008 2.508v14.904c0 .376-.145.737-.402 1.003a1.353 1.353 0 01-.973.416c-.364 0-.714-.15-.972-.416a1.443 1.443 0 01-.403-1.003V8.516c0-.941-.362-1.844-1.007-2.509a3.384 3.384 0 00-2.43-1.04c-.912 0-1.786.375-2.43 1.04a3.607 3.607 0 00-1.008 2.51V11c0 .376-.145.738-.402 1.004a1.354 1.354 0 01-.973.415H0v2.13h1.031c.912 0 1.786-.374 2.43-1.04A3.607 3.607 0 004.47 11V8.516c0-.376.145-.737.402-1.004.258-.266.608-.415.973-.415.364 0 .714.15.972.415.258.267.403.628.403 1.004v9.936c0 .94.362 1.843 1.007 2.509A3.384 3.384 0 0010.656 22c.912 0 1.786-.374 2.43-1.04a3.607 3.607 0 001.008-2.508V3.548c0-.376.145-.737.402-1.003.258-.266.608-.416.973-.416.364 0 .714.15.972.416s.403.627.403 1.003V11c0 .941.362 1.844 1.007 2.51a3.384 3.384 0 002.43 1.038H22V12.42h-1.719c-.364 0-.714-.15-.972-.415A1.443 1.443 0 0118.906 11z"
    />
  ),
})

export default AudioGuide
