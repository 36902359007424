import { Container, Flex, Text, Menu, MenuButton, MenuList, Button, MenuItem, useMediaQuery } from "@chakra-ui/react";
import { I18nWidget } from "@common";
import { SSMBigLogo} from "@icon";
import { useStickyHeader } from "@lib/hooks";
import { useStore } from "@store";
import { HambugerMenu, Link, SearchBox, Ticket, Store } from "@ui";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import useTranslation from "next-translate/useTranslation";
import * as S from "./styles";
import { createNavLink } from "@utils";
import { useRouter } from "next/router";
import { IMenuPaths, IFonts } from "@interfaces";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface IHeader {
  activeFontFamily?: IFonts;
  isHomePage?: boolean;
  navigation: IMenuPaths[];
}

const Header = observer(
  ({ isHomePage, activeFontFamily = "heading", navigation }: IHeader) => {
    const { ui, search } = useStore();
    const { t } = useTranslation();
    const { locale } = useRouter();
    const [isDesktop] = useMediaQuery("(min-width: 769px)");

    // side effects
    useStickyHeader();

    const handleOnPressMenu = () => {
      ui.setDisplayBannerSlider(false);
      ui.toggleSideMenu();
    };

    const handleOnPressSearchBox = () => {
      search.toggleDisplaySearchModal();
      ui.setDisplayBannerSlider(false);
      const yCoordinate = window.pageYOffset;
      window.scrollTo({ top: yCoordinate - 1, behavior: "smooth" });
    };

    return (
      <S.Header
        display="flex"
        as="header"
        width="full"
        maxWidth="fillX"
        height={{ base: "50px", md: "65px" }}
        minHeight={12}
        zIndex={6}
        className={cn({
          "Header--sticky":
            ui.isSticky || (!isHomePage && search.displaySearchModal),
          "Header--secondary":
            (!isHomePage && !search.displaySearchModal) ||
            (isHomePage && search.displaySearchModal),
          "Header--hide":
            isHomePage && !search.displaySearchModal && !ui.isSticky
              ? false
              : ui.scrollDir === "down"
        })}
      >
        <Container
          px={{ base: "padding.parimary", lg: "50px" }}
          maxW="100%" className="Header__Inner"
        >
          <Flex align="center">
            <Link
              href={createNavLink("home", locale)}
              className={cn(
                {
                  Header__SSMLogo: isHomePage
                },
                {
                  "Header__SSMLogo--visible":
                    (ui.isSticky || search.displaySearchModal) && !isHomePage
                }
              )}
            >
              <SSMBigLogo className="Header__SSMBigLogo__Icon" />
            </Link>
            <Flex display={{ base: "none", md: "flex" }} align="left" as="nav" ml="30px" justifyContent="center"
                  alignItems="center">
              {navigation && navigation.map((menu, index) => (
                <div key={index}>
                  {!menu.subMenu || menu.subMenu.length === 0 ? (
                    <Text
                      as="a"
                      href={menu.path}
                      color="white.primary"
                      fontWeight="bold"
                      fontSize="15px"
                      pr="5"
                      fontFamily="heading"
                    >
                      {menu.labelTx}
                    </Text>
                  ) : (
                    <Menu autoSelect={false}>
                      <MenuButton
                        as={Button}
                        color="white.primary"
                        fontWeight="bold"
                        fontSize="15px"
                        bg="transparent"
                        pr="5"
                        pl="0"
                        fontFamily="heading"
                        _hover={{ bg: "transparent" }}
                        _active={{ bg: "transparent" }}
                        rightIcon={<ChevronDownIcon />}
                      >
                        {menu.labelTx}
                      </MenuButton>
                      <MenuList
                        bg="red.primary"
                        borderRadius="0"
                        border="none"
                        color="white.primary"
                        sx={{
                          "& > *": {
                            _hover: {
                              color: "red.primary",
                              bg: "white.primary",
                              textDecoration: "underline"
                            }
                          }
                        }}
                      >
                        {menu.subMenu.map((subItem) => (

                          <Link
                            href={subItem.path}
                            {...(subItem.isExternal && { target: "_blank" })}
                            key={subItem.id}
                            display="block"
                          >
                            <MenuItem>
                              {subItem.labelTx}
                            </MenuItem>
                          </Link>


                        ))}
                      </MenuList>
                    </Menu>
                  )}
                </div>
              ))}
            </Flex>
          </Flex>

          <style jsx global>
            {`
              html {
                overflow-y: overlay;
              }
            `}
          </style>

          <Flex className="Header__Nav" align="center" as="nav">
            <Ticket
              label={t("nav:ticket")}
              isSticky={
                !isHomePage ? true : ui.isSticky || search.displaySearchModal
              }
              isActive={search.displaySearchModal}
              activeFontFamily={activeFontFamily}
            />

            <Store
              label={t("nav:giftShop")}
              isSticky={
                !isHomePage ? true : ui.isSticky || search.displaySearchModal
              }
              isActive={search.displaySearchModal}
              activeFontFamily={activeFontFamily}
            />

            <I18nWidget
              isSticky={
                !isHomePage ? true : ui.isSticky || search.displaySearchModal
              }
              activeFontFamily={activeFontFamily}
            />
            <SearchBox
              onClick={handleOnPressSearchBox}
              label={t("nav:search")}
              isSticky={
                !isHomePage ? true : ui.isSticky || search.displaySearchModal
              }
              isActive={search.displaySearchModal}
              activeFontFamily={activeFontFamily}
            />
            {!isDesktop &&
              <HambugerMenu
                isOpened={ui.displaySideMenu}
                onClick={handleOnPressMenu}
                label={t("nav:menu")}
                isSticky={
                  !isHomePage ? true : ui.isSticky || search.displaySearchModal
                }
                activeFontFamily={activeFontFamily}
              />
            }

          </Flex>
        </Container>
        {isHomePage && !search.displaySearchModal && (
          <Link
            href="/"
            className={cn("Header__SSMBigLogo", {
              "Header__SSMBigLogo--hidden": ui.isSticky && isHomePage
            })}
          >
            {locale == "tr" ? (
              <SSMBigLogo className="Header__SSMBigLogo__Icon" />) : (
              <SSMBigLogo className="Header__SSMBigLogo__Icon" />)}
          </Link>
        )}
      </S.Header>
    );
  }
);

export default Header;
