import drupalHttp from '../drupalHttpService'
import { NAVIGATION } from '@lib/const/api-endpoints.const'

/**
 * Navigation Http Service
 */
export const NavigationHttpService = {
  /**
   * Get nav items
   */
  getNavItems: async (locale: string) =>
    await drupalHttp.get(`${NAVIGATION[locale]}`),
}
