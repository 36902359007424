import { createIcon } from '@chakra-ui/icons'

const Play = createIcon({
  displayName: 'Play',
  defaultProps: {
    width: 185,
    height: 329,
  },
  viewBox: '0 0 185 329',
  path: (
    <>
      <circle
        r="27.655"
        transform="matrix(-1 0 0 1 142.882 191.655)"
        fill="#E2513D"
      />
      <circle
        r="27.655"
        transform="matrix(-1 0 0 1 142.882 149.655)"
        fill="#E2513D"
      />
      <circle
        r="27.655"
        transform="matrix(-1 0 0 1 142.882 233.655)"
        fill="#E2513D"
      />
      <circle
        r="26.733"
        transform="matrix(-1 0 0 1 142.882 265.402)"
        fill="#E2513D"
      />
      <circle
        r="17.976"
        transform="matrix(-1 0 0 1 17.976 155.611)"
        fill="#F8BB5B"
      />
      <circle
        r="17.976"
        transform="matrix(-1 0 0 1 144.265 56.976)"
        fill="#fff"
      />
      <path
        d="M133.746 41.746c-11.664 8.63-28.116 6.17-36.746-5.494L139.24 5c8.63 11.664 6.17 28.116-5.494 36.746z"
        fill="#345744"
      />
      <path
        fill="#345744"
        d="M97 21.737L123.144 0l17.842 21.46-26.144 21.736z"
      />
      <path
        d="M162.24 66.313c-3.934.946-12.208.497-13.827-8.877"
        stroke="#C71F3F"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M184.364 328.863h-64.528l4.058-16.58a16.948 16.948 0 0116.462-12.918h.276a16.508 16.508 0 0114.853 9.303l.483.996a14.739 14.739 0 0013.262 8.307 14.74 14.74 0 0112.287 6.597l2.847 4.295z"
        fill="#FA79F7"
      />
      <path
        d="M168.122 95.094c-5.263-13.406-20.398-20.007-33.805-14.744L24.865 123.322l19.061 48.55 109.452-42.973c13.407-5.263 20.008-20.398 14.744-33.805z"
        fill="#E2513D"
      />
    </>
  ),
})

export default Play
