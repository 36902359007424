import { Flex, Skeleton, SkeletonText } from '@chakra-ui/react'

const PublicationCard = () => (
  <Flex flexDirection="column" width="full" maxWidth="300px">
    <Skeleton width="full" height="375px" mb="4" />
    <SkeletonText mt="6" noOfLines={5} spacing="3" />
  </Flex>
)

export default PublicationCard
