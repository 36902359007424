import { Text, Link, AspectRatio, Image } from '@chakra-ui/react'
import { IFonts, IVideoSliderCollection } from '@interfaces'
import React from 'react'

interface IExhibitionVideoCardProps
  extends Omit<IVideoSliderCollection, 'youtubeEmbedUrl'> {
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  href: string
  activeFontFamily?: IFonts
}

const ExhibitionVideoCard = React.forwardRef<
  HTMLAnchorElement,
  IExhibitionVideoCardProps
>((props, ref) => {
  const {
    onClick,
    title,
    description,
    posterImgUrl,
    href,
    activeFontFamily = 'body',
  } = props

  return (
    <Link
      ref={ref}
      onClick={onClick}
      href={href}
      display="flex"
      flexDirection="column"
      width="300px"
      transition="transform 0.3s ease"
      _hover={{
        transform: 'translateY(-5px)',
      }}
    >
      <AspectRatio ratio={16 / 9} mb="5">
        <Image
          src={posterImgUrl}
          fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
          alt={title}
        />
      </AspectRatio>
      <Text mb="2.5" fontFamily={activeFontFamily}>
        {title}
      </Text>
      <Text fontSize="sm" color="grey.primary" fontFamily={activeFontFamily}>
        {description}
      </Text>
    </Link>
  )
})

ExhibitionVideoCard.displayName = 'ExhibitionVideoCard'

export default ExhibitionVideoCard
