import { createIcon } from '@chakra-ui/icons'

const ListViewColumn = createIcon({
  displayName: 'ListViewColumn',
  viewBox: '0 0 22 22',
  defaultProps: {
    width: '22px',
    height: '22px',
    fill: 'none',
  },
  path: (
    <>
      <rect width="10" height="10" fill="currentColor" />
      <rect y="12" width="10" height="10" fill="currentColor" />
      <rect x="12" width="10" height="10" fill="currentColor" />
      <rect x="12" y="12" width="10" height="10" fill="currentColor" />
    </>
  ),
})

export default ListViewColumn
