import {
  Box,
  IconButton,
  Flex,
  useBreakpointValue,
  Button,
  Text,
  Divider,
} from '@chakra-ui/react'
import { useStore } from '@store'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import { Cross, ChevronDown } from '@icon'
import { useState } from 'react'
import ART_SORT_FILTER_OPTIONS from '@lib/const/art-sort-filter-options.const'
import { Box as MotionBox } from '@motion'
import { AnimatePresence } from 'framer-motion'
import { fadeIn } from '@lib/motion'
// import { CollectionSearchSortButton } from '@common'
// import { ListViewColumn, ListViewRow } from '@icon'
// import ART_LIMIT_COUNT_PAGE_OPTIONS from '@lib/const/art-limit-count-page-options.const'
// import ART_LISTING_VIEW_OPTIONS from '@lib/const/art-listing-view-options.const'

const CollectionSearchSortBox = observer(() => {
  const [displaySortOption, setDisplaySortOption] = useState(false)
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const { t } = useTranslation()
  const {
    artSearch: {
      displaySortModal,
      onCloseModal,
      sortResult,
      setSortResult,
      // setLimitCountOfPage,
      // limitCountOfPage,
      // setListingView,
      // listingView,
    },
  } = useStore()

  if (!isDesktop && !displaySortModal) {
    return null
  }

  const getActiveSortLabel = () => {
    switch (sortResult) {
      case 'Relevance':
        return t('collections-and-research:search.collectionSort1')

      case 'title-asc':
        return t('collections-and-research:search.collectionSort2')

      case 'title-desc':
        return t('collections-and-research:search.collectionSort3')

      case 'displayDate-asc':
        return t('collections-and-research:search.collectionSort4')

      case 'displayDate-desc':
        return t('collections-and-research:search.collectionSort5')

      case 'primaryMakerAlpha-asc':
        return t('collections-and-research:search.collectionSort6')

      case 'primaryMakerAlpha-desc':
        return t('collections-and-research:search.collectionSort7')

      case 'invno-asc':
        return t('collections-and-research:search.collectionSort8')

      case 'invno-desc':
        return t('collections-and-research:search.collectionSort9')

      default:
        return t('collections-and-research:search.collectionSort1')
    }
  }

  const handleOnClickSort = (currentSort: typeof sortResult) => {
    setSortResult(currentSort)
    setDisplaySortOption(false)
  }

  return (
    <Box
      position={{ base: 'static', lg: 'absolute' }}
      top={{ base: 'auto', lg: '0' }}
      right={{ base: 'auto', lg: '0' }}
      width="full"
      maxWidth={{ base: 'full', lg: '345px' }}
      background="white.primary"
    >
      <Box
        height="65px"
        display="flex"
        alignItems="center"
        justifyContent={{ base: 'space-between', lg: 'flex-end' }}
        px="padding.primary"
      >
        <Button
          variant="unstyled"
          rightIcon={
            <ChevronDown
              fontSize="16px"
              transition="transform 0.3s ease"
              transform={displaySortOption ? 'rotate(180deg)' : 'rotate(0deg)'}
            />
          }
          onClick={() => setDisplaySortOption((oldState) => !oldState)}
          fontWeight="bold"
          fontSize="sm"
          color="black.primary3"
          minWidth={{ base: '16px', lg: '10' }}
          display="flex"
          alignItems="center"
        >
          <Text color="red.primary">
            {t('collections-and-research:search.orderButtonLabel')}
            {': \u00A0'}
          </Text>
          {getActiveSortLabel()}
        </Button>
        {!isDesktop && (
          <IconButton
            aria-label={t('collections-and-research:search.clossButtonLabel')}
            icon={<Cross />}
            onClick={onCloseModal}
            variant="unstyled"
          />
        )}
      </Box>
      <AnimatePresence initial={false}>
        {displaySortOption && (
          <MotionBox
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
              },
              collapsed: {
                opacity: 0,
                height: 0,
              },
            }}
            transition={{ easing: fadeIn.easing }}
          >
            <Flex
              flexDirection="column"
              pb="padding.primary"
              maxHeight="calc(100vh - 65px)"
              overflowY="auto"
              sx={{
                '::-webkit-scrollbar': {
                  width: '5px',
                },
                '::-webkit-scrollbar-track': {
                  background: 'white.primary3',
                },
                '::-webkit-scrollbar-thumb': {
                  background: 'red.primary',
                },
                '::-webkit-scrollbar-thumb:hover': {
                  background: 'red.primary3',
                },
              }}
            >
              <Flex flexDirection="column" px="padding.primary">
                {ART_SORT_FILTER_OPTIONS.map((option, index) => (
                  <Button
                    key={option.id}
                    onClick={() => handleOnClickSort(option.value)}
                    fontWeight="light"
                    color="black.primary3"
                    fontSize="sm"
                    variant="unstyled"
                    height="auto"
                  >
                    {index === 0 && (
                      <Divider borderBottomColor="white.primary3" opacity="1" />
                    )}
                    <Text py="4" textAlign="left">
                      {t(option.labelTx)}
                    </Text>
                    <Divider borderBottomColor="white.primary3" opacity="1" />
                  </Button>
                ))}
              </Flex>
            </Flex>
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  )
})

export default CollectionSearchSortBox
