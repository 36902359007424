export const transformArticleKeyNameOfType = (
  key: 'haber' | 'makale' | 'soylesi' | 'performans' | 'film ve video' | 'gezi'
) => {
  switch (key) {
    case 'haber':
      return 'new'

    case 'makale':
      return 'article'

    case 'soylesi':
      return 'interview'

    case 'performans':
      return 'performance'

    case 'film ve video':
      return 'film-and-video'

    case 'gezi':
      return 'trip'

    default:
      return 'article'
  }
}
