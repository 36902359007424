import { Box, Button, useDisclosure } from '@chakra-ui/react'
import {
  Hero,
  Layout,
  PublicationCollectionGrid,
  PublicationDetailModal,
} from '@common'
import { IPublicationCard, ISSRCollectionError, IGlobal } from '@interfaces'
import { getFilterPublications } from '@utils'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

interface ICollection {
  data?: IPublicationCard[]
  error?: ISSRCollectionError
}

interface IPublicationsTemplate extends IGlobal {
  collection: ICollection
}

export const PublicationsTemplate = ({
  collection,
  global: { navigation },
}: IPublicationsTemplate) => {
  const { t } = useTranslation()
  const { asPath, query } = useRouter()
  const [activePopUpId, setActivePopUpId] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    const slug = query?.slug || null

    if (slug) {
      setActivePopUpId(`/${slug}`)
      onOpen()
    }
  }, [query, onOpen])

  const getActiveCollection = () => {
    const publicationBySlug = collection.data.find(
      (collection) => collection?.slug === activePopUpId
    )

    return publicationBySlug
  }
  return (
    <Layout
      title={t('explore-art:publications.pageTitle')}
      navigation={navigation}
      path={asPath}
    >
      <Hero
        subTitle={t('explore-art:publications.banner.subTitle')}
        title={t('explore-art:publications.banner.title')}
        bgImageUrl="https://drupal.sakipsabancimuzesi.org/sites/default/files/2023-03/HAT_KITAP_MOCKUP_BANNER.jpg"
      >
        <Box d="none" position="absolute" bottom="0" right="0">
          <Button width={{ base: 'full', lg: '240px' }} height="100px">
            {t('common:buttonExamineLabel')}
          </Button>
        </Box>
      </Hero>
      <Box as="main">
        {!collection.error && (
          <>
            <PublicationCollectionGrid
              collection={getFilterPublications(
                collection.data,
                'Sergi Katalogları'
              )}
              sectionTitle={t(
                'explore-art:publications.exhibitionCatalogsSectionTitle'
              )}
              containerProps={{
                pb: '0',
              }}
            />
            <PublicationCollectionGrid
              collection={getFilterPublications(
                collection.data,
                'Bilimsel Yayınlar'
              )}
              sectionTitle={t(
                'explore-art:publications.scientificPublicationsSectionTitle'
              )}
            />
            <PublicationCollectionGrid
              collection={getFilterPublications(
                collection.data,
                'Çocuk Yayınları'
              )}
              sectionTitle={t(
                'explore-art:publications.kidCatalogsSectionTitle'
              )}
            />
            {activePopUpId && (
              <PublicationDetailModal
                isOpen={isOpen}
                onClose={onClose}
                pdfLink={getActiveCollection()?.pdfLink}
                epubLink={getActiveCollection()?.epubLink}
                videoUrl={getActiveCollection()?.videoUrl}
                listeningUrl={getActiveCollection()?.listeningUrl}
                pageCount={getActiveCollection()?.pageCount}
                isbn={getActiveCollection()?.isbn}
                place={getActiveCollection()?.place}
                author={getActiveCollection()?.author}
                description={getActiveCollection()?.description}
                title={getActiveCollection()?.title}
                imgUrl={getActiveCollection()?.imgUrl}
                type={getActiveCollection()?.type}
                activeFontFamily={'body'}
              />
            )}
          </>
        )}
      </Box>
    </Layout>
  )
}
