import { IWorkshopCard } from '@interfaces'

export const getActiveColorsForWorkshopCard = (
  index: number
): IWorkshopCard['colorSchema'] => {
  const colors = {
    yellow: 'yellow.primary2',
    blue: 'blue.primary2',
    red: 'red.primary4',
    blueSecondary: 'blue.primary4',
    pink: 'pink.primary2',
    black: 'black.primary',
    white: 'white.primary',
  }

  const activeIndex = index % 5

  const colorSchema = {
    bgColor: colors.yellow,
    titleColor: colors.blueSecondary,
    buttonBgColor: colors.blueSecondary,
    buttonTextColor: colors.yellow,
    descriptionTextColor: colors.black,
  }

  switch (activeIndex) {
    case 1:
      colorSchema.bgColor = colors.blue
      colorSchema.titleColor = colors.yellow
      colorSchema.buttonBgColor = colors.yellow
      colorSchema.buttonTextColor = colors.white
      colorSchema.descriptionTextColor = colors.white
      break

    case 2:
      colorSchema.bgColor = colors.red
      colorSchema.titleColor = colors.yellow
      colorSchema.buttonBgColor = colors.pink
      colorSchema.buttonTextColor = colors.white
      colorSchema.descriptionTextColor = colors.white
      break

    case 3:
      colorSchema.bgColor = colors.pink
      colorSchema.titleColor = colors.blueSecondary
      colorSchema.buttonBgColor = colors.blue
      colorSchema.buttonTextColor = colors.white
      colorSchema.descriptionTextColor = colors.black
      break

    case 4:
      colorSchema.bgColor = colors.blueSecondary
      colorSchema.titleColor = colors.red
      colorSchema.buttonBgColor = colors.red
      colorSchema.buttonTextColor = colors.white
      colorSchema.descriptionTextColor = colors.black
      break

    default:
      break
  }

  return colorSchema
}
