import {
  Divider,
  Flex,
  Image,
  ListItem,
  UnorderedList,
  Button,
} from '@chakra-ui/react'
import { PressReleaseCollectionGrid, Layout, Hero } from '@common'
import { Link } from '@ui'
import Trans from 'next-translate/Trans'
import { Text, Box, Container } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { IPressReleaseCard, ISSRCollectionError, IGlobal } from '@interfaces'
import { DownloadIcon } from '@chakra-ui/icons'

interface ICollection {
  data?: IPressReleaseCard[]
  error?: ISSRCollectionError
}

interface IPressTemplate extends IGlobal {
  collection: ICollection
}

export const PressTemplate = ({
  collection,
  global: { navigation },
}: IPressTemplate) => {
  const { t } = useTranslation()
  const { asPath } = useRouter()

  return (
    <Layout
      title={t('legal:press.pageTitle')}
      navigation={navigation}
      path={asPath}
    >
      <Hero
        subTitle={t('legal:press.subTitle')}
        title={t('legal:press.title')}
        description={t('legal:press.description')}
        bgImageUrl={
          'https://drupal.sakipsabancimuzesi.org/sites/muze.sabanciuniv.edu/files/2021-12/banner_new_basin_1_0.jpg'
        }
      />
      {!collection.error && (
        <PressReleaseCollectionGrid
          sectionTitle={t('legal:press.pressRelease')}
          collection={collection}
        />
      )}
      <Box as="section">
        <Container mb={{ base: 'padding.primary', lg: 'padding.primary2' }}>
          <Text
            as="h2"
            color="red.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
          >
            {t('legal:press.pressListHeader')}
          </Text>
        </Container>
        <Box
          maxWidth="container.secondary"
          mx="auto"
          px={{ base: 'padding.primary', lg: '0' }}
        >
          <Box
            as="main"
            pt={4}
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
          >
            <Text fontWeight="light" mb="{8}">
              {t('legal:press.pressListText1')}
            </Text>
            <Trans
              i18nKey="legal:press.pressListText2"
              components={[
                <Text key="kvkkTextComponent" fontWeight="light" />,
                <Link
                  fontWeight="medium"
                  key="kvkkLinkComponent"
                  href="mailto:pr.ssm@sabanciuniv.edu"
                  target="_blank"
                />,
              ]}
            />
          </Box>
        </Box>
        <Container mb={{ base: 'padding.primary', lg: 'padding.primary2' }}>
          <Text
            as="h2"
            color="red.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
          >
            {t('legal:press.corporateguideheader')}
          </Text>
        </Container>
        <Box
          maxWidth="container.secondary"
          mx="auto"
          px={{ base: 'padding.primary', lg: '0' }}
          mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
        >
          <Text mb={{ base: 'padding.primary', lg: '10' }} fontWeight="bold">
            {t('legal:press.corporateguidetext01')}
          </Text>
          <UnorderedList fontWeight="light" ml="10">
            <ListItem>{t('legal:press.corporateguidetext02')}</ListItem>
            <ListItem>{t('legal:press.corporateguidetext03')}</ListItem>
            <ListItem>{t('legal:press.corporateguidetext04')}</ListItem>
            <ListItem>{t('legal:press.corporateguidetext05')}</ListItem>
            <ListItem>{t('legal:press.corporateguidetext06')}</ListItem>
            <ListItem>{t('legal:press.corporateguidetext07')}</ListItem>
            <ListItem>{t('legal:press.corporateguidetext08')}</ListItem>
            <ListItem>{t('legal:press.corporateguidetext09')}</ListItem>
          </UnorderedList>
          <Text
            fontWeight="bold"
            mb={{ base: 'padding.primary', lg: '10' }}
            mt={{ base: 'padding.primary', lg: '10' }}
          >
            {t('legal:press.corporateguidetext10')}
          </Text>
          <Flex
            mt="{20}"
            flexDir="row"
            w="full"
            maxW={{ base: 'full', lg: 'full' }}
          >
            <Box flex="10" pr="10">
              <Text fontWeight="bold" mb="6">
                {t('legal:press.corporateguidetext11')}
              </Text>
              <Text fontWeight="light">
                {t('legal:press.corporateguidetext12')}
              </Text>
            </Box>
            <Box flex="4">
              <Image
                src="https://drupal.sakipsabancimuzesi.org/sites/muze.sabanciuniv.edu/files/2021-12/SSM_Beyaz%20ve%20Ac%CC%A7%C4%B1k%20tonlu%20zeminlerde%20kullan%C4%B1m_thumb.png"
                alt="Beyaz ve Açık tonlu zeminlerde kullanımı"
              />
              <Button
                as={Link}
                href={
                  'https://drupal.sakipsabancimuzesi.org/sites/muze.sabanciuniv.edu/files/2021-12/Beyaz%20ve%20Ac%CC%A7%C4%B1k%20tonlu%20zeminlerde%20kullan%C4%B1m.zip'
                }
                width="full"
                height="50px"
                variant="secondary"
                leftIcon={<DownloadIcon w={6} h={6} />}
                mr={4}
                mb={4}
                mt={8}
              >
                {t('common:download')}
              </Button>
            </Box>
          </Flex>
          <Divider
            borderBottomColor="white.primary3"
            opacity="1"
            my="padding.primary"
          />
          <Flex
            mt="{20}"
            flexDir="row"
            w="full"
            maxW={{ base: 'full', lg: 'full' }}
          >
            <Box flex="10" pr="10">
              <Text fontWeight="bold" mb="6">
                {t('legal:press.corporateguidetext13')}
              </Text>
              <Text fontWeight="light">
                {t('legal:press.corporateguidetext14')}
              </Text>
            </Box>
            <Box flex="4">
              <Image
                src="https://drupal.sakipsabancimuzesi.org/sites/muze.sabanciuniv.edu/files/2021-12/SSM_Pantone%20Reflex%20Blue%20ve%20orta%20ve%20koyu%20tonlu%20zeminlerde%20kullan%C4%B1m_thumb.png"
                alt="Pantone Reflex Blue ve orta ve koyu tonlu zeminlerde kullanım"
              />
              <Button
                as={Link}
                href={
                  'https://drupal.sakipsabancimuzesi.org/sites/muze.sabanciuniv.edu/files/2021-12/Pantone%20Reflex%20Blue%20ve%20orta%20ve%20koyu%20tonlu%20zeminlerde%20kullan%C4%B1m.zip'
                }
                width="full"
                height="50px"
                variant="secondary"
                leftIcon={<DownloadIcon w={6} h={6} />}
                mr={4}
                mb={4}
                mt={8}
              >
                {t('common:download')}
              </Button>
            </Box>
          </Flex>
          <Divider
            borderBottomColor="white.primary3"
            opacity="1"
            my="padding.primary"
          />
          <Flex
            mt="{20}"
            flexDir="row"
            w="full"
            maxW={{ base: 'full', lg: 'full' }}
          >
            <Box flex="10" pr="10">
              <Text fontWeight="bold" mb="6">
                {t('legal:press.corporateguidetext15')}
              </Text>
              <Text fontWeight="light">
                {t('legal:press.corporateguidetext16')}
              </Text>
            </Box>
            <Box flex="4">
              <Image
                src="https://drupal.sakipsabancimuzesi.org/sites/muze.sabanciuniv.edu/files/2021-12/SSM_Siyah%20ve%20k%C4%B1rm%C4%B1z%C4%B1%20tonlu%20zeminlerde_thumb.png"
                alt="Siyah ve kırmızı tonlu zeminlerde"
              />
              <Button
                as={Link}
                href={
                  'https://drupal.sakipsabancimuzesi.org/sites/muze.sabanciuniv.edu/files/2021-12/Siyah%20ve%20k%C4%B1rm%C4%B1z%C4%B1%20tonlu%20zeminlerde%20kullan%C4%B1m.zip'
                }
                width="full"
                height="50px"
                variant="secondary"
                leftIcon={<DownloadIcon w={6} h={6} />}
                mr={4}
                mb={4}
                mt={8}
              >
                {t('common:download')}
              </Button>
            </Box>
          </Flex>
          <Divider
            borderBottomColor="white.primary3"
            opacity="1"
            my="padding.primary"
          />
          <Box as="main" pt={4}>
            <Trans
              i18nKey="legal:press.corporateguidetext"
              components={[
                <Text key="kvkkTextComponent" fontWeight="light" />,
                <Link
                  fontWeight="medium"
                  key="kvkkLinkComponent1"
                  href="mailto:pr.ssm@sabanciuniv.edu"
                  target="_blank"
                />,
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}
