import { ToursHttpService } from '@httpServices'
import { createNavLink, prepareSectionData, prepareVideoUrl } from '@utils'
import {
  ITwoColumnBlock,
  ITourTabsDetailDto,
  IAccordionCollection,
  IPhotoAndVideoSliderSectionCollection,
} from '@interfaces'

export const ToursService = {
  /**
   * Get tour categories
   */
  getTourCategories: async (locale: string | 'en' | 'tr') => {
    const tourCategories = await ToursHttpService.getTourCategories(locale)

    return tourCategories
      .sort(
        (a, b) => a.field_display_rank[0].value - b.field_display_rank[0].value
      )
      .map((category) => ({
        id: category?.nid[0].value || '',
        type: 'exhibition',
        title: category?.title[0]?.value || '',
        description: category?.body[0]?.summary || '',
        imgUrl: category?.field_tour_category_listing_imag[0]?.url || '',
        url: `${createNavLink('groupsAndTours', locale)}/${
          category?.field_slug_for_service_[0]?.value || ''
        }`,
        slug:
          (category?.field_slug_for_service_ &&
            category?.field_slug_for_service_[0]?.value) ||
          '',
      }))
  },
  /**
   * Get tour category detail by slug
   */
  getTourDetailBySlug: async (locale: string, slug: string) => {
    const getTourCategoryBySlugData = await ToursHttpService.getTourCategory(
      locale,
      slug
    )

    if (!getTourCategoryBySlugData || getTourCategoryBySlugData.length === 0) {
      return null
    }

    const tourCategory = getTourCategoryBySlugData[0]

    const tourDetailData = {
      pageTitle: null,
      hero: null,
      tickets: null,
      faq: null,
      photoAndVideos: null,
      stickyNavItems: null,
      data: null,
      tabsOfTour: null,
      sections: null,
    }

    tourDetailData.data = getTourCategoryBySlugData

    const idOfTourCategory = tourCategory.nid[0]?.value

    // page title
    tourDetailData.pageTitle = tourCategory.title[0]?.value || ''

    // prepare hero
    const hero = {
      bgImageUrl: '',
      subTitle: '',
      title: '',
      description: '',
    }

    if (tourCategory) {
      hero.bgImageUrl = tourCategory.field_page_banner_image[0]?.url || ''
      hero.subTitle = tourCategory.field_page_banner_subheader[0]?.value || ''
      hero.title = tourCategory.field_page_banner_header[0]?.value || ''
      hero.description = tourCategory.body[0]?.summary || ''
    }

    tourDetailData.hero = hero

    // preparing dynamic tabs
    const tabIds = tourCategory.field_tour_tab.map((t) => t.target_id)

    const initTabsOfTour: ITourTabsDetailDto[] = await Promise.all(
      tabIds.map(async (id) => {
        const tourCategory = await ToursService.getTabsOfTourCategory(
          locale,
          id
        )
        return tourCategory
      })
    )

    const tabsOfTour = initTabsOfTour.map((tab) => ({
      id: tab[0]?.nid[0]?.value || '',
      navTitleTx: tab[0]?.title[0]?.value || '',
      href: tab[0]?.field_tour_tab_id[0]?.value || '',
      titleIsWithLocalizationOnClient: false,
      rank: tab[0]?.field_tour_tab_rank[0]?.value || 0,
      contentType: tab[0]?.field_tab_content_type[0]?.value || 'html',
      __component: `sections.${
        tab[0]?.field_tab_content_type[0]?.value || 'html'
      }`,
    }))

    tourDetailData.stickyNavItems = tabsOfTour.sort((a, b) => a.rank - b.rank)

    // prepare get dynamic tabs content
    const sectionsData = await Promise.all(
      tourDetailData.stickyNavItems.map(async (tab) => {
        let sectionData = null

        // tickets
        if (tab.__component === 'sections.block') {
          sectionData = await ToursService.getTicketsOfTourCategory(
            locale,
            idOfTourCategory
          )
        }

        // faq
        if (tab.__component === 'sections.accordion') {
          const tabContent = initTabsOfTour.find(
            (tab) => tab[0]?.field_tab_content_type[0]?.value === 'accordion'
          )

          if (tabContent) {
            // get faq ids
            const faqIds = tabContent[0].field_tour_faq.map(
              (faq) => faq.target_id
            )

            const faqsOfTour = await Promise.all(
              faqIds.map(async (faqId) => {
                const faq = await ToursService.getFaqOfTourCategory(
                  locale,
                  faqId
                )
                return faq[0]
              })
            )

            // get faq detail
            sectionData = faqsOfTour
          }
        }

        // photo and videos
        if (tab.__component === 'sections.gallery') {
          const tabContent = initTabsOfTour.find(
            (tab) => tab[0]?.field_tab_content_type[0]?.value === 'gallery'
          )

          if (tabContent) {
            const photoAndVideos = []

            // images
            tabContent[0].field_tour_tab_image_collection.map((img) => {
              photoAndVideos.push({
                id: img.target_id,
                url: img.url,
                alt: img.alt,
                isVideo: false,
              })
            })

            // videos
            const videoIds = tabContent[0].field_tour_tab_video_collection.map(
              (vid) => vid.target_id
            )

            await Promise.all(
              videoIds.map(async (videoId) => {
                const video =
                  await ToursService.getVideoOfTabContentOfTourCategory(
                    locale,
                    videoId
                  )

                if (video) {
                  photoAndVideos.push(video)
                }
              })
            )

            sectionData = photoAndVideos
          }
        }

        return prepareSectionData(
          tab.__component,
          sectionData,
          tab.navTitleTx,
          tab.href,
          tab.id,
          'groupsAndTours'
        )
      })
    )

    tourDetailData.sections = sectionsData

    return tourDetailData
  },
  /**
   * Get tickets of tour category drupalHttp service
   */
  getTicketsOfTourCategory: async (
    locale: string,
    id: string
  ): Promise<ITwoColumnBlock[]> => {
    const tickets = await ToursHttpService.getTicketsOfTourCategory(locale, id)

    return tickets.map((ticket) => ({
      id: ticket.nid[0]?.value || '',
      leftColTitleRow: [
        {
          leftColTitle: ticket.field_tour_ticket_text[0]?.value || '',
          leftColRightSubTitle: [
            ticket.field_tour_price[0]?.value || '',
            ticket.field_tour_duration[0]?.value || '',
          ],
        },
      ],
      leftColButtonLabel: locale === 'tr' ? 'Satın Al' : 'Buy a TicketIcon',
      leftColButtonUrl: ticket.field_tour_ticket_url[0]?.value || '',
      leftColisExternelUrl: true,
      rightColHtmlContent: ticket.body[0]?.value || '',
      isAlignItemsCenter: false,
    }))
  },
  /**
   * Get faqs of tour category drupalHttp service
   */
  getFaqOfTourCategory: async (
    locale: string,
    id: string
  ): Promise<IAccordionCollection[]> => {
    const faqsOfTourCategory = await ToursHttpService.getFaqOfTourCategory(
      locale,
      id
    )

    const faqs = faqsOfTourCategory.map((faq) => ({
      id: faq.nid[0]?.value || '',
      accordionTitle: faq.field_question[0]?.value || '',
      accordionHtmlContent: faq.field_answer[0]?.value || '',
    }))

    return faqs
  },
  /**
   * Get tabs of tour category drupalHttp service
   */
  getTabsOfTourCategory: async (locale: string, id: string) => {
    const tabsOfTourCategory = await ToursHttpService.getTabsOfTourCategory(
      locale,
      id
    )

    return tabsOfTourCategory
  },
  /**
   * Get video of tab content of tour category drupalHttp service
   */
  getVideoOfTabContentOfTourCategory: async (
    locale: string,
    id: string
  ): Promise<IPhotoAndVideoSliderSectionCollection> => {
    const video = await ToursHttpService.getVideoOfTabContentOfTourCategory(
      locale,
      id
    )

    if (video.length === 0) {
      return
    }

    return {
      id: video[0].vid[0]?.value || '',
      url:
        (video[0]?.field_video_url &&
          video[0]?.field_tour_video_provider &&
          prepareVideoUrl(
            video[0]?.field_video_url[0]?.value,
            video[0]?.field_tour_video_provider[0]?.value || 'youtube'
          )) ||
        '',
      alt: video[0].title[0]?.value || '',
      isVideo: true,
      videoProvider: video[0].field_tour_video_provider[0]?.value || 'youtube',
    }
  },
  getAllTourCategorySlugsByLocale: async (locale: string | 'en' | 'tr') => {
    const tours = await ToursService.getTourCategories(locale)

    return tours.map((tour) => tour.slug)
  },
}
