import { createIcon } from '@chakra-ui/icons'

const ZoomIn = createIcon({
  displayName: 'ZoomIn',
  defaultProps: {
    width: 22,
    height: 22,
    fill: 'none',
  },
  viewBox: '0 0 22 22',
  path: (
    <>
      <path d="M7 10.75H14.5" stroke="currentColor" />
      <path d="M10.75 7L10.75 14.5" stroke="currentColor" />
      <circle cx="11" cy="11" r="10.5" stroke="currentColor" />
    </>
  ),
})

export default ZoomIn
