/* eslint-disable @typescript-eslint/no-explicit-any */
import { Section } from '../'
import { Fragment } from 'react'
import { ISectionData, IFonts } from '@interfaces'
import { Divider } from '@chakra-ui/react'

interface ISections {
  sections: ISectionData[]
  activeFontFamily?: IFonts
  withDivider?: boolean
}

const Sections = ({
  sections,
  activeFontFamily = 'body',
  withDivider = false,
}: ISections) => {
  // Show the actual sections
  return (
    <Fragment>
      {sections &&
        sections.length > 0 &&
        sections.map((section, i) => (
          <Fragment key={`${section.__component}${section.id}`}>
            <Section
              sectionData={section}
              activeFontFamily={activeFontFamily}
              withDivider={withDivider}
              isLastItem={i + 1 === sections.length}
            />
            {withDivider && i + 1 < sections.length && (
              <Divider
                my={{ base: '20px', lg: '20px' }}
                borderColor="white.primary3"
              />
            )}
          </Fragment>
        ))}
    </Fragment>
  )
}

export default Sections
