import { createIcon } from "@chakra-ui/icons";

const Search = createIcon({
  displayName: "Search",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "none",
    width: "17px",
    height: "17px",
    marginRight: "3px"
  },
  path: (
    <>
      <path
        d="M10 0.5C4.75329 0.5 0.5 4.75329 0.5 10C0.5 15.2467 4.75329 19.5 10 19.5C12.082 19.5 14.0076 18.8302 15.5731 17.6944L20.2929 22.4142C20.6834 22.8047 21.3166 22.8047 21.7071 22.4142L22.4142 21.7071C22.8047 21.3166 22.8047 20.6834 22.4142 20.2929L17.6944 15.5731C18.8302 14.0076 19.5 12.082 19.5 10C19.5 4.75329 15.2467 0.5 10 0.5ZM3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10Z"
        fill="#fff" />

    </>
  )
});

export default Search;


