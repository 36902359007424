import { Box, BoxProps, Container, Flex, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Link } from '@ui'

interface IInformationContactSection {
  tel: string
  fax: string
  email: string
  containerProps?: BoxProps
  stickyId: string
  sectionTitle: string
}

const InformationContactSection = ({
  tel,
  fax,
  email,
  containerProps,
  stickyId = 'InformationContactSection',
  sectionTitle,
}: IInformationContactSection) => {
  const { t } = useTranslation()

  return (
    <Box id={stickyId} as="section" {...containerProps}>
      <Container>
        {sectionTitle && (
          <Text
            as="h2"
            color="red.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
          >
            {sectionTitle}
          </Text>
        )}

        <Flex
          flexDirection="column"
          w="full"
          maxW="container.secondary"
          mx="auto"
        >
          {tel && (
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              sx={{
                columnGap: '10px',
              }}
            >
              <Box
                w={{ base: 'auto', lg: 'full' }}
                maxW={{ base: 'full', lg: '60' }}
              >
                <Text
                  as="h6"
                  minWidth={{ base: '100px', lg: 'auto' }}
                  color="black.primary3"
                  fontSize="lg"
                >
                  {t('common:contact.tel')}
                </Text>
              </Box>

              <Box
                flex={{ base: 'auto', lg: '1' }}
                pl={{ base: '0', lg: 20 }}
                textStyle="richText"
              >
                <Link href={`tel:${tel}`} target="_blank">
                  <Text fontWeight="light" fontSize="sm">
                    {tel}
                  </Text>
                </Link>
              </Box>
            </Flex>
          )}

          {fax && (
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              sx={{
                columnGap: '10px',
              }}
            >
              <Box
                w={{ base: 'auto', lg: 'full' }}
                maxW={{ base: 'full', lg: '60' }}
              >
                <Text
                  as="h6"
                  minWidth={{ base: '100px', lg: 'auto' }}
                  color="black.primary3"
                  fontSize="lg"
                >
                  {t('common:contact.fax')}
                </Text>
              </Box>

              <Box
                flex={{ base: 'auto', lg: '1' }}
                pl={{ base: '0', lg: 20 }}
                textStyle="richText"
              >
                <Link href={`fax:${fax}`} target="_blank">
                  <Text fontWeight="light" fontSize="sm">
                    {fax}
                  </Text>
                </Link>
              </Box>
            </Flex>
          )}

          {email && (
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              sx={{
                columnGap: '10px',
              }}
            >
              <Box
                w={{ base: 'auto', lg: 'full' }}
                maxW={{ base: 'full', lg: '60' }}
              >
                <Text
                  as="h6"
                  minWidth={{ base: '100px', lg: 'auto' }}
                  color="black.primary3"
                  fontSize="lg"
                >
                  {t('common:contact.email')}
                </Text>
              </Box>

              <Box
                flex={{ base: 'auto', lg: '1' }}
                pl={{ base: '0', lg: 20 }}
                textStyle="richText"
              >
                <Link href={`mailto:${email}`} target="_blank">
                  <Text fontWeight="light" fontSize="sm">
                    {email}
                  </Text>
                </Link>
              </Box>
            </Flex>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default InformationContactSection
