/* eslint-disable react/display-name */
import { Checkbox as ChakraCheckbox, CheckboxProps } from '@chakra-ui/react'
import { Checked } from '@icon'
import { forwardRef } from 'react'

interface ICheckbox extends CheckboxProps {
  children: JSX.Element | JSX.Element[] | string | string[]
}

type Ref = HTMLInputElement

const Checkbox = forwardRef<Ref, ICheckbox>((props, ref) => {
  const { children, ...rest } = props

  return (
    <ChakraCheckbox
      ref={ref}
      iconSize="20px"
      icon={<Checked width="19px" height="19px" />}
      transition="all 0.3s ease"
      sx={{
        '.chakra-checkbox__control[data-focus]': {
          boxShadow: 'none',
        },
        '.chakra-checkbox__control[data-checked], .chakra-checkbox__control[data-checked]:hover':
          {
            background: 'transparent',
            borderColor: 'black.primary3',
            color: 'red.primary',
          },
      }}
      _focus={{
        boxShadow: 'none',
      }}
      {...rest}
    >
      {children}
    </ChakraCheckbox>
  )
})

export default Checkbox
