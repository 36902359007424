/**
 * convert data from "Marina Abramović Institute[;]543[;]68663"
 * to "Marina Abramović"
 */
const getArtistName = (artist: string) => {
  return artist.split('[;]')[0]
}

const getArtistSourceId = (artist: string) => {
  return artist.split('[;]')[1]
}

const prepareArtist = (dirtyData) => {
  const fullName = getArtistName(dirtyData)
  const sourceId = getArtistSourceId(dirtyData)

  return {
    fullName,
    sourceId,
  }
}

export const getArtistsDataForExhibitionDetailPage = (
  artists: string | string[]
) => {
  if (artists instanceof Array) {
    return artists.map((art) => prepareArtist(art))
  } else {
    return [prepareArtist(artists)]
  }
}
