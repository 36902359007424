import { Flex, Text, Box, Image, Button } from '@chakra-ui/react'
import { IDaysAndTicketsCard } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import { Markup } from 'interweave'
import { Link } from '@ui'

const DaysAndTicketsCard = ({
  type,
  price,
  productName,
  productDetails,
  productPhoto,
  purchaseLink,
  stock,
}: IDaysAndTicketsCard) => {
  const { t } = useTranslation()

  const getCardTypeLabelTx = () => {
    switch (type) {
      case 'event':
        return 'exhibition-and-event:eventAndExhibitionDetail.eventTypes.event'

      case 'exhibition':
        return 'exhibition-and-event:eventAndExhibitionDetail.eventTypes.exhibition'

      case 'learning':
        return 'exhibition-and-event:eventAndExhibitionDetail.eventTypes.learning'

      case 'moviesAndVideos':
        return 'exhibition-and-event:eventAndExhibitionDetail.eventTypes.movieAndVideo'

      case 'online':
        return 'exhibition-and-event:eventAndExhibitionDetail.eventTypes.online'

      case 'tours':
        return 'exhibition-and-event:eventAndExhibitionDetail.eventTypes.tour'

      case 'workshop':
        return 'exhibition-and-event:eventAndExhibitionDetail.eventTypes.workshop'

      case 'childrensWorkshop':
        return 'exhibition-and-event:eventAndExhibitionDetail.eventTypes.childrensWorkshop'

      default:
        return 'exhibition-and-event:eventAndExhibitionDetail.eventTypes.event'
    }
  }

  const getButtonLabelTx = () =>
    type === 'event' && price && price > 0
      ? 'common:buyButtonLabel'
      : 'common:registerButtonLabel'

  return (
    <Flex
      flexDirection={{ base: 'column', lg: 'row' }}
      maxW="container.secondary"
      mx="auto"
      width="full"
    >
      <Flex flexDir="column" w="full" maxW={{ base: 'full', lg: '60' }}>
        <Flex
          flexDir="row"
          justifyContent="space-between"
          alignItems="baseline"
          mb="5"
        >
          <Text fontSize="lg" wordBreak="break-word">
            {t(getCardTypeLabelTx())}
          </Text>
          <Text fontWeight="light" fontSize="sm">
            {price === 0 ? t('common:free') : `${price} ₺`}
          </Text>
        </Flex>
        {productPhoto && (
          <Image
            src={productPhoto}
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
            alt={productName}
            mb="2.5"
          />
        )}
        {purchaseLink && (
          <Box cursor={'pointer'}>
            <Button
              as={Link}
              variant="secondary"
              href={purchaseLink}
              target="_blank"
              disabled={false}
              pointerEvents={'auto'}
              width="full"
            >
              {`${t(getButtonLabelTx())}${
                !stock ? ' (' + t('common:soldOut') + ')' : ''
              }`}
            </Button>
          </Box>
        )}
      </Flex>
      <Box
        flex={{ base: 'auto', lg: '1' }}
        mt={{ base: 'padding.primary', lg: '0' }}
        pl={{ base: '0', lg: 20 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Text fontSize="lg" mb="5">
          {productName}
        </Text>

        <Box fontWeight="light" fontSize="sm">
          <Markup content={productDetails} />
        </Box>
      </Box>
    </Flex>
  )
}

export default DaysAndTicketsCard
