import { Flex, Button, Box, Container, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IFullScreenSliderSection } from '@interfaces'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import { HeroAspectRatio, MotionBox, MotionText } from '@ui'
import { fadeIn } from '@lib/motion'
import { useInViewMotion } from '@lib/hooks'
import { keyframes } from '@emotion/react'
import { Markup } from 'interweave'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

const FullScreenSliderSection = ({
  subTitle,
  title,
  description,
  sliderCollection,
  stickyId = 'FullScreenSliderSection',
  containerProps,
  viewAllButtonLabelTx,
  viewAllButtonLabelOnClick,
  isHeroSection = false,
  isCustomTextContent = false,
  customHTMLContent,
}: IFullScreenSliderSection) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { controls, ref } = useInViewMotion(true)
  const [activeSliderIndex, setActiveSliderIndex] = useState(0)

  useEffect(() => {
    const fullScreenSliderSectionBg = `${stickyId}Bg`
    const fullScreenSliderSectionBgNode = document.getElementById(
      fullScreenSliderSectionBg
    )

    fullScreenSliderSectionBgNode.classList.add('animate__fadeIn')

    const removeFadingClass = () =>
      fullScreenSliderSectionBgNode.classList.remove('animate__fadeIn')

    fullScreenSliderSectionBgNode.addEventListener(
      'animationend',
      removeFadingClass,
      false
    )
  }, [activeSliderIndex, stickyId])

  const handleArtDetail = (path: string) => router.push(path)

  return (
    <HeroAspectRatio
      id={stickyId}
      as="section"
      minHeight={{ base: 'auto', lg: 'calc(100vh - 110px)' }}
      sx={{
        _before: {
          pb: { base: '0px', lg: 'auto' },
        },
        '&>*:not(style)': {
          position: { base: 'static', lg: 'absolute' },
        },
      }}
    >
      <Box
        width="full"
        height="full"
        overflow="hidden"
        position="relative"
        pt={isHeroSection ? '70px' : 'padding.primary2'}
        zIndex="1"
        {...containerProps}
      >
        <Box
          id={`${stickyId}Bg`}
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundImage={`linear-gradient(100.96deg, rgba(13, 16, 29, 0.9) 0%, rgba(13, 16, 29, 0.2) 50%), url(${
            sliderCollection[activeSliderIndex]?.imgUrl ||
            '/img/home-page/discover-art/discover-SSM-art.jpeg'
          })`}
          zIndex={-1}
          sx={{
            '&.animate__fadeIn': {
              animation: `${bgFadeIn} 0.4s  ease-in-out forwards`,
            },
          }}
        />
        <MotionBox
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={fadeIn.animationContainer}
        >
          <Container
            height="full"
            display="flex"
            flexDirection={{ base: 'column', lg: 'row' }}
          >
            <Box maxW="610px">
              {isCustomTextContent ? (
                <Box
                  sx={{
                    '& h3': {
                      fontSize: { base: '2xl', lg: '3xl' },
                      color: 'red.primary',
                      mb: { base: 1, lg: '0' },
                    },
                    '& h2': {
                      fontSize: { base: '3xl', lg: '5xl' },
                      color: 'whiteAlpha.primary5',
                      fontWeight: '200',
                      fontStyle: 'italic',
                      lineHeight: 'normal',
                      mb: { base: 1, lg: '0' },
                    },
                    '& p': {
                      fontSize: 'md',
                      fontWeight: 'light',
                      color: 'white.primary',
                    },
                  }}
                >
                  <Markup content={customHTMLContent} />
                </Box>
              ) : (
                <>
                  <MotionText
                    fontSize={{ base: '2xl', lg: '3xl' }}
                    color="red.primary"
                    as="h3"
                    mb={{ base: 1, lg: '0' }}
                    variants={fadeIn.up}
                  >
                    {subTitle}
                  </MotionText>
                  <MotionText
                    fontSize={{ base: '3xl', lg: '5xl' }}
                    color="whiteAlpha.primary5"
                    fontWeight="200"
                    fontStyle="italic"
                    lineHeight="normal"
                    as="h2"
                    mb={{ base: 1, lg: '6px' }}
                    variants={fadeIn.up}
                  >
                    {title}
                  </MotionText>
                  <MotionText
                    fontSize="md"
                    fontWeight="light"
                    color="white.primary"
                    variants={fadeIn.up}
                  >
                    {description}
                  </MotionText>
                </>
              )}
            </Box>
            <Flex
              flex={1}
              alignItems="flex-end"
              flexDirection={{ base: 'column-reverse', lg: 'row' }}
              position="relative"
              width="full"
              mt={{ base: 'padding.primary', lg: '0' }}
              sx={{
                width: 'calc(100% + 60px)',
                ml: '-30px',
                '@media (min-width: 1170px)': {
                  position: 'absolute',
                  right: '-40px',
                  bottom: 0,
                  width: '790px',
                },
              }}
            >
              <Box
                width={{ base: 'full', lg: 'auto' }}
                display="inline-block"
                opacity={
                  viewAllButtonLabelTx && viewAllButtonLabelOnClick ? 1 : 0
                }
                visibility={
                  viewAllButtonLabelTx && viewAllButtonLabelOnClick
                    ? 'visible'
                    : 'hidden'
                }
              >
                <Button
                  width={{ base: 'full', lg: '240px' }}
                  height={{ base: 20, lg: '100px' }}
                  onClick={viewAllButtonLabelOnClick}
                >
                  {viewAllButtonLabelTx}
                </Button>
              </Box>

              <Box
                width="full"
                className="DiscoverSSMArtSwiper"
                sx={{
                  '&.DiscoverSSMArtSwiper .swiper-container': {
                    width: 'full',
                    maxW: { base: 'full', lg: '520px' },
                    height: 'full',
                    pl: { base: 'padding.primary', lg: '0' },
                    ml: { base: 'auto', lg: '0' },
                  },
                  '&.DiscoverSSMArtSwiper .swiper-slide': {
                    width: { base: '295px', lg: 'drawer.lg' },
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: 'white.primary',
                  },
                  '.discover-art-swiper-button-next, .discover-art-swiper-button-prev':
                    {
                      width: '16px',
                      height: 'auto',
                      color: 'red.primary2',
                    },
                  '.discover-art-swiper-button-next:after, .discover-art-swiper-button-prev:after':
                    {
                      fontSize: 'md',
                    },
                  '.discover-art-swiper-button-next.swiper-button-disabled, .discover-art-swiper-button-prev.swiper-button-disabled':
                    {
                      opacity: 1,
                    },
                  '.discover-art-swiper-button-next': {
                    position: 'absolute',
                    bottom: '27px',
                    top: 'auto',
                    right: '80px',
                  },
                  '.discover-art-swiper-button-prev': {
                    position: 'absolute',
                    top: 'auto',
                    bottom: '27px',
                    right: '115px',
                    left: 'auto',
                  },
                }}
              >
                <Swiper
                  breakpoints={{
                    0: {
                      freeMode: true,
                    },
                    576: {
                      width: 576,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 2,
                    },
                    992: {
                      width: 992,
                      slidesPerView: 2,
                    },
                    1200: {
                      width: 1200,
                      slidesPerView: 1,
                    },
                    1170: {
                      freeMode: false,
                    },
                  }}
                  slidesPerView={1}
                  spaceBetween={20}
                  pagination={{
                    el: '.swiper-pagination',
                    type: 'fraction',
                  }}
                  navigation={{
                    nextEl: '.discover-art-swiper-button-next',
                    prevEl: '.discover-art-swiper-button-prev',
                  }}
                  grabCursor={true}
                  onSlideChange={({ activeIndex }) =>
                    setActiveSliderIndex(activeIndex)
                  }
                >
                  {sliderCollection.map((slide) => (
                    <SwiperSlide key={slide.id}>
                      <Flex
                        flexDirection="column"
                        px="padding.primary"
                        pt="padding.primary"
                        mb={{ base: 4, lg: '107px' }}
                      >
                        <Text
                          fontSize={{ base: 'sm', lg: 'xs' }}
                          color="red.primary"
                          mb={{ base: '6px', lg: '11px' }}
                          as="span"
                        >
                          {t(`common:newsAndBlogTypes.${slide.type}`)}
                        </Text>

                        <Text
                          fontSize="sm"
                          mb={{ base: '7px', lg: '11px' }}
                          color="grey.primary"
                          fontWeight="medium"
                          as="span"
                        >
                          {slide.date}
                        </Text>
                        <Text
                          fontSize="lg"
                          color="black.primary"
                          mb="padding.primary"
                          as="h5"
                          w={{ base: 'xs', md: 'md' }}
                          pr="padding.primary"
                        >
                          {slide.title + ' ' + slide.subtitle}
                        </Text>
                        <Text
                          fontSize="sm"
                          fontWeight="light"
                          color="black.primary"
                          as="p"
                          noOfLines={4}
                        >
                          {slide.shortDescription}
                        </Text>
                      </Flex>
                      <Flex>
                        <Button
                          onClick={() =>
                            handleArtDetail(
                              slide.mediumLink ? slide.mediumLink : slide.url
                            )
                          }
                          size="newsletter"
                          bgColor="white.primary2"
                          color="#A50032"
                          paddingX="3.5"
                          _hover={{
                            backgroundColor: 'red.primary',
                            color: 'white.primary',
                          }}
                        >
                          {t('common:buttonExamineLabel')}
                        </Button>
                      </Flex>
                    </SwiperSlide>
                  ))}

                  <Box
                    role="button"
                    aria-label="Discover ssm art slider prev button"
                    className="swiper-button-prev discover-art-swiper-button-prev"
                  />
                  <Box
                    role="button"
                    aria-label="Discover ssm art slider next button"
                    className="swiper-button-next discover-art-swiper-button-next"
                  />
                </Swiper>
              </Box>
            </Flex>
          </Container>
        </MotionBox>
      </Box>
    </HeroAspectRatio>
  )
}

export default FullScreenSliderSection

const bgFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
