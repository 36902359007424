/* eslint-disable @typescript-eslint/no-unused-vars */
import { ExhibitionHttpService } from '@httpServices'
import {
  IEventCard,
  ISelectionsCollection,
  IExhibitionSortName,
} from '@interfaces'
import {
  createNavLink,
  convertEMuseumMediaLinks,
  generateStickyNavItem,
  generateTabCollection,
  getArtistsDataForExhibitionDetailPage,
  getCurrentLocalePropFromAPI,
  getEventSourceIdsOfExhibition,
  removeDuplicateImgUrls,
  getCurrentLocaleByObjectTitle,
} from '@utils'
import { EventService, ExtendedEMuseumService } from '@services'

export const ExhibitionService = {
  /**
   * Get all exhibition
   */
  getAllExhibition: async (locale: string): Promise<IEventCard[]> => {
    const collection = await ExhibitionHttpService.getAllExhibition()

    return await Promise.all(
      collection.exhibitions.map(async (d) => {
        const sourceId = d.sourceId?.value

        // check card image url on extended e-museum
        const extendedEMuseumData =
          await ExtendedEMuseumService.getExtendedEMuseum(
            locale,
            `exhibition-${parseInt(sourceId, 10)}`,
            'exhibition'
          )

        return {
          id: sourceId || '',
          type: 'exhibition',
          tagsTx: ['exhibition'],
          title:
            (d.exhTitle &&
              getCurrentLocalePropFromAPI(d.exhTitle?.value, locale)) ||
            '',
          date:
            (d.displayDate &&
              getCurrentLocalePropFromAPI(d.displayDate?.value, locale)) ||
            '',
          imgUrl:
            (extendedEMuseumData && extendedEMuseumData?.cardImage) ||
            d.primaryMedia?.value ||
            '',
          url: `${createNavLink('exhibition', locale)}/${sourceId}` || '#',
        }
      })
    )
  },
  /**
   * Get exhibition detail
   */
  getExhibitionDetail: async (id: string) => {
    const collection = await ExhibitionHttpService.getExhibitionDetail(id)
    return collection
  },
  /**
   * Get objects of exhibition
   */
  getObjectsOfExhibition: async (
    id: string,
    locale: string
  ): Promise<ISelectionsCollection[]> => {
    const objectsOfExhibition =
      await ExhibitionHttpService.getObjectsOfExhibition(id)

    return objectsOfExhibition.objects.map((row) => ({
      id: row.sourceId?.value || '',
      imgUrl: row.primaryMedia?.value || '',
      title: getCurrentLocaleByObjectTitle(row, locale) || '',
      name: locale === 'tr' ? 'Detaylı İncele' : 'Detailed Review',
      url:
        `${createNavLink('exhibition', locale)}/${id}/${row.sourceId?.value}` ||
        '#',
    }))
  },
  /**
   * Get exhibition for detail page
   */
  getExhibitionDetailPage: async (id: string, locale: string) => {
    const collection = await ExhibitionHttpService.getExhibitionDetail(id)
    const pageProps = {
      heroCard: {
        title: '',
        date: '',
        bgImgUrl: '',
        mobileBgImgUrl: '',
      },
      eventAbout: {
        stickyTitle: '',
        description: '',
      },
      stickyNavItems: [],
      relatedEventsAndOtherExhibitions: [],
      selectionsFromWorks: [],
      artists: [],
      googleArtLink: null,
      matterportLink: null,
      ticketLink: null,
      discoverLink: null,
      threeDTourEmbedLink: null,
      pressRelease: null,
      photosAndVideos: null,
      products: null,
    }

    if (collection?.exhibition.length > 0) {
      pageProps.heroCard.title =
        (collection?.exhibition[0]?.exhTitle &&
          getCurrentLocalePropFromAPI(
            collection?.exhibition[0]?.exhTitle?.value,
            locale
          )) ||
        ''

      pageProps.heroCard.date =
        (collection?.exhibition[0]?.displayDate &&
          getCurrentLocalePropFromAPI(
            collection?.exhibition[0]?.displayDate.value,
            locale
          )) ||
        ''

      pageProps.heroCard.bgImgUrl =
        (collection?.exhibition[0]?.primaryMedia &&
          collection?.exhibition[0]?.primaryMedia?.value) ||
        ''

      pageProps.eventAbout.stickyTitle =
        (collection?.exhibition[0]?.exhTitle &&
          getCurrentLocalePropFromAPI(
            collection?.exhibition[0]?.exhTitle?.value,
            locale
          )) ||
        ''

      pageProps.eventAbout.description =
        (collection?.exhibition[0]?.boilerText &&
          getCurrentLocalePropFromAPI(
            collection?.exhibition[0]?.boilerText?.value,
            locale
          )) ||
        ''

      // Prepare artist of exhibitions
      const initArtists =
        (collection?.exhibition[0]?.people &&
          getArtistsDataForExhibitionDetailPage(
            collection?.exhibition[0]?.people?.value
          )) ||
        []
      pageProps.artists = [...initArtists]

      // Preparing Related Events And Other Exhibitions
      const preparingrelatedEventsAndOtherExhibitions = []

      // check empty events source data of exhibition
      if (collection?.exhibition[0]?.events) {
        const eventSourceIdsOfExhibitions = getEventSourceIdsOfExhibition(
          collection?.exhibition[0]?.events?.value
        )

        const initRelatedEvents = await EventService.getRelatedEventsByIds(
          eventSourceIdsOfExhibitions,
          locale
        )

        if (initRelatedEvents.length > 0) {
          preparingrelatedEventsAndOtherExhibitions.push(
            generateTabCollection('relatedEvents', initRelatedEvents)
          )
        }
      }

      // other exhibitions
      const initOtherExhibitions = (
        await ExhibitionService.getAllExhibition(locale)
      )
        .filter((col) => col.id != id)
        .slice(0, 10)

      if (initOtherExhibitions.length > 0) {
        preparingrelatedEventsAndOtherExhibitions.push(
          generateTabCollection('otherExhibitions', initOtherExhibitions)
        )
      }

      pageProps.relatedEventsAndOtherExhibitions = [
        ...preparingrelatedEventsAndOtherExhibitions,
      ]

      // Preparing OBJECTS OF EXHBITION
      const initObjectsOfExhibition =
        await ExhibitionService.getObjectsOfExhibition(id, locale)

      pageProps.selectionsFromWorks = [...initObjectsOfExhibition]

      // Preparing STICKY NAV ITEMS
      const initStickyNavItems = []

      // check empty control for aboutTheExhibition sticky nav item
      pageProps.eventAbout.stickyTitle !== '' &&
        initStickyNavItems.push(generateStickyNavItem('aboutTheExhibition'))

      // check empty control for objects of exhibition sticky nav item
      pageProps.selectionsFromWorks.length &&
        initStickyNavItems.push(generateStickyNavItem('selectionsFromWorks'))

      /* extended e-mueseum data - start */
      const extendedEMuseumData =
        await ExtendedEMuseumService.getExtendedEMuseum(
          locale,
          `exhibition-${parseInt(
            collection?.exhibition[0]?.sourceId?.value,
            10
          )}`,
          'exhibition'
        )
      // banner image url
      if (extendedEMuseumData.bannerImage) {
        pageProps.heroCard.bgImgUrl = extendedEMuseumData.bannerImage
      }

      // mobile banner image url
      if (extendedEMuseumData.mobileBannerImage) {
        pageProps.heroCard.mobileBgImgUrl =
          extendedEMuseumData.mobileBannerImage
      }

      // prepare photos and videos (concat exhibition and extended e museum data)
      let photoAndVideos = []

      if (
        collection?.exhibition[0]?.media &&
        collection?.exhibition[0]?.exhTitle
      ) {
        const parsedEMuseumPhotos =
          (collection?.exhibition[0]?.media &&
            collection?.exhibition[0]?.exhTitle &&
            convertEMuseumMediaLinks(
              collection?.exhibition[0]?.media.value,
              collection?.exhibition[0]?.exhTitle?.value
            )) ||
          []

        photoAndVideos = [...parsedEMuseumPhotos]
      }

      if (extendedEMuseumData.photosAndVideos) {
        photoAndVideos = [
          ...photoAndVideos,
          ...extendedEMuseumData.photosAndVideos,
        ]
      }

      // remove hero image for on extended drupal img url
      photoAndVideos = removeDuplicateImgUrls(
        photoAndVideos,
        pageProps.heroCard.bgImgUrl
      )

      // remove mobile banner image for on extended drupal img url
      photoAndVideos = removeDuplicateImgUrls(
        photoAndVideos,
        pageProps.heroCard.mobileBgImgUrl
      )

      // remove primary value on e-museum for photo and video module
      photoAndVideos = removeDuplicateImgUrls(
        photoAndVideos,
        collection?.exhibition[0]?.primaryMedia?.value
      )

      if (photoAndVideos.length > 0) {
        pageProps.photosAndVideos = photoAndVideos
        initStickyNavItems.push(generateStickyNavItem('photosAndVideos'))
      }

      // 3d tour
      if (extendedEMuseumData.threeDTourEmbedLink) {
        pageProps.threeDTourEmbedLink = extendedEMuseumData.threeDTourEmbedLink
        initStickyNavItems.push(generateStickyNavItem('threeDTour'))
      }

      // products
      if (extendedEMuseumData.products) {
        pageProps.products = extendedEMuseumData.products
        initStickyNavItems.push(generateStickyNavItem('giftShop'))
      }

      // google art links
      if (extendedEMuseumData.googleArtLink) {
        pageProps.googleArtLink = extendedEMuseumData.googleArtLink
      }

      // matterport links
      if (extendedEMuseumData.matterportLink) {
        pageProps.matterportLink = extendedEMuseumData.matterportLink
      }

      // ticket links
      if (extendedEMuseumData.ticketLink) {
        pageProps.ticketLink = extendedEMuseumData.ticketLink
      }

      // discover links
      if (extendedEMuseumData.discoverLink) {
        pageProps.discoverLink = extendedEMuseumData.discoverLink
      }

      // press release
      if (extendedEMuseumData.pressRelease) {
        pageProps.pressRelease = extendedEMuseumData.pressRelease
      }
      /* extended e-mueseum data - end */

      /*
       * check empty control for relatedEvents sticky nav item
       * if array length 1 is tab title otherExhibitions
       * if array length 2 is tab title relatedEvents
       */
      pageProps.relatedEventsAndOtherExhibitions.length > 0 &&
        initStickyNavItems.push(
          generateStickyNavItem(
            pageProps.relatedEventsAndOtherExhibitions.length === 1
              ? 'otherExhibitions'
              : 'relatedEvents'
          )
        )

      pageProps.stickyNavItems = [...initStickyNavItems]
    }

    return pageProps
  },
  /**
   * Get exhibitions by sort name
   */
  getExhibitionBySortName: async (
    locale: string,
    type: IExhibitionSortName,
    params?: string
  ) => {
    const collection = await ExhibitionHttpService.getExhibitionByType(
      type,
      params
    )

    const collectionData = {
      data: [],
      totalCount: 0,
    }

    const concatCollections = await Promise.all(
      collection.exhibitions.map(async (d) => {
        const sourceId = d.sourceId?.value

        // check card image url on extended e-museum
        const extendedEMuseumData =
          await ExtendedEMuseumService.getExtendedEMuseum(
            locale,
            `exhibition-${parseInt(sourceId, 10)}`,
            'exhibition'
          )

        return {
          id: sourceId || '',
          type: 'exhibition',
          tagsTx: ['exhibition'],
          title:
            (d.exhTitle &&
              getCurrentLocalePropFromAPI(d.exhTitle?.value, locale)) ||
            '',
          date:
            (d.displayDate &&
              getCurrentLocalePropFromAPI(d.displayDate?.value, locale)) ||
            '',
          imgUrl:
            (extendedEMuseumData && extendedEMuseumData?.cardImage) ||
            d.primaryMedia?.value ||
            '',
          url:
            `${createNavLink('exhibition', locale)}/${d.sourceId?.value}` ||
            '#',
        }
      })
    )

    collectionData.data = concatCollections
    collectionData.totalCount = collection.count

    return collectionData
  },
  getAllExhibitionSlugs: async (): Promise<string[]> => {
    const data = await ExhibitionHttpService.getAllExhibition()

    return data.exhibitions.map((d) => d.sourceId?.value)
  },
}
