const SpectralFontFace = () => (
  <style jsx global>
    {`
      /* spectral-200 - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-200.woff2') format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-200.woff') format('woff'); /* Modern Browsers */
      }

      /* spectral-200italic - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: italic;
        font-weight: 200;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-200italic.woff2')
            format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-200italic.woff')
            format('woff'); /* Modern Browsers */
      }

      /* spectral-300 - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-300.woff2') format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-300.woff') format('woff'); /* Modern Browsers */
      }

      /* spectral-300italic - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-300italic.woff2')
            format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-300italic.woff')
            format('woff'); /* Modern Browsers */
      }

      /* spectral-regular - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-regular.woff2')
            format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-regular.woff')
            format('woff'); /* Modern Browsers */
      }

      /* spectral-500 - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-500.woff2') format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-500.woff') format('woff'); /* Modern Browsers */
      }

      /* spectral-italic - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-italic.woff2')
            format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-italic.woff')
            format('woff'); /* Modern Browsers */
      }

      /* spectral-500italic - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-500italic.woff2')
            format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-500italic.woff')
            format('woff'); /* Modern Browsers */
      }

      /* spectral-600 - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-600.woff2') format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-600.woff') format('woff'); /* Modern Browsers */
      }

      /* spectral-700italic - latin-ext_latin */
      @font-face {
        font-family: 'Spectral';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: local(''),
          url('/fonts/spectral-v11-latin-ext_latin-700italic.woff2')
            format('woff2'),
          /* Super Modern Browsers */
            url('/fonts/spectral-v11-latin-ext_latin-700italic.woff')
            format('woff'); /* Modern Browsers */
      }
    `}
  </style>
)

export default SpectralFontFace
