import { Flex, Box, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Checkbox } from '@ui'
import { ICheckedListingFilterTypes } from '@interfaces'
import { Dispatch } from 'react'

interface ICollectionListingFilterTypes {
  id: string
  labelTx: string
  type: string
}

interface ICollectionFilterByTypeWithCheckbox {
  title: string
  collectionListingFilterTypes: ICollectionListingFilterTypes[]
  setCheckedListingFilterTypes: Dispatch<ICheckedListingFilterTypes[]>
  checkedListingFilterTypes: ICheckedListingFilterTypes[]
}

const CollectionFilterByTypeWithCheckbox = ({
  title,
  collectionListingFilterTypes,
  checkedListingFilterTypes,
  setCheckedListingFilterTypes,
}: ICollectionFilterByTypeWithCheckbox) => {
  const { t } = useTranslation()

  const handleOnChange = (checkedValue: boolean, index: number) => {
    const cloneOldCheckedList = [...checkedListingFilterTypes]
    cloneOldCheckedList[index] = checkedValue
    setCheckedListingFilterTypes(cloneOldCheckedList)
  }

  return (
    <Box px="padding.primary" py="5">
      <Text fontWeight="medium" fontSize="sm" mb="6px">
        {title}
      </Text>
      <Flex
        flexDirection="column"
        sx={{
          '&>*+*': {
            mt: '10px',
          },
        }}
      >
        {collectionListingFilterTypes.map((row, index) => (
          <Checkbox
            key={row.id}
            isChecked={checkedListingFilterTypes[index]}
            onChange={(e) => handleOnChange(e.target.checked, index)}
          >
            {t(row.labelTx)}
          </Checkbox>
        ))}
      </Flex>
    </Box>
  )
}

export default CollectionFilterByTypeWithCheckbox
