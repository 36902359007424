import { Box, Container, Flex, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Markup } from 'interweave'
import { ShowMore } from '@common'
import { IListArtistName } from '@interfaces'
// import { Link } from '@ui'
// import { createNavLink } from '@utils'
//import { useRouter } from 'next/router'
interface IExhibitionAndEventAbout {
  stickyTitle: string
  staticDescriptions: string
  sectionId?: string
  artists?: IListArtistName[]
  activePage: 'exhibition' | 'event' | 'learning' | 'workshop' | 'movies'
}

const ExhibitionAndEventAbout = ({
  sectionId = 'AboutTheExhibition',
  stickyTitle,
  staticDescriptions,
  artists,
  activePage,
}: IExhibitionAndEventAbout) => {
  const { t } = useTranslation()
  // const { locale } = useRouter()
  return (
    <Box
      id={sectionId}
      as="section"
      pt={{ base: '4', lg: '20' }}
      pb="padding.primary2"
    >
      <Container maxW={{ base: 'full', lg: 'container.secondary' }}>
        <Flex flexDirection={{ base: 'column', lg: 'row' }}>
          <Box
            maxW={{ base: 'full', lg: '266px' }}
            width="full"
            mb={{ base: '5', lg: '0' }}
          >
            <Text
              color="black.primary3"
              fontSize="lg"
              position="sticky"
              top="25%"
            >
              {stickyTitle}
            </Text>
          </Box>
          <Box
            flex={1}
            maxW="full"
            pl={{ base: '0', lg: '14' }}
            sx={{ '&>*+*': { mt: '4' } }}
          >
            {staticDescriptions && (
              <ShowMore isOnlyMobileWork={false} startingHeight={210}>
                <Box fontWeight="light" fontSize="sm" color="black.primary3">
                  <Markup content={staticDescriptions} />
                </Box>
              </ShowMore>
            )}
            {artists && artists.length > 0 && (
              <Box my="8">
                <Text color="black.primary3" mb="2">
                  {t(
                    activePage === 'exhibition'
                      ? 'common:curator'
                      : activePage === 'event'
                      ? 'common:people'
                      : 'common:artists'
                  )}
                </Text>
                <Flex flexDirection="column">
                  {artists.map((artist, index) => (
                    /* <Link
                      d="none"
                       key={index}
                      href={`${createNavLink('artist', locale)}/${
                        artist.sourceId
                      }`}
                      target="_blank"
                      fontWeight="light"
                      fontSize="sm"
                      color="black.primary3"
                      _hover={{
                        color: 'red.primary',
                      }}
                    >
                      {artist.fullName}
                    </Link> */
                    <Text key={index}>{artist.fullName}</Text>
                  ))}
                </Flex>
              </Box>
            )}
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default ExhibitionAndEventAbout
