import {
  IPlaceOpeningHoursInput,
  IVisitingDay,
  IPlacePeriod,
} from '@interfaces'
import visitTR from '@/locales/tr/visit.json'
import visitEN from '@/locales/en/visit.json'
import commonTR from '@/locales/tr/common.json'
import commonEN from '@/locales/en/common.json'
import { v4 as uuidv4 } from 'uuid'

const getDayName = (locale: string, day: IPlacePeriod['day']) => {
  let dayName = ''

  switch (day) {
    case 0:
      dayName =
        locale === 'tr'
          ? visitTR.informationAndTransportation.visitingDays.sunday
          : visitEN.informationAndTransportation.visitingDays.sunday
      break

    case 1:
      dayName =
        locale === 'tr'
          ? visitTR.informationAndTransportation.visitingDays.monday
          : visitEN.informationAndTransportation.visitingDays.monday
      break

    case 2:
      dayName =
        locale === 'tr'
          ? visitTR.informationAndTransportation.visitingDays.tuesday
          : visitEN.informationAndTransportation.visitingDays.tuesday
      break

    case 3:
      dayName =
        locale === 'tr'
          ? visitTR.informationAndTransportation.visitingDays.wednesday
          : visitEN.informationAndTransportation.visitingDays.wednesday
      break

    case 4:
      dayName =
        locale === 'tr'
          ? visitTR.informationAndTransportation.visitingDays.thursday
          : visitEN.informationAndTransportation.visitingDays.thursday
      break

    case 5:
      dayName =
        locale === 'tr'
          ? visitTR.informationAndTransportation.visitingDays.friday
          : visitEN.informationAndTransportation.visitingDays.friday
      break

    case 6:
      dayName =
        locale === 'tr'
          ? visitTR.informationAndTransportation.visitingDays.saturday
          : visitEN.informationAndTransportation.visitingDays.saturday
      break

    default:
      break
  }

  return dayName
}

const isToday = (
  currentDay: IPlacePeriod['day'] | number,
  placeDay: IPlacePeriod['day'] | number
) => currentDay === placeDay

const sortVisitingDays = (visitingDays: IVisitingDay[]): IVisitingDay[] => {
  const a = [...visitingDays]
  const b = a.shift()
  a[a.length] = b
  return a
}

const prepareOpeningHour = (time: string, isAlwaysClosed = false) => {
  const hour = time.slice(0, 2)
  const min = time.slice(2, time.length)

  return isAlwaysClosed ? `${time}` : `${hour}.${min}`
}

const generateVisitingDay = (
  locale: string,
  period?: IPlaceOpeningHoursInput
): IVisitingDay => {
  const { open, close } = period
  const { day: openDay, time: openTime } = open
  const { time: closeTime } = close
  const isAlwaysClosed = openTime === 'Closed'

  // get current day
  const d = new Date()
  const getDay = d.getDay()

  // get day name
  const getDyName = getDayName(locale, openDay)

  const parsedOpenTime = prepareOpeningHour(openTime, isAlwaysClosed)

  const parsedCloseTime = prepareOpeningHour(closeTime, isAlwaysClosed)

  return {
    id: uuidv4(),
    dayType: isAlwaysClosed
      ? 'alwaysClosed'
      : isToday(getDay, openDay)
      ? 'isActive'
      : 'isNotActive',
    dayName: getDyName,
    isClosed: isAlwaysClosed,
    openingHours: isAlwaysClosed
      ? parsedOpenTime
      : `${parsedOpenTime}-${parsedCloseTime}`,
  }
}

const fillClosedVisitingDay = (
  periods: IPlaceOpeningHoursInput[]
): IPlaceOpeningHoursInput[] => {
  const filledMissedPeriods = [...periods]
  const openedDays = periods.map((p) => p.open.day)
  const days = [0, 1, 2, 3, 4, 5, 6]

  const difference = days.filter((x) => !openedDays.includes(x))

  difference.map((index) => {
    filledMissedPeriods.splice(index, 0, {
      close: { day: index, time: 'Closed' },
      open: { day: index, time: 'Closed' },
    })
  })

  return filledMissedPeriods
}

export const generateVisitingDays = (
  locale: string,
  periods: IPlaceOpeningHoursInput[]
): IVisitingDay[] => {
  const visitingDays = []

  const filledWithClosedVisitingDay = fillClosedVisitingDay(periods)

  filledWithClosedVisitingDay.map((period) =>
    visitingDays.push(generateVisitingDay(locale, period))
  )

  return sortVisitingDays(visitingDays)
}

export const getOpeningHourLabelCurrentMuseum = (locale, openingHours) => {
  if (!openingHours) {
    return
  }

  const result = {
    isOpeningLabel: '',
    openingHourLabel: '',
  }

  const activeDay = [
    {
      dayname: 'Sunday',
      realIndex: 6,
    },
    {
      dayname: 'Monday',
      realIndex: 0,
    },
    {
      dayname: 'Tuesday',
      realIndex: 1,
    },
    {
      dayname: 'Wednesday',
      realIndex: 2,
    },
    {
      dayname: 'Thursday',
      realIndex: 3,
    },
    {
      dayname: 'Friday',
      realIndex: 4,
    },
    {
      dayname: 'Saturday',
      realIndex: 5,
    },
  ][new Date().getDay()]

  const todayOpeningHours = openingHours.find(
    (_d, index) => activeDay?.realIndex === index
  )

  if (todayOpeningHours) {
    result.isOpeningLabel = todayOpeningHours?.isClosed
      ? locale === 'tr'
        ? commonTR.museumProgram.closedToday
        : commonEN.museumProgram.closedToday
      : locale === 'tr'
      ? commonTR.museumProgram.openToday
      : commonEN.museumProgram.openToday
    result.openingHourLabel = todayOpeningHours?.isClosed
      ? null
      : todayOpeningHours?.openingHours
  }

  return result
}
