import { createIcon } from "@chakra-ui/icons";

const TicketIcon = createIcon({
  displayName: "Ticket",
  viewBox: "0 0 512 512",
  defaultProps: {
    fill: "#fff",
    width: "24px",
    height: "20px",
    marginBottom: "3px",
    marginRight: "4px"
  },
  path: (
    <path d="M512,170.667v-102.4H349.867v51.2h-51.2v-51.2H0v102.4c47.128,0,85.333,38.205,85.333,85.333S47.128,341.333,0,341.333
			v102.4h298.667v-51.2h51.2v51.2H512v-102.4c-47.128,0-85.333-38.205-85.333-85.333S464.872,170.667,512,170.667z M349.867,335.645
			h-51.2v-51.2h51.2V335.645z M349.867,227.555h-51.2v-51.2h51.2V227.555z"/>
  )
});

export default TicketIcon;
