export const Accordion = {
  baseStyle: {
    container: {
      borderTopWidth: '0px',
      _last: {
        borderBottomWidth: '0px',
      },
    },
    button: {
      fontSize: 'lg',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'transparent',
      },
      px: 0,
      py: 0,
    },
    panel: {
      pt: '10px',
      px: 0,
      pb: '30px',
    },
  },
}
