import eMuseumHttp from '../eMuseumHttpService'
import {
  EXHIBITION,
  EXHIBITION_DETAIL,
  OBJECTS_OF_EXHIBITION,
  EXHIBITIONS_BY_TYPE,
} from '@lib/const/api-endpoints.const'

/**
 * Exhibition Http Service
 */
export const ExhibitionHttpService = {
  /**
   * Get all exhibition eMuseumHttp service
   */
  getAllExhibition: async () => await eMuseumHttp.get(EXHIBITION),
  /**
   * Get exhibition detail eMuseumHttp service
   */
  getExhibitionDetail: async (id) =>
    await eMuseumHttp.get(EXHIBITION_DETAIL(id)),
  /**
   * Get objects of the exhibition eMuseumHttp service
   */
  getObjectsOfExhibition: async (id) =>
    await eMuseumHttp.get(OBJECTS_OF_EXHIBITION(id)),
  /**
   * Get exhibition by sort type eMuseumHttp service
   */
  getExhibitionByType: async (type, params) =>
    await eMuseumHttp.get(`${EXHIBITIONS_BY_TYPE(type)}&${params}`),
}
