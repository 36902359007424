import { SearchHttpService } from '@httpServices'
import {
  prepareSearchFacet,
  prepareSearchResultLink,
  getCurrentLocalePropFromAPI,
  prepareSearchImgUrl,
  parseISODate,
} from '@utils'
import { ISearchResultCard } from '@interfaces'

/**
 * Search Service
 */
export const SearchService = {
  /**
   * Get search results
   */
  getSearchResults: async (
    locale: string,
    searchTerm: string,
    page: number,
    type: string
  ) => {
    const searchTerms = searchTerm.split(';')
    const searchResults = []
    for (const term of searchTerms) {
      const response = await SearchHttpService.getSearchResults(
        locale,
        term,
        page,
        type
      )

      const totalPage = response?.pager?.total_pages || '1'
      const totalCount = response?.pager?.total_items || '0'

      const preparedResults = await SearchService.prepareSearchResults(
        response?.rows,
        locale
      )

      searchResults.push({
        results: preparedResults || null,
        metaData: {
          totalPage,
          totalCount,
        },
      })
    }
    const combinedResults = []

    searchResults.forEach((result) => {
      combinedResults.push(...result.results)
    })

    const mergedResult = {
      results: combinedResults,
      metaData: {
        totalPage: searchResults[0].metaData.totalPage,
        totalCount: searchResults
          .reduce((acc, curr) => acc + parseInt(curr.metaData.totalCount), 0)
          .toString(),
      },
    }

    return mergedResult
  },
  prepareSearchResults: (dirtyResults, locale): ISearchResultCard[] => {
    return dirtyResults.map((result) => ({
      title: getCurrentLocalePropFromAPI(result?.title, locale) || '',
      shortDescription: result?.field_pr_short_description || '',
      url: prepareSearchResultLink({ ...result, locale }) || '#',
      type: prepareSearchFacet(result?.type),
      imgUrl: prepareSearchImgUrl(result),
      date:
        (result?.field_publication_year &&
          parseISODate(result?.field_publication_year, locale)) ||
        '',
      author: result?.field_publication_author,
      pageCount: result?.field_publication_page_count,
    }))
  },
}
