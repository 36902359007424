/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import {
  EventService,
  ExhibitionService,
  ArticleService,
  InstagramService,
  HeadlineService,
  SelectionsFromCollectionService,
  ToursService,
  PlantService,
  ResearchAreaService,
  //  OnlineExhibitionsService,
  PublicationsService,
} from '@services'
import {
  generateTabCollection,
  generateStickyNavItem,
  createNavLink,
  prepareSectionData,
  prepareTwoColBlock,
  generatePhotosAndVideosFromExtendedEMuseum,
  prepareVideoUrl,
  getFilterPublications,
} from '@utils'
import { PageHttpService } from '@httpServices'
import NAV_PATHS from '@lib/const/nav-paths.const'
import {
  IStickyNav,
  ISectionData,
  IPageTab,
  ITwoColumnBlock,
  IAccordionCollection,
  IAddressContactAndTransportation,
  IWorkshopCard,
  IDynamicPageTemplate,
} from '@interfaces'
import commonTR from '@/locales/tr/common.json'
import commonEN from '@/locales/en/common.json'
import { v4 as uuidv4 } from 'uuid'
import { PAGE_TYPES_KEYS } from '@lib/const/dynamic-layout-components'

export const PageService = {
  /**
   * Get home page data
   */
  getHomePage: async (locale: string) => {
    //headline
    const headlines = await HeadlineService.getAllHeadline(locale)

    // current museum

    // prepare events
    const currentEvents = await EventService.getEventsBySortName(
      locale,
      'Event',
      'Current',
      `page=1`
    )
    const futureEvents = await EventService.getEventsBySortName(
      locale,
      'Event',
      'Future',
      `page=1`
    )
    const events = [...currentEvents.data, ...futureEvents.data]

    // prepare exhibitions
    const currentExhibitions = await ExhibitionService.getExhibitionBySortName(
      locale,
      'current',
      `page=1`
    )
    const futureExhibitions = await ExhibitionService.getExhibitionBySortName(
      locale,
      'future',
      `page=1`
    )
    const exhibitions = [...currentExhibitions.data, ...futureExhibitions.data]

    // prepare learnings
    /*
    const currentWorkshops = await EventService.getWorkshopsBySortName(
      locale,
      'Current',
      `page=1`
    )
    const futureWorkshops = await EventService.getWorkshopsBySortName(
      locale,
      'Future',
      `page=1`
    )
    const workshops = [...currentWorkshops.data, ...futureWorkshops.data]
    */
    // prepare tours
    const tours = await ToursService.getTourCategories(locale)
    // prepare learnings
    const currentLearnings = await EventService.getEventsBySortName(
      locale,
      'Learning',
      'Current',
      `page=1`
    )
    const futureLearnings = await EventService.getEventsBySortName(
      locale,
      'Learning',
      'Future',
      `page=1`
    )

    const learnings = [
      ...currentLearnings.data,
      ...futureLearnings.data.concat().reverse().slice(0, 7),
      ...tours,
    ]

    // prepare online exhibitions
    // const onlineExhibitions = await OnlineExhibitionsService.getOnlineExhibitions(
    //  locale
    // )

    // prepare tours
    /*const tours = await ToursService.getTourCategories(
        locale
    )
*/
    const currentMuseumProgram = [
      generateTabCollection('exhibitions', exhibitions),
      //   generateTabCollection('onlineExhibitions', onlineExhibitions),
      generateTabCollection('events', events),
      //   generateTabCollection('workshops', workshops),
      generateTabCollection('learningPrograms', learnings),
      //    generateTabCollection('tours', tours),
      generateTabCollection('allTab', [
        ...exhibitions,
        ...events,
        ...learnings,
        //    ...onlineExhibitions,
        //    ...tours,
      ]),
    ]

    // explore
    const exploreSSM = await PageService.getPageByFilterType(locale, 'featured')

    // learning
    const discoverArt = await PageService.getPageByFilterType(
      locale,
      'learning'
    )

    // prepare explore art section
    const artForAllPageData = await PageService.getPage(locale, 'artForAll')

    const exploreArt = {
      bannerTextContent: null,
      slider: null,
    }

    const featuredArticles = await ArticleService.getArticlesByFilter(
      locale,
      'featured'
    )

    if (featuredArticles.length > 0) {
      exploreArt.slider = featuredArticles
    }

    if (artForAllPageData) {
      exploreArt.bannerTextContent = artForAllPageData.body[0]?.value || ''
    }

    // selections from collection
    const selectionsFromCollections =
      await SelectionsFromCollectionService.getSelectionsFromCollection(locale)

    return {
      headlines,
      currentMuseumProgram,
      exploreArt,
      exploreSSM,
      discoverArt,
      selectionsFromCollections,
    }
  },
  /**
   * Get featured or learning page data drupalHttp service
   * for example: https://drupal.sakipsabancimuzesi.org/tr/api/page/featured
   * for example: https://drupal.sakipsabancimuzesi.org/tr/api/page/learning
   */
  getPageByFilterType: async (
    locale: string,
    filterType: 'featured' | 'learning'
  ) => {
    const collection = await PageHttpService.getPageByFilterType(
      locale,
      filterType
    )

    const pageByFilterType = collection.sort(function (a, b) {
      return a.field_featured_rank[0]?.value - b.field_featured_rank[0]?.value
    })

    return pageByFilterType.map((d) => ({
      id: d.uuid[0]?.value || '',
      title: (d.title && d.title[0]?.value) || '',
      description: (d.body && d.body[0]?.value) || '',
      imgUrl: (d.field_featured_bnr && d.field_featured_bnr[0]?.url) || '',
      buttonLabel:
        (d.field_featured_link_text && d.field_featured_link_text[0]?.value) ||
        '',
      buttonUrl: (d.field_path_alias && d.field_path_alias[0]?.value) || '',
    }))
  },
  /**
   * Get board members data for 'ssm-yi-kesfet/organizasyon' page
   */
  getBoardMembers: async (locale: string) => {
    const boardMembers = await PageHttpService.getAllPage(locale)

    const getBoardMemberKey = {
      boardOfDirectors: {
        tr: '/yonetim-kurulu-uyeleri',
        en: '/board-trustees',
      },
      sSMInternationalAdvisoryBoard: {
        tr: '/ssm-uluslararasi-danisma-kurulu-uyeleri',
        en: '/ssm-international-board-overseers',
      },
      sSMYoungAdvisoryBoard: {
        tr: '/genc-kurul',
        en: '/young-advisory-board',
      },
      sSMStaff: {
        tr: '/ssm-calisanlari',
        en: '/ssm-staff',
      },
    }

    const boardOfDirectors = boardMembers
      .filter(
        (b) =>
          b.field_path_alias &&
          b.field_path_alias[0]?.value ===
            getBoardMemberKey['boardOfDirectors'][locale]
      )
      .map((row) => ({
        __html: decodeURIComponent(row.body[0].value),
      }))[0]

    const sSMInternationalAdvisoryBoard = boardMembers
      .filter(
        (b) =>
          b.field_path_alias &&
          b.field_path_alias[0]?.value ===
            getBoardMemberKey['sSMInternationalAdvisoryBoard'][locale]
      )
      .map((row) => ({
        __html: decodeURIComponent(row.body[0].value),
      }))[0]

    const sSMYoungAdvisoryBoard = boardMembers
      .filter(
        (b) =>
          b.field_path_alias &&
          b.field_path_alias[0]?.value ===
            getBoardMemberKey['sSMYoungAdvisoryBoard'][locale]
      )
      .map((row) => ({
        __html: decodeURIComponent(row.body[0].value),
      }))[0]

    const sSMStaff = boardMembers
      .filter(
        (b) =>
          b.field_path_alias &&
          b.field_path_alias[0]?.value === getBoardMemberKey['sSMStaff'][locale]
      )
      .map((row) => ({
        __html: decodeURIComponent(row.body[0].value),
      }))[0]

    return {
      boardOfDirectors,
      sSMInternationalAdvisoryBoard,
      sSMYoungAdvisoryBoard,
      sSMStaff,
    }
  },
  /**
   * Get video detail for photos and videos modules
   */
  getVideoDetail: async (locale: string, targetId) => {
    return PageHttpService.getVideoDetail(locale, targetId)
  },
  /**
   * Get page by field_path_alias
   */
  getPage: async (locale: string, slug: keyof typeof NAV_PATHS) => {
    const pageData = await PageHttpService.getAllPage(locale)

    return pageData.find(
      (page) => page?.field_path_alias[0]?.value === createNavLink(slug, locale)
    )
  },
  /**
   * Get data art for all page
   */
  getDataArtForAllPage: async (locale: string) => {
    const pageData = await PageService.getPage(locale, 'artForAll')

    // prepare banner section data
    const hero = {
      bgImageUrl: '',
      subTitle: '',
      title: '',
      description: '',
    }

    if (pageData) {
      hero.bgImageUrl = pageData.field_page_banner_image[0]?.url || ''
      hero.subTitle = pageData.field_page_banner_subheader[0]?.value || ''
      hero.title = pageData.field_page_banner_header[0]?.value || ''
      hero.description = pageData.body[0]?.summary || ''
    }

    // prepare stay updated section data
    const stayUpdated = {
      bannerTextContent: null,
      slider: null,
    }

    const featuredArticles = await ArticleService.getArticlesByFilter(
      locale,
      'featured'
    )

    if (pageData) {
      stayUpdated.bannerTextContent = pageData.body[0]?.value || ''
    }

    if (featuredArticles.length > 0) {
      stayUpdated.slider = featuredArticles.slice(0, 10)
    }

    // prepare inspiring section data
    // const inspiringArticles = await ArticleService.getArticlesByFilter(
    //   locale,
    //   'inspiring'
    // )

    // prepare learn section data
    // const learnWithSSM = await PageService.getPageByFilterType(
    //   locale,
    //   'learning'
    // )

    // Preparing STICKY NAV ITEMS
    const initStickyNavItems = []

    stayUpdated?.slider?.length > 0 &&
      initStickyNavItems.push(generateStickyNavItem('stayUpdated'))

    // inspiringArticles?.length > 0 &&
    //   initStickyNavItems.push(generateStickyNavItem('getInspired'))

    // learnWithSSM?.length > 0 &&
    //   initStickyNavItems.push(generateStickyNavItem('learnWitSSM'))

    return {
      stickyNavItems: initStickyNavItems,
      hero,
      stayUpdated,
      // inspiringArticles,
      // learnWithSSM,
    }
  },
  /**
   * Get page data for independent project on explore art
   */
  getPageDataIndependentProject: async (locale: string) => {
    const pageData = await PageService.getPage(locale, 'independentProject')

    // prepare banner section data
    const hero = {
      bgImageUrl: '',
      subTitle: '',
      title: '',
      description: '',
    }

    if (pageData) {
      hero.bgImageUrl = pageData.field_page_banner_image[0]?.url || ''
      hero.subTitle = pageData.field_page_banner_subheader[0]?.value || ''
      hero.title = pageData.field_page_banner_header[0]?.value || ''
      hero.description = pageData.body[0]?.summary || ''
    }

    return {
      hero,
    }
  },
  /**
   * Get group and tour page data
   */
  getGroupAndTourPageData: async (locale: string) => {
    const pageData = await PageService.getPage(locale, 'groupsAndTours')
    const tourCategories = await ToursService.getTourCategories(locale)

    // prepare banner section data
    const hero = {
      subTitle: '',
      title: '',
      description: '',
    }

    if (pageData) {
      hero.subTitle =
        (pageData?.field_page_banner_header &&
          pageData?.field_page_banner_header[0]?.value) ||
        ''
      hero.title =
        (pageData?.field_page_banner_subheader &&
          pageData?.field_page_banner_subheader[0]?.value) ||
        ''
      hero.description = (pageData?.body && pageData?.body[0]?.summary) || ''
    }

    // current on e-museum
    const currentEvents = await EventService.getEventsBySortName(
      locale,
      'Event',
      'Current'
    )
    const futureEvents = await EventService.getEventsBySortName(
      locale,
      'Event',
      'Future'
    )
    const events = [...currentEvents.data, ...futureEvents.data]

    const currentExhibitions = await ExhibitionService.getExhibitionBySortName(
      locale,
      'current'
    )
    const futureExhibitions = await ExhibitionService.getExhibitionBySortName(
      locale,
      'future'
    )
    const exhibitions = [...currentExhibitions.data, ...futureExhibitions.data]

    const relatedExhibitionAndEvents = [...events, ...exhibitions]

    return {
      hero,
      tourCategories,
      relatedExhibitionAndEvents,
    }
  },
  /**
   * Get archive and research area page data
   */
  getArchiveAndResearchAreaPageData: async (locale: string) => {
    const pageData = await PageService.getDefaultLayoutPageData(
      locale,
      'archiveAndResearchArea'
    )

    if (
      pageData &&
      pageData?.data &&
      pageData?.data?.stickyNavItems &&
      pageData?.data?.stickyNavItems?.length &&
      pageData?.data?.sections &&
      pageData?.data?.sections?.length
    ) {
      // add project data to section
      // check has project on sticky nav items and sections
      const hasProjectOnStickyNav = pageData?.data?.stickyNavItems.some(
        (navItem) => navItem?.href === 'projects'
      )
      const hasProjectSection = pageData?.data?.sections.some(
        (sectionItem) => sectionItem?.stickyId === 'projects'
      )

      if (hasProjectOnStickyNav && hasProjectSection) {
        const indexProjectSection = pageData?.data?.sections.findIndex(
          (section) => section.stickyId === 'projects'
        )

        if (
          indexProjectSection &&
          pageData?.data?.sections[indexProjectSection] &&
          pageData?.data?.sections[indexProjectSection]?.data
        ) {
          const projectsOfResearchArea = await ResearchAreaService.getProjects(
            locale
          )

          if (projectsOfResearchArea && projectsOfResearchArea?.length) {
            pageData.data.sections[indexProjectSection].data =
              projectsOfResearchArea
          }
        }
      }

      // add software development
      // check has software development on sticky nav items and sections
      const hasSwDevOnStickyNav = pageData?.data?.stickyNavItems.some(
        (navItem) => navItem?.href === 'softwareDevelopment'
      )
      const hasSwDevSection = pageData?.data?.sections.some(
        (sectionItem) => sectionItem?.stickyId === 'softwareDevelopment'
      )

      if (hasSwDevOnStickyNav && hasSwDevSection) {
        const indexSwDevSection = pageData?.data?.sections.findIndex(
          (section) => section.stickyId === 'softwareDevelopment'
        )

        if (
          indexSwDevSection &&
          pageData?.data?.sections[indexSwDevSection] &&
          pageData?.data?.sections[indexSwDevSection]?.data
        ) {
          const swDevOfResearchArea = await ResearchAreaService.getSoftwareDev(
            locale
          )

          if (swDevOfResearchArea && swDevOfResearchArea?.length) {
            pageData.data.sections[indexSwDevSection].data = swDevOfResearchArea
          }
        }
      }
    }

    return pageData
  },
  /**
   * Get tab of page drupalHttp service
   */
  getTabOfPage: async (locale: string, id: string) => {
    const tabOfPageCategory = await PageHttpService.getTabOfPage(locale, id)

    return tabOfPageCategory
  },
  /**
   * Get default layout page data
   */
  getDefaultLayoutPageData: async (
    locale: string,
    activeScreen: keyof typeof NAV_PATHS
  ) => {
    const pageData = {
      data: {
        pageTitle: '',
        hero: {
          bgImageUrl: '',
          subTitle: '',
          title: '',
          description: '',
        },
        stickyNavItems: null,
        instagramMedia: null,
        exploreSSM: null,
        sections: null,
      },
      error: null,
    }

    try {
      // get page data
      const initPageData = await PageService.getPage(locale, activeScreen)
      // instagram
      const instagramMedia = await InstagramService.getFeeds(locale)

      pageData.data.instagramMedia = instagramMedia
      if (initPageData) {
        // prepare hero and page title
        const heroAndPageTitle = await PageService.preparePageTitleHeroAnd(
          initPageData
        )

        if (heroAndPageTitle) {
          pageData.data.pageTitle = heroAndPageTitle?.pageTitle
          pageData.data.hero = heroAndPageTitle?.hero
        }

        // preparing dynamic tabs
        const tabIds = initPageData.field_page_tabs.map((t) => t.target_id)

        // get tabs of page initial data
        const initialTabsOfPage = await PageService.getTabsOfPage(
          locale,
          tabIds
        )

        // prepare sticky nav items
        const preparedStickyNavItems = await PageService.prepareStickyNavItems(
          initialTabsOfPage
        )

        pageData.data.stickyNavItems = preparedStickyNavItems

        // prepare page tab sections
        const preparedSectionsData = await PageService.preparedSectionsData(
          locale,
          preparedStickyNavItems,
          initialTabsOfPage,
          activeScreen
        )

        pageData.data.sections = preparedSectionsData
      }

      // prepare explore ssm section data
      const exploreSSM = await PageService.getPageByFilterType(
        locale,
        'featured'
      )

      pageData.data.exploreSSM = exploreSSM
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  getTabsOfPage: async (
    locale: string,
    tabIds: string[]
  ): Promise<IPageTab[]> => {
    const initTabsOfPage = await Promise.all(
      tabIds.map(async (id) => {
        const tabOfPage = await PageService.getTabOfPage(locale, id)

        return tabOfPage
      })
    )

    return initTabsOfPage
  },
  preparePageTitleHeroAnd: async (pageData) => {
    const data = {
      pageTitle: '',
      hero: {
        bgImageUrl: '',
        bgImageUrlMobile: '',
        subTitle: '',
        title: '',
        description: '',
      },
    }

    // prepare page title
    data.pageTitle =
      (pageData?.title &&
        pageData?.title.length > 0 &&
        pageData?.title[0]?.value) ||
      ''

    // prepare hero
    data.hero.bgImageUrl =
      (pageData?.field_page_banner_image &&
        pageData?.field_page_banner_image.length > 0 &&
        pageData?.field_page_banner_image[0]?.url.replace(
          'files',
          'files/styles/webp/public'
        ) + '.webp') ||
      ''
    data.hero.bgImageUrlMobile =
      (pageData?.field_page_banner_image_mobile &&
        pageData?.field_page_banner_image_mobile.length > 0 &&
        pageData?.field_page_banner_image_mobile[0]?.url) ||
      ''
    data.hero.subTitle =
      (pageData?.field_page_banner_header &&
        pageData?.field_page_banner_header.length > 0 &&
        pageData?.field_page_banner_header[0]?.value) ||
      ''

    data.hero.title =
      (pageData?.field_page_banner_subheader &&
        pageData?.field_page_banner_subheader.length > 0 &&
        pageData?.field_page_banner_subheader[0]?.value) ||
      ''

    data.hero.description =
      (pageData?.body &&
        pageData?.body.length > 0 &&
        pageData?.body[0]?.summary) ||
      ''

    return data
  },
  prepareStickyNavItems: async (
    initTabsOfPage: IPageTab[]
  ): Promise<IStickyNav[]> => {
    const tabsOfTour = initTabsOfPage.map((tab) => ({
      id: tab[0]?.nid[0]?.value || '',
      navTitleTx: tab[0]?.title[0]?.value || '',
      href: tab[0]?.field_page_tab_id[0]?.value || '',
      titleIsWithLocalizationOnClient: false,
      rank: tab[0]?.field_page_tab_rank[0]?.value || 0,
      contentType: tab[0]?.field_tab_content_type[0]?.value || 'html',
      __component: `sections.${
        tab[0]?.field_tab_content_type[0]?.value || 'html'
      }`,
    }))

    const stickyNavItems = tabsOfTour.sort((a, b) => a.rank - b.rank)
    return stickyNavItems
  },
  preparedSectionsData: async (
    locale: string,
    stickyNavItems: IStickyNav[],
    initTabsOfPage: IPageTab[],
    activeScreen?: keyof typeof NAV_PATHS
  ): Promise<ISectionData[]> => {
    const sectionsData = await Promise.all(
      stickyNavItems.map(async (tab) => {
        let sectionData = null

        // visiting days
        if (tab.__component === 'sections.htmlwithvisitinghour') {
          const tabContent = initTabsOfPage.find(
            (t) => t[0]?.field_page_tab_id[0]?.value === tab.href
          )

          if (tabContent) {
            const data = {
              reservationLink: null,
              htmlContent: null,
            }

            const reservationLink =
              tabContent[0]?.field_reservation_btn_json[0]?.value
            const htmlContent = tabContent[0]?.body[0]?.value

            if (reservationLink) {
              data.reservationLink = JSON.parse(reservationLink)
            }

            if (htmlContent) {
              data.htmlContent = htmlContent
            }

            sectionData = data
          }
        }

        // two column grid block
        if (tab.__component === 'sections.block') {
          // prepare rest block ids
          const tabContent = initTabsOfPage.find(
            (t) => t[0]?.field_page_tab_id[0]?.value === tab.href
          )
          if (tabContent) {
            const restBlockIds = tabContent[0]?.field_tab_rest_block.map(
              (blockData) => blockData?.target_id
            )

            const restBlockData = await Promise.all(
              restBlockIds.map(async (restBlockId) => {
                const restBlockData = await PageService.getRestBlock(
                  locale,
                  restBlockId
                )
                return (
                  (restBlockData &&
                    restBlockData.length > 0 &&
                    restBlockData[0]) ||
                  null
                )
              })
            )

            const bodyValue = tabContent[0]?.body[0]?.value
            if (bodyValue) {
              restBlockData.push({ bodyValue })
            }
            // get prepared rest block data
            sectionData = restBlockData
          }
        }

        // accordion
        if (tab.__component === 'sections.accordion') {
          const tabContent = initTabsOfPage.find(
            (t) => t[0]?.field_page_tab_id[0]?.value === tab.href
          )

          if (tabContent) {
            // get faq ids
            const accordionIds = tabContent[0].field_tab_accordion.map(
              (faq) => faq.target_id
            )

            // get faq detail
            const accordionData = await Promise.all(
              accordionIds.map(async (accordionId) => {
                const faq = await PageService.getAccordion(locale, accordionId)
                return faq[0]
              })
            )

            sectionData = accordionData
          }
        }

        // custom html
        if (tab.__component === 'sections.html') {
          const tabContent = initTabsOfPage.find(
            (t) => t[0]?.field_page_tab_id[0]?.value === tab.href
          )

          if (tabContent) {
            const data = {
              link: null,
              htmlContent: null,
            }

            const link = tabContent[0]?.field_primary_btn_json[0]?.value || null
            const htmlContent = tabContent[0]?.body[0]?.value || ''

            if (link) {
              data.link = JSON.parse(link)
            }

            if (htmlContent) {
              data.htmlContent = htmlContent
            }

            sectionData = data
          }
        }

        // address, contact and transportation section
        if (tab.__component === 'sections.contact_info') {
          const contactData = await PageService.getContact(locale, activeScreen)

          if (contactData) {
            sectionData = contactData
          }
        }

        // gallery
        if (tab.__component === 'sections.gallery') {
          const tabContent = initTabsOfPage.find(
            (t) => t[0]?.field_page_tab_id[0]?.value === tab.href
          )
          if (tabContent) {
            const photoAndVideos = []

            // images
            tabContent[0].field_page_tab_image_collection.map((img) => {
              photoAndVideos.push({
                id: img.target_id,
                url:
                  img.url.replace('files', 'files/styles/webp/public') +
                  '.webp',
                alt: img.alt,
                isVideo: false,
              })
            })

            // generate videos
            if (
              tabContent[0].field_page_tab_video_collection &&
              tabContent[0].field_page_tab_video_collection.length > 0
            ) {
              const videoTargetIds = []
              tabContent[0].field_page_tab_video_collection.map((video) =>
                videoTargetIds.push(video?.target_id)
              )

              await Promise.all(
                videoTargetIds.map(async (videoTargetId) => {
                  const videoDetail = await PageService.getVideoDetail(
                    locale,
                    videoTargetId
                  )

                  if (videoDetail.length > 0) {
                    photoAndVideos.push(
                      generatePhotosAndVideosFromExtendedEMuseum({
                        id: videoDetail[0]?.nid[0]?.value || uuidv4(),
                        url:
                          (videoDetail[0]?.field_page_video_url &&
                            videoDetail[0]?.field_page_video_provider &&
                            prepareVideoUrl(
                              videoDetail[0]?.field_page_video_url[0]?.value,
                              videoDetail[0]?.field_page_video_provider[0]
                                ?.value || 'youtube'
                            )) ||
                          '',
                        alt: videoDetail[0]?.title[0]?.value || '',
                        isVideo: true,
                        videoProvider:
                          videoDetail[0]?.field_page_video_provider[0]?.value ||
                          'youtube',
                      })
                    )
                  }
                })
              )
            }

            sectionData = photoAndVideos
          }
        }

        // plant list section on garden page
        if (tab.__component === 'sections.plantlist') {
          const tabContent = initTabsOfPage.find(
            (t) => t[0]?.field_page_tab_id[0]?.value === tab.href
          )

          if (tabContent) {
            const plantService = await PlantService.getAllPlant(locale)

            if (plantService) {
              sectionData = plantService
            }
          }
        }

        return prepareSectionData(
          tab.__component,
          sectionData,
          tab.navTitleTx,
          tab.href,
          tab.id,
          activeScreen
        )
      })
    )

    return sectionsData
  },
  /**
   * Get block data for page tab section
   */
  getRestBlock: async (
    locale: string,
    id: string
  ): Promise<ITwoColumnBlock[]> => {
    const tickets = await PageHttpService.getRestBlock(locale, id)
    return tickets.map((ticket) => {
      let initTicket = {
        id: uuidv4(),
        leftColTitleRow: [
          {
            leftColTitle: '',
            leftColRightSubTitle: null,
          },
        ],
        leftColButtonLabel: null,
        leftColButtonUrl: null,
        leftColisExternelUrl: null,
        rightColHtmlContent: '',
        isAlignItemsCenter: false,
      }

      try {
        const leftColTitleRow = JSON.parse(
          ticket.field_left_col_title_row[0]?.value
        )

        initTicket = {
          id: ticket.nid[0]?.value || '',
          leftColTitleRow: [
            {
              leftColTitle: leftColTitleRow?.leftColTitle || '',
              leftColRightSubTitle: [
                leftColTitleRow?.leftColRightSubTitle || '',
              ],
            },
          ],
          leftColButtonLabel:
            ticket.field_rb_left_col_btn_label[0]?.value ||
            (locale === 'tr' ? commonTR.goToWebsite : commonEN.goToWebsite),
          leftColButtonUrl: ticket.field_rb_left_col_btn_url[0]?.value || '',
          leftColisExternelUrl:
            ticket.field_rb_left_col_is_external_ur[0]?.value || true,
          rightColHtmlContent:
            ticket.field_rb_right_col_html_content[0]?.value || '',
          isAlignItemsCenter:
            ticket.field_is_align_items_center[0]?.value || true,
        }
      } catch (error) {
        if (error instanceof Error) {
          initTicket = {
            id: uuidv4(),
            leftColTitleRow: [
              {
                leftColTitle: 'JSON Parse Error',
                leftColRightSubTitle: null,
              },
            ],
            leftColButtonLabel: null,
            leftColButtonUrl: null,
            leftColisExternelUrl: null,
            rightColHtmlContent: error?.message || '',
            isAlignItemsCenter: false,
          }
        }
      }

      return initTicket
    })
  },
  /**
   * Get accordion data for page tab section
   */
  getAccordion: async (
    locale: string,
    id: string
  ): Promise<IAccordionCollection[]> => {
    const accordionsOfPage = await PageHttpService.getAccordion(locale, id)

    const accordions = accordionsOfPage.map((accordion) => ({
      id: accordion.nid[0]?.value || '',
      accordionTitle: accordion.field_page_accordion_header[0]?.value || '',
      accordionHtmlContent:
        accordion.field_page_accordion_content[0]?.value || '',
    }))

    return accordions
  },
  /**
   * Get contact data for page tab section
   */
  getContact: async (
    locale: string,
    activeScreen: keyof typeof NAV_PATHS
  ): Promise<IAddressContactAndTransportation['data']> => {
    const avaliableScreens = [
      'visit',
      'seed',
      'msaSRestaurant',
      'archiveAndResearchArea',
      'contact',
    ]

    if (!avaliableScreens.includes(activeScreen)) {
      return
    }

    let activeScreenKey

    switch (activeScreen) {
      case 'visit':
        activeScreenKey = 'ssm'
        break

      case 'seed':
        activeScreenKey = 'seed'
        break

      case 'msaSRestaurant':
        activeScreenKey = 'msa'
        break

      case 'archiveAndResearchArea':
        activeScreenKey = 'rd'
        break

      case 'contact':
        activeScreenKey = 'ssm'
        break

      default:
        break
    }

    const contactData = await PageHttpService.getContact(
      locale,
      activeScreenKey
    )

    if (contactData && contactData.length > 0) {
      let iframeLink = null
      let twoColumnBlockData = []

      // prepare iframe link
      const iframeValue = contactData[0]?.field_contact_map[0]?.value

      if (iframeValue) {
        iframeLink = iframeValue
      }

      // prepare primary contact data; address, tel, fax, email.
      // address
      const addressValue = contactData[0]?.field_contact_address_line[0]?.value
      const addressLinkValue =
        contactData[0]?.field_contact_direction_link[0]?.value || ''

      if (addressValue) {
        const leftColTitleRow = [
          {
            leftColTitle:
              locale === 'tr'
                ? commonTR.contact.address
                : commonEN.contact.address,
            leftColRightSubTitle: null,
          },
        ]
        const leftColButtonLabel =
          locale === 'tr'
            ? commonTR.contact.getDirections
            : commonEN.contact.getDirections

        const addressData = prepareTwoColBlock(
          leftColTitleRow,
          leftColButtonLabel,
          addressLinkValue,
          true,
          addressValue,
          false
        )

        twoColumnBlockData.push(addressData)
      }

      // tel, fax and e-mail
      const tel = contactData[0]?.field_contact_phone[0]?.value
      const fax = contactData[0]?.field_contact_fax[0]?.value
      const email = contactData[0]?.field_contact_email[0]?.value
      let contactHtmlContent

      if (tel || fax || email) {
        const leftColTitleRow = []

        if (tel) {
          const telTitleRow = {
            leftColTitle:
              locale === 'tr' ? commonTR.contact.tel : commonEN.contact.tel,
            leftColRightSubTitle: null,
          }

          leftColTitleRow.push(telTitleRow)
          contactHtmlContent = `<a target="_blank" href="tel:${tel}">${tel}</a><br /><br />`
        }

        if (fax) {
          const faxTitleRow = {
            leftColTitle:
              locale === 'tr' ? commonTR.contact.fax : commonEN.contact.fax,
            leftColRightSubTitle: null,
          }

          leftColTitleRow.push(faxTitleRow)
          contactHtmlContent = `${contactHtmlContent}<a target="_blank" href="fax:${fax}">${fax}</a><br /><br />`
        }

        if (email) {
          const emailTitleRow = {
            leftColTitle:
              locale === 'tr' ? commonTR.contact.email : commonEN.contact.email,
            leftColRightSubTitle: null,
          }

          leftColTitleRow.push(emailTitleRow)
          contactHtmlContent = `${contactHtmlContent}<a target="_blank" href="mailto:${email}">${email}</a>`
        }

        const leftColButtonLabel = null
        const leftColButtonUrl = null
        const leftColisExternelUrl = null

        const addressData = prepareTwoColBlock(
          leftColTitleRow,
          leftColButtonLabel,
          leftColButtonUrl,
          leftColisExternelUrl,
          contactHtmlContent,
          true
        )

        twoColumnBlockData.push(addressData)
      }

      // add additional location data
      if (activeScreen !== 'contact') {
        // other location data
        const locationData = contactData[0]?.field_contact_json[0]?.value

        if (locationData) {
          const parsedLocationData = JSON.parse(locationData)

          if (parsedLocationData.length > 0) {
            twoColumnBlockData = [...twoColumnBlockData, ...parsedLocationData]
          }
        }
      }

      return {
        iframeLink,
        twoColumnBlockData,
      }
    }
  },
  /**
   * Get kid page data
   */
  getKidPage: async (locale: string) => {
    const pageData = {
      data: {
        data: null,
        kidBooks: null,
        workshopDescriptionHtmlContent: null,
        workshops: null,
        gallery: null,
        guidedToursDescriptionHtmlContent: null,
        guidedToursUrl: null,
      },
      error: null,
    }

    try {
      // prepare kid books
      const publications = await PublicationsService.getAllPublications(locale)

      if (publications && publications.length > 0) {
        const kidBooks = getFilterPublications(publications, 'Çocuk Yayınları')
        if (kidBooks && kidBooks.length) {
          pageData.data.kidBooks = kidBooks.map((book) => ({
            id: book?.id || '',
            imgUrl: book?.imgUrl || '',
            imgAlt: book?.title || '',
            title: book?.title || '',
            description: book?.description || '',
            pdfUrl: book?.pdfLink || '',
            listeningUrl: book?.listeningUrl || '',
            videoUrl: book?.videoUrl || '',
          }))
        }
      }

      const kidsData = await PageService.getKidsPage(locale)
      // prepare workshop text
      if (kidsData && kidsData.length > 0) {
        pageData.data.data = kidsData

        const workshopDescriptionHtmlContent =
          kidsData[0]?.field_kids_workshops_text[0]?.value || null

        pageData.data.workshopDescriptionHtmlContent =
          workshopDescriptionHtmlContent

        // prepare gallery
        const photoAndVideos = []

        // check has image
        if (
          kidsData[0]?.field_page_image_collection &&
          kidsData[0]?.field_page_image_collection.length > 0
        ) {
          // images
          kidsData[0].field_page_image_collection.map((img) => {
            photoAndVideos.push({
              id: img.target_id,
              url: img.url,
              alt: img.alt,
              isVideo: false,
            })
          })
        }

        // generate videos
        if (
          kidsData[0].field_page_video_collection &&
          kidsData[0].field_page_video_collection.length > 0
        ) {
          const videoTargetIds = []
          kidsData[0].field_page_video_collection.map((video) =>
            videoTargetIds.push(video?.target_id)
          )
          await Promise.all(
            videoTargetIds.map(async (videoTargetId) => {
              const videoDetail = await PageService.getVideoDetail(
                locale,
                videoTargetId
              )

              if (videoDetail.length > 0) {
                photoAndVideos.push(
                  generatePhotosAndVideosFromExtendedEMuseum({
                    id: videoDetail[0]?.nid[0]?.value || uuidv4(),
                    url:
                      (videoDetail[0]?.field_page_video_url &&
                        videoDetail[0]?.field_page_video_provider &&
                        prepareVideoUrl(
                          videoDetail[0]?.field_page_video_url[0]?.value,
                          videoDetail[0]?.field_page_video_provider[0]?.value ||
                            'youtube'
                        )) ||
                      '',
                    alt: videoDetail[0]?.title[0]?.value || '',
                    isVideo: true,
                    videoProvider:
                      videoDetail[0]?.field_page_video_provider[0]?.value ||
                      'youtube',
                  })
                )
              }
            })
          )
        }

        if (photoAndVideos.length > 0) {
          pageData.data.gallery = photoAndVideos
        }

        // prepare tour text and button url
        if (
          kidsData[0]?.field_kids_guidedtours_text &&
          kidsData[0]?.field_kids_guidedtours_text.length > 0
        ) {
          pageData.data.guidedToursDescriptionHtmlContent =
            kidsData[0]?.field_kids_guidedtours_text[0]?.value || ''
        }

        if (
          kidsData[0]?.field_kids_guidedtours_url &&
          kidsData[0]?.field_kids_guidedtours_url.length > 0
        ) {
          pageData.data.guidedToursUrl =
            kidsData[0]?.field_kids_guidedtours_url[0]?.value || ''
        }
      }

      // prepare workshops
      const workshops = await EventService.getAllEvent('Workshop', locale)

      if (workshops && workshops.length > 0) {
        const preparedWorkshops = PageService.prepareWorkshopData(
          workshops.slice(0, 10)
        )
        pageData.data.workshops = preparedWorkshops
      }
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  getKidsPage: async (locale: string) => {
    const kids = await PageHttpService.getKidsPage(locale)

    return kids
  },
  prepareWorkshopData: (workshops): IWorkshopCard[] => {
    return workshops.map((workshop) => ({
      id: workshop?.id || '',
      title: workshop?.title || '',
      shortDescription: workshop?.date || '',
      imgUrl: workshop?.imgUrl || '',
      url: workshop?.url || '',
    }))
  },
  getOrganizationPage: async (locale) => {
    const pageData = {
      data: {
        pageTitle: null,
        hero: {
          subTitle: '',
          title: '',
          description: '',
          bgImageUrl: '',
        },
        boardMembers: null,
        data: null,
      },
      error: null,
    }

    try {
      // prepare hero
      const organizationPageData = await PageService.getDefaultLayoutPageData(
        locale,
        'organization'
      )

      if (
        organizationPageData &&
        organizationPageData.data &&
        organizationPageData.data.hero
      ) {
        pageData.data.pageTitle = organizationPageData.data.pageTitle
        pageData.data.hero = organizationPageData.data.hero
        pageData.data.data = organizationPageData
      }

      // prepare board member
      const boardMembers = await PageService.getBoardMembers(locale)

      pageData.data.boardMembers = boardMembers
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  getContactPage: async (locale) => {
    const pageData = {
      data: {
        pageTitle: null,
        hero: {
          subTitle: '',
          title: '',
          description: '',
          bgImageUrl: '',
        },
        data: null,
        addressContact: null,
      },
      error: null,
    }

    try {
      // prepare hero
      const contactPageData = await PageService.getDefaultLayoutPageData(
        locale,
        'contact'
      )

      if (
        contactPageData &&
        contactPageData.data &&
        contactPageData.data.hero
      ) {
        pageData.data.pageTitle = contactPageData.data.pageTitle
        pageData.data.hero = contactPageData.data.hero
        pageData.data.data = contactPageData
      }

      // prepare address and contact data
      const contactData = await PageService.getContact(locale, 'contact')

      if (contactData) {
        pageData.data.addressContact = contactData
      }
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  getMembershipPage: async (locale) => {
    const pageData = {
      data: {
        pageTitle: '',
        hero: {
          bgImageUrl: '',
          subTitle: '',
          title: '',
          description: '',
        },
        data: null,
        tickets: null,
      },
      error: null,
    }

    try {
      // get page data
      const initPageData = await PageService.getPage(locale, 'membership')

      if (initPageData) {
        pageData.data.data = initPageData

        // prepare hero and page title
        const heroAndPageTitle = await PageService.preparePageTitleHeroAnd(
          initPageData
        )

        if (heroAndPageTitle) {
          pageData.data.pageTitle = heroAndPageTitle?.pageTitle
          pageData.data.hero = heroAndPageTitle?.hero
        }

        // prepare tickets
        if (
          initPageData.field_page_data_json &&
          initPageData.field_page_data_json.length > 0
        ) {
          try {
            const dirtyTickets = initPageData.field_page_data_json[0].value
            const parsedTickets = JSON.parse(dirtyTickets)

            if (
              parsedTickets &&
              typeof parsedTickets === 'object' &&
              parsedTickets?.length > 0
            ) {
              pageData.data.tickets = parsedTickets
            }
          } catch (error) {
            if (error instanceof Error) {
              console.log({ parseError: error?.message })
            }
          }
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  getBasicPage: async (locale, activeScreen: keyof typeof NAV_PATHS) => {
    const pageData = {
      data: {
        title: '',
        htmlContent: '',
        data: null,
      },
      error: null,
    }

    try {
      const dirtyPage = await PageService.getPage(locale, activeScreen)

      if (dirtyPage) {
        pageData.data.title = dirtyPage.title[0]?.value || ''
        pageData.data.htmlContent = dirtyPage.body[0]?.value || ''
      }
    } catch (error) {
      if (error instanceof Error) {
        pageData.error = { message: error.message }
      }
    }

    return pageData
  },
  getDynamicPages: async (locale) =>
    await PageHttpService.getDynamicPages(locale),
  getDynamicPage: async (
    locale: string,
    slug: string
  ): Promise<IDynamicPageTemplate> => {
    const layoutData = {
      pageType: null,
      pageData: null,
    }

    try {
      const dynamicPage = await PageHttpService.getDynamicPage(locale, slug)

      if (
        dynamicPage &&
        typeof dynamicPage === 'object' &&
        dynamicPage.length > 0
      ) {
        const pageType: IDynamicPageTemplate['pageType'] =
          dynamicPage[0]?.field_pa[0]?.value || null

        if (pageType && PAGE_TYPES_KEYS.includes(pageType)) {
          layoutData.pageType = pageType

          switch (pageType) {
            case 'basicpage':
              const basicPageData = await PageService.preparePageBasicData(
                dynamicPage[0]
              )

              layoutData.pageData = basicPageData
              break

            case 'pagewithtabs':
              const pageWithTabsData =
                await PageService.preparePageWithTabsData(
                  locale,
                  dynamicPage[0]
                )

              layoutData.pageData = pageWithTabsData
              break

            case 'pagewithherobanner':
              const pageWitHeroBannerData =
                await PageService.preparePageWithHeroBannerData(dynamicPage[0])
              layoutData.pageData = pageWitHeroBannerData
              break

            case 'pagewithherotext':
              const pageWitHeroTextData =
                await PageService.preparePageWithHeroTextData(dynamicPage[0])

              layoutData.pageData = pageWitHeroTextData
              break

            default:
              break
          }
        }
      }

      // layoutData.pageData.data = dynamicPage
    } catch (error) {
      if (error instanceof Error) {
        layoutData.pageData.error = { message: error.message }
      }
    }

    return layoutData
  },
  preparePageBasicData: async (initPage: any) => {
    const pageData = {
      data: {
        pageTitle: '',
        title: '',
        htmlContent: '',
      },
      error: null,
    }

    try {
      if (initPage) {
        pageData.data.pageTitle = initPage.title[0]?.value || ''
        pageData.data.title = initPage.title[0]?.value || ''
        pageData.data.htmlContent = initPage.body[0]?.value || ''
      }
    } catch (error) {
      if (error instanceof Error) {
        pageData.error = { message: error.message }
      }
    }

    return pageData
  },
  preparePageWithTabsData: async (locale: string, initPageData: any) => {
    const pageData = {
      data: {
        pageTitle: '',
        hero: {
          bgImageUrl: '',
          subTitle: '',
          title: '',
          description: '',
        },
        stickyNavItems: null,
        exploreSSM: null,
        sections: null,
      },
      error: null,
    }

    try {
      if (initPageData) {
        // prepare hero and page title
        const heroAndPageTitle = await PageService.preparePageTitleHeroAnd(
          initPageData
        )

        if (heroAndPageTitle) {
          pageData.data.pageTitle = heroAndPageTitle?.pageTitle
          pageData.data.hero = heroAndPageTitle?.hero
        }

        // preparing dynamic tabs
        const tabIds = initPageData.field_page_tabs.map((t) => t.target_id)

        // get tabs of page initial data
        const initialTabsOfPage = await PageService.getTabsOfPage(
          locale,
          tabIds
        )

        // prepare sticky nav items
        const preparedStickyNavItems = await PageService.prepareStickyNavItems(
          initialTabsOfPage
        )

        pageData.data.stickyNavItems = preparedStickyNavItems

        // prepare page tab sections
        const preparedSectionsData = await PageService.preparedSectionsData(
          locale,
          preparedStickyNavItems,
          initialTabsOfPage,
          'visit'
        )

        pageData.data.sections = preparedSectionsData
      }

      // prepare explore ssm section data
      const exploreSSM = await PageService.getPageByFilterType(
        locale,
        'featured'
      )

      pageData.data.exploreSSM = exploreSSM
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  preparePageWithHeroBannerData: async (initPageData: any) => {
    const pageData = {
      data: {
        pageTitle: '',
        hero: {
          bgImageUrl: '',
          subTitle: '',
          title: '',
          description: '',
        },
        htmlContent: null,
        alternative_block: null,
      },
      error: null,
    }

    try {
      if (initPageData) {
        // prepare hero and page title
        const heroAndPageTitle = await PageService.preparePageTitleHeroAnd(
          initPageData
        )

        console.table(initPageData);

        if (heroAndPageTitle) {
          pageData.data.pageTitle = heroAndPageTitle?.pageTitle
          pageData.data.hero = heroAndPageTitle?.hero
        }

        const alternativeBlock = initPageData.field_alternative_block

        pageData.data.alternative_block = alternativeBlock?.[0]?.value
          ? JSON.parse(alternativeBlock[0].value)
          : []

        pageData.data.htmlContent =
          (initPageData?.body && initPageData?.body[0]?.value) || null
      }
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  preparePageWithHeroTextData: async (initPageData: any) => {
    const pageData = {
      data: {
        pageTitle: '',
        subTitle: '',
        title: '',
        description: '',
        htmlContent: null,
        imageCollection: null,
      },
      error: null,
    }

    try {
      if (initPageData) {
        // prepare hero and page title
        const heroAndPageTitle = await PageService.preparePageTitleHeroAnd(
          initPageData
        )

        if (heroAndPageTitle) {
          pageData.data.pageTitle = heroAndPageTitle?.pageTitle
          pageData.data.subTitle = heroAndPageTitle?.hero?.subTitle || ''
          pageData.data.title = heroAndPageTitle?.hero?.title || ''
          pageData.data.description = heroAndPageTitle?.hero?.description || ''
        }

        pageData.data.htmlContent =
          (initPageData?.body && initPageData?.body[0]?.value) || null
        pageData.data.imageCollection = initPageData?.field_page_collection || null
      }
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  getDynamicPageSlugs: async (locale) => {
    const dynamicPages = await PageHttpService.getDynamicPages(locale)

    const dynamicPageSlugs = []

    dynamicPages.map((page) => {
      if (
        page?.field_slug_for_page_service &&
        page?.field_slug_for_page_service.length > 0
      ) {
        dynamicPageSlugs.push(page?.field_slug_for_page_service[0]?.value)
      }
    })

    return dynamicPageSlugs
  },
}
