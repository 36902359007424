import { createIcon } from '@chakra-ui/icons'

const FullScreen = createIcon({
  displayName: 'FullScreen',
  defaultProps: {
    width: '26px',
    height: '26px',
    fill: 'none',
  },
  viewBox: '0 0 26 26',
  path: (
    <>
      <path
        d="M12.7676 6.06145H20.1093V13.4031"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M20.1094 6.05957L14.4363 11.7327"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M13.1034 20.4092L5.76179 20.4092L5.76179 13.0676"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M5.76172 20.4111L11.4348 14.738"
        stroke="currentColor"
        strokeWidth="2"
      />
    </>
  ),
})

export default FullScreen
