import { createIcon } from '@chakra-ui/icons'

const VirtualTour = createIcon({
  displayName: 'VirtualTour',
  defaultProps: {
    width: 22,
  },
  viewBox: '0 0 22 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.4 2.4h17.2v10.681H2.4V2.4zM0 0H22v15.481H0V0zm9.167 10.916l5.5-3.175-5.5-3.176v6.351z"
    />
  ),
})

export default VirtualTour
