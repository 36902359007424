import {
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Flex,
  Divider,
  Box,
  BoxProps,
} from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useStore } from '@store'
import { ChevronDown } from '@icon'
import useTranslation from 'next-translate/useTranslation'
import ART_SORT_FILTER_OPTIONS from '@lib/const/art-sort-filter-options.const'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

interface ICollectionSearchSortButton {
  containerProps?: BoxProps
  isOnModal?: boolean
}

const CollectionSearchSortButton = observer(
  ({ containerProps, isOnModal = false }: ICollectionSearchSortButton) => {
    const [displaySortOption, setDisplaySortOption] = useState(false)
    const {
      artSearch: { sortResult, setSortResult },
      ui: { scrollDir },
    } = useStore()
    const { t } = useTranslation()
    const router = useRouter()

    const getActiveSortLabel = () => {
      switch (sortResult) {
        case 'Relevance':
          return t('collections-and-research:search.collectionSort1')

        case 'title-asc':
          return t('collections-and-research:search.collectionSort2')

        case 'title-desc':
          return t('collections-and-research:search.collectionSort3')

        case 'displayDate-asc':
          return t('collections-and-research:search.collectionSort4')

        case 'displayDate-desc':
          return t('collections-and-research:search.collectionSort5')

        case 'primaryMakerAlpha-asc':
          return t('collections-and-research:search.collectionSort6')

        case 'primaryMakerAlpha-desc':
          return t('collections-and-research:search.collectionSort7')

        case 'invno-asc':
          return t('collections-and-research:search.collectionSort8')

        case 'invno-desc':
          return t('collections-and-research:search.collectionSort9')

        default:
          return t('collections-and-research:search.collectionSort1')
      }
    }

    useEffect(() => {
      if (scrollDir === 'up' && !isOnModal) {
        setDisplaySortOption(false)
      }
    }, [scrollDir, isOnModal])

    const handleOnClickSort = (currentSort: typeof sortResult) => {
      setSortResult(currentSort)
      setDisplaySortOption(false)

      const path = router.pathname
      const query = router.query
      query.sort = currentSort
      query.page = '1'

      router.push(
        {
          pathname: path,
          query: query,
        },
        undefined,
        { scroll: false }
      )
    }

    return (
      <Box {...containerProps}>
        <Popover
          placement="bottom-start"
          strategy="fixed"
          isOpen={displaySortOption}
        >
          <PopoverTrigger>
            <Button
              variant="unstyled"
              rightIcon={
                <ChevronDown
                  fontSize="16px"
                  transition="transform 0.3s ease"
                  transform={
                    displaySortOption ? 'rotate(180deg)' : 'rotate(0deg)'
                  }
                />
              }
              onClick={() => setDisplaySortOption((oldState) => !oldState)}
              fontWeight="bold"
              fontSize="sm"
              color="black.primary3"
              minWidth={{ base: '16px', lg: '10' }}
              display="flex"
              alignItems="center"
            >
              <Text color="red.primary">
                {t('collections-and-research:search.orderButtonLabel')}
                {': \u00A0'}
              </Text>
              {getActiveSortLabel()}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            background="white.primary"
            borderRadius="none"
            borderColor="greyAlpha.primary"
            _focus={{
              boxShadow: 'none',
            }}
          >
            <PopoverArrow />
            <PopoverBody p="0">
              <Flex flexDirection="column" px="padding.primary">
                {ART_SORT_FILTER_OPTIONS.map((option, index) => (
                  <Button
                    key={option.id}
                    onClick={() => handleOnClickSort(option.value)}
                    fontWeight="light"
                    color="black.primary3"
                    fontSize="sm"
                    variant="unstyled"
                    height="auto"
                    _hover={{
                      '& > p': {
                        color: 'red.primary',
                      },
                    }}
                  >
                    {index === 0 && isOnModal && (
                      <Divider borderBottomColor="white.primary3" opacity="1" />
                    )}
                    <Text py="4" textAlign="left">
                      {t(option.labelTx)}
                    </Text>
                    {index + 1 !== ART_SORT_FILTER_OPTIONS.length &&
                      !isOnModal && (
                        <Divider
                          borderBottomColor="white.primary3"
                          opacity="1"
                        />
                      )}
                  </Button>
                ))}
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    )
  }
)

export default CollectionSearchSortButton
