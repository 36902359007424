import { Button, ButtonProps } from '@chakra-ui/react'
import { IFonts } from '@interfaces'

interface IPaginationButton extends ButtonProps {
  isActive?: boolean
  label: string
  onClick: () => void
  activeFontFamily?: IFonts
}

const PaginationButton = ({
  isActive = false,
  label,
  onClick,
  activeFontFamily = 'body',
  ...rest
}: IPaginationButton) => {
  return (
    <Button
      onClick={onClick}
      size="xs"
      variant="unstyled"
      minWidth="auto"
      position="relative"
      fontFamily={activeFontFamily}
      fontSize="sm"
      color={isActive ? 'red.primary' : 'black.primary3'}
      fontWeight={isActive ? 'medium' : 'light'}
      _before={
        isActive
          ? {
              content: `" "`,
              position: 'absolute',
              bottom: '-3px',
              left: '0px',
              right: '0px',
              width: '100%',
              height: '2px',
              bgColor: 'red.primary',
            }
          : { content: `none` }
      }
      {...rest}
    >
      {label}
    </Button>
  )
}

export default PaginationButton
