import { ArticleHttpService } from '@httpServices'
import { INewsAndBlogCard, IFullScreenSlider, IArticleTag } from '@interfaces'
import {
  parseISODate,
  createNavLink,
  transformArticleKeyNameOfType,
} from '@utils'

export const ArticleService = {
  /**
   * Get all article
   */
  getAllArticle: async (
    locale: string | 'en' | 'tr',
    addUrlParams?: string
  ): Promise<INewsAndBlogCard[]> => {
    const collection = await ArticleHttpService.getAllArticle(
      locale,
      addUrlParams
    )

    return collection
      .sort(
        (a, b) =>
          new Date(b.created[0].value).valueOf() -
          new Date(a.created[0].value).valueOf()
      )
      .map((d) => ({
        id: d.uuid[0]?.value || '',
        imgUrl: d.field_image[0]?.url || '',
        url:
          `${createNavLink('newsAndBlog', locale)}${
            d.field_path_alias[0]?.value
          }` || '',
        title: (d.title && d.title[0]?.value) || '',
        subtitle:
          (d.field_article_subtitle && d.field_article_subtitle[0]?.value) ||
          '',
        description: d.body[0]?.value || '',
        shortDescription: d.body[0]?.summary || '',
        date: parseISODate(d.created[0]?.value, locale) || '',
        type:
          transformArticleKeyNameOfType(d.field_article_type[0]?.value) ||
          'article',
        author:
          (d.field_article_author_fe_dname &&
            d.field_article_author_fe_dname[0]?.value) ||
          '',

        bannerDisplay:
          (d.field_article_banner_display &&
            d.field_article_banner_display[0]?.value) ||
          '',

        slug:
          (d.field_path_alias &&
            d.field_path_alias[0]?.value.slice(
              1,
              d.field_path_alias[0]?.value.length
            )) ||
          '',
        mediumLink:
          (d.field_article_medium_link &&
            d.field_article_medium_link[0]?.uri) ||
          false,
      }))
  },
  /**
   * Get articles by filter
   */
  getArticlesByFilter: async (
    locale: string | 'en' | 'tr',
    filterType: 'featured' | 'inspiring'
  ): Promise<INewsAndBlogCard[] | IFullScreenSlider[]> => {
    const collection = await ArticleHttpService.getArticlesByFilter(
      locale,
      filterType
    )

    return collection
      .sort(
        (a, b) =>
          new Date(b.created[0].value).valueOf() -
          new Date(a.created[0].value).valueOf()
      )
      .map((d) => ({
        id: d.uuid[0]?.value || '',
        imgUrl: d.field_image[0]?.url || '',
        url:
          `${createNavLink('newsAndBlog', locale)}${
            d.field_path_alias[0]?.value
          }` || '',
        title: (d.title && d.title[0]?.value) || '',
        subtitle:
          (d.field_article_subtitle && d.field_article_subtitle[0]?.value) ||
          '',
        description: d.body[0]?.value || '',
        shortDescription: d.body[0]?.summary || '',
        date: parseISODate(d.created[0]?.value, locale) || '',
        type: filterType === 'inspiring' ? 'inspiration' : 'new',
        author:
          (d.field_article_author_fe_dname &&
            d.field_article_author_fe_dname[0]?.value) ||
          '',
        slug:
          (d.field_path_alias &&
            d.field_path_alias[0]?.value.slice(
              1,
              d.field_path_alias[0]?.value.length
            )) ||
          '',
        mediumLink:
          (d.field_article_medium_link &&
            d.field_article_medium_link[0]?.uri) ||
          false,
        tagsTx: [
          transformArticleKeyNameOfType(d.field_article_type[0]?.value) ||
            'new',
        ],
      }))
  },
  /**
   * Get tags of article
   */
  getTagsOfArticles: async (locale: string): Promise<IArticleTag[]> => {
    const allTags = await ArticleHttpService.getTagsOfArticles(locale)

    const transformedTags = allTags.map((tag) => ({
      id: tag.tid[0]?.value || '',
      name: tag.name[0]?.value || '',
      url: `${createNavLink('newsAndBlog', locale)}?tagId=${
        tag.tid[0]?.value || ''
      }`,
    }))

    return transformedTags
  },
  /**
   * Get articles by tag id
   */
  getArticleByTag: async (locale: string, tagId: string) => {
    const articlesByTag = await ArticleHttpService.getArticleByTag(
      locale,
      tagId
    )

    return articlesByTag
      .sort(
        (a, b) =>
          new Date(b.created[0].value).valueOf() -
          new Date(a.created[0].value).valueOf()
      )
      .map((d) => ({
        id: d.uuid[0]?.value || '',
        imgUrl: d.field_image[0]?.url || '',
        url:
          `${createNavLink('newsAndBlog', locale)}${
            d.field_path_alias[0]?.value
          }` || '',
        title: (d.title && d.title[0]?.value) || '',
        subtitle:
          (d.field_article_subtitle && d.field_article_subtitle[0]?.value) ||
          '',
        description: d.body[0]?.value || '',
        shortDescription: d.body[0]?.summary || '',
        date: parseISODate(d.created[0]?.value, locale) || '',
        type:
          transformArticleKeyNameOfType(d.field_article_type[0]?.value) ||
          'article',
        author:
          (d.field_article_author_fe_dname &&
            d.field_article_author_fe_dname[0]?.value) ||
          '',
        slug:
          (d.field_path_alias &&
            d.field_path_alias[0]?.value.slice(
              1,
              d.field_path_alias[0]?.value.length
            )) ||
          '',
        mediumLink:
          (d.field_article_medium_link &&
            d.field_article_medium_link[0]?.uri) ||
          false,
      }))
  },
  getAllArticleSlugs: async (
    locale: string | 'en' | 'tr'
  ): Promise<string[]> => {
    const articles = await ArticleHttpService.getAllArticle(locale)

    return articles.map(
      (article) =>
        (article.field_path_alias &&
          article.field_path_alias[0]?.value.slice(
            1,
            article.field_path_alias[0]?.value.length
          )) ||
        ''
    )
  },
}
