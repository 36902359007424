import { useStore } from '@/src/models/Root'
import { useRouter } from 'next/router'
import { useRef, MutableRefObject } from 'react'

export const useHandleSearchPagination = () => {
  const containerRef = useRef(null) as MutableRefObject<HTMLDivElement>
  const router = useRouter()
  const {
    search: { setPage },
  } = useStore()

  // Triggers fetch for new page
  const handlePagination = (page: number) => {
    const path = router.pathname
    const query = router.query
    query.page = page.toString()

    const yCoordinate =
      containerRef?.current?.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -40
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
    setPage(page.toString())

    router.push(
      {
        pathname: path,
        query: query,
      },
      undefined,
      { scroll: false }
    )
  }

  return {
    handlePagination,
    containerRef,
  }
}
