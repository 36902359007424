import {
  Container,
  Box,
  BoxProps,
  Flex,
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Divider,
} from '@chakra-ui/react'
import { ChevronDown } from '@icon'
import { observer } from 'mobx-react-lite'
import { useStore } from '@store'
import useTranslation from 'next-translate/useTranslation'
import { INewsAndBlogSortTypes } from '@interfaces'
import NEWS_AND_BLOG_SORT_FILTER_OPTIONS from '@lib/const/news-and-blog-sort-filter-options.const'

interface INewsAndBlogSortBox {
  containerProps?: BoxProps
}

const NewsAndBlogSortBox = observer(
  ({ containerProps }: INewsAndBlogSortBox) => {
    const { t } = useTranslation()
    const {
      newsAndBlog: {
        sortResult,
        displaySortModal,
        toggleDisplaySortModal,
        setSortResult,
      },
      ui: { scrollDir },
    } = useStore()

    const getActiveSortLabel = () => {
      switch (sortResult) {
        case 'all':
          return t('explore-art:newsAndBlog.sortTypes.all')

        case 'new':
          return t('explore-art:newsAndBlog.sortTypes.new')

        case 'article':
          return t('explore-art:newsAndBlog.sortTypes.article')

        case 'interview':
          return t('explore-art:newsAndBlog.sortTypes.interview')

        case 'performance':
          return t('explore-art:newsAndBlog.sortTypes.performance')

        case 'film-and-video':
          return t('explore-art:newsAndBlog.sortTypes.film-and-video')

        case 'trip':
          return t('explore-art:newsAndBlog.sortTypes.trip')

        default:
          return t('explore-art:newsAndBlog.sortTypes.all')
      }
    }

    const handleOnClickSortButton = (sortVal: INewsAndBlogSortTypes) => {
      setSortResult(sortVal)
      toggleDisplaySortModal()
    }

    return (
      <Box
        as="nav"
        position="sticky"
        top={scrollDir === 'down' ? '0px' : '-100%'}
        bgColor="white.primary"
        zIndex={3}
        {...containerProps}
      >
        <Container>
          <Flex
            sx={{
              '& > * + *': {
                ml: 6,
              },
              scrollSnapType: 'x proximity',
            }}
            py={{ base: 4, lg: 5 }}
            height={{ base: '50px', lg: '65px' }}
            flexDirection="row"
            alignItems="center"
          >
            <Popover
              placement="bottom-start"
              strategy="fixed"
              isOpen={displaySortModal}
            >
              <PopoverTrigger>
                <Button
                  variant="unstyled"
                  rightIcon={
                    <ChevronDown
                      fontSize="16px"
                      transition="transform 0.3s ease"
                      transform={
                        displaySortModal ? 'rotate(180deg)' : 'rotate(0deg)'
                      }
                    />
                  }
                  onClick={toggleDisplaySortModal}
                  fontWeight="bold"
                  fontSize="sm"
                  color="black.primary3"
                  minWidth={{ base: '16px', lg: '10' }}
                  display="flex"
                  alignItems="center"
                >
                  <Text color="red.primary">
                    {t('explore-art:newsAndBlog.sortTypes.contentType')}
                    {': \u00A0'}
                  </Text>
                  {getActiveSortLabel()}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                background="white.primary"
                border="none"
                borderRadius="none"
                boxShadow="none"
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <PopoverBody p="0">
                  <Flex flexDirection="column" px="2.5">
                    {NEWS_AND_BLOG_SORT_FILTER_OPTIONS.map((option, index) => (
                      <Button
                        key={option.id}
                        onClick={() => handleOnClickSortButton(option.value)}
                        fontWeight="light"
                        color="black.primary3"
                        fontSize="sm"
                        variant="unstyled"
                        height="auto"
                        _hover={{
                          color: 'red.primary',
                        }}
                      >
                        {index === 0 && (
                          <Divider
                            borderBottomColor="white.primary3"
                            opacity="1"
                          />
                        )}
                        <Text py="4" textAlign="left">
                          {t(option.labelTx)}
                        </Text>
                        <Divider
                          borderBottomColor="white.primary3"
                          opacity="1"
                        />
                      </Button>
                    ))}
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Container>
      </Box>
    )
  }
)

export default NewsAndBlogSortBox
