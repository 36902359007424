import { Box, BoxProps } from '@chakra-ui/react'
import { Markup } from 'interweave'

interface IRichText extends BoxProps {
  htmlContent: string
}

const RichText = ({ htmlContent, ...rest }: IRichText) => (
  <Box textStyle="richText" {...rest}>
    {htmlContent && <Markup content={htmlContent} />}
  </Box>
)

export default RichText
