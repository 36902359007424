import drupalHttp from '../drupalHttpService'
import { PUBLICATIONS } from '@lib/const/api-endpoints.const'

/**
 * Publications Http Service
 */
export const PublicationsHttpService = {
  /**
   * Get all publications drupalHttp service
   */
  getAllPublications: async (locale: string, addUrlParams?: string) =>
    await drupalHttp.get(`${PUBLICATIONS[locale]}${addUrlParams || ''}`),
  /**
   * Get publication of page detail drupalHttp service
   */
  getPublication: async (locale: string, id: string) =>
    await drupalHttp.get(`${PUBLICATIONS[locale]}/${id}`),
}
