import { v4 as uuidv4 } from 'uuid'
import { IPublicationsSortFilterOption } from '@interfaces'

const PUBLICATIONS_SORT_FILTER_OPTIONS: IPublicationsSortFilterOption[] = [
  {
    id: uuidv4(),
    labelTx: 'explore-art:publications.sortTypes.allPublications',
    value: 'all-publications',
  },
  {
    id: uuidv4(),
    labelTx: 'explore-art:publications.sortTypes.exhibitionCatalogs',
    value: 'exhibition-catalogs',
  },
  {
    id: uuidv4(),
    labelTx: 'explore-art:publications.sortTypes.childrensPublications',
    value: 'childrens-publications',
  },
  {
    id: uuidv4(),
    labelTx: 'explore-art:publications.sortTypes.scientificPublications',
    value: 'scientific-publications',
  },
]

export default PUBLICATIONS_SORT_FILTER_OPTIONS
