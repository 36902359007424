import { ShopHttpService } from '@httpServices'
import { IDaysAndTicketsCard } from '@interfaces'
import { IEventCardTypes } from '@interfaces'

export const ShopService = {
  /**
   * Get product detail
   */
  getProductDetail: async (
    productId: number,
    locale: string | 'en' | 'tr',
    eMuseumType: IEventCardTypes
  ): Promise<IDaysAndTicketsCard> => {
    const productDetail: IDaysAndTicketsCard = {
      id: null,
      price: null,
      type: null,
      productName: null,
      productDetails: null,
      productPhoto: null,
      purchaseLink: null,
      stock: null,
    }

    const productDetailData = await ShopHttpService.getProductDetail(
      productId,
      locale
    )

    if (productDetailData.length > 0) {
      productDetail.id = productDetailData[0]?.nid[0]?.value || 0
      productDetail.price =
        (productDetailData[0]?.field_price.length > 0 &&
          productDetailData[0]?.field_price[0]?.value) ||
        0
      productDetail.type = eMuseumType
      productDetail.productName = productDetailData[0]?.title[0]?.value || ''

      if (productDetailData[0]?.field_image_link.length > 0) {
        productDetail.productPhoto =
          productDetailData[0]?.field_image_link[0]?.value || ''
      }

      if (productDetailData[0]?.field_shop_product_short_desc.length > 0) {
        productDetail.productDetails =
          productDetailData[0]?.field_shop_product_short_desc[0]?.value || ''
      }

      if (productDetailData[0]?.field_link.length > 0) {
        productDetail.purchaseLink =
          productDetailData[0]?.field_link[0]?.value || ''
      }

      if (productDetailData[0]?.field_shop_product_stock.length > 0) {
        productDetail.stock =
          productDetailData[0]?.field_shop_product_stock[0]?.value || null
      }
    } else {
      return null
    }

    return productDetail
  },
}
