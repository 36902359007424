import { useEffect } from 'react'
import { useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

/**
 * Framer motion with in view hooks
 * Example usage:
 * const [controls, inView, ref] = useInViewMotion()
 */
export const useInViewMotion = (
  triggerOnce = false,
  threshold: number | number[] = [0.15]
) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: threshold,
    triggerOnce: triggerOnce,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    } else {
      controls.start('hidden')
    }
  }, [controls, inView])

  return { controls, inView, ref }
}
