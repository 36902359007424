import { Container, Box, Text, Image } from '@chakra-ui/react'
import { Link, RichText, MotionImage } from '@ui'
import useTranslation from 'next-translate/useTranslation'

interface IGuidedToursForKids {
  tourDescriptionHtmlContent: string
  tourUrl: string
  stickyId: string
}

const GuidedToursForKids = ({
  tourDescriptionHtmlContent,
  tourUrl,
  stickyId = 'guided-tours-for-kids',
}: IGuidedToursForKids) => {
  const { t } = useTranslation()

  return (
    <Box
      as="section"
      py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      position="relative"
      id={stickyId}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex="-1"
      >
        <MotionImage
          imgUrl="/img/explore-art/kid/banner/shape-2.png"
          imgAlt="Shape"
          containerProps={{
            style: {
              position: 'absolute',
              top: '400px',
              left: '63px',
            },
          }}
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 2,
            },
          }}
          imageProps={{
            width: '68px',
          }}
        />

        <MotionImage
          imgUrl="/img/exhibition-and-event-detail/for-kids/kids-element-2.png"
          imgAlt="Shape"
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 4,
            },
          }}
          containerProps={{
            position: 'absolute',
            top: '280px',
            left: '-10px',
          }}
        />

        <MotionImage
          imgUrl="/img/exhibition-and-event-detail/for-kids/kids-element-3.png"
          imgAlt="Shape"
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 4,
            },
          }}
          containerProps={{
            position: 'absolute',
            top: '820px',
            right: '0px',
          }}
        />

        <MotionImage
          imgUrl="/img/explore-art/kid/banner/shape-5.png"
          imgAlt="Shape"
          containerProps={{
            style: {
              position: 'absolute',
              top: '0px',
              right: '4%',
            },
          }}
          imageProps={{
            width: '60px',
          }}
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 3,
            },
          }}
        />
      </Box>
      <Container maxWidth="1104px">
        <Box
          position="relative"
          maxWidth="820px"
          mx="auto"
          p={{ base: 'padding.primary', lg: 'padding.primary2' }}
          boxShadow="2xl"
          rounded="3xl"
          bgColor="white.primary"
        >
          <Image
            src="/img/explore-art/kid/photo-for-guided-tour.jpg"
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
            width="full"
            height="335px"
            objectFit="cover"
            alt="Guided Tour"
          />

          <Text
            fontFamily={'mikado'}
            as="h2"
            fontWeight="extrabold"
            fontSize={{ base: '15vw', lg: '4-5xl' }}
            lineHeight="none"
            textAlign="center"
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            mt="padding.primary"
          >
            <Text
              fontFamily={'mikado'}
              as="span"
              color="pink.primary2"
              position="relative"
              _before={{
                content: `""`,
                position: 'absolute',
                top: '40px',
                right: '-48px',
                width: '58px',
                height: '58px',
                backgroundImage:
                  'url("/img/explore-art/kid/custom-cursor-for-kids.png")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
            >
              {t('explore-art:kid.guidedTours.online')}
            </Text>
            <Text fontFamily={'mikado'} as="span" color="red.primary4">
              &nbsp;{t('explore-art:kid.guidedTours.guided')}
            </Text>
            <Text fontFamily={'mikado'} as="span" color="yellow.primary2">
              &nbsp;{t('explore-art:kid.guidedTours.museum')}
            </Text>
            <Text fontFamily={'mikado'} as="span" color="blue.primary2">
              &nbsp;{t('explore-art:kid.guidedTours.tours')}
            </Text>
          </Text>
        </Box>
      </Container>

      <Container
        mt="padding.primary2"
        maxWidth="container.secondary"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {tourDescriptionHtmlContent && (
          <RichText
            fontFamily="mikado"
            fontSize="md"
            textAlign="center"
            htmlContent={tourDescriptionHtmlContent}
          />
        )}
        {tourUrl && (
          <Link
            href={tourUrl}
            mt="padding.primary"
            textAlign="center"
            fontFamily="mikado"
            fontSize={{ base: 'lg', lg: 'xxl' }}
            fontWeight="bold"
            color="white.primary"
            bgColor="red.primary4"
            _hover={{
              bgColor: 'red.primary2',
            }}
            px="12"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="60px"
            borderRadius="48px"
          >
            {t('explore-art:kid.banner.tours')}
          </Link>
        )}
      </Container>
    </Box>
  )
}

export default GuidedToursForKids
