import drupalHttp from '../drupalHttpService'
import { PRESS_RELEASE } from '@lib/const/api-endpoints.const'

/**
 * Press Release Http Service
 */
export const PressReleasesHttpService = {
  /**
   * Get all press release drupalHttp service
   */
  getAllPressRelease: async (locale: string, addUrlParams?: string) =>
    await drupalHttp.get(`${PRESS_RELEASE[locale]}${addUrlParams || ''}`),
}
