import { createIcon } from '@chakra-ui/icons'

const ChevronDown = createIcon({
  displayName: 'ChevronDown',
  viewBox: '0 0 18 10',
  defaultProps: {
    fill: 'none',
  },
  path: <path d="M1 1.5L9 8.5L17 1.5" stroke="currentColor" strokeWidth="2" />,
})

export default ChevronDown
