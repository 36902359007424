import {
  Box,
  Flex,
  Container,
  Text,
  AspectRatio,
  Image,
} from '@chakra-ui/react'
import { BlogEmbededVideo } from '@common'
import { INewsAndBlogCard } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
// import { Markup } from 'interweave'
import Interweave from 'interweave'

function transform(node: HTMLElement): React.ReactNode {
  //console.log(node)
  if (node.tagName === 'IFRAME') {
    // console.log('one found')
    // return <Link href={node.getAttribute('href')}>{children}</Link>;
    return (
      <BlogEmbededVideo embedUrl={node.getAttribute('src')}></BlogEmbededVideo>
    )
  }

  if (node.className === 'bodyHeading') {
    // console.log('one found')
    // return <Link href={node.getAttribute('href')}>{children}</Link>;
    return (
      <Text
        fontFamily="spectral"
        fontWeight="700"
        font-style="medium"
        as="h2"
        mb="30px"
        fontSize={{ base: '24px', lg: '24px' }}
      >
        {node.textContent}
      </Text>
    )
  }
  if (node.className === 'imgCaption') {
    // console.log('one found')
    // return <Link href={node.getAttribute('href')}>{children}</Link>;
    return (
      <Text
        fontFamily="spectral"
        fontWeight="500"
        font-style="medium"
        as="sub"
        mb="5"
        lineHeight="1"
        textAlign="center"
        fontSize={{ base: '14px', lg: '14px' }}
      >
        {node.textContent}
        <br />
      </Text>
    )
  }

  if (node.className === 'sponsorshipInfo') {
    // console.log('one found')
    // return <Link href={node.getAttribute('href')}>{children}</Link>;
    return (
      <Text
        fontFamily="spectral"
        fontWeight="700"
        font-style="medium"
        as="h3"
        mb="0"
        fontSize={{ base: '22px', lg: '22px' }}
      >
        {node.textContent}
      </Text>
    )
  }
}

const NewsAndBlogContent = ({
  type,
  title,
  subtitle,
  author,
  date,
  description,
  imgUrl,
  bannerDisplay,
  containerProps,
  activeFontFamily = 'spectral',
}: Omit<INewsAndBlogCard, 'url'>) => {
  const { t } = useTranslation()
  const bd = bannerDisplay.toString()

  // console.log(subtitle)

  return (
    <Container
      maxWidth="container.secondary"
      px={{ base: 'padding.primary', lg: '0' }}
      {...containerProps}
    >
      <Flex flexDirection="column" alignItems="center">
        <Text
          textTransform="uppercase"
          as="h6"
          fontSize="14px"
          fontWeight="200"
          fontStyle="extralight"
          color="grey.primary"
          mb="2"
          letterSpacing="1px"
          fontFamily={activeFontFamily}
        >
          {t(`common:newsAndBlog.sortTypes.${type}`)}
        </Text>
        <Text
          fontFamily={activeFontFamily}
          fontWeight="bold"
          as="h1"
          mb="5"
          fontSize={{ base: '32px', lg: '32px' }}
        >
          {title}
        </Text>
        <Text
          fontFamily={activeFontFamily}
          fontWeight="bold"
          as="h1"
          mb="5"
          fontSize={{ base: '32px', lg: '32px' }}
        >
          {subtitle}
        </Text>
        <Flex mb={{ base: 'padding.primary', lg: 'padding.primary2' }}>
          {author && (
            <Text
              fontFamily={activeFontFamily}
              fontStyle="italic"
              as="h5"
              fontSize="22px"
              fontWeight="400"
            >
              {author}&nbsp;
            </Text>
          )}
        </Flex>
        <Flex
          textAlign="left"
          width="100%"
          mb={{ base: 'padding.primary', lg: 'padding.primary' }}
        >
          {date && (
            <Text
              fontFamily={activeFontFamily}
              as="h5"
              fontSize="12px"
              fontWeight="300"
              color="grey.primary"
            >
              {date}
            </Text>
          )}
        </Flex>
        <style jsx global>{`
          .css-kn1qn6 span > p {
            text-align: left;
            font-family: 'Spectral, serif';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
          }

          .css-kn1qn6 span > p {
            text-align: left;
            font-family: 'Spectral, serif';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
          }

          .css-kn1qn6 span > ul > li {
            margin-bottom: 8px;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            font-family: 'Spectral, serif';
          }

          .css-kn1qn6 > span > p.imgCaption {
            margin-bottom: 16px;
            text-align: center;
            font-family: 'Spectral, serif';
          }
        `}</style>
        <style jsx global>{`
          .css-kn1qn6 div > p {
            text-align: left;
            font-family: 'Spectral, serif';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
          }
        `}</style>

        {bd == 'top' && (
          <AspectRatio
            ratio={1.85 / 1}
            width="full"
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
          >
            <Image
              src={imgUrl}
              fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
              alt={title}
            />
          </AspectRatio>
        )}
        <Box
          sx={{
            mb: { base: 'padding.primary', lg: 'padding.primary2' },
            '& h3': {
              mb: { base: 1, lg: '0' },
            },
            '& h2': {
              mb: { base: 1, lg: '0' },
            },
            '& p': {
              fontWeight: 'light',
              fontSize: '20px',
              fontFamily: 'spectral',
              pb: '12px',
            },
            '& img': {
              my: { base: 'padding.primary', lg: 'padding.primary2' },
            },
          }}
        >
          <Interweave
            fontFamily={activeFontFamily}
            transform={transform}
            content={description}
          />
        </Box>

        {bd == 'bottom' && (
          <AspectRatio
            ratio={1.85 / 1}
            width="full"
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
          >
            <Image
              src={imgUrl}
              fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
              alt={title}
            />
          </AspectRatio>
        )}
      </Flex>
    </Container>
  )
}

export default NewsAndBlogContent
