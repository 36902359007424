import { Box, BoxProps, Container } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useStore } from '@store'
import { fadeIn } from '@lib/motion'
import { useInViewMotion } from '@lib/hooks'
import { MotionBox, MotionText, HeroAspectRatio } from '@ui'
import { IFonts } from '@interfaces'

import { useRouter } from 'next/router'

interface IHero {
  subTitle?: string
  title?: string
  description?: string
  bgImageUrl: string
  bgImageUrlMobile?: string
  mobileBgImageUrl?: string
  headerNav?: JSX.Element
  children?: JSX.Element
  /**
   * There is only one photo on hero component.
   */
  customHero?: boolean
  isCoveredBg?: boolean
  containerProps?: BoxProps
  activeFontFamily?: IFonts
}

//let thumbimgUrl = imgUrl.replace('full','thumbnail');

const Hero = observer(
  ({
    subTitle,
    title,
    description,
    bgImageUrl,
    bgImageUrlMobile,
    mobileBgImageUrl,
    headerNav,
    children,
    customHero,
    isCoveredBg = true,
    containerProps,
    activeFontFamily = 'body',
  }: IHero) => {
    const {
      ui: { displayBannerSlider },
    } = useStore()
    const { controls, ref } = useInViewMotion(true)

    // detect image origin
    const router = useRouter()

    if (bgImageUrl.includes('drupal')) {
      const domain = new URL(bgImageUrl).hostname.replace('www.', '')

      // console.log(domain)

      const isDrupal = domain.includes('drupal')

      // console.log(router.pathname)
      const EaE = router.pathname.includes('exhibitions-and-events')

      if (EaE && isDrupal) {
        let mBgImageUrl = bgImageUrl.replace('styles/webp', 'styles/eae_mobile')

        if (mobileBgImageUrl) {
          //console.log(mobileBgImageUrl)
          mBgImageUrl = mobileBgImageUrl
        }
        // console.log(mobileBgImageUrl)

        return (
          <HeroAspectRatio>
            <MotionBox
              width="full"
              height="full"
              backgroundImage={{
                sm: `url('${
                  mBgImageUrl === ''
                    ? '/img/visit/hero/ssm-visit.jpg'
                    : mBgImageUrl
                }')`,
                base: `url('${
                  bgImageUrl === ''
                    ? '/img/visit/hero/ssm-visit.jpg'
                    : mBgImageUrl
                }')`,
                lg: `url('${
                  bgImageUrl === ''
                    ? '/img/visit/hero/ssm-visit.jpg'
                    : bgImageUrl
                }')`,
              }}
              //         backgroundImage={`url('${
              //         bgImageUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : bgImageUrl
              //       }')`}
              backgroundPosition="center"
              backgroundSize={isCoveredBg ? 'cover' : 'cover'}
              backgroundRepeat="no-repeat"
              position="relative"
              zIndex={displayBannerSlider ? 5 : 3}
              py={{ base: 'padding.primary', lg: '8' }}
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={fadeIn.animationContainer}
              _before={{
                content: `" "`,
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                width: '100vw',
                height: '100%',
                background:
                  'linear-gradient(180deg, rgba(13, 16, 29, 0.9) 0%, rgba(13, 16, 29, 0) 50%)',
                zIndex: '-1',
              }}
              //_after={{
              //  content: `" "`,
              //  position: 'absolute',
              //top: 0,
              //right: 0,
              //bottom: 0,
              //width: '50vw',
              //height: '100%',
              //background:
              //  'linear-gradient(180deg, rgba(13, 16, 29, 0.9) 0%, rgba(13, 16, 29, 0) 50%)',
              //zIndex: '-1',
              //}}
              {...containerProps}
            >
              {headerNav && headerNav}
              <Container
                height={headerNav ? 'auto' : 'full'}
                display="flex"
                flexDirection={{ base: 'column', lg: 'row' }}
                position="relative"
              >
                <Box wordBreak="break-word">
                  {subTitle && (
                    <MotionText
                      fontFamily={activeFontFamily}
                      variants={fadeIn.up}
                      fontSize={{ base: '2xl', lg: '3xl' }}
                      color="red.primary"
                      as="h3"
                      mb={{ base: 1, lg: '0' }}
                    >
                      {subTitle}
                    </MotionText>
                  )}
                  {title && (
                    <MotionText
                      variants={fadeIn.up}
                      fontFamily={activeFontFamily}
                      fontSize={{ base: '3xl', lg: '5xl' }}
                      color="whiteAlpha.primary4"
                      fontWeight="bold"
                      lineHeight="normal"
                      as="h2"
                      mb={{ base: 1, lg: '6px' }}
                    >
                      {title}
                    </MotionText>
                  )}
                  {title && description && (
                    <MotionText
                      variants={fadeIn.up}
                      fontFamily={activeFontFamily}
                      fontSize="sm"
                      fontWeight="light"
                      color="white.primary"
                      maxW="610px"
                      whiteSpace="break-spaces"
                      noOfLines={{ base: 10, lg: 5 }}
                    >
                      {description}
                    </MotionText>
                  )}
                </Box>
              </Container>
              {children && children}
            </MotionBox>
          </HeroAspectRatio>
        )
      }
    }

    // if domain is drupal and current url is  then change mBgImageURl to EaE style
    // https://drupal.sakipsabancimuzesi.org/sites/muze.sabanciuniv.edu/files/styles/eae_mobile/public/2021-12/Etkinlik-banner-id-16.jpg.webp
    // https://drupal.sakipsabancimuzesi.org/sites/muze.sabanciuniv.edu/files/styles/webp/public/2021-10/Etkinlik-banner-id-4.jpg.webp

    if (customHero) {
      return (
        <HeroAspectRatio>
          <Box
            width="full"
            height="full"
            backgroundImage={{
              base: `url('${
                bgImageUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : bgImageUrl
              }')`,
              lg: `url('${
                bgImageUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : bgImageUrl
              }')`,
            }}
            //   backgroundImage={`url('${
            //   bgImageUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : bgImageUrl
            //  }')`}
            backgroundPosition="center"
            backgroundSize={isCoveredBg ? 'cover' : 'contain'}
            backgroundRepeat="no-repeat"
            position="relative"
            zIndex={displayBannerSlider ? 5 : 3}
            py={{ base: 'padding.primary2', lg: '8' }}
            _before={{
              content: `" "`,
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              background:
                'linear-gradient(180deg, rgba(13, 16, 29, 0.9) 0%, rgba(13, 16, 29, 0) 50%)',
            }}
            // _after={{
            //   content: `" "`,
            //   position: 'absolute',
            //   top: 0,
            //   right: 0,
            //   bottom: 0,
            //   width: '50vw',
            //   height: '100%',
            //   background:
            //     'linear-gradient(180deg, rgba(13, 16, 29, 0.9) 0%, rgba(13, 16, 29, 0) 50%)',
            // }}
            {...containerProps}
          />
        </HeroAspectRatio>
      )
    }

    console.log(bgImageUrlMobile)
    return (
      <HeroAspectRatio>
        <MotionBox
          width="full"
          height="full"
          backgroundImage={{
            base: `url('${
              bgImageUrlMobile === undefined || bgImageUrlMobile === ''
                ? bgImageUrl
                : bgImageUrlMobile
            }')`,
            lg: `url('${
              bgImageUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : bgImageUrl
            }')`,
            sm: `url('${
              bgImageUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : bgImageUrl
            }')`,
          }}
          //         backgroundImage={`url('${
          //          bgImageUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : bgImageUrl
          //       }')`}
          backgroundPosition="center"
          backgroundSize={isCoveredBg ? 'cover' : 'contain'}
          backgroundRepeat="no-repeat"
          position="relative"
          zIndex={displayBannerSlider ? 5 : 3}
          py={{ base: 'padding.primary', lg: '8' }}
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={fadeIn.animationContainer}
          _before={{
            content: `" "`,
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(180deg, rgba(13, 16, 29, 0.9) 0%, rgba(13, 16, 29, 0) 50%)',
            zIndex: '-1',
          }}
          // _after={{
          //   content: `" "`,
          //   position: 'absolute',
          //   top: 0,
          //   right: 0,
          //   bottom: 0,
          //   width: '50vw',
          //   height: '100%',
          //   background:
          //     'linear-gradient(180deg, rgba(13, 16, 29, 0.9) 0%, rgba(13, 16, 29, 0) 50%)',
          //   zIndex: '-1',
          // }}
          {...containerProps}
        >
          {headerNav && headerNav}
          <Container
            height={headerNav ? 'auto' : 'full'}
            display="flex"
            flexDirection={{ base: 'column', lg: 'row' }}
            position="relative"
          >
            <Box wordBreak="break-word">
              {title && (
                <MotionText
                  variants={fadeIn.up}
                  fontFamily={activeFontFamily}
                  fontSize={{ base: '3xl', lg: '5xl' }}
                  color="whiteAlpha.primary4"
                  opacity={0.8}
                  fontWeight="bold"
                  lineHeight="normal"
                  as="h2"
                  mb={{ base: 1, lg: '6px' }}
                >
                  {title}
                </MotionText>
              )}
              {title && description && (
                <MotionText
                  variants={fadeIn.up}
                  fontFamily={activeFontFamily}
                  fontSize="md"
                  fontWeight="light"
                  color="white.primary"
                  maxW="610px"
                  whiteSpace="break-spaces"
                  noOfLines={{ base: 10, lg: 5 }}
                >
                  {description}
                </MotionText>
              )}
            </Box>
          </Container>
          {children && children}
        </MotionBox>
      </HeroAspectRatio>
    )
  }
)

export default Hero
