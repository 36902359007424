// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { SENTRY_DSN, IS_PRODUCTION } from './src/lib/config'

if (IS_PRODUCTION) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://d0c3b037fdeb400f9bae6b07539d0e94@o995106.ingest.sentry.io/5953887',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
