import { v4 as uuidv4 } from 'uuid'
import { ExhibitionService } from '../services'
import { getCurrentLocalePropFromAPI, createNavLink } from '../utils'

export const generateArtollectionWorkDetailAccordionItems = async (
  objectData,
  locale
) => {
  const accordionItems = []

  // referans
  if (objectData?.pubreferences) {
    const accordionObject = {
      id: uuidv4(),
      accordionTitleTx: 'common:objectOfCollection.reference',
      accordionContent: [
        {
          id: uuidv4(),
          contentDetail: objectData?.pubreferences?.value || '',
        },
      ],
    }

    accordionItems.push(accordionObject)
  }

  // further readings
  if (objectData?.edition) {
    const accordionObject = {
      id: uuidv4(),
      accordionTitleTx: 'common:objectOfCollection.furtherReadings',
      accordionContent: [
        {
          id: uuidv4(),
          contentDetail: objectData?.edition?.value || '',
        },
      ],
    }

    accordionItems.push(accordionObject)
  }

  // exhibition history
  if (objectData?.exhibitions) {
    const exhibitionSourceIds = []
    const isSingleExhibition =
      typeof objectData?.exhibitions?.value === 'string'

    if (isSingleExhibition) {
      exhibitionSourceIds.push(objectData?.exhibitions?.value)
    } else {
      objectData?.exhibitions?.value.map((exhibition) =>
        exhibitionSourceIds.push(exhibition)
      )
    }

    const exhibitionAccordionItems = await Promise.all(
      exhibitionSourceIds.map(async (exihibition) => {
        try {
          const exgihibitionSourceId = exihibition.split('/')
          const exhibitionDetail = await ExhibitionService.getExhibitionDetail(
            exgihibitionSourceId[1]
          )

          const exhibitionTitle =
            (exhibitionDetail.exhibition[0]?.exhTitle &&
              getCurrentLocalePropFromAPI(
                exhibitionDetail?.exhibition[0]?.exhTitle?.value,
                locale
              )) ||
            ''

          const exhibitionurL =
            `${createNavLink('exhibition', locale)}/${
              exgihibitionSourceId[1]
            }` || ''

          const exhibitionAnchorHtml = `<a href='${exhibitionurL}' style="text-decoration: underline;">${exhibitionTitle}</a>`

          return {
            id: uuidv4(),
            contentDetail: exhibitionAnchorHtml || '',
          }
        } catch (error) {
          return {
            id: uuidv4(),
            contentDetail: exihibition || '',
          }
        }
      })
    )

    const accordionObject = {
      id: uuidv4(),
      accordionTitleTx: 'common:objectOfCollection.exhibitionHistory',
      accordionContent: exhibitionAccordionItems,
    }

    accordionItems.push(accordionObject)
  }

  return accordionItems
}
