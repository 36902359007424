import {
  Flex,
  TabList as ChakraTabList,
  Tab,
  useBreakpointValue,
  Select,
} from '@chakra-ui/react'
import { IArtCollection, ArtCollectionType } from '@interfaces'

interface ITabList {
  tabListCollection: Omit<IArtCollection, 'description' | 'collection'>[]
  handleSelectChange: (index: string) => void
  activePage: ArtCollectionType
  handleTabItemButton: () => void
}

const TabList = ({
  tabListCollection,
  handleSelectChange,
  activePage,
  handleTabItemButton,
}: ITabList) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const element = event.target as HTMLSelectElement
    handleSelectChange(element.value)
  }

  return (
    <ChakraTabList
      w="full"
      maxW={{ base: 'full', lg: '60' }}
      borderBottom="none"
      mb={{ base: 'padding.primary', lg: '0' }}
    >
      {isDesktop ? (
        <Flex
          flexDir="column"
          alignItems="flex-start"
          alignSelf="flex-start"
          position="sticky"
          top="65px"
          sx={{
            '&>*+*': {
              mt: '10',
            },
          }}
        >
          {tabListCollection.map((tab) => (
            <Tab
              key={tab.id}
              textAlign="left"
              fontSize="lg"
              fontWeight={tab.type === activePage ? 'medium' : 'normal'}
              mb="0"
              mr="0"
              color={
                tab.type === activePage ? 'black.primary3' : 'grey.primary2'
              }
              borderBottom="none"
              sx={{
                '&[aria-selected=true]': {
                  color: 'red.primary',
                },
                '&:focus': {
                  boxShadow: 'none',
                },
              }}
              onClick={handleTabItemButton}
            >
              {tab.title}
            </Tab>
          ))}
        </Flex>
      ) : (
        <Select onChange={handleChange}>
          {tabListCollection.map((tab, index) => (
            <option key={tab.id} value={index}>
              {tab.title}
            </option>
          ))}
        </Select>
      )}
    </ChakraTabList>
  )
}

export default TabList
