import { Button } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Cross } from '@icon'

interface IClearTagOfNew {
  onClick: () => void
}

const ClearTagOfNew = ({ onClick }: IClearTagOfNew) => {
  const { t } = useTranslation()

  return (
    <Button onClick={onClick} leftIcon={<Cross />} alignSelf="flex-start">
      {t('common:clearSelections')}
    </Button>
  )
}

export default ClearTagOfNew
