import { createIcon } from '@chakra-ui/icons'

const ListViewRow = createIcon({
  displayName: 'ListViewRow',
  viewBox: '0 0 22 19',
  defaultProps: {
    fill: 'none',
    width: '22px',
    height: '19px',
  },
  path: (
    <>
      <path d="M4 1.5H22" stroke="currentColor" strokeWidth="2" />
      <path d="M0 1.5H2" stroke="currentColor" strokeWidth="2" />
      <path d="M4 9.5H22" stroke="currentColor" strokeWidth="2" />
      <path d="M0 9.5H2" stroke="currentColor" strokeWidth="2" />
      <path d="M4 17.5H22" stroke="currentColor" strokeWidth="2" />
      <path d="M0 17.5H2" stroke="currentColor" strokeWidth="2" />
    </>
  ),
})

export default ListViewRow
