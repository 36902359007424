import { createNavLink, getCurrentLocaleByObjectTitle } from './'
import { IRelatedObjtectsPageNames } from '@interfaces'

export const generateSelectionsFromCollectionDataFrom = (
  collectionSourceId,
  objectData,
  pageType: IRelatedObjtectsPageNames,
  locale: string
) => {
  return objectData.map((object) => ({
    id: object?.sourceId?.value,
    people: object?.portfolio?.value ?? null,
    artist: object?.people?.value ?? null,
    imgUrl: (object?.primaryMedia && object?.primaryMedia?.value) || '',
    title: getCurrentLocaleByObjectTitle(object, locale),
    name: locale === 'tr' ? 'Detaylı İncele' : 'Detailed Review',
    url: `${createNavLink(
      pageType === 'archive'
        ? 'archives'
        : pageType === 'collection'
        ? 'collections'
        : 'exhibition',
      locale
    )}/${collectionSourceId}/${object?.sourceId?.value}`,
  }))
}
