import { Layout, ContentNotFound } from '@common'
import { ISSRPageData } from '@interfaces'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Container, Flex, Text } from '@chakra-ui/react'
import { RichText } from '@ui'

export const PageWithHeroTextTemplate = ({
  pageData,
  global: { navigation },
}: ISSRPageData) => {
  const { asPath } = useRouter()
  const { t } = useTranslation()

  if (pageData.error) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound infoType="serverSideError" />
      </Layout>
    )
  }

  return (
    <Layout
      title={pageData?.data?.pageTitle || t('common:ssmPageTitle')}
      navigation={navigation}
      path={asPath}
    >
      <Container
        as="section"
        pt={{ base: '65px', lg: '70px' }}
        pb={{ base: 'padding.primary2', lg: '20' }}
      >
        <Text
          as="h2"
          fontSize={{ base: '2xl', lg: '3xl' }}
          color="red.primary"
          mb={{ base: 1, lg: '6px' }}
        >
          {pageData.data.subTitle}
        </Text>
        <Text
          as="h1"
          fontSize={{ base: '4xl', lg: '5xl' }}
          color="white.primary3"
          lineHeight="shorter"
          fontWeight="bold"
          mb={{ base: 1, lg: '6px' }}
        >
          {pageData.data.title}
        </Text>
        <Text
          fontSize="md"
          fontWeight="light"
          color="black.primary5"
          maxW="610px"
          whiteSpace="break-spaces"
        >
          {pageData.data.description}
        </Text>
      </Container>
      <Flex
        flexDirection="column"
        w="full"
        maxW="container.secondary"
        mx="auto"
        py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
        px={{ base: 'padding.primary', lg: '0' }}
      >
        {pageData.data.htmlContent && (
          <RichText htmlContent={pageData.data.htmlContent} />
        )}
      </Flex>
    </Layout>
  )
}
