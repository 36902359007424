const ease = [0.83, 0, 0.17, 1]

const sliderMotion = {
  hidden: {
    transition: {
      duration: 0.75,
      ease: ease,
    },
    opacity: 1,
    x: '0%',
  },
  visible: {
    staggerChildren: 1,
    transition: {
      duration: 0.75,
      ease: ease,
    },
    opacity: 1,
    x: '0px',
  },
  exit: {
    transition: {
      duration: 1.5,
      ease: ease,
    },
    x: '0%',
  },
}

const imagesOnDrawerMenu = {
  enter: {
    transition: {
      duration: 0.5,
      ease: ease,
    },
    opacity: 0,
    x: '100%',
  },
  center: {
    transition: {
      duration: 0.3,
      ease: ease,
      when: 'beforeChildren',
      delay: 0.3,
    },
    opacity: 1,
    x: '0px',
  },
  exit: {
    transition: {
      duration: 0.5,
      ease: ease,
    },
    x: '100%',
  },
}

export const sideMenuAnim = {
  sliderMotion,
  imagesOnDrawerMenu,
}
