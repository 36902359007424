import { createIcon } from '@chakra-ui/icons'

const ChevronUp = createIcon({
  displayName: 'ChevronUp',
  viewBox: '0 0 18 11',
  defaultProps: {
    fill: 'none',
    transition: 'all 0.3s ease',
  },
  path: <path d="M17 10L9 2L1 10" stroke="currentColor" strokeWidth="2" />,
})

export default ChevronUp
