export const prepareQrReaderLink = (result) => {
  const internRoute = {
    mainRouteKey: null,
    additionalParams: null,
  }

  try {
    const parsedResult = JSON.parse(result)

    if (typeof parsedResult === 'object') {
      if (parsedResult['mainRouteKey']) {
        internRoute.mainRouteKey = parsedResult['mainRouteKey']
      }

      if (parsedResult['additionalParams']) {
        internRoute.additionalParams = parsedResult['additionalParams']
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      console.log({ qrCodeParseError: err.message })
    }
  }

  return internRoute
}
