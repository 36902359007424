import { Flex, Text } from '@chakra-ui/react'
import { IEventInformationCard } from '@interfaces'
import {
  ListeningAudioGuide,
  OnlineExhibitionMap,
  Exhibition,
  Union,
  Learning,
  Article,
} from '@icon'

const EventInformationCard = ({
  type,
  title,
  description,
  children,
  containerProps,
  primaryColor = 'red',
  activeFontFamily = 'body',
}: IEventInformationCard) => {
  const getCurrentIcon = () => {
    switch (type) {
      case 'AudioGuide':
        return (
          <ListeningAudioGuide
            width="66.82px"
            height="61.04px"
            color={`${primaryColor}.primary`}
          />
        )

      case 'OnlineExhibitionMap':
        return (
          <OnlineExhibitionMap
            width="66.82px"
            height="61.04px"
            color={`${primaryColor}.primary`}
          />
        )

      case 'Exhibitions':
        return (
          <Exhibition
            width="66.82px"
            height="61.04px"
            color={`${primaryColor}.primary`}
          />
        )
      case 'Online':
        return (
          <Exhibition
            width="66.82px"
            height="61.04px"
            color={`${primaryColor}.primary`}
          />
        )
      case 'Events':
        return (
          <Union
            width="66.82px"
            height="61.04px"
            color={`${primaryColor}.primary`}
          />
        )
      case 'Workshops':
        return (
          <Learning
            width="66.82px"
            height="61.04px"
            color={`${primaryColor}.primary`}
          />
        )

      case 'Learning':
        return (
          <Learning
            width="66.82px"
            height="61.04px"
            color={`${primaryColor}.primary`}
          />
        )

      case 'Article':
        return (
          <Article
            width="66.82px"
            height="61.04px"
            color={`${primaryColor}.primary`}
          />
        )

      default:
        return (
          <ListeningAudioGuide
            width="66.82px"
            height="61.04px"
            color={`${primaryColor}.primary`}
          />
        )
    }
  }

  return (
    <Flex
      _hover={{ transform: 'translateY(-5px)' }}
      transition="all 0.3s ease"
      flexDirection="column"
      maxWidth="315px"
      pt={{ base: '0', lg: '20' }}
      alignItems="center"
      justifyContent="center"
      {...containerProps}
    >
      <Flex mb="5">{getCurrentIcon()}</Flex>
      <Text
        textAlign="center"
        fontSize="lg"
        fontFamily={activeFontFamily}
        color={`${primaryColor}.primary`}
        mb="2.5"
        noOfLines={2}
      >
        {title}
      </Text>
      {description && (
        <Text
          textAlign="center"
          fontFamily={activeFontFamily}
          fontWeight="light"
          fontSize="sm"
          noOfLines={3}
        >
          {description}
        </Text>
      )}
      {children}
    </Flex>
  )
}

export default EventInformationCard
