import { Box, AspectRatio, Image, Text } from '@chakra-ui/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { INewsAndBlogSliderItem } from '@interfaces'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

interface INewsAndBlogSliderSection {
  sliderCollection: INewsAndBlogSliderItem[]
}

const NewsAndBlogSliderSection = ({
  sliderCollection,
}: INewsAndBlogSliderSection) => (
  <Box
    className="newsAndBlogSlider"
    mt="padding.primary"
    sx={{
      '&.newsAndBlogSlider .swiper-container': {
        pl: { base: 'padding.primary', lg: '0' },
        pb: { base: '40px', lg: '0' },
      },
      '&.newsAndBlogSlider .swiper-slide': {
        width: '300px',
      },
      '&.newsAndBlogSlider .swiper-pagination-bullet.swiper-pagination-bullet-active':
        {
          backgroundColor: 'red.primary',
          transition: 'all 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
        },
      '&.newsAndBlogSlider .swiper-pagination-bullet': {
        opacity: 1,
        width: '102px',
        height: '2px',
        borderRadius: 'none',
        backgroundColor: 'blackAlpha.primary10',
        transition: 'all 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
      },
      '&.newsAndBlogSlider .swiper-pagination': {
        display: { base: 'block', lg: 'none' },
      },
    }}
  >
    <Swiper
      slidesPerView="auto"
      centeredSlides={true}
      initialSlide={1}
      spaceBetween={20}
    >
      {sliderCollection.map((slide) => (
        <SwiperSlide key={slide.id}>
          <AspectRatio width="full" mb="2.5" ratio={4 / 5}>
            <Image
              src={slide.imgUrl}
              fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
              alt={slide.title}
            />
          </AspectRatio>
          <Text fontWeight="medium">{slide.title + ' ' + slide.subtitle}</Text>
        </SwiperSlide>
      ))}
      <Box className="swiper-pagination" />
    </Swiper>
  </Box>
)

export default NewsAndBlogSliderSection
