import { createIcon } from '@chakra-ui/icons'

const ChevronRight = createIcon({
  displayName: 'ChevronRight',
  viewBox: '0 0 10 18',
  defaultProps: {
    width: '10px',
    height: '18px',
    fill: 'none',
  },
  path: <path d="M1 17L8 9L0.999999 1" stroke="currentColor" strokeWidth="2" />,
})

export default ChevronRight
