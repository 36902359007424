import {
  Box,
  Flex,
  Text,
  Divider,
  Input,
  useBreakpointValue,
  FormControl,
  FormLabel,
  Button,
} from '@chakra-ui/react'
import { useStore } from '@store'
import { ClearSelectionsButton, FilterButton, Select } from '@ui'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import DatePicker from 'react-datepicker'
import { CollapseBox } from '@motion'
import { ChevronUp, ChevronDown } from '@icon'
import { POPULAR_COLLECTION_SEARCH_TEARMS } from '@/src/lib/const/popularCollectionSearchTerms.const'
import { useRouter } from 'next/router'

interface ICollectionSearchFilterBox {
  handleSearchAction: () => void
}

const CollectionSearchFilterBox = observer(
  ({ handleSearchAction }: ICollectionSearchFilterBox) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const { t } = useTranslation()
    const {
      artSearch: {
        displayFilterModal,
        onCloseModal,
        searchTitle,
        setSearchTitle,
        beginDate,
        setBeginDate,
        endDate,
        setEndDate,
        displayBeginDate,
        displayEndDate,
        toggleDisplayBeginDate,
        toggleDisplayEndDate,
        selectedInCollectionOptions,
        setSelectedInCollectionOptions,
        searchArtist,
        searchInvNo,
        searchDescription,
        setSearchArtist,
        setSearchInvNo,
        setSearchDescription,
        clearAllFilterOnAdvancedFilter,
      },
    } = useStore()

    const { locale } = useRouter()

    if (!isDesktop && !displayFilterModal) {
      return null
    }

    return (
      <Box
        width="full"
        maxWidth={{ base: 'full', lg: '345px' }}
        minHeight="100vh"
        maxHeight="100vh"
        background="white.primary"
        position={{ base: 'static', lg: 'absolute' }}
        top={{ base: 'auto', lg: '0' }}
        left={{ base: 'auto', lg: '0' }}
        display="flex"
        flexDirection="column"
        sx={{
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: 'white.primary3',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'red.primary',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: 'red.primary3',
          },
        }}
      >
        <Box
          minHeight="65px"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          px="padding.primary"
          py="3"
        >
          <FilterButton
            containerProps={{ color: 'black.primary3' }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          borderBottomColor="white.primary3"
          opacity="1"
          width="calc(100% - 60px)"
          mx="auto"
        />
        <Box
          pb="5"
          flex={1}
          overflowY="auto"
          sx={{
            '::-webkit-scrollbar': {
              width: '5px',
            },
            '::-webkit-scrollbar-track': {
              background: 'white.primary3',
            },
            '::-webkit-scrollbar-thumb': {
              background: 'red.primary',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: 'red.primary3',
            },
          }}
        >
          {/* title ( input ) only mobile */}
          {!isDesktop && (
            <>
              <Divider
                borderBottomColor="white.primary3"
                opacity="1"
                width="calc(100% - 60px)"
                mx="auto"
              />
              <FormControl id="title" py="5" px="padding.primary">
                <FormLabel fontWeight="medium" fontSize="sm" mb="2">
                  {t('collections-and-research:search.titleInputLabel')}
                </FormLabel>
                <Input
                  px="10px"
                  border="1px solid"
                  borderColor="black.primary3"
                  variant="secondary"
                  size="secondary"
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
              </FormControl>
            </>
          )}

          {/* people ( input ) */}
          <Divider
            borderBottomColor="white.primary3"
            opacity="1"
            width="calc(100% - 60px)"
            mx="auto"
          />
          <FormControl id="artistCreator" py="5" px="padding.primary">
            <FormLabel fontWeight="medium" fontSize="sm" mb="2">
              {t('collections-and-research:search.artistCreator')}
            </FormLabel>
            <Input
              px="10px"
              border="1px solid"
              borderColor="black.primary3"
              variant="secondary"
              size="secondary"
              value={searchArtist}
              onChange={(e) => setSearchArtist(e.target.value)}
            />
          </FormControl>

          {/* invo ( input ) */}
          <Divider
            borderBottomColor="white.primary3"
            opacity="1"
            width="calc(100% - 60px)"
            mx="auto"
          />
          <FormControl id="objectNumber" py="5" px="padding.primary">
            <FormLabel fontWeight="medium" fontSize="sm" mb="2">
              {t('collections-and-research:search.objectNumber')}
            </FormLabel>
            <Input
              px="10px"
              border="1px solid"
              borderColor="black.primary3"
              variant="secondary"
              size="secondary"
              value={searchInvNo}
              onChange={(e) => setSearchInvNo(e.target.value)}
            />
          </FormControl>

          {/* description ( input ) */}
          <Divider
            borderBottomColor="white.primary3"
            opacity="1"
            width="calc(100% - 60px)"
            mx="auto"
          />
          <FormControl id="description" py="5" px="padding.primary">
            <FormLabel fontWeight="medium" fontSize="sm" mb="2">
              {t('collections-and-research:search.description')}
            </FormLabel>
            <Input
              px="10px"
              border="1px solid"
              borderColor="black.primary3"
              variant="secondary"
              size="secondary"
              value={searchDescription}
              onChange={(e) => setSearchDescription(e.target.value)}
            />
          </FormControl>

          {/* begin date - end date ( dropdown ) */}
          <Divider
            borderBottomColor="white.primary3"
            opacity="1"
            width="calc(100% - 60px)"
            mx="auto"
          />
          <Text
            fontWeight="medium"
            fontSize="sm"
            mb="2"
            px="padding.primary"
            pt="5"
          >
            {t('collections-and-research:search.dateRange')}
          </Text>

          <Flex
            flexDirection="row"
            sx={{
              '& .react-datepicker-wrapper input': {
                height: '50px',
                border: '1px solid',
                borderColor: 'black.primary3',
                width: 'calc(100% - 10px)',
                px: '2.5',
              },
              '& .react-datepicker': {
                fontFamily: 'body',
                borderColor: 'black.primary',
                borderRadius: '0px',
              },
              '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before':
                {
                  borderBottomColor: 'black.primary',
                },
              '& .react-datepicker__header': {
                backgroundColor: 'white.primary2',
                borderBottomColor: 'white.primary3',
              },
              '& .react-datepicker__navigation--previous': {
                borderRightColor: 'black.primary',
              },
              '& .react-datepicker__navigation--next': {
                borderLeftColor: 'black.primary',
              },
              '& .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected, .react-datepicker__year-text--selected':
                {
                  backgroundColor: 'red.primary',
                },
            }}
          >
            <Box
              pb="5"
              pl="padding.primary"
              display="inline-block"
              width="full"
            >
              <Button
                width="calc(100% - 5px)"
                variant="outline"
                onClick={toggleDisplayBeginDate}
                fontSize="9px"
                rightIcon={displayBeginDate ? <ChevronUp /> : <ChevronDown />}
              >
                {t(
                  `collections-and-research:search.${
                    displayBeginDate ? 'hideBeginDate' : 'showBeginDate'
                  }`
                )}
              </Button>
              <CollapseBox displayChildren={displayBeginDate}>
                <Box mt="2">
                  <Text fontWeight="medium" fontSize="sm" mb="2">
                    {t('collections-and-research:search.beginDate')}
                  </Text>
                  <DatePicker
                    selected={beginDate}
                    onChange={(date) => setBeginDate(date)}
                    showYearPicker
                    dateFormat="yyyy"
                    maxDate={new Date()}
                  />
                </Box>
              </CollapseBox>
            </Box>
            <Box
              pb="5"
              pr="padding.primary"
              display="inline-block"
              width="full"
            >
              <Button
                width="calc(100% - 5px)"
                variant="outline"
                onClick={toggleDisplayEndDate}
                fontSize="9px"
                rightIcon={displayEndDate ? <ChevronUp /> : <ChevronDown />}
              >
                {t(
                  `collections-and-research:search.${
                    displayEndDate ? 'hideEndDate' : 'showEndDate'
                  }`
                )}
              </Button>
              <CollapseBox displayChildren={displayEndDate}>
                <Box mt="2">
                  <Text fontWeight="medium" fontSize="sm" mb="2">
                    {t('collections-and-research:search.endDate')}
                  </Text>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    showYearPicker
                    dateFormat="yyyy"
                    maxDate={new Date()}
                  />
                </Box>
              </CollapseBox>
            </Box>
          </Flex>

          {/* inCollection ( dropdown ) */}
          <Divider
            borderBottomColor="white.primary3"
            opacity="1"
            width="calc(100% - 60px)"
            mx="auto"
          />
          <Box py="5" px="padding.primary">
            <Text fontWeight="medium" fontSize="sm" mb="2">
              {t('collections-and-research:search.inCollection')}
            </Text>
            <Select
              variant="secondary"
              value={selectedInCollectionOptions}
              onChange={(e) => setSelectedInCollectionOptions(e.target.value)}
            >
              <option value="All">
                {t('collections-and-research:search.inCollectionOptions.all')}
              </option>
              {POPULAR_COLLECTION_SEARCH_TEARMS[locale].map(
                ({ displayTerm, inCollection }, index) => (
                  <option key={`collection-${index}`} value={inCollection}>
                    {displayTerm}
                  </option>
                )
              )}
            </Select>
          </Box>
        </Box>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          bgColor="red.primary"
          px="padding.primary"
          py="5"
        >
          <ClearSelectionsButton
            onClick={clearAllFilterOnAdvancedFilter}
            buttonLabelTx="collections-and-research:search.clearFilterButtonLabel"
          />

          {!isDesktop && (
            <Button
              onClick={handleSearchAction}
              fontSize="sm"
              fontWeight="medium"
              _hover={{
                bgColor: 'red.primary3',
              }}
              variant="solid"
            >
              {t(
                'collections-and-research:collections.heroSearchCard.searchButtonLabel'
              )}
            </Button>
          )}
        </Flex>
      </Box>
    )
  }
)

export default CollectionSearchFilterBox
