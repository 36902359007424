const MikadoFontFace = () => (
  <style jsx global>
    {`
      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoBold-Italic.woff2') format('woff2'),
          url('/fonts/MikadoBold-Italic.woff') format('woff');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoBold.woff2') format('woff2'),
          url('/fonts/MikadoBold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoLight.woff2') format('woff2'),
          url('/fonts/MikadoLight.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoMedium-Italic.woff2') format('woff2'),
          url('/fonts/MikadoMedium-Italic.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoLight-Italic.woff2') format('woff2'),
          url('/fonts/MikadoLight-Italic.woff') format('woff');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoBlack.woff2') format('woff2'),
          url('/fonts/MikadoBlack.woff') format('woff');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoBlack-Italic.woff2') format('woff2'),
          url('/fonts/MikadoBlack-Italic.woff') format('woff');
        font-weight: 800;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoRegular-Italic.woff2') format('woff2'),
          url('/fonts/MikadoRegular-Italic.woff') format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoRegular.woff2') format('woff2'),
          url('/fonts/MikadoRegular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Mikado';
        src: url('/fonts/MikadoMedium.woff2') format('woff2'),
          url('/fonts/MikadoMedium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
    `}
  </style>
)

export default MikadoFontFace
