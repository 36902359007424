import {
  PhotoAndVideoSliderSection,
  AccordionSection,
  AddressContactAndTransportation,
} from '@common'
import VisitingDaysSection from '../../components/common/VisitingDaysSection'
import SectionWithCustomHtml from '../../components/common/SectionWithCustomHtml'
import TwoColBlockSection from '../../components/common/TwoColBlockSection'
import SectionPlant from '../../components/common/SectionPlant'

// Map drupal tabs to section components
export const sectionComponents = {
  'sections.block': TwoColBlockSection,
  'sections.accordion': AccordionSection,
  'sections.gallery': PhotoAndVideoSliderSection,
  'sections.html': SectionWithCustomHtml,
  'sections.htmlwithvisitinghour': VisitingDaysSection,
  'sections.contact_info': AddressContactAndTransportation,
  'sections.plantlist': SectionPlant,
}
