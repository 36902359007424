import {
  ContentNotFound,
  Layout,
  SpectralFontFace,
  OnlineExhibitionHero,
  StickyHeader,
  PhotoAndVideoSliderSection,
  AboutTheArtistExhibitionAndEvent,
  AboutExhibitionOnline,
  PeriodsAndArtifacts,
  CatalogExhibitionAndEvent,
  ConferencesExhibitionAndEvent,
  OnlineExhibitionForKidsSection,
  RelatedEvents,
} from '@common'
import { ISSRPageData } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Box } from '@chakra-ui/react'
import {
  checkStickyNavItem,
  getStickyId,
  getSectionData,
  getSectionTitle,
} from '@utils'

export const OnlineExhibitionTemplate = ({
  pageData,
  global: { navigation },
}: ISSRPageData) => {
  const { t } = useTranslation()
  const { asPath } = useRouter()

  if (pageData.error) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound />
      </Layout>
    )
  }

  return (
    <Layout
      title={pageData.data.pageTitle}
      navigation={navigation}
      path={asPath}
      activeFontFamily="spectral"
    >
      <SpectralFontFace />
      <OnlineExhibitionHero
        mobileImgUrl={pageData?.data?.hero?.mobileImgUrl || ''}
        desktopImgUrl={pageData?.data?.hero?.desktopImgUrl || ''}
      />
      {pageData.data.stickyNavItems &&
        pageData.data.stickyNavItems.length > 0 && (
          <Box as="main" pt={4}>
            <StickyHeader
              navs={pageData.data.stickyNavItems}
              activeFontFamily="spectral"
            />
            {checkStickyNavItem(
              pageData.data.stickyNavItems,
              'storyOfTheExhibition'
            ) &&
              pageData.data.storyOfTheExhibition && (
                <PhotoAndVideoSliderSection
                  stickyId={getStickyId(
                    pageData.data.stickyNavItems,
                    'storyOfTheExhibition'
                  )}
                  sectionTitle={getSectionTitle(
                    pageData.data.stickyNavItems,
                    'storyOfTheExhibition'
                  )}
                  data={getSectionData(pageData, 'storyOfTheExhibition')}
                  activeFontFamily={'spectral'}
                  my="0"
                />
              )}
            {checkStickyNavItem(
              pageData.data.stickyNavItems,
              'aboutTheArtist'
            ) &&
              pageData.data.aboutTheArtist?.artistName && (
                <AboutTheArtistExhibitionAndEvent
                  stickyId={getStickyId(
                    pageData.data.stickyNavItems,
                    'aboutTheArtist'
                  )}
                  artistName={pageData?.data?.aboutTheArtist?.artistName}
                  artistLifeTimeRange={
                    pageData?.data?.aboutTheArtist?.artistLifeTimeRange
                  }
                  artistLife={pageData?.data?.aboutTheArtist?.artistLife}
                  artistQuote={pageData?.data?.aboutTheArtist?.artistQuote}
                  artistLifeAboutPdfLink={
                    pageData?.data?.aboutTheArtist?.artistPdfLink
                  }
                  artistPhotos={pageData?.data?.aboutTheArtist?.artistPhotos}
                  activeFontFamily="spectral"
                />
              )}

            {checkStickyNavItem(
              pageData.data.stickyNavItems,
              'aboutTheExhibition'
            ) &&
              pageData.data.aboutTheExhibition?.exhibitionTitle !== '' && (
                <AboutExhibitionOnline
                  stickyId={getStickyId(
                    pageData.data.stickyNavItems,
                    'aboutTheExhibition'
                  )}
                  title={getSectionTitle(
                    pageData.data.stickyNavItems,
                    'aboutTheExhibition'
                  )}
                  descriptions={
                    pageData?.data?.aboutTheExhibition?.exhibitionDescription
                  }
                  videoUrl={
                    pageData?.data?.aboutTheExhibition?.exhibitionVideoUrl
                  }
                  activeFontFamily={'spectral'}
                />
              )}

            {checkStickyNavItem(
              pageData.data.stickyNavItems,
              'periodsArtifacts'
            ) &&
              pageData.data.periodsArtifacts &&
              pageData.data.periodsArtifacts.length > 0 && (
                <PeriodsAndArtifacts
                  sectionTitle={getSectionTitle(
                    pageData.data.stickyNavItems,
                    'periodsArtifacts'
                  )}
                  stickyId={getStickyId(
                    pageData.data.stickyNavItems,
                    'periodsArtifacts'
                  )}
                  collection={pageData?.data?.periodsArtifacts}
                  activeFontFamily={'spectral'}
                />
              )}

            {checkStickyNavItem(pageData.data.stickyNavItems, 'catalog') &&
              pageData.data.catalog && (
                <CatalogExhibitionAndEvent
                  stickyId={getStickyId(
                    pageData.data.stickyNavItems,
                    'catalog'
                  )}
                  catalogPdfLink={pageData.data.catalog?.catalogPdfLink}
                  catalogName={pageData.data.catalog?.catalogName}
                  catalogDescriptions={
                    pageData.data.catalog?.catalogDescriptions
                  }
                  catalogImgUrl={pageData.data.catalog?.catalogImgUrl}
                  activeFontFamily={'spectral'}
                />
              )}

            {checkStickyNavItem(pageData.data.stickyNavItems, 'conferences') &&
              pageData.data.conferences && (
                <ConferencesExhibitionAndEvent
                  stickyId={getStickyId(
                    pageData.data.stickyNavItems,
                    'conferences'
                  )}
                  sectionTitle={getSectionTitle(
                    pageData.data.stickyNavItems,
                    'conferences'
                  )}
                  videoCollection={pageData.data.conferences}
                  activeFontFamily={'spectral'}
                />
              )}

            {checkStickyNavItem(pageData.data.stickyNavItems, 'forKids') &&
              pageData.data.forKids &&
              pageData.data.forKids.length > 0 && (
                <OnlineExhibitionForKidsSection
                  stickyId={getStickyId(
                    pageData.data.stickyNavItems,
                    'forKids'
                  )}
                  sectionTitle={getSectionTitle(
                    pageData.data.stickyNavItems,
                    'forKids'
                  )}
                  collection={pageData.data.forKids}
                  activeFontFamily="spectral"
                />
              )}
          </Box>
        )}

      {pageData.data?.otherOnlineExhibitions &&
        pageData.data?.otherOnlineExhibitions.length > 0 && (
          <RelatedEvents
            title={t(
              'exhibition-and-event:eventAndExhibitionDetail.onlineExhibition.title'
            )}
            collection={pageData.data.otherOnlineExhibitions}
            containerProps={{
              my: { base: 'padding.primary2', lg: 'padding.primary4' },
            }}
            mainType="Online"
            activeFontFamily="spectral"
          />
        )}
    </Layout>
  )
}
