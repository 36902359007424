/* eslint-disable prefer-const */
import { ISearchResultDTO } from '@interfaces'
import { prepareSearchFacet } from './'

export const prepareSearchImgUrl = ({
  type,
  field_exhibition_em_primary_medi = '',
  field_collection_em_primary_medi = '',
  field_object_em_primary_media = '',
  field_people_primary_media = '',
  field_event_em_primary_media = '',
  field_publication_image = '',
  field_pr_banner_image = '',
  field_image = '',
}: ISearchResultDTO) => {
  const facet = prepareSearchFacet(type)

  let imgUrl = null

  switch (facet) {
    case 'collection':
      imgUrl = field_collection_em_primary_medi
      break

    case 'exhibition':
      imgUrl = field_exhibition_em_primary_medi
      break

    case 'object':
      imgUrl = field_object_em_primary_media
      break

    case 'people':
      imgUrl = field_people_primary_media
      break

    case 'event':
      imgUrl = field_event_em_primary_media
      break

    case 'publication':
      imgUrl = `${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/${field_publication_image}`
      break

    case 'press_release':
      imgUrl = `${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/${field_pr_banner_image}`
      break

    case 'article':
      imgUrl = field_image
      break

    case 'makale':
      imgUrl = `${process.env.NEXT_PUBLIC_DRUPAL_API_URL}${field_image}`
      break

    default:
      imgUrl = '/img/placeholders/no-image-available-icon.jpeg'
      break
  }

  return imgUrl
}
