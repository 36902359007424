import { useStore } from '@store'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import {
  CollectionSearchSortBox,
  CollectionSearchInputBox,
  CollectionSearchFilterBox,
} from '@common'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { createNavLink } from '@utils'

const CollectionSearchModal = observer(() => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const toast = useToast()
  const { t } = useTranslation()
  const router = useRouter()

  const {
    artSearch: {
      displayFilterModal,
      displaySortModal,
      onCloseModal,
      setSearchTerm,
      searchTitle,
      sortResult,
      selectedInCollectionOptions,
      beginDate,
      endDate,
      searchArtist,
      searchInvNo,
      searchDescription,
      toggleDisplayFilterModal,
      displayBeginDate,
      displayEndDate,
    },
  } = useStore()

  const handleSearchAction = () => {
    if (
      searchTitle === '' &&
      searchArtist === '' &&
      searchInvNo === '' &&
      searchDescription === ''
    ) {
      toast({
        title: t(
          'collections-and-research:search.emptyAdvancedSearchInputMessage'
        ),
        status: 'info',
        duration: 9000,
        isClosable: true,
      })
      return
    }

    let query = router.query
    query = {}

    // remove searchTerm param on common search
    setSearchTerm('')

    query.sort = sortResult
    query.page = '1'
    query.isAdvancedSearch = 'true'

    if (searchTitle !== '') {
      query.searchTitle = searchTitle
    }

    if (searchArtist !== '') {
      query.searchArtist = searchArtist
    }

    if (searchInvNo !== '') {
      query.searchInvNo = searchInvNo
    }

    if (searchDescription !== '') {
      query.searchDescription = searchDescription
    }

    if (selectedInCollectionOptions !== 'All') {
      query.selectedInCollectionOptions = selectedInCollectionOptions
    }

    if (displayBeginDate && beginDate) {
      query.beginDate = new Date(beginDate).getFullYear().toString()
    }

    if (displayEndDate && endDate) {
      query.endDate = new Date(endDate).getFullYear().toString()
    }

    router.push({
      pathname: createNavLink('collectionSearch', router.locale),
      query: query,
    })
    toggleDisplayFilterModal()
  }

  return (
    <Modal
      isOpen={displayFilterModal || displaySortModal}
      onClose={onCloseModal}
      scrollBehavior="inside"
    >
      <ModalOverlay
        bgColor="blackAlpha.primary80"
        transition="all 0.3s ease"
        sx={{
          backdropFilter: 'blur(8px)',
        }}
      />
      <ModalContent
        width="100vw"
        maxWidth="full"
        height="auto"
        maxHeight="100vh"
        my="0"
        borderRadius="none"
        display="flex"
        flexDirection={{ base: 'column', lg: 'row' }}
        background="transparent"
        boxShadow="none"
        position="relative"
        overflowY={{ base: 'auto', lg: 'visible' }}
      >
        <CollectionSearchFilterBox handleSearchAction={handleSearchAction} />
        {isDesktop && (
          <CollectionSearchInputBox handleSearchAction={handleSearchAction} />
        )}
        <CollectionSearchSortBox />
      </ModalContent>
    </Modal>
  )
})

export default CollectionSearchModal
