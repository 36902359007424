import { BoxProps } from '@chakra-ui/react'
import { HeroAspectRatio, MotionBox } from '@ui'

interface IOnlineExhibitionHero {
  mobileImgUrl: string
  desktopImgUrl: string
  containerProps?: BoxProps
}

const OnlineExhibitionHero = ({
  mobileImgUrl,
  desktopImgUrl,
  containerProps,
}: IOnlineExhibitionHero) => (
  <HeroAspectRatio>
    <MotionBox
      width="full"
      height="full"
      backgroundImage={{
        base: `url('${
          mobileImgUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : mobileImgUrl
        }')`,
        lg: `url('${
          desktopImgUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : desktopImgUrl
        }')`,
      }}
      backgroundPosition="center"
      backgroundSize={'cover'}
      backgroundRepeat="no-repeat"
      position="relative"
      zIndex={5}
      py={{ base: 'padding.primary2', lg: '8' }}
      _before={{
        content: `" "`,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '50vw',
        height: '100%',
        background:
          'linear-gradient(105deg, rgba(13, 16, 29, 0.9) 0%, rgba(13, 16, 29, 0) 50%)',
        zIndex: '-1',
      }}
      _after={{
        content: `" "`,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: '50vw',
        height: '100%',
        background:
          'linear-gradient(255deg, rgba(13, 16, 29, 0.9) 0%, rgba(13, 16, 29, 0) 50%)',
        zIndex: '-1',
      }}
      {...containerProps}
    />
  </HeroAspectRatio>
)

export default OnlineExhibitionHero
