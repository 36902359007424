import styled from '@emotion/styled'
import theme from '../../../styles/theme'
import { mq } from '../../../lib/hooks'

export const SearchBox = styled.button`
  display: flex;
  align-items: center;
  padding: 0px;
  user-select: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 0px 10px;

  @media screen and (max-width: 342px) {
    margin: 0px 8px;
  }

  &.SearchBox--active {
    & > svg {
      color: ${theme.colors.red.primary} !important;
    }

    & > span {
      color: ${theme.colors.red.primary} !important;
    }
  }

  &:hover {
    & > span,
    & > svg {
      opacity: 0.75;
    }
  }

  .SearchBox__Label {
    color: ${theme.colors.white.primary3};

    &.SearchBox__Label--onDrawer {
      color: ${theme.colors.white.primary};
    }

    ${mq('lg')} {
      color: ${theme.colors.white.primary};

      &.SearchBox__Label--isSticky {
        color: ${theme.colors.white.primary3};
      }
    }
  }

  .SearchBox__Icon {
    &.SearchBox__Icon--onDrawer {
      color: ${theme.colors.white.primary};
    }

    ${mq('lg')} {
      color: ${theme.colors.white.primary};

      &.SearchBox__Icon--isSticky {
        color: ${theme.colors.black.primary3};
      }
    }
  }
`
