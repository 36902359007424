import {
  BoxProps,
  Container,
  SimpleGrid,
  Text,
  Box,
  Image,
  Stack,
  Heading,
  Divider,
} from '@chakra-ui/react'
import { ISelectionsCollection, ISectionData } from '@interfaces'
import { RichText } from '@ui'

interface ISelectionsFromWork {
  variant?: 'primary' | 'withPopUp'
  isDarkSection?: boolean
  sectionTitle: ISectionData['sectionTitle']
  sectionSubTitle: string
  sectionDescription?: string
  data: ISelectionsCollection[]
  footerButtonLabel?: string
  footerButtonOnPress?: () => void
  containerStyle?: BoxProps
  stickyId?: ISectionData['stickyId']
  isFooterButtonLoading?: boolean
}

const SectionsPlant = ({
  stickyId = 'SelectionsFromWorks',
  data,
}: ISelectionsFromWork) => {
  const sortedData = [...data].sort((a, b) => a.title.localeCompare(b.title))

  const PlantCard = ({
    title,
    imgUrl,
    description,
  }: {
    title: string
    imgUrl: string
    description?: string
  }) => (
    <Box
      maxW="sm"
      boxShadow="xl"
      border="1px"
      borderColor="gray.100"
      p="4"
      rounded="md"
      bg="white"
    >
      <Image
        border="1px"
        borderColor="gray.400"
        rounded="md"
        boxShadow="lg"
        w="full"
        h="230px"
        backgroundSize="cover"
        src={imgUrl}
        fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
        alt={title}
        filter="brightness(110%) contrast(120%)"
      />
      <Stack mt="2" spacing="3" p="3">
        <Heading size="sm">{title}</Heading>
        <Divider />
        <Text>
          <RichText htmlContent={description} />
        </Text>
      </Stack>
    </Box>
  )
  return (
    <Container id={stickyId} maxW="container.xl" mb="100px">
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
        {sortedData.map((collection) => (
          <PlantCard key={collection.id} {...collection} />
        ))}
      </SimpleGrid>
    </Container>
  )
}

export default SectionsPlant
