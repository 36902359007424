import drupalHttp from '../drupalHttpService'
import { EXTENDED_EMUSEUM } from '@lib/const/api-endpoints.const'

/**
 * Extended e-Museum Http Service
 */
export const ExtendedEMuseumHttpService = {
  /**
   * Get products ids, google art link, 3d tour video link and press release data on drupalHttp service for limited e-Museum data
   * addUrlParams example: exhibition-4|event-103|collection-329
   */
  getExtendedEMuseum: async (locale: string, addUrlParams?: string) =>
    await drupalHttp.get(`${EXTENDED_EMUSEUM[locale]}/${addUrlParams || ''}`),
}
