import { createIcon } from '@chakra-ui/icons'

const Cross = createIcon({
  displayName: 'Cross',
  viewBox: '0 0 413.348 413.348',
  path: (
    <path
      fill="currentColor"
      d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"
    />
  ),
})

export default Cross
