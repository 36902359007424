/* eslint-disable @typescript-eslint/no-unused-vars */
import { ISearchResultDTO } from '@interfaces'
import {
  prepareSearchFacet,
  createNavLink,
  clearSlugForSearchResultUrl,
} from './'
import NAV_PATHS from '@lib/const/nav-paths.const'

interface ISearchUrl {
  slug: string
  prefix: keyof typeof NAV_PATHS
}

export const prepareSearchResultLink = ({
  field_event_em_source_id = '#',
  field_exhibition_em_source_id = '#',
  type,
  field_path_alias,
  field_collection_em_source_id = '#',
  field_people_source_id = '#',
  field_object_em_source_id = '#',
  field_object_em_artist_id = '#',
  field_event_em_event_type = 'Event-Exhibition',
  locale,
  field_article_medium_link = '',
}: ISearchResultDTO) => {
  const facet = prepareSearchFacet(type)

  const url: ISearchUrl = {
    slug: null,
    prefix: null,
  }

  let searchResultUrl = ''

  switch (facet) {
    case 'collection':
      url.slug = field_collection_em_source_id
      url.prefix = 'collections'
      searchResultUrl = `${createNavLink(url.prefix, locale)}/${url.slug}`
      break

    case 'exhibition':
      url.slug = field_exhibition_em_source_id
      url.prefix = 'exhibition'
      searchResultUrl = `${createNavLink(url.prefix, locale)}/${url.slug}`
      break

    /** field_object_em_artist_id = artist id of object */
    case 'object':
      url.slug = `${field_object_em_artist_id}/${field_object_em_source_id}`
      url.prefix = 'artist'
      searchResultUrl = `${createNavLink(url.prefix, locale)}/${url.slug}`
      break

    case 'people':
      url.slug = field_people_source_id
      url.prefix = 'artist'
      searchResultUrl = `${createNavLink(url.prefix, locale)}/${url.slug}`
      break

    case 'event':
      url.slug = field_event_em_source_id
      url.prefix = getEventTypeRouteKey(field_event_em_event_type)
      searchResultUrl = `${createNavLink(url.prefix, locale)}/${url.slug}`
      break

    case 'publication':
      url.slug = clearSlugForSearchResultUrl(field_path_alias)
      url.prefix = 'publications'
      searchResultUrl = `${createNavLink(url.prefix, locale)}?slug=${url.slug}`
      break

    case 'press_release':
      url.slug = clearSlugForSearchResultUrl(field_path_alias)
      url.prefix = 'press'
      searchResultUrl = `${createNavLink(url.prefix, locale)}/${url.slug}`
      break

    case 'makale':
    case 'article':
      url.slug = clearSlugForSearchResultUrl(field_path_alias)
      url.prefix = 'newsAndBlog'
      searchResultUrl = `${createNavLink(url.prefix, locale)}/${url.slug}`
      if (field_article_medium_link != '') {
        searchResultUrl = field_article_medium_link
      }
      break

    default:
      url.slug = '#'
      url.prefix = 'home'
      searchResultUrl = `${createNavLink(url.prefix, locale)}/${url.slug}`
      break
  }

  return searchResultUrl
}

const getEventTypeRouteKey = (
  eventType: ISearchResultDTO['field_event_em_event_type']
): 'event' | 'learning' | 'workshop' => {
  if (eventType === 'Learning-Adult-Workshop') {
    return 'learning'
  }

  if (eventType === 'Learning-Kid-Workshop') {
    return 'workshop'
  }

  return 'event'
}
