import { ConservationHttpService } from '@httpServices'
import { v4 as uuidv4 } from 'uuid'
import { IConservationInput } from '@interfaces'
import { EventService } from '../'
import {
  generatePhotosAndVideosFromExtendedEMuseum,
  prepareVideoUrl,
  moveItemInArrayFromIndexToIndex,
} from '@utils'
import { PageService } from '@services'
import NAV_TR from '@/locales/tr/nav.json'
import NAV_EN from '@/locales/en/nav.json'

export const ConservationService = {
  /**
   * Get conservation
   */
  getConservation: async (locale: string | 'en' | 'tr') => {
    const conservationInit = {
      pageTitle: '',
      hero: {
        bgImageUrl: '',
        subTitle: '',
        title: '',
        description: '',
      },
      stickyNavItems: null,
      fullWidthTwoColSections: null,
      scientificPublications: null,
      gallery: {
        contentType: null,
        contentId: null,
        contentTabLabel: null,
        data: null,
      },
      conferencesAndSeminars: {
        contentType: null,
        contentId: null,
        contentTabLabel: null,
        data: null,
      },
      contact: null,
    }

    const conservation = await ConservationHttpService.getConservation(locale)

    const parsedPreparedJSONConservation = JSON.parse(
      conservation[0].field_conservation_data[0].value
    )

    const conservationData: IConservationInput[] =
      parsedPreparedJSONConservation.data

    // preparing hero
    conservationInit.pageTitle =
      (conservation[0]?.title &&
        conservation[0]?.title.length > 0 &&
        conservation[0]?.title[0]?.value) ||
      ''

    conservationInit.hero.subTitle =
      (conservation[0].field_page_banner_header &&
        conservation[0].field_page_banner_header[0]?.value) ||
      ''

    conservationInit.hero.title =
      (conservation[0].title &&
        conservation[0].field_page_banner_subheader[0]?.value) ||
      ''

    conservationInit.hero.description =
      (conservation[0].body && conservation[0].body[0]?.summary) || ''

    conservationInit.hero.bgImageUrl =
      (conservation[0].field_page_banner_image &&
        conservation[0].field_page_banner_image[0]?.url) ||
      '/img/collections-and-research/collections/banner/ssm-art-collections-banner.jpg'

    // Preparing STICKY NAV ITEMS
    const initStickyNavItems = []

    conservationData.map((item) => {
      initStickyNavItems.push({
        id: item?.id || uuidv4(),
        key: item?.contentId || '',
        navTitleTx: item?.contentTabLabel || '',
        href: item?.contentId || '',
      })
    })

    conservationInit.stickyNavItems = initStickyNavItems

    // Preparing full width two col sections
    conservationInit.fullWidthTwoColSections = conservationData.filter(
      (item) => item.contentType === 'twoColBlock'
    )

    // Preparing scientific publications
    conservationInit.scientificPublications = conservationData.filter(
      (item) => item.contentType === 'scientificPublications'
    )

    // prepare gallery
    const gallery = []

    // generate images
    if (conservation[0].field_page_image_collection.length > 0) {
      conservation[0].field_page_image_collection.map((img) => {
        gallery.push(
          generatePhotosAndVideosFromExtendedEMuseum({
            id: img?.target_id || uuidv4(),
            url: img?.url || '',
            alt: img?.alt || '',
            isVideo: false,
          })
        )
      })
    }

    // generate videos
    if (
      conservation[0].field_page_video_collection &&
      conservation[0].field_page_video_collection.length > 0
    ) {
      const videoTargetIds = []
      conservation[0].field_page_video_collection.map((video) =>
        videoTargetIds.push(video?.target_id)
      )

      await Promise.all(
        videoTargetIds.map(async (videoTargetId) => {
          const videoDetail = await PageService.getVideoDetail(
            locale,
            videoTargetId
          )

          if (videoDetail.length > 0) {
            gallery.push(
              generatePhotosAndVideosFromExtendedEMuseum({
                id: videoDetail[0]?.nid[0]?.value || uuidv4(),
                url:
                  (videoDetail[0]?.field_page_video_url &&
                    videoDetail[0]?.field_page_video_provider &&
                    prepareVideoUrl(
                      videoDetail[0]?.field_page_video_url[0]?.value,
                      videoDetail[0]?.field_page_video_provider[0]?.value ||
                        'youtube'
                    )) ||
                  '',
                alt: videoDetail[0]?.title[0]?.value || '',
                isVideo: true,
                videoProvider:
                  videoDetail[0]?.field_page_video_provider[0]?.value ||
                  'youtube',
              })
            )
          }
        })
      )
    }

    if (gallery.length > 0) {
      conservationInit.gallery.data = gallery

      conservationInit.gallery.contentType = 'gallery'
      conservationInit.gallery.contentId = 'gallery'
      conservationInit.gallery.contentTabLabel =
        locale === 'tr'
          ? NAV_TR.stickyItems.photosAndVideos
          : NAV_EN.stickyItems.photosAndVideos

      conservationInit.stickyNavItems.splice(7, 0, {
        id: conservationInit.gallery.contentType,
        key: conservationInit.gallery.contentType,
        navTitleTx: conservationInit.gallery.contentTabLabel,
        href: conservationInit.gallery.contentType,
      })
    }

    // Preparing conferences and seminars
    const conservationEvents = await EventService.getConservationEvents(locale)

    if (conservationEvents.length > 0) {
      conservationInit.conferencesAndSeminars.data = conservationEvents
      conservationInit.conferencesAndSeminars.contentType =
        'conferencesAndSeminars'
      conservationInit.conferencesAndSeminars.contentId =
        'conferencesAndSeminars'
      conservationInit.conferencesAndSeminars.contentTabLabel =
        locale === 'tr'
          ? NAV_TR.stickyItems.conferencesAndSeminars
          : NAV_EN.stickyItems.conferencesAndSeminars

      conservationInit.stickyNavItems.splice(8, 0, {
        id: conservationInit.conferencesAndSeminars.contentType,
        key: conservationInit.conferencesAndSeminars.contentType,
        navTitleTx: conservationInit.conferencesAndSeminars.contentTabLabel,
        href: conservationInit.conferencesAndSeminars.contentType,
      })
    }

    // Preparing contact
    conservationInit.contact = conservationData.filter(
      (item) => item.contentType === 'contact'
    )

    // move contact nav item to last index on sticky nav item array
    const contactStickyId = conservationInit.stickyNavItems.findIndex(
      (d) => d.href === 'contact'
    )

    if (contactStickyId !== -1) {
      const transformedStickyNavItems = moveItemInArrayFromIndexToIndex(
        conservationInit.stickyNavItems,
        contactStickyId,
        conservationInit.stickyNavItems.length - 1
      )

      conservationInit.stickyNavItems = transformedStickyNavItems
    }

    return conservationInit
  },
}
