import eMuseumHttp from '../eMuseumHttpService'
import {
  COMMON_OBJECT_SEARCH,
  ADVANCED_OBJECT_SEARCH,
} from '@lib/const/api-endpoints.const'

/**
 * eMuseum Http Service
 */
export const eMuseumHttpSearch = {
  /**
   * Search common objects on e-museum.
   * For example;
   * - common search: https://emuseum.sakipsabancimuzesi.org/search/abidin/objects/json
   * - common search with sort params: https://emuseum.sakipsabancimuzesi.org/search/abidin/objects/json?sort=title-asc
   */
  commonSearchOnObjects: async (searchTerm: string, params: string) =>
    await eMuseumHttp.get(`${COMMON_OBJECT_SEARCH(searchTerm)}?${params}`),
  /**
   * Search advanced objects on e-museum.
   * For example;
   * - advanced search: https://emuseum.sakipsabancimuzesi.org/advancedsearch/Objects/title%3Aabidin/json
   * - advanced search with multiple args: https://emuseum.sakipsabancimuzesi.org/advancedsearch/Objects/beginDate:1725;endDate:1934;collections:Archaeological;title:abidin;people:abidin/json
   * - advanced search with multiple args and pagination: https://emuseum.sakipsabancimuzesi.org/advancedsearch/Objects/beginDate:1725;endDate:1934;collections:Archaeological;title:abidin;people:abidin/json?page=1
   */
  advancedSearchOnObjects: async (filterParams: string, addParams: string) => {
    return await eMuseumHttp.get(
      `${ADVANCED_OBJECT_SEARCH(filterParams)}?${addParams}`
    )
  },
}
