import { v4 as uuidv4 } from 'uuid'
import { IPressReleaseFilterOption } from '@interfaces'

const START_YEAR_PRESS_RELEASES = 2005

const PRESS_RELEASE_FILTER_OPTIONS: () => IPressReleaseFilterOption[] = () => {
  const FILTER_OPTIONS = []

  // all
  const ALL_OPTION: IPressReleaseFilterOption = {
    id: uuidv4(),
    labelTx: 'legal:press.filterBox.all',
    value: 'all',
    isWithLocalization: true,
  }
  FILTER_OPTIONS.push(ALL_OPTION)

  const DYNAMIC_YEAR_OPTIONS = []

  // dynamic years of press releases
  for (
    let index = START_YEAR_PRESS_RELEASES;
    index <= new Date().getFullYear();
    index++
  ) {
    const DYNAMIC_YEAR_ROW = {
      id: uuidv4(),
      labelTx: index,
      value: index.toString(),
      isWithLocalization: false,
    }

    DYNAMIC_YEAR_OPTIONS.push(DYNAMIC_YEAR_ROW)
  }

  return [...FILTER_OPTIONS, ...DYNAMIC_YEAR_OPTIONS.reverse()]
}

export const AVALIABLE_PRESS_RELEASES_OPTION_VALUES =
  PRESS_RELEASE_FILTER_OPTIONS().map((option) => option.value)

export default PRESS_RELEASE_FILTER_OPTIONS
