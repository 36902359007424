import { PlantHttpService } from '@httpServices'
import { ISelectionsCollection } from '@interfaces'

export const PlantService = {
  /**
   * Get all plant
   */
  getAllPlant: async (
    locale: string,
    addUrlParams?: string
  ): Promise<ISelectionsCollection[]> => {
    const collection = await PlantHttpService.getAllPlant(locale, addUrlParams)

    //console.log(JSON.stringify(collection))

    return collection.map((d) => ({
      id: d.uuid[0]?.value || '',
      imgUrl: d.field_plant_image[0]?.url || '',
      title: d.title[0]?.value || '',
      subTitle: d.field_plant_season[0]?.value || '',
      description: d.field_plant_desc[0]?.value || '',
      alt: d.field_plant_image[0]?.alt || '',
      name: 'Detaylı İncele',
    }))
  },
}
