import { SimpleGrid, Container, ContainerProps } from '@chakra-ui/react'
import { IArtistCard } from '@interfaces'
import { ArtistCard } from '@common'
import { FadeInUpBox, IntersectionObserver, StaggerWrap } from '@motion'

interface IArtistsCollectionGrid {
  collection: IArtistCard[]
  containerProps?: ContainerProps
}

const ArtistsCollectionGrid = ({
  collection,
  containerProps,
}: IArtistsCollectionGrid) => (
  <Container
    maxWidth="container.secondary"
    px={{ base: 'padding.primary', lg: '0' }}
    pt={{ base: '10', lg: '14' }}
    pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
    {...containerProps}
  >
    <StaggerWrap staggerChildren={1.6}>
      <SimpleGrid
        columns={{ base: 1, lg: 3 }}
        spacingX={{ base: '4', lg: '5' }}
        spacingY={{ base: '4', lg: '14' }}
      >
        {collection &&
          collection.length > 0 &&
          collection.map((col, i) => (
            <IntersectionObserver key={i}>
              <FadeInUpBox duration={1}>
                <ArtistCard {...col} />
              </FadeInUpBox>
            </IntersectionObserver>
          ))}
      </SimpleGrid>
    </StaggerWrap>
  </Container>
)

export default ArtistsCollectionGrid
