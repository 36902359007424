import { Image, ImageProps, BoxProps } from '@chakra-ui/react'
import { MotionBox } from '@ui'
import { HTMLMotionProps } from 'framer-motion'

interface IMotionImage {
  containerProps?: BoxProps
  motionProps?: HTMLMotionProps<'div'>
  imageProps?: ImageProps
  imgUrl: string
  imgAlt: string
  fallbackSrc?: string
}

const MotionImage = ({
  containerProps,
  motionProps,
  imageProps,
  imgAlt,
  imgUrl,
  fallbackSrc = '/img/placeholders/no-image-available-icon.jpeg',
}: IMotionImage) => (
  <MotionBox {...motionProps} {...containerProps}>
    <Image
      src={imgUrl}
      fallbackSrc={fallbackSrc}
      alt={imgAlt}
      {...imageProps}
    />
  </MotionBox>
)

export default MotionImage
