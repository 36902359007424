import { Text } from '@chakra-ui/react'
import { Link } from '@ui'
import cn from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import * as S from './styles'
import { IFonts } from '@interfaces'
import { LanguageIcon} from "@icon";

interface II18nWidget {
  isSticky?: boolean
  onDrawerMenu?: boolean
  activeFontFamily?: IFonts
}

const I18nWidget = ({
  isSticky = false,
  onDrawerMenu = false,
}: II18nWidget) => {
  const { locale } = useRouter()
  const { t } = useTranslation()

  return (
    <S.I18nWidget>
      <Link
        href="/"
        locale={locale === 'tr' ? 'en' : 'tr'}
        className={cn('I18nWidget__Link', {
          'I18nWidget__Link--isSticky': isSticky,
          'I18nWidget__Link--onDrawer': onDrawerMenu,
        })}
      >
        <LanguageIcon />
        <Text
          as="span"
          color="white.primary"
          fontWeight="bold"
          fontSize="15px"
          fontFamily="heading"
        >
          {t(locale === 'tr' ? 'nav:en' : 'nav:tr')}
        </Text>
      </Link>
    </S.I18nWidget>
  )
}

export default I18nWidget
