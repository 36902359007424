import { createNavLink } from './'

/**
 * input like "collections/335/1181"
 * output like '/collections-and-research/collections/335/1181'
 */
export const createRouteUrlByKey = (routeUrlWithKey, locale: string) => {
  const parsedRouteUrlWithKey = routeUrlWithKey.split('/')
  const mainPath = parsedRouteUrlWithKey[0]
  const collectionSourceId = parsedRouteUrlWithKey[1]
  const objectOfCollectionSourceId = parsedRouteUrlWithKey[2]

  return `${createNavLink(mainPath, locale)}${
    collectionSourceId !== undefined ? '/' + collectionSourceId : ''
  }${
    objectOfCollectionSourceId !== undefined
      ? '/' + objectOfCollectionSourceId
      : ''
  }`
}
