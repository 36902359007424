import { useEffect } from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  useToast,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import useTranslation from 'next-translate/useTranslation'
import { FormService } from '@services'
import { useRouter } from 'next/router'
import Trans from 'next-translate/Trans'
import { Checkbox, Link } from '@ui'
import { IFonts } from '@interfaces'

type ExtendedNewsletterFormInputs = {
  name: string
  surname: string
  email: string
  companyName: string
  pdfurl: string
  kvkk: boolean
}

interface IExtendedNewsletterForm {
  email: string
  isOpen: boolean
  onClose: () => void
  newsLetterReset: () => void
  activeFontFamily?: IFonts
}

const ExtendedNewsletterForm = ({
  email,
  isOpen,
  onClose,
  newsLetterReset,
  activeFontFamily = 'body',
}: IExtendedNewsletterForm) => {
  const { locale } = useRouter()
  const toast = useToast()
  const { t } = useTranslation()

  const schema = yup.object().shape({
    name: yup.string().required(t('form:error.requiredField')),
    surname: yup.string().required(t('form:error.requiredField')),
    email: yup
      .string()
      .email(t('form:error.invalidEmail'))
      .required(t('form:error.requiredEmail')),
    //   companyName: yup.string().required(t('form:error.requiredField')),
    kvkk: yup.bool().oneOf([true], t('form:error.requiredField')),
  })

  const { register, handleSubmit, errors, reset } =
    useForm<ExtendedNewsletterFormInputs>({
      mode: 'onBlur',
      resolver: yupResolver(schema),
      defaultValues: {
        email: '',
        name: '',
        surname: '',
        companyName: '',
        kvkk: false,
      },
    })

  useEffect(() => {
    reset({
      email: email,
    })
  }, [email, reset])

  const onSubmit = async (values: ExtendedNewsletterFormInputs, e) => {
    e.preventDefault()
    try {
      const response = await FormService.post({
        webform_id: 'newsletter',
        email: values.email,
        name: values.name,
        surname: values.surname,
        companyname: '',
        pdfurl: '',
        consent: true,
        locale,
      })
      if (response) {
        toast({
          title: t('form:success.primary'),
          description: t('form:success.newsletter'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        newsLetterReset()
        reset()
        onClose()
      }
    } catch (error) {
      toast({
        title: t('form:error.commonTitle'),
        description: t('form:error.commonDescription'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bgColor="blackAlpha.primary80"
        transition="all 0.3s ease"
        sx={{
          backdropFilter: 'blur(8px)',
        }}
      />
      <ModalContent bgColor="white.primary">
        <ModalBody py="padding.primary2">
          <Box
            as="form"
            sx={{
              '& > * + *': {
                mt: '6',
              },
            }}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl isInvalid={!!errors?.name?.message}>
              <FormLabel
                fontWeight="medium"
                fontSize="sm"
                fontFamily={activeFontFamily}
                mb="2"
              >
                {t('form:field.name')}
              </FormLabel>
              <Input
                type="name"
                name="name"
                px="10px"
                border="1px solid"
                borderColor="black.primary3"
                variant="secondary"
                size="secondary"
                focusBorderColor="black.primary3"
                errorBorderColor="red.primary"
                fontFamily={activeFontFamily}
                ref={register}
              />
              <FormErrorMessage
                color="red.primary"
                fontFamily={activeFontFamily}
              >
                {errors?.name?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.surname?.message}>
              <FormLabel
                fontWeight="medium"
                fontSize="sm"
                fontFamily={activeFontFamily}
                mb="2"
              >
                {t('form:field.surname')}
              </FormLabel>
              <Input
                type="name"
                name="surname"
                px="10px"
                border="1px solid"
                borderColor="black.primary3"
                variant="secondary"
                size="secondary"
                fontFamily={activeFontFamily}
                focusBorderColor="black.primary3"
                errorBorderColor="red.primary"
                ref={register}
              />
              <FormErrorMessage
                color="red.primary"
                fontFamily={activeFontFamily}
              >
                {errors?.surname?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.email?.message}>
              <FormLabel
                fontFamily={activeFontFamily}
                fontWeight="medium"
                fontSize="sm"
                mb="2"
              >
                {t('form:field.email')}
              </FormLabel>
              <Input
                type="email"
                name="email"
                px="10px"
                border="1px solid"
                borderColor="black.primary3"
                variant="secondary"
                size="secondary"
                fontFamily={activeFontFamily}
                focusBorderColor="black.primary3"
                errorBorderColor="red.primary"
                ref={register}
              />
              <FormErrorMessage
                fontFamily={activeFontFamily}
                color="red.primary"
              >
                {errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors?.kvkk?.message}>
              <Checkbox name="kvkk" ref={register} id="kvkk">
                <Trans
                  i18nKey="form:field.kvkkLabel"
                  components={[
                    <Text
                      key="kvkkTextComponent"
                      fontWeight="light"
                      fontSize="sm"
                      fontFamily={activeFontFamily}
                    />,
                    <Link
                      fontFamily={activeFontFamily}
                      fontWeight="medium"
                      key="kvkkLinkComponent"
                      href="/kvkk"
                      target="_blank"
                    />,
                  ]}
                />
              </Checkbox>
              <FormErrorMessage
                fontFamily={activeFontFamily}
                color="red.primary"
              >
                {errors?.kvkk?.message}
              </FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              fontFamily={activeFontFamily}
              disabled={
                !!errors.email ||
                !!errors.kvkk ||
                !!errors.name ||
                !!errors.surname
              }
            >
              {t('common:newsRegisterButtonLabel')}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ExtendedNewsletterForm
