export const splitReferenceAndAdditionalReadings = (dirtyString: string) => {
  const splittedString = dirtyString.split('[;]')

  const val = {
    reference: splittedString[0],
    additionalReadings: splittedString[1] || null,
  }

  return val
}
