import { v4 as uuidv4 } from 'uuid'
import NAV_PATHS from '@lib/const/nav-paths.const'

interface IFOOTER_NAVIGATION {
  id: string
  path: keyof typeof NAV_PATHS
  labelTx: string
  isExternal: boolean
}

interface IFOOTER_NAVIGATION_COLLECTION {
  exhibitionsAndEventsFooter: IFOOTER_NAVIGATION[]
  collectionsAndResearchFooter: IFOOTER_NAVIGATION[]
  visitFooter: IFOOTER_NAVIGATION[]
  aboutFooter: IFOOTER_NAVIGATION[]
  exploreFooter: IFOOTER_NAVIGATION[]
  leftCol: IFOOTER_NAVIGATION[]
  bottomRow: IFOOTER_NAVIGATION[]
}

const FOOTER_NAVIGATION_COLLECTION: IFOOTER_NAVIGATION_COLLECTION = {
  exhibitionsAndEventsFooter: [
    {
      id: uuidv4(),
      path: 'currentExhibitions',
      labelTx: 'nav:currentExhibitions',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'pastExhibitions',
      labelTx: 'nav:pastExhibitions',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'rdEvents',
      labelTx: 'nav:rdEvents',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'learningFilter',
      labelTx: 'nav:learning',
      isExternal: false,
    },
  ],
  visitFooter: [
    {
      id: uuidv4(),
      path: 'visit',
      labelTx: 'nav:informationAndTransportation',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'groupsAndTours',
      labelTx: 'nav:groupsAndTours',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'servicesAndAccessibility',
      labelTx: 'nav:servicesAndAccessibility',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'garden',
      labelTx: 'nav:garden',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'seed',
      labelTx: 'the Seed',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'msaSRestaurant',
      labelTx: 'nav:msaSRestaurant',
      isExternal: false,
    },
  ],
  collectionsAndResearchFooter: [
    {
      id: uuidv4(),
      path: 'collections',
      labelTx: 'nav:collections',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'archives',
      labelTx: 'nav:archives',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'artists',
      labelTx: 'nav:artists',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'conservation',
      labelTx: 'nav:conservation',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'digitalSSM',
      labelTx: 'digitalSSM',
      isExternal: false,
    },
  ],
  exploreFooter: [
    {
      id: uuidv4(),
      path: 'publications',
      labelTx: 'nav:publications',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'newsAndBlog',
      labelTx: 'nav:newsAndBlog',
      isExternal: false,
    },
  ],
  leftCol: [
    {
      id: uuidv4(),
      path: 'visit',
      labelTx: 'nav:visit',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'exhibitionAndEvent',
      labelTx: 'nav:exhibitionsAndEvents',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'msaSRestaurant',
      labelTx: 'nav:msaSRestaurant',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'support',
      labelTx: 'nav:support',
      isExternal: false,
    },
  ],
  aboutFooter: [
    {
      id: uuidv4(),
      path: 'about',
      labelTx: 'nav:about',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'team',
      labelTx: 'nav:team',
      isExternal: false,
    },

    {
      id: uuidv4(),
      path: 'press',
      labelTx: 'nav:press',
      isExternal: false,
    },
  ],
  bottomRow: [
    {
      id: uuidv4(),
      path: 'kvkk',
      labelTx: 'nav:kvkk',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'privacyPolicy',
      labelTx: 'nav:privacyPolicy',
      isExternal: false,
    },
    {
      id: uuidv4(),
      path: 'sabanciUniversity',
      labelTx: 'nav:sabanciUniversity',
      isExternal: true,
    },
  ],
}

const NAV_LINKS = {
  socialMedia: {
    youtube: 'https://www.youtube.com/user/sakipsabancimuzesi',
    instagram: 'https://www.instagram.com/sakipsabancimuzesi/',
    facebook: 'https://www.facebook.com/SakipSabanciMuzesi/',
    twitter: 'https://twitter.com/SSabanciMuze',
    googleArts: 'https://artsandculture.google.com/partner/sakp-sabanc-museum',
    tripadvisor:
      'https://www.tripadvisor.com.tr/Attraction_Review-g293974-d3140819-Reviews-Sakip_Sabanci_Museum-Istanbul.html',
  },
  navigation: FOOTER_NAVIGATION_COLLECTION,
}

export default NAV_LINKS
