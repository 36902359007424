import {
  Box,
  Divider,
  Flex,
  Grid,
  Text,
  Button,
  useBreakpointValue,
  Img,
} from '@chakra-ui/react'
import {
  Facebook,
  Instagram,
  Marker,
  Tripadvisor,
  Twitter,
  GoogleArts,
  Youtube,
} from '@icon'
import { Link } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import NAV_LINKS from './FooterNav.const'
import { createNavLink } from '@utils'
import { IFonts } from '@interfaces'
import { NewsletterForm } from "@forms";

interface IFooterNav {
  activeFontFamily?: IFonts
}

const FooterNav = ({ activeFontFamily = 'body' }: IFooterNav) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <Flex
      as="nav"
      width="100%"
      pt={{ base: 'padding.primary2', lg: '68px' }}
      pb={{ base: 'padding.primary', lg: '45px' }}
      direction={{ base: 'column', lg: 'row' }}
    >
      <Box
        width={{ base: '100%%',  lg:"60%" }}
        pl={{ base: 'padding.primary', lg: 'padding.primary2' }}
        pr={{ base: 'padding.primary', lg: '9' }}
      >
        <Text
          as="h4"
          color="red.primary"
          fontSize="lg"
          fontFamily={activeFontFamily}
          borderBottom="1px"
          width="fit-content"
        >
          {t('common:siteFullName')}
        </Text>
        <Flex flexDirection="column" mt={{ base: 5, lg: '38px' }}>
          <Text
            as="h6"
            color="black.primary3"
            fontSize="sm"
            fontWeight="light"
            fontFamily={activeFontFamily}
          >
            <Text
              as="b"
              fontWeight="bold"
              fontSize="sm"
              fontFamily={activeFontFamily}
            >
              {t('common:contact.address')}:{' '}
            </Text>
            Sakıp Sabancı Caddesi No:42 Emirgan 34467 İstanbul
          </Text>
          <Text
            as="h6"
            color="black.primary3"
            fontSize="sm"
            fontWeight="light"
            fontFamily={activeFontFamily}
          >
            <Text
              as="b"
              fontWeight="bold"
              fontSize="sm"
              fontFamily={activeFontFamily}
            >
              {t('common:contact.tel')}:{' '}
            </Text>
            <Text
              as="a"
              href="tel:+90 212 277 22 00"
              fontFamily={activeFontFamily}
              _hover={{ color: 'red.primary3' }}
            >
              +90 212 277 22 00
            </Text>
          </Text>
          <Text
            as="h6"
            color="black.primary3"
            fontSize="sm"
            fontWeight="light"
            fontFamily={activeFontFamily}
          >
            <Text
              as="b"
              fontWeight="bold"
              fontSize="sm"
              fontFamily={activeFontFamily}
            >
              {t('common:contact.email')}:{' '}
            </Text>
            <Text
              as="a"
              href="mailto:ssminfo@sabanciuniv.edu"
              _hover={{ color: 'red.primary3' }}
              fontFamily={activeFontFamily}
            >
              ssminfo@sabanciuniv.edu
            </Text>
          </Text>
        </Flex>
        <Link
          href="https://goo.gl/maps/L3KzMMhPLn6CKmF39"
          target="_blank"
          display="flex"
          mt="padding.primary"
          flexDirection="row"
          alignItems="center"
          color="red.primary"
          _hover={{ color: 'red.primary3' }}
        >
          <Marker />
          <Text
            ml="6px"
            fontSize="sm"
            fontWeight="medium"
            fontFamily={activeFontFamily}
          >
            {t('common:contact.getDirections')}
          </Text>
        </Link>
        <Flex
          flexDir="row"
          alignItems={{ base: 'left', lg: 'left' }}
          justifyContent="start"
          width="full"
          my="6"
          gap="2"
        >
          <Link href={createNavLink('visit', locale)} title={t('nav:visit')}>
            <Img
              loading="lazy"
              src="/img/common/trb-sertifika.webp"
              width="34px"
              alt="Safe Tourism Logo || SSM"
            />
          </Link>
          <Img
            loading="lazy"
            src="/img/common/leedgold_footer.webp"
            width="34px"
            alt="Leed Gold Logo || SSM"
          />
        </Flex>
        <Grid
          templateColumns="repeat(6, minmax(50px, 10px))"
          placeItems="start"
          mt="20px"
          mb={{base:"40px",lg:0}}
        >
          <Link
            href={NAV_LINKS.socialMedia.instagram}
            target="_blank"
            color="grey.primary"
            _hover={{ color: 'red.primary' }}
            title="Instagram"
          >
            <Instagram />
          </Link>
          <Link
            href={NAV_LINKS.socialMedia.facebook}
            target="_blank"
            color="grey.primary"
            _hover={{ color: 'red.primary' }}
            title="Facebook"
          >
            <Facebook />
          </Link>
          <Link
            href={NAV_LINKS.socialMedia.twitter}
            target="_blank"
            color="grey.primary"
            _hover={{ color: 'red.primary' }}
            title="Twitter"
          >
            <Twitter />
          </Link>
          <Link
            href={NAV_LINKS.socialMedia.youtube}
            target="_blank"
            color="grey.primary"
            _hover={{ color: 'red.primary' }}
            title="Youtube"
          >
            <Youtube />
          </Link>

          <Link
            href={NAV_LINKS.socialMedia.tripadvisor}
            target="_blank"
            color="grey.primary"
            _hover={{ color: 'red.primary' }}
            title="TripAdvisor"
          >
            <Tripadvisor />
          </Link>
          <Link
            href={NAV_LINKS.socialMedia.googleArts}
            target="_blank"
            color="grey.primary"
            _hover={{ color: 'red.primary' }}
            title="Google Arts"
          >
            <GoogleArts />
          </Link>
        </Grid>
        <Flex mt={{ xl: '28px', '2xl': '65px', lg: '105px' }} gap={5}>
          {NAV_LINKS.navigation.bottomRow.map((nav) => (
            <Link
              key={nav.id}
              href={createNavLink(nav.path, locale)}
              target={nav.isExternal ? '_blank' : '_self'}
              fontSize="xs"
              fontFamily={activeFontFamily}
              color="grey.primary"
              _hover={{ color: 'red.primary' }}
              textAlign="center"
            >
              {t(nav.labelTx)}
            </Link>
          ))}
        </Flex>
      </Box>

      <Divider
        orientation={isDesktop ? 'vertical' : 'horizontal'}
        borderColor="white.primary3"
        mt={{ base: '34px', lg: 0 }}
        mb={{ base: 'padding.primary', lg: 0 }}
      />
      <Flex
        flexDir="column"
        alignItems={{ base: 'baseline', lg: 'baseline' }}
        alignContent="flex-start"
        width="full"
        pl={{ base: 'padding.primary', lg: '9' }}
        pr={{ base: 'padding.primary', lg: 'padding.primary2' }}
      >
        <Grid
          width="100%"
          templateColumns={{
            base: '1fr',
            lg: 'repeat(2, 1fr) 2fr',
          }}
          columnGap={{ xl: '6px', '2xl': '50px' }}
          mt="10px"
        >
          <Flex flexDirection="column">
            <Text
              as="h4"
              color="red.primary"
              fontSize="md"
              mb="10px"
              mt="40px"
              fontFamily={activeFontFamily}
            >
              {t('nav:about')}
            </Text>
            {NAV_LINKS.navigation.aboutFooter.map((nav) => (
              <Link
                key={nav.id}
                href={createNavLink(nav.path, locale)}
                target={nav.isExternal ? '_blank' : '_self'}
                fontSize="sm"
                fontWeight="light"
                fontFamily={activeFontFamily}
                color="black.primary3"
                _hover={{ color: 'red.primary' }}
              >
                {t(nav.labelTx)}
              </Link>
            ))}

          </Flex>
          <Flex flexDirection="column">
            <Text
              as="h4"
              color="red.primary"
              fontSize="md"
              mt="40px"
              fontFamily={activeFontFamily}
            >
              {t("nav:subs")}
            </Text>
            <NewsletterForm
              buttonLabel={t("nav:register")}
              activeFontFamily={activeFontFamily}
            />
          </Flex>
          <Flex flexDirection="column" alignItems="center" p="20px" bg="white.primary"  boxShadow="sm" mt={{ base:10,lg:0}}>
            <Text
              as="h4"
              color="red.primary"
              fontSize="md"
              mt="20px"
              fontFamily={activeFontFamily}
            >
              {t("nav:support")}
            </Text>

            <Text
              fontSize="sm"
              mt="10px"
              mb="20px"
              textAlign="center"
              fontFamily={activeFontFamily}
            >
              {t("nav:supportText")}
            </Text>

            <Button
              as={Link}
              href={createNavLink('membership', locale)}
              mt="20px"
              position="relative"
              size="sm"
              fontFamily={activeFontFamily}
            >

              {t("nav:more")}
            </Button>
          </Flex>
        </Grid>
        <Grid>
          <Text
            as="h4"
            color="red.primary"
            fontSize="md"
            mb="10px"
            mt="40px"
            width="100%"
            fontFamily={activeFontFamily}
          >
            {t('nav:sponserAndSupporters')}
          </Text>
          <Flex
            wrap="wrap"
            justifyContent="left"
            gap={4}
          >
            <Link href="https://www.gilan.com/tr/">
              <Img
                p="10px"
                background="#f0f0f5"
                loading="lazy"
                src="/img/common/gilan.png"
                width="auto"
                height="40px"
                alt="Sponsors || SSM"
              />
            </Link>

            <Link href="https://www.medline.com.tr/">
            <Img
              p="6px"
              background="#f0f0f5"
              loading="lazy"
              src="/img/common/medline1.png"
              width="auto"
              height="40px"
              alt="Sponsors || SSM"
            />
            </Link>
            <Link href="https://www.jotun.com/tr-tr/decorative">

            <Img
              p="6px"
              background="#f0f0f5"
              loading="lazy"
              src="/img/common/jotun1.png"
              width="auto"
              height="40px"
              alt="Sponsors || SSM"
            />
            </Link>
            <Link href="https://www.thegrandtarabya.com/anasayfa.aspx?gad_source=1&gclid=CjwKCAiAgoq7BhBxEiwAVcW0LNd06DW6KQKdG650w82wQ0h4lI4dDsm7jcu7qm5DzL9qLkl3w93opxoCgQ0QAvD_BwE ">
            <Img
              p="6px"
              background="#f0f0f5"
              loading="lazy"
              src="/img/common/tgt_footer.png"
              width="auto"
              height="40px"
              alt="Sponsors || SSM"
            />
            </Link>
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  )
}

export default FooterNav
