import { v4 as uuidv4 } from 'uuid'
import { INewsAndBlogFilterOption } from '@interfaces'

const NEWS_AND_BLOG_SORT_FILTER_OPTIONS: INewsAndBlogFilterOption[] = [
  {
    id: uuidv4(),
    labelTx: 'explore-art:newsAndBlog.sortTypes.all',
    value: 'all',
  },
  {
    id: uuidv4(),
    labelTx: 'explore-art:newsAndBlog.sortTypes.new',
    value: 'new',
  },
  {
    id: uuidv4(),
    labelTx: 'explore-art:newsAndBlog.sortTypes.article',
    value: 'article',
  },
  {
    id: uuidv4(),
    labelTx: 'explore-art:newsAndBlog.sortTypes.interview',
    value: 'interview',
  },
  {
    id: uuidv4(),
    labelTx: 'explore-art:newsAndBlog.sortTypes.performance',
    value: 'performance',
  },
  {
    id: uuidv4(),
    labelTx: 'explore-art:newsAndBlog.sortTypes.film-and-video',
    value: 'film-and-video',
  },
  {
    id: uuidv4(),
    labelTx: 'explore-art:newsAndBlog.sortTypes.trip',
    value: 'trip',
  },
]

export default NEWS_AND_BLOG_SORT_FILTER_OPTIONS
