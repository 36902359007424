import {
  FullScreenSliderSection,
  Layout,
  NewsAndBlogCollectionGrid,
  ContentNotFound,
} from '@common'
import { INewsAndBlogCard, ISSRCollectionError, IGlobal } from '@interfaces'
// import BLOG_CAROUSEL_COLLECTION from '@lib/const/blog-carousel-collection.const'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

interface ICollectionData {
  allArticle: INewsAndBlogCard[]
  featuredArticles: INewsAndBlogCard[]
}

interface ICollection {
  data?: ICollectionData
  error?: ISSRCollectionError
}

interface IArticleTemplate extends IGlobal {
  pageData: ICollection
}

export const NewsAndBlogTemplate = ({
  pageData,
  global: { navigation },
}: IArticleTemplate) => {
  const { t } = useTranslation()
  const router = useRouter()

  if (pageData.error) {
    return (
      <Layout
        title={t('explore-art:newsAndBlog.pageTitle')}
        navigation={navigation}
        path={router.asPath}
      >
        <ContentNotFound infoType="serverSideError" />
      </Layout>
    )
  }

  return (
    <Layout
      title={t('explore-art:newsAndBlog.pageTitle')}
      navigation={navigation}
      path="/explore-art/news-and-blog"
    >
      <FullScreenSliderSection
        subTitle={t('explore-art:newsAndBlog.banner.subTitle')}
        title={t('explore-art:newsAndBlog.banner.title')}
        sliderCollection={pageData.data.featuredArticles}
        containerProps={{
          minHeight: { base: 'calc(100vh - 70px)', lg: 'calc(100vh - 125px)' },
          height: 'full',
        }}
        isHeroSection={true}
      />
      {!pageData.error && (
        <NewsAndBlogCollectionGrid collection={pageData.data.allArticle} />
      )}
    </Layout>
  )
}
