export const POPULAR_COLLECTION_SEARCH_TEARMS = {
  en: [
    {
      displayTerm: 'Archaeological',
      inCollection: 'Arkeolojik Eserler Koleksiyonu',
    },
    {
      displayTerm: 'Painting Collection',
      inCollection: 'Resim Koleksiyonu',
    },
    {
      displayTerm: 'The Arts of the Book and Calligraphy',
      inCollection: 'Kitap Sanatları ve Hat Koleksiyonu',
    },
    {
      displayTerm: 'Emirgan Archive',
      inCollection: 'Emirgan Archive',
    },
    {
      displayTerm: 'Abidin Dino Archive',
      inCollection: 'Abidin Dino Archive',
    },
    {
      displayTerm: 'Decorative Arts',
      inCollection: 'Dekoratif Eserler Koleksiyonu',
    },
  ],
  tr: [
    {
      displayTerm: 'Arkeolojik Eserler Koleksiyonu',
      inCollection: 'Arkeolojik Eserler Koleksiyonu',
    },
    {
      displayTerm: 'Resim Koleksiyonu',
      inCollection: 'Resim Koleksiyonu',
    },
    {
      displayTerm: 'Kitap Sanatları ve Hat Koleksiyonu',
      inCollection: 'Kitap Sanatları ve Hat Koleksiyonu',
    },
    {
      displayTerm: 'Emirgan Arşivi',
      inCollection: 'Emirgan Archive',
    },
    {
      displayTerm: 'Abidin Dino Arşivi',
      inCollection: 'Abidin Dino Archive',
    },
    {
      displayTerm: 'Dekoratif Eserler Koleksiyonu',
      inCollection: 'Dekoratif Eserler Koleksiyonu',
    },
  ],
}
