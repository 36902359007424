interface ISsmMap {
  iframeLink: string
}

const SsmMap = ({ iframeLink }: ISsmMap) => {
  if (!iframeLink) {
    return null
  }
  return <iframe src={iframeLink} width="100%" height="360" loading="lazy" />
}

export default SsmMap
