import {
  AspectRatio,
  Box,
  Button,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Catalog, Cross, Link as LinkIcon, Share } from '@icon'
import { IPublicationCard } from '@interfaces'
import { Link, RichText } from '@ui'
import { RWebShare } from 'react-web-share'
import {
  PUBLICATION_CHILD_IMAGE_ASPECT_RATIO_ON_MODAL,
  PUBLICATION_IMAGE_ASPECT_RATIO_ON_MODAL,
} from '../../../lib/const'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

interface IPublicationDetailModal extends IPublicationCard {
  isOpen: boolean
  epubLink: string
  onClose: () => void
}

const PublicationDetailModal = ({
  isOpen,
  onClose,
  pdfLink,
  epubLink,
  videoUrl,
  listeningUrl,
  pageCount,
  isbn,
  place,
  author,
  description,
  title,
  imgUrl,
  type,
  activeFontFamily,
}: IPublicationDetailModal) => {
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        background="blackAlpha.primary80"
        sx={{ backdropFilter: 'blur(8px)' }}
      />
      <ModalContent
        mt={{ base: 'padding.primary2', lg: 'padding.primary4' }}
        maxW={{ base: 'calc(100vw - 60px)', lg: '1063px' }}
        borderRadius="none"
        bgColor="white.primary"
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <ModalHeader
          flex="auto"
          width="full"
          maxWidth={{ base: 'full', lg: '413px' }}
          minH={{ base: '271px', lg: '267px' }}
          position="relative"
          p="0"
        >
          <Box
            position="relative"
            _before={{
              content: `" "`,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: 'full',
              height: 'full',
              background:
                'linear-gradient(rgba(13, 16, 29, 0.2), rgba(13, 16, 29, 0.1))',
              zIndex: 1,
            }}
          >
            <AspectRatio
              ratio={
                type === 'Çocuk Yayınları'
                  ? PUBLICATION_CHILD_IMAGE_ASPECT_RATIO_ON_MODAL
                  : PUBLICATION_IMAGE_ASPECT_RATIO_ON_MODAL
              }
              width="full"
              sx={{
                '& > img, & > video': {
                  objectFit: 'contain',
                },
              }}
            >
              <Img src={imgUrl || '/img/visit/hero/ssm-visit.jpg'} />
            </AspectRatio>
          </Box>
          <Box
            position="absolute"
            top="20px"
            left="20px"
            right="20px"
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            zIndex="2"
          >
            <RWebShare
              data={{
                text: title,
                url: router.basePath,
                title: `${title} | SSM`,
              }}
              closeText={t('common:closeLabel')}
            >
              <Button
                variant="unstyled"
                rightIcon={<Share />}
                color="white.primary"
                fontSize="sm"
                fontWeight="normal"
                fontFamily={activeFontFamily}
                alignItems="baseline"
              >
                {t('common:detailPageNavigation.share')}
              </Button>
            </RWebShare>
            <Button
              variant="unstyled"
              leftIcon={<Cross />}
              color="white.primary"
              fontSize="sm"
              fontWeight="normal"
              fontFamily={activeFontFamily}
              onClick={onClose}
            />
          </Box>
        </ModalHeader>
        <ModalBody
          py="padding.primary2"
          px={{ base: 'padding.primary', lg: 'padding.primary4' }}
          maxHeight={{ base: 'auto', lg: '516px' }}
          overflowY="auto"
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'white.primary3',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'red.primary3',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'red.primary2',
            },
          }}
        >
          <Text
            fontFamily={activeFontFamily}
            fontSize="lg"
            color="red.primary"
            mb="22px"
            as="h5"
          >
            {title || t('common:contentNotFound')}
          </Text>
          {description && (
            <RichText htmlContent={description} fontFamily={activeFontFamily} />
          )}
          <VStack spacing="1" alignItems="flex-start">
            {author && (
              <Text as="p" textStyle="richText" fontFamily={activeFontFamily}>
                {author}
              </Text>
            )}
            {place && (
              <Text as="p" textStyle="richText" fontFamily={activeFontFamily}>
                {place}
              </Text>
            )}
            {isbn && (
              <Text as="p" textStyle="richText" fontFamily={activeFontFamily}>
                {isbn}
              </Text>
            )}
            {pageCount && (
              <Text as="p" textStyle="richText" fontFamily={activeFontFamily}>
                {`${pageCount} ${t('common:page')}`}
              </Text>
            )}
          </VStack>
          {listeningUrl && (
            <Box
              as="audio"
              controls
              src={listeningUrl}
              mt="4"
              width="full"
              sx={{
                '::-webkit-media-controls-enclosure': {
                  borderRadius: '0px',
                  background: 'white.primary2',
                },
              }}
            >
              Your browser does not support the
              <code>audio</code> element.
            </Box>
          )}

          {videoUrl && (
            <Link
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgColor="red.primary"
              color="white.primary"
              _hover={{
                bgColor: 'red.primary3',
              }}
              height="50px"
              width="full"
              href={videoUrl || '#'}
              target="_blank"
              minWidth="75px"
              mt="4"
            >
              <LinkIcon color="red.primary2" />
              <Text
                ml="2.5"
                fontWeight="light"
                fontFamily={activeFontFamily}
                fontSize="sm"
              >
                {t('common:descriptiveWatchButtonLabel')}
              </Text>
            </Link>
          )}

          {pdfLink && pdfLink !== '' && (
            <Link
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgColor="red.primary"
              color="white.primary"
              _hover={{
                bgColor: 'red.primary3',
              }}
              height="50px"
              width="full"
              href={pdfLink}
              target="_blank"
              minWidth="75px"
              mt="4"
            >
              <Catalog color="red.primary2" />
              <Text
                ml="2.5"
                fontWeight="light"
                fontSize="sm"
                fontFamily={activeFontFamily}
              >
                PDF
              </Text>
            </Link>
          )}
          {epubLink && epubLink !== '' && (
            <Link
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgColor="red.primary"
              color="white.primary"
              _hover={{
                bgColor: 'red.primary3',
              }}
              height="50px"
              width="full"
              href={epubLink}
              target="_blank"
              minWidth="75px"
              mt="4"
            >
              <Catalog color="red.primary2" />
              <Text
                ml="2.5"
                fontWeight="light"
                fontSize="sm"
                fontFamily={activeFontFamily}
              >
                EPUB
              </Text>
            </Link>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PublicationDetailModal
