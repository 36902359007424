export const isPwa = () => {
  // on Android Chrome
  const isStandaloneOnAndroid = window.matchMedia(
    '(display-mode: standalone)'
  ).matches

  // on iOS Safari
  const isStandaloneOnIOS =
    'standalone' in window.navigator && window.navigator['standalone']

  // check Trusted Web Activity on android
  const isTWAOnAndroid = document.referrer.startsWith('android-app://')

  if (isTWAOnAndroid) {
    return true
  } else if (isStandaloneOnAndroid) {
    return true
  } else if (isStandaloneOnIOS) {
    return true
  }

  return false
}
