export const SIZES = {
  fillX: '100vw',
  fillY: '100vw',
  container: {
    secondary: '940px',
  },
  drawer: {
    xs: '77.5vw',
    lg: '470px',
    xl: '670px',
  },
  header: {
    default: '40px',
    negative: '-100px',
  },
}
