import drupalHttp from '../drupalHttpService'
import { HEADLINE } from '@lib/const/api-endpoints.const'

/**
 * Headline Http Service
 */
export const HeadlineHttpService = {
  /**
   * Get all headline drupalHttp service
   */
  getAllHeadline: async (locale: string) =>
    await drupalHttp.get(`${HEADLINE[locale]}`),
}
