import { createIcon } from '@chakra-ui/icons';

const GoogleArts = createIcon({
  displayName: 'GoogleArts',
  defaultProps: {
    width: '25px',
    height: '16px',
  },
  viewBox: '0 0 192 192',
  path: (
    <>
      <path d="M0 0h192v192H0z" fill="none" />
      <path
        d="M81.36 69.07c-24.92 3.18-44.19 24.47-44.19 50.26 0 27.98 22.69 50.67 50.67 50.67 26.23 0 47.8-19.93 50.41-45.47h16.58"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12"
      />
      <path
        d="M53.6 50.27C56.73 34.16 70.92 22 87.94 22c19.32 0 34.98 15.66 34.98 34.98 0 17.02-12.16 31.21-28.27 34.34M78.9 108.97 139.94 170"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12"
      />
    </>
  ),
})

export default GoogleArts
