import { Button, ButtonProps } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Filter } from '@icon'

interface IFilterButton {
  onClick: () => void
  containerProps?: ButtonProps
}

const FilterButton = ({ onClick, containerProps }: IFilterButton) => {
  const { t } = useTranslation()

  return (
    <Button
      gridArea="filterButton"
      leftIcon={<Filter />}
      variant="unstyled"
      display="flex"
      alignItems="center"
      color="red.primary"
      fontWeight="medium"
      fontSize="sm"
      onClick={onClick}
      {...containerProps}
    >
      {t('common:filterButtonLabel')}
    </Button>
  )
}

export default FilterButton
