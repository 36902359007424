import { Box } from '@chakra-ui/react'
import {
  ContentNotFound,
  DetailPageNavigationHeader,
  Hero,
  Layout,
  Sections,
  StickyHeader,
} from '@common'
import { useShare } from '@lib/hooks'
import { createNavLink } from '@utils'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { ISSRPageData } from '@interfaces'

export const GroupAndTourDetailTemplate = ({
  pageData,
  global: { navigation },
}: ISSRPageData) => {
  const { t } = useTranslation()
  const { asPath, locale, basePath } = useRouter()

  if (pageData.error) {
    return (
      <Layout
        title={'Groups and Tours Detail Page'}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound />
      </Layout>
    )
  }

  return (
    <Layout
      title={pageData.data.pageTitle}
      navigation={navigation}
      path={asPath}
    >
      <Hero
        subTitle={pageData.data.hero.subTitle}
        title={pageData.data.hero.title}
        description={pageData.data.hero.description}
        bgImageUrl={pageData.data.hero.bgImageUrl}
        headerNav={
          <DetailPageNavigationHeader
            leftButtonLabel={t('visit:detailPage.groupsAndTours')}
            leftButtonHref={createNavLink('groupsAndTours', locale)}
            rightButtonLabel={t('visit:detailPage.share')}
            rightButtonAction={useShare}
            isRightButtonShareAction={true}
            shareProps={{
              title: `${pageData.data.pageTitle} | SSM`,
              text: pageData.data.pageTitle,
              url: basePath,
            }}
          />
        }
        containerProps={{
          pt: 'padding.primary',
        }}
      />
      {pageData.data.stickyNavItems.length > 0 && (
        <Box as="main" pt={4}>
          <StickyHeader navs={pageData.data.stickyNavItems} />
          <Sections sections={pageData.data.sections} />
        </Box>
      )}
    </Layout>
  )
}
