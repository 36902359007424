/* eslint-disable no-inner-declarations */
import { ExtendedEMuseumHttpService } from '@httpServices'
import { IExtendedEMuseumData, IEventCardTypes } from '@interfaces'
import {
  generatePhotosAndVideosFromExtendedEMuseum,
  getSlugOfPressRelease,
  prepareVideoUrl,
} from '@utils'
import { v4 as uuidv4 } from 'uuid'
import { PageService, ShopService } from '@services'

export const ExtendedEMuseumService = {
  /**
   * Get products ids, google art link, 3d tour video link and press release data on drupalHttp service for limited e-Museum data
   * addUrlParams example: exhibition-4 | event-103 | collection-329
   */
  getExtendedEMuseum: async (
    locale: string | 'en' | 'tr',
    addUrlParams?: string,
    eMuseumType?: IEventCardTypes
  ): Promise<IExtendedEMuseumData> => {
    const extendedEMuseumData =
      await ExtendedEMuseumHttpService.getExtendedEMuseum(locale, addUrlParams)

    const extendedEMusuemData = {
      googleArtLink: null,
      matterportLink: null,
      digitalssmLink: null,
      ticketLink: null,
      discoverLink: null,
      threeDTourEmbedLink: null,
      pressRelease: null,
      photosAndVideos: null,
      products: null,
      bannerImage: null,
      mobileBannerImage: null,
      cardImage: null,
    }

    if (extendedEMuseumData.length === 0) {
      return extendedEMusuemData
    }

    // prepare googleArtLink
    if (extendedEMuseumData[0].field_em_gac_url.length > 0) {
      extendedEMusuemData.googleArtLink =
        extendedEMuseumData[0].field_em_gac_url[0]?.value || null
    }

    // prepare matterportLink
    if (extendedEMuseumData[0].field_em_matterport_url.length > 0) {
      extendedEMusuemData.matterportLink =
        extendedEMuseumData[0].field_em_matterport_url[0]?.value || null
    }

    // prepare digitalssmtLink
    if (extendedEMuseumData[0].field_em_digitalssm_url.length > 0) {
      extendedEMusuemData.digitalssmLink =
        extendedEMuseumData[0].field_em_digitalssm_url[0]?.value || null
    }

    // prepare ticketLink
    if (extendedEMuseumData[0].field_em_ticket_url.length > 0) {
      extendedEMusuemData.ticketLink =
        extendedEMuseumData[0].field_em_ticket_url[0]?.value || null
    }

    // prepare discover
    if (extendedEMuseumData[0].field_field_discover.length > 0) {
      extendedEMusuemData.discoverLink =
        extendedEMuseumData[0].field_field_discover.map((discoverItem) => ({
          title: discoverItem.title || null,
          url: discoverItem.uri || null,
        }))
    }

    // prepare threeDTourEmbedLink
    if (extendedEMuseumData[0].field_em_3dvideo_url.length > 0) {
      extendedEMusuemData.threeDTourEmbedLink =
        extendedEMuseumData[0].field_em_3dvideo_url[0]?.value || null
    }

    // prepare photosAndVideos
    const photosAndVideos = []

    // generate images
    if (extendedEMuseumData[0].field_em_object_image_collection.length > 0) {
      extendedEMuseumData[0].field_em_object_image_collection.map((img) => {
        photosAndVideos.push(
          generatePhotosAndVideosFromExtendedEMuseum({
            id: img?.target_id || uuidv4(),
            url: img?.url || '',
            alt: img?.alt || '',
            isVideo: false,
          })
        )
      })
    }

    // generate videos
    if (extendedEMuseumData[0].field_em_object_video_collection.length > 0) {
      const videoTargetIds = []
      extendedEMuseumData[0].field_em_object_video_collection.map((video) =>
        videoTargetIds.push(video?.target_id)
      )

      await Promise.all(
        videoTargetIds.map(async (videoTargetId) => {
          const videoDetail = await PageService.getVideoDetail(
            locale,
            videoTargetId
          )

          if (videoDetail.length > 0) {
            photosAndVideos.push(
              generatePhotosAndVideosFromExtendedEMuseum({
                id: videoDetail[0]?.nid[0]?.value || uuidv4(),
                url:
                  (videoDetail[0]?.field_page_video_url &&
                    videoDetail[0]?.field_page_video_provider &&
                    prepareVideoUrl(
                      videoDetail[0]?.field_page_video_url[0]?.value,
                      videoDetail[0]?.field_page_video_provider[0]?.value ||
                        'youtube'
                    )) ||
                  '',
                alt: videoDetail[0]?.title[0]?.value || '',
                isVideo: true,
                videoProvider:
                  videoDetail[0]?.field_page_video_provider[0]?.value ||
                  'youtube',
              })
            )
          }
        })
      )
    }

    if (photosAndVideos.length > 0) {
      extendedEMusuemData.photosAndVideos = photosAndVideos
    }

    // prepare pressRelease
    if (extendedEMuseumData[0].field_press_releases.length > 0) {
      extendedEMusuemData.pressRelease =
        (extendedEMuseumData[0].field_press_releases.length > 0 &&
          getSlugOfPressRelease(
            extendedEMuseumData[0].field_press_releases[0]?.url
          )) ||
        null
    }

    // prepare products
    if (extendedEMuseumData[0].field_em_related_products.length > 0) {
      const productTargetIds = []
      extendedEMuseumData[0].field_em_related_products.map((product) =>
        productTargetIds.push(product?.target_id)
      )

      const products = await Promise.all(
        productTargetIds.map(async (productTargetId) => {
          const productDetail = await ShopService.getProductDetail(
            productTargetId,
            locale,
            eMuseumType
          )

          return productDetail || null
        })
      )

      if (products.length > 0) {
        extendedEMusuemData.products = products.filter(
          (product) => product !== null
        )
      }
    }

    // prepare banner image url
    if (extendedEMuseumData[0].field_em_banner_image.length > 0) {
      extendedEMusuemData.bannerImage =
        extendedEMuseumData[0].field_em_banner_image[0]?.url.replace(
          'files',
          'files/styles/webp/public'
        ) + '.webp' || ''
    }

    // prepare mobile banner image url
    if (extendedEMuseumData[0].field_em_mobile_banner_image.length > 0) {
      extendedEMusuemData.mobileBannerImage =
        extendedEMuseumData[0].field_em_mobile_banner_image[0]?.url || ''
    }

    // prepare card image url
    if (extendedEMuseumData[0].field_em_card_image.length > 0) {
      extendedEMusuemData.cardImage =
        extendedEMuseumData[0].field_em_card_image[0]?.url || ''
    }

    return extendedEMusuemData
  },
}
