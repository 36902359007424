const title = 'SAKIP SABANCI MÜZESİ - SABANCI ÜNİVERSİTESİ'
const description =
  'Sabancı Üniversitesi Sakıp Sabancı Müzesi, zengin bir hat ve resim koleksiyonunu bünyesinde barındıran ve düzenlediği geçici sergilerle birçok ünlü sanatçının eserlerine ev sahipliği yapan bir sanat müzesidir.'
const siteUrl = `${process.env.NEXT_PUBLIC_SITE_URL}`

const SEO = {
  title,
  description,
  canonical: siteUrl,
  // TODO: update noindex value after production
  //  noindex: false,
  openGraph: {
    type: 'website',
    locale: 'tr_TR',
    url: siteUrl,
    title,
    description,
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_SITE_URL}/img/icon-512.png`,
        alt: title,
        width: 512,
        height: 512,
      },
    ],
  },
  twitter: {
    handle: '@SSabanciMuze',
    site: '@SSabanciMuze',
    cardType: 'summary_large_image',
  },
}

export default SEO
