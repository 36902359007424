import { createIcon } from '@chakra-ui/icons'

const Discover = createIcon({
  displayName: 'Discover',
  defaultProps: {
    width: 256,
    height: 326,
  },
  viewBox: '0 0 256 326',
  path: (
    <>
      <path
        d="M0 325.498h64.528l-4.058-16.579A16.947 16.947 0 0044.008 296h-.276a16.507 16.507 0 00-14.852 9.303l-.483.996a14.741 14.741 0 01-13.263 8.308 14.742 14.742 0 00-12.287 6.596L0 325.498zM114 325.498h64.528l-4.058-16.579A16.947 16.947 0 00158.008 296h-.276a16.506 16.506 0 00-14.852 9.303l-.483.996a14.742 14.742 0 01-13.263 8.308 14.742 14.742 0 00-12.287 6.596L114 325.498z"
        fill="#E2513D"
      />
      <circle cx="95.531" cy="54.388" r="19.358" fill="#fff" />
      <circle cx="237.976" cy="230.976" r="17.976" fill="#fff" />
      <path
        d="M104.667 36.746c11.664 8.63 28.116 6.17 36.746-5.494L99.173 0c-8.63 11.664-6.17 28.116 5.494 36.746z"
        fill="#345744"
      />
      <rect
        x="48.518"
        y="74.668"
        width="79.277"
        height="35.951"
        rx="17.976"
        fill="#FA79F7"
      />
      <path
        d="M63.55 140.599L9 292h54.95l31.284-92.33L126.92 292h52.944l-24.065-72.574 43.718 29.433L230 199.67l-107.379-77.364a38.726 38.726 0 00-59.071 18.293z"
        fill="#F8BB5B"
      />
      <path
        d="M76.173 61.42c3.934.947 12.208.497 13.827-8.876"
        stroke="#C71F3F"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </>
  ),
})

export default Discover
