import {
  Container,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Box,
  Button,
} from '@chakra-ui/react'
import { EventCard, EventInformationCard, Link } from '@ui'
import { observer } from 'mobx-react-lite'
import { useStore } from '@store'
import useTranslation from 'next-translate/useTranslation'
import { scroller } from 'react-scroll'
import { ITabData } from '@interfaces'
import { Swiper, SwiperSlide } from 'swiper/react'
// import TAB_DATA from './ExhibitionAndEventRelatedEvents.const'
import { createNavLink } from '@utils'
import { useRouter } from 'next/router'

interface IExhibitionAndEventRelatedEvents {
  tabData: ITabData[]
  stickyId?: string
}

const ExhibitionAndEventRelatedEvents = observer(
  ({
    tabData,
    stickyId = 'RelatedEvents',
  }: IExhibitionAndEventRelatedEvents) => {
    const { locale } = useRouter()
    const { ui } = useStore()
    const { t } = useTranslation()

    const scrollToTopSticky = () => scroller.scrollTo(stickyId, {})

    return (
      <Box
        as="section"
        id={stickyId}
        py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      >
        <Container>
          <Text
            fontSize={{ base: '2xl', lg: '3xl' }}
            as="h3"
            color="red.primary"
            mb={{ base: '4', lg: '5' }}
          >
            {t('exhibition-and-event:museumProgram.title')}
          </Text>
        </Container>
        <Tabs isLazy={true} lazyBehavior="keepMounted">
          <Container>
            <Grid
              gridAutoFlow="column"
              justifyContent="flex-start"
              overflowX="auto"
              background="white.primary"
              zIndex="1"
              position="sticky"
              top={ui.scrollDir === 'down' ? '0px' : '-100%'}
              py={{ base: 2, lg: 4 }}
              transition="all 0.3s ease"
              mb={{ base: 'padding.primary', lg: '5' }}
            >
              <TabList borderBottom="none">
                {tabData.map((tab) => (
                  <Tab
                    key={tab.id}
                    borderBottom="2px solid"
                    mb="0px"
                    sx={{
                      '&[aria-selected=true]': {
                        color: 'initial',
                        borderColor: 'red.primary',
                        fontWeight: 'semibold',
                      },
                      '&:focus': {
                        boxShadow: 'none',
                      },
                    }}
                    whiteSpace="pre"
                    onClick={scrollToTopSticky}
                  >
                    {t(tab.tabLabelTx)}
                  </Tab>
                ))}
              </TabList>
            </Grid>
          </Container>

          <TabPanels id="MuseumProgram">
            {tabData.map((tab, i) => (
              <TabPanel
                key={tab.id}
                p={0}
                sx={{
                  '& .relatedEventsSwiper.swiper-container': {
                    width: '100%',
                    height: '100%',
                    pl: { base: 'padding.primary', lg: 'padding.primary3' },
                  },
                  '& .relatedEventsSwiper .swiper-slide': {
                    width: '315px',
                  },
                }}
              >
                <Swiper
                  spaceBetween={20}
                  slidesPerView="auto"
                  grabCursor={true}
                  className={'relatedEventsSwiper'}
                  breakpoints={{
                    1170: {
                      initialSlide: 1,
                    },
                  }}
                >
                  {tab.tabContent.map((card) => (
                    <SwiperSlide key={card.id}>
                      <EventCard key={card.id} {...card} />
                    </SwiperSlide>
                  ))}
                  {tabData.length > 0 && (
                    <SwiperSlide>
                      <EventInformationCard
                        type={i === 0 ? 'Events' : 'Exhibitions'}
                        title={t(
                          i === 0
                            ? 'common:tabs.events'
                            : 'common:tabs.exhibitions'
                        )}
                        containerProps={{
                          minHeight: '500px',
                        }}
                      >
                        <Button
                          as={Link}
                          href={`${createNavLink(
                            'exhibitionAndEvent',
                            locale
                          )}?activeCollection=${
                            i === 0 ? 'events' : 'exhibitions'
                          }`}
                        >
                          {t('common:viewAll')}
                        </Button>
                      </EventInformationCard>
                    </SwiperSlide>
                  )}
                </Swiper>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    )
  }
)

export default ExhibitionAndEventRelatedEvents
