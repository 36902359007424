import { Box, Container, Text, Grid } from '@chakra-ui/react'
import { INewsAndBlogCard, IArticleTag } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import { NewsAndBlogCard, Link } from '@ui'

interface IMoreNewsAndBlog {
  title: string
  collection: INewsAndBlogCard[]
  tags: IArticleTag[]
  activeFontFamily: string
}

const MoreNewsAndBlog = ({
  title,
  collection,
  tags,
  activeFontFamily = 'spectral',
}: IMoreNewsAndBlog) => {
  const { t } = useTranslation()

  return (
    <Box py={{ base: 'padding.primary2', lg: 'padding.primary4' }}>
      <Container mb="padding.primary2">
        <Text
          fontFamily={activeFontFamily}
          color="red.primary"
          fontSize={{ base: '2xl', lg: '3xl' }}
        >
          {title}
        </Text>
      </Container>
      <Container
        maxWidth="container.secondary"
        px={{ base: 'padding.primary', lg: '0' }}
      >
        {collection.map(({ id, type, ...rest }, i) => (
          <NewsAndBlogCard
            key={id}
            type={t(`common:newsAndBlog.sortTypes.${type}`)}
            {...rest}
            isLastItem={i + 1 === collection.length}
          />
        ))}

        <Box mt="padding.primary2">
          <Text fontFamily={activeFontFamily} fontSize="lg" as="h5" mb="5">
            {t(`explore-art:newsAndBlog.tags`)}
          </Text>
          <Grid
            gridTemplateColumns="repeat(auto-fit, minmax(min(200px, 100%), 1fr))"
            columnGap="4"
            rowGap="2"
          >
            {tags.map((tag) => (
              <Link
                fontFamily={activeFontFamily}
                key={tag.id}
                href={tag.url}
                textDecoration="underline"
                fontSize="sm"
                fontWeight="light"
              >
                {tag.name}
              </Link>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default MoreNewsAndBlog
