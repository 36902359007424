import { Box, Grid } from '@chakra-ui/react'
import { EventCard, MotionBox } from '@ui'
import { fadeIn } from '@lib/motion'
import { IEventCard } from '@interfaces'

interface IGroupsAndToursCollection {
  tourCategories: IEventCard[]
}

const GroupsAndToursCollection = ({
  tourCategories,
}: IGroupsAndToursCollection) => {
  return (
    <Box
      maxW={{ base: 'full', lg: 'container.secondary' }}
      mx="auto"
      mt={{ base: 'padding.primary', lg: 'padding.primary2' }}
    >
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gridRowGap={{ base: 'padding.primary', lg: 9 }}
        gridColumnGap={{ base: 0, lg: 5 }}
      >
        {tourCategories.map((card) => (
          <Box key={card.id} overflow="hidden">
            <MotionBox variants={fadeIn.left}>
              <EventCard
                key={card.id}
                type="exhibition"
                title={card.title}
                description={card.description}
                imgUrl={card.imgUrl}
                url={card.url}
              />
            </MotionBox>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

export default GroupsAndToursCollection
