export const getCurrentLocalePropFromAPI = (
  value: string,
  locale: string | 'tr' | 'en'
) => {
  const splitedVal = value.split('[;]')
  const val = {
    en: splitedVal[0] === '' ? splitedVal[1] : splitedVal[0],
    tr: splitedVal[1] || splitedVal[0],
  }

  return val[locale]
}
