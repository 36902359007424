import { types } from 'mobx-state-tree'
import { ISearchResultsScreen } from '@interfaces'

export const Search = types
  .model({
    displaySearchModal: types.boolean,
    searchTerm: types.string,
    searchFacet: types.union(
      types.literal('all'),
      types.literal('collection'),
      types.literal('exhibition'),
      types.literal('object'),
      types.literal('people'),
      types.literal('event'),
      types.literal('publication'),
      types.literal('press_release'),
      types.literal('article')
    ),
    page: types.string,
    totalCount: types.string,
    totalPage: types.string,
  })
  .actions((self) => ({
    closeSideMenu() {
      self.displaySearchModal = false
    },
    toggleDisplaySearchModal() {
      self.displaySearchModal = !self.displaySearchModal
    },
    setSearchTerm(val: string) {
      self.searchTerm = val
    },
    setSearchFacet(val) {
      self.searchFacet = val
    },
    setPage(val) {
      self.page = val
    },
    setMetaData(metaData: ISearchResultsScreen['metaData']) {
      self.searchTerm = metaData.searchTerm.toString()
      self.searchFacet = metaData.searchFacet
      self.page = metaData.page.toString()
      self.totalPage = metaData.totalPage.toString()
      self.totalCount = metaData.totalCount.toString()
    },
  }))
