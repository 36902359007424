import { Box, Container, Text } from "@chakra-ui/react";
import {
  ContentNotFound,
  Hero,
  Layout,
  StickyHeader,
  ArtHeroSearchCard,
  ThreeDTourEmbedVideo,
  PhotoAndVideoSliderSection,
  SsmShopSliderSection
} from "@common";
import { Link as LinkIcon } from "@icon";
import { ISSRPageData } from "@interfaces";
import { Link as SSMLink, RichText } from "@ui";
import { useRouter } from "next/router";
import {
  checkStickyNavItem,
  getStickyId,
  createNavLink,
  splitCollectionSubtitle
} from "@utils";
import useTranslation from "next-translate/useTranslation";
import { useLoadMoreObjectsOfCollection } from "@lib/hooks";
import { useStore } from "@/src/models/Root";
import { useEffect } from "react";
import { POPULAR_COLLECTION_SEARCH_TEARMS } from "@/src/lib/const/popularCollectionSearchTerms.const";

export const CollectionDetailTemplate = ({
                                           pageData,
                                           global: { navigation }
                                         }: ISSRPageData) => {
  const { asPath, locale } = useRouter();

  const {
    artSearch: { setSelectedInCollectionOptions }
  } = useStore();

  useEffect(() => {
    const collectionTitle = pageData.data?.title;
    const filteredTitle = POPULAR_COLLECTION_SEARCH_TEARMS[locale].find(
      (term) => term.displayTerm == collectionTitle
    );

    if (filteredTitle) {
      setSelectedInCollectionOptions(filteredTitle.inCollection);
    }
  }, [locale, pageData.data, setSelectedInCollectionOptions]);

  const {} =
    useLoadMoreObjectsOfCollection(
      pageData.data.selectionsFromWorks.data,
      pageData.data.selectionsFromWorks.count,
      pageData.data.id,
      pageData.data?.type,
      locale
    );

  const { t } = useTranslation();

  if (pageData.error) {
    return (
      <Layout
        title={t(
          pageData.data?.type === "archive"
            ? "collections-and-research:archives.title"
            : "collections-and-research:collections.title"
        )}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound />
      </Layout>
    );
  }
  const isPageTypeArchive = pageData.data?.type === "archive";

  return (
    <Layout
      title={
        pageData.data?.title ||
        t(
          isPageTypeArchive
            ? "collections-and-research:archives.title"
            : "collections-and-research:collections.title"
        )
      }
      navigation={navigation}
      path={asPath}
    >
      <Hero
        bgImageUrl={
          pageData.data?.imgUrl ||
          "/img/collections-and-research/collections/banner/ssm-art-collections-banner.jpg"
        }
        subTitle={t(
          isPageTypeArchive
            ? "collections-and-research:archive"
            : "collections-and-research:collection"
        )}
        title={splitCollectionSubtitle(pageData.data?.title)}
      >
        <ArtHeroSearchCard isCollectionDetail={true} />
      </Hero>

      <Box as="main" pt={4}>
        <StickyHeader
          navs={pageData.data.stickyNavItems.filter(
            (item) => item.key !== "selectionsFromWorks"
          )}
          containerProps={{
            zIndex: 3
          }}
        >
          {pageData.data?.digitalssmLink && (
            <SSMLink
              display="flex"
              alignItems="center"
              fontSize="sm"
              fontWeight="light"
              color="black.primary3"
              _hover={{ cursor: "pointer" }}
              position="relative"
              whiteSpace="pre"
              href={pageData.data?.digitalssmLink}
              target="_blank"
            >
              digitalSSM
              <LinkIcon ml="10px" width="15px" height="15px" />
            </SSMLink>
          )}
          {pageData.data?.pressRelease && (
            <SSMLink
              display="flex"
              alignItems="center"
              fontSize="sm"
              fontWeight="light"
              color="black.primary3"
              _hover={{ cursor: "pointer" }}
              position="relative"
              whiteSpace="pre"
              href={`${createNavLink("press", locale)}/${
                pageData.data?.pressRelease
              }`}
            >
              {t("nav:stickyItems.pressRelease")}
              <LinkIcon ml="10px" width="15px" height="15px" />
            </SSMLink>
          )}

          {pageData.data?.googleArtLink && (
            <SSMLink
              display="flex"
              alignItems="center"
              fontSize="sm"
              fontWeight="light"
              color="black.primary3"
              _hover={{ cursor: "pointer" }}
              position="relative"
              whiteSpace="pre"
              href={pageData.data?.googleArtLink}
              target="_blank"
            >
              Google Arts & Culture
              <LinkIcon ml="10px" width="15px" height="15px" />
            </SSMLink>
          )}
        </StickyHeader>
        {checkStickyNavItem(
          pageData.data.stickyNavItems,
          isPageTypeArchive ? "aboutTheArchive" : "aboutTheCollection"
        ) && (
          <Box
            as="section"
            id={getStickyId(
              pageData.data.stickyNavItems,
              isPageTypeArchive ? "aboutTheArchive" : "aboutTheCollection"
            )}
            mt={{ base: "padding.primary2", lg: "77px" }}
            mb={{ base: "padding.primary2", lg: "padding.primary4" }}
          >
            <Container>
              <Text color="red.primary" fontSize={{ base: "2xl", lg: "3xl" }}>
                {t(
                  isPageTypeArchive
                    ? "collections-and-research:archives.navs.aboutTheArchive"
                    : "collections-and-research:collections.navs.aboutTheCollection"
                )}
              </Text>
            </Container>
            <Container
              maxW="container.secondary"
              mx="auto"
              mt={{ base: "padding.primary", lg: "padding.primary2" }}
            >
              {pageData?.data && pageData?.data?.description && (
                <RichText htmlContent={pageData?.data?.description} />
              )}
              {pageData.data?.digitalssmLink && (
                <SSMLink
                  alignItems="center"
                  fontSize="md"
                  variant="secondary"
                  fontWeight="light"
                  p="3"
                  mt="3"
                  color="white.primary3"
                  _hover={{ cursor: "pointer" }}
                  position="relative"
                  href={pageData.data?.digitalssmLink}
                  target="_blank"
                >
                  Arşivi incele
                  <LinkIcon color="white.primary" ml="10px" width="15px" height="15px" />
                </SSMLink>
              )}
            </Container>
          </Box>
        )}

        {checkStickyNavItem(pageData.data.stickyNavItems, "threeDTour") && (
          <ThreeDTourEmbedVideo
            stickyId={getStickyId(pageData.data.stickyNavItems, "threeDTour")}
            embedUrl={pageData.data?.threeDTourEmbedLink}
          />
        )}

        {checkStickyNavItem(
          pageData.data.stickyNavItems,
          "photosAndVideos"
        ) && (
          <PhotoAndVideoSliderSection
            stickyId={getStickyId(
              pageData.data.stickyNavItems,
              "photosAndVideos"
            )}
            sectionTitle={t("nav:stickyItems.photosAndVideos")}
            data={pageData.data?.photosAndVideos}
            pb={{ base: "padding.primary2", lg: "padding.primary4" }}
            my={{ base: "0", lg: "0" }}
          />
        )}

        {checkStickyNavItem(pageData.data.stickyNavItems, "giftShop") && (
          <SsmShopSliderSection
            stickyId={getStickyId(pageData.data.stickyNavItems, "giftShop")}
            products={pageData.data.products}
          />
        )}
      </Box>
    </Layout>
  );
};
