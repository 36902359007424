export const TEXT_STYLES = {
  richText: {
    fontSize: 'sm',
    fontWeight: 'light',
    lineHeight: '6',
    transition: 'all 0.3s ease',
    '&  p + p': {
      mt: '4',
    },
    '&  a': {
      textDecoration: 'underline',
      _hover: {
        color: 'red.primary',
      },
    },
    '& img': {
      maxWidth: 'full',
      mb: 'padding.primary',
    },
    '& ul': {
      pl: 7,
    },
  },
}
