import drupalHttp from '../drupalHttpService'
import {
  PAGE,
  VIDEO,
  PAGE_TAB,
  REST_BLOCK_FOR_PAGE_SECTION,
  ACCORDION_FOR_PAGE_SECTION,
  CONTACT_FOR_PAGE_SECTION,
  KIDS_PAGE,
  DYNAMIC_PAGES,
} from '@lib/const/api-endpoints.const'

/**
 * Page Http Service
 */
export const PageHttpService = {
  /**
   * Get all page data drupalHttp service
   * for example: https://drupal.sakipsabancimuzesi.org/tr/api/page
   */
  getAllPage: async (locale) => await drupalHttp.get(PAGE[locale]),
  /**
   * Get featured or learning page data drupalHttp service
   * for example: https://drupal.sakipsabancimuzesi.org/tr/api/page/featured
   * for example: https://drupal.sakipsabancimuzesi.org/tr/api/page/learning
   */
  getPageByFilterType: async (
    locale: string,
    filterType: 'featured' | 'learning'
  ) => await drupalHttp.get(`${PAGE[locale]}/${filterType}`),
  /**
   * Get video detail for photos and videos modules
   * for example: https://drupal.sakipsabancimuzesi.org/tr/api/pagevideo/12200
   */
  getVideoDetail: async (locale, targetId) =>
    await drupalHttp.get(`${VIDEO[locale]}/${targetId}`),
  /**
   * Get tab of page drupalHttp service
   */
  getTabOfPage: async (locale: string, id: string) =>
    await drupalHttp.get(`${PAGE_TAB[locale]}/${id}`),
  /**
   * Get block data for page tab section
   */
  getRestBlock: async (locale: string, id: string) =>
    await drupalHttp.get(`${REST_BLOCK_FOR_PAGE_SECTION[locale]}/${id}`),
  /**
   * Get accordion data for page tab section
   */
  getAccordion: async (locale: string, id: string) =>
    await drupalHttp.get(`${ACCORDION_FOR_PAGE_SECTION[locale]}/${id}`),
  /**
   * Get contact data for page tab section
   * tr/api/contact/ssm
   * tr/api/contact/seed
   * tr/api/contact/msa
   * tr/api/contact/rd
   */
  getContact: async (
    locale: string,
    activeScreen: 'ssm' | 'seed' | 'msa' | 'rd'
  ) =>
    await drupalHttp.get(`${CONTACT_FOR_PAGE_SECTION[locale]}/${activeScreen}`),
  getKidsPage: async (locale: string) =>
    await drupalHttp.get(`${KIDS_PAGE[locale]}`),
  getDynamicPages: async (locale: string) =>
    await drupalHttp.get(DYNAMIC_PAGES[locale]),
  getDynamicPage: async (locale: string, slug: string) =>
    await drupalHttp.get(`${PAGE[locale]}/${slug}`),
}
