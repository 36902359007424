import { Box } from '@chakra-ui/react'
import { IPhotoSliderSection } from '@interfaces'
import LightGallery from 'lightgallery/react'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import React, { useCallback, useRef, useState } from 'react'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'
import { ContainerResizeDetail, InitDetail } from 'lightgallery/lg-events'
import { LightGallery as LightGalleryType } from 'lightgallery/lightgallery'

function CollectionWorkDetailLightGallery({
  collection,
  activeFontFamily,
}: Pick<IPhotoSliderSection, 'collection' | 'activeFontFamily'>) {
  const [container, setContainer] = useState<HTMLDivElement>(null)

  const setContainerRef = useCallback((node) => {
    if (node !== null) {
      setContainer(node)
    }
  }, [])

  const lightGallery = useRef<LightGalleryType>(null)
  const onInit = useCallback((detail: InitDetail) => {
    if (detail) {
      lightGallery.current = detail.instance
      lightGallery.current.openGallery()
    }
  }, [])

  const onContainerResize = useCallback((event: ContainerResizeDetail) => {
    const isZoomed = lightGallery.current.$container
      .find('.lg-outer')
      .hasClass('lg-zoomed')

    if (lightGallery.current && isZoomed) {
      lightGallery.current.plugins[1].setActualSize(
        lightGallery.current.index,
        event
      )
    }
  }, [])

  const getLgComponent = () => {
    if (container !== null) {
      return (
        <LightGallery
          animateThumb={false}
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
          thumbnail={true}
          download={false}
          zoom={true}
          pager={false}
          height="500px"
          showCloseIcon={false}
          showMaximizeIcon={true}
          mobileSettings={{
            controls: false,
            download: false,
            rotate: false,
          }}
          closable={false}
          dynamic
          dynamicEl={collection.map((col) => ({
            id: col.id,
            src: col.url,
            thumb: col.url,
            subHtml: col.alt,
          }))}
          onContainerResize={onContainerResize}
          onInit={onInit}
          container={container}
        />
      )
    }
    return null
  }

  return (
    <Box
      ref={setContainerRef}
      as="div"
      width="full"
      bgColor="white.primary"
      position="relative"
      height="500px"
      sx={{
        '.lg-inline .lg-backdrop, .lg-inline .lg-outer .lg-thumb-outer': {
          backgroundColor: '#f9f7f0',
        },
        '.lg-inline .lg-icon:hover': {
          color: '#000',
        },
        '.lg-inline .lg-icon, .lg-inline .lg-prev, .lg-inline .lg-next': {
          color: '#4c4c4b',
        },
        '.lg-sub-html': {
          fontFamily: activeFontFamily ?? 'Spectral',
        },
        '.lg-inline .lg-sub-html': {
          color: '#000',
        },
        '.lg-outer.lg-single-item .lg-thumb-outer': {
          display: 'block',
        },
      }}
    >
      {getLgComponent()}
    </Box>
  )
}
export default CollectionWorkDetailLightGallery
