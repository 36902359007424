import drupalHttp from '../drupalHttpService'
import { PLANT } from '@lib/const/api-endpoints.const'

/**
 * Plant Http Service
 */
export const PlantHttpService = {
  /**
   * Get all plant drupalHttp service
   */
  getAllPlant: async (locale: string, addUrlParams?: string) =>
    await drupalHttp.get(`${PLANT[locale]}${addUrlParams || ''}`),
}
