import { Box } from '@chakra-ui/react'
import 'photoswipe/dist/default-skin/default-skin.css'
import 'photoswipe/dist/photoswipe.css'
import React from 'react'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { IVideoSliderCollection, IFonts } from '@interfaces'
import { ExhibitionVideoCard } from '@ui'
import { Swiper, SwiperSlide } from 'swiper/react'

interface IVideoSliderSection {
  videoCollection: IVideoSliderCollection[]
  activeFontFamily?: IFonts
}

const VideoSliderSection = ({
  videoCollection,
  activeFontFamily = 'body',
}: IVideoSliderSection) => {
  const generatedIframeNodes = () => {
    const videosHtmlNode = videoCollection.map((video) => {
      return `
        <iframe
        width="85%"
        height="85%"
        src=${video.youtubeEmbedUrl}
        frameBorder="0"
        allowFullScreen
      />`
    })

    return videosHtmlNode
  }

  return (
    <Box
      className="video-slider-section"
      sx={{
        '&.video-slider-section .online-exhibition-video-carousel .swiper-slide':
          {
            width: 'auto',
          },
        '&.video-slider-section .pswp__zoom-wrap': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Gallery
        options={{
          getThumbBoundsFn: undefined,
          showHideOpacity: true,
          shareEl: false,
        }}
      >
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={20}
          initialSlide={1}
          className="online-exhibition-video-carousel"
        >
          {videoCollection &&
            videoCollection.map((video, i) => (
              <SwiperSlide key={video.id}>
                <Item html={generatedIframeNodes()[i]}>
                  {({ open, ref }) => (
                    <ExhibitionVideoCard
                      key={video.id}
                      onClick={(e) => {
                        e.preventDefault()
                        open()
                      }}
                      ref={ref as React.MutableRefObject<HTMLAnchorElement>}
                      href="#"
                      title={video.title}
                      description={video.description}
                      posterImgUrl={video.posterImgUrl}
                      activeFontFamily={activeFontFamily}
                    />
                  )}
                </Item>
              </SwiperSlide>
            ))}
        </Swiper>
      </Gallery>
    </Box>
  )
}

export default VideoSliderSection
