import { IPhotoAndVideoSliderSectionCollection } from '@interfaces'

export const generatePhotosAndVideosFromExtendedEMuseum = ({
  id,
  url,
  alt,
  isVideo,
  videoProvider = 'youtube',
}: IPhotoAndVideoSliderSectionCollection) => {
  return {
    id,
    url,
    alt,
    isVideo,
    videoProvider,
  }
}
