import { ISectionData } from '@interfaces'

// preparing sections data
export const prepareSectionData = (
  __component: ISectionData['__component'],
  data: ISectionData['data'],
  sectionTitle: ISectionData['sectionTitle'],
  stickyId: ISectionData['stickyId'],
  id: ISectionData['id'],
  activeScreen?: ISectionData['activeScreen']
): ISectionData => ({
  __component,
  data,
  sectionTitle,
  stickyId,
  id,
  activeScreen,
})
