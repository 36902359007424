import { Box, Button, Divider, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Link } from '@ui'
import { Pdf } from '@icon'
import { IScientificPublicationCard } from '@interfaces'
import { Markup } from 'interweave'

const ScientificPublicationCard = ({
  date,
  title,
  shortDescription,
  url,
  isLastItem = false,
}: IScientificPublicationCard) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box>
        <Text
          mb="2.5"
          color="grey.primary"
          fontSize="sm"
          fontWeight="light"
          as="h5"
        >
          {date}
        </Text>

        <Text mb="3.5" fontSize="lg" as="h4">
          {title}
        </Text>
        <Box mb="2.5" fontSize="sm" fontWeight="light">
          <Markup content={shortDescription} />
        </Box>
        <Button
          as={Link}
          href={url}
          target="_blank"
          width="240px"
          height="50px"
          variant="secondary"
          leftIcon={<Pdf />}
        >
          {t('common:buttonExamineLabel')}
        </Button>
      </Box>
      {!isLastItem && (
        <Divider
          borderBottomColor="white.primary3"
          opacity="1"
          my="padding.primary"
        />
      )}
    </Box>
  )
}

export default ScientificPublicationCard
