import {
  Box,
  Flex,
  Grid,
  Image,
  Text,
  Button,
  Container,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import SwiperCore, { Navigation, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SsmShopSliderCard, Link, ImageButton } from '@ui'
import { IDaysAndTicketsCard } from '@interfaces'
import { useState } from 'react'
// import SSM_SHOP_SLIDER_COLLECTION from '@lib/const/ssm-shop-slider-collection.const'

// install Swiper modules
SwiperCore.use([Navigation, Controller])

interface ISsmShopSliderSection {
  stickyId?: string
  products: IDaysAndTicketsCard[]
}

const SsmShopSliderSection = ({
  stickyId = 'giftShop',
  products,
}: ISsmShopSliderSection) => {
  // store swiper instances
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  const [currentBgSize, setCurrentBgSize] = useState(false)
  const { t } = useTranslation()

  return (
    <Box as="section" id={stickyId}>
      <Container>
        <Text
          color="red.primary"
          mt={{ base: 'padding.primary2', lg: '77px' }}
          fontSize={{ base: '2xl', lg: '3xl' }}
        >
          {t('common:ssmShopSliderSection.shop')}
        </Text>
      </Container>
      <Grid
        gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}
        templateAreas={{
          base: `'shop-card-content' 'shop-card-img'`,
          lg: `'shop-card-content shop-card-img'`,
        }}
        height={{ base: 'auto', lg: 'calc(100vh - 65px)' }}
        maxHeight={{ base: 'auto', lg: '800px' }}
      >
        <Flex
          height={{ base: 'auto', lg: 'calc(100vh - 65px)' }}
          maxHeight={{ base: 'auto', lg: '800px' }}
          flexDirection="column"
          alignItems="flex-start"
          px="padding.primary"
          pt="padding.primary2"
          pb="padding.primary"
          gridArea="shop-card-content"
          position="relative"
        >
          <Box
            width="full"
            height="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="300px"
              key="hero-slider"
              className="shopSliderSection"
              sx={{
                '&.shopSliderSection .swiper-container': {
                  width: 'full',
                  height: 'full',
                  position: 'static',
                },
              }}
            >
              <Swiper
                pagination={{
                  el: '.swiper-pagination',
                  type: 'fraction',
                }}
                navigation={{
                  nextEl: '.shop-swiper-button-next',
                  prevEl: '.shop-swiper-button-prev',
                }}
                grabCursor={true}
                watchOverflow
                onSwiper={setFirstSwiper}
                controller={{ control: secondSwiper }}
              >
                {products.map(({ id, ...rest }) => (
                  <SwiperSlide key={id}>
                    <SsmShopSliderCard {...rest} />
                  </SwiperSlide>
                ))}
                <Flex
                  position="absolute"
                  flexDirection="row"
                  alignItems="flex-end"
                  minHeight="30px"
                  top="auto"
                  left="auto"
                  bottom="10"
                  right="padding.primary"
                  zIndex="1"
                  sx={{
                    '.shop-swiper-button-next, .shop-swiper-button-prev': {
                      width: '16px',
                      height: 'auto',
                      color: 'red.primary2',
                    },
                    '.shop-swiper-button-next:after, .shop-swiper-button-prev:after':
                      {
                        fontSize: 'md',
                      },
                    '.shop-swiper-button-next.swiper-button-disabled, .shop-swiper-button-prev.swiper-button-disabled':
                      {
                        opacity: 1,
                      },
                  }}
                >
                  <Box
                    role="button"
                    aria-label="Shop slider previous button"
                    position="static"
                    className="swiper-button-prev shop-swiper-button-prev"
                    sx={{
                      '&:after': {
                        fontsize: 'md',
                      },
                    }}
                  />
                  <Box
                    role="button"
                    aria-label="Shop slider next button"
                    position="static"
                    className="swiper-button-next shop-swiper-button-next"
                    ml="padding.primary"
                    sx={{
                      '&:after': {
                        fontsize: 'md',
                      },
                    }}
                  />
                </Flex>
              </Swiper>
            </Box>
          </Box>
        </Flex>
        <Box
          d={{ base: 'none', lg: 'block' }}
          height={{ base: 'auto', lg: 'calc(100vh - 65px)' }}
          maxHeight={{ base: 'auto', lg: '800px' }}
          gridArea="shop-card-img"
          pb={{ base: '20', lg: '0' }}
          position="relative"
          className="productPhotoSlider"
          sx={{
            '&.productPhotoSlider .swiper-container': {
              height: 'full',
            },
          }}
        >
          <Image
            src="/img/shop/shop-bnr-exhibitions.jpg"
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
            alt={t('common:ssmShop.sectionTitle')}
            height={{ base: 'auto', lg: 'calc(100vh - 65px)' }}
            maxHeight={{ base: 'auto', lg: '100%' }}
            objectFit={'cover'}
            margin="auto"
            width={currentBgSize ? 'full' : 'auto'}
          />
          <Box d="none">
            <Swiper
              onSwiper={setSecondSwiper}
              controller={{ control: firstSwiper }}
              slidesPerView={'auto'}
            >
              {products.map(({ id, productPhoto, productName }) => (
                <SwiperSlide key={id}>
                  <Image
                    src={productPhoto}
                    fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
                    alt={productName || t('common:ssmShop.sectionTitle')}
                    height={{ base: 'auto', lg: 'calc(100vh - 65px)' }}
                    maxHeight={{ base: 'auto', lg: '80%' }}
                    objectFit={currentBgSize ? 'cover' : 'contain'}
                    margin="auto"
                    width={currentBgSize ? 'full' : 'auto'}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>

          <Button
            as={Link}
            href="https://sakipsabancimuzesi.org/shop/"
            target="_blank"
            variant="secondary"
            position="absolute"
            left="auto"
            bottom="0"
            right={{ base: '0', lg: '0' }}
            width={{ base: 'full', lg: '240px' }}
            height={{ base: '80px', lg: '100px' }}
            zIndex="1"
          >
            {t('common:goToStore')}
          </Button>
          <ImageButton
            d="none"
            aria-label="Toggle image object fit"
            onClick={() => setCurrentBgSize((oldState) => !oldState)}
            left="auto"
            right="0"
          />
        </Box>
      </Grid>
    </Box>
  )
}

export default SsmShopSliderSection
