const parseEventSourceId = (eventString) => {
  const parsedEventString = eventString.split('[;]')
  const eventSourceId = parsedEventString[1]

  return eventSourceId
}

/**
 * from "148586[;]26" or ["148571[;]93","148617[;]97"]
 * to ["26"] or ["93","97"]
 */
export const getEventSourceIdsOfExhibition = (events: string | string[]) => {
  const eventSourceIds = []

  if (events instanceof Array) {
    events.map((e) => eventSourceIds.push(parseEventSourceId(e)))
  }

  if (typeof events === 'string') {
    eventSourceIds.push(parseEventSourceId(events))
  }

  return eventSourceIds
}
