import { Container, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { IFonts } from '@interfaces'

interface ICollectionPaginationText {
  activeLengthOnPage: number
  totalLength: number
  activeFontFamily?: IFonts
}

const CollectionPaginationText = ({
  activeLengthOnPage,
  totalLength,
  activeFontFamily = 'body',
}: ICollectionPaginationText) => {
  const { t } = useTranslation()

  return (
    <Container mt="5">
      <Text
        textAlign="center"
        fontFamily={activeFontFamily}
        fontWeight="light"
        fontSize="sm"
      >
        {t('common:paginationLabel', { activeLengthOnPage, totalLength })}
      </Text>
    </Container>
  )
}

export default CollectionPaginationText
