import { OnlineExhibitionHttpService } from '@httpServices'
import {
  IStickyNav,
  IPageTab,
  IArtCollection,
  IArtCollectionItemType,
  IEventCard,
} from '@interfaces'
import {
  generatePhotosAndVideosFromExtendedEMuseum,
  prepareVideoUrl,
  createNavLink,
  generateConferenceOfOnlineExhibition,
  parseISODate,
} from '@utils'
import { v4 as uuidv4 } from 'uuid'
import { PageService } from '@services'
import COMMON_TR from '@/locales/tr/common.json'
import COMMON_EN from '@/locales/en/common.json'

export const OnlineExhibitionsService = {
  /**
   * Get online exhibitions page drupalHttp service
   */
  getOnlineExhibitions: async (locale): Promise<IEventCard[]> => {
    const onlineExhibitions =
      await OnlineExhibitionHttpService.getOnlineExhibitions(locale)

    return onlineExhibitions.map((exhibition) => ({
      id: (exhibition?.nid && exhibition?.nid[0]?.value) || '',
      type: 'online',
      tagsTx: ['online-exhibition'],
      title: (exhibition?.title && exhibition?.title[0]?.value) || '',
      date:
        (exhibition?.field_oe_exhibition_date &&
          exhibition?.field_oe_exhibition_date.length > 0 &&
          exhibition?.field_oe_exhibition_date[0]?.value &&
          exhibition?.field_oe_exhibition_date[0]?.end_value &&
          `${parseISODate(
            exhibition?.field_oe_exhibition_date[0]?.value,
            locale
          )} - ${parseISODate(
            exhibition?.field_oe_exhibition_date[0]?.end_value,
            locale
          )}`) ||
        '',
      imgUrl:
        (exhibition?.field_oe_card_image &&
          exhibition?.field_oe_card_image[0]?.url) ||
        '',
      url: `${createNavLink('online', locale)}/${
        (exhibition?.nid && exhibition?.nid[0]?.value) || ''
      }`,
    }))
  },
  /**
   * Get online exhibition
   */
  getOnlineExhibition: async (locale: string | 'en' | 'tr', slug?: string) => {
    const pageData = {
      data: {
        pageTitle: null,
        hero: {
          mobileImgUrl: null,
          desktopImgUrl: null,
        },
        stickyNavItems: null,
        storyOfTheExhibition: null,
        aboutTheArtist: {
          artistName: null,
          artistLifeTimeRange: null,
          artistLife: null,
          artistPdfLink: null,
          artistQuote: null,
          artistPhotos: null,
        },
        aboutTheExhibition: {
          exhibitionTitle: null,
          exhibitionDescription: null,
          exhibitionVideoUrl: null,
        },
        periodsArtifacts: null,
        catalog: null,
        conferences: null,
        forKids: null,
        otherOnlineExhibitions: null,
        data: null,
      },
      error: null,
    }

    try {
      const onlineExhibition =
        await OnlineExhibitionHttpService.getOnlineExhibition(locale, slug)

      if (onlineExhibition && onlineExhibition.length > 0) {
        const cleanOnlineExhibition = onlineExhibition[0]
        pageData.data.data = cleanOnlineExhibition

        // prepare page title and hero
        pageData.data.pageTitle = cleanOnlineExhibition?.title[0]?.value || ''
        pageData.data.hero.desktopImgUrl =
          cleanOnlineExhibition?.field_oe_banner_image[0]?.url || ''

        pageData.data.hero.mobileImgUrl =
          cleanOnlineExhibition?.field_oe_card_image[0]?.url || ''

        // prepare sticky nav items
        // preparing dynamic tabs
        const tabIds = cleanOnlineExhibition.field_oe_tabs.map(
          (t) => t.target_id
        )

        // get tabs of page initial data
        const initialTabsOfPage = await OnlineExhibitionsService.getTabsOfPage(
          locale,
          tabIds
        )
        const preparedStickyNavItems =
          await OnlineExhibitionsService.prepareStickyNavItems(
            initialTabsOfPage
          )
        pageData.data.stickyNavItems = preparedStickyNavItems

        // prepare gallery
        const gallery = []

        // generate images
        if (cleanOnlineExhibition?.field_oe_sofexh_imgcollection.length > 0) {
          cleanOnlineExhibition?.field_oe_sofexh_imgcollection.map((img) => {
            gallery.push(
              generatePhotosAndVideosFromExtendedEMuseum({
                id: img?.target_id || uuidv4(),
                url: img?.url || '',
                alt: img?.alt || '',
                isVideo: false,
              })
            )
          })
        }

        // generate videos
        if (
          cleanOnlineExhibition?.field_oe_sofexh_videocollection &&
          cleanOnlineExhibition?.field_oe_sofexh_videocollection.length > 0
        ) {
          const videoTargetIds = []
          cleanOnlineExhibition?.field_oe_sofexh_videocollection.map((video) =>
            videoTargetIds.push(video?.target_id)
          )

          await Promise.all(
            videoTargetIds.map(async (videoTargetId) => {
              const videoDetail = await PageService.getVideoDetail(
                locale,
                videoTargetId
              )

              if (videoDetail.length > 0) {
                gallery.push(
                  generatePhotosAndVideosFromExtendedEMuseum({
                    id: videoDetail[0]?.nid[0]?.value || uuidv4(),
                    url:
                      (videoDetail[0]?.field_page_video_url &&
                        videoDetail[0]?.field_page_video_provider &&
                        prepareVideoUrl(
                          videoDetail[0]?.field_page_video_url[0]?.value,
                          videoDetail[0]?.field_page_video_provider[0]?.value ||
                            'youtube'
                        )) ||
                      '',
                    alt: videoDetail[0]?.title[0]?.value || '',
                    isVideo: true,
                    videoProvider:
                      videoDetail[0]?.field_page_video_provider[0]?.value ||
                      'youtube',
                  })
                )
              }
            })
          )
        }

        if (gallery.length > 0) {
          pageData.data.storyOfTheExhibition = gallery
        }

        // prepare artist detail
        if (
          cleanOnlineExhibition?.field_oe_artist_life &&
          cleanOnlineExhibition?.field_oe_artist_life.length > 0
        ) {
          const artist = {
            artistName: null,
            artistLifeTimeRange: null,
            artistLife: null,
            artistPdfLink: null,
            artistQuote: null,
            artistPhotos: null,
          }

          artist.artistName =
            cleanOnlineExhibition?.field_oe_artist_name[0]?.value || ''
          artist.artistLifeTimeRange =
            cleanOnlineExhibition?.field_oe_artist_lifetimerange[0]?.value || ''
          artist.artistLife =
            cleanOnlineExhibition?.field_oe_artist_life[0]?.value || ''
          artist.artistPdfLink =
            cleanOnlineExhibition?.field_oe_artist_lifeaboutpdflink[0]?.value ||
            ''
          artist.artistQuote =
            cleanOnlineExhibition?.field_oe_artist_quote[0]?.value || ''

          const artistPhotoCollection = []

          // generate artist photo collection
          if (
            cleanOnlineExhibition?.field_oe_artist_photocollection.length > 0
          ) {
            cleanOnlineExhibition?.field_oe_artist_photocollection.map(
              (img) => {
                artistPhotoCollection.push({
                  id: img?.target_id || uuidv4(),
                  imgUrl: img?.url || '',
                  imgAlt: img?.alt || '',
                })
              }
            )
          }
          artist.artistPhotos = artistPhotoCollection

          pageData.data.aboutTheArtist = artist
        }

        // prepare exhibition story
        const aboutTheExhibition = {
          exhibitionTitle: null,
          exhibitionDescription: null,
          exhibitionVideoUrl: null,
        }

        aboutTheExhibition.exhibitionTitle =
          cleanOnlineExhibition?.field_oe_exhibition_title[0]?.value || ''

        aboutTheExhibition.exhibitionDescription =
          cleanOnlineExhibition?.field_oe_exhibition_description[0]?.value || ''

        // prepare exhibition video url
        const videoTargetId =
          cleanOnlineExhibition?.field_oe_exhibition_videocollect[0]
            ?.target_id || null

        if (videoTargetId) {
          const videoDetailData = await PageService.getVideoDetail(
            locale,
            videoTargetId
          )

          if (videoDetailData.length > 0) {
            const videoUrl = prepareVideoUrl(
              videoDetailData[0]?.field_page_video_url[0]?.value,
              videoDetailData[0]?.field_page_video_provider[0]?.value ||
                'youtube'
            )

            aboutTheExhibition.exhibitionVideoUrl = videoUrl
          }
        }

        pageData.data.aboutTheExhibition = aboutTheExhibition

        // prepare periods
        if (
          cleanOnlineExhibition?.field_oe_related_periods &&
          cleanOnlineExhibition?.field_oe_related_periods.length > 0
        ) {
          // prepare period ids
          const periodIds = cleanOnlineExhibition?.field_oe_related_periods.map(
            (period) => period?.target_id
          )

          // get periods
          const periods =
            await OnlineExhibitionsService.getPeriodsOfOnlineExhibition(
              locale,
              periodIds,
              slug
            )

          if (periods.length > 0) {
            pageData.data.periodsArtifacts = periods
          }
        }

        // prepare catalog
        if (
          cleanOnlineExhibition?.field_oe_catalog_name &&
          cleanOnlineExhibition?.field_oe_catalog_name.length > 0
        ) {
          const catalog = {
            catalogName: null,
            catalogDescriptions: null,
            catalogImgUrl: null,
            catalogPdfLink: null,
          }

          catalog.catalogName =
            cleanOnlineExhibition?.field_oe_catalog_name[0]?.value || null
          catalog.catalogDescriptions =
            cleanOnlineExhibition?.field_oe_catalog_description[0]?.value ||
            null
          catalog.catalogImgUrl =
            cleanOnlineExhibition?.field_oe_catalog_imgurl[0]?.url || null
          catalog.catalogPdfLink =
            cleanOnlineExhibition?.field_oe_catalog_pdflink[0]?.url || null
          pageData.data.catalog = catalog
        }

        // prepare conference
        const conferences = []
        const conferenceVideoTargetIds = []
        if (
          cleanOnlineExhibition?.field_oe_conf_videocollection &&
          cleanOnlineExhibition?.field_oe_conf_videocollection.length > 0
        ) {
          cleanOnlineExhibition?.field_oe_conf_videocollection.map((video) =>
            conferenceVideoTargetIds.push(video?.target_id)
          )

          await Promise.all(
            conferenceVideoTargetIds.map(async (videoTargetId) => {
              const videoDetail = await PageService.getVideoDetail(
                locale,
                videoTargetId
              )

              if (videoDetail.length > 0) {
                conferences.push(
                  generateConferenceOfOnlineExhibition({
                    id: videoDetail[0]?.nid[0]?.value || uuidv4(),
                    youtubeEmbedUrl:
                      (videoDetail[0]?.field_page_video_url &&
                        videoDetail[0]?.field_page_video_provider &&
                        prepareVideoUrl(
                          videoDetail[0]?.field_page_video_url[0]?.value,
                          videoDetail[0]?.field_page_video_provider[0]?.value ||
                            'youtube'
                        )) ||
                      '',
                    title: videoDetail[0]?.title[0]?.value || '',
                    description:
                      videoDetail[0]?.field_page_video_description[0]?.value ||
                      '',
                    posterImgUrl:
                      videoDetail[0]?.field_page_video_poster[0]?.url || '',
                  })
                )
              }
            })
          )

          if (conferences.length > 0) {
            pageData.data.conferences = conferences
          }
        }

        // prepare child
        const forKids = []

        // first card
        if (
          cleanOnlineExhibition?.field_oe_kids_card1_img &&
          cleanOnlineExhibition?.field_oe_kids_card1_img.length > 0 &&
          cleanOnlineExhibition?.field_oe_kids_card1_title &&
          cleanOnlineExhibition?.field_oe_kids_card1_title.length > 0
        ) {
          const kidCard = {
            id: uuidv4(),
            imgUrl:
              (cleanOnlineExhibition?.field_oe_kids_card1_img &&
                cleanOnlineExhibition?.field_oe_kids_card1_img[0]?.url) ||
              null,
            imgAlt:
              (cleanOnlineExhibition?.field_oe_kids_card1_img &&
                cleanOnlineExhibition?.field_oe_kids_card1_img[0]?.alt) ||
              null,
            title:
              (cleanOnlineExhibition?.field_oe_kids_card1_title &&
                cleanOnlineExhibition?.field_oe_kids_card1_title[0]?.value) ||
              null,
            pdfUrl:
              (cleanOnlineExhibition?.field_oe_kids_card1_pdfurl &&
                cleanOnlineExhibition?.field_oe_kids_card1_pdfurl[0]?.value) ||
              null,
            listeningUrl:
              (cleanOnlineExhibition?.field_oe_kids_card1_audiourl &&
                cleanOnlineExhibition?.field_oe_kids_card1_audiourl[0]
                  ?.value) ||
              null,
            videoUrl:
              (cleanOnlineExhibition?.field_oe_kids_card1_videourl &&
                cleanOnlineExhibition?.field_oe_kids_card1_videourl[0]
                  ?.value) ||
              null,
          }

          forKids.push(kidCard)
        }

        // second card
        if (
          cleanOnlineExhibition?.field_oe_kids_card2_img &&
          cleanOnlineExhibition?.field_oe_kids_card2_img.length > 0 &&
          cleanOnlineExhibition?.field_oe_kids_card2_title &&
          cleanOnlineExhibition?.field_oe_kids_card2_title.length > 0
        ) {
          const kidCard = {
            id: uuidv4(),
            imgUrl:
              (cleanOnlineExhibition?.field_oe_kids_card2_img &&
                cleanOnlineExhibition?.field_oe_kids_card2_img[0]?.url) ||
              null,
            imgAlt:
              (cleanOnlineExhibition?.field_oe_kids_card2_img &&
                cleanOnlineExhibition?.field_oe_kids_card2_img[0]?.alt) ||
              null,
            title:
              (cleanOnlineExhibition?.field_oe_kids_card2_title &&
                cleanOnlineExhibition?.field_oe_kids_card2_title[0]?.value) ||
              null,
            pdfUrl:
              (cleanOnlineExhibition?.field_oe_kids_card2_pdfurl &&
                cleanOnlineExhibition?.field_oe_kids_card2_pdfurl[0]?.value) ||
              null,
            listeningUrl:
              (cleanOnlineExhibition?.field_oe_kids_card2_audiourl &&
                cleanOnlineExhibition?.field_oe_kids_card2_audiourl[0]
                  ?.value) ||
              null,
            videoUrl:
              (cleanOnlineExhibition?.field_oe_kids_card2_videourl &&
                cleanOnlineExhibition?.field_oe_kids_card2_videourl[0]
                  ?.value) ||
              null,
          }

          forKids.push(kidCard)
        }

        if (forKids.length > 0) {
          pageData.data.forKids = forKids
        }

        // prepare other online exhibitions
        const onlineExhibitions =
          await OnlineExhibitionsService.getOnlineExhibitions(locale)

        if (onlineExhibitions.length > 0) {
          pageData.data.otherOnlineExhibitions = onlineExhibitions.filter(
            (online) => online?.id != slug
          )
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  prepareStickyNavItems: async (initTabsOfPage): Promise<IStickyNav[]> => {
    const tabsOfTour = initTabsOfPage.map((tab) => ({
      id: tab[0]?.nid[0]?.value || '',
      navTitleTx: tab[0]?.title[0]?.value || '',
      href: tab[0]?.field_oe_tab_id[0]?.value || '',
      titleIsWithLocalizationOnClient: false,
      rank: 0,
      contentType: 'custom',
      __component: 'custom',
    }))

    const stickyNavItems = tabsOfTour.sort((a, b) => a.rank - b.rank)
    return stickyNavItems
  },
  getTabsOfPage: async (
    locale: string,
    tabIds: string[]
  ): Promise<IPageTab[]> => {
    const initTabsOfPage = await Promise.all(
      tabIds.map(async (id) => {
        const tabOfPage = await OnlineExhibitionsService.getTabOfPage(
          locale,
          id
        )

        return tabOfPage
      })
    )

    return initTabsOfPage
  },
  /**
   * Get tab of online exhibition page drupalHttp service
   */
  getTabOfPage: async (locale: string, id: string) => {
    const tabOfPageCategory = await OnlineExhibitionHttpService.getTabOfPage(
      locale,
      id
    )

    return tabOfPageCategory
  },
  getPeriodsOfOnlineExhibition: async (
    locale: string,
    periodIds: string[],
    onlineExhibitionId: string
  ): Promise<IArtCollection[]> => {
    const periods = await Promise.all(
      periodIds.map(async (periodId) => {
        const periodDetail =
          await OnlineExhibitionsService.getPeriodOfOnlineExhibition(
            locale,
            periodId,
            onlineExhibitionId
          )

        return periodDetail
      })
    )

    return periods
  },
  /**
   * Get period of online exhibition page drupalHttp service
   */
  getPeriodOfOnlineExhibition: async (
    locale: string,
    periodId: string,
    onlineExhibitionId: string
  ): Promise<IArtCollection> => {
    const periodDetail =
      await OnlineExhibitionHttpService.getPeriodOfOnlineExhibition(
        locale,
        periodId
      )
    const period = {
      id: null,
      title: null,
      description: null,
      type: null,
      collection: null,
    }

    // periodda üç adet veri yer alıyor; title, description ve object id
    if (periodDetail && periodDetail.length > 0) {
      const cleanPeriod = periodDetail[0]

      period.id = cleanPeriod?.nid[0]?.value || uuidv4()
      period.title = cleanPeriod?.title[0]?.value || ''
      period.description =
        cleanPeriod?.field_oe_related_period_desc[0]?.value || ''
      period.type = 'collection'

      // her bir perioddaki obje için gidip object servisinden görsel, obje title ve id yi al
      const objectsOfExhibition = cleanPeriod?.field_oe_related_period_object

      if (objectsOfExhibition && objectsOfExhibition.length > 0) {
        const objectIds = objectsOfExhibition.map((object) => object?.target_id)

        if (objectIds && objectIds.length > 0) {
          const objects =
            await OnlineExhibitionsService.getObjectsOfOnlineExhibition(
              locale,
              objectIds,
              onlineExhibitionId,
              periodId
            )

          if (objects && objects.length > 0) {
            period.collection = objects || null
          }
        }
      }
    }

    return period
  },
  /**
   * Get object of online exhibition page drupalHttp service
   */
  getObjectsOfOnlineExhibition: async (
    locale: string,
    objectIds: string[],
    onlineExhibitionId: string,
    periodId: string
  ) => {
    const objects = await Promise.all(
      objectIds.map(async (objectId) => {
        const objectDetail =
          await OnlineExhibitionsService.getObjectOfOnlineExhibition(
            locale,
            objectId,
            onlineExhibitionId,
            periodId
          )

        return objectDetail
      })
    )

    return objects
  },
  /**
   * Get object of online exhibition page drupalHttp service
   */
  getObjectOfOnlineExhibition: async (
    locale: string,
    objectId: string,
    onlineExhibitionId: string,
    periodId: string
  ): Promise<IArtCollectionItemType> => {
    const object =
      await OnlineExhibitionHttpService.getObjectOfOnlineExhibition(
        locale,
        objectId
      )

    const preparedObject = object.map((o) => ({
      id: o?.field_object_em_source_id[0]?.value || uuidv4(),
      imgUrl: o?.field_object_em_primary_media[0]?.value || '',
      title: o?.field_object_em_title[0]?.value || '',
      name:
        locale === 'tr'
          ? COMMON_TR.detailedReviewButtonLabel
          : COMMON_EN.detailedReviewButtonLabel,
      url:
        `${createNavLink('online', locale)}/${onlineExhibitionId}/${
          o?.field_object_em_source_id[0]?.value
        }?periodId=${periodId}&dId=${objectId}` || '',
    }))

    if (preparedObject && preparedObject.length > 0) {
      return preparedObject[0]
    }

    return null
  },
  getAllOnlineSlugs: async (locale) => {
    const onlineExhibitions =
      await OnlineExhibitionHttpService.getOnlineExhibitions(locale)

    return onlineExhibitions.map(
      (exhibition) => (exhibition?.nid && exhibition?.nid[0]?.value) || ''
    )
  },
}
