import { Flex, Container, IconButton } from '@chakra-ui/react'
import { ChevronLeft, ChevronRight } from '@icon'
import { Paginated } from '@makotot/paginated'
import { PaginationButton } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { IFonts } from '@interfaces'

interface ICollectionSearchPagination {
  currentPage: number
  totalPage: number
  handlePagination: (page: number) => void
  activeFontFamily?: IFonts
}

const CollectionSearchPagination = ({
  currentPage,
  totalPage,
  handlePagination,
  activeFontFamily = 'body',
}: ICollectionSearchPagination) => {
  const { t } = useTranslation()

  return (
    <Paginated
      currentPage={currentPage}
      totalPage={totalPage}
      siblingsSize={1}
      boundarySize={1}
    >
      {({
        pages,
        currentPage,
        hasPrev,
        hasNext,
        getFirstBoundary,
        getLastBoundary,
        isPrevTruncated,
        isNextTruncated,
      }) => (
        <>
          {pages.length > 1 && (
            <Container
              maxW="container.secondary"
              mt={{ base: 'padding.primary', lg: 'padding.primary2' }}
            >
              <Flex justifyContent="center" alignItems="center">
                {hasPrev() && (
                  <IconButton
                    variant="unstyled"
                    aria-label={t('common:paginationPrevButtonAriaLabel')}
                    icon={<ChevronLeft />}
                    onClick={() => handlePagination(Number(currentPage) - 1)}
                  />
                )}
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    '&>*+*': {
                      ml: '10px!important',
                    },
                  }}
                >
                  {getFirstBoundary().map((boundary) => (
                    <PaginationButton
                      key={boundary}
                      label={boundary.toString()}
                      onClick={() => handlePagination(boundary)}
                      activeFontFamily={activeFontFamily}
                    />
                  ))}
                  {isPrevTruncated && <span>...</span>}
                  {pages.map((page) => {
                    return page == currentPage ? (
                      <PaginationButton
                        key={page}
                        isActive={true}
                        label={page.toString()}
                        onClick={() => handlePagination(page)}
                      />
                    ) : (
                      <PaginationButton
                        key={page}
                        label={page.toString()}
                        onClick={() => handlePagination(page)}
                      />
                    )
                  })}

                  {isNextTruncated && <span>...</span>}
                  {getLastBoundary().map((boundary) => (
                    <PaginationButton
                      key={boundary}
                      label={boundary.toString()}
                      onClick={() => handlePagination(boundary)}
                    />
                  ))}
                </Flex>

                {hasNext() && (
                  <IconButton
                    variant="unstyled"
                    aria-label={t('common:paginationNextButtonAriaLabel')}
                    icon={<ChevronRight />}
                    onClick={() => handlePagination(Number(currentPage) + 1)}
                  />
                )}
              </Flex>
            </Container>
          )}
        </>
      )}
    </Paginated>
  )
}

export default CollectionSearchPagination
