/* eslint-disable @typescript-eslint/no-unused-vars */
import { eMuseumHttpSearch } from '@httpServices'
import { createNavLink } from '@utils'

export const eMuseumSearch = {
  /**
   * Search common objects on e-museum.
   * For example;
   * - common search: https://emuseum.sakipsabancimuzesi.org/search/abidin/objects/json
   * - common search with sort params: https://emuseum.sakipsabancimuzesi.org/search/abidin/objects/json?sort=title-asc
   */
  commonSearchOnObjects: async (
    locale: string,
    searchTerm: string,
    params?: string
  ) => {
    const resultOfSearch = await eMuseumHttpSearch.commonSearchOnObjects(
      searchTerm,
      params
    )

    const prepareResultsOfObjects = {
      results: [],
      count: 0,
    }

    prepareResultsOfObjects.results = resultOfSearch.results.map((object) => ({
      id: object?.sourceId?.value,
      imgUrl: (object?.primaryMedia && object?.primaryMedia?.value) || '',
      title: object?.title?.value,
      name: locale === 'tr' ? 'Detaylı İncele' : 'Detailed Review',
      url: `${createNavLink('collectionSearch', locale)}/${
        object?.sourceId?.value
      }`,
    }))
    prepareResultsOfObjects.count = prepareResultsOfObjects.results.length

    return prepareResultsOfObjects
  },
  /**
   * Search advanced objects on e-museum.
   * For example;
   * - advanced search: https://emuseum.sakipsabancimuzesi.org/advancedsearch/Objects/title%3Aabidin/json
   * - advanced search with multiple args: https://emuseum.sakipsabancimuzesi.org/advancedsearch/Objects/beginDate:1725;endDate:1934;collections:Archaeological;title:abidin;people:abidin/json
   * - advanced search with multiple args and pagination: https://emuseum.sakipsabancimuzesi.org/advancedsearch/Objects/beginDate:1725;endDate:1934;collections:Archaeological;title:abidin;people:abidin/json?page=1
   */
  advancedSearchOnObjects: async (
    locale: string,
    filterParams: string,
    addParams: string
  ) => {
    const resultOfSearch = await eMuseumHttpSearch.advancedSearchOnObjects(
      filterParams,
      addParams
    )

    const prepareResultsOfObjects = {
      results: null,
      count: null,
    }

    prepareResultsOfObjects.results = resultOfSearch.objects.map((object) => ({
      id: object?.sourceId?.value,
      imgUrl: (object?.primaryMedia && object?.primaryMedia?.value) || '',
      title: object?.title?.value,
      name: locale === 'tr' ? 'Detaylı İncele' : 'Detailed Review',
      url: `${createNavLink('collectionSearch', locale)}/${
        object?.sourceId?.value
      }`,
    }))

    prepareResultsOfObjects.count = resultOfSearch.count

    return prepareResultsOfObjects
  },
}
