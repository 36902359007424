import { createIcon } from '@chakra-ui/icons'

const Facebook = createIcon({
  displayName: 'Facebook',
  defaultProps: {
    width: '10px',
    height: '18px',
  },
  viewBox: '0 0 10 18',
  path: (
    <>
      <path fill="currentColor" d="M8.374 9.533H0V5.955h9.017l-.643 3.578z" />
      <path
        fill="currentColor"
        d="M1.953 3.598V17.88h3.846V4.173c0-.39.243-.595.644-.595h2.575V0h-2.97C1.85-.005 1.953 3.128 1.953 3.598z"
      />
    </>
  ),
})

export default Facebook
