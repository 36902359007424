import {
  AspectRatio,
  Box,
  Button,
  IconButton,
  Container,
  SimpleGrid,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { KidCard } from '@common'
import {
  Catalog,
  Cross,
  Link as LinkIcon,
  MusicForKidsv2,
  PlayForKidsv2,
  Share,
} from '@icon'
import { IFonts, IKidCard } from '@interfaces'
import { Link, MotionImage, RichText } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { RWebShare } from 'react-web-share'
import { PUBLICATION_CHILD_IMAGE_ASPECT_RATIO_ON_MODAL } from '../../../lib/const'
import { getActiveColorForKidsPage } from '@utils'

interface IChildrensBooks {
  stickyId: string
  sectionTitle: string
  collection: IKidCard[]
  activeFontFamily?: IFonts
}

const ChildrensBooks = ({
  stickyId,
  sectionTitle,
  collection,
  activeFontFamily = 'mikado',
}: IChildrensBooks) => {
  const [activePopUpId, setActivePopUpId] = useState<null | string>(null)
  const { isOpen, onToggle, onClose } = useDisclosure()
  const router = useRouter()
  const { t } = useTranslation()

  const handleOnClickForPopUp = (activeId: string) => {
    setActivePopUpId(activeId)
    onToggle()
  }

  const getActiveCollection = () =>
    collection.find((collection) => collection.id === activePopUpId)

  return (
    <Box
      as="section"
      bgColor="white.primary"
      id={stickyId}
      pt={{ base: 'padding.primary2', lg: '100px' }}
      pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      position="relative"
      zIndex="1"
    >
      <Box>
        <MotionImage
          imgUrl="/img/exhibition-and-event-detail/for-kids/kids-element-2.png"
          imgAlt="Shape"
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 4,
            },
          }}
          containerProps={{
            position: 'absolute',
            top: '600px',
            left: '-50px',
          }}
        />

        <MotionImage
          imgUrl="/img/exhibition-and-event-detail/for-kids/kids-element-3.png"
          imgAlt="Shape"
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 4,
            },
          }}
          containerProps={{
            position: 'absolute',
            top: '154px',
            right: '0px',
          }}
        />

        <MotionImage
          imgUrl="/img/exhibition-and-event-detail/for-kids/kids-element-5.png"
          imgAlt="Shape"
          motionProps={{
            animate: { y: 20 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 2,
            },
          }}
          containerProps={{
            position: 'absolute',
            bottom: { base: '0px', lg: '-100px' },
            right: { base: '0px', lg: '15%' },
          }}
        />
      </Box>
      <Container>
        <Text
          textAlign="center"
          fontSize={{ base: '3xl', lg: '5xl' }}
          fontFamily={activeFontFamily}
          fontWeight="bold"
          color="blue.primary4"
          mb="10"
        >
          {sectionTitle}
        </Text>
        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          spacingY="padding.primary2"
          spacingX="padding.primary"
          placeItems="flex-start"
          justifyItems="center"
        >
          {collection &&
            collection.length > 0 &&
            collection.map((col, i) => (
              <KidCard
                key={i}
                title={col.title}
                imgUrl={col.imgUrl}
                activeFontFamily={activeFontFamily}
                primaryColor={getActiveColorForKidsPage(i)}
              >
                {(col?.listeningUrl || col?.listeningUrl !== '') && (
                  <IconButton
                    bgColor={getActiveColorForKidsPage(i)}
                    aria-label="Listen Button"
                    icon={<MusicForKidsv2 />}
                    color="white.primary"
                    onClick={() => handleOnClickForPopUp(col.id)}
                    width="60px"
                    height="60px"
                    borderRadius="30px"
                  />
                )}
                {(col?.videoUrl || col?.videoUrl !== '') && (
                  <IconButton
                    bgColor={getActiveColorForKidsPage(i)}
                    aria-label="Play Button"
                    icon={<PlayForKidsv2 />}
                    color="white.primary"
                    onClick={() => handleOnClickForPopUp(col.id)}
                    width="60px"
                    height="60px"
                    borderRadius="30px"
                  />
                )}
                {(col.pdfUrl || col.pdfUrl !== '') && (
                  <IconButton
                    bgColor={getActiveColorForKidsPage(i)}
                    aria-label="Pdf Button"
                    icon={
                      <Catalog
                        width="26px"
                        height="23px"
                        color="white.primary"
                      />
                    }
                    color="white.primary"
                    onClick={() => handleOnClickForPopUp(col.id)}
                    width="60px"
                    height="60px"
                    borderRadius="30px"
                  />
                )}
              </KidCard>
            ))}
        </SimpleGrid>
      </Container>
      {activePopUpId && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay
            background="blackAlpha.primary80"
            sx={{ backdropFilter: 'blur(8px)' }}
          />
          <ModalContent
            mt={{ base: 'padding.primary2', lg: 'padding.primary4' }}
            maxW={{ base: 'calc(100vw - 60px)', lg: '1063px' }}
            borderRadius="none"
            bgColor="white.primary"
            flexDirection={{ base: 'column', lg: 'row' }}
          >
            <ModalHeader
              flex="auto"
              width="full"
              maxWidth={{ base: 'full', lg: '413px' }}
              minH={{ base: '271px', lg: '267px' }}
              position="relative"
              p="0"
            >
              <Box
                position="relative"
                _before={{
                  content: `" "`,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: 'full',
                  height: 'full',
                  background:
                    'linear-gradient(rgba(13, 16, 29, 0.2), rgba(13, 16, 29, 0.1))',
                  zIndex: 1,
                }}
              >
                <AspectRatio
                  ratio={PUBLICATION_CHILD_IMAGE_ASPECT_RATIO_ON_MODAL}
                  width="full"
                  sx={{
                    '& > img, & > video': {
                      objectFit: 'contain',
                    },
                  }}
                >
                  <Img src={getActiveCollection().imgUrl} />
                </AspectRatio>
              </Box>
              <Box
                position="absolute"
                top="20px"
                left="20px"
                right="20px"
                display="flex"
                alignItems="baseline"
                justifyContent="space-between"
                zIndex="2"
              >
                <RWebShare
                  data={{
                    text: getActiveCollection().title,
                    url: router.basePath,
                    title: `${getActiveCollection().title} | SSM`,
                  }}
                  closeText={t('common:closeLabel')}
                >
                  <Button
                    variant="unstyled"
                    rightIcon={<Share />}
                    color="white.primary"
                    fontSize="sm"
                    fontWeight="normal"
                    fontFamily={activeFontFamily}
                    alignItems="baseline"
                  >
                    {t('common:detailPageNavigation.share')}
                  </Button>
                </RWebShare>
                <Button
                  variant="unstyled"
                  leftIcon={<Cross />}
                  color="white.primary"
                  fontSize="sm"
                  fontWeight="normal"
                  fontFamily={activeFontFamily}
                  onClick={onClose}
                />
              </Box>
            </ModalHeader>
            <ModalBody
              py="padding.primary2"
              px={{ base: 'padding.primary', lg: 'padding.primary4' }}
              maxHeight={{ base: 'auto', lg: '516px' }}
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'white.primary3',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'red.primary3',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: 'red.primary2',
                },
              }}
            >
              <Text
                fontFamily={activeFontFamily}
                fontSize="lg"
                color="red.primary"
                mb="22px"
                as="h5"
              >
                {getActiveCollection().title}
              </Text>

              {getActiveCollection()?.description && (
                <RichText
                  htmlContent={getActiveCollection().description}
                  fontFamily={activeFontFamily}
                />
              )}

              {getActiveCollection()?.listeningUrl && (
                <Box
                  as="audio"
                  controls
                  src={getActiveCollection()?.listeningUrl}
                  mt="4"
                  width="full"
                  sx={{
                    '::-webkit-media-controls-enclosure': {
                      borderRadius: '0px',
                      background: 'white.primary2',
                    },
                  }}
                >
                  Your browser does not support the
                  <code>audio</code> element.
                </Box>
              )}

              {getActiveCollection()?.videoUrl && (
                <Link
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgColor="red.primary"
                  color="white.primary"
                  _hover={{
                    bgColor: 'red.primary3',
                  }}
                  height="50px"
                  width="full"
                  href={getActiveCollection()?.videoUrl || '#'}
                  target="_blank"
                  minWidth="75px"
                  mt="4"
                >
                  <LinkIcon color="red.primary2" />
                  <Text
                    ml="2.5"
                    fontWeight="light"
                    fontFamily={activeFontFamily}
                    fontSize="sm"
                  >
                    {t('common:descriptiveWatchButtonLabel')}
                  </Text>
                </Link>
              )}

              {getActiveCollection()?.pdfUrl &&
                getActiveCollection()?.pdfUrl !== '' && (
                  <Link
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgColor="red.primary"
                    color="white.primary"
                    _hover={{
                      bgColor: 'red.primary3',
                    }}
                    height="50px"
                    width="full"
                    href={getActiveCollection()?.pdfUrl}
                    target="_blank"
                    minWidth="75px"
                    mt="4"
                  >
                    <Catalog color="red.primary2" />
                    <Text
                      ml="2.5"
                      fontWeight="light"
                      fontSize="sm"
                      fontFamily={activeFontFamily}
                    >
                      PDF
                    </Text>
                  </Link>
                )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  )
}

export default ChildrensBooks
