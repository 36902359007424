import eMuseumHttp from '../eMuseumHttpService'
import {
  EVENT,
  EVENT_DETAIL,
  EVENT_BY_SORT,
  CONSERVATION_EVENTS,
  WORKSHOPS_BY_SORT,
} from '@lib/const/api-endpoints.const'
import { IMainTypeOfEvent, ISortNameOfEvent } from '@interfaces'

/**
 * Event Http Service
 */
export const EventHttpService = {
  /**
   * Get all event eMuseumHttp service
   */
  getAllEvent: async (mainType: IMainTypeOfEvent, params) =>
    await eMuseumHttp.get(`${EVENT(mainType)}&${params}`),
  /**
   * Get all event eMuseumHttp service
   */
  getEventDetail: async (id) => await eMuseumHttp.get(EVENT_DETAIL(id)),
  /**
   * Get events by sort type eMuseumHttp service
   */
  getEventsBySort: async (
    mainType: IMainTypeOfEvent,
    sortType: ISortNameOfEvent,
    params: string
  ) => {
    const urlParams = new URLSearchParams(params)
    const eventType = urlParams.get('eventType')
    let url = EVENT_BY_SORT(mainType, sortType)

    if (eventType) {
      url += `;eventType:${eventType}`
      urlParams.delete('eventType')
    }

    const remainingParams = urlParams.toString()
    if (remainingParams) {
      url += `&${remainingParams}`
    }

    return await eMuseumHttp.get(url)
  },

  /**
   * Get conservation events eMuseumHttp service
   */
  getConservationEvents: async () => await eMuseumHttp.get(CONSERVATION_EVENTS),

  /**
   * Get workshops by sort type eMuseumHttp service
   */
  getWorkshopsBySort: async (sortType: ISortNameOfEvent, params) =>
    await eMuseumHttp.get(`${WORKSHOPS_BY_SORT(sortType)}&${params}`),
}
