import {
  Box,
  Flex,
  IconButton,
  Text,
  useMediaQuery,
  useTheme
} from "@chakra-ui/react";
import { ChevronUp } from "@icon";
import { sideMenuAnim } from "@lib/motion";
import { useStore } from "@store";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useState,useEffect,useRef } from "react";
import SwiperCore, { Controller, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { IHeadlineSlider } from "@interfaces";
import { HeroAspectRatio, Link } from "@ui";
import Vimeo from "@u-wave/react-vimeo";
import { useVimeoPlayer } from "@lib/hooks";
import useTranslation from "next-translate/useTranslation";
import Player from '@vimeo/player';

// install Swiper modules
SwiperCore.use([Navigation, Controller]);

export interface IHomeHero {
  swiperCollection: IHeadlineSlider[];
}

const HomeHero = observer(({ swiperCollection }: IHomeHero) => {
  const theme = useTheme();
  const [isMobileScreen] = useMediaQuery("(max-width: 768px)");

  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const {
    ui: { displayBannerSlider, setDisplayBannerSlider }
  } = useStore();

  const {
    handlePlayerPause,
    handlePlayerPlay,
    getPausedVideoState,
    stopPlayingVideo
  } = useVimeoPlayer(
    swiperCollection
      .filter((row) => row.isWithVideo === true)
      .map((video) => ({
        id: video.vimeoId,
        vimeoId: video.vimeoId,
        paused: true
      }))
  );

  // autoplay if active slide is a video
  const autoPlayActiveVideo = (index: number) => {
    const isVideo = swiperCollection[index]?.isWithVideo || false;

    if (isVideo) {
      setTimeout(() => {
        handlePlayerPlay(swiperCollection[index]?.vimeoId);
      }, 100);
    }
  };

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  /**
   * If there is a video playing, let's stop it.
   */
  const swiperOnSlideChange = (swiper) => {
    // stop playing video
    stopPlayingVideo();

    // auto play if active slide is video
    autoPlayActiveVideo(swiper?.activeIndex);
    setActiveIndex(swiper?.activeIndex);
  }


  const MotionText = motion(Text);

  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3, ease: "easeOut" }
    }
  };
  const videoRef = useRef(null);
  useEffect(() => {
    if (!videoRef.current) return;

    const player = new Player(videoRef.current, {
      id: 1069163564,
      autoplay: true,
      muted: true,
      loop: true,
      background: true,
    });

    player.on('loaded', () => {
      player.ready().then(() => {
        player.on('play', () => {
          setIsVideoPlaying(true);
        });
      });
    });

    return () => {
      player.destroy();
    };
  }, []);


  const { t } = useTranslation();
  if (swiperCollection.length === 1 || !swiperCollection) {
    return (
      <Box
        as="section"
        overflow="hidden"
        minHeight={{ base: "calc(63vh - 268px)", lg: "calc(100vh - 62px)" }}
        width="100vw"
        position="relative"
        sx={{
          "& .home-hero-iframe-video > iframe": {
            boxSizing: "border-box",
            height: "48.25vw",
            left: "50%",
            minHeight: "100%",
            minWidth: "100%",
            position: "absolute",
            top: { base: "154px", md: "41%", lg: "52%" },
            transform: "translate(-50%, -50%)",
            visibility: "visible",
            zIndex: 1,
            display: isVideoPlaying ? 'block' : 'none',
          },
        }}
      >
        <Box
          backgroundImage="url('https://drupal.sakipsabancimuzesi.org/sites/default/files/styles/webp/public/2021-10/bahce_anabanner2.jpg.webp')"
          backgroundSize="cover"
          backgroundPosition="left"
          width="100%"
          height="100%"
          position="absolute"
          top="0%"
          left="0"
          zIndex="2"
          sx={{
            display: isVideoPlaying ? 'none' : 'block',
            pointerEvents: isVideoPlaying ? 'none' : 'auto'
          }}
        />

        <div
          className="home-hero-iframe-video"
          id="video-container"
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>

        <MotionText
          initial="hidden"
          animate="visible"
          variants={textVariants}
          fontSize={{ base: "sm", lg: "lg" }}
          color="white"
          fontWeight="bold"
          position="absolute"
          bottom={{ base: '20px', lg: '30px' }}
          fontFamily="heading"
          right={{ base: '10px', lg: '30px' }}
          zIndex={3}
        >
          {t('home:videoTag')}
        </MotionText>
      </Box>
    );
  }

  return (
    <HeroAspectRatio>
      <Box
        id="stickyFix"
        width="full"
        height="full"
        position="relative"
        zIndex="1"
        pt={{ base: "65px", lg: "65px" }}
      >
        <Box
          bgColor="black.primary"
          width="full"
          height="full"
          overflow="hidden"
          sx={{
            ".hero-bg-slider.swiper-container": {
              height: "full"
            }
          }}
        >
          <Swiper
            pagination={{
              el: ".swiper-pagination",
              type: "fraction"
            }}
            navigation={{
              nextEl: ".shop-swiper-button-next",
              prevEl: ".shop-swiper-button-prev"
            }}
            noSwiping={true}
            className="hero-bg-slider"
            controller={{ control: secondSwiper }}
            onSwiper={setFirstSwiper}
            onSlideChange={swiperOnSlideChange}
          >
            {swiperCollection.map((row, index) => (
              <SwiperSlide
                key={row.id}
                // className="swiper-no-swiping"
              >
                <Box
                  opacity="0.60"
                  // userSelect="none"
                  // pointerEvents="none"
                  width="full"
                  height="full"
                >
                  {row.isWithVideo ? (
                    <Box
                      bgColor="black.primary"
                      height="full"
                      sx={{
                        "& .home-hero-iframe-video": {
                          width: "full",
                          height: "full"
                        },
                        "& .home-hero-iframe-video > iframe": {
                          width: "full",
                          height: "full",
                          // height: '60%',
                          pointerEvents: "auto"
                        }
                      }}
                    >
                      <Vimeo
                        className="home-hero-iframe-video"
                        video={isMobileScreen ? row.mobileVimeoId : row.vimeoId}
                        paused={getPausedVideoState(row.vimeoId)}
                        onPause={() => handlePlayerPause(row.vimeoId)}
                        onPlay={() => handlePlayerPlay(row.vimeoId)}
                        color={theme.colors.red.primary}
                        showTitle={false}
                        loop={true}
                        volume={1}
                        muted={true}
                        {...(index === 0 && { autoplay: true })}
                      />
                    </Box>
                  ) : (
                    <Box
                      width="full"
                      height="100%  "
                      backgroundImage={{
                        base: `url('${
                          row.mobileImgUrl === ""
                            ? "/img/visit/hero/ssm-visit.jpg"
                            : row.mobileImgUrl
                        }')`,
                        lg: `url('${
                          row.imgUrl === ""
                            ? "/img/visit/hero/ssm-visit.jpg"
                            : row.imgUrl
                        }')`
                      }}
                      //   backgroundImage={`url('${
                      //   bgImageUrl === '' ? '/img/visit/hero/ssm-visit.jpg' : bgImageUrl
                      //  }')`}
                      backgroundPosition="center"
                      backgroundSize="cover"
                      backgroundRepeat="no-repeat"
                      position="relative"
                      zIndex={displayBannerSlider ? 5 : 3}
                      py={{ base: "padding.primary2", lg: "8" }}
                    />
                  )}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <IconButton
          d="none"
          role="button"
          aria-pressed={displayBannerSlider}
          aria-label="Toggle banner slider"
          icon={
            <ChevronUp
              color="red.primary"
              fontSize={{ base: "md", lg: "xl" }}
              // transform={
              //   !displayBannerSlider ? 'rotate(90deg)' : 'rotate(0deg)'
              // }
              // transition="transform 0.3s ease-in-out"
            />
          }
          background="red.primary2"
          _hover={{ background: "red.primary3" }}
          borderRadius="none"
          position="absolute"
          bottom={{ base: "-50px", lg: "-120px" }}
          right={0}
          width={{ base: "50px", lg: "120px" }}
          height={{ base: "50px", lg: "120px" }}
          onClick={() => setDisplayBannerSlider(!displayBannerSlider)}
          zIndex={2}
        />
        <Box
          opacity={0.85}
          position="absolute"
          bottom={{ base: "50%", lg: "50%" }}
          right={"50%"}
          width={{ base: "drawer.lg", lg: "drawer.xl" }}
          height={{ base: "230px", lg: "300px" }}
          maxWidth="drawer.xl"
          maxHeight="drawer.lg"
          textAlign="center"
          transform="translate(50%, 50%)"
          // zIndex={displayBannerSlider ? 1 : -1}
          zIndex={1}
          overflow="hidden"
          sx={{
            ".hero-slider-box.swiper-container": {
              height: "full",
              position: "relative"
            }
          }}
        >
          <Box
            width="full"
            height="full"
            as={motion.div}
            initial={false}
            animate={
              displayBannerSlider
                ? sideMenuAnim.sliderMotion.visible
                : sideMenuAnim.sliderMotion.hidden
            }
          >
            <Swiper
              onSwiper={setSecondSwiper}
              controller={{ control: firstSwiper }}
              pagination={{
                el: ".swiper-pagination",
                type: "fraction"
              }}
              navigation={{
                nextEl: ".hero-slider-box-button-next",
                prevEl: ".hero-slider-box-button-prev"
              }}
              grabCursor={true}
              className="hero-slider-box"
            >
              {swiperCollection.map((content) => {
                if (swiperCollection[activeIndex]?.title.length < 1) {
                  return (
                    <SwiperSlide key={content.id}>
                      <Box
                        opacity={0.85}
                        as={motion.div}
                        width="full"
                        height="full"
                      ></Box>
                    </SwiperSlide>
                  );
                }
                return (
                  <SwiperSlide key={content.id}>
                    {swiperCollection.length && (
                      <Box
                        opacity={0.85}
                        as={motion.div}
                        padding={{
                          base: "padding.primary",
                          lg: "padding.primary2"
                        }}
                        width="full"
                        height="full"
                      >
                        <Text
                          fontSize={{ base: "18px", lg: "50px" }}
                          fontWeight="600"
                          color={{
                            base: content.mobileFontColor,
                            lg: content.fontColor
                          }}
                          mb={0}
                          as="h6"
                          noOfLines={{ base: 3, lg: 2 }}
                        >
                          {content.title}
                        </Text>
                        <Text
                          fontSize={{ base: "md", lg: "lg" }}
                          fontWeight="light"
                          color={{
                            base: content.mobileFontColor,
                            lg: content.fontColor
                          }}
                          noOfLines={{ base: 3, lg: 2 }}
                        >
                          {content.description}
                        </Text>

                        {content.linkLabel && content.linkUrl && (
                          <Link
                            href={content.linkUrl}
                            color={{
                              base: content.mobileFontColor,
                              lg: content.fontColor
                            }}
                            fontSize="lg"
                            textDecor="underline"
                            mt="3.5"
                            noOfLines={1}
                            target={content.isExternalLink ? "_blank" : "_self"}
                          >
                            {content.linkLabel}
                          </Link>
                        )}
                        <IconButton
                          d="none"
                          role="button"
                          aria-pressed={displayBannerSlider}
                          aria-label="Toggle banner slider"
                          icon={
                            <ChevronUp
                              color={
                                swiperCollection[activeIndex]?.bgColorChevron ||
                                "white.primary2"
                              }
                              fontSize={{ base: "md", lg: "xl" }}
                              transform={
                                !displayBannerSlider
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)"
                              }
                              transition="transform 0.3s ease-in-out"
                            />
                          }
                          background={{
                            md: content.bgColorChevron || "red.primary2",
                            base: content.mobileBgColor || "red.primary2"
                          }}
                          // background={content.bgColorChevron || 'red.primary2'}
                          _hover={{ background: "red.primary3" }}
                          borderRadius="none"
                          position="absolute"
                          bottom={0}
                          right={0}
                          //  bottom={{ base: '-100px', lg: '-120px' }}
                          // right={0}
                          width={{ base: "50px", lg: "70px" }}
                          height={{ base: "50px", lg: "70px" }}
                          onClick={() =>
                            setDisplayBannerSlider(!displayBannerSlider)
                          }
                          zIndex={4}
                        />
                      </Box>
                    )}
                  </SwiperSlide>
                );
              })}
              {swiperCollection.length > 3 && (
                <Flex
                  position="absolute"
                  flexDirection="row"
                  alignItems="baseline"
                  top="auto"
                  left={{
                    base:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? 0
                        : "padding.primary",
                    lg:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? 0
                        : "padding.primary2"
                  }}
                  paddingTop={{
                    base:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? "10px"
                        : 0,
                    lg:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? "30px"
                        : 0
                  }}
                  paddingLeft={{
                    base:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? "padding.primary"
                        : 0,
                    lg:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? "padding.primary2"
                        : 0
                  }}
                  paddingBottom={{
                    base:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? "10px"
                        : 0,
                    lg:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? "20px"
                        : 0
                  }}
                  bottom={{
                    base:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? 0
                        : "10px",
                    lg:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? 0
                        : "20px"
                  }}
                  right="auto"
                  width={
                    swiperCollection[activeIndex]?.title.length < 1
                      ? "full"
                      : "inherit"
                  }
                  color={{
                    md:
                      swiperCollection[activeIndex]?.bgColorChevron ||
                      "white.primary2",
                    base:
                      swiperCollection[activeIndex]?.mobileBgColor ||
                      "white.primary2"
                  }}
                  sx={{
                    ".hero-slider-box-button-next, .hero-slider-box-button-prev":
                      {
                        width: "16px",
                        height: "auto"
                      },
                    ".hero-slider-box-button-next:after, .hero-slider-box-button-prev:after":
                      {
                        fontSize: "md"
                      },
                    ".hero-slider-box-button-next.swiper-button-disabled, .hero-slider-box-button-prev.swiper-button-disabled":
                      {
                        opacity: 1
                      }
                  }}
                  bgColor={{
                    base:
                      swiperCollection[activeIndex]?.title.length < 1
                        ? "red.primary"
                        : "initial"
                  }}
                >
                </Flex>
              )}
            </Swiper>
          </Box>
        </Box>
      </Box>
    </HeroAspectRatio>
  );
});

export default HomeHero;
