import { createIcon } from '@chakra-ui/icons'
import TwitterNew from './TwitterNew'

const Twitter = createIcon({
  displayName: 'tTwitter',
  viewBox: '0 0 1668.56 1221.19',
  defaultProps: {
    width: '25px',
    height: '16px',
  },
  path: <TwitterNew />,
})

export default Twitter
