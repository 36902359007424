import { useStore } from '@store'
import {
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  Box,
  Flex,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ChevronDown, ExternalLink } from '@icon'
import { Link } from '@ui'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
// import { MENUPATHS } from './NavList.const'
import { IMenuPaths, IFonts } from '@interfaces'
import { isPwa } from '@utils'
interface INavAccordion {
  navigation: IMenuPaths[]
  activeFontFamily?: IFonts
}

const NavAccordion = observer(
  ({ navigation, activeFontFamily = 'body' }: INavAccordion) => {
    const {
      ui: { toggleSideMenu, activeDrawerMenuIndex, setActiveDrawerMenuIndex },
    } = useStore()
    const accordionTitleFontSize = useBreakpointValue({ base: 'lg', lg: '3xl' })
    const accordionPanelPB = useBreakpointValue({
      base: 5,
      lg: 'padding.primary',
    })

    return (
      <Accordion
        allowToggle
        defaultIndex={[activeDrawerMenuIndex]}
        index={activeDrawerMenuIndex}
        onChange={(expandedIndex: number) =>
          setActiveDrawerMenuIndex(expandedIndex)
        }
      >
        {navigation &&
          navigation.map((menu) => {
            return (
              <AccordionItem key={menu.id} mb={5}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton>
                      {menu.isExternal && menu.labelTx === 'Mağaza' ? (
                        <Box
                          as="a"
                          display="flex"
                          alignItems="baseline"
                          width="full"
                          href={menu.path}
                          target="_blank"
                          rel="noreferrer"
                          onClick={toggleSideMenu}
                          textAlign="left"
                        >
                          <Text
                            as="span"
                            flex="1"
                            color="white.primary"
                            fontWeight="light"
                            fontFamily={activeFontFamily}
                            fontSize={accordionTitleFontSize}
                          >
                            {menu.labelTx}
                          </Text>
                          <ExternalLink color="white.primary" />
                        </Box>
                      ) : !menu.subMenu.length ? (
                        <Link
                          href={menu.path}
                          onClick={toggleSideMenu}
                          textAlign="left"
                        >
                          <Text
                            as="span"
                            flex="1"
                            color="white.primary"
                            fontWeight="light"
                            fontFamily={activeFontFamily}
                            fontSize={accordionTitleFontSize}
                          >
                            {menu.labelTx}
                          </Text>
                        </Link>
                      ) : (
                        <Flex width="full" alignItems="baseline">
                          <Text
                            as="span"
                            flex="1"
                            color="white.primary"
                            fontWeight="light"
                            fontFamily={activeFontFamily}
                            textAlign="left"
                            fontSize={accordionTitleFontSize}
                            pr="2.5"
                            textDecoration={isExpanded ? 'underline' : null}
                          >
                            {menu.labelTx}
                          </Text>
                          <ChevronDown
                            width={6}
                            height={6}
                            color="white.primary"
                            sx={{
                              transition: 'all 0.3s ease',
                              '&.Svg--rotate180': {
                                transform: 'rotate(180deg)',
                              },
                            }}
                            className={cn({
                              'Svg--rotate180': isExpanded,
                            })}
                          />
                        </Flex>
                      )}
                    </AccordionButton>
                    {menu.subMenu.length ? (
                      <AccordionPanel
                        pb={accordionPanelPB}
                        display="flex"
                        flexDirection="column"
                      >
                        {menu.subMenu.map((subMenu) => (
                          <Link
                            href={subMenu.path}
                            {...(subMenu.isExternal && { target: '_blank' })}
                            {...(subMenu.isPwaLink &&
                              !isPwa() && { display: 'none' })}
                            key={subMenu.id}
                            onClick={toggleSideMenu}
                          >
                            <Text
                              as="span"
                              flex="1"
                              color="white.primary"
                              fontWeight="light"
                              fontFamily={activeFontFamily}
                              textAlign="left"
                              _hover={{
                                color: 'white.primary',
                                textDecoration: 'underline',
                              }}
                            >
                              {subMenu.labelTx}
                            </Text>
                          </Link>
                        ))}
                      </AccordionPanel>
                    ) : null}
                  </>
                )}
              </AccordionItem>
            )
          })}
      </Accordion>
    )
  }
)

export default NavAccordion
