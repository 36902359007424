import {
  Container,
  Grid,
  Text,
  Link,
  AspectRatio,
  Box,
  Image,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { RichText } from '@ui'
import { IFonts } from '@interfaces'

interface ICatalogExhibitionAndEvent {
  stickyId: string
  catalogPdfLink: string
  catalogName: string
  catalogDescriptions: string
  catalogImgUrl: string
  activeFontFamily?: IFonts
}

const CatalogExhibitionAndEvent = ({
  stickyId,
  catalogPdfLink,
  catalogName,
  catalogDescriptions,
  catalogImgUrl,
  activeFontFamily = 'body',
}: ICatalogExhibitionAndEvent) => {
  const { t } = useTranslation()

  return (
    <Container id={stickyId} as="section">
      <Grid
        gridTemplateColumns={{ base: '1fr', lg: '580px 1fr' }}
        columnGap={{ base: '0px', lg: 'padding.primary' }}
        rowGap={{ base: 'padding.primary', lg: '0px' }}
      >
        <Box alignSelf="center" pt={{ base: 'padding.primary', lg: '0' }}>
          <Text
            as="h3"
            color="red.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
            fontFamily={activeFontFamily}
            mb="5"
          >
            {t('common:catalog')}
          </Text>
          {catalogName && (
            <Text
              as="h4"
              color="grey.primary"
              fontSize="md"
              fontFamily={activeFontFamily}
              mb="padding.primary"
            >
              {catalogName}
            </Text>
          )}

          {catalogDescriptions && (
            <RichText
              htmlContent={catalogDescriptions}
              fontFamily={activeFontFamily}
              fontSize="md"
              mb="6"
            />
          )}

          {catalogPdfLink && (
            <Link
              href={catalogPdfLink}
              target="_blank"
              fontSize="sm"
              fontWeight="medium"
              fontFamily={activeFontFamily}
              color="red.primary"
              display="inline-flex"
              alignItems="center"
              position="relative"
              transition="all 0.3s ease"
              sx={{
                '&:hover': {
                  color: 'red.primary3',
                  _before: {
                    bgColor: 'red.primary3',
                  },
                },
              }}
              _before={{
                content: `""`,
                position: 'absolute',
                bottom: '0',
                left: '0',
                right: '0',
                width: '100%',
                height: '2px',
                bgColor: 'red.primary',
                transition: 'all 0.3s ease',
              }}
            >
              {t('exhibition-and-event:eventAndExhibitionDetail.readOnline')}
            </Link>
          )}
        </Box>
        <Box>
          <AspectRatio
            ratio={4 / 5}
            sx={{
              '& > img': {
                objectFit: 'contain',
              },
            }}
          >
            <Image
              src={catalogImgUrl}
              fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
              alt="Picasso İstanbul’da"
            />
          </AspectRatio>
        </Box>
      </Grid>
    </Container>
  )
}

export default CatalogExhibitionAndEvent
