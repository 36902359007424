import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Container,
  Divider,
  Flex,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { Minus, Plus } from '@icon'
import { IObject } from '@interfaces'
import {
  getCurrentLocalePropFromAPI,
  parsePeopleDataForObjectDetail,
  getCurrentLocaleByObjectTitle,
  createNavLink,
} from '@utils'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Markup } from 'interweave'
import { Link } from '@ui'

interface ICollectionWorkDetailSection {
  objectData: IObject
  containerProps?: BoxProps
  accordionItems: {
    id: string
    accordionTitleTx: string
    accordionContent: {
      id: string
      contentDetail: string
    }[]
  }[]
}

const CollectionWorkDetailSection = ({
  containerProps,
  accordionItems,
  objectData,
}: ICollectionWorkDetailSection) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <Box as="section" {...containerProps}>
      <Container mb={{ base: 'padding.primary', lg: 'padding.primary2' }}>
        <Text as="h2" color="red.primary" fontSize={{ base: '2xl', lg: '3xl' }}>
          {t('common:object.detail.title')}
        </Text>
      </Container>
      <Container
        maxW={{ base: 'full', lg: 'container.secondary' }}
        px={{ base: 'padding.primary', lg: '0' }}
      >
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: '0', lg: 'padding.primary' }}
        >
          <Flex
            flexDirection="column"
            fontSize="sm"
            sx={{
              '&>*+*': {
                mt: 4,
              },
            }}
          >
            {objectData.exhibitions?.label !== 'Exhibitions' && (
              <Box>
                <Text as="h5" display="inline-block" fontWeight="bold">
                  {t('common:object.collection')}:&nbsp;
                </Text>
                <Text as="span" fontWeight="light">
                  {objectData.classification.value}
                </Text>
              </Box>
            )}

            <Box>
              <Text as="h5" display="inline-block" fontWeight="bold">
                {t('common:object.title')}:&nbsp;
              </Text>
              <Text as="span" fontWeight="light">
                {}
                {getCurrentLocaleByObjectTitle(objectData, locale)}
              </Text>
            </Box>
            {objectData.people && (
              <Box>
                <Text as="h5" display="inline-block" fontWeight="bold">
                  {t('common:object.artist')}:&nbsp;
                </Text>
                <Link
                  href={`${createNavLink('artist', locale)}/${
                    parsePeopleDataForObjectDetail(objectData.people.value)
                      ?.sourceID || ''
                  }`}
                  variant="inlineLink"
                  isExternal={true}
                >
                  <Text fontSize="sm" fontWeight="light">
                    {parsePeopleDataForObjectDetail(objectData.people.value)
                      ?.name || ''}
                  </Text>
                </Link>
              </Box>
            )}
            {objectData.displayDate && (
              <Box>
                <Text as="h5" display="inline-block" fontWeight="bold">
                  {t('common:object.date')}:&nbsp;
                </Text>
                <Text as="span" fontWeight="light">
                  <Text as="span">{objectData.displayDate.value}</Text>
                </Text>
              </Box>
            )}
          </Flex>
          <Flex
            flexDirection="column"
            fontSize="sm"
            sx={{
              '&>*+*': {
                mt: 4,
              },
            }}
          >
            {objectData.dimensions && (
              <Box>
                <Text as="h5" display="inline-block" fontWeight="bold">
                  {t('common:object.dimensions')}:&nbsp;
                </Text>
                <Text as="span" fontWeight="light">
                  {objectData.dimensions.value}
                </Text>
              </Box>
            )}
            {objectData.medium && (
              <Box>
                <Text as="h5" display="inline-block" fontWeight="bold">
                  {t('common:object.medium')}:&nbsp;
                </Text>
                <Text as="span" fontWeight="light">
                  <Text as="span">
                    {locale === 'tr'
                      ? objectData?.medium?.value || ''
                      : objectData?.markings?.value || ''}
                  </Text>
                </Text>
              </Box>
            )}
            <Box d="none">
              <Text d="none" as="h5" display="inline-block" fontWeight="bold">
                {t('common:object.location')}:&nbsp;
              </Text>
              <Text d="none" as="span" fontWeight="light">
                Sabancı Üniversitesi Sakıp Sabancı Müzesi (Emirgan, İstanbul,
                Türkiye)
              </Text>
            </Box>
            {objectData.exhibitions?.label !== 'Exhibitions' && (
                <Box>
                  <Text as="h5" display="inline-block" fontWeight="bold">
                    {t('common:object.inventoryNumber')}:&nbsp;
                  </Text>
                  <Text as="span" fontWeight="light">
                    {objectData.invno.value}
                  </Text>
                </Box>
              )}
            {objectData?.creditline && (
              <Box>
                <Text as="h5" display="inline-block" fontWeight="bold">
                  {t('common:object.credit')}:&nbsp;
                </Text>
                <Box
                  as="span"
                  fontWeight="light"
                  sx={{
                    textTransform: 'capitalize',
                    '& > div': {
                      display: 'inline-block',
                    },
                  }}
                >
                  <Markup
                    content={getCurrentLocalePropFromAPI(
                      objectData?.creditline?.value,
                      locale
                    )}
                  />
                </Box>
              </Box>
            )}
          </Flex>
        </SimpleGrid>
        <Divider my="padding.primary" borderBottomColor="white.primary3" />
        <Accordion allowToggle defaultIndex={[0]}>
          {accordionItems.map((accordion) => (
            <Box key={accordion.id}>
              <AccordionItem mb={5}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton>
                      <Text
                        flex="1"
                        textAlign="left"
                        as="span"
                        color="black.primary3"
                        fontSize="lg"
                      >
                        {t(accordion.accordionTitleTx)}
                      </Text>
                      {isExpanded ? <Minus /> : <Plus />}
                    </AccordionButton>
                    <AccordionPanel
                      pb={0}
                      pt={5}
                      sx={{
                        '&>*+*': {
                          mt: 5,
                        },
                      }}
                    >
                      {accordion.accordionContent.map((paragraph) => (
                        <Box
                          key={paragraph.id}
                          fontWeight="light"
                          fontSize="sm"
                        >
                          <Markup content={paragraph.contentDetail} />
                        </Box>
                      ))}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <Divider
                my="padding.primary"
                borderBottomColor="white.primary3"
              />
            </Box>
          ))}
        </Accordion>
      </Container>
    </Box>
  )
}

export default CollectionWorkDetailSection
