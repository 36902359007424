/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useStore } from '@store'

/**
 * Scroll event listener
 */
export const useStickyHeader = () => {
  const {
    ui: { scrollDir, setScrollDir, setIsSticky, setDisplayBannerSlider },
  } = useStore()

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false
    //let YOffset = 0

    const header = document.getElementsByTagName('header')[0]

    const sticky = Math.round(header.offsetHeight * 3)

    const stickyFix = document.getElementById('stickyFix')

    if (stickyFix) {
      if (window.scrollY == 0 && window.innerWidth < 429) {
        setIsSticky(true)
        stickyFix.style.paddingTop = '0px'
      }
    }

    /**
     * Sticky scroll listener
     */
    const handleStickyScroll = () => {
      //  YOffset = window.pageYOffset
      //console.log(YOffset)
      if (window.pageYOffset > sticky) {
        setDisplayBannerSlider(true)
        setIsSticky(true)
      } else {
        // console.log(window.scrollY)
        setDisplayBannerSlider(true)
        setIsSticky(false)

        if (stickyFix && window.pageYOffset < 86 && window.innerWidth < 429) {
          setIsSticky(true)
          stickyFix.style.paddingTop = '0px'
        }
      }
    }

    /**
     * Scroll direction listener
     */
    const handleScrollDir = () => {
      const scrollY = window.pageYOffset
      // console.log(window.pageYOffset)

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    /**
     * Handle listeners
     */
    const scrollListeners = () => {
      handleScrollDir()
      handleStickyScroll()
    }

    /**
     * Performance improvment
     */
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(scrollListeners)
        ticking = true
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollDir])

  return {}
}
