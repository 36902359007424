import { createIcon } from '@chakra-ui/icons'

const Instagram = createIcon({
  displayName: 'Instagram',
  defaultProps: {
    width: '19px',
    height: '19px',
  },
  viewBox: '0 0 19 19',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.196 4.474c-2.553 0-4.625 2.115-4.625 4.722s2.072 4.722 4.625 4.722 4.625-2.115 4.625-4.722-2.072-4.722-4.625-4.722zm0 7.768c-1.65 0-2.98-1.364-2.98-3.046 0-1.682 1.336-3.045 2.986-3.045 1.65 0 2.98 1.363 2.98 3.045-.005 1.682-1.341 3.046-2.986 3.046z"
        fill="currentColor"
      />
      <path
        d="M15.114 4.22c0 .588-.468 1.065-1.045 1.065a1.055 1.055 0 01-1.044-1.066c0-.588.468-1.065 1.044-1.065.577 0 1.044.477 1.044 1.065z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.545 0H4.847C2.18 0 0 2.229 0 4.95v8.487c0 2.726 2.18 4.955 4.847 4.955h8.698c2.667 0 4.847-2.229 4.847-4.95V4.95c0-2.721-2.18-4.95-4.847-4.95zm3.202 13.318c0 1.888-1.509 3.43-3.354 3.43h-8.39c-1.844 0-3.353-1.542-3.353-3.43V5.074c0-1.888 1.51-3.43 3.354-3.43h8.384c1.845 0 3.354 1.542 3.354 3.43v8.244h.005z"
        fill="currentColor"
      />
    </>
  ),
})

export default Instagram
