import { Grid, Box, BoxProps, Container, Text } from '@chakra-ui/react'
import { IDiscoverSsmCard } from '@interfaces'
import { DiscoverSsmCard } from '@ui'

interface ILearnWithSsm {
  stickyId: string
  subTitle: string
  title: string
  description: string
  containerProps?: BoxProps
  discoverCardCollection: IDiscoverSsmCard[]
}

const LearnWithSsm = ({
  subTitle,
  title,
  description,
  containerProps,
  discoverCardCollection,
  stickyId,
}: ILearnWithSsm) => {
  return (
    <Box
      id={stickyId}
      as="section"
      pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      {...containerProps}
    >
      <Container>
        <Box maxW="610px">
          <Text
            fontSize={{ base: '2xl', lg: '3xl' }}
            color="red.primary"
            as="h3"
            mb={{ base: 1, lg: '0' }}
          >
            {subTitle}
          </Text>
          <Text
            fontSize={{ base: '4xl', lg: '5xl' }}
            color="greyAlpha.primary"
            fontWeight="bold"
            lineHeight="normal"
            as="h2"
            mb={{ base: 1, lg: '6px' }}
          >
            {title}
          </Text>
          <Text fontSize="md" fontWeight="light" color="initial">
            {description}
          </Text>
        </Box>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          rowGap={{ base: '4', lg: '0' }}
          placeItems="center"
          mt="padding.primary"
        >
          {discoverCardCollection.map((collection) => (
            <DiscoverSsmCard
              key={collection.id}
              title={collection.title}
              description={collection.description}
              imgUrl={collection.imgUrl}
              buttonLabel={collection.buttonLabel}
              buttonUrl={collection.buttonUrl}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default LearnWithSsm
