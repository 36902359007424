import { Box, BoxProps, Flex, Container, Text } from '@chakra-ui/react'
import { LETTER_OF_ARTISTS } from '@lib/const/letter-of-artists.const'
import useTranslation from 'next-translate/useTranslation'
import { Link } from '@ui'
import { createNavLink } from '@utils'
import { useRouter } from 'next/router'
import { useRef, MutableRefObject } from 'react'

interface IArtistsFilterButtonGroup {
  activeLetter: string
  containerProps?: BoxProps
}

const ArtistsFilterButtonGroup = ({
  activeLetter,
  containerProps,
}: IArtistsFilterButtonGroup) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const containerRef = useRef(null) as MutableRefObject<HTMLDivElement>

  const isActiveLetter = (currentLetter: string, activeLetter: string) => {
    return (
      currentLetter.toLocaleLowerCase() === activeLetter.toLocaleLowerCase()
    )
  }

  const scrollToContainer = () => containerRef.current.scrollIntoView()

  return (
    <Box ref={containerRef} as="nav" {...containerProps}>
      <Container>
        <Flex flexDirection="row" alignItems="center">
          <Text
            as="span"
            color="red.primary"
            fontWeight="medium"
            fontSize="sm"
            mr="padding.primary"
          >
            {t('common:choose')}
          </Text>
          <Flex
            sx={{
              scrollSnapType: 'x proximity',
              '& > * + *': {
                ml: 'padding.primary',
              },
              '::-webkit-scrollbar': {
                width: '2px',
                height: '5px',
              },
              '::-webkit-scrollbar-track': {
                background: 'greyAlpha.primary',
              },
              '::-webkit-scrollbar-thumb': {
                background: 'red.primary',
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: 'red.primary3',
              },
            }}
            overflowX="auto"
            display="flex"
            alignItems="center"
            height={{ base: '50px', lg: '65px' }}
          >
            {LETTER_OF_ARTISTS.map((letter, i) => {
              const letterKey = Object.keys(letter)[0]
              return (
                <Link
                  key={i}
                  scroll={false}
                  onClick={scrollToContainer}
                  fontSize="sm"
                  fontWeight={
                    isActiveLetter(letterKey, activeLetter) ? 'normal' : 'light'
                  }
                  href={`${createNavLink('artist', locale)}/a-z/${letterKey}`}
                  position="relative"
                  {...(isActiveLetter(letterKey, activeLetter) && {
                    _after: {
                      content: `""`,
                      position: 'absolute',
                      bottom: '-4px',
                      left: '0',
                      width: 'full',
                      height: '2px',
                      backgroundColor: 'red.primary',
                    },
                  })}
                >
                  {letterKey.toLocaleUpperCase('tr-TR')}
                </Link>
              )
            })}
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default ArtistsFilterButtonGroup
