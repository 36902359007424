import drupalHttp from '../drupalHttpService'
import { INSTAGRAM_FEED } from '@lib/const/api-endpoints.const'

/**
 * Instagram Http Service
 */
export const InstagramHttpService = {
  /**
   * Get all instagram feed data drupalHttp service
   * for example: https://drupal.sakipsabancimuzesi.org/tr/api/instagramfeed
   */
  getFeeds: async (locale) => await drupalHttp.get(INSTAGRAM_FEED[locale]),
}
