import { Box, BoxProps, Container, Flex, Text } from '@chakra-ui/react'
import { TwoColumnBlock } from '@common'
import { ITwoColumnBlock, IFonts } from '@interfaces'

interface ITwoColBlockSection {
  stickyId: string
  sectionTitle: string
  data: ITwoColumnBlock[]
  containerProps?: BoxProps
  activeFontFamily?: IFonts
}

const TwoColBlockSection = ({
  stickyId,
  sectionTitle,
  data,
  containerProps,
  activeFontFamily = 'body',
}: ITwoColBlockSection) => {
  const bodyValueObj = data.find(
    (item) => item && 'bodyValue' in item
  ) as ITwoColumnBlock & { bodyValue?: string }
  const bodyValue = bodyValueObj ? bodyValueObj.bodyValue : null

  return (
    <Box
      id={stickyId}
      pt={{ base: '20px', lg: 0 }}
      pb={{ base: '20px', lg: '20px' }}
      as="section"
      {...containerProps}
    >
      <Container>
        <Text
          as="h2"
          color="red.primary"
          fontSize={{ base: '2xl', lg: '3xl' }}
          fontFamily={activeFontFamily}
        >
          {sectionTitle}
        </Text>
        {bodyValue && (
          <Flex
            dangerouslySetInnerHTML={{ __html: bodyValue }}
            my="2"
            mb={{ base: 'padding.primary', lg: 'padsding.primary2' }}
          />
        )}
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          maxW="container.secondary"
          mx="auto"
        >
          <TwoColumnBlock data={data} activeFontFamily={activeFontFamily} />
        </Flex>
      </Container>
    </Box>
  )
}

export default TwoColBlockSection
