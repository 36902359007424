import { Container, Box, Text, BoxProps } from '@chakra-ui/react'
import { RichText } from '@ui'
// import { useRouter } from 'next/router'

interface IStickyContentSection {
  stickyId?: string
  title: string
  descriptions: string
  containerProps?: BoxProps
  path?: string
}

const restrictedPaths: string[] = [
  '/sayfa/sakayiklar-ve-kadin',
  '/sayfa/figur',
  '/sayfa/firtina',
  '/sayfa/ekip',
  '/sayfa/istanbul',
  '/sayfa/otoportre',
  '/sayfa/sarayli-hanimlar',
  '/sayfa/manzara-ve-koyluler',
  '/sayfa/kuzgun-acar-anisina',
  '/sayfa/manzara',
  '/sayfa/double-cift',
]

const StickyContentSection = ({
  stickyId = 'sticky-content-section',
  title,
  descriptions,
  containerProps,
  path,
}: IStickyContentSection) => (
  <Box
    as="section"
    id={stickyId}
    mt={{ base: 'padding.primary2', lg: '77px' }}
    {...containerProps}
  >
    {restrictedPaths.includes(path) == false && (
      <Container>
        <Text color="red.primary" fontSize={{ base: '2xl', lg: '3xl' }}>
          {title}
        </Text>
      </Container>
    )}

    <Container
      maxW="container.secondary"
      mx="auto"
      mt={{ base: 'padding.primary', lg: 'padding.primary2' }}
      mb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      sx={{
        '&>*+*': {
          mt: '6',
        },
      }}
    >
      {descriptions && <RichText htmlContent={descriptions} />}
    </Container>
  </Box>
)

export default StickyContentSection
