/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Box, Image, Text } from '@chakra-ui/react'
import { Link } from '@ui'
import { ISelectionsFromCollectionCard } from '@interfaces'
import { createNavLink } from '@/src/utils'
import { useRouter } from 'next/router'

const SelectionsFromCollectionCard = ({
                                        variant = 'primary',
                                        people,
                                        artist,
                                        title,
                                        name,
                                        nameUrl,
                                        url,
                                        imgUrl,
                                        onClick,
                                        isDarkSection = true,
                                        containerProps,
                                        activeFontFamily = 'body',
                                        id,
                                      }: ISelectionsFromCollectionCard) => {
  const { locale } = useRouter()

  if (variant === 'withPopUp') {
    return (
      <Box
        display="block"
        transition="all 0.3s ease"
        cursor="pointer"
        _hover={{
          transform: 'translateY(-5px)',
        }}
        onClick={onClick}
        {...containerProps}
        id={`collection-${id}`}
      >
        <Image
          //   loading="lazy"
          w="full"
          src={imgUrl}
          fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
          alt={title}
          mb="10px"
        />
        <Text
          fontFamily={activeFontFamily}
          color={isDarkSection ? 'white.primary' : 'black.primary3'}
          noOfLines={2}
        >
          {title}
        </Text>
        {artist && people && (
          <Link
            href={`${createNavLink('artist', locale)}/${people}`}
            variant="inlineLink"
            isExternal={true}
          >
            <Text
              fontSize="sm"
              fontWeight="light"
              color="white.primary"
              textDecoration="underline"
            >
              {artist}
            </Text>
          </Link>
        )}
        <Text
          color="grey.primary"
          fontFamily={activeFontFamily}
          textDecoration="underline"
          noOfLines={1}
        >
          {name}
        </Text>
      </Box>
    )
  }

  return (
    <Box id={`collection-${id}`}>
      <Link
        href={url}
        display="block"
        _hover={{
          transform: 'translateY(-5px)',
        }}
        onClick={onClick}
        {...containerProps}
      >
        <Image
          //loading="lazy"
          w="full"
          src={imgUrl}
          fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
          alt={title}
          mb="10px"
        />
        <Text
          color={isDarkSection ? 'black.primary' : 'black.primary3'}
          noOfLines={2}
          fontFamily={activeFontFamily}
        >
          {title}
        </Text>
      </Link>
      {artist && people && (
        <Link
          href={`${createNavLink('artist', locale)}/${people}`}
          variant="inlineLink"
          isExternal={true}
        >
          <Text
            fontSize="sm"
            fontWeight="light"
            color="white.primary"
            textDecoration="underline"
          >
            {artist}
          </Text>
        </Link>
      )}
      {nameUrl ? (
        <Link
          href={nameUrl}
          display="none"
          _hover={{
            '& > p': {
              color: 'white.primary',
            },
          }}
        >
          <Text
            transition="color 0.3s ease"
            color="grey.primary"
            textDecoration="underline"
            noOfLines={1}
            fontFamily={activeFontFamily}
          >
            {name}
          </Text>
        </Link>
      ) : (
        <Link
          href={url}
          display="block"
          _hover={{
            transform: 'translateY(-5px)',
          }}
          onClick={onClick}
          {...containerProps}
        >
          <Text
            fontFamily={activeFontFamily}
            color="grey.primary"
            textDecoration="underline"
            noOfLines={1}
          >
            {name}
          </Text>
        </Link>
      )}
    </Box>
  )
}

export default SelectionsFromCollectionCard
