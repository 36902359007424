export const parseArtistDisplayName = (displayName: string) => {
  const parenthesisMark = '('

  if (displayName.indexOf(parenthesisMark) === -1) {
    return {
      artistName: displayName || '',
      lifeRange: null,
    }
  }

  const splittedDisplayName = displayName.split(parenthesisMark)

  if (splittedDisplayName && splittedDisplayName.length !== 2) {
    return {
      artistName: displayName || '',
      lifeRange: null,
    }
  }

  const artistName = splittedDisplayName[0]
  const dirtyLifeRange = splittedDisplayName[1]
  const lifeRange = dirtyLifeRange.replace(')', '')

  return {
    artistName: artistName || displayName || '',
    lifeRange: lifeRange || null,
  }
}
