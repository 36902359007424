/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosResponse } from 'axios'

const eMuseumInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_EMUSEUM_API_URL,
  timeout: 15000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  },
})

const responseBody = (response: AxiosResponse) => response.data

const eMuseumHttp = {
  get: (url: string) => eMuseumInstance.get(url).then(responseBody),
  post: (url: string, body: {}) =>
    eMuseumInstance.post(url, body).then(responseBody),
  put: (url: string, body: {}) =>
    eMuseumInstance.put(url, body).then(responseBody),
  delete: (url: string) => eMuseumInstance.delete(url).then(responseBody),
}

export default eMuseumHttp
