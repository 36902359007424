import drupalHttp from '../drupalHttpService'
import { CONSERVATION } from '@lib/const/api-endpoints.const'

/**
 * Conservation Http Service
 */
export const ConservationHttpService = {
  /**
   * Get conservation on drupalHttp service
   */
  getConservation: async (locale: string) =>
    await drupalHttp.get(CONSERVATION[locale]),
}
