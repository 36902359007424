import { Box, Grid, GridItem, Image, Text, Button, Link } from "@chakra-ui/react";
import { useRouter } from "next/router";


const cards = [
  {
    id: 1,
    title: {
      tr: "SSM Bahçe",
      en: "SSM Garden"
    },
    description: {
      tr: "Sizi, Boğaziçi'nin en zarif köşelerinden birinde yer alan müze bahçemizde, 115 farklı bitki türü eşliğinde doğanın dinginliğini deneyimlemeye ya da doğanın sanatla harmanlandığı bir atmosferde nefeslenmeye ya da derin bir nefes alarak yenilenmeye davet ediyoruz.",
      en: "We invite you to experience the serenity of nature in our museum garden, located in one of the most elegant corners of the Bosphorus, accompanied by 115 different plant species, or to breathe in an atmosphere where nature blends with art, or to be renewed by taking a deep breath."
    },
    imgUrl:
      "https://drupal.sakipsabancimuzesi.org/sites/default/files/styles/webp/public/2023-12/3_1.png.webp",
    buttonLabel: {
      tr: "Keşfet",
      en: "Discover"
    },
    buttonUrl: {
      tr: "/ziyaret/bahce",
      en: "/en/visit/garden"
    },
    backgroundColor: "#f0f0f5"
  },
  {
    id: 2,
    title: {
      tr: "SSM Mağaza",
      en: "SSM Shop"
    },
    description:
      "öğrencileri tarafından hazırlanarak servis ediliyor.",
    imgUrl:
      "https://sakipsabancimuzesi.org/shop/wp-content/uploads/2024/10/magaza-scaled.jpg",
    buttonLabel: {
      tr: "Ziyaret Et",
      en: "Visit Shop"
    },
    buttonUrl: {
      tr: "/shop",
      en: "/shop"
    },
    backgroundColor: "#f0f0f5"
  },
  {
    id: 3,
    title: {
      tr: "SSM Dostu Kart",
      en: "SSM Friendly Card"
    },
    description:
      "öğrencileri tarafından hazırlanarak servis ediliyor.",
    imgUrl:
      "https://drupal.sakipsabancimuzesi.org/sites/default/files/styles/webp/public/2021-10/ssm-dostu.jpg.webp",
    buttonLabel: {
      tr: "Satın Al",
      en: "Buy"
    },
    buttonUrl: {
      tr: "/uyelik",
      en: "/en/membership"
    },
    backgroundColor: "#f0f0f5"
  }
];
const DiscoverSsmCards = () => {
  const { locale } = useRouter();

  return (
    <Box py="2">
      <Grid
        templateRows="auto auto"
        gap="20px"
      >
        <GridItem colSpan={2}>
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            bg={cards[0].backgroundColor}
            overflow="hidden"
            shadow="sm"
          >
            <Image
              src={cards[0].imgUrl}
              alt={cards[0].title[locale]}
              width={{ base: "100%", md: "50%" }} // Mobilde tam genişlik, büyük ekranlarda %50
              height={{ base: "auto", lg: "460px" }}
              objectFit="cover"
            />
            <Box
              py="5"
              px="5"
              color="white"
              width={{ base: "100%", md: "50%" }} // Mobilde tam genişlik, büyük ekranlarda %50
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={{ base: "0", lg: "4" }}
            >
              <Text fontSize={{ base: "md", lg: "2xl" }} mb="10px" color="black.primary">
                {cards[0].title[locale]}
              </Text>
              <Text fontSize={{ base: "xs", lg: "sm" }} mb="20px" color="black.primary">
                {cards[0].description[locale]}
              </Text>
              <Link href={cards[0].buttonUrl[locale]} style={{ textDecoration: "none" }}>
                <Button
                  color="black.primary"
                  colorScheme="whiteAlpha"
                  variant="link"
                  fontWeight="bold"
                  fontSize="16px"
                  fontFamily="heading"
                  rightIcon={<span>→</span>}
                >
                  {cards[0].buttonLabel[locale]}
                </Button>
              </Link>
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 1 }}>
          <Box bg={cards[1].backgroundColor} overflow="hidden" shadow="sm">
            <Image
              src={cards[1].imgUrl}
              alt={cards[1].title[locale]}
              width="100%"
              height={{ base: "200px", lg: "360px" }}
              objectFit="cover"
            />
            <Box py="5" px="5" color="white" textAlign="left">
              <Text fontSize={{ base: "md", lg: "2xl" }} mb="10px" color="black.primary">
                {cards[1].title[locale]}
              </Text>
              <Link href={cards[1].buttonUrl[locale]} style={{ textDecoration: "none" }}>
                <Button
                  color="black.primary"
                  variant="link"
                  width="100%"
                  fontWeight="bold"
                  fontSize="15px"
                  fontFamily="heading"
                  rightIcon={<span>→</span>}
                >
                  {cards[1].buttonLabel[locale]}
                </Button>
              </Link>
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 1 }}>
          <Box bg={cards[2].backgroundColor} overflow="hidden" shadow="sm">
            <Image
              src={cards[2].imgUrl}
              alt={cards[2].title[locale]}
              width="100%"
              height={{ base: "200px", lg: "360px" }}
              objectFit="cover"
            />
            <Box py="5" px="5" color="white" textAlign="left">
              <Text fontSize={{ base: "md", lg: "2xl" }} mb="10px" color="black.primary">
                {cards[2].title[locale]}
              </Text>
              <Link href={cards[2].buttonUrl[locale]} style={{ textDecoration: "none" }}>
                <Button
                  color="black.primary"
                  variant="link"
                  fontWeight="bold"
                  fontSize="15px"
                  fontFamily="heading"
                  rightIcon={<span>→</span>}
                >
                  {cards[2].buttonLabel[locale]}
                </Button>
              </Link>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};


export default DiscoverSsmCards;
