import { createIcon } from '@chakra-ui/icons'

const ThreeDTour = createIcon({
  displayName: 'ThreeDTour',
  defaultProps: {
    width: 22,
    height: 28.55,
  },
  viewBox: '0 0 22 29',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.395 17.472L2 11.175v7.953l8.395 6.296v-7.952zm2 10.172L22 20.128V8.425l-.811-.634L11.234 0 .834 7.8 0 8.427v11.702l11.234 8.425 1.042-.816h.12v-.093zm0-2.54v-7.892L20 11.261v7.892l-7.605 5.952zM11.208 2.52l8.358 6.541-8.345 6.531L2.5 9.051l8.708-6.532z"
    />
  ),
})

export default ThreeDTour
