import { createIcon } from '@chakra-ui/icons'

const Clapperboard = createIcon({
  displayName: 'Clapperboard',
  viewBox: '0 0 511.81 511.81',
  defaultProps: {
    width: '26px',
    height: '26px',
  },
  path: (
    <>
      <path
        fill="currentColor"
        d="M500.94 191.81H31.606c-5.888 0-10.667 4.779-10.667 10.667v256c0 29.397 23.915 53.333 53.333 53.333h384c29.419 0 53.333-23.936 53.333-53.333v-256c.001-5.889-4.777-10.667-10.665-10.667zm-10.667 266.666c0 17.643-14.357 32-32 32h-384c-17.643 0-32-14.357-32-32V213.143h448v245.333z"
      />
      <path
        fill="currentColor"
        d="M511.265 93.1L493.11 24.215C488.929 7.468 471.82-3.049 454.86.791L25.078 102.764c-8.448 1.92-15.595 7.061-20.139 14.443-4.544 7.381-5.888 16.085-3.755 24.533l20.096 79.445c1.195 4.821 5.547 8.043 10.325 8.043.853 0 1.728-.085 2.645-.32 5.696-1.429 9.152-7.232 7.701-12.949l-1.173-4.629 462.528-105.088c2.816-.661 5.269-2.411 6.763-4.885 1.516-2.475 1.921-5.462 1.196-8.257zM35.553 190.636l-13.696-54.08c-.704-2.816-.235-5.717 1.259-8.192 1.515-2.453 3.904-4.16 6.805-4.843L459.702 21.57a9.62 9.62 0 012.325-.277c4.779 0 9.195 3.285 10.411 8.213l15.381 58.347L35.553 190.636z"
      />
      <path
        fill="currentColor"
        d="M121.718 192.919c-5.291-2.581-11.648-.491-14.336 4.779l-42.667 85.333c-2.624 5.269-.491 11.669 4.779 14.315a10.754 10.754 0 004.779 1.131c3.904 0 7.68-2.155 9.557-5.909l42.667-85.333c2.624-5.271.49-11.671-4.779-14.316zM228.364 192.919c-5.269-2.581-11.669-.491-14.315 4.779l-42.667 85.333c-2.624 5.269-.491 11.669 4.779 14.315a10.754 10.754 0 004.779 1.131c3.904 0 7.68-2.155 9.536-5.909l42.667-85.333c2.623-5.271.49-11.671-4.779-14.316zM335.03 192.919c-5.248-2.581-11.648-.491-14.315 4.779l-42.667 85.333c-2.624 5.269-.491 11.669 4.779 14.315a10.754 10.754 0 004.779 1.131c3.904 0 7.68-2.155 9.536-5.909l42.667-85.333c2.624-5.271.49-11.671-4.779-14.316zM441.697 192.919c-5.291-2.581-11.648-.491-14.315 4.779l-42.667 85.333c-2.624 5.269-.491 11.669 4.779 14.315a10.754 10.754 0 004.779 1.131c3.904 0 7.68-2.155 9.536-5.909l42.667-85.333c2.623-5.271.49-11.671-4.779-14.316z"
      />
      <path
        fill="currentColor"
        d="M500.94 277.143H31.606c-5.888 0-10.667 4.779-10.667 10.667s4.779 10.667 10.667 10.667H500.94c5.909 0 10.667-4.779 10.667-10.667s-4.779-10.667-10.667-10.667zM134.753 173.186L61.281 99.714c-4.16-4.16-10.923-4.16-15.083 0s-4.16 10.923 0 15.083l73.472 73.493a10.716 10.716 0 007.552 3.115c2.731 0 5.461-1.045 7.531-3.136 4.16-4.161 4.16-10.923 0-15.083zM239.115 149.484L165.58 76.012c-4.16-4.16-10.923-4.16-15.083 0s-4.16 10.923 0 15.083l73.515 73.472a10.716 10.716 0 0015.104 0c4.16-4.16 4.16-10.923-.001-15.083zM343.393 125.783L269.985 52.29c-4.16-4.16-10.923-4.16-15.083 0s-4.16 10.923 0 15.083l73.408 73.493c2.091 2.069 4.821 3.115 7.552 3.115s5.461-1.024 7.531-3.115c4.16-4.16 4.16-10.923 0-15.083zM447.628 102.103l-73.43-73.493c-4.16-4.16-10.923-4.16-15.083 0s-4.16 10.923 0 15.083l73.429 73.493c2.091 2.069 4.821 3.115 7.552 3.115s5.461-1.045 7.531-3.115c4.161-4.16 4.161-10.923.001-15.083z"
      />
    </>
  ),
})

export default Clapperboard
