const NAV_PATHS = {
  home: {
    en: '/',
    tr: '/',
  },
  visit: {
    en: '/visit',
    tr: '/ziyaret',
  },
  groupsAndTours: {
    en: '/visit/groups-and-tours',
    tr: '/ziyaret/grup-ve-turlar',
  },
  artists: {
    en: '/artist/a-z/a',
    tr: '/sanatci/a-z/a',
  },
  digitalSSM: {
    en: '/collections-and-research/archive-and-research-area',
    tr: '/koleksiyonlar-ve-arastirmalar/arsiv-ve-arastirma-alani',
  },
  currentExhibitions: {
    en: '/exhibitions-and-events?sort=current',
    tr: '/sergiler-ve-etkinlikler?sort=current',
  },
  rdEvents: {
    en: '/exhibitions-and-events?sort=current&activeCollection=events',
    tr: '/sergiler-ve-etkinlikler?sort=current&activeCollection=events',
  },
  onlineExhibitionFilter: {
    en: '/exhibitions-and-events?sort=current&activeCollection=online',
    tr: '/sergiler-ve-etkinlikler?sort=current&activeCollection=online',
  },
  learningFilter: {
    en: '/exhibitions-and-events?activeCollection=events&eventType=Learning-Kid-Workshop',
    tr: '/sergiler-ve-etkinlikler?activeCollection=events&eventType=Learning-Kid-Workshop',
  },
  pastExhibitions: {
    en: '/exhibitions-and-events?sort=past',
    tr: '/sergiler-ve-etkinlikler?sort=past',
  },
  atTheMuseum: {
    en: '/sayfa/muzede-anda',
    tr: '/sayfa/muzede-anda',
  },
  servicesAndAccessibility: {
    en: '/visit/services-and-accessibility',
    tr: '/ziyaret/hizmetler-ve-erisilebilirlik',
  },
  garden: {
    en: '/visit/garden',
    tr: '/ziyaret/bahce',
  },
  seed: {
    en: '/visit/seed',
    tr: '/ziyaret/seed',
  },
  msaSRestaurant: {
    en: '/visit/msas-restaurant',
    tr: '/ziyaret/msa-restorani',
  },
  collections: {
    en: '/collections-and-research/collections',
    tr: '/koleksiyonlar-ve-arastirmalar/koleksiyonlar',
  },
  archives: {
    en: '/collections-and-research/archives',
    tr: '/koleksiyonlar-ve-arastirmalar/arsivler',
  },
  collectionSearch: {
    en: '/collections-and-research/search',
    tr: '/koleksiyonlar-ve-arastirmalar/arama',
  },
  conservation: {
    en: '/collections-and-research/conservation',
    tr: '/koleksiyonlar-ve-arastirmalar/konservasyon',
  },
  archiveAndResearchArea: {
    en: '/collections-and-research/archive-and-research-area',
    tr: '/koleksiyonlar-ve-arastirmalar/arsiv-ve-arastirma-alani',
  },
  exhibitionAndEvent: {
    en: '/exhibitions-and-events',
    tr: '/sergiler-ve-etkinlikler',
  },
  exhibition: {
    en: '/exhibitions-and-events/exhibition',
    tr: '/sergiler-ve-etkinlikler/sergi',
  },
  event: {
    en: '/exhibitions-and-events/event',
    tr: '/sergiler-ve-etkinlikler/etkinlik',
  },
  online: {
    en: '/exhibitions-and-events/online',
    tr: '/sergiler-ve-etkinlikler/cevrimici',
  },
  learning: {
    en: '/exhibitions-and-events/learning',
    tr: '/sergiler-ve-etkinlikler/ogrenme',
  },
  workshop: {
    en: '/exhibitions-and-events/workshop',
    tr: '/sergiler-ve-etkinlikler/atolye',
  },
  activitiesAndLearning: {
    en: '/exhibitions-and-events',
    tr: '/sergiler-ve-etkinlikler',
  },
  discover: {
    en: '/exhibitions-and-events',
    tr: '/sergiler-ve-etkinlikler',
  },
  artForAll: {
    en: '/explore-art/art-for-all',
    tr: '/sanati-kesfet/herkes-icin-sanat',
  },
  publications: {
    en: '/explore-art/publications',
    tr: '/sanati-kesfet/yayinlar',
  },
  newsAndBlog: {
    en: '/explore-art/news-and-blog',
    tr: '/sanati-kesfet/haberler-ve-blog',
  },
  kid: {
    en: '/explore-art/kid',
    tr: '/sanati-kesfet/cocuk',
  },
  independentProject: {
    en: '/explore-art/independent-project',
    tr: '/sanati-kesfet/bagimsiz-proje',
  },
  accordionMenu: {
    en: '/',
    tr: '/',
  },
  about: {
    en: '/about-ssm',
    tr: '/hakkimizda',
  },
  team: {
    en: '/page/team',
    tr: '/sayfa/ekip',
  },
  organization: {
    en: '/about-ssm/organization',
    tr: '/hakkimizda/organizasyon',
  },
  contact: {
    en: '/about-ssm/contact',
    tr: '/hakkimizda/iletisim',
  },
  support: {
    en: '/about-ssm/support',
    tr: '/hakkimizda/destek',
  },
  membership: {
    en: '/membership',
    tr: '/uyelik',
  },
  press: {
    en: '/press-and-media',
    tr: '/basin-ve-medya',
  },
  kvkk: {
    en: '/kvkk',
    tr: '/kvkk-aydinlatma-bildirimi',
  },
  privacyPolicy: {
    en: '/privacy-policy',
    tr: '/gizlilik-politikasi',
  },
  sabanciUniversity: {
    en: 'https://www.sabanciuniv.edu/en',
    tr: 'https://www.sabanciuniv.edu/',
  },
  career: {
    en: 'https://sakipsabancimuzesi.org/en',
    tr: 'https://sakipsabancimuzesi.org/tr',
  },
  googleArtAndCulture: {
    en: 'https://artsandculture.google.com/partner/sakp-sabanc-museum',
    tr: 'https://artsandculture.google.com/partner/sakp-sabanc-museum',
  },
  giftShop: {
    en: 'https://sakipsabancimuzesi.org/shop',
    tr: 'https://sakipsabancimuzesi.org/shop/',
  },
  search: {
    en: '/search-results',
    tr: '/arama-sonuclari',
  },
  reservation: {
    en: '/reservation',
    tr: '/rezervasyon',
  },
  musicprogram: {
    en: '/musicprogram',
    tr: '/muzikprogrami',
  },
  womensday: {
    en: '/womensday',
    tr: '/womensday',
  },
  artist: {
    en: '/artist',
    tr: '/sanatci',
  },
  qrReader: {
    en: '/qr-reader',
    tr: '/qr-reader',
  },
  page: {
    en: '/page',
    tr: '/sayfa',
  },
  museumToursForStudents: {
    en: '/visit/groups-and-tours/school-groups',
    tr: '/ziyaret/grup-ve-turlar/ogrenciler-icin-muze-turlari',
  },
  museumToursForAdult: {
    en: '/visit/groups-and-tours/guided-museum-tours-adults',
    tr: '/ziyaret/grup-ve-turlar/yetiskinler-icin-rehberli-muze-turlari',
  },
  eventConference: {
    en: '/exhibitions-and-events?activeCollection=events&eventType=Event-Conference',
    tr: '/sergiler-ve-etkinlikler?activeCollection=events&eventType=Event-Conference',
  },
  learningAdultSeminar: {
    en: '/exhibitions-and-events?activeCollection=events&eventType=Learning-Adult-Seminar',
    tr: '/sergiler-ve-etkinlikler?activeCollection=events&eventType=Learning-Adult-Seminar',
  },
  learningAdultWorkshop: {
    en: '/exhibitions-and-events?activeCollection=events&eventType=Learning-Adult-Workshop',
    tr: '/sergiler-ve-etkinlikler?activeCollection=events&eventType=Learning-Adult-Workshop',
  },
}

export default NAV_PATHS
