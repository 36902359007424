import styled from '@emotion/styled'
import theme from '../../../styles/theme'
import { mq } from '../../../lib/hooks'

export const Store = styled.a`
  display: flex;
  color: ${theme.colors.white.primary3};
  align-items: center;
  padding: 0px;
  user-select: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 0px 10px 0 10px;
  position: relative;

  @media screen and (max-width: 342px) {
    margin: 0px 8px;
  }

  &:hover {
    & > span,
    & > svg {
      opacity: 0.75;
    }
  }

  .Store__Label {
    color: ${theme.colors.black.primary};

    &.Store__Label--onDrawer {
      color: ${theme.colors.white.primary};
    }

    ${mq('lg')} {
      color: ${theme.colors.white.primary};

      &.Store__Label--isSticky {
        color: ${theme.colors.white.primary};
      }
    }
  }

  .Store__Icon {
    &.Store__Icon--onDrawer {
      fill: ${theme.colors.white.primary};
    }

    ${mq('lg')} {
      fill: ${theme.colors.white.primary};

      &.Store__Icon--isSticky {
        fill: ${theme.colors.white.primary3};
      }
    }
  }
`
