import { types } from 'mobx-state-tree'

export const UI = types
  .model({
    displaySideMenu: types.boolean,
    displayBannerSlider: types.boolean,
    isSticky: types.boolean,
    scrollDir: types.union(types.literal('down'), types.literal('up')),
    activeDrawerMenuIndex: types.number,
  })
  .actions((self) => ({
    closeSideMenu() {
      self.displaySideMenu = false
    },
    toggleSideMenu() {
      self.displaySideMenu = !self.displaySideMenu
    },
    setIsSticky(val: boolean) {
      self.isSticky = val
    },
    setDisplayBannerSlider(val: boolean) {
      self.displayBannerSlider = val
    },
    setScrollDir(val) {
      self.scrollDir = val
    },
    setActiveDrawerMenuIndex(val) {
      self.activeDrawerMenuIndex = val
    },
  }))
