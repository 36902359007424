import { createIcon } from '@chakra-ui/icons'

const MusicForKids = createIcon({
  displayName: 'MusicForKids',
  viewBox: '0 0 32 27',
  defaultProps: {
    width: 32,
    height: 27,
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M27.4286 12.4784V11.2273C27.4286 5.58826 22.8149 1 17.1429 1H14.8571C9.18514 1 4.57143 5.58826 4.57143 11.2273V12.4784C1.96648 13.0064 0 15.3019 0 18.0455V20.3182C0 23.4511 2.56343 26 5.71429 26H8C8.63162 26 9.14286 25.4913 9.14286 24.8636V13.5C9.14286 12.8723 8.63162 12.3636 8 12.3636H6.85714V11.2273C6.85714 6.84091 10.4465 3.27273 14.8571 3.27273H17.1429C21.5535 3.27273 25.1429 6.84091 25.1429 11.2273V12.3636H24C23.3684 12.3636 22.8571 12.8723 22.8571 13.5V24.8636C22.8571 25.4913 23.3684 26 24 26H26.2857C29.4366 26 32 23.4511 32 20.3182V18.0455C32 15.3019 30.0335 13.0064 27.4286 12.4784ZM6.85714 23.7273H5.71429C3.82362 23.7273 2.28571 22.1981 2.28571 20.3182V18.0455C2.28571 16.1655 3.82362 14.6364 5.71429 14.6364H6.85714V23.7273ZM29.7143 20.3182C29.7143 22.1981 28.1764 23.7273 26.2857 23.7273H25.1429V14.6364H26.2857C28.1764 14.6364 29.7143 16.1655 29.7143 18.0455V20.3182Z"
        fill="white"
      />
      <path
        d="M18.7971 14.7558L16.5114 13.6194C16.1563 13.4448 15.7358 13.4619 15.3998 13.6694C15.0626 13.8766 14.8573 14.2422 14.8573 14.6361V19.2391C13.0767 18.8781 11.4287 20.2391 11.4287 22.0225C11.4287 23.5887 12.7099 24.8634 14.2859 24.8634C15.8619 24.8634 17.143 23.5887 17.143 22.0225V16.4747L17.7746 16.7887C18.3403 17.0694 19.0245 16.8422 19.3079 16.2804C19.5906 15.719 19.3617 15.0369 18.7971 14.7558Z"
        fill="white"
      />
      <path
        d="M7.98383 12.9961C8.00655 14.1093 8.28698 15.1794 8.63028 16.2284C8.76964 16.6542 8.7919 17.0504 8.7919 17.4943C8.7919 18.1951 8.93658 18.8773 8.95351 19.5773C8.97133 20.3139 9.29417 21.0499 9.15104 21.7861C9.09946 22.0513 9.03432 22.2399 9.03432 22.5133C9.03432 22.9224 8.93789 23.441 9.07472 23.8242C9.13394 23.99 9.34262 24.7115 9.07023 24.7894C8.7107 24.8921 8.50402 25.3641 8.10953 25.4358C7.41825 25.5615 6.63919 25.3303 5.99958 25.1126C4.99623 24.771 3.92941 24.4668 3.07259 23.8242C1.85804 22.9133 1.4529 21.3033 1.29484 19.9006C1.11906 18.3405 2.00508 17.039 2.6506 15.7121C3.05675 14.8772 3.83621 13.6704 4.71566 13.2834C5.5561 12.9136 7.05364 12.8877 7.82222 13.4001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.44851 12.9963C6.2701 11.3582 5.58987 9.70377 5.92775 8.06264C6.09408 7.25474 6.24896 6.41402 6.56074 5.65191C6.74723 5.19605 6.98378 4.92127 7.30595 4.54306C7.85254 3.90142 8.37587 3.27024 8.99391 2.69798C9.62824 2.11064 10.4739 1.7494 11.252 1.40059C12.4523 0.862511 13.9257 1.09857 15.1756 1.36018C16.4747 1.63208 17.8774 1.94704 19.0768 2.52739C19.6089 2.78488 20.1115 3.09082 20.6345 3.36239C20.9861 3.54495 21.3225 3.68474 21.5593 4.00884C21.6876 4.18439 21.927 4.28907 22.0846 4.43981C22.2997 4.64554 22.4496 4.92052 22.6278 5.15809C23.1115 5.80301 23.2978 6.55348 23.6423 7.26804C23.9739 7.95562 24.356 8.77623 24.3876 9.54858C24.409 10.0742 24.542 10.3913 24.6928 10.8774C24.7657 11.1123 25.006 12.7679 24.8724 12.8347"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3574 12.9977C25.0149 13.1456 24.2122 13.4267 24.0016 13.8058C23.8368 14.1024 23.5329 14.6447 23.5841 14.9775C23.6235 15.2338 23.7832 15.4963 23.84 15.7631C23.9186 16.1323 23.9836 16.4221 23.9836 16.8046C23.9836 17.9803 23.7724 19.1661 23.8849 20.3332C23.9546 21.0566 24.0734 21.75 24.1722 22.4701C24.2033 22.6968 24.1422 22.9072 24.1587 23.13C24.1761 23.3644 24.3372 23.6091 24.3877 23.8438C24.4593 24.1769 24.6301 24.4948 24.6301 24.8359C24.6301 25.0125 24.5966 25.2214 24.6346 25.3926C24.6498 25.4612 25.6048 25.6274 25.7434 25.6664C25.9557 25.7261 26.2195 25.3402 26.345 25.2265C26.877 24.7443 27.4156 24.3006 27.9432 23.8258C28.6586 23.182 29.4354 22.571 29.9588 21.7204C30.4744 20.8827 30.2767 19.6167 30.1654 18.7036C30.0914 18.0969 30.0768 17.4332 29.7838 16.8765C29.5652 16.4613 29.1895 15.7916 28.8141 15.5027C28.5772 15.3206 28.4302 15.0661 28.2664 14.8204C27.9849 14.3981 27.5516 13.9979 27.1845 13.6577C26.677 13.1873 26.1773 12.7556 25.6447 12.3199C25.3804 12.1036 24.5213 12.429 24.6301 12.7553"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2463 15.9057C17.7587 15.5101 17.2434 15.1417 16.7245 14.7879C16.4462 14.5982 16.3099 14.3656 16.0645 14.1728C15.7404 13.9182 15.3253 13.3912 15.454 14.1818C15.6498 15.3846 15.6286 16.5776 15.8625 17.7732C16.052 18.7416 16.4848 19.8772 16.3025 20.8798C16.244 21.2011 16.1009 21.7713 16.271 22.0874C16.4098 22.3451 16.4921 22.6814 16.5449 22.9718C16.6473 23.5353 16.068 24.0583 15.6156 24.3051C14.9248 24.6819 14.6081 23.6908 14.1252 23.3803C13.2806 22.8373 12.9513 21.4627 13.4383 20.597C13.6892 20.1509 14.1093 19.9074 14.4843 19.5824C14.7573 19.3458 14.9868 19.0753 15.3014 18.8955C15.5316 18.7639 15.7097 18.6531 15.9837 18.6531C16.3858 18.6531 15.6884 18.9226 15.5976 18.9763C14.8614 19.4126 14.2758 19.7824 14.0264 20.6553C13.931 20.9893 13.7071 21.2139 13.7211 21.5801C13.7412 22.1009 14.079 22.8761 14.4304 23.2411C14.5802 23.3966 15.1224 23.4154 15.2565 23.2411C15.5674 22.8369 15.9041 22.3195 15.8042 21.7866C15.702 21.242 15.5483 20.6541 15.2744 20.1884C15.1487 19.9747 15.0766 20.2204 14.9961 20.368C14.7704 20.7817 14.3649 21.4546 14.4843 21.9482C14.7726 23.1399 15.5331 20.6921 15.5617 20.368C15.5689 20.2871 15.5871 19.2837 15.463 19.7305C15.3074 20.2905 15.014 20.8916 15.014 21.4813C15.014 21.6266 15.1657 22.0063 15.3103 21.7866C15.4347 21.5978 15.5268 21.4252 15.5797 21.203C15.5851 21.1805 15.7151 20.7286 15.7233 20.7451C15.9018 21.1019 15.488 21.8793 15.436 22.2535C15.3931 22.5629 15.4418 22.9366 15.6605 23.1783C15.9431 23.4907 16.3222 22.7791 16.0825 22.9359C15.832 23.0996 15.5797 23.3374 15.5797 22.8551"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})

export default MusicForKids
