import { Box, Container, Flex, Text } from '@chakra-ui/react'
import { SsmMap, TwoColumnBlock } from '@common'
import { IAddressContactAndTransportation } from '@interfaces'

const AddressContactAndTransportation = ({
  stickyId,
  sectionTitle,
  data,
  activeFontFamily = 'body',
  containerProps,
  isLastItem = false,
}: IAddressContactAndTransportation) => {
  const { iframeLink, twoColumnBlockData } = data || {}

  return (
    <Box
      as="section"
      id={stickyId}
      py={{ base: 'padding.primary', lg: 'padding.primary2' }}
      {...(isLastItem && {
        pb: { base: 'padding.primary2', lg: 'padding.primary4' },
      })}
      {...containerProps}
    >
      <Container>
        {sectionTitle && (
          <Text
            as="h2"
            color="red.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
            fontFamily={activeFontFamily}
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
          >
            {sectionTitle}
          </Text>
        )}

        <Flex
          flexDirection="column"
          w="full"
          maxW="container.secondary"
          mx="auto"
        >
          {iframeLink && <SsmMap iframeLink={iframeLink} />}

          <TwoColumnBlock
            data={twoColumnBlockData}
            containerProps={{ mt: 'padding.primary' }}
            activeFontFamily={activeFontFamily}
          />
        </Flex>
      </Container>
    </Box>
  )
}

export default AddressContactAndTransportation
