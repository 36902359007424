import { IFonts, IStickyNav } from '@interfaces'
import { Box, Flex, Text, Button, Image } from '@chakra-ui/react'
import { SSMLogo } from '@icon'
import { MotionImage } from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { Link } from 'react-scroll'

interface IKidHero {
  navs: IStickyNav[]
  activeFontFamily: IFonts
}

const KidHero = ({ navs, activeFontFamily = 'mikado' }: IKidHero) => {
  const { t } = useTranslation()

  return (
    <Flex
      as="section"
      overflowX="hidden"
      minHeight="100vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
      bgColor="white.primary2"
      pb={{ base: 'padding.primary', lg: '0' }}
      overflow="hidden"
    >
      <Box position="absolute" top="0" left="0" right="0" bottom="0">
        <MotionImage
          imgUrl="/img/explore-art/kid/banner/shape-1.png"
          imgAlt="Shape"
          containerProps={{
            style: {
              position: 'absolute',
              top: '192px',
              left: '-48px',
            },
          }}
          imageProps={{
            width: '132px',
          }}
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 4,
            },
          }}
        />
        <MotionImage
          imgUrl="/img/explore-art/kid/banner/shape-2.png"
          imgAlt="Shape"
          containerProps={{
            style: {
              position: 'absolute',
              bottom: '90px',
              left: '87px',
            },
          }}
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 2,
            },
          }}
          imageProps={{
            width: '68px',
          }}
        />
        <MotionImage
          imgUrl="/img/explore-art/kid/banner/shape-3.png"
          imgAlt="Shape"
          containerProps={{
            style: {
              position: 'absolute',
              top: '-10px',
              right: '-40px',
            },
          }}
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 3,
            },
          }}
          imageProps={{
            width: '121px',
          }}
        />

        <MotionImage
          imgUrl="/img/explore-art/kid/banner/shape-4.png"
          imgAlt="Shape"
          containerProps={{
            style: {
              position: 'absolute',
              bottom: '61px',
              right: '-50px',
            },
          }}
          motionProps={{
            animate: { y: 70 },
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 4,
            },
          }}
          imageProps={{
            width: '162px',
          }}
        />
      </Box>
      <Flex
        flexDirection="column"
        alignItems="center"
        mt={{ base: '20', lg: '0' }}
        mb="16"
        zIndex={1}
      >
        <Flex alignItems="center" mb="2.5">
          <SSMLogo mr="11px" />
          <Text
            fontFamily={activeFontFamily}
            as="h1"
            fontSize="25px"
            fontWeight="medium"
            color="red.primary"
            pt="3px"
          >
            {t('explore-art:kid.banner.with')}
          </Text>
        </Flex>
        <Text
          fontFamily={activeFontFamily}
          as="h1"
          fontWeight="extrabold"
          fontSize={{ base: '15vw', lg: '4-5xl' }}
          lineHeight="none"
          textAlign="center"
          display="flex"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Text fontFamily={activeFontFamily} as="span" color="yellow.primary4">
            {t('explore-art:kid.banner.learn')}
          </Text>
          <Text fontFamily={activeFontFamily} as="span" color="red.primary4">
            &nbsp;{t('explore-art:kid.banner.withFun')}
          </Text>
        </Text>
      </Flex>
      <Flex
        flexWrap="wrap"
        justifyContent="center"
        alignItems="flex-end"
        sx={{
          '& > * + *': {
            pt: { base: 'padding.primary2', lg: '0' },
          },
        }}
      >
        <Flex flexDirection="column" alignItems="center" maxWidth="336px">
          <Image
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
            src="/img/explore-art/kid/banner/puzzle.png"
            alt="Puzzle"
          />
          <Button
            as={Link}
            to={navs[0].href}
            spy={true}
            smooth={true}
            duration={200}
            cursor="pointer"
            width="230px"
            height="60px"
            fontFamily={activeFontFamily}
            fontWeight="bold"
            fontSize="xxl"
            borderRadius="264px"
            backgroundColor="red.primary"
            _hover={{
              backgroundColor: 'red.primary2',
            }}
          >
            {t(navs[0].navTitleTx)}
          </Button>
        </Flex>
        <Flex flexDirection="column" alignItems="center" maxWidth="254px">
          <Image
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
            src="/img/explore-art/kid/banner/painting.png"
            alt="Painting"
          />
          <Button
            width="180px"
            height="60px"
            as={Link}
            to={navs[1].href}
            spy={true}
            smooth={true}
            duration={200}
            cursor="pointer"
            fontFamily={activeFontFamily}
            fontWeight="bold"
            fontSize="xxl"
            borderRadius="264px"
            backgroundColor="blue.primary2"
            _hover={{
              backgroundColor: 'blue.primary3',
            }}
          >
            {t(navs[1].navTitleTx)}
          </Button>
        </Flex>
        <Flex flexDirection="column" alignItems="center" maxWidth="286px">
          <Image
            fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
            src="/img/explore-art/kid/banner/match.png"
            alt="Match"
          />
          <Button
            width="180px"
            height="60px"
            as={Link}
            to={navs[2].href}
            spy={true}
            smooth={true}
            duration={200}
            cursor="pointer"
            fontFamily={activeFontFamily}
            fontWeight="bold"
            fontSize="xxl"
            borderRadius="264px"
            backgroundColor="yellow.primary4"
            _hover={{
              backgroundColor: 'yellow.primary',
            }}
          >
            {t(navs[2].navTitleTx)}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default KidHero
