export const FONTSIZES = {
  xs: '0.75rem',
  sm: '16px',
  md: '18px',
  lg: '22px',
  xl: '24px',
  xxl: '26px',
  '2xl': '28px',
  '3xl': '32px',
  '3-5xl': '44px',
  '4xl': '70px',
  '4-5xl': '95px',
  '5xl': '80px',
  '6xl': '110px',
  '7xl': '110px',
  '8xl': '110px',
  '9xl': '110px',
}
