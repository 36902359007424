import { Container, Tabs } from '@chakra-ui/react'
import { IArtCollection, ArtCollectionType } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import React, { useRef } from 'react'
import TabList from './TabList'
import TabPanels from './TabPanels'

interface ISection {
  collection: IArtCollection[]
  activePage: ArtCollectionType
}

const Section = ({ collection, activePage }: ISection) => {
  const sectionRef = useRef(null)
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = React.useState(0)

  const handleSelectChange = (value: string) => {
    setTabIndex(parseInt(value, 10))
  }

  const handleTabItemButton = () => {
    sectionRef.current.scrollIntoView()
  }

  return (
    <Container
      py={{ base: 'padding.primary', lg: 24 }}
      as="section"
      ref={sectionRef}
    >
      <Tabs
        display="flex"
        flexDirection={{ base: 'column', lg: 'row' }}
        maxW="container.secondary"
        mx="auto"
        index={tabIndex}
        onChange={(index: number) => {
          setTabIndex(index)
        }}
      >
        <TabList
          activePage={activePage}
          handleSelectChange={handleSelectChange}
          tabListCollection={collection.map(({ id, title, type }) => {
            return { id, title: t(title), type }
          })}
          handleTabItemButton={handleTabItemButton}
        />
        <TabPanels
          activePage={activePage}
          activeTabIndex={tabIndex}
          tabPanelsCollection={collection.map(
            ({ id, description, collection, type }) => {
              return { id, description: t(description), collection, type }
            }
          )}
        />
      </Tabs>
    </Container>
  )
}

export default Section
