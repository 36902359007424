import { InstagramHttpService } from '@httpServices'
import { v4 as uuidv4 } from 'uuid'

export const InstagramService = {
  /**
   * Get all instagram feed data drupalHttp service
   */
  getFeeds: async (locale) => {
    const feeds = await InstagramHttpService.getFeeds(locale)

    return feeds.map((media) => ({
      id: media?.nid[0]?.value || uuidv4(),
      imgUrl:
        (media?.field_field_if_instagram_imgurl &&
          media?.field_field_if_instagram_imgurl[0]?.url) ||
        '',
      imgAlt:
        (media?.field_if_instagram_imgalt &&
          media?.field_if_instagram_imgalt[0]?.value) ||
        '',
      url:
        (media?.field_if_instagram_instagramurl &&
          media?.field_if_instagram_instagramurl[0]?.value) ||
        '',
    }))
  },
}
