import { v4 as uuidv4 } from 'uuid'
import { IStickyNav } from '@interfaces'

const KIDS_STICKY_NAV: IStickyNav[] = [
  {
    id: uuidv4(),
    navTitleTx: 'common:kid.childrensBooks',
    href: 'childrensBooks',
  },
  {
    id: uuidv4(),
    navTitleTx: 'common:kid.workshops',
    href: 'childrensWorkshops',
  },
  {
    id: uuidv4(),
    navTitleTx: 'common:kid.tours',
    href: 'childrensTours',
  },
]

export default KIDS_STICKY_NAV
