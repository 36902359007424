import { IObject } from '@interfaces'
import { v4 as uuidv4 } from 'uuid'
import { convertEMuseumMediaLinks } from './convertMediaLinksOnAPI'

export const getPhotoCollectionFromObject = (object: IObject) => {
  if (object?.media) {
    return convertEMuseumMediaLinks(
      object?.media?.value,
      (object?.title && object?.title?.value) || 'Object Item'
    )
  }

  return [
    {
      id: uuidv4(),
      url: (object?.primaryMedia && object?.primaryMedia?.value) || '',
      alt: (object?.title && object?.title?.value) || 'Object Item',
    },
  ]
}
