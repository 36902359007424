import { IExhibitionAndEventLocationFilter } from '@interfaces'
import { v4 as uuidv4 } from 'uuid'

const EXHIBITION_AND_EVENT_LOCATION_FILTER_TYPES: IExhibitionAndEventLocationFilter[] =
  [
    {
      id: uuidv4(),
      labelTx: 'exhibition-and-event:home.locationFilterTypes.inTheMuseum',
      type: 'inTheMuseum',
    },
    {
      id: uuidv4(),
      labelTx: 'exhibition-and-event:home.locationFilterTypes.online',
      type: 'online',
    },
  ]

export default EXHIBITION_AND_EVENT_LOCATION_FILTER_TYPES
