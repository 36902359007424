import { Box, Container, Flex, BoxProps } from '@chakra-ui/react'
import {
  CollectionSearchPagination,
  NewsAndBlogSortBox,
  ClearTagOfNew,
} from '@common'
import { INewsAndBlogCard, IFonts } from '@interfaces'
import {
  NewsAndBlogCard,
  Skeletons,
  CollectionPaginationText,
  NoResultsFound,
} from '@ui'
import useTranslation from 'next-translate/useTranslation'
import { usePagination, useFilterCollection } from '@lib/hooks'
import { useStore } from '@/src/models/Root'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'

interface INewsAndBlogCollectionGrid {
  collection: INewsAndBlogCard[]
  isWithFilters?: boolean
  activeFontFamily?: IFonts
  containerProps?: BoxProps
  innerContainerProps?: BoxProps
}

const NewsAndBlogCollectionGrid = observer(
  ({
    collection,
    isWithFilters = true,
    activeFontFamily = 'body',
    containerProps,
    innerContainerProps,
  }: INewsAndBlogCollectionGrid) => {
    const {
      newsAndBlog: { sortResult },
    } = useStore()
    const { t } = useTranslation()
    const filteredCollection = useFilterCollection(collection, sortResult)
    const {
      isLoading,
      handlePagination,
      containerRef,
      currentPage,
      totalPage,
      paginatedData,
    } = usePagination(filteredCollection, 6)
    const { query, push, pathname } = useRouter()

    const handleClearTag = () => {
      push(pathname, undefined, { scroll: false })
    }

    if (!filteredCollection.length) {
      return (
        <Box as="main" pt={4} ref={containerRef} {...containerProps}>
          {isWithFilters && <NewsAndBlogSortBox />}
          <Container maxWidth="container.secondary" mx="auto" pt={4}>
            <Flex flexDirection="column" alignItems="center">
              {query?.tagId && <ClearTagOfNew onClick={handleClearTag} />}
              <NoResultsFound />
            </Flex>
          </Container>
        </Box>
      )
    }

    return (
      <Box as="main" pt={4} ref={containerRef} {...containerProps}>
        {isWithFilters && <NewsAndBlogSortBox />}

        <Box
          as="section"
          px={{ base: 'padding.primary', lg: '0' }}
          pt={{ base: '16', lg: '20' }}
          pb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
          {...innerContainerProps}
        >
          <Container maxWidth="container.secondary" mx="auto">
            <Flex flexDirection="column">
              {isLoading ? (
                <>
                  <Skeletons.ArticleCard />
                  <Skeletons.ArticleCard />
                  <Skeletons.ArticleCard />
                  <Skeletons.ArticleCard />
                  <Skeletons.ArticleCard />
                  <Skeletons.ArticleCard />
                </>
              ) : (
                <Box>
                  {paginatedData.map(({ id, type, ...rest }, i) => (
                    <NewsAndBlogCard
                      key={id}
                      type={t(`common:newsAndBlog.sortTypes.${type}`)}
                      {...rest}
                      isLastItem={i + 1 === paginatedData.length}
                      activeFontFamily={activeFontFamily}
                    />
                  ))}
                </Box>
              )}
            </Flex>
          </Container>
          <CollectionSearchPagination
            currentPage={currentPage}
            totalPage={totalPage}
            handlePagination={handlePagination}
            activeFontFamily={activeFontFamily}
          />
          <CollectionPaginationText
            activeLengthOnPage={paginatedData.length}
            totalLength={filteredCollection.length}
            activeFontFamily={activeFontFamily}
          />
        </Box>
      </Box>
    )
  }
)

export default NewsAndBlogCollectionGrid
