import { useContext, createContext } from 'react'
import { types, Instance, onSnapshot } from 'mobx-state-tree'
import { enableStaticRendering } from 'mobx-react-lite'
import { UI } from './UI'
import { ArtSearch } from './ArtSearch'
import { ExhibitionAndEvents } from './ExhibitionAndEvent'
import { Publications } from './Publications'
import { NewsAndBlog } from './NewsAndBlog'
import { Search } from './Search'
import { PressRelease } from './PressRelease'

const isServer = typeof window === 'undefined'
enableStaticRendering(isServer)

const RootModel = types.model({
  ui: UI,
  artSearch: ArtSearch,
  exhibitionAndEvents: ExhibitionAndEvents,
  publications: Publications,
  newsAndBlog: NewsAndBlog,
  search: Search,
  pressRelease: PressRelease,
})

export const rootStore = RootModel.create({
  ui: {
    displaySideMenu: false,
    displayBannerSlider: true,
    isSticky: false,
    scrollDir: 'up',
    activeDrawerMenuIndex: 0,
  },
  artSearch: {
    searchTerm: '',
    displayFilterModal: false,
    displaySortModal: false,
    sortResult: 'Relevance',
    page: '1',
    beginDate: new Date('July 20, 1700 00:20:18'),
    endDate: new Date(),
    selectedInCollectionOptions: 'All',
    displayBeginDate: false,
    displayEndDate: false,
    // limitCountOfPage: 40,
    // listingView: 'column',
  },
  exhibitionAndEvents: {
    displayFilterModal: false,
    displaySortModal: false,
    sortResult: 'current',
    activeCollection: 'exhibitions',
    page: '1',
  },
  publications: {
    displaySortModal: false,
    sortResult: 'all-publications',
  },
  newsAndBlog: {
    displaySortModal: false,
    sortResult: 'all',
  },
  search: {
    displaySearchModal: false,
    searchTerm: '',
    searchFacet: 'all',
    page: '0',
    totalPage: '0',
    totalCount: '0',
  },
  pressRelease: {
    displaySortModal: false,
    filterResult: 'all',
  },
})

const isProd = process.env.NEXT_PUBLIC_ENV === 'production'
onSnapshot(
  rootStore,
  (snapshot) => !isProd && console.log('Snapshot: ', snapshot)
)

export type RootInstance = Instance<typeof RootModel>
const RootStoreContext = createContext<null | RootInstance>(null)

export const Provider = RootStoreContext.Provider
export function useStore() {
  const store = useContext(RootStoreContext)
  if (store === null) {
    throw new Error('StoreIcon cannot be null, please add a context provider')
  }
  return store
}
