import { Container, ContainerProps, Button } from '@chakra-ui/react'
import { ChevronLeft, Share } from '@icon'
import { Link, MotionBox } from '@ui'
import { fadeIn } from '@lib/motion'
import { IWebShare } from '@interfaces'
import { RWebShare } from 'react-web-share'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

interface IDetailPageNavigationHeader {
  leftButtonLabel?: string
  leftButtonHref?: string
  isWithBackButton?: boolean
  rightButtonLabel?: string
  rightButtonAction?: () => void
  isDarkSection?: boolean
  variant?: 'primary' | 'extended'
  leftChildren?: JSX.Element
  rightChildren?: JSX.Element
  isRightButtonShareAction?: boolean
  shareProps?: IWebShare
  containerProps?: ContainerProps
}

const DetailPageNavigationHeader = ({
  leftButtonLabel,
  leftButtonHref,
  isWithBackButton = false,
  rightButtonLabel,
  rightButtonAction,
  isDarkSection = true,
  variant = 'primary',
  leftChildren,
  rightChildren,
  isRightButtonShareAction = false,
  shareProps,
  containerProps,
}: IDetailPageNavigationHeader) => {
  const { t } = useTranslation()
  const router = useRouter()

  const goBack = (e) => {
    e.preventDefault()
    router.back()
  }

  if (variant === 'extended') {
    return (
      <Container
        display="flex"
        alignItems="baseline"
        justifyContent="space-between"
        mb={{ base: 'padding.primary', lg: '3.5' }}
        mt="16"
        {...containerProps}
      >
        {leftChildren}
        {rightChildren}
      </Container>
    )
  }

  return (
    <Container
      display="flex"
      alignItems="baseline"
      justifyContent="space-between"
      mb="padding.primary"
      {...containerProps}
    >
      <MotionBox variants={fadeIn.left}>
        {isWithBackButton ? (
          <Button
            variant="unstyled"
            display="inline-flex"
            leftIcon={<ChevronLeft />}
            color={isDarkSection ? 'white.primary' : 'black.primary3'}
            onClick={goBack}
            fontSize="sm"
            fontWeight="light"
          >
            {leftButtonLabel}
          </Button>
        ) : (
          <Button
            as={Link}
            variant="unstyled"
            display="inline-flex"
            leftIcon={<ChevronLeft />}
            color={isDarkSection ? 'white.primary' : 'black.primary3'}
            href={leftButtonHref}
            fontSize="sm"
            fontWeight="light"
            scroll={false}
            target="_blank"
          >
            {leftButtonLabel}
          </Button>
        )}
      </MotionBox>
      <MotionBox variants={fadeIn.right}>
        {isRightButtonShareAction ? (
          <RWebShare data={shareProps} closeText={t('common:closeLabel')}>
            <Button
              variant="unstyled"
              rightIcon={<Share />}
              color={isDarkSection ? 'white.primary' : 'black.primary3'}
              fontSize="sm"
              fontWeight="light"
            >
              {rightButtonLabel}
            </Button>
          </RWebShare>
        ) : (
          <Button
            variant="unstyled"
            rightIcon={<Share />}
            color={isDarkSection ? 'white.primary' : 'black.primary3'}
            onClick={rightButtonAction}
            fontSize="sm"
            fontWeight="light"
          >
            {rightButtonLabel}
          </Button>
        )}
      </MotionBox>
    </Container>
  )
}

export default DetailPageNavigationHeader
