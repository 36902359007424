import { IArtCollectionItemType } from '@interfaces'
import Masonry from 'react-responsive-masonry'
import { SelectionsFromCollectionCard } from '@ui'
import { Box } from '@chakra-ui/react'
import { IntersectionObserver, FadeInUpBox, StaggerWrap } from '@motion'

interface ISelectionsFromCollection {
  collection: IArtCollectionItemType[]
}

const SelectionsFromCollection = ({
  collection,
}: ISelectionsFromCollection) => {
  return (
    <Box mt="padding.primary">
      <StaggerWrap staggerChildren={1.6}>
        <Masonry columnsCount={2} gutter="20px">
          {collection.map((col) => (
            <IntersectionObserver key={col.id}>
              <FadeInUpBox duration={1}>
                <SelectionsFromCollectionCard
                  key={col.id}
                  url={col.url}
                  title={col.title}
                  name={col.name}
                  imgUrl={col.imgUrl}
                  isDarkSection={false}
                />
              </FadeInUpBox>
            </IntersectionObserver>
          ))}
        </Masonry>
      </StaggerWrap>
    </Box>
  )
}

export default SelectionsFromCollection
