/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  BoxProps,
  Flex,
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Divider,
} from '@chakra-ui/react'
import { ChevronDown } from '@icon'
import { observer } from 'mobx-react-lite'
import { useStore } from '@store'
import useTranslation from 'next-translate/useTranslation'
import PRESS_RELEASE_FILTER_OPTIONS, {
  AVALIABLE_PRESS_RELEASES_OPTION_VALUES,
} from '@lib/const/press-release-filter-options.const'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

interface IPressReleaseFilterBox {
  containerProps?: BoxProps
  containerRef: any
}

const PressReleaseFilterBox = observer(
  ({ containerProps, containerRef }: IPressReleaseFilterBox) => {
    const { t } = useTranslation()
    const {
      pressRelease: {
        displaySortModal,
        toggleDisplaySortModal,
        filterResult,
        setFilterResult,
      },
    } = useStore()
    const router = useRouter()

    useEffect(() => {
      console.log({ router: router, query: router.query })
      if (
        router?.query &&
        router?.query?.year &&
        AVALIABLE_PRESS_RELEASES_OPTION_VALUES.includes(
          router?.query?.year as string
        )
      ) {
        setFilterResult(router?.query?.year as string)
      } else if (!router?.query?.year) {
        setFilterResult('all')
      }
    }, [router, setFilterResult])

    const getActiveSortLabel = () => {
      switch (filterResult) {
        case 'all':
          return t('legal:press.filterBox.all')

        default:
          return filterResult
      }
    }

    const handleOnClickSortButton = (sortVal: string) => {
      setFilterResult(sortVal)
      toggleDisplaySortModal()
      if (sortVal === 'all') {
        router.push(router.pathname, undefined, { scroll: false })
        containerRef.current.scrollIntoView()
      } else {
        const query = {
          year: sortVal,
        }

        router.push(
          {
            pathname: router.pathname,
            query,
          },
          undefined,
          { scroll: false }
        )
        containerRef.current.scrollIntoView()
      }
    }

    return (
      <Box as="nav" bgColor="white.primary" zIndex={3} {...containerProps}>
        <Flex
          py={{ base: 4, lg: 5 }}
          height={{ base: '50px', lg: '65px' }}
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Popover
            placement="bottom-start"
            strategy="fixed"
            isOpen={displaySortModal}
          >
            <PopoverTrigger>
              <Button
                variant="unstyled"
                rightIcon={
                  <ChevronDown
                    fontSize="16px"
                    transition="transform 0.3s ease"
                    transform={
                      displaySortModal ? 'rotate(180deg)' : 'rotate(0deg)'
                    }
                  />
                }
                onClick={toggleDisplaySortModal}
                fontWeight="bold"
                fontSize="sm"
                color="black.primary3"
                minWidth={{ base: '16px', lg: '10' }}
                display="flex"
                alignItems="center"
              >
                <Text color="red.primary">
                  {t('legal:press.filterBox.year')}
                  {': \u00A0'}
                </Text>
                {getActiveSortLabel()}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              background="white.primary"
              // border="none"
              borderRadius="none"
              boxShadow="none"
              _focus={{
                boxShadow: 'none',
              }}
              maxHeight={'200px'}
              overflow={'auto'}
              sx={{
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'white.primary3',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'red.primary3',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: 'red.primary2',
                },
              }}
            >
              <PopoverBody p="0">
                <Flex flexDirection="column" px="2.5">
                  {PRESS_RELEASE_FILTER_OPTIONS().map((option, index) => (
                    <Button
                      key={option.id}
                      onClick={() => handleOnClickSortButton(option.value)}
                      fontWeight={
                        option.value === filterResult ? 'bold' : 'light'
                      }
                      color={
                        option.value === filterResult
                          ? 'red.primary'
                          : 'black.primary3'
                      }
                      fontSize="sm"
                      variant="unstyled"
                      height="auto"
                      _hover={{
                        color: 'red.primary',
                      }}
                    >
                      {index === 0 && (
                        <Divider
                          borderBottomColor="white.primary3"
                          opacity="1"
                        />
                      )}
                      <Text py="4" textAlign="left">
                        {option?.isWithLocalization
                          ? t(option.labelTx)
                          : option.labelTx}
                      </Text>
                      <Divider borderBottomColor="white.primary3" opacity="1" />
                    </Button>
                  ))}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Box>
    )
  }
)

export default PressReleaseFilterBox
