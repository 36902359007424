import { Box, Image, AspectRatio, Text } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IAboutTheArtistPhotos, IFonts } from '@interfaces'
interface IArtistPhotoSlider {
  artistPhotos: IAboutTheArtistPhotos[]
  artistName: string
  activeFontFamily?: IFonts
}

const ArtistPhotoSlider = ({
  artistPhotos,
  artistName,
  activeFontFamily = 'body',
}: IArtistPhotoSlider) => (
  <Box
    position="absolute"
    top={{ base: '-200px', lg: '-245px' }}
    left="0px"
    right="0px"
    className="artist-photo-swiper"
    sx={{
      '&.artist-photo-swiper .swiper-container': {
        pl: { base: 'padding.primary', lg: '0' },
      },
      '&.artist-photo-swiper .swiper-slide': {
        width: 'auto',
      },
    }}
  >
    <Text
      fontSize={{ base: '4xl', lg: '5xl' }}
      color="whiteAlpha.primary2"
      textAlign="center"
      position="absolute"
      top="9"
      left="0"
      right="0"
      zIndex={2}
      userSelect="none"
      fontFamily={activeFontFamily}
    >
      {artistName}
    </Text>
    <Swiper
      slidesPerView={'auto'}
      spaceBetween={20}
      centeredSlides={true}
      initialSlide={1}
    >
      {artistPhotos &&
        artistPhotos.length > 0 &&
        artistPhotos.map((content) => (
          <SwiperSlide key={content.id}>
            <Box width={{ base: '300px', lg: '436px' }}>
              <AspectRatio ratio={1}>
                <Image
                  src={content.imgUrl}
                  alt={content.imgAlt || ''}
                  fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
                />
              </AspectRatio>
              <Text as="h6" textAlign="center" fontFamily={activeFontFamily}>
                {content.imgAlt || ''}
              </Text>
            </Box>
          </SwiperSlide>
        ))}
    </Swiper>
  </Box>
)

export default ArtistPhotoSlider
