import { useState, useEffect, useRef } from 'react'
import { IPressReleaseSortTypes } from '@interfaces'

export const usePagination = (
  collection = [],
  limit = 8,
  fakeLoadingMs = 1000,
  /**
   * activeSortType is only use on press release page
   */
  activeSortType: IPressReleaseSortTypes = 'displayDate-asc'
) => {
  const containerRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(
    Math.ceil(collection.length / limit)
  )
  const [paginatedData, setPaginatedData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const startLoading = () => setLoading(true)
  const stopLoading = () => setLoading(false)

  useEffect(() => {
    const startIndex = limit * (currentPage - 1)
    const endIndex = limit * currentPage

    setPaginatedData(collection.slice(startIndex, endIndex))
    setTotalPage(Math.ceil(collection.length / limit))
  }, [collection, currentPage, limit, activeSortType])

  useEffect(() => {
    setCurrentPage(1)
  }, [collection.length, activeSortType])

  // Triggers fetch for new page
  const handlePagination = (page: number) => {
    startLoading()
    containerRef.current?.scrollIntoView()
    setTimeout(() => {
      stopLoading()
      setCurrentPage(page)
    }, fakeLoadingMs)
  }

  return {
    handlePagination,
    isLoading,
    containerRef,
    currentPage,
    totalPage,
    paginatedData,
  }
}
