
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import SEO from '@/next-seo.config'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider, rootStore } from '@store'
import theme from '@styles/theme'
import { DefaultSeo } from 'next-seo'
import App, { AppProps } from 'next/app'
import NextHead from 'next/head'
import NextNProgress from 'nextjs-progressbar'
import 'swiper/swiper-bundle.min.css'
import { NavigationService } from '@services'
import '@styles/react-datepicker.css'
import dynamic from 'next/dynamic'
import GoogleAnalytics from '../components/common/GoogleAnalytics'

const InterFontFace = dynamic(
  () => import('../components/common/InterFontFace')
)

function SSMApp({ Component, pageProps, router }: AppProps) {
  return (
    <>
      <GoogleAnalytics />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="google-site-verification"
          content="_9sBIFY48O0VRVxNnPB2OA0O2M-_rLVPMetSckpA5QA"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '895236265319240');
              fbq('track', 'PageView');
            `,
          }}
        />
        <noscript>
          <img
            alt=""
            height="1"
            width="1"
            style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=895236265319240&ev=PageView&noscript=1"
          />
        </noscript>
        <InterFontFace />
      </NextHead>
      <DefaultSeo {...SEO} />

      <Provider value={rootStore}>
        <ChakraProvider resetCSS theme={theme}>
          <NextNProgress
            options={{
              showSpinner: false,
              trickleSpeed: 500,
              easing: 'linear',
              speed: 600,
            }}
            color={theme.colors.red.primary}
            height={1.5}
          />
          <Component key={router.route} {...pageProps} />
        </ChakraProvider>
      </Provider>
    </>
  )
}

// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
SSMApp.getInitialProps = async (appContext: any) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)
  const navigation = await NavigationService.getNavItems(
    appContext.router.locale
  )

  return {
    ...appProps,
    pageProps: {
      global: { navigation },
    },
  }
}

const __Page_Next_Translate__ = SSMApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  