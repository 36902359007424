import { Box, BoxProps, Container, Flex, Text } from '@chakra-ui/react'
import { useStore } from '@store'
import { Link } from '@ui'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import cn from 'classnames'
import { IStickyNavItem } from '@interfaces'
import { useRouter } from 'next/router'

interface IArtCollectionNav {
  activePath: string
  navItems: IStickyNavItem[]
  containerProps?: BoxProps
}

const ArtCollectionNav = observer(
  ({ activePath, navItems, containerProps }: IArtCollectionNav) => {
    const { locale } = useRouter()
    const { t } = useTranslation()
    const { ui } = useStore()

    return (
      <Box
        as="nav"
        position="sticky"
        top={ui.scrollDir === 'down' ? '0px' : '-100%'}
        bgColor="white.primary"
        zIndex={{ base: 4, lg: 2 }}
        {...containerProps}
      >
        <Container>
          <Flex
            height={{ base: '50px', lg: '65px' }}
            flexDirection="row"
            alignItems="center"
            sx={{
              '&>*+*': {
                ml: 'padding.primary',
              },
            }}
          >
            <Text
              as="span"
              fontWeight="medium"
              color="red.primary"
              fontSize="sm"
            >
              {t('common:choose')}
            </Text>
            {navItems.map((nav) => (
              <Link
                key={nav.id}
                href={nav.href[locale]}
                target={nav.isExternal ? '_blank' : null}
                color="black.primary3"
                _hover={{ color: 'red.primary3' }}
                fontWeight={activePath === nav.href ? 'medium' : 'light'}
                position="relative"
                whiteSpace="pre"
                className={cn({
                  'navLink--active': activePath === nav.href,
                })}
                sx={{
                  '&.navLink--active::before': {
                    content: `""`,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: '-4px',
                    width: 'full',
                    height: '2px',
                    bgColor: 'red.primary',
                  },
                }}
              >
                {t(nav.navTitleTx)}
              </Link>
            ))}
          </Flex>
        </Container>
      </Box>
    )
  }
)

export default ArtCollectionNav
