import { Box, BoxProps, Container, Flex, Text } from '@chakra-ui/react'
import { RichText } from '@ui'
import { ISectionData, IDrupalLink } from '@interfaces'

interface IVisitingDaysData {
  htmlContent: string
  reservationLink: IDrupalLink
}

interface IVisitingDaysSection extends ISectionData {
  containerProps?: BoxProps
  activeScreen: 'visit' | 'msaSRestaurant'
  data: IVisitingDaysData
}

const VisitingDaysSection = ({
  stickyId,
  sectionTitle,
  data,
  containerProps,
}: IVisitingDaysSection) => {
  const { htmlContent } = data || {}

  return (
    <Container
      id={stickyId}
      pt={{ base: '20px', lg: 0 }}
      pb={{ base: '20px', lg: '20px' }}
      as="section"
      {...containerProps}
    >
      {sectionTitle && (
        <Text
          as="h2"
          color="red.primary"
          fontSize={{ base: '2xl', lg: '3xl' }}
          mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
        >
          {sectionTitle}
        </Text>
      )}

      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        maxW="container.secondary"
        mx="auto"
      >
        <Box flex={{ base: 'auto' }} mt={{ base: 'padding.primary', lg: '0' }}>
          {htmlContent && <RichText htmlContent={htmlContent} />}
        </Box>
      </Flex>
    </Container>
  )
}

export default VisitingDaysSection
