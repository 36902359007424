import { Box, Container, Text } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SelectionsFromCollectionCard } from '@ui'
import { IRelatedWorks } from '@interfaces'

const RelatedWorks = ({ title, collection }: IRelatedWorks) => (
  <Box
    as="section"
    sx={{
      '.RelatedWorksSwiper.swiper-container': {
        width: '100%',
        height: '100%',
        px: { base: 'padding.primary', lg: 'padding.primary3' },
      },
      '.RelatedWorksSwiper .swiper-slide': {
        width: 'auto',
      },
    }}
    mb={{ base: 'padding.primary2', lg: 'padding.primary4' }}
  >
    <Container>
      <Text
        color="red.primary"
        fontSize={{ base: '2xl', lg: '3xl' }}
        mb={{ base: 'padding.primary', lg: '45px' }}
      >
        {title}
      </Text>
    </Container>
    <Swiper
      spaceBetween={20}
      slidesPerView="auto"
      grabCursor={true}
      className="RelatedWorksSwiper"
      breakpoints={{
        1170: {
          initialSlide: 1,
        },
      }}
    >
      {collection.map((card) => (
        <SwiperSlide key={card.id}>
          <SelectionsFromCollectionCard
            isDarkSection={false}
            {...card}
            containerProps={{
              width: { base: '315px', lg: '300px' },
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  </Box>
)

export default RelatedWorks
