export const Link = {
  baseStyle: {
    display: 'inline-flex',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    textDecoration: 'none',
    outline: 'none',
    color: 'inherit',
    _hover: {
      textDecoration: 'none',
    },
    _focus: {
      boxShadow: 'none',
    },
  },

  variants: {
    primary: {
      background: 'red.primary2',
      color: 'white.primary',
      _hover: {
        backgroundColor: 'red.primary',
      },
    },
    secondary: {
      background: 'red.primary',
      color: 'white.primary',
      _hover: {
        backgroundColor: 'red.primary2',
      },
    },
    blue: {
      background: 'blue.primary',
      color: 'white.primary',
      _hover: {
        backgroundColor: 'blue.primary2',
      },
    },
    white: {
      background: 'white.primary3',
      color: 'grey.primary2',
      _hover: {
        backgroundColor: 'red.primary',
        color: 'white.primary',
      },
    },
    inlineLink: {
      textDecoration: 'underline',
      _hover: {
        color: 'red.primary',
      },
    },
  },
}
