import { ITwoColumnBlock, ILeftColTitleRow } from '@interfaces'

export const prepareTwoColBlock = (
  leftColTitleRow: ILeftColTitleRow[],
  leftColButtonLabel: ITwoColumnBlock['leftColButtonLabel'],
  leftColButtonUrl: ITwoColumnBlock['leftColButtonUrl'],
  leftColisExternelUrl: ITwoColumnBlock['leftColisExternelUrl'],
  rightColHtmlContent: ITwoColumnBlock['rightColHtmlContent'],
  isAlignItemsCenter: ITwoColumnBlock['isAlignItemsCenter']
): ITwoColumnBlock => ({
  leftColTitleRow,
  leftColButtonLabel,
  leftColButtonUrl,
  leftColisExternelUrl,
  rightColHtmlContent,
  isAlignItemsCenter,
})
