/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Container, Image, Text, Link } from '@chakra-ui/react'
import { IPhotoAndVideoSliderSection } from '@interfaces'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { HeroAspectRatio } from '@ui'
import LightGallery from 'lightgallery/react'
import lgZoom from 'lightgallery/plugins/zoom'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

/**
 * Important note: If it is going to be a youtube video, the url shape should be as follows.
 * Example youtube url: https://www.youtube.com/embed/f0zQ_akQC4A?enablejsapi=1&rel=0
 */
const PhotoAndVideoSliderSection = ({
  data,
  stickyId = 'PhotoAndVideoSliderSection',
  sectionTitle,
  activeScreen,
  activeFontFamily = 'body',
  ...containerProps
}: IPhotoAndVideoSliderSection) => {
  const photos = data.filter((item) => !item.isVideo)
  const videos = data.filter((item) => item.isVideo)
  return (
    <Box
      id={stickyId}
      as="section"
      my={{ base: 'padding.primary', lg: 'padding.primary2' }}
      pt={{ base: 'padding.primary2', lg: 'padding.primary4' }}
      pb={{ base: '240px', lg: '340px' }}
      bgColor="black.primary3"
      {...containerProps}
    >
      <Container>
        <Text
          fontSize={{ base: '2xl', lg: '3xl' }}
          fontFamily={activeFontFamily}
          color="red.primary"
        >
          {sectionTitle}
        </Text>
      </Container>
      <Box
        bgColor="black.primary4"
        mt="padding.primary2"
        sx={{
          '.photo-and-video-slider.swiper-container': {
            height: 'full',
            position: 'relative',
            pb: '70px',
          },
          '.photo-and-video-slider .swiper-slide': {
            width: 'auto',
            height: 'auto',
            position: 'relative',
          },
          '.photo-and-video-slider .swiper-slide > iframe': {
            width: 'full',
            height: 'full',
          },
        }}
      >
        <Container>
          <LightGallery
            plugins={[lgZoom]}
            mode="lg-fade"
            speed={500}
            elementClassNames="custom-garden-class"
          >
            <style jsx global>{`
              .custom-garden-class {
                display: grid;
                gap: 20px;

                @media screen and (max-width: 767px) {
                  grid-template-columns: repeat(2, 1fr); /* Mobil görünüm */
                }

                @media screen and (min-width: 768px) {
                  grid-template-columns: repeat(3, 1fr); /* Masaüstü görünüm */
                }
              }
            `}</style>

            {photos.map((photo) => (
              <Link
                key={photo.id}
                className="gallery-item"
                href={photo.url}
                isExternal
              >
                <Box>
                  <Image
                    src={photo.url}
                    fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
                    alt={photo.alt}
                    w={[300, 400, 500]}
                    h={[120, 200, 240]}
                    objectFit="cover"
                  />
                </Box>
              </Link>
            ))}
          </LightGallery>
          <Box mt="padding.primary2">
            {videos.map((video) => (
              <HeroAspectRatio key={video.id} as="div" minHeight="auto">
                <Box width="full" height="full">
                  {video.isVideo ? (
                    <Box
                      pb="4"
                      as="iframe"
                      src={video.url}
                      frameBorder="0"
                      allowFullScreen
                      width="full"
                      height="full"
                      data-video-provider={video.videoProvider}
                    />
                  ) : (
                    <Image
                      src={video.url}
                      fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
                      alt={video.alt}
                      width="full"
                      height="full"
                      objectFit={{
                        base: 'cover',
                      }}
                    />
                  )}
                </Box>
              </HeroAspectRatio>
            ))}
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default PhotoAndVideoSliderSection
