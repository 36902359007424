import { ArtistHttpService } from '@httpServices'
import { IArtistCard, ICollection } from '@interfaces'
import {
  createNavLink,
  getCurrentLocalePropFromAPI,
  parseArtistDisplayName,
  shuffle,
  splitReferenceAndAdditionalReadings,
  getCurrentLocaleByObjectTitle,
} from '@utils'
import COMMON_EN from '@/locales/en/common.json'
import COMMON_TR from '@/locales/tr/common.json'
import { v4 as uuidv4 } from 'uuid'

export const ArtistService = {
  /**
   * Get artists slugs eMuseumHttp service
   */
  getArtists: async () => {
    const artists = await ArtistHttpService.getArtists()

    return artists.people.filter((article) => article.personType === 'Artist')
  },
  /**
   * Get people slugs eMuseumHttp service
   */
  getPeople: async () => {
    const artists = await ArtistHttpService.getArtists()

    return artists.people
  },
  /**
   * Get artist by letter eMuseumHttp service
   */
  getArtistByLetter: async (locale, letter): Promise<IArtistCard[]> => {
    const artists = await ArtistHttpService.getArtistByLetter(letter)
    const preparedArtists = await ArtistService.prepareArtists(locale, artists)

    return preparedArtists
  },
  /**
   * Get artist service
   */
  getArtistPageData: async (locale: string, id: string) => {
    const pageData = {
      data: {
        data: null,
        artist: {
          fullName: null,
          lifeRange: null,
          biography: null,
          imgUrl: null,
          caption: null,
          school: null,
          nationality: null,
          personType: null,
          reference: null,
          additionalReadings: null,
        },
        relatedObjects: null,
        relatedArtists: null,
      },
      error: null,
    }

    try {
      const artistDetail = await ArtistHttpService.getArtist(id)

      if (
        artistDetail &&
        artistDetail.person &&
        artistDetail.person.length > 0
      ) {
        pageData.data.data = artistDetail

        const artist = artistDetail.person[0]

        if (artist) {
          pageData.data.artist.fullName =
            (artist?.displayName &&
              artist?.displayName?.value &&
              parseArtistDisplayName(artist?.displayName?.value)?.artistName) ||
            ''
          pageData.data.artist.lifeRange =
            (artist?.displayName &&
              artist?.displayName?.value &&
              parseArtistDisplayName(artist?.displayName?.value)?.lifeRange) ||
            null
          pageData.data.artist.biography =
            (artist.biography &&
              getCurrentLocalePropFromAPI(artist?.biography?.value, locale)) ||
            ''
          pageData.data.artist.imgUrl =
            artist?.primaryMedia?.value ||
            'img/explore-ssm/board-members/placeholder.png'

          pageData.data.artist.caption =
            (artist?.caption &&
              getCurrentLocalePropFromAPI(artist?.caption?.value, locale)) ||
            null
          pageData.data.artist.school =
            (artist?.school &&
              getCurrentLocalePropFromAPI(artist?.school?.value, locale)) ||
            null
          pageData.data.artist.nationality =
            (artist?.nationality &&
              getCurrentLocalePropFromAPI(
                artist?.nationality?.value,
                locale
              )) ||
            null
          pageData.data.artist.personType = artist?.personType?.value || null
          pageData.data.artist.reference =
            (artist.remarks &&
              splitReferenceAndAdditionalReadings(artist?.remarks?.value)
                .reference) ||
            null
          pageData.data.artist.additionalReadings =
            (artist.remarks &&
              splitReferenceAndAdditionalReadings(artist?.remarks?.value)
                .additionalReadings) ||
            null
        }
      }

      // prepare objects of artist
      const relatedObjects = await ArtistService.getRelatedObjects(locale, id)

      if (relatedObjects) {
        pageData.data.relatedObjects = relatedObjects
      }

      // related artist
      const relatedArtists = await ArtistService.getRelatedArtists(locale)

      if (relatedArtists) {
        pageData.data.relatedArtists = relatedArtists.filter(
          (artist) => artist.sourceId != id
        )
      }
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  prepareArtists: async (locale, data): Promise<IArtistCard[]> => {
    return data?.people
      .filter((artist) => artist.personType.value === 'Artist')
      .map((artist) => ({
        imgUrl: artist?.primaryMedia?.value || '',
        imgAlt: artist?.displayName?.value,
        fullName:
          (artist?.displayName &&
            artist?.displayName?.value &&
            parseArtistDisplayName(artist?.displayName?.value)?.artistName) ||
          '',
        lifeRange:
          (artist?.displayName &&
            artist?.displayName?.value &&
            parseArtistDisplayName(artist?.displayName?.value)?.lifeRange) ||
          '',
        url: `${createNavLink('artist', locale)}/${artist?.sourceId?.value}`,
        sourceId: artist?.sourceId?.value,
      }))
  },
  getRelatedObjects: async (locale, artistId) => {
    const relatedObjects = await ArtistHttpService.getRelatedObjects(artistId)

    return (
      (relatedObjects &&
        relatedObjects.objects.map((object) => ({
          id: object?.sourceId?.value,
          imgUrl: object?.primaryMedia?.value || '',
          title: getCurrentLocaleByObjectTitle(object, locale) || '',
          name:
            locale === 'tr'
              ? COMMON_TR.detailedReviewButtonLabel
              : COMMON_EN.detailedReviewButtonLabel,
          url: `${createNavLink('artist', locale)}/${artistId}/${
            object?.sourceId?.value
          }`,
        }))) ||
      null
    )
  },
  getRelatedArtists: async (locale): Promise<ICollection[]> => {
    const artists = await ArtistHttpService.getArtists()

    const preparedArtists = await ArtistService.prepareArtists(locale, artists)

    return shuffle(preparedArtists)
      .slice(0, 10)
      .map((artist) => ({
        id: uuidv4(),
        imgUrl:
          artist?.imgUrl || '/img/placeholders/no-image-available-icon.jpeg',
        title: artist?.fullName || '',
        name: artist?.lifeRange || '',
        url: artist?.url || '',
        sourceId: artist?.sourceId || '',
      }))
  },
  getAllArtistsSlugs: async () => {
    const artists = await ArtistHttpService.getArtists()

    return artists.people.map((artist) => artist.sourceId.value)
  },
}
