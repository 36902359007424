import { Box, BoxProps, Container } from '@chakra-ui/react'
import DiscoverSsmSlider from './DiscoverSsmSlider'
import { MotionBox, MotionText } from '@ui'
import { fadeIn } from '@lib/motion'
import { useInViewMotion } from '@lib/hooks'
import { IDiscoverSsmCard } from '@interfaces'

interface IDiscoverSsm {
  stickyId?: string
  containerProps?: BoxProps
  sliderCollection: IDiscoverSsmCard[]
  subTitle: string
  title: string
  description: string
}

const DiscoverSsm = ({
  stickyId = 'discover-ssm',
  containerProps,
  title,
  description,
}: IDiscoverSsm) => {
  const { controls, ref } = useInViewMotion(true)

  return (
    <Box
      id={stickyId}
      as="section"
      pt={{ base: 'padding.primary1', lg: '3' }}
      pb={{ base: '0', lg: 'padding.primary2' }}
      {...containerProps}
    >
      <MotionBox
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={fadeIn.animationContainer}
      >
        <Container>
          <Box>
            <MotionText
              variants={fadeIn.up}
              fontSize={{ base: 'lg', lg: '3xl' }}
              color="red.primary"
              fontWeight="200"
              fontStyle="normal"
              lineHeight="normal"
              as="h2"
              mb={{ base: 1, lg: '6px' }}
            >
              {title}
            </MotionText>
            <MotionText
              variants={fadeIn.up}
              fontSize={{ base: 'sm', lg: 'md' }}
              fontWeight="light"
              color="black.primary"
              my="2"
            >
              {description}
            </MotionText>
          </Box>
          <DiscoverSsmSlider />
        </Container>
      </MotionBox>
    </Box>
  )
}

export default DiscoverSsm
