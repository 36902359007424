import { Flex, FlexProps } from '@chakra-ui/react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import tr from 'date-fns/locale/tr'
registerLocale('tr', tr)

interface IDateRangePicker {
  locale?: 'en' | 'tr' | string
  startDate: Date
  onChange: (dates: [Date, Date]) => void
  endDate: Date
  containerProps?: FlexProps
}

const DateRangePicker = ({
  locale = 'en',
  startDate,
  onChange,
  endDate,
  containerProps,
}: IDateRangePicker) => {
  return (
    <Flex
      justifyContent="center"
      sx={{
        '.ssm-datepicker': {
          fontFamily: 'body',
          fontSize: 'sm',
          bgColor: 'white.primary',
          color: 'black.primary3',
          border: 'none',
          borderRadius: 'none',
        },
        '.ssm-datepicker .react-datepicker__header': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '.ssm-datepicker .react-datepicker__current-month': {
          color: 'black.primary3',
          fontSize: 'lg',
          fontWeight: 'normal',
        },
        '.ssm-datepicker .react-datepicker__navigation--previous': {
          background: 'url(/img/icons/leftArrow.png) no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          width: '15px',
          height: '15px',
          border: 'none',
          top: '17px',
          left: '13px',
        },
        '.ssm-datepicker .react-datepicker__navigation--next': {
          background: 'url(/img/icons/rightArrow.png) no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          width: '15px',
          height: '15px',
          border: 'none',
          top: '17px',
          right: '13px',
        },
        '.ssm-datepicker .react-datepicker__day-names': {
          mt: 'padding.primary',
        },
        '.ssm-datepicker .react-datepicker__day-name': {
          fontWeight: 'medium',
          lineHeight: '6',
          color: 'red.primary',
          margin: '2',
        },
        '.ssm-datepicker .react-datepicker__day': {
          fontWeight: 'light',
          lineHeight: '6',
          color: 'black.primary3',
          margin: '2',
          position: 'relative',
        },
        '.ssm-datepicker .react-datepicker__day--selected': {
          color: 'red.primary',
          fontWeight: 'medium',
          backgroundColor: 'transparent',
          borderRadius: 'none',
          _before: {
            content: `""`,
            position: 'absolute',
            bottom: '0',
            width: '70%',
            height: '2px',
            backgroundColor: 'red.primary',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        },
        '.ssm-datepicker .react-datepicker__day--in-selecting-range': {
          color: 'red.primary',
          backgroundColor: 'transparent',
          borderRadius: 'none',
        },
        '.ssm-datepicker .react-datepicker__day--in-selecting-range:hover': {
          color: 'red.primary3',
          backgroundColor: 'transparent',
          borderRadius: 'none',
        },
        '.ssm-datepicker .react-datepicker__day--in-range': {
          backgroundColor: 'transparent',
          color: 'red.primary',
          borderRadius: 'none',
          fontWeight: 'medium',
          _before: {
            content: `""`,
            position: 'absolute',
            bottom: '0',
            width: '70%',
            height: '2px',
            backgroundColor: 'red.primary',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        },
        '.ssm-datepicker .react-datepicker__day--keyboard-selected': {
          backgroundColor: 'transparent',
          color: 'red.primary',
          borderRadius: 'none',
        },
        '.ssm-datepicker .react-datepicker__day--outside-month': {
          color: 'grey.primary',
        },
      }}
      {...containerProps}
    >
      <DatePicker
        locale={locale}
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        calendarClassName="ssm-datepicker"
      />
    </Flex>
  )
}

export default DateRangePicker
