import { Box, BoxProps, Container } from '@chakra-ui/react'
import DiscoverArtSlider from './DiscoverArtSlider'
import { MotionBox, MotionText } from '@ui'
import { fadeIn } from '@lib/motion'
import { useInViewMotion } from '@lib/hooks'
import { IDiscoverSsmCard } from '@interfaces'

interface IDiscoverArt {
  stickyId?: string
  containerProps?: BoxProps
  sliderCollection: IDiscoverSsmCard[]
  subTitle: string
  title: string
  description: string
}

const DiscoverArt = ({
  stickyId = 'discover-ssm',
  containerProps,
  subTitle,
  title,
  description,
}: IDiscoverArt) => {
  const { controls, ref } = useInViewMotion(true)

  return (
    <Box
      id={stickyId}
      as="section"
      pt={{ base: '10', lg: 'padding.primary2' }}
      pb={{ base: '0', lg: '10' }}
      {...containerProps}
    >
      <MotionBox
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={fadeIn.animationContainer}
      >
        <Container>
          <Box maxW="610px">
            <MotionText
              display="none"
              variants={fadeIn.up}
              fontSize={{ base: '2xl', lg: '3xl' }}
              color="red.primary"
              as="h3"
              fontWeight="600"
              mb={{ base: 1, lg: '0' }}
            >
              {subTitle}
            </MotionText>
            <MotionText
              display="none"
              variants={fadeIn.up}
              fontSize={{ base: '4xl', lg: '5xl' }}
              color="greyAlpha.primary"
              fontWeight="200"
              fontStyle="italic"
              lineHeight="normal"
              as="h2"
              mb={{ base: 1, lg: '6px' }}
            >
              {title}
            </MotionText>
            <MotionText
              display="none"
              variants={fadeIn.up}
              fontSize="md"
              fontWeight="light"
              color="initial"
            >
              {description}
            </MotionText>
          </Box>
        </Container>
        <DiscoverArtSlider />
      </MotionBox>
    </Box>
  )
}

export default DiscoverArt
