import eMuseumHttp from '../eMuseumHttpService'
import { OBJECT_DETAIL } from '@lib/const/api-endpoints.const'

/**
 * Object Http Service
 */
export const ObjectHttpService = {
  /**
   * Get object detail eMuseumHttp service
   */
  getObject: async (id) => await eMuseumHttp.get(OBJECT_DETAIL(id)),
}
