import eMuseumHttp from '../eMuseumHttpService'
import {
  ARTIST,
  ARTIST_DETAIL,
  RELATED_OBJECTS_OF_ARTIST,
} from '@lib/const/api-endpoints.const'

/**
 * Artist Http Service
 */
export const ArtistHttpService = {
  /**
   * Get artists eMuseumHttp service
   */
  getArtists: async () => await eMuseumHttp.get(ARTIST),
  /**
   * Get artist by letter eMuseumHttp service
   */
  getArtistByLetter: async (letter) =>
    await eMuseumHttp.get(`${ARTIST}?filter=letter:${letter}`),
  /**
   * Get artist eMuseumHttp service
   */
  getArtist: async (id) => await eMuseumHttp.get(ARTIST_DETAIL(id)),
  /**
   * Get related objects of artist eMuseumHttp service
   */
  getRelatedObjects: async (id) =>
    await eMuseumHttp.get(RELATED_OBJECTS_OF_ARTIST(id)),
}
