export const getActiveColorForKidsPage = (index) => {
  const colors = [
    'yellow.primary2',
    'blue.primary2',
    'red.primary4',
    'blue.primary4',
    'pink.primary2',
  ]

  return colors[index % 5]
}
