import googleMapHttp from '../googleMapHttpService'
import { IGoogleMapPlaceType } from '@interfaces'
import { GOOGLE_MAP_PLACE } from '@lib/const/api-endpoints.const'

/**
 * Google Place Http Service
 */
export const GoogleMapHttpService = {
  /**
   * Get place detail on google map api
   */
  getPlaceDetail: async (placeType: IGoogleMapPlaceType) => {
    let placeId

    switch (placeType) {
      /**
       * ssm
       */
      case 'visit':
        placeId = process.env.NEXT_PUBLIC_GOOGLE_MAP_PLACE_SSM_PLACE_ID
        break

      /**
       * msa
       */
      case 'msaSRestaurant':
        placeId = process.env.NEXT_PUBLIC_GOOGLE_MAP_PLACE_MSA_PLACE_ID
        break

      default:
        break
    }

    if (!placeId) {
      return
    }

    const url = `${GOOGLE_MAP_PLACE}?place_id=${placeId}&fields=business_status,opening_hours,website&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_PLACE_API_KEY}`
    return await googleMapHttp.get(url)
  },
}
