import { createIcon } from '@chakra-ui/icons'

const MusicForKidsv2 = createIcon({
  displayName: 'MusicForKidsv2',
  viewBox: '0 0 32 25',
  defaultProps: {
    width: '32px',
    height: '25px',
    color: 'white.primary',
  },
  path: (
    <>
      <path
        d="M27.4286 11.4774V10.2263C27.4286 4.58728 22.8149 -0.000976562 17.1429 -0.000976562H14.8571C9.18514 -0.000976562 4.57143 4.58728 4.57143 10.2263V11.4774C1.96648 12.0055 0 14.3009 0 17.0445V19.3172C0 22.4502 2.56343 24.999 5.71429 24.999H8C8.63162 24.999 9.14286 24.4903 9.14286 23.8627V12.499C9.14286 11.8714 8.63162 11.3627 8 11.3627H6.85714V10.2263C6.85714 5.83993 10.4465 2.27175 14.8571 2.27175H17.1429C21.5535 2.27175 25.1429 5.83993 25.1429 10.2263V11.3627H24C23.3684 11.3627 22.8571 11.8714 22.8571 12.499V23.8627C22.8571 24.4903 23.3684 24.999 24 24.999H26.2857C29.4366 24.999 32 22.4502 32 19.3172V17.0445C32 14.3009 30.0335 12.0055 27.4286 11.4774ZM6.85714 22.7263H5.71429C3.82362 22.7263 2.28571 21.1971 2.28571 19.3172V17.0445C2.28571 15.1646 3.82362 13.6354 5.71429 13.6354H6.85714V22.7263ZM29.7143 19.3172C29.7143 21.1971 28.1764 22.7263 26.2857 22.7263H25.1429V13.6354H26.2857C28.1764 13.6354 29.7143 15.1646 29.7143 17.0445V19.3172Z"
        fill="currentColor"
      />
      <path
        d="M18.7981 13.7548L16.5124 12.6184C16.1573 12.4438 15.7367 12.4609 15.4007 12.6684C15.0636 12.8756 14.8583 13.2412 14.8583 13.6351V18.2381C13.0777 17.8772 11.4297 19.2381 11.4297 21.0215C11.4297 22.5878 12.7108 23.8624 14.2868 23.8624C15.8628 23.8624 17.144 22.5878 17.144 21.0215V15.4738L17.7756 15.7878C18.3413 16.0684 19.0255 15.8412 19.3089 15.2794C19.5916 14.7181 19.3626 14.0359 18.7981 13.7548Z"
        fill="currentColor"
      />
    </>
  ),
})

export default MusicForKidsv2
