import { ContentNotFound, Layout } from '@common'
import { IDynamicPageTemplate } from '@interfaces'
import PAGE_TYPES, {
  PAGE_TYPES_KEYS,
} from '@lib/const/dynamic-layout-components'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

export const DynamicPageTemplate = ({
  pageType,
  pageData,
  global,
}: IDynamicPageTemplate) => {
  const { t } = useTranslation()
  const router = useRouter()

  if (pageData?.error || !pageType || !PAGE_TYPES_KEYS.includes(pageType)) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={global?.navigation}
        path={router.asPath}
      >
        <ContentNotFound infoType="serverSideError" />
      </Layout>
    )
  }

  const LAYOUT = PAGE_TYPES[pageType]

  return <LAYOUT pageData={pageData} global={global} />
}
