import { Box, BoxProps, Container, Text } from '@chakra-ui/react'
import { IScientificPublicationCard } from '@interfaces'
import { ScientificPublicationCard } from '@ui'

interface IScientificPublicationsollectionLinst {
  sectionTitle: string
  stickyId?: string
  collection: IScientificPublicationCard[]
  containerProps?: BoxProps
}

const ScientificPublicationsollectionLinst = ({
  sectionTitle,
  stickyId,
  collection,
  containerProps,
}: IScientificPublicationsollectionLinst) => {
  return (
    <>
      {collection.length > 0 && (
        <Container
          as="section"
          py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
          {...(stickyId && { id: stickyId })}
          {...containerProps}
        >
          <Text
            as="h2"
            color="red.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
            mb={{ base: 'padding.primary', lg: 'padding.primary2' }}
          >
            {sectionTitle}
          </Text>

          <Box maxWidth="container.secondary" mx="auto">
            {collection.map((col, i) => (
              <ScientificPublicationCard
                key={col.id}
                isLastItem={collection.length === i + 1}
                {...col}
              />
            ))}
          </Box>
        </Container>
      )}
    </>
  )
}

export default ScientificPublicationsollectionLinst
