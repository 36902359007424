import { EasingFunction } from 'framer-motion/types/types'
import React, { useContext, useMemo } from 'react'
import { Box, IntersectionContext } from '../'

type IStaggerWrap = {
  children?: JSX.Element | JSX.Element[]
  staggerChildren?: number
  ease?:
    | [number, number, number, number]
    | 'linear'
    | 'easeIn'
    | 'easeOut'
    | 'easeInOut'
    | 'circIn'
    | 'circOut'
    | 'circInOut'
    | 'backIn'
    | 'backOut'
    | 'backInOut'
    | 'anticipate'
    | EasingFunction
  stagger?: boolean
  duration?: number
  delay?: number
}

export const StaggerContext = React.createContext<Partial<IStaggerWrap>>({
  stagger: false,
})

export const StaggerWrap = ({
  children,
  staggerChildren = 0.5,
  ease = 'easeInOut',
  duration = 0,
  delay = 0,
  ...rest
}: IStaggerWrap) => {
  const { inView } = useContext(IntersectionContext)

  const transition = useMemo(
    () => ({
      duration,
      delay,
      ease,
      staggerChildren,
    }),
    [duration, delay, ease, staggerChildren]
  )

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        ...transition,
      },
    },
  }

  return (
    <StaggerContext.Provider value={{ stagger: true }}>
      <Box
        initial="hidden"
        animate={inView ? 'show' : 'hidden'}
        exit="hidden"
        variants={variants}
        {...rest}
      >
        {children}
      </Box>
    </StaggerContext.Provider>
  )
}
