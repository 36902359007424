import { createIcon } from '@chakra-ui/icons'

const PlayForKidsv2 = createIcon({
  displayName: 'PlayForKidsv2',
  viewBox: '0 0 24 27',
  defaultProps: {
    width: '24px',
    height: '27px',
    color: 'white.primary',
    fill: 'none',
  },
  path: (
    <path
      d="M15.37 19.0589L11.38 21.3625C8.72642 22.8946 6.88073 23.9557 5.45439 24.5235C4.03872 25.0871 3.43675 24.9981 3.04561 24.7831C3.01502 24.7662 2.98479 24.7488 2.95493 24.7307C2.57314 24.4995 2.19509 24.0227 1.9753 22.5149C1.75385 20.9957 1.75 18.8667 1.75 15.8026L1.75 11.1954C1.75 8.13131 1.75385 6.00231 1.9753 4.48317C2.19509 2.97539 2.57314 2.49856 2.95493 2.26735C2.98479 2.24926 3.01503 2.23181 3.04562 2.21499C3.43675 1.99996 4.03872 1.91097 5.45439 2.47452C6.88074 3.04231 8.72643 4.10347 11.38 5.63551L15.37 7.93915C18.0236 9.47118 19.8654 10.539 21.0703 11.4904C22.2662 12.4346 22.4901 13.0004 22.4995 13.4467C22.5002 13.4816 22.5002 13.5165 22.4995 13.5514C22.4901 13.9976 22.2662 14.5634 21.0703 15.5077C19.8654 16.459 18.0236 17.5269 15.37 19.0589Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  ),
})

export default PlayForKidsv2
