import { FooterNav } from '@common'
import { Grid, Box, Text } from '@chakra-ui/react'
import { IFonts } from '@interfaces'

interface IFooter {
  activeFontFamily?: IFonts
}

const Footer = ({ activeFontFamily }: IFooter) => {

  return (
    <Grid
      templateColumns={{ base: '1fr', lg: '1fr', xl: '1fr' }}
      overflow="hidden"
      gap={0}
      position="relative"
      as="footer"
      backgroundColor="white.primary2"
    >
      <Box display="grid" gridTemplateRows="auto 1fr">
        <FooterNav activeFontFamily={activeFontFamily} />
      </Box>
      <Text
        position="absolute"
        bottom="5px"
        right="5px"
        color="grey.primary4"
        fontSize="sm"
        fontWeight="light"
        fontFamily={activeFontFamily}
      >
      </Text>
    </Grid>
  )
}

export default Footer
