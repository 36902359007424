import {
  Box,
  BoxProps,
  Container,
  Grid,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { CollectionSearchPagination, PublicationDetailModal } from '@common'
import { IFonts, IPublicationCard } from '@interfaces'
import { usePagination } from '@lib/hooks'
import { CollectionPaginationText, PublicationCard, Skeletons } from '@ui'
import { useState } from 'react'

interface IPublicationCollectionGrid {
  collection: IPublicationCard[]
  sectionTitle: string
  containerProps?: BoxProps
  activeFontFamily?: IFonts
}

const PublicationCollectionGrid = ({
  collection,
  sectionTitle,
  containerProps,
  activeFontFamily = 'body',
}: IPublicationCollectionGrid) => {
  const [activePopUpId, setActivePopUpId] = useState<null | string>(null)
  const { isOpen, onToggle, onClose } = useDisclosure()
  const {
    isLoading,
    handlePagination,
    containerRef,
    currentPage,
    totalPage,
    paginatedData,
  } = usePagination(collection, 16)

  const handleOnClickForPopUp = (activeId: string) => {
    setActivePopUpId(activeId)
    onToggle()
  }

  const getActiveCollection = () =>
    collection.find((collection) => collection.id === activePopUpId)

  return (
    <>
      {paginatedData.length > 0 && (
        <Box
          as="section"
          py={{ base: 'padding.primary2', lg: 'padding.primary4' }}
          {...containerProps}
          ref={containerRef}
        >
          <Container mb={{ base: '16', lg: '24' }}>
            <Text
              color="red.primary"
              fontFamily={activeFontFamily}
              fontSize={{ base: '2xl', lg: '3xl' }}
            >
              {sectionTitle}
            </Text>
          </Container>
          <Box px="0" maxWidth="container.secondary" mx="auto">
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(4, 1fr)',
              }}
              gridRowGap={{ base: 'padding.primary', lg: '24' }}
              gridColumnGap={{ base: 0, lg: 5 }}
              justifyItems="center"
            >
              {isLoading ? (
                <>
                  <Skeletons.PublicationCard />
                  <Skeletons.PublicationCard />
                  <Skeletons.PublicationCard />
                  <Skeletons.PublicationCard />
                  <Skeletons.PublicationCard />
                  <Skeletons.PublicationCard />
                </>
              ) : (
                <>
                  {paginatedData.map((col) => (
                    <PublicationCard
                      key={col.id}
                      {...col}
                      onClick={() => handleOnClickForPopUp(col.id)}
                      activeFontFamily={activeFontFamily}
                    />
                  ))}
                </>
              )}
            </Grid>
          </Box>

          <CollectionSearchPagination
            currentPage={currentPage}
            totalPage={totalPage}
            handlePagination={handlePagination}
            activeFontFamily={activeFontFamily}
          />
          <CollectionPaginationText
            activeLengthOnPage={paginatedData.length}
            totalLength={collection.length}
            activeFontFamily={activeFontFamily}
          />
          {activePopUpId && (
            <PublicationDetailModal
              isOpen={isOpen}
              onClose={onClose}
              pdfLink={getActiveCollection()?.pdfLink}
              epubLink={getActiveCollection()?.epubLink}
              videoUrl={getActiveCollection()?.videoUrl}
              listeningUrl={getActiveCollection()?.listeningUrl}
              pageCount={getActiveCollection()?.pageCount}
              isbn={getActiveCollection()?.isbn}
              place={getActiveCollection()?.place}
              author={getActiveCollection()?.author}
              description={getActiveCollection()?.description}
              title={getActiveCollection()?.title}
              imgUrl={getActiveCollection()?.imgUrl}
              type={getActiveCollection()?.type}
              activeFontFamily={activeFontFamily}
            />
          )}
        </Box>
      )}
    </>
  )
}

export default PublicationCollectionGrid
