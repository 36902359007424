interface ICOLLECTIONS_API {
  sourceId: number
  locale: 'tr' | 'en'
  type: 'archive' | 'collection'
  rank: number
}

const COLLECTIONS_API: ICOLLECTIONS_API[] = [
  {
    sourceId: 344,
    locale: 'en',
    type: 'archive',
    rank: 1,
  },
  {
    sourceId: 345,
    locale: 'en',
    type: 'archive',
    rank: 1,
  },
  {
    sourceId: 337,
    locale: 'tr',
    type: 'archive',
    rank: 1,
  },
  {
    sourceId: 338,
    locale: 'tr',
    type: 'archive',
    rank: 1,
  },
  {
    sourceId: 340,
    locale: 'en',
    type: 'collection',
    rank: 1,
  },
  {
    sourceId: 341,
    locale: 'en',
    type: 'collection',
    rank: 2,
  },
  {
    sourceId: 342,
    locale: 'en',
    type: 'collection',
    rank: 3,
  },
  {
    sourceId: 343,
    locale: 'en',
    type: 'collection',
    rank: 4,
  },
  {
    sourceId: 329,
    locale: 'tr',
    type: 'collection',
    rank: 1,
  },
  {
    sourceId: 330,
    locale: 'tr',
    type: 'collection',
    rank: 2,
  },
  {
    sourceId: 332,
    locale: 'tr',
    type: 'collection',
    rank: 3,
  },
  {
    sourceId: 335,
    locale: 'tr',
    type: 'collection',
    rank: 4,
  },
]
export default COLLECTIONS_API
