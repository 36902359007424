import {
  Flex,
  Box,
  Container,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-scroll'
import { IStickyHeader } from '@interfaces'
import { useEffect, useState, useRef } from 'react'
import * as S from './styles'

const StickyHeader = observer(
  ({
    navs,
    children,
    containerProps,
    activeFontFamily = 'body',
  }: IStickyHeader) => {
    // const { ui } = useStore()
    const { t } = useTranslation()
    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const [isSticky, setIsSticky] = useState(false)
    const headerRef = useRef(null)

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY
        const triggerPosition = headerRef.current.offsetHeight

        if (scrollPosition > triggerPosition) {
          setIsSticky(true)
        } else {
          setIsSticky(false)
        }
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [])

    return (
      <S.StickyBox
        isSticky={isSticky}
        ref={headerRef}
        as="nav"
        bgColor="white.primary"
        zIndex="6"
        {...containerProps}
      >
        <Container>
          <Flex flexDirection="row" alignItems="center">
            <Text
              as="span"
              color="red.primary"
              fontFamily={activeFontFamily}
              fontWeight="medium"
              fontSize="sm"
              mr="padding.primary"
            >
              {t('common:choose')}
            </Text>
            <Flex
              sx={{
                scrollSnapType: 'x proximity',
                '& > * + *': {
                  ml: 'padding.primary',
                },
                '::-webkit-scrollbar': {
                  width: '2px',
                  height: '5px',
                },
                '::-webkit-scrollbar-track': {
                  background: 'greyAlpha.primary',
                },
                '::-webkit-scrollbar-thumb': {
                  background: 'red.primary',
                },
                '::-webkit-scrollbar-thumb:hover': {
                  background: 'red.primary3',
                },
                '@-moz-document url-prefix()': {
                  '&': {
                    overflowY: 'hidden',
                  },
                },
              }}
              overflowX="auto"
              display="flex"
              alignItems="center"
              height={{ base: '50px', lg: '65px' }}
            >
              {navs.map((nav, i) => (
                <Box
                  as={Link}
                  key={nav.id}
                  activeClass="navLink--active"
                  to={nav.href}
                  spy={true}
                  smooth={true}
                  offset={isDesktop && i === 0 ? -102 : isDesktop ? -62 : -54}
                  duration={200}
                  fontFamily={activeFontFamily}
                  fontSize="sm"
                  fontWeight="light"
                  color="black.primary3"
                  _hover={{ cursor: 'pointer' }}
                  position="relative"
                  whiteSpace="pre"
                  sx={{
                    '&.navLink--active': {
                      fontWeight: 'bold',
                      scrollSnapAlign: 'center',
                    },
                    '&.navLink--active::before': {
                      content: `""`,
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      bottom: '-4px',
                      width: 'full',
                      height: '2px',
                      bgColor: 'red.primary',
                    },
                  }}
                >
                  {nav.titleIsWithLocalizationOnClient === false
                    ? nav.navTitleTx
                    : t(nav.navTitleTx)}
                </Box>
              ))}
              {children}
            </Flex>
          </Flex>
        </Container>
      </S.StickyBox>
    )
  }
)

export default StickyHeader
