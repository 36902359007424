import { useStore } from '@store'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useBreakpointValue,
} from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import {
  ExhibitionAndEventFilterSortBox,
  ExhibitionAndEventTabButtonGroups,
  ExhibitionAndEventFilterBox,
} from '@common'

const ExhibitionAndEventFilterModal = observer(() => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const {
    exhibitionAndEvents: { displayFilterModal, displaySortModal, onCloseModal },
  } = useStore()

  return (
    <Modal
      isOpen={displayFilterModal || displaySortModal}
      onClose={onCloseModal}
      scrollBehavior="inside"
    >
      <ModalOverlay
        bgColor="blackAlpha.primary80"
        transition="all 0.3s ease"
        sx={{
          backdropFilter: 'blur(8px)',
        }}
      />
      <ModalContent
        width="100vw"
        maxWidth="full"
        height="auto"
        maxHeight="100vh"
        my="0"
        borderRadius="none"
        display="flex"
        flexDirection={{ base: 'column', lg: 'row' }}
        background="transparent"
        boxShadow="none"
        position="relative"
        overflowY={{ base: 'auto', lg: 'visible' }}
      >
        <ExhibitionAndEventFilterSortBox />
        {isDesktop && (
          <ExhibitionAndEventTabButtonGroups
            containerProps={{
              width: { base: 'full', lg: 'auto' },
              maxWidth: { base: 'full', lg: 'calc(100vw - 690px)' },
              pl: { base: '0', lg: '22.5%', xl: '0' },
              height: '65px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'white.primary',
              position: { base: 'static', lg: 'absolute' },
              top: { base: 'auto', lg: '0' },
              right: { base: 'auto', lg: '345px' },
              left: { base: 'auto', lg: '345px' },
              margin: '0',
            }}
            buttonProps={{
              minWidth: 'auto',
              height: '35px',
            }}
          />
        )}
        <ExhibitionAndEventFilterBox />
      </ModalContent>
    </Modal>
  )
})

export default ExhibitionAndEventFilterModal
