import { createIcon } from '@chakra-ui/icons'

const Marker = createIcon({
  displayName: 'Marker',
  defaultProps: {
    width: '18px',
    height: '18px',
  },
  viewBox: '0 0 64 64',
  path: (
    <g transform="translate(232 376)">
      <path
        fill="currentColor"
        d="M-200-320.3l-.9-1.1c-.6-.8-15.9-18.7-15.9-29.4 0-9.3 7.6-16.8 16.8-16.8s16.8 7.6 16.8 16.8c0 10.7-15.3 28.7-15.9 29.4l-.9 1.1zm0-45c-8 0-14.4 6.5-14.4 14.4 0 8.4 11.1 22.7 14.4 26.8 3.3-4.1 14.4-18.3 14.4-26.8 0-7.9-6.4-14.4-14.4-14.4z"
      />
      <path
        fill="currentColor"
        d="M-200-344.4c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.9 6.4-6.4 6.4zm0-10.4c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z"
      />
    </g>
  ),
})

export default Marker
