import {
  StickyHeader,
  ContentNotFound,
  Hero,
  Layout,
  SectionWithCustomHtml,
  RelatedEvents,
  PublicationCollectionGrid,
  NewsAndBlogCollectionGrid,
  TwoColBlockSection,
  PartnersOfResearchSection,
  SpectralFontFace,
} from '@common'
import { ISSRPageData } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Box, Container, Text } from '@chakra-ui/react'
import {
  checkStickyNavItem,
  getStickyId,
  getSectionTitle,
  getSectionData,
} from '@utils'

export const ResearchAreaDetailTemplate = ({
  pageData,
  global: { navigation },
}: ISSRPageData) => {
  const { t } = useTranslation()
  const { asPath } = useRouter()

  if (pageData.error) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound />
      </Layout>
    )
  }

  return (
    <Layout
      title={pageData.data.pageTitle}
      navigation={navigation}
      path={asPath}
      activeFontFamily="spectral"
    >
      <SpectralFontFace />
      <Hero
        subTitle={pageData.data.hero.subTitle}
        title={pageData.data.hero.title}
        description={pageData.data.hero.description}
        bgImageUrl={pageData.data.hero.bgImageUrl}
        activeFontFamily="spectral"
      />
      {pageData.data.stickyNavItems &&
        pageData.data.stickyNavItems.length > 0 && (
          <Box as="main" pt={4}>
            <StickyHeader
              navs={pageData.data.stickyNavItems}
              activeFontFamily="spectral"
            />
            {checkStickyNavItem(
              pageData.data.stickyNavItems,
              'projectDescription'
            ) && (
              <SectionWithCustomHtml
                stickyId={getStickyId(
                  pageData.data.stickyNavItems,
                  'projectDescription'
                )}
                data={getSectionData(pageData, 'projectDescription')}
                sectionTitle={getSectionTitle(
                  pageData.data.stickyNavItems,
                  'projectDescription'
                )}
                isWithShowMoreHtmlContent={false}
                activeFontFamily="spectral"
              />
            )}

            {checkStickyNavItem(pageData.data.stickyNavItems, 'rdEvents') && (
              <RelatedEvents
                stickyId={getStickyId(pageData.data.stickyNavItems, 'rdEvents')}
                title={getSectionTitle(
                  pageData.data.stickyNavItems,
                  'rdEvents'
                )}
                collection={getSectionData(pageData, 'rdEvents')}
                activeFontFamily="spectral"
                containerProps={{
                  my: { base: 'padding.primary', lg: 'padding.primary2' },
                }}
              />
            )}

            {checkStickyNavItem(
              pageData.data.stickyNavItems,
              'rdPublications'
            ) &&
              getSectionData(pageData, 'rdPublications') && (
                <Box
                  as="section"
                  id={getStickyId(
                    pageData.data.stickyNavItems,
                    'rdPublications'
                  )}
                >
                  <PublicationCollectionGrid
                    collection={getSectionData(pageData, 'rdPublications')}
                    sectionTitle={getSectionTitle(
                      pageData.data.stickyNavItems,
                      'rdPublications'
                    )}
                    activeFontFamily="spectral"
                    containerProps={{
                      py: { base: 'padding.primary', lg: 'padding.primary2' },
                    }}
                  />
                </Box>
              )}

            {checkStickyNavItem(pageData.data.stickyNavItems, 'rdCSs') &&
              getSectionData(pageData, 'rdCSs') && (
                <Box
                  as="section"
                  id={getStickyId(pageData.data.stickyNavItems, 'rdCSs')}
                  py={{ base: 'padding.primary', lg: 'padding.primary2' }}
                >
                  <Container>
                    <Text
                      color="red.primary"
                      fontSize={{ base: '2xl', lg: '3xl' }}
                      fontFamily={'spectral'}
                    >
                      {getSectionTitle(pageData.data.stickyNavItems, 'rdCSs')}
                    </Text>
                  </Container>

                  <NewsAndBlogCollectionGrid
                    collection={getSectionData(pageData, 'rdCSs')}
                    isWithFilters={false}
                    activeFontFamily={'spectral'}
                    innerContainerProps={{
                      pb: '0',
                    }}
                  />
                </Box>
              )}

            {checkStickyNavItem(
              pageData.data.stickyNavItems,
              'rdProjectTeam'
            ) &&
              getSectionData(pageData, 'rdProjectTeam') && (
                <TwoColBlockSection
                  stickyId={getStickyId(
                    pageData.data.stickyNavItems,
                    'rdProjectTeam'
                  )}
                  sectionTitle={getSectionTitle(
                    pageData.data.stickyNavItems,
                    'rdProjectTeam'
                  )}
                  data={getSectionData(pageData, 'rdProjectTeam')}
                  activeFontFamily={'spectral'}
                />
              )}

            {checkStickyNavItem(
              pageData.data.stickyNavItems,
              'rdProjectPartners'
            ) &&
              getSectionData(pageData, 'rdProjectPartners') && (
                <PartnersOfResearchSection
                  stickyId={getStickyId(
                    pageData.data.stickyNavItems,
                    'rdProjectPartners'
                  )}
                  sectionTitle={getSectionTitle(
                    pageData.data.stickyNavItems,
                    'rdProjectPartners'
                  )}
                  data={getSectionData(pageData, 'rdProjectPartners')}
                  activeFontFamily={'spectral'}
                  containerProps={{
                    pt: { base: 'padding.primary', lg: 'padding.primary2' },
                    pb: { base: 'padding.primary2', lg: 'padding.primary4' },
                  }}
                />
              )}
          </Box>
        )}
    </Layout>
  )
}
