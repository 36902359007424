import { Accordion } from './Accordion'
import { Button } from './Button'
import { Container } from './Container'
import { Drawer } from './Drawer'
import { Input } from './Input'
import { Link } from './Link'
import { Tabs } from './Tabs'
import { Select } from './Select'
import { Checkbox } from './Checkbox'

const COMPONENTS = {
  Accordion,
  Button,
  Container,
  Drawer,
  Input,
  Link,
  Tabs,
  Select,
  Checkbox,
}

export default COMPONENTS
