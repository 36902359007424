import { StickyContentSection, Layout, ContentNotFound } from '@common'
import { ISSRPageData } from '@interfaces'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export const BasicPageTemplate = ({
  pageData,
  global: { navigation },
}: ISSRPageData) => {
  const { asPath } = useRouter()
  const { t } = useTranslation()
  // console.log(asPath);
  if (pageData.error) {
    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={asPath}
      >
        <ContentNotFound infoType="serverSideError" />
      </Layout>
    )
  }

  return (
    <Layout
      title={pageData?.data?.pageTitle || t('common:ssmPageTitle')}
      navigation={navigation}
      path={asPath}
    >
      <StickyContentSection
        title={pageData?.data?.title || t('common:ssmPageTitle')}
        descriptions={pageData?.data?.htmlContent}
        path={asPath}
      />
      <style jsx global>{`
        img {
          max-width: 100%;
          display: block;
        }

        figure {
          margin: 0;
          display: grid;
          grid-template-rows: 1fr auto;
          margin-bottom: 10px;
          break-inside: avoid;
        }

        figure > img {
          grid-row: 1 / -1;
          grid-column: 1;
        }

        figure a {
          color: black;
          text-decoration: none;
        }

        figcaption {
          grid-row: 2;
          grid-column: 1;
          background-color: rgba(255, 255, 255, 0.5);
          padding: 0.2em 0.5em;
          justify-self: start;
        }

        .container {
          column-count: 1;
          column-gap: 10px;
        }

        .object-info {
          padding-left: 0em;
          padding-top: 1em;
        }

        details.accordion {
          border: solid 2px #f5f5f5;
          transition: all 0.3s ease-in-out;
        }

        details.accordion > p {
          margin: 0 20px auto;
        }

        details.accordion + details.accordion {
          margin-top: 0.25rem;
        }
        details.accordion:hover {
          background-color: #f5f5f5;
        }
        details.accordion summary.accordion__title {
          list-style-type: none;
          cursor: pointer;
          font-size: 1rem;
          font-weight: 700;
          color: #555;
          padding: 0.875rem 2.5rem 0.875rem 0.875rem;
          background-image: url("data:image/svg+xml;
	charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M23.024 11.263l-7.024 7.023-7.022-7.023-3.091 3.090 8.569 8.569c0.413 0.413 0.961 0.64 1.545 0.64s1.133-0.228 1.545-0.64l8.569-8.569-3.091-3.090z' fill='%23555555'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: right 0.75rem top 0.625rem;
          background-size: 1.5rem;
        }
        details.accordion summary.accordion__title::marker,
        details.accordion summary.accordion__title::-webkit-details-marker {
          display: none;
        }
        details.accordion .accordion__content {
          padding: 0 1rem 1rem 1rem;
        }
        details.accordion .accordion__content p {
          margin: 0;
        }
        details.accordion .accordion__content p + p {
          margin-top: 0.5em;
        }
        details.accordion[open] {
          background-color: #ebebeb;
          border-color: #ebebeb;
        }
        details.accordion[open] summary.accordion__title {
          background-image: url("data:image/svg+xml;
	charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.976 22.025l7.024-7.023 7.022 7.023 3.091-3.090-8.568-8.568c-0.413-0.412-0.961-0.64-1.545-0.64s-1.133 0.228-1.545 0.64l-8.569 8.569 3.091 3.090z' fill='%23555555'/%3e%3c/svg%3e");
        }

        @media only screen and (min-width: 600px) {
          /* For tablets: */
          .container {
            column-count: 2;
            column-gap: 10px;
          }
          .object-info {
            padding-left: 3em;
            padding-top: 2em;
          }
        }
      `}</style>
    </Layout>
  )
}
