import { CollectionsHttpService } from '@httpServices'
import COLLECTIONS_API from '@lib/const/collections-api.const'
// import ART_ARCHIVES from '@lib/const/art-artchives.const'
import {
  generateStickyNavItem,
  generateSelectionsFromCollectionDataFrom,
  getParseNotes,
  calculateTotalPageCount,
} from '@utils'
import { ExtendedEMuseumService } from '@services'
import { eMUSEUM_PAGINATION_LIMIT } from '@lib/const'
import { IObjectWithCollectionIdForSitemap } from '@interfaces'

export const CollectionsService = {
  /**
   * Get all collections service
   */
  getAllCollections: async () => {
    const collections = await CollectionsHttpService.getAllCollections()

    return collections.collections
  },
  /**
   * Get all collections service for collection and archive page
   */
  getCollectionsByPageType: async (
    locale: string,
    pageType: 'collection' | 'archive'
  ) => {
    const getSourceIdsByFilters = COLLECTIONS_API.filter(
      (col) => col.locale === locale && col.type === pageType
    ).map((col) => ({ sourceId: col.sourceId, rank: col.rank }))

    const collections = await CollectionsHttpService.getAllCollections()

    const filteredCollectionsFromAPI = collections.collections
      .filter((col) =>
        getSourceIdsByFilters.some(
          (c) => c.sourceId === parseInt(col.sourceId.value)
        )
      )
      .map((col) => {
        const sourceId = parseInt(col.sourceId.value, 10)
        const getRankIndex = getSourceIdsByFilters.findIndex(
          (d) => d.sourceId === sourceId
        )

        if (getRankIndex !== -1) {
          return {
            ...col,
            rank: getSourceIdsByFilters[getRankIndex]?.rank || 1,
          }
        }

        return col
      })
      .sort((a, b) => a.rank - b.rank)

    const transformedCollections = await Promise.all(
      filteredCollectionsFromAPI.map(async (col) => {
        const sourceId = parseInt(col?.sourceId?.value, 10)
        const objectsOfCollection =
          await CollectionsHttpService.getObjectsOfCollection(sourceId)

        return {
          id: sourceId,
          title: col?.name?.value || '',
          description:
            (col?.notes && getParseNotes(col?.notes?.value)?.description) || '',
          type: pageType,
          imgUrl: col?.image?.value || '',
          collection: generateSelectionsFromCollectionDataFrom(
            sourceId,
            objectsOfCollection.objects,
            pageType,
            locale
          ).slice(0, 10),
        }
      })
    )

    return transformedCollections
  },
  /**
   * Get collection or archive for detail page
   */
  getCollectionDetailPage: async (
    sourceId: string,
    pageType: 'collection' | 'archive',
    locale: string
  ) => {
    const pageData = {
      data: {
        selectionsFromWorks: null,
        stickyNavItems: null,
      },
      error: null,
    }

    try {
      const safeCollectionDetail =
        await CollectionsHttpService.getCollectionDetail(sourceId)

      const transformedCollection = safeCollectionDetail.collection.map(
        (col) => ({
          id: parseInt(col?.sourceId?.value, 10) || '',
          title: col?.name?.value || '',
          description:
            (col?.notes && getParseNotes(col?.notes?.value)?.description) || '',
          type: pageType,
          imgUrl: col?.image?.value || '',
          collection: [],
          googleArtLink: null,
          threeDTourEmbedLink: null,
          pressRelease: null,
          photosAndVideos: null,
          products: null,
        })
      )
      const collectionDetail = transformedCollection[0]

      // Preparing STICKY NAV ITEMS
      const initStickyNavItems = []

      // check empty control for aboutTheArchive sticky nav item
      collectionDetail.description !== '' &&
        initStickyNavItems.push(
          generateStickyNavItem(
            pageType === 'archive' ? 'aboutTheArchive' : 'aboutTheCollection'
          )
        )

      // selections from works
      const objectsOfCollection =
        await CollectionsHttpService.getObjectsOfCollection(
          collectionDetail.id,
          'page=1'
        )
      const selectionsFromWorks = {
        data: [],
        totalCount: 0,
        totalPage: '0',
      }

      selectionsFromWorks.data =
        generateSelectionsFromCollectionDataFrom(
          collectionDetail.id,
          objectsOfCollection.objects,
          pageType,
          locale
        ) || []

      selectionsFromWorks.totalCount = objectsOfCollection.count
      selectionsFromWorks.totalPage = calculateTotalPageCount(
        objectsOfCollection.count,
        eMUSEUM_PAGINATION_LIMIT
      )

      selectionsFromWorks.data.length > 0 &&
        initStickyNavItems.push(generateStickyNavItem('selectionsFromWorks'))

      /* extended e-mueseum data - start */
      const extendedEMuseumData =
        await ExtendedEMuseumService.getExtendedEMuseum(
          locale,
          `collection-${parseInt(collectionDetail.id, 10)}`,
          'collection'
        )

      // banner image url
      if (extendedEMuseumData.bannerImage) {
        collectionDetail.imgUrl = extendedEMuseumData.bannerImage
      }

      // 3d tour
      if (extendedEMuseumData.threeDTourEmbedLink) {
        collectionDetail.threeDTourEmbedLink =
          extendedEMuseumData.threeDTourEmbedLink
        initStickyNavItems.push(generateStickyNavItem('threeDTour'))
      }

      // photos and videos
      if (extendedEMuseumData.photosAndVideos) {
        collectionDetail.photosAndVideos = extendedEMuseumData.photosAndVideos
        initStickyNavItems.push(generateStickyNavItem('photosAndVideos'))
      }

      // products
      if (extendedEMuseumData.products) {
        collectionDetail.products = extendedEMuseumData.products
        initStickyNavItems.push(generateStickyNavItem('giftShop'))
      }

      // google art links
      if (extendedEMuseumData.googleArtLink) {
        collectionDetail.googleArtLink = extendedEMuseumData.googleArtLink
      }

      // digitalSSM link
      if (extendedEMuseumData.digitalssmLink) {
        collectionDetail.digitalssmLink = extendedEMuseumData.digitalssmLink
      }

      // press release
      if (extendedEMuseumData.pressRelease) {
        collectionDetail.pressRelease = extendedEMuseumData.pressRelease
      }
      /* extended e-mueseum data - end */

      pageData.data = collectionDetail
      pageData.data.selectionsFromWorks = selectionsFromWorks
      pageData.data.stickyNavItems = initStickyNavItems
    } catch (err) {
      if (err instanceof Error) {
        pageData.error = { message: err.message }
      }
    }

    return pageData
  },
  getObjectsOfCollection: async (
    collectionSourceId: string,
    pageType: 'collection' | 'archive',
    locale: string,
    addParams?: string
  ) => {
    // selections from works
    const objectsOfCollection =
      await CollectionsHttpService.getObjectsOfCollection(
        collectionSourceId,
        addParams
      )
    const selectionsFromWorks = {
      data: [],
      totalCount: 0,
    }

    selectionsFromWorks.data =
      generateSelectionsFromCollectionDataFrom(
        collectionSourceId,
        objectsOfCollection.objects,
        pageType,
        locale
      ) || []

    selectionsFromWorks.totalCount = objectsOfCollection.count

    return selectionsFromWorks
  },
  getObjectsOfCollections: async (): Promise<
    IObjectWithCollectionIdForSitemap[][]
  > => {
    const collections = await CollectionsService.getAllCollections()

    const collectionIds = collections.map(
      (collection) => collection.sourceId.value
    )

    const objectIdsWithCollectionId: IObjectWithCollectionIdForSitemap[][] =
      await Promise.all(
        collectionIds.map(async (collectionSourceId) => {
          const objectsOfCollection =
            await CollectionsHttpService.getObjectsOfCollection(
              collectionSourceId
            )

          return objectsOfCollection.objects.map((object) => ({
            objectId: object.sourceId.value,
            collectionId: collectionSourceId,
          }))
        })
      )

    return objectIdsWithCollectionId
  },
}
