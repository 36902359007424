/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Box, Flex, IconButton, Image } from '@chakra-ui/react'
import { FullScreen } from '@icon'
import { IPhotoSliderSection } from '@interfaces'
import useTranslation from 'next-translate/useTranslation'
import 'photoswipe/dist/default-skin/default-skin.css'
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import React, { useRef, useState } from 'react'
import { CustomGallery, DefaultLayout, Item } from 'react-photoswipe-gallery'
import SwiperCore, { Thumbs } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

// install Swiper's Thumbs component
SwiperCore.use([Thumbs])

const CollectionWorkDetailCarousel = ({
  collection,
}: Pick<IPhotoSliderSection, 'collection'>) => {
  // const [activeSliderIndex, setActiveSliderIndex] = useState(0)

  // store thumbs swiper instance
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const collectionWorkDetailCarouselRef = useRef()
  const { t } = useTranslation()

  const handleZoomIn = () => {
    const exhibitionPhotos = document.querySelectorAll<HTMLElement>(
      '[data-photo-and-videos-index]'
    )

    if (exhibitionPhotos.length) {
      exhibitionPhotos[0].click()
    }
  }
  {
    /*   
  const handleDownload = async () => {
    const imageSrc = collection[activeSliderIndex].url
    try {
      const image = await fetch(imageSrc)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'object-image'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      const link = document.createElement('a')
      link.href = imageSrc
      link.target = '_blank'
      link.download = 'object-image'
      link.rel = 'noopener noreferrer'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
*/
  }
  return (
    <Box
      width="full"
      bgColor="white.primary"
      position="relative"
      sx={{
        '.collection-work-carousel': {
          width: '100%',
          height: '100%',
        },
        '.collection-work-thumb-carousel': {
          width: '100%',
          maxW: '500px',
          height: '100%',
        },
        '.collection-work-thumb-carousel .swiper-slide': {
          width: '60px',
        },
        '& .pswp__img': {
          objectFit: 'contain',
        },
      }}
    >
      <CustomGallery
        ui={PhotoswipeUIDefault}
        layoutRef={collectionWorkDetailCarouselRef}
      >
        <Swiper
          thumbs={{ swiper: thumbsSwiper }}
          slidesPerView={'auto'}
          className="collection-work-carousel"
          onSlideChange={({ activeIndex }) => setActiveSliderIndex(activeIndex)}
        >
          {collection.map((col, index) => (
            <SwiperSlide key={col.id}>
              <Item
                original={col.url}
                thumbnail={col.url}
                width="1024"
                height="768"
              >
                {({ ref, open }) => (
                  <Image
                    ref={ref as React.MutableRefObject<HTMLImageElement>}
                    onClick={() => {
                      open()
                    }}
                    src={col.url}
                    fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
                    alt={col.alt}
                    width="full"
                    height={{ base: '400px', lg: '408px' }}
                    objectFit="contain"
                    data-photo-and-videos-index={index}
                  />
                )}
              </Item>
            </SwiperSlide>
          ))}
        </Swiper>
      </CustomGallery>
      <Box bgColor="white.primary2" display="flex" alignItems="center" p="10px">
        <Swiper
          onSwiper={setThumbsSwiper}
          slidesPerView={'auto'}
          freeMode={true}
          spaceBetween={10}
          watchSlidesVisibility
          watchSlidesProgress
          className="collection-work-thumb-carousel"
        >
          {collection.map((col) => (
            <SwiperSlide key={col.id}>
              <Image
                src={col.url}
                fallbackSrc="/img/placeholders/no-image-available-icon.jpeg"
                alt={col.alt}
                width="full"
                height="40px"
                objectFit="cover"
                cursor="pointer"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Flex
          alignItems="center"
          sx={{
            '&>*+*': {
              ml: 5,
            },
          }}
        >
          {/* 
          <IconButton
            variant="unstyled"
            title={t('common:download')}
            aria-label={t('common:download')}
            icon={<Download color="grey.primary2" />}
            onClick={handleDownload}
          />
          */}
          <IconButton
            variant="unstyled"
            title={t('common:fullScreen')}
            aria-label={t('common:fullScreen')}
            icon={<FullScreen color="grey.primary2" />}
            onClick={handleZoomIn}
          />
        </Flex>
      </Box>
      <DefaultLayout
        zoomButton={false}
        shareButton={false}
        fullscreenButton={true}
        ref={collectionWorkDetailCarouselRef}
      />
    </Box>
  )
}

export default CollectionWorkDetailCarousel
