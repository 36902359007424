import {
  Box,
  Text,
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  chakra,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useStore } from '@store'
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import { Cross } from '@icon'
import { useRouter } from 'next/router'
import { useMemo, useRef, useState } from 'react'
import { sideMenuAnim } from '@lib/motion'
import { createNavLink, prepareCollectionSearchTerm } from '@utils'
import { Link } from '@ui'
import { POPULAR_COLLECTION_SEARCH_TEARMS } from '@lib/const/popularCollectionSearchTerms.const'
import { IArtHeroSearchCard } from '@/src/interfaces'

const ArtHeroSearchCard = observer(
  ({ isCollectionDetail = false }: IArtHeroSearchCard) => {
    const searchInputBoxEl = useRef<HTMLInputElement>(null)
    const inputEl = useRef<HTMLInputElement>(null)
    const inputElModal = useRef<HTMLInputElement>(null)
    const [searchInputFocused, setSearchInputFocused] = useState(false)
    const router = useRouter()
    const { t } = useTranslation('exhibition-and-event')
    const {
      ui: { displayBannerSlider },
      artSearch: { searchTerm, setSearchTerm, selectedInCollectionOptions },
    } = useStore()

    const [isDesktop] = useMediaQuery('(min-width: 769px)')

    const handleSearchAction = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      inputEl.current.blur()
      if (searchTerm === '') {
        inputEl.current && inputEl.current.focus()
        return
      }
      if (!isDesktop) setSearchInputFocused(false)
      let query = router.query
      query = {}

      if (selectedInCollectionOptions != 'All' || isCollectionDetail) {
        setSearchTerm('')
        query.selectedInCollectionOptions = selectedInCollectionOptions
        query.searchTitle = searchTerm
        query.isAdvancedSearch = 'true'
      } else {
        query.searchTerm = searchTerm
      }
      router.push({
        pathname: createNavLink('collectionSearch', router.locale),
        query,
      })
    }

    const handleCloseSearchAction = () => {
      if (searchInputFocused) {
        setSearchInputFocused(false)
      }
      if (searchTerm !== '') {
        setSearchTerm('')
        return
      }
    }

    const onSearchInputFocus = () => {
      if (!isDesktop) {
        setSearchInputFocused(true)
        inputEl.current.blur()
      }
    }

    const searchBoxTransform = useMemo<string | null>(
      () =>
        isCollectionDetail
          ? isDesktop
            ? 'translateY(-70px) !important'
            : 'translateY(0px) !important'
          : isDesktop
          ? displayBannerSlider
            ? 'translateY(0px) !important'
            : 'translateY(-70px) !important'
          : null,
      [isDesktop, isCollectionDetail, displayBannerSlider]
    )

    return (
      <>
        {!isDesktop && (
          <Modal
            isOpen={searchInputFocused}
            onClose={() => setSearchInputFocused(false)}
            size="full"
          >
            <ModalOverlay />
            <ModalContent bgColor="red.primary">
              <ModalCloseButton color="white.primary" />
              <ModalBody>
                <chakra.form
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mt={{
                    base: 'padding.primary2',
                    lg: '5',
                  }}
                  onSubmit={handleSearchAction}
                >
                  <InputGroup>
                    <Input
                      autoFocus
                      ref={inputElModal}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      px="5"
                      type="text"
                      placeholder={t(
                        'collections-and-research:collections.heroSearchCard.searchInputPlaceholder'
                      )}
                      variant="secondary"
                      size="secondary"
                      value={searchTerm}
                    />
                    <InputRightElement
                      pr="5"
                      width="5"
                      bottom="0"
                      height="full"
                    >
                      <IconButton
                        onClick={handleCloseSearchAction}
                        variant="unstyled"
                        aria-label={t(
                          'collections-and-research:collections.heroSearchCard.searchInputPlaceholder'
                        )}
                        icon={
                          <Cross
                            width="12px"
                            height="12px"
                            color="grey.primary2"
                          />
                        }
                      />
                    </InputRightElement>
                  </InputGroup>
                  <Button
                    type="submit"
                    fontSize="sm"
                    fontWeight="medium"
                    minW={{ base: '10', lg: '110px' }}
                    height="50px"
                    _hover={{
                      bgColor: 'red.primary3',
                    }}
                  >
                    {t(
                      'collections-and-research:collections.heroSearchCard.searchButtonLabel'
                    )}
                  </Button>
                </chakra.form>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            '@media (min-width: 769px)': {
              bottom: isCollectionDetail ? 0 : '-70px',
            },
          }}
          width="full"
          height="auto"
          maxWidth={{ base: 'calc(100vw - 30px)', lg: '60%' }}
          zIndex="1"
        >
          <AnimatePresence exitBeforeEnter>
            <Box
              ref={searchInputBoxEl}
              className="search-box"
              as={motion.div}
              variants={sideMenuAnim.sliderMotion}
              initial="hidden"
              animate="visible"
              exit="hidden"
              bgColor="red.primary"
              py="padding.primary"
              transform={searchBoxTransform}
              transition="all 0.5s ease-in-out"
              px={{ base: 'padding.primary', lg: 'padding.primary2' }}
            >
              <Text
                as="p"
                color="white.primary"
                noOfLines={3}
                fontWeight="light"
              >
                {t(
                  'collections-and-research:collections.heroSearchCard.description'
                )}
              </Text>
              <chakra.form
                display="flex"
                flexDirection="row"
                alignItems="center"
                mt={{
                  base: isDesktop ? 'padding.primary2' : 0,
                  lg: '5',
                }}
                onSubmit={handleSearchAction}
              >
                <InputGroup>
                  <Input
                    ref={inputEl}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    px="5"
                    type="text"
                    placeholder={t(
                      'collections-and-research:collections.heroSearchCard.searchInputPlaceholder'
                    )}
                    onFocus={onSearchInputFocus}
                    variant="secondary"
                    size="secondary"
                    value={searchTerm}
                  />
                  <InputRightElement pr="5" width="5" bottom="0" height="full">
                    <IconButton
                      onClick={handleCloseSearchAction}
                      variant="unstyled"
                      aria-label={t(
                        'collections-and-research:collections.heroSearchCard.searchInputPlaceholder'
                      )}
                      icon={
                        <Cross
                          width="12px"
                          height="12px"
                          color="grey.primary2"
                        />
                      }
                    />
                  </InputRightElement>
                </InputGroup>
                <Button
                  type="submit"
                  fontSize="sm"
                  fontWeight="medium"
                  minW={{ base: '10', lg: '110px' }}
                  height="50px"
                  _hover={{
                    bgColor: 'red.primary3',
                  }}
                >
                  {t(
                    'collections-and-research:collections.heroSearchCard.searchButtonLabel'
                  )}
                </Button>
              </chakra.form>
            </Box>
            )
          </AnimatePresence>
          {!isCollectionDetail && isDesktop && (
            <Flex
              bgColor="red.primary2"
              height="70px"
              flexDirection="row"
              overflowX="auto"
              alignItems="center"
              px={{ base: 'padding.primary', lg: 'padding.primary2' }}
              sx={{
                '& > * + *': {
                  ml: 'padding.primary',
                },
              }}
              transform={
                displayBannerSlider ? 'translateY(0px)' : 'translateY(-70px)'
              }
              transition="all 0.5s ease-in-out"
            >
              <Text
                fontWeight="medium"
                color="red.primary"
                fontSize="sm"
                whiteSpace="pre"
              >
                {t(
                  'collections-and-research:collections.heroSearchCard.popularSearches'
                )}
              </Text>
              {POPULAR_COLLECTION_SEARCH_TEARMS[router.locale].map(
                (searchTerm, i) => (
                  <Link
                    key={i}
                    href={`${createNavLink(
                      'collectionSearch',
                      router.locale
                    )}?isAdvancedSearch=true&searchTitle=*&selectedInCollectionOptions=${prepareCollectionSearchTerm(
                      searchTerm?.inCollection
                    )}`}
                    scroll={false}
                  >
                    <Text
                      fontWeight="light"
                      color="white.primary"
                      fontSize="sm"
                      whiteSpace="pre"
                    >
                      {searchTerm?.displayTerm}
                    </Text>
                  </Link>
                )
              )}
            </Flex>
          )}
        </Box>
      </>
    )
  }
)

export default ArtHeroSearchCard
